
const LiveAppointment=
{
    id:'00092XYZ',
    patientNumber:125,
    patient:{
        id:1,
        name:'M.S.Dhoni',
        address:'Vadodara, Gujarat, India',
        phone:'1234567988',
        phoneCode:'+91',
        bloodGroup:'AB+',
        isPoliceCase:false,
        caseType:'old',
        dob:'01/01/1999',
        urgencyType:'Normal',
        timeSlot:'8:30pm',
        doctor:{
            id:4,
            name:"Test Doctor 4",
            address:"vadodra Gujrat",
            phone:1234567890,
            location:{id:1,name:'Delhi'},
            specialization:{id:1,name:'specialization 1'},
            insurance:{id:1,name:'A Insurance'},
            imageUrl:''
        },
        history:[
            {
                id:1,
                diagnosis:" AAAAAAAAAAAAAAAAAAAAAAAAAAA",
                treatment:[
                    {
                        id:1,
                        medicineName:"abc",
                        nature:"qwqwq",
                        timing:"3:00pm",
                        comment:"Take it regularly"
                    },
                    {
                        id:2,
                        medicineName:"abc 2",
                        nature:"qwqwq 2",
                        timing:"23:00pm",
                        comment:"Take it regularly"
                    },
                    {
                        id:3,
                        medicineName:"abc 3 ",
                        nature:"qwqwq 3",
                        timing:"3:30pm",
                        comment:"Take it regularly"
                    }
                ],
                invoice:{
                    id:1,
                    itemList:[
                    {
                        id:1,
                        treatment:" Sanitization",
                        price:10

                    },
                    {
                        id:1,
                        treatment:" X Ray",
                        price:100
                    }
                ],
                totalAmount:110,
                createdOn:"23 th Aug 2021"
                }
            }
        ],
        treatment:[],
        itemList:[]

    }
};


export {LiveAppointment};