import React, { useEffect, useState } from 'react';
import { DialogTitle, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, DialogContent, DialogActions } from '@mui/material';
import { connect } from "react-redux";
import { Box } from '@mui/system';
import DialogBox from 'src/common/DialogBox'
import TextInput from 'src/common/TextInput';
import DropDown from 'src/common/DropDown';
import DeleteModal from 'src/common/DeleteModal';
import { useCookies } from 'react-cookie';

import {
    userAccessMangementListAction, getRolesListAction, getMenusListAction,
    addUserForAccessAction, editUserForAccessAction, deleteUserForAccessAction,
    emptyStaffAccessManagment
} from 'src/redux/Main/actions';

const AccessManagment = (props) => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [onDelete, setOnDelete] = useState(false);
    const [mode, setMode] = useState("ADD");
    const [openModal, setOpenModal] = useState(false);
    const [cookies, setCookie] = useCookies(['authtoken']);
    const [ID, SetID] = useState(null)
    const [onCreate, setOnCreate] = useState(false);


    const { authtoken = null } = cookies


    const [state, setState] = useState({
        authToken: authtoken,
        name: "",
        email: "",
        password: "",
        menu_ids: [],
        aed_status: "1,1,1",
        role_id: "0",
        status: 1,
    })

    useEffect(() => {

        props.getMenusListAction()
        props.getRolesListAction()
        props.userAccessMangementListAction({ authToken: authtoken })

    }, [ID, onDelete, onCreate])


    useEffect(() => {
        if (onDelete && props.deleteAccessStatus === 'User access deleted successfully.') {
            props.emptyStaffAccessManagment();
            setOnDelete(false)
        }
        if ((openModal && props.addAccessStatus === 'User access has been added successfully.') || (openModal && props.editAccessStatus === 200)) {
            setOpenModal(false)
            setOnCreate(true)
        }

    }, [props])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onChangeHandler = (event) => {

        const { target: { name, value } = {} } = event;
        let stateCopy = { ...state };
        stateCopy[name] = value;
        setState(stateCopy);

    }

    const getAedStatus = (data = []) => {

        const item = '1,1,1';
        let result = '';

        for (var i of data) {
            result += item + '/';
        }

        return result
    }

    const AddStaffHandler = () => {

        console.log("Payload", state);

        const paylpoad = {
            ...state,
            menu_ids: state.menu_ids.toString(),
            aed_status: getAedStatus(state.menu_ids)
        }

        props.addUserForAccessAction(paylpoad);
    }

    const EditStaffHandler = () => {
        props.editUserForAccessAction(state);

    }

    const DeleteStaffHandler = () => {


        if (ID) {

            const payload = {
                authToken: authtoken,
                user_id: ID
            }

            console.log(payload)
            props.deleteUserForAccessAction(payload)
        }

    }


    const onSelectMenus = (id) => {

        let menuIds = [...state.menu_ids];

        if (menuIds.includes(id)) {
            menuIds = menuIds.filter(e => e !== id)
        } else {
            menuIds.push(id);
        }


        setState({ ...state, menu_ids: menuIds })
    }

    const { roleList = [], menuList = [], userList = [] } = props || {};
    console.log("menu_ids", getAedStatus(state.menu_ids))

    return (
        <>

            {onDelete &&
                <DeleteModal
                    open={onDelete}
                    handleClose={() => setOnDelete(false)}
                    handleSubmit={() => DeleteStaffHandler()}
                />
            }
            <Box>
                <Button
                    className='primary-button'
                    variant='contained'
                    size='small'

                    onClick={() => {
                        setMode('ADD')
                        setOpenModal(true)
                        setState({
                            authToken: authtoken,
                            name: "",
                            email: "",
                            password: "",
                            menu_ids: [],
                            aed_status: null,
                            role_id: "0",
                            status: 1,
                        })
                    }}
                >Add User
                </Button>
                <br />
                <br />

                <DialogBox
                    open={openModal}
                    handleClose={() => setOpenModal(false)}
                >
                    <DialogTitle>
                        {mode === "ADD" ? 'Add Doctor/Staff Access' : 'Edit Add Doctor/Staff Access'}

                    </DialogTitle>
                    <DialogContent dividers>

                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextInput
                                    name={'name'}
                                    placeholder={'Name'}
                                    value={state?.name}
                                    onChange={(e) => onChangeHandler(e)}
                                    error={''}

                                />

                                <TextInput
                                    name={'email'}
                                    placeholder={'Email'}
                                    value={state?.email}
                                    onChange={(e) => onChangeHandler(e)}
                                    error={''}

                                />
                                <TextInput
                                    name={'password'}
                                    placeholder={'Password'}
                                    value={state?.password}
                                    onChange={(e) => onChangeHandler(e)}
                                    error={''}

                                />
                                <DropDown
                                    name={'status'}
                                    placeholder={'Status'}
                                    value={state?.status}
                                    selectLabel={'Please Select'}
                                    onChange={(e) => onChangeHandler(e)}
                                    error={''}
                                    options={[{ name: 'Active', value: '1' }, { name: "Inactive", value: "0" }]}

                                />
                                <DropDown
                                    name={'role_id'}
                                    placeholder={'Role'}
                                    value={state?.role_id}
                                    selectLabel={'Please Select'}
                                    onChange={(e) => onChangeHandler(e)}
                                    error={''}
                                    options={roleList.map((item) => ({ name: item.role, value: item.roleId }))}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <div className="pageParmission" id="menus">
                                    {menuList.map((menu) => (
                                        <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons" key={menu.id} onClick={(e) => onSelectMenus(menu.id)} >
                                            <label className="form-check-label btn btn-outline-primary" for="menu1"><input type="checkbox" name="menu_ids" id="menu1" value={menu.id} checked={state?.menu_ids === menu.id} onChange={(e) => console.log(e)} />{menu.name}</label>
                                        </div>
                                    ))}
                                </div>

                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenModal(false)} variant='outlined'>Close</Button>
                        <Button className='primary-button' onClick={(e) => { mode === "ADD" ? AddStaffHandler(e) : EditStaffHandler(e) }} variant='contained'>Save</Button>
                    </DialogActions>

                </DialogBox>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table" size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email ID</TableCell>
                                    <TableCell>User Role</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userList.map((item) => (

                                    <TableRow hover>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.email}</TableCell>
                                        <TableCell>{item.role_id}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell><span className={item.status === '1' ? "badge badge-pill badge-success" : "badge badge-pill badge-danger"}>Active</span></TableCell>
                                        <TableCell>
                                            <ul className="d-flex options">
                                                <li className="p-2 btn btn-sm btn-outline-success mr-2" onClick={() => {
                                                    setMode("EDIT")
                                                    setOpenModal(true)
                                                }}><i className="fas fa-pencil-alt"></i></li>
                                                <li className="p-2 btn btn-sm btn-outline-danger" onClick={() => {
                                                    SetID(item.user_id);
                                                    setOnDelete(true);
                                                }}>
                                                    <i className="fas fa-trash-alt" ></i>
                                                </li>
                                            </ul>
                                        </TableCell>
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={10}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    userAccessMangementListAction: (params) => dispatch(userAccessMangementListAction(params)),
    getMenusListAction: (params) => dispatch(getMenusListAction(params)),
    getRolesListAction: (params) => dispatch(getRolesListAction(params)),
    addUserForAccessAction: (params) => dispatch(addUserForAccessAction(params)),
    editUserForAccessAction: (params) => dispatch(editUserForAccessAction(params)),
    deleteUserForAccessAction: (params) => dispatch(deleteUserForAccessAction(params)),
    emptyStaffAccessManagment: (params) => dispatch(emptyStaffAccessManagment(params)),

})

const mapStateToProps = state => {
    return {
        userList:
            state.mainReducer?.userAccessMangListReducer?.response?.data || [],
        roleList:
            state.mainReducer?.getRolesListReducer?.response?.data || [],
        menuList:
            state.mainReducer?.getMenusListReducer?.response?.data || [],
        deleteAccessStatus: state.mainReducer?.deleteUserForAccessReducer?.response?.message || null,
        addAccessStatus: state.mainReducer?.addUserForAccessReducer?.response?.message || null,
        editAccessStatus: state.mainReducer?.editUserForAccessReducer?.response?.status || null,

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(AccessManagment);