import React, { Component } from 'react';

import * as $ from 'jquery';

import {Dashboard} from '../../../mock/hospital/Dashboard';
import { Link } from 'react-router-dom';
import { LiveAppointment } from '../../../mock/hospital/LiveAppointment';


class LiveAppointmentComponent extends Component{



	constructor(props) {
		super(props);
		this.state = {
            liveAppointment:LiveAppointment,
            treatment:[
                {
                    id:0,
                    name:'',
                    medicineNature:"",
                    timing:"",
                    comment:""

                }
            ],
            invoice:{
                id:1,
                itemList:[
                        {
                            id:'',
                            treatment:"",
                            price:0
                        }
                ],
            totalAmount:0,
            createdOn:"23 th Aug 2021"
            }
	    };


	  }

      handleInvoiceChange = e => {
        if (
          ["treatment", "price", "id"].includes(
            e.target.name
          )
        ) {
          let treatment = [...this.state.invoice.itemList];
          treatment[e.target.dataset.id][e.target.name] = e.target.value;
        }

        this.handleTotalAmountChange();


      };

      handleChange = e => {
        if (
          ["name", "medicineNature", "timing", "comment"].includes(
            e.target.name
          )
        ) {
          let treatment = [...this.state.treatment];
          treatment[e.target.dataset.id][e.target.name] = e.target.value;
        } else {
          this.setState({ [e.target.name]: e.target.value });
        }
      };

      addInvoice = e => {
        this.setState(prevState => ({
            invoice:{
                ...prevState.invoice,
                itemList: [
                    ...prevState.invoice.itemList,
                    {
                        id:'',
                        treatment:"",
                        price:0
                    }
                ]

            }
        }));

        // setTimeout( ()=>{
        //     let sum= this.state.invoice.itemList.reduce( function(a, b){
        //          return a + b['price'];
        //      }, 0);
        //      this.setState( prevState =>{
        //          prevState.invoice.totalAmount=sum;
        //      })
        //    },100);
      }

      removeInvoice = (index) =>{
        this.setState(prevState => ({
            invoice:{
                ...prevState.invoice,
                itemList:this.state.invoice.itemList.filter(
                    (s, sindex) => index !== sindex)
            }
          }));

        //   setTimeout( ()=>{
        //    let sum= this.state.invoice.itemList.reduce( function(a, b){
        //         return a + b['price'];
        //     }, 0);
        //     console.log(sum);
        //     this.setState( prevState =>{
        //         prevState.invoice.totalAmount=sum;
        //     })
        //   },100)


      }

      addPrescription = e => {
        this.setState(prevState => ({
            treatment: [
            ...prevState.treatment,
            {
                id:Math.random(),
                name:'',
                medicineNature:"",
                timing:"",
                comment:""

            }
          ]
        }));
      };

      handleTotalAmountChange = () =>{
        let sum= this.state.invoice.itemList.reduce( function(a, b){
            return parseInt(a||0) + parseInt(b['price'] || 0);
        }, 0);
        const invoice  = { ...this.state.invoice };
        invoice.totalAmount=sum;
        this.setState( {invoice: invoice});
     }

      removePrescription = (index) =>{
        this.setState({
            treatment: this.state.treatment.filter(
              (s, sindex) => index !== sindex
            )
          });
      }

      saveAppointment = e =>{
          console.log(JSON.stringify(this.state.treatment));
          console.log(JSON.stringify(this.state.invoice));
      }

      componentDidMount() {
        // $(document).ready(function() {
        //     $(".add").click(function(){
        //         $(".table-white").find(".add_row").before('<tr><td>1</td><td><input className="form-control" type="text" styles="min-width:150px"></td><td><input className="form-control" styles="width:100px" type="text"></td><td><a href="javascript:void(0)" className="text-danger font-18 p-2 remove" title="Remove"><i className="fa fa-minus"></i></a></td></tr>');
        //     });

        //     $(".table-white").on ('click', '.remove', function(e){
        //         e.preventDefault();
        //         $(this).closest('tr').remove();
        //     });
        // });

        // $(document).ready(function() {
        //     $(".add_pre").click(function(){
        //         $(".table-white").find(".add_pre_row").before('<tr><td>1</td><td><input className="form-control" type="text" styles="min-width:150px"></td><td><input className="form-control" styles="width:100px" type="text"></td><td><input className="form-control" styles="width:100px" type="text"></td><td><input className="form-control" styles="width:100px" type="text"></td><td><a href="javascript:void(0)" className="text-danger font-18 p-2 remove" title="Remove"><i className="fa fa-minus"></i></a></td></tr>');
        //     });

        //     $(".table-white").on ('click', '.remove', function(e){
        //         e.preventDefault();
        //         $(this).closest('tr').remove();
        //     });
        // });

    }





    render() {

        console.log(this.props)
		const userDetails= this.context;
        return (
			<React.Fragment>
                <div className="container">
                    <div className="card shadow-sm mt-3">
                        <div className="cardTitle">
                            <div className='d-flex'>
                                <p className='mr-3'><strong>Appointment ID : </strong>00092XYZ</p>
                                <p><strong>Patient Name :</strong> Mr. Dhoni</p>
                            </div>
                            <div>
                                <Link className='btn btn-outline-primary mr-2'>Google Meet Link</Link>
                                <a href="#" className='btn btn-success mr-2'>Minimize</a>
                                <Link to='./allappointment' className="btn btn-secondary mr-2">Complete</Link>
                                <button type="button" className="btn btn-primary" onClick={(e) => this.saveAppointment(e)} >Save changes</button>
                            </div>
                        </div>
                        <div className="cardBody">
                            <div className="row">
                                <div className="col-md-12">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <nav className="user-tabs mb-3">
                                                    <ul className="nav nav-tabs nav-tabs-bottom">
                                                        <li className="nav-item">
                                                            <Link className="nav-link active rounded-left" to="#patientProfile" data-bs-toggle="tab">Patient Profile</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" to="#diagnosis" data-bs-toggle="tab">Diagnosis</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" to="#prescription" data-bs-toggle="tab">Prescription</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" to="#invoice" data-bs-toggle="tab">Invoice</Link>
                                                        </li>
                                                        <li className="nav-item">
                                                            <Link className="nav-link rounded-right" to="#pastHistory" data-bs-toggle="tab">Appointments History (3)</Link>
                                                        </li>
                                                    </ul>
                                                </nav>

                                                <div className="tab-content pt-0">
                                                    <div role="tabpanel" id="patientProfile" className="tab-pane fade show active">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <ul className='liveappointmentDetail'>
                                                                    <li>
                                                                        <img src={require('../../../assets/images/profile_circle-1.png').default} width="150" alt="User" />
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="col-md-5">
                                                                <ul className='liveappointmentDetail'>
                                                                    <li className="d-flex">
                                                                        <span><strong>Appintment Date : </strong></span>
                                                                        <span>25/03/2019</span>
                                                                    </li>
                                                                    <li className="d-flex">
                                                                        <span><strong>Doctor Name : </strong></span>
                                                                        <span>Dr. xyz</span>
                                                                    </li>
                                                                    <li className="d-flex">
                                                                        <span><strong>Phone : </strong></span>
                                                                        <span>3226215144</span>
                                                                    </li>
                                                                    <li className="d-flex">
                                                                        <span><strong>DOB : </strong></span>
                                                                        <span>13/05/1981</span>
                                                                    </li>
                                                                    <li className="d-flex">
                                                                        <span><strong>Address : </strong></span>
                                                                        <span>Lorem ipsum dolor sit amet</span>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="col-md-5">
                                                                <ul className='liveappointmentDetail'>
                                                                    <li className="d-flex">
                                                                        <span><strong>Time Slot : </strong></span>
                                                                        <span>8:30pm</span>
                                                                    </li>
                                                                    <li className="d-flex">
                                                                        <span><strong>Blood Group : </strong></span>
                                                                        <span>A+</span>
                                                                    </li>
                                                                    <li className="d-flex">
                                                                        <span><strong>Police Case : </strong></span>
                                                                        <span>No</span>
                                                                    </li>
                                                                    <li className="d-flex">
                                                                        <span><strong>Urgency Type : </strong></span>
                                                                        <span>Normal</span>
                                                                    </li>
                                                                    <li className="d-flex">
                                                                        <span><strong>Case Type : </strong></span>
                                                                        <span>Old</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div role="tabpanel" id="pastHistory" className="tab-pane fade">
                                                        <div className="row">
                                                            <div className="col-md-12 col-lg-12">
                                                                <div className="accordion" id="accordionExample">
                                                                    <div className="card m-0">
                                                                        <div className="card-header p-0 collapsed" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                            <h6 className="mb-0 fw-bold p-2">
                                                                                <i className="fa fa-plus"></i> 24/05/2018 (Dr. Wilson)
                                                                            </h6>
                                                                        </div>

                                                                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                                            <div className="card-body">
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <div className="card">
                                                                                            <div className='dashboard_box listingBlock box_bg'>
                                                                                                <div className="d-flex">
                                                                                                    <div className='d-flex justify-content-start align-items-center'>
                                                                                                        <div className="img_block text-center mr-3">
                                                                                                            <img className="profile-pic" src={require('../../../assets/images/profile_circle-1.png').default} />
                                                                                                        </div>
                                                                                                        <div className="docinfo">
                                                                                                            <p className="title_name mb-md-0 mb-2"><strong>Ramesh Sharma</strong></p>
                                                                                                            <p className="sub_font mb-md-0 mb-1"><i className="fa fa-map-marker mr-2"></i> Lalkila, Delhi</p>
                                                                                                            <p className="sub_font mb-md-0 mb-1"><i className="fa fa-mobile-alt mr-2"></i> 1236547898</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="mt-2">
                                                                                                        <p className="title_name mb-2"><i className="far fa-clock"></i> <strong>10:20 - 11:00</strong></p>
                                                                                                        <p className="title_name mb-2"><i className="far fa-hospital"></i> <strong>Apollo Delhi</strong></p>
                                                                                                        <p className="title_name" href="#"><i className="far fa-user"></i> <strong>Dr. Wilson</strong></p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="text-right d-flex flex-column">
                                                                                                    <Link to="#" className="btn btn-primary mb-2 m_font_10"  >View Prescription</Link>
                                                                                                    <Link to="#" className="btn btn-primary m_font_10 mb-2" >View Invoice</Link>
                                                                                                    <Link className='btn btn-primary m_font_10' data-toggle="modal" data-target="#viewMedicalHistoryModal">View Medical History</Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-md-6">
                                                                                        <div className="card">
                                                                                            <div className='dashboard_box listingBlock box_bg'>
                                                                                                <div className='d-flex justify-content-start'>
                                                                                                    <div className="img_block text-center mr-3">
                                                                                                        <img className="profile-pic" src={require('../../../assets/images/profile_circle-1.png').default} />
                                                                                                    </div>
                                                                                                    <div className="docinfo">
                                                                                                        <p className="title_name mb-md-0 mb-2"><strong>Ramesh Sharma</strong></p>
                                                                                                        <p className="sub_font mb-md-0 mb-1"><i className="fa fa-map-marker mr-2"></i> Lalkila, Delhi</p>
                                                                                                        <p className="sub_font mb-md-0 mb-1"><i className="fa fa-mobile-alt mr-2"></i> 1236547898</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="mt-2">
                                                                                                    <p className="title_name mb-2"><i className="far fa-clock"></i> <strong>10:20 - 11:00</strong></p>
                                                                                                    <p className="title_name mb-2"><i className="far fa-hospital"></i> <strong>Apollo Delhi</strong></p>
                                                                                                    <p className="title_name" href="#"><i className="far fa-user"></i> <strong>Dr. Wilson</strong></p>
                                                                                                </div>
                                                                                                <div className="text-right d-flex flex-column">
                                                                                                    <Link to="#" className="btn btn-primary mb-2 m_font_10"  >View Prescription</Link>

                                                                                                    <Link to="#" className="btn btn-primary mb-2 m_font_10" >View Invoice</Link>
                                                                                                    <Link className='btn btn-primary m_font_10' data-toggle="modal" data-target="#viewMedicalHistoryModal">View Medical History</Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card m-0">
                                                                        <div className="card-header p-0 collapsed" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                            <h6 className="mb-0 fw-bold p-2">
                                                                                <i className="fa fa-plus"></i> 24/05/2018 (Outside Doctor)
                                                                            </h6>
                                                                        </div>
                                                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                                                            <div className="card-body">
                                                                            <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <div className="card">
                                                                                            <div className='dashboard_box listingBlock box_bg'>
                                                                                                <div className='d-flex justify-content-start'>
                                                                                                    <div className="img_block text-center mr-3">
                                                                                                        <img className="profile-pic" src={require('../../../assets/images/profile_circle-1.png').default} />
                                                                                                    </div>
                                                                                                    <div className="docinfo">
                                                                                                        <p className="title_name mb-md-0 mb-2"><strong>Ramesh Sharma</strong></p>
                                                                                                        <p className="sub_font mb-md-0 mb-1"><i className="fa fa-map-marker mr-2"></i> Lalkila, Delhi</p>
                                                                                                        <p className="sub_font mb-md-0 mb-1"><i className="fa fa-mobile-alt mr-2"></i> 1236547898</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="mt-2">
                                                                                                    <p className="title_name mb-2"><i className="far fa-clock"></i> <strong>10:20 - 11:00</strong></p>
                                                                                                    <p className="title_name mb-2"><i className="far fa-hospital"></i> <strong>Apollo Delhi</strong></p>
                                                                                                    <p className="title_name" href="#"><i className="far fa-user"></i> <strong>Dr. Wilson</strong></p>
                                                                                                </div>
                                                                                                <div className="text-right d-flex flex-column">
                                                                                                    <Link to="#" className="btn btn-primary mb-2 m_font_10"  >View Prescription</Link>

                                                                                                    <Link to="#" className="btn btn-primary m_font_10" >View Invoice</Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-md-6">
                                                                                        <div className="card">
                                                                                            <div className='dashboard_box listingBlock box_bg'>
                                                                                                <div className='d-flex justify-content-start'>
                                                                                                    <div className="img_block text-center mr-3">
                                                                                                        <img className="profile-pic" src={require('../../../assets/images/profile_circle-1.png').default} />
                                                                                                    </div>
                                                                                                    <div className="docinfo">
                                                                                                        <p className="title_name mb-md-0 mb-2"><strong>Ramesh Sharma</strong></p>
                                                                                                        <p className="sub_font mb-md-0 mb-1"><i className="fa fa-map-marker mr-2"></i> Lalkila, Delhi</p>
                                                                                                        <p className="sub_font mb-md-0 mb-1"><i className="fa fa-mobile-alt mr-2"></i> 1236547898</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="mt-2">
                                                                                                    <p className="title_name mb-2"><i className="far fa-clock"></i> <strong>10:20 - 11:00</strong></p>
                                                                                                    <p className="title_name mb-2"><i className="far fa-hospital"></i> <strong>Apollo Delhi</strong></p>
                                                                                                    <p className="title_name" href="#"><i className="far fa-user"></i> <strong>Dr. Wilson</strong></p>
                                                                                                </div>
                                                                                                <div className="text-right d-flex flex-column">
                                                                                                    <Link to="#" className="btn btn-primary mb-2 m_font_10"  >View Prescription</Link>

                                                                                                    <Link to="#" className="btn btn-primary m_font_10" >View Invoice</Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card m-0">
                                                                        <div className="card-header p-0 collapsed" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                            <h6 className="mb-0 fw-bold p-2">
                                                                                <i className="fa fa-plus"></i> 24/05/2018 (Outside Doctor)
                                                                            </h6>
                                                                        </div>
                                                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                                                            <div className="card-body">
                                                                            <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <div className="card">
                                                                                            <div className='dashboard_box listingBlock box_bg'>
                                                                                                <div className='d-flex justify-content-start'>
                                                                                                    <div className="img_block text-center mr-3">
                                                                                                        <img className="profile-pic" src={require('../../../assets/images/profile_circle-1.png').default} />
                                                                                                    </div>
                                                                                                    <div className="docinfo">
                                                                                                        <p className="title_name mb-md-0 mb-2"><strong>Ramesh Sharma</strong></p>
                                                                                                        <p className="sub_font mb-md-0 mb-1"><i className="fa fa-map-marker mr-2"></i> Lalkila, Delhi</p>
                                                                                                        <p className="sub_font mb-md-0 mb-1"><i className="fa fa-mobile-alt mr-2"></i> 1236547898</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="mt-2">
                                                                                                    <p className="title_name mb-2"><i className="far fa-clock"></i> <strong>10:20 - 11:00</strong></p>
                                                                                                    <p className="title_name mb-2"><i className="far fa-hospital"></i> <strong>Apollo Delhi</strong></p>
                                                                                                    <p className="title_name" href="#"><i className="far fa-user"></i> <strong>Dr. Wilson</strong></p>
                                                                                                </div>
                                                                                                <div className="text-right d-flex flex-column">
                                                                                                    <Link to="#" className="btn btn-primary mb-2 m_font_10"  >View Prescription</Link>

                                                                                                    <Link to="#" className="btn btn-primary m_font_10" >View Invoice</Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="col-md-6">
                                                                                        <div className="card">
                                                                                            <div className='dashboard_box listingBlock box_bg'>
                                                                                                <div className='d-flex justify-content-start'>
                                                                                                    <div className="img_block text-center mr-3">
                                                                                                        <img className="profile-pic" src={require('../../../assets/images/profile_circle-1.png').default} />
                                                                                                    </div>
                                                                                                    <div className="docinfo">
                                                                                                        <p className="title_name mb-md-0 mb-2"><strong>Ramesh Sharma</strong></p>
                                                                                                        <p className="sub_font mb-md-0 mb-1"><i className="fa fa-map-marker mr-2"></i> Lalkila, Delhi</p>
                                                                                                        <p className="sub_font mb-md-0 mb-1"><i className="fa fa-mobile-alt mr-2"></i> 1236547898</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="mt-2">
                                                                                                    <p className="title_name mb-2"><i className="far fa-clock"></i> <strong>10:20 - 11:00</strong></p>
                                                                                                    <p className="title_name mb-2"><i className="far fa-hospital"></i> <strong>Apollo Delhi</strong></p>
                                                                                                    <p className="title_name" href="#"><i className="far fa-user"></i> <strong>Dr. Wilson</strong></p>
                                                                                                </div>
                                                                                                <div className="text-right d-flex flex-column">
                                                                                                    <Link to="#" className="btn btn-primary mb-2 m_font_10"  >View Prescription</Link>

                                                                                                    <Link to="#" className="btn btn-primary m_font_10" >View Invoice</Link>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div role="tabpanel" id="diagnosis" className="tab-pane fade">
                                                        <h5 className='text-primary'><strong>Diagnosis</strong></h5>
                                                        <input type="text" className="form-control mb-2" />
                                                    </div>

                                                    <div role="tabpanel" id="prescription" className="tab-pane fade">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover table-white m-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Medicine Name</th>
                                                                        <th>Days</th>
                                                                        <th>Timing</th>
                                                                        <th>Comment</th>
                                                                        <th> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {
                                                                        this.state.treatment.map((val, idx) => {
                                                                        let name = `name-${idx}`,
                                                                                medicineNature = `medicineNature-${idx}`,
                                                                                timing = `timing-${idx}`,
                                                                                comment = `comment-${idx}`;
                                                                                return  (  <tr className="add_pre_row11">
                                                                                <td>{idx}</td>
                                                                                <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Name"
                                                                                    name="name"
                                                                                    styles="min-width:100px"
                                                                                    data-id={idx}
                                                                                    onChange={this.handleChange}
                                                                                    id={name}
                                                                                />
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group">
                                                                                        <input type="text" className="form-control" aria-describedby="helpId" placeholder="Enter Days"/>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group">
                                                                                        <select className="form-control">
                                                                                            <option>Select Timing</option>
                                                                                            <option>1-1-1</option>
                                                                                            <option>1-0-1</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder="Comment"
                                                                                    name="comment"
                                                                                    styles="min-width:100px"
                                                                                    onChange={this.handleChange}
                                                                                    data-id={idx}
                                                                                    id={comment}
                                                                                />
                                                                                </td>
                                                                                <td>
                                                                                {idx === 0 ? (
                                                                                        <a  className="text-success font-18 pt-2 add_pre"
                                                                                        onClick={(e) => this.addPrescription(e)}
                                                                                        title="Add"><i styles="margin-top:10px" className="fa fa-plus"></i></a>
                                                                                    ) : (
                                                                                        <a  className="text-danger font-18 pt-2 add_pre"
                                                                                        onClick={(e) => this.removePrescription(idx)}
                                                                                        title="Delete"><i styles="margin-top:10px" className="fa fa-minus"></i></a>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                        })
                                                                    }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div role="tabpanel" id="invoice" className="tab-pane fade">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover table-white m-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th styles="width: 20px">#</th>
                                                                        <th className="col-md-6">Treatement</th>
                                                                        <th styles="width:100px;">Price</th>
                                                                        <th> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                {
                                                                        this.state.invoice.itemList.map((val, idx) => {
                                                                        let treatment = `treatment-${idx}`,
                                                                        price = `price-${idx}`;
                                                                                return  (  <tr className="add_row">
                                                                                <td>{idx}</td>
                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        placeholder="Treatment"
                                                                                        value="Appointment Charges"
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        placeholder="Price"
                                                                                        value="250"
                                                                                    />
                                                                                </td>

                                                                                <td>
                                                                                {idx === 0 ? (
                                                                                        <a  className="text-success font-18 pt-2 add_pre"
                                                                                        onClick={(e) => this.addInvoice(e)}
                                                                                        title="Add"><i styles="margin-top:10px" className="fa fa-plus"></i></a>
                                                                                    ) : (
                                                                                        <a  className="text-danger font-18 pt-2 add_pre"
                                                                                        onClick={(e) => this.removeInvoice(idx)}
                                                                                        title="Delete"><i styles="margin-top:10px" className="fa fa-minus"></i></a>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>

                                                            <label className='text-primary mt-3'>Medicines Charges</label>
                                                            <table className="table table-hover table-white m-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th styles="width: 20px">#</th>
                                                                        <th className="col-md-6">Medicine Name</th>
                                                                        <th styles="width:100px;">Quantity</th>
                                                                        <th styles="width:100px;">Unit Cost</th>
                                                                        <th styles="width:100px;">Amount</th>
                                                                        <th> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                {
                                                                        this.state.invoice.itemList.map((val, idx) => {
                                                                        let treatment = `treatment-${idx}`,
                                                                        price = `price-${idx}`;
                                                                                return  (  <tr className="add_row">
                                                                                <td>{idx}</td>
                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        placeholder="Treatment"
                                                                                        value="Dolo 400"
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        placeholder="Price"
                                                                                        value="10"
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        placeholder="Price"
                                                                                        value="5"
                                                                                    />
                                                                                </td>
                                                                                <td>
                                                                                    <input
                                                                                        type="text"
                                                                                        className="form-control"
                                                                                        placeholder="Price"
                                                                                        value="50"
                                                                                    />
                                                                                </td>

                                                                                <td>
                                                                                {idx === 0 ? (
                                                                                        <a  className="text-success font-18 pt-2 add_pre"
                                                                                        onClick={(e) => this.addInvoice(e)}
                                                                                        title="Add"><i styles="margin-top:10px" className="fa fa-plus"></i></a>
                                                                                    ) : (
                                                                                        <a  className="text-danger font-18 pt-2 add_pre"
                                                                                        onClick={(e) => this.removeInvoice(idx)}
                                                                                        title="Delete"><i styles="margin-top:10px" className="fa fa-minus"></i></a>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="table-responsive">
                                                            <table className="table table-hover table-white mb-2">
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan="5" className='text-primary'>
                                                                            Total
                                                                        </td>
                                                                        <td styles="text-align: right; padding-left: 80px; font-weight: bold; font-size: 16px;width: 230px">
                                                                            <input className="form-control form-amt" readOnly="" styles="width:100px" type="text"
                                                                            value={this.state.invoice.totalAmount}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    {/* <div className="row dashboard_box">
                                        <div className="col-md-4 col-6">
                                            <div className="box">
                                                <p>Current Patient No.</p>
                                                <p className="waiting">115</p>
                                                <a href="javascript:void(0)" className="btn btn-primary mt-md-5 mt-3" data-toggle="modal" data-target=".take_btn">Take</a>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-6">
                                            <div className="box">
                                                <p>Current Patient Name</p>
                                                <p className="mb-1 waiting"><strong>MS Dhoni</strong></p>
                                                <p className="mb-1"><strong>ID Number : </strong>00092XYZ</p>
                                                <button type="button" className="btn btn-outline-primary">Old Case</button>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-6">
                                            <div className="box">
                                                <div className="text-left">
                                                    <p>Extra Comment</p>
                                                    <p className="text-left">Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
                                                    <p className="mt-3">Police Case ? <button className="btn btn-outline-primary ml-3">No</button></p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-6">
                                            <div className="box">
                                                <p className="mb-3">Extra Information</p>
                                                <div className='text-left'>
                                                    <p><strong>Address:</strong> Vadodara, Gujarat, India</p>
                                                    <p><strong>Phone:</strong> <Link to="tel:+911234567988">+91 1234567988</Link></p>
                                                    <p><strong>Blood Group:</strong> AB+</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-6">
                                            <div className="box">
                                                <p className="mb-md-3 mb-0">History</p>
                                                <p className="mb-md-3 mb-0">Number of time <span className="primary_text">MS Dhoni</span> visited : 3</p>
                                                <ul>
                                                    <li className="mb-2"><p>24/05/2018 <span className="ml-5">4:00 PM</span></p></li>
                                                    <li className="mb-2"><p>26/05/2018 <span className="ml-5">3:00 PM</span></p></li>
                                                    <li className="mb-2"><p>28/05/2018 <span className="ml-5">4:00 PM</span></p></li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="col-md-4 col-6">
                                            <div className="box">
                                                <a className="btn-primary btn mt-3" href="#">Next Patient</a>
                                            </div>

                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* View medical history modal */}
                    <div class="modal fade" id="viewMedicalHistoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Medical History (Ramesh Sharma)</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#medicalprofile" role="tab" aria-controls="home" aria-selected="true">Patient's Medical Profile</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#myappointment" role="tab" aria-selected="true">Patient's Appointments </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#labResult" role="tab" aria-selected="true">Lab Result </a>
                                        </li>
                                    </ul>

                                    <div className="tab-content pt-2">
                                        <div className="tab-pane fade show active" id="medicalprofile" role="tabpanel">
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/blood-type.png').default} alt="blood group" width="65"/>
                                                            </div>
                                                            <h5>Blood group</h5>
                                                            <h6>A+</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/age.png').default} alt="age" width="65"/>
                                                            </div>
                                                            <h5>Age</h5>
                                                            <h6>32 Years</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/weight.png').default} alt="weight" width="65"/>
                                                            </div>
                                                            <h5>Weight</h5>
                                                            <h6>72 kg</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
                                                            </div>
                                                            <h5>Gender</h5>
                                                            <h6>Male</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
                                                            </div>
                                                            <h5>Insurance</h5>
                                                            <h6>Medcare</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
                                                            </div>
                                                            <h5>Any Disabilities</h5>
                                                            <h6>No</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
                                                            </div>
                                                            <h5>HIV</h5>
                                                            <h6>No</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
                                                            </div>
                                                            <h5>Medical Condition</h5>
                                                            <h6>Common</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="myappointment" role="tabpanel">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-md-row flex-column">
                                                        <div className="d-flex align-items-center flex-md-row flex-column w-100">
                                                            <div className="img_block mr-2">
                                                                <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                                            </div>
                                                            <div className="docinfo">
                                                                <Link className="text-primary" to="/hospital/liveappointmentid123">
                                                                    <strong>Dr. Wilson</strong>
                                                                </Link>
                                                                <br />
                                                                <p className="m_font_10"><strong>Appointment ID Number : </strong>1</p>
                                                                <Link className="m_font_10" to="tel:12345789">
                                                                    <i className="fa fa-mobile-alt" ></i>
                                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                                </Link>
                                                                <p className="m_font_10">
                                                                    <i className="fa fa-map-marker"></i>
                                                                    <span style={{ paddingLeft: '4px' }}>Apollo Delhi</span>
                                                                </p>
                                                                <div className='moreBtns'>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="Upcoming">Upcoming</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="View Details">View Details</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="Reschedule">Reschedule</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="Review">Review</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className='d-md-none d-block' />
                                                        <div className="d-flex justify-content-between align-items-center flex-md-column flex-row m-100">
                                                            <div className='w-100'>
                                                                <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 12-09-2022</p>
                                                                <p className="mb-1 small"><i className="far fa-clock"></i> 1:30pm</p>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='fw-bold small'><i className="far fa-clock"></i> Slot time-online</p>
                                                                <Link to="#" className="mb-1 small text-primary"><i className="far fa-clock"></i> Google meet link</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-md-row flex-column">
                                                        <div className="d-flex align-items-center flex-md-row flex-column w-100">
                                                            <div className="img_block mr-2">
                                                                <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                                            </div>
                                                            <div className="docinfo">
                                                                <Link className="text-primary" to="/hospital/liveappointmentid123">
                                                                    <strong>Dr. Jack</strong>
                                                                </Link>
                                                                <br />
                                                                <p className="m_font_10"><strong>Appointment ID Number : </strong>1</p>
                                                                <Link className="m_font_10" to="tel:12345789">
                                                                    <i className="fa fa-mobile-alt" ></i>
                                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                                </Link>
                                                                <p className="m_font_10">
                                                                    <i className="fa fa-map-marker"></i>
                                                                    <span style={{ paddingLeft: '4px' }}>Apollo Mumbai</span>
                                                                </p>

                                                                <div className='moreBtns'>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Completed</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">View Details</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="/user/book/appointment" role="button">Book again</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="/user/book/appointment" role="button">Diagosis</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="/user/prescription" role="button">Prescription</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="/user/invoice" role="button">Invoice</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Review</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className='d-md-none d-block' />
                                                        <div className="d-flex justify-content-between align-items-center flex-md-column flex-row m-100">
                                                            <div className="w-100">
                                                                <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 12-09-2022</p>
                                                                <p className="mb-1 small"><i className="far fa-clock"></i> 1:30pm</p>
                                                            </div>
                                                            <div className="w-100">
                                                                <p className='fw-bold small'><i className="far fa-clock"></i> General time-online</p>
                                                                <Link to="#" className="mb-1 small text-primary"><i className="far fa-clock"></i> Google meet link</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="labResult" role="tabpanel">
                                            <div className="col-md-12 col-12 text-center">
                                                <button className='btn btn-outline-primary text-right shareBtn mt-5' data-toggle="modal" data-target="#confirmRequest">Request Access</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="modal fade take_btn" tabIndex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable modal-lg" role="document">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalScrollableTitle">Patient History</h5>
                                <div className="d-flex" styles="margin: 0 auto">
                                    <p className="mt-2 mr-3"><strong>Patient Name :</strong> Mr. Dhoni</p>
                                    <p className="mt-2"><strong>ID Number : </strong>00092XYZ</p>
                                </div>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-md-6 str_small">
                                            <ul styles="padding:0;list-styles:none">
                                                <li className="mb-2 d-flex">
                                                    <span><strong>Appintment Date </strong></span>

                                                    <span>25/03/2019</span>
                                                </li>
                                                <li className="d-flex">
                                                    <span><strong>Doctor Name </strong></span>

                                                    <span>Dr. xyz</span>
                                                </li>
                                                <li className="d-flex">
                                                    <span><strong>Phone Number </strong></span>

                                                    <span>3226215144</span>
                                                </li>
                                                <li className="d-flex">
                                                    <span><strong>Date Of Birth </strong></span>

                                                    <span>13/05/1981</span>
                                                </li>
                                                <li className="d-flex">
                                                    <span><strong>Address </strong></span>

                                                    <span>Lorem ipsum dolor sit amet</span>
                                                </li>
                                                <li className="d-flex">
                                                    <span><strong>Time Slot </strong></span>

                                                    <span>8:30pm</span>
                                                </li>
                                                <li className="d-flex">
                                                    <span><strong>Blood Group </strong></span>

                                                    <span>A+</span>
                                                </li>
                                                <li className="d-flex">
                                                    <span><strong>Police Case </strong></span>

                                                    <span>No</span>
                                                </li>
                                                <li className="d-flex">
                                                    <span><strong>Urgency Type </strong></span>

                                                    <span>Normal</span>
                                                </li>
                                                <li className="d-flex">
                                                    <span><strong>Case Type </strong></span>

                                                    <span>Old</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <h5>Past Appointments History (3)</h5>

                                            <div className="accordion" id="accordionExample">
                                                <div className="card">
                                                    <div className="card-header p-0 collapsed" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link" type="button">
                                                                <i className="fa fa-plus"></i> 24/05/2018 (Dr. xyz)
                                                            </button>
                                                        </h2>
                                                    </div>

                                                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                        <div className="card-body">
                                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header p-0 collapsed" id="headingTwo" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link" type="button">
                                                                <i className="fa fa-plus"></i> 24/05/2018 (Outside Doctor)
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                                        <div className="card-body">
                                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card">
                                                    <div className="card-header p-0 collapsed" id="headingThree" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-link" type="button" >
                                                                <i className="fa fa-plus"></i> 24/05/2018 (Outside Doctor)
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                                        <div className="card-body">
                                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <hr />

                                        <div className="col-md-12">
                                            <div className="filter">
                                                <h5><strong>Diagnosis</strong></h5>
                                                <input type="text" className="form-control mb-2" />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="col-md-12 col-sm-12">
                                            <div className="filter">
                                                <h5><strong>Prescription </strong></h5>
                                                <div className="table-responsive">
                                                    <table className="table table-hover table-white m-0">
                                                        <thead>
                                                            <tr>
                                                                <th styles="width: 20px">#</th>
                                                                <th className="col-md-6">Medicine Name</th>
                                                                <th styles="width:100px;">Nature of Medicine</th>
                                                                <th styles="width:100px;">Timing</th>
                                                                <th styles="width:100px;">Comment</th>
                                                                <th> </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                this.state.treatment.map((val, idx) => {
                                                                let name = `name-${idx}`,
                                                                        medicineNature = `medicineNature-${idx}`,
                                                                        timing = `timing-${idx}`,
                                                                        comment = `comment-${idx}`;
                                                                        return  (  <tr className="add_pre_row11">
                                                                        <td>{idx}</td>
                                                                        <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Name"
                                                                            name="name"
                                                                            styles="min-width:100px"
                                                                            data-id={idx}
                                                                            onChange={this.handleChange}
                                                                            id={name}
                                                                        />
                                                                        </td>
                                                                        <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Medicine Nature"
                                                                            name="medicineNature"
                                                                            styles="min-width:100px"
                                                                            data-id={idx}
                                                                            onChange={this.handleChange}
                                                                            id={medicineNature}
                                                                        />
                                                                        </td>
                                                                        <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Timing"
                                                                            name="timing"
                                                                            styles="min-width:100px"
                                                                            onChange={this.handleChange}
                                                                            data-id={idx}
                                                                            id={timing}
                                                                        />
                                                                        </td>
                                                                        <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Comment"
                                                                            name="comment"
                                                                            styles="min-width:100px"
                                                                            onChange={this.handleChange}
                                                                            data-id={idx}
                                                                            id={comment}
                                                                        />
                                                                        </td>
                                                                        <td>
                                                                        {idx === 0 ? (
                                                                                <a  className="text-success font-18 pt-2 add_pre"
                                                                                onClick={(e) => this.addPrescription(e)}
                                                                                title="Add"><i styles="margin-top:10px" className="fa fa-plus"></i></a>
                                                                            ) : (
                                                                                <a  className="text-danger font-18 pt-2 add_pre"
                                                                                onClick={(e) => this.removePrescription(idx)}
                                                                                title="Delete"><i styles="margin-top:10px" className="fa fa-minus"></i></a>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                                })
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <hr />

                                        <div className="col-md-12 col-sm-12">
                                            <div className="filter">
                                                <h5><strong>Invoice</strong></h5>
                                                <div className="table-responsive">
                                                    <table className="table table-hover table-white m-0">
                                                        <thead>
                                                            <tr>
                                                                <th styles="width: 20px">#</th>
                                                                <th className="col-md-6">Treatement</th>
                                                                <th styles="width:100px;">Price</th>
                                                                <th> </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                        {
                                                                this.state.invoice.itemList.map((val, idx) => {
                                                                let treatment = `treatment-${idx}`,
                                                                price = `price-${idx}`;
                                                                        return  (  <tr className="add_row">
                                                                        <td>{idx}</td>
                                                                        <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Treatment"
                                                                            name="treatment"
                                                                            styles="min-width:100px"
                                                                            data-id={idx}
                                                                            onChange={this.handleInvoiceChange}
                                                                            id={treatment}
                                                                        />
                                                                        </td>
                                                                        <td>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Price"
                                                                            name="price"
                                                                            styles="min-width:100px"
                                                                            data-id={idx}
                                                                            onChange={this.handleInvoiceChange}
                                                                            id={price}
                                                                        />
                                                                        </td>

                                                                        <td>
                                                                        {idx === 0 ? (
                                                                                <a  className="text-success font-18 pt-2 add_pre"
                                                                                onClick={(e) => this.addInvoice(e)}
                                                                                title="Add"><i styles="margin-top:10px" className="fa fa-plus"></i></a>
                                                                            ) : (
                                                                                <a  className="text-danger font-18 pt-2 add_pre"
                                                                                onClick={(e) => this.removeInvoice(idx)}
                                                                                title="Delete"><i styles="margin-top:10px" className="fa fa-minus"></i></a>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table table-hover table-white mb-2">
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="5" styles="text-align: right; font-weight: bold">
                                                                    Total
                                                                </td>
                                                                <td styles="text-align: right; padding-left: 80px; font-weight: bold; font-size: 16px;width: 230px">
                                                                    <input className="form-control form-amt" readOnly="" styles="width:100px" type="text"
                                                                    value={this.state.invoice.totalAmount}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Completed</button>
                                <button type="button" className="btn btn-primary"
                                onClick={(e) => this.saveAppointment(e)}
                                >Save changes</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
		   </React.Fragment>
        )
    }
}


export default LiveAppointmentComponent;