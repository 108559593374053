import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '@fortawesome/fontawesome-free/css/all.css';
// import 'fortawesome/fontawesome-free/css/all.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './state/UserState';
import store from './redux/store';
import { Provider } from 'react-redux';
import { CookiesProvider } from "react-cookie";
import { createMuiTheme, ThemeProvider } from '@mui/material/styles';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 600,
      lg: 900,
      xl: 1200,
      tablet: 1024
    }
  }
});

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <UserProvider >
        <CookiesProvider>

          <BrowserRouter>
            <ThemeProvider theme={theme}>

              <App />
            </ThemeProvider>
          </BrowserRouter>
        </CookiesProvider>
      </UserProvider>
    </Provider>
  </React.StrictMode>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
