import { Box, Tabs, Tab } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import PatientProfile from "./PatientProfile";
import Diagnosis from "./Diagnosis";
import Prescription from "./Prescription";
import Invoice from "./Invoice";
import AppointmentsHistory from "./AppointmentsHistory";
import { useCookies } from 'react-cookie';
import { saveAppointmentDataAction, emptyLiveAppointment, startAppointmentAction } from "src/redux/Main/actions";


const LiveAppointments = (props) => {
    const [cookies, setCookie] = useCookies(['authtoken']);

    const [tab, setTab] = React.useState('Patient Profile');
    const [minimize, setMinimize] = useState(false);


    const [diagnosis, setDiagnosis] = useState('');
    const [prescription, setPrescription] = useState([])
    const [treatment, setTreatment] = useState([])
    const [medicineCharges, setMedicineCharges] = useState([])

    const { authtoken = null } = cookies

    const onChangeTabs = (event, newValue) => {
        setTab(newValue)
    }

    useEffect(()=>{

        if(props.appointmentResponse === 200) {
            window.location.href='/hospital/allappointment'
        }

        return props.emptyLiveAppointment();
    },[])


    const { location: { state: { appointmentId = null, name = null, patient_id = null, apt_id = null } = {} } } = props;

    const saveChanges = () => {
        const payload =
        {
            "authToken": authtoken,
            "apt_id": apt_id,
            "patient_id": patient_id,
            "diagnosis": {
                "diagnosis": diagnosis
            },
            "prescription": {
                "medicine": prescription?.map(({ medicine }) => medicine)?.toString(),
                "days": prescription?.map(({ days }) => days)?.toString(),
                "timing": prescription?.map(({ timing }) => timing)?.toString(),
                "comment": prescription?.map(({ comment }) => comment)?.toString(),
            },
            "invoice": {
                "treatment": treatment?.map(({ treatment }) => treatment)?.toString(),
                "treatment_price": treatment?.map(({ treatment_price }) => treatment_price)?.toString(),
                "medicine": medicineCharges?.map(({ medicine }) => medicine)?.toString(),
                "quantity": medicineCharges?.map(({ quantity }) => quantity)?.toString(),
                "unit_cost": medicineCharges?.map(({ unit_cost }) => unit_cost)?.toString(),
            }
        }

        props.saveAppointmentDataAction(payload)
    }


    const completeHandler = ()=>{
        const payload =
            {
                "authToken": authtoken,
                "status": "Completed",
                "start_time": getTime(),
                "appointmen_id": apt_id

            }
            props.startAppointmentAction(payload)
    }

    const getTime = () => {
        return `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getMilliseconds()}`
    }

    return (
        <Fragment>
            <div className="container">
                <div className="card shadow-sm mt-3">

                    <div className="cardTitle">
                        <div className='d-flex'>
                            <p className='mr-3'><strong>Appointment ID : </strong>{appointmentId}</p>
                            <p><strong>Patient Name :</strong> {name}</p>
                        </div>
                        <div>
                            <Link className='btn btn-outline-primary mr-2'>Google Meet Link</Link>
                            <button type="button" className="btn btn-success mr-2" onClick={() => setMinimize(!minimize)} >Minimize</button>
                            <Link to='./allappointment' className="btn btn-secondary mr-2" onClick={completeHandler} >Complete</Link>
                            <button type="button" className="btn btn-primary" onClick={saveChanges} >Save changes</button>
                        </div>
                    </div>

                    <div className="cardBody">
                        {minimize ?
                            <div>
                                <div className="row">
                                    <Link className='btn btn-outline-primary mr-2' to='./allappointment'>View All Appointments</Link>
                                </div>
                            </div>

                            :
                            <>
                                <div className="row">
                                    <Box>
                                        <Tabs
                                            value={tab}
                                            onChange={onChangeTabs}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            textColor="primary"
                                            indicatorColor="secondary"
                                            aria-label="scrollable auto tabs example"
                                        >
                                            <Tab label="Patient Profile" value="Patient Profile" />
                                            <Tab label="Diagnosis" value="Diagnosis" />
                                            <Tab label="Prescription" value='Prescription' />
                                            <Tab label="Invoice" value='Invoice' />
                                            <Tab label="Appointments History" value='Appointments History' />
                                        </Tabs>
                                    </Box>

                                </div>
                                <br />

                                {tab === "Patient Profile" && <PatientProfile {...props} />}
                                {tab === 'Diagnosis' && <Diagnosis {...props} setDiagnosis={setDiagnosis} diagnosis={diagnosis} />}
                                {tab === 'Prescription' && <Prescription {...props} prescriptionTextHandler={(data) => setPrescription(data)} />}
                                {tab === "Invoice" && <Invoice {...props}

                                    medicineChargesTextHandler={(data) => setMedicineCharges(data)}
                                    treatmentTextHandler={(data) => setTreatment(data)}

                                />}
                                {tab === 'Appointments History' && <AppointmentsHistory patient_id={patient_id} />}
                            </>
                        }
                        <hr />
                    </div>
                </div>
            </div>

        </Fragment>
    )
}



const mapDispatchToProps = dispatch => ({
    saveAppointmentDataAction: (params) => dispatch(saveAppointmentDataAction(params)),
    emptyLiveAppointment: (params) => dispatch(emptyLiveAppointment(params)),
    startAppointmentAction: (params) => dispatch(startAppointmentAction(params)),
})

const mapStateToProps = state => {
    return {
        appointmentResponse:
            state.mainReducer?.saveAppointmentDataReducer?.response?.status || null,
       

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(LiveAppointments);


