import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import '../HospitalLogin.css';
import * as $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';
import { Link, useHistory } from 'react-router-dom';
import {
  getCountryCodes,
  userVerifyOTP,
  userRegistration,
  HospitalRegistration,
  emptyUserRegistration,

} from "../../../../redux/Main/actions";
import { connect } from "react-redux";
import axios from 'axios';

import StepperComponent from "../../Stepper";
import { Grid, Typography, TextField, Box, Autocomplete, Button } from "@mui/material";
import OtpInput from "react-otp-input";

const HospitalSignupComponent = (props) => {
  const history = useHistory();
  const [state, setState] = useState({
    dial_code: null,
    mobile: null,
  });
  const [otp, setOtp] = useState('');
  const [counter, setCounter] = useState(0);
  const [done, setDone] = useState(false);
  const [userDetails, setUserDetails] = useState({
    hospital_name: null,
    username: null,
    password: null,
    ReEnterpassword: null,
  })
  const [activeStep, setActiveStep] = useState(0);
  const [autoCompleteValue, setAutoCompleteValue] = useState({ name: "", value: "" })

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);


  useEffect(() => {
    props.getCountryCodes({});
    axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      let data = response.data;
      if (data) {
        setAutoCompleteValue({ name: data.country_name?.toUpperCase(), value: data.country_calling_code?.slice(1) })
        setState({
          ...state,
          dial_code: data.country_calling_code?.slice(1),
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
    props.emptyUserRegistration({});
  }, []);


  useEffect(() => {
    if (activeStep === 0 && props.OTPMatch) {
      setActiveStep(1);
    }

    return () => setActiveStep(0);
  }, [props.OTPMatch]);
  useEffect(() => {
    if (activeStep === 1 && props.RegistrationSucess) {
      history.push("/hospitalLogin");
    }

    return () => setActiveStep(0);
  }, [props.RegistrationSucess]);

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const stateCopy = { ...state, [name]: value };
    if (name === "mobile") {
      stateCopy["mobile_error"] = false;
      stateCopy["mobile"] = value;
    }

    setCounter(0);

    setState(stateCopy);
  };

  const sendOTPHandler = () => {
    const { dial_code = null, mobile = null } = state || {};
    let errors = {};
    let errCount = 0;
    if (!dial_code) {
      errors["dial_code_error"] = true;
      errCount++;
    }
    if (!mobile) {
      errors["mobile_error"] = true;
      errCount++;
    }

    if (errCount > 0) {
      setState({ ...state, ...errors });
      return;
    }
    setCounter(60);
    const params = {
      dial_code: dial_code,
      mobile: mobile,
      roleId: "2",
    };

    props.registration(params);
  };


  const submitHandler = () => {
    if (props.authToken) {
      props.verifyOTP({
        authToken: props.authToken,
        OTP: Number(otp),
      });
    }
  };

  const onChangeUserHandler = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value })
  }

  const saveHandler = () => {

    const { username = null, hospital_name = null, password = null, ReEnterpassword = null } = userDetails || {};

    let errors = {};
    let errCount = 0;
    if (!username) {
      errors['username_err'] = true;
      errCount++;

    }
    if (!hospital_name) {
      errors['hospital_err'] = true;
      errCount++;
    }

    if (!password) {
      errors['pswd_err'] = true;
      errCount++;
    }
    if (!ReEnterpassword) {
      errors['rePswd_err'] = true;
      errCount++;
    }

    if (password !== ReEnterpassword) {
      errCount++;

    }

    if (errCount > 0) {
      setUserDetails({ ...userDetails, ...errors });
      return;

    }
    if (props.authToken) {
      props.HospitalRegistration({ ...userDetails, authtoken: props.authToken })
    }
  }

  return (
    <React.Fragment>'<Grid container className="container">
      <Grid item xs={6}>
        <img
          src={
            require("../../../../assets/images/home/doctors.png").default
          }
          alt="1"
        />
      </Grid>
      <Grid item xs={6}>
        <section className="multi_step_form mt-4">
          <div className="login">
            <h4 className="text-center text-primary fw-bold">Sign Up</h4>
            <StepperComponent stepCount={activeStep} />
            <br />
            {activeStep === 0 &&

              <section>
                <h6 className="text-center">Please enter the One-Time Password to verify your Account</h6>

                <Typography variant="p">Phone Number</Typography>
                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={12} sm={12} md={4}>
                      <Autocomplete
                        id="country-select-demo"
                        options={props.countriesList}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          if (value) {
                            const stateCopy = { ...state }
                            stateCopy['dial_code_error'] = false;
                            stateCopy['dial_code'] = value.phonecode;
                            setState(stateCopy)
                            setAutoCompleteValue({ name: value.name, value: value.phonecode })

                          }
                        }}
                        value={autoCompleteValue}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose a country"
                            size='small'
                            error={state.dial_code_error}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <TextField
                        placeholder='Mobile Number'
                        value={`+ ${state.dial_code}`}
                        name='mobile'
                        size='small'
                        fullWidth
                        disabled
                        error={state.mobile_error}
                        onChange={onChangeHandler}

                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        placeholder='Mobile Number'
                        value={state.mobile}
                        name='mobile'
                        fullWidth size='small'
                        error={state.mobile_error}
                        onChange={onChangeHandler}

                      />
                    </Grid>
                  </Grid>

                <div className="invalid-feedback small d-block">
                  Account already exist with this mobile number.
                </div>
                <div className="invalid-feedback small d-block">
                  If you wish to login then -{" "}
                  <Link className="text-primary" to="/userlogin">
                    {" "}
                    Click here
                  </Link>
                </div>

                <div className="text-center">

                  <Button
                    variant="outlined"
                    disabled={counter > 0}

                    onClick={sendOTPHandler}
                  >
                    {done ? "Resend OTP" : "Send OTP"}
                  </Button>
                  {counter > 0 && <span id="timer">{counter} Sec</span>}

                  <br />
                  <br />
                  <div className="done_text">
                    <h6>Enter OTP</h6>
                  </div>
                  <br />
                  <div className="d-flex align-items-center justify-content-center">
                    <OtpInput
                      value={otp}
                      onChange={(otp) => setOtp(otp)}
                      numInputs={4}
                      shouldAutoFocus={true}
                      inputStyle={{
                        border: "1px solid #CFD3DB",
                        borderRadius: "8px",
                        width: "54px",
                        height: "54px",
                        fontSize: "12px",
                        color: "#000",
                        fontWeight: "400",
                        caretColor: "blue"
                      }}
                      separator={<span style={{ width: "28px" }}></span>}
                    />

                  </div>
                  <br />
                  <Button
                    type="button"
                    variant='contained'
                    className="next action-button"
                    onClick={submitHandler}
                    disabled={!otp}
                  >
                    Submit & Verify OTP
                  </Button>
                </div>

              </section>


            }

            {activeStep === 1 &&
              <section>
                <h6 className="text-center">Please fill the Information</h6>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} sm={6}>
                    <Typography variant="p"><strong>Hospital Name</strong></Typography>
                    <TextField
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter Hospital Name"
                      name='hospital_name'
                      value={userDetails.hospital_name}
                      error={userDetails?.hospital_err}
                      onChange={onChangeUserHandler}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Typography variant="p"><strong>Username</strong></Typography>
                    <TextField
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter Username"
                      name='username'
                      error={userDetails?.username_err}
                      value={userDetails.username}
                      onChange={onChangeUserHandler}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} className='pt-0'>
                    <Typography variant="p"><strong>Password</strong></Typography>
                    <TextField
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter Password"
                      name='password'
                      value={userDetails.password}
                      onChange={onChangeUserHandler}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} className='pt-0'>
                    <Typography variant="p"><strong>Re-Enter Password</strong></Typography>
                    <TextField
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder="Enter Re-enter Password"
                      name='ReEnterpassword'
                      value={userDetails.ReEnterpassword}
                      onChange={onChangeUserHandler}
                    />
                  </Grid>
                  {userDetails.password !==
                    userDetails.ReEnterpassword && (
                      <div className="invalid-feedback small d-block">
                        your password not match.
                      </div>
                    )}
                </Grid>

                <br />

                <div className="d-flex justify-content-between">

                  <Button
                    type="button"
                    variant='outlined'
                    className="action-button previous previous_button"
                    onClick={() => setActiveStep(0)}
                  >
                    Back
                  </Button>
                  <Button
                    type="button"
                    variant='contained'
                    className="action-button"
                    onClick={saveHandler}
                  >
                    Save & Proceed to Login
                  </Button>
                </div>


              </section>
            }
          </div>

        </section>
      </Grid>
    </Grid>
      {/* <div className="container login_area">
        <div className="row">
          <div className="col-md-6 d-flex align-items-end justify-content-center order-md-1 order-2">
            <img src={require('../../../../assets/images/home/doctors.png').default} alt="1" />
          </div>

          <div className="col-md-6 d-flex align-items-end justify-content-center order-md-2 order-1">
            <section className="multi_step_form">
              <form id="msform" className='login'>
                <ul id="progressbar">
                  <li className="active" id="phoneNo"> Verify Phone Number</li>
                  <li id="info" className={activeStep === 2 ? "active" : ""}>Your Information</li>
                </ul>
                <fieldset
                  className={activeStep === 1 ? "active d-block" : "d-none"}
                >
                  <h6>
                    Please enter the One-Time Password to verify your Account
                  </h6>
                  <div className="form-row">
                    <div className="form-group col-la-12 col-md-12 col-12">
                      <label className="customLabel">
                        <strong>Phone Number</strong>
                      </label>
                      <div className="input-group d-flex p-0">
                        <select
                          className="form-control w-25 input-group-text"
                          required
                          name="dial_code"
                          value={state.dial_code}
                          onChange={onChangeHandler}
                        >
                          <option value="0">Select Country</option>
                          {props?.countriesList.map((item) => (
                            <option
                              value={item.phonecode}
                              key={item.id}
                            >{`+ ${item.phonecode} (${item.iso3})`}</option>
                          ))}
                        </select>
                        <input
                          type="text"
                          className="form-control w-75"
                          placeholder="Enter Your Phone Number"
                          value={state?.mobile}
                          name="mobile"
                          onChange={onChangeHandler}
                        />
                        {(state.dial_code_error || state.mobile_error) &&
                          <div className="d-flex flex-row w-100">
                            {state.dial_code_error &&

                              <div className="invalid-feedback small d-block">
                                Please select Country.
                              </div>
                            }
                            {state.mobile_error &&
                              <div className="invalid-feedback small d-block">
                                Please enter mobile.
                              </div>
                            }

                          </div>
                        }
                        <div className="invalid-feedback small d-block">
                          Account already exist with this mobile number.
                        </div>
                        <div className="invalid-feedback small d-block">
                          If you wish to login then -{" "}
                          <Link className="text-primary" to="/userlogin">
                            {" "}
                            Click here
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    id="sendOtp"
                    className={
                      counter > 0 ? "action-button disabled" : "action-button"
                    }
                    onClick={sendOTPHandler}
                  >
                    {" "}
                    {done ? "Resend OTP" : "Send OTP"}
                  </button>
                  {counter > 0 && <span id="timer">{counter} Sec</span>}
                  <div className="done_text">
                    <a href="#" className="don_icon">
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </a>
                    <h6>Enter OTP</h6>
                  </div>
                  <div className="code_group">
                    <input
                      type="text"
                      maxlLength="1"
                      className="form-control"
                      placeholder="0"
                      name="otp1"
                      value={state?.otp1}
                      onChange={onChangeHandler}
                    />
                    <input
                      type="text"
                      maxlLength="1"
                      className="form-control"
                      placeholder="0"
                      name="otp2"
                      value={state?.otp2}
                      onChange={onChangeHandler}
                    />
                    <input
                      type="text"
                      maxlLength="1"
                      className="form-control"
                      placeholder="0"
                      name="otp3"
                      value={state?.otp3}
                      onChange={onChangeHandler}
                    />
                    <input
                      type="text"
                      maxlLength="1"
                      className="form-control"
                      placeholder="0"
                      name="otp4"
                      value={state?.otp4}
                      onChange={onChangeHandler}
                    />
                    {/* <div className="invalid-feedback small d-block">
                      please enter valid otp.
                    </div> *
                  </div>
                  <button
                    type="button"
                    className="next action-button"
                    onClick={submitHandler}
                  >
                    Submit & Verify OTP
                  </button>
                </fieldset>

                <fieldset className={activeStep === 2 ? "active d-block " : "d-none"}
                >
                  <h6>Please fill the Information</h6>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <label className="customLabel"><strong>Hospital Name</strong></label>
                        <input type="text" className="form-control" placeholder="Enter Hospital Name"

                          name="hospital_name"
                          value={userDetails.hospital_name}
                          onChange={onChangeUserHandler} />
                        {userDetails?.hospital_err &&
                          <div className="invalid-feedback small d-block">
                            Please enter Hospital Name.
                          </div>
                        }
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-12">
                      <div className="form-group">
                        <label className="customLabel"><strong>Username</strong></label>
                        <input type="text" className="form-control" placeholder="Enter Username"

                          name="username"
                          value={userDetails.username}
                          onChange={onChangeUserHandler}
                        />
                        {userDetails?.username_err &&
                          <div className="invalid-feedback small d-block">
                            Please enter User Name.
                          </div>
                        }
                        {/* <div className="invalid-feedback small d-block mb-3">
                          username already exists.
                        </div> *
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <label className="customLabel">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Password"
                          name="password"
                          value={userDetails.password}
                          onChange={onChangeUserHandler}
                        />
                        {userDetails?.pswd_err &&
                          <div className="invalid-feedback small d-block">
                            Please enter mobile.
                          </div>
                        }
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="form-group">
                        <label className="customLabel">
                          <strong>Re-Enter Password</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Re-enter Password"
                          name="ReEnterpassword"
                          value={userDetails.ReEnterpassword}
                          onChange={onChangeUserHandler}
                        />
                        {userDetails.password !==
                          userDetails.ReEnterpassword && (
                            <div className="invalid-feedback small d-block">
                              your password not match.
                            </div>
                          )}
                      </div>
                    </div>
                  </div>


                  <button type="button" className="action-button previous previous_button"
                    onClick={() => setActiveStep(1)}
                  >Back</button>
                  <button
                    type="button"
                    className="action-button"
                    onClick={saveHandler}
                  >
                    Save & Proceed to Login
                  </button>
                </fieldset>
              </form>
            </section>
          </div>
        </div>
      </div> */}

    </React.Fragment>)



}

const mapDispatchToProps = (dispatch) => ({
  getCountryCodes: (params) => dispatch(getCountryCodes(params)),
  verifyOTP: (params) => dispatch(userVerifyOTP(params)),
  registration: (params) => dispatch(userRegistration(params)),
  HospitalRegistration: (params) => dispatch(HospitalRegistration(params)),
  emptyUserRegistration: (params) => dispatch(emptyUserRegistration(params)),

});

const mapStateToProps = (state) => {
  return {
    countriesList:
      state.mainReducer?.getCountryCodesReducer?.response?.data || [],
    authToken:
      state.mainReducer?.userRegistrationReducer?.response?.data?.authToken ||
      null,
    OTPMatch:
      state.mainReducer?.userVerifyOTPReducer?.response?.status === 200 ||
      false,
    RegistrationSucess:
      state.mainReducer?.HospitalRegistrationReducer?.response?.status === 200 ||
      false,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HospitalSignupComponent)
);