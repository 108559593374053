import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getAllPatientInvoiceReportAction } from "src/redux/Main/actions";
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";

const PatientInvoiceReport = (props) => {
    const [cookies, setCookie] = useCookies(['authtoken']);


    console.log("PatientInvoiceReport", props)

    const { authtoken = null } = cookies

    useEffect(() => {

        props.getAllPatientInvoiceReportAction({ authToken: authtoken })

    }, [])

    return (
        <>
            <table table className="table" id="paymentTbl1">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Date</th>
                        <th scope="col">Patient Name</th>
                        <th scope="col">Invoice No.</th>
                        <th scope="col">Amount</th>
                        <th scope="col">More</th>
                    </tr>
                </thead>
                <tbody>
                    {props.patientInvoiceReport?.map((item) => (

                        <tr key={item.invoice_id}>
                            <th scope="row">{item.invoice_id}</th>
                            <td>{item.createdDate}</td>
                            <td>{item.name}</td>
                            <td><Link className='text-primary' to="#">{item.invoice_number}</Link></td>
                            <td>{item.medicine_charge}</td>
                            <td>
                                <a className="btn btn-outline-primary btn-sm mr-1" href="#">View</a>
                                <a className="btn btn-outline-primary btn-sm mr-1" href="#">Delete</a>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    getAllPatientInvoiceReportAction: (params) => dispatch(getAllPatientInvoiceReportAction(params)),

})

const mapStateToProps = state => {
    return {
        patientInvoiceReport:
            state.mainReducer?.allPatientInvoiceReportReducer?.response?.data || [],

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PatientInvoiceReport)