import React, { Component } from 'react';
import { Link } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"




import DataTableGrid from 'src/common/DataGrid/DataGrid';
import { connect } from 'react-redux';

import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const Alldoctors = () => {

	const rows = [
		{
			id: 1, col1: "Hello",
			col2: "World",
			col3: "World",
			col4: "World",
			col5: "World",
			col6: "World",
			col7: "World",
			col8: "World",
			col9: "World",
		},
		{
			id: 2, col1: "MUI X",
			col2: "is awesome",
			col3: "World",
			col4: "World",
			col5: "World",
			col6: "World",
			col7: "World",
			col8: "World",
			col9: "World",
		},
		{
			id: 3, col1: "Material UI",
			col2: "is amazing",
			col3: "World",
			col4: "World",
			col5: "World",
			col6: "World",
			col7: "World",
			col8: "World",
			col9: "World",
		},
		{
			id: 4, col1: "MUI",
			col2: "Lokesh",
			col3: "World",
			col4: "World",
			col5: "World",
			col6: "World",
			col7: "World",
			col8: "World",
			col9: "World",
		},
		{
			id: 5, col1: "Joy UI",
			col2: "is awesome",
			col3: "World",
			col4: "World",
			col5: "World",
			col6: "World",
			col7: "World",
			col8: "World",
			col9: "World",
		},
		{
			id: 6, col1: "MUI Base",
			col2: "is amazing",
			col3: "World",
			col4: "World",
			col5: "World",
			col6: "World",
			col7: "World",
			col8: "World",
			col9: "World",
		}
	];


	const columns = [
		{ field: "id", headerName: "ID", },
		{ field: "col1", headerName: "Doctor Name", width: 150 },
		{ field: "col5", headerName: "Speciality", width: 150 },
		{ field: "col2", headerName: "Hospital", width: 150 },
		{ field: "col4", headerName: "Location", width: 150 },
		{
            headerName: "Action",
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    // onClick={deleteUser(params.id)}
                    onClick={() => { }}
                />,
                <GridActionsCellItem
                    icon={<SecurityIcon />}
                    label="View"
                    onClick={() => { }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<FileCopyIcon />}
                    label="Edit"
                    onClick={() => { }}
                    showInMenu
                />,
            ]
        },	];

	return (
		<div className="container dct-appoinment">
			<h5 className='mt-3 fw-bold mb-3'>All Doctors</h5>

			<DataTableGrid
				rows={rows}
				columns={columns}
				rowCountState={rows.length}

			/>
		</div>
	)
}

export default connect()(Alldoctors)


// class Alldoctors extends React.Component{

// 	componentDidMount() {
// 		document.title = "All Doctors"
// 		$('#allDoctors').DataTable({
// 			dom: '<"top"Bflp>rt<"bottom"ip>',
//             pageLength: 5,
//             buttons: ['copy', 'csv', 'print', 'excel']
// 		});
// 	}

// 	render(){
// 		return(
// 			<>
// 				<div className="container">
// 					<div className="row">
// 						<div className="col-md-12">
// 							<h5 className='mt-3 fw-bold mb-3'>All Doctors</h5>
// 							<div className="card-table mb-0 card p-2">
//                                 <div className="card-body p-0">
// 									<div className="table-responsive">
// 										<table className="datatable table table-hover table-center mb-0" id="allDoctors">
// 											<thead>
// 												<tr>
// 													<th scope="col">#</th>
// 													<th scope="col">Doctor Name</th>
// 													<th scope="col">Speciality</th>
// 													<th scope="col">Hospital</th>
// 													<th scope="col">Location</th>
// 													<th scope="col">Action</th>
// 												</tr>
// 											</thead>
// 											<tbody>
// 												<tr>
// 													<th scope="row">DOC1</th>
// 													<td>Dr. Jack</td>
// 													<td>Dental</td>
// 													<td>Apollo Mumbai</td>
// 													<td>Mumbai</td>
// 													<td>
// 														<Link to="./doctordetail" className='btn btn-outline-primary mr-2'><i className="fas fa-eye"></i></Link>
// 														<Link to="./editdoctor" className='btn btn-outline-success mr-2'><i className="fas fa-edit"></i></Link>
// 														<Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
// 													</td>
// 												</tr>
// 											</tbody>
// 										</table>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</>
// 		)
// 	}
// }


// export default Alldoctors