import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import React from "react";
import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import { useCookies } from 'react-cookie';
import { getAppointmentHistoryAction } from "src/redux/Main/actions";
import { useEffect } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AppointmentsHistory = (props) => {
    const [cookies, setCookie] = useCookies(['authtoken']);


    const { authtoken = null } = cookies

    const patientID = props.patient_id;


    useEffect(() => {

        props.getAppointmentHistoryAction({
            authToken: authtoken,
            patient_id: patientID
        })

    }, [patientID])


    console.log("AppointmentsHistory", props)
    return (
        <Box>
            <div className="row">
                <div className="col-md-12 col-lg-12">

                    {props.appointmentsHistory?.map((item) => (

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"

                            >
                                <Typography>{`${item.appointmentDate} (Dr. ${item.doctor_name}) `}</Typography>
                            </AccordionSummary>

                            <hr />

                            <AccordionDetails>
                                <div className="card">
                                    <div className='dashboard_box listingBlock box_bg'>
                                        <div className="d-flex">
                                            <div className='d-flex justify-content-start align-items-center'>
                                                <div className="img_block text-center mr-3">
                                                    <img className="profile-pic" src={item.profile_image} />
                                                </div>
                                                <div className="docinfo">
                                                    <p className="title_name mb-md-0 mb-2"><strong>{item.patient_name}</strong></p>
                                                    <p className="sub_font mb-md-0 mb-1"><i className="fa fa-map-marker mr-2"></i>{item.patient_address}</p>
                                                    <p className="sub_font mb-md-0 mb-1"><i className="fa fa-mobile-alt mr-2"></i> {item.patient_mobile}</p>
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <p className="title_name mb-2"><i className="far fa-clock"></i> <strong>{item.slotTime}</strong></p>
                                                <p className="title_name mb-2"><i className="far fa-hospital"></i> <strong>{item.doctor_address}</strong></p>
                                                <p className="title_name" href="#"><i className="far fa-user"></i> <strong>{item.doctor_name}</strong></p>
                                            </div>
                                        </div>
                                        <div className="text-right d-flex flex-column">
                                            <Link
                                                to={{
                                                    pathname: "/user/prescription",
                                                    state: { ...item, patient_id: patientID } // your data array of objects
                                                }}
                                                className="btn btn-primary mb-2 m_font_10">View Prescription</Link>
                                            <Link
                                                to={{
                                                    pathname: "/user/invoice",
                                                    state: { ...item, patient_id: patientID } // your data array of objects
                                                }}

                                                className="btn btn-primary m_font_10 mb-2" >View Invoice</Link>
                                            <Link className='btn btn-primary m_font_10' data-toggle="modal" data-target="#viewMedicalHistoryModal">View Medical History</Link>
                                        </div>
                                    </div>
                                </div>
                            </AccordionDetails>

                        </Accordion>

                    ))}

                    <br />
                    <br />
                    <br />

                </div>
            </div>
        </Box>
    )
}



const mapDispatchToProps = dispatch => ({
    getAppointmentHistoryAction: (params) => dispatch(getAppointmentHistoryAction(params)),

})

const mapStateToProps = state => {
    return {
        appointmentsHistory:
            state.mainReducer?.getAppointmentHistoryReducer?.response?.data || [],

    }

}


export default connect(mapStateToProps, mapDispatchToProps)(AppointmentsHistory);