import React, { Component } from 'react';
import { Link   } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"

class Editdoctor extends React.Component{

	componentDidMount() {
        document.title = "Edit Doctor Detail"
		// add more general time
        $(".addMoreBtnGeneral").on("click", function (){
            var html = $(".addMoreGeneralTime").first().clone();
            $(html).find(".change").html("<a href='#' className='btn btn-outline-danger removeGeneralTime'>Remove</a>");

            $(".addMoreGeneralTime").last().after(html);
        });
        $(".removeGeneralTime").on("click",function (){
            alert('yes');
            $(this).parents(".addMoreGeneralTime").remove();
        });

        // add more Qualification
        $(".addMoreBtnQualification").on("click", function (){
            var html = $(".addMoreQualification").first().clone();
            $(html).find(".change").html("<a href='javascript:void(0)' className='btn btn-outline-danger removeQualification'>Remove</a>");

            $(".addMoreQualification").first().after(html);
        });
        $(".removeQualification").on("click",function(){
            alert('yes');
            $(this).parents(".addMoreQualification").remove();
        });
	}

	render(){
		return(
			<>
				<div className="content">
                    <div className="container">
                        <form>
                            <div className="card mt-2">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 text-right">
                                            <button className='btn btn-outline-primary'><i className="fas fa-user-edit"></i> Save</button>
                                        </div>
                                    </div>
                                    <div className="doctor-widget">
                                        <div className="doc-info-left">
                                            <div className="doctor-img">
                                                <div className="small-12 medium-2 large-2 columns">
                                                    <div className="circle_profile mb-3">
                                                        <img className="profile-pic" src={require('../../../assets/images/home/doctor.png').default} />
                                                        <div className="profile_image">
                                                            <i className="fa fa-camera upload-button"></i>
                                                            <input className="file-upload" type="file" accept="image/*" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="doc-info-cont">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input type="text" className='form-control' placeholder='Doctor Name' value='Dr. Jack'/>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="text" className='form-control' placeholder='Phone' value='+91 123654789'/>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="text" className='form-control' placeholder='Email' value='demo@gmail.com'/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input type="text" className='form-control' placeholder='Password' value='******'/>
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="text" className='form-control' placeholder='Confirm Password' value='******'/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-body">
                                    <nav className="user-tabs mb-4">
                                        <ul className="nav nav-tabs nav-tabs-bottom">
                                            <li className="nav-item">
                                                <Link className="nav-link active rounded-left" to="#doc_overview" data-bs-toggle="tab">Education Qualification</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link rounded-right" to="#doc_clinic_hours" data-bs-toggle="tab">Doctor Timing</Link>
                                            </li>
                                        </ul>
                                    </nav>

                                    <div className="tab-content pt-0">
                                        <div role="tabpanel" id="doc_overview" className="tab-pane fade show active">
                                            <div className='addMoreQualification'>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className='text-primary fw-bold'>Education Qualification</h5>
                                                    <div className="change">
                                                        <a href="#" className='btn btn-outline-primary addMoreBtnQualification'>Add More</a>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label for="speciality">Specialization</label>
                                                            <select className='form-control'>
                                                                <option value="1">Dentist</option>
                                                                <option value="2">General Physician</option>
                                                                <option value="3">Homoeopath</option>
                                                                <option value="4">Gynecologist/obstetrician</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label for="speciality">Degree</label>
                                                            <select className='form-control'>
                                                                <option value="1">Bachelor of Medicine, Bachelor of Surgery - MBBS</option>
                                                                <option value="2">Master of Surgery - MS</option>
                                                                <option value="3">Doctor of Medicine - MD</option>
                                                                <option value="4">Bachelor of Ayurvedic Medicine and Surgery - BAMS</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label for="speciality">Collage Name</label>
                                                            <select className='form-control'>
                                                                <option value="1">Collage Name</option>
                                                                <option value="2">Master of Surgery - MS</option>
                                                                <option value="3">Doctor of Medicine - MD</option>
                                                                <option value="4">Bachelor of Ayurvedic Medicine and Surgery - BAMS</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 mt-2 mt-md-2">
                                                        <label>Registration No.</label>
                                                        <input type="text" className="form-control" placeholder="Registration Number" name="registrationNo" />
                                                    </div>
                                                    <div className="col-md-4 col-6 mt-2">
                                                        <label>Registration Council</label>
                                                        <input className="form-control" list="council" placeholder="Registration Council" name="registrationCouncil" />
                                                        <datalist id="council">
                                                            <option value="Council 1"></option>
                                                            <option value="Council 2"></option>
                                                            <option value="Council 3"></option>
                                                            <option value="Council 4"></option>
                                                        </datalist>
                                                    </div>
                                                    <div className="col-md-4 col-6 mt-2">
                                                        <label>Registration Type</label><input className="form-control" list="type" name="registrationType" placeholder="Registration Type" />
                                                        <datalist id="type">
                                                            <option value="Reg Type 1"></option>
                                                            <option value="Reg Type 2"></option>
                                                            <option value="Reg Type 3"></option>
                                                            <option value="Reg Type 4"></option>
                                                        </datalist>
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col-md-12">
                                                        <p className='fw-bold'>Uploaded Document</p>
                                                        <div className='d-flex'>
                                                            <div className="card mr-2">
                                                                <div className="card-body text-center">
                                                                    <div className="btn btn-danger btn-sm editDoctor">
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </div>
                                                                    <img className='m-auto' src={require('../../../assets/images/doc.png').default} width='80' alt="documents" />
                                                                    <p>Regitration Detail</p>
                                                                </div>
                                                            </div>
                                                            <div className="card mr-2">
                                                                <div className="card-body text-center">
                                                                    <div className="btn btn-danger btn-sm editDoctor">
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </div>
                                                                    <img className='m-auto' src={require('../../../assets/images/doc.png').default} width='80' alt="documents" />
                                                                    <p>Regitration Detail</p>
                                                                </div>
                                                            </div>
                                                            <div className="card mr-2">
                                                                <div className="card-body text-center">
                                                                    <div className="btn btn-danger btn-sm editDoctor">
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </div>
                                                                    <img className='m-auto' src={require('../../../assets/images/doc.png').default} width='80' alt="documents" />
                                                                    <p>Regitration Detail</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="form-group mt-2">
                                                            <input type="file" className='form-control' multiple/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </div>

                                        <div role="tabpanel" id="doc_clinic_hours" className="tab-pane fade">
                                            <ul className="nav nav-tabs border-0 w-100">
                                                <li className="nav-item w-50">
                                                    <a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime" role="tab" aria-controls="general" aria-selected="true">General Time <br/>
                                                        <small>(First come, First Served - Waiting time visible - Avg time visible) (Online + In-person)</small>
                                                    </a>
                                                </li>
                                                <li className='nav-item w-50'>
                                                    <a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime" role="tab" aria-controls="slot" aria-selected="true">Slot time <br/>
                                                        <small>(priority fixed appointments) (Online + In-person)</small>
                                                    </a>
                                                </li>
                                            </ul>

                                            <div className="tab-content p-2">
                                                <div id="generalTime" className="tab-pane fade show active" role="tabpanel" aria-labelledby="general-time">
                                                    <label htmlFor="time">Duty Timing</label>
                                                    <div className="mb-2">
                                                        <div className="row addMoreGeneralTime">
                                                            <div className="col-md-3">
                                                                <select className="form-control" defaultValue={'0'}>
                                                                    <option value="0"  disabled>Day</option>
                                                                    <option selected value="0">Monday</option>
                                                                    <option value="0">Tuesday</option>
                                                                    <option value="0">Wednesday</option>
                                                                    <option value="0">Thursday</option>
                                                                    <option value="0">Friday</option>
                                                                    <option value="0">Saturday</option>
                                                                    <option value="0">Sunday</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <div className='d-flex justify-ceontent-between align-items-center'>
                                                                    <input type="time" className="form-control" />
                                                                    <p className='p-2'>to</p>
                                                                    <input type="time" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2 change'>
                                                                <a href="#" className='btn btn-outline-primary addMoreBtnGeneral'><i className="fa fa-plus"></i> Add more</a>
                                                            </div>
                                                        </div>
                                                        <div className="row addMoreGeneralTime">
                                                            <div className="col-md-3">
                                                                <select className="form-control" defaultValue={'0'}>
                                                                    <option value="0"  disabled>Day</option>
                                                                    <option value="0">Monday</option>
                                                                    <option selected value="0">Tuesday</option>
                                                                    <option value="0">Wednesday</option>
                                                                    <option value="0">Thursday</option>
                                                                    <option value="0">Friday</option>
                                                                    <option value="0">Saturday</option>
                                                                    <option value="0">Sunday</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <div className='d-flex justify-ceontent-between align-items-center'>
                                                                    <input type="time" value="10:00AM" className="form-control" />
                                                                    <p className='p-2'>to</p>
                                                                    <input type="time" value="5:00PM" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2 change'>
                                                                <a href="#" className='btn btn-outline-primary'> Remove</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="slotTime" className="tab-pane fade" role="tabpanel" aria-labelledby="slot-time-tab">
                                                    <div className="profile-box">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h6 className='m-0'>Select Slot time</h6>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="session-time flex-wrap">
                                                                            <div>
                                                                                <h6 className="mb-0 me-3 fw-bold">Date</h6>
                                                                                <input type="date" className="form-control" name="schedule_date" id="schedule_date"/>
                                                                            </div>
                                                                            <div>
                                                                                <h6 className="mb-0 me-3 fw-bold">Slot time</h6>
                                                                                <select className="form-control">
                                                                                    <option value="1">5 min</option>
                                                                                    <option value="1">10 min</option>
                                                                                    <option value="1">15 min</option>
                                                                                    <option value="1">20 min</option>
                                                                                    <option value="1">25 min</option>
                                                                                    <option value="1">30 min</option>
                                                                                    <option value="1">35 min</option>
                                                                                </select>
                                                                            </div>
                                                                            <div>
                                                                                <h6 className="mb-0 w-100 mr-2 fw-bold">Start Time </h6>
                                                                                <input type="time" className='form-control btn'/>
                                                                            </div>
                                                                            <div>
                                                                                <h6 className="mb-0 w-100 mr-2 fw-bold">End Time </h6>
                                                                                <input type="time" className='form-control btn'/>
                                                                            </div>
                                                                            <hr />
                                                                            <div className="d-flex">
                                                                                <div className='mr-3'>
                                                                                    <h6 className="mb-0 w-100 mr-2 fw-bold">Start Luanch Time </h6>
                                                                                    <input type="time" className='form-control btn'/>
                                                                                </div>
                                                                                <div>
                                                                                    <h6 className="mb-0 w-100 mr-2 fw-bold">End Luanch Time </h6>
                                                                                    <input type="time" className='form-control btn'/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
			</>
		)
	}
}


export default Editdoctor