import React, { Component, useState } from 'react';
import { Link } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"


import DataTableGrid from 'src/common/DataGrid/DataGrid';
import { connect } from 'react-redux';

import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import DialogBox from 'src/common/DialogBox';
import DeleteModal from 'src/common/DeleteModal';
import TextInput from 'src/common/TextInput';
import DropDown from 'src/common/DropDown';
import TextareaAutosize from '@mui/base/TextareaAutosize';

const Newplan = () => {

    const [openModal, setOpenModal] = useState(false);
    const [onDelete, setOnDelete] = useState(false);

    const rows = [
        {
            id: 1, col1: "Hello",
            col2: "World",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 2, col1: "MUI X",
            col2: "is awesome",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 3, col1: "Material UI",
            col2: "is amazing",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 4, col1: "MUI",
            col2: "Lokesh",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "1.00 PM - 1.15 PM",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 5, col1: "Joy UI",
            col2: "is awesome",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "1.00 PM - 1.15 PM",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 6, col1: "MUI Base",
            col2: "is amazing",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "1.00 PM - 1.15 PM",
            col7: "World",
            col8: "World",
            col9: "World",
        }
    ];


    const columns = [
        { field: "id", headerName: "#", },
        { field: "col1", headerName: "Plan Name", width: 150 },
        { field: "col4", headerName: "Price", width: 150 },
        { field: "col3", headerName: "Benefits", width: 150 },
        { field: "col2", headerName: "Validity", width: 150 },
        { field: "col5", headerName: "Status", width: 150 },
        { field: "col7", headerName: "Status", width: 150 },
        {
            headerName: "Action",
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    // onClick={deleteUser(params.id)}
                    onClick={() => setOnDelete(true)}
                />,
                <GridActionsCellItem
                    icon={<SecurityIcon />}
                    label="View"
                    onClick={() => { }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<FileCopyIcon />}
                    label="Edit"
                    onClick={() => { }}
                    showInMenu
                />,
            ]
        },];


    const handleSubmit = () => {

    }

    return (
        <div className="container dct-appoinment">
            <br />

            <div className='d-flex d-flex justify-content-between mb-3 align-items-center'>
                <h5 className='fw-bold'>Plan List</h5>
                <Button className='primary-button'
                    size='small'
                    onClick={() => {
                        setOpenModal(true)
                    }}
                >Add New Plan
                </Button>
            </div>


            {onDelete &&
                <DeleteModal
                    open={onDelete}

                    handleClose={() => setOnDelete(false)}
                    handleSubmit={() => { }}
                />
            }
            <DataTableGrid
                rows={rows}
                columns={columns}
                rowCountState={rows.length}

            />

            <DialogBox
                open={openModal}
                handleClose={() => setOpenModal(false)}
            >

                <DialogTitle>Add New Plan</DialogTitle>

                <DialogContent dividers>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} sm={12} >
                            <TextInput
                                placeholder='Plan Name'
                                name={'plan'}
                                onChange={() => { }}
                            />

                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                            <TextInput
                                placeholder='Plan Price'
                                name={'price'}
                                onChange={() => { }}
                            />

                        </Grid>
                        <Grid item xs={12} md={6} sm={12} className='pt-0'>
                            <DropDown
                                placeholder={'Validity'}
                                name='validity'
                                selectLabel={'Please Select'}
                                onChange={() => { }}
                                value={0}

                                options={[
                                    { name: "1 Month", value: "1" },
                                    { name: "2 Months", value: "2" },
                                    { name: "3 Months", value: "3" },
                                    { name: "4 Months", value: "4" },
                                    { name: "5 Months", value: "5" },
                                    { name: "6 Months", value: "6" },
                                    { name: "7 Months", value: "7" },
                                    { name: "8 Months", value: "8" },
                                    { name: "9 Months", value: "9" },
                                    { name: "10 Months", value: "10" },
                                    { name: "11 Months", value: "11" },
                                    { name: "12 Months", value: "12" },
                                ]}

                            />

                        </Grid>
                        <Grid item xs={12} md={6} sm={12} className='pt-0'>
                            <DropDown
                                placeholder={'Status'}
                                name='status'
                                selectLabel={'Please Select'}
                                value={0}
                                onChange={() => { }}
                                options={[
                                    { name: "Active", value: "Active" },
                                    { name: "In Active", value: "InActive" },
                                ]}

                            />

                        </Grid>
                        <Grid item className='pt-1'>
                            <Typography>Enter Plan Benifits</Typography>
                            <TextareaAutosize
                                aria-label="minimum height"
                                minRows={3}
                                fullWidth
                                style={{ width: 400 }}

                                placeholder="Enter Plan Benifits"
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)}
                        variant='outlined'>close</Button>
                    <Button onClick={handleSubmit} variant='contained'>Save</Button>
                </DialogActions>

            </DialogBox>
        </div>
    )
}

export default connect()(Newplan)

// class Newplan extends React.Component{

// 	componentDidMount() {
//         document.title = "Add New Plans"
// 		$('#allAppointment').DataTable({
// 			dom: '<"top"Bflp>rt<"bottom"ip>',
//             pageLength: 5,
//             buttons: ['copy', 'csv', 'print', 'excel']
// 		});
// 	}

// 	render(){
// 		return(
// 			<>
// 				<div className="container mt-2">
// 					<div className="row">
// 						<div className="col-md-12">
//                             <button className='btn btn-primary' data-toggle="modal" data-target="#addPlan">Add New Plan</button>
// 							<h5 className='mt-3 fw-bold mb-3'>Plan List</h5>
//                             <table className="datatable table table-hover table-center mb-0" id="allAppointment">
//                                 <thead>
//                                     <tr>
//                                         <th scope="col">#</th>
//                                         <th scope="col">Plan Name</th>
//                                         <th scope="col">Price</th>
//                                         <th scope="col">Benefits</th>
//                                         <th scope="col">Validity</th>
//                                         <th scope="col">Status</th>
//                                         <th scope="col">Action</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     <tr>
//                                         <th scope="row">1</th>
//                                         <td>Monthly</td>
//                                         <td>250</td>
//                                         <td>
//                                             <ul>
//                                                 <li>10 users included</li>
//                                                 <li>Email support</li>
//                                                 <li>Help center access</li>
//                                             </ul>
//                                         </td>
//                                         <td>
//                                             1 month
//                                         </td>
//                                         <td>
//                                             <span className="badge badge-pill badge-primary">Active</span>
//                                         </td>
//                                         <td>
//                                             <Link to="#" className='btn btn-outline-success mr-2'><i className="fas fa-edit"></i></Link>
//                                             <Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                         </td>
//                                     </tr>
//                                     <tr>
//                                         <th scope="row">2</th>
//                                         <td>3 Months</td>
//                                         <td>750</td>
//                                         <td>
//                                             <ul>
//                                                 <li>20 users included</li>
//                                                 <li>Priority email support</li>
//                                                 <li>Help center access</li>
//                                             </ul>
//                                         </td>
//                                         <td>
//                                             3 Months
//                                         </td>
//                                         <td>
//                                             <span className="badge badge-pill badge-primary">Active</span>
//                                         </td>
//                                         <td>
//                                             <Link to="#" className='btn btn-outline-success mr-2'><i className="fas fa-edit"></i></Link>
//                                             <Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                         </td>
//                                     </tr>
//                                 </tbody>
//                             </table>
// 						</div>
// 					</div>
// 				</div>


//                 <div class="modal fade" id="addPlan" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//                     <div class="modal-dialog modal-dialog-centered modal-lg">
//                         <div class="modal-content">
//                             <div class="modal-header">
//                                 <h5 class="modal-title" id="exampleModalLabel">Add New Plan</h5>
//                                     <button type="button" class="close" data-dismiss="modal" aria-label="Close">
//                                     <span aria-hidden="true">&times;</span>
//                                 </button>
//                             </div>
//                             <div class="modal-body">
//                                 <form action="">
//                                     <div className="row">
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label htmlFor="">Plan Name</label>
//                                                 <input type="text" className='form-control' placeholder='Enter Plan Name' />
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label htmlFor="">Plan Price</label>
//                                                 <input type="number" className='form-control' placeholder='Enter Plan Price' />
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label htmlFor="">Validity</label>
//                                                 <select className='form-control'>
//                                                     <option value="1">1 Month</option>
//                                                     <option value="2">2 Month</option>
//                                                     <option value="3">3 Month</option>
//                                                     <option value="4">4 Month</option>
//                                                     <option value="5">5 Month</option>
//                                                     <option value="6">6 Month</option>
//                                                     <option value="7">7 Month</option>
//                                                     <option value="8">8 Month</option>
//                                                     <option value="9">9 Month</option>
//                                                     <option value="10">10 Month</option>
//                                                     <option value="11">11 Month</option>
//                                                     <option value="12">12 Month</option>
//                                                 </select>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label htmlFor="">Status</label>
//                                                 <select className='form-control'>
//                                                     <option value="1">Active</option>
//                                                     <option value="1">Deactive</option>
//                                                 </select>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-12">
//                                             <div className="form-group">
//                                                 <label htmlFor="">Plan Benifits</label>
//                                                 <textarea type="text" className='form-control' placeholder='Enter Plan Benifits' />
//                                             </div>
//                                         </div>
//                                         <div className="col-md-12 text-right">
//                                             <button className='btn btn-primary'>Save</button>
//                                         </div>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
// 			</>
// 		)
// 	}
// }

// export default Newplan