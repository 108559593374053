import {all, fork} from 'redux-saga/effects';

import * as MainSagas from './Main/sagas';

export default function* rootSagas(){
    yield all (
        [
            ...Object.values(MainSagas)
        ].map(fork)
    )
}