
import React, { Component } from 'react';
import * as $ from 'jquery';
import viewImg from '../../../assets/images/view.png';
import HospitalFilterComponent from '../../Search/SearchFilter/HospitalFilter';
import DashBoardItemsComponent from '../../dashboard/dashboard-items/DashBoardItems';
import InsuranceList from '../../../mock/dropdown/InsuranceList';
import SpecialityList from '../../../mock/dropdown/SpecialityList';
import { Link } from 'react-router-dom';


class UserSearchComponent extends Component {

	specialityItemList = {
		speciality: '',
		specialityList: '',
		key: ''
	};

	insuranceItemList = {
		insurance: '',
		insuranceList: '',
		key: ''
	}
	constructor(props) {
		super(props);
		this.state = {
			speciality: '', specialityList: '', insurance: '',
			insuranceList: '',
			specialityId: '',
			insuranceId: '',
			location: '',
			isSpecialityClicked: false,
			isInsuranceClicked: false
		};
		//this.fetchSpecialityList = this.fetchSpecialityList.bind(this);
		this.fetchInsuranceList = this.fetchInsuranceList.bind(this);
		//this.handleBlur=this.handleBlur.bind(this);
		this.specialityItemList.specialityList = SpecialityList;
		this.insuranceItemList.insuranceList = InsuranceList;
	}

	componentDidMount() {
		this.itemList = SpecialityList;

		this.insuranceList = InsuranceList;

		var self = this;
		$('body').on('click', function (evt) {
			if (evt.target.id !== "specialization" && evt.target.id !== "insurance") {
				self.setState({ isSpecialityClicked: false, isInsuranceClicked: false });
				return;
			}
		});



	}

	toggleSpecialityDropDown = (event) => {
		this.setState({ isSpecialityClicked: true, isInsuranceClicked: false });
		this.specialityItemList.key = 'speciality';

	}

	toggleInsuranceDropDown = (event) => {
		this.setState({ isSpecialityClicked: false, isInsuranceClicked: true });
		this.insuranceItemList.key = 'insurance';
	}

	fetchSpecialityList = (event) => {
		event.stopPropagation();
		event.preventDefault();
		this.setState({ speciality: event.target.value, isSpecialityClicked: true });
		this.specialityItemList.speciality = event.target.value;
		this.specialityItemList.specialityList = this.itemList;
		this.specialityItemList.key = 'speciality';
	}


	fetchInsuranceList = (event) => {
		event.stopPropagation();
		event.preventDefault();
		this.setState({ insurance: event.target.value, isInsuranceClicked: true });
		this.insuranceItemList.insurance = event.target.value;
		this.insuranceItemList.insuranceList = this.insuranceList;
		this.insuranceItemList.key = 'insurance';
	}



	onSelectItem = (value, flag, title) => {
		//console.log("received in dashboad"+value+flag);
		if (flag === 'speciality') {
			this.setState({ speciality: title, specialityId: value });
		} else if (flag === 'insurance') {
			this.setState({ insurance: title, insuranceId: value });
		}
		this.isInsuranceClicked = false;
		this.isSpecialityClicked = false;
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	selectAppointment = (val) => {
		console.log('fiilter selected ');
	}

	selectedFilter = (selectedVal) => {
		console.log(" menu selescted ");
	}

	render() {
		return (
			<React.Fragment>



				<div className="row">
					{/* <div className="col-md-12">
						<HospitalFilterComponent onSelectFilter={this.selectedFilter} />
					</div> */}

					<div className="col-md-12">
						<section className="slider_bgimage m-md-3 m-0">
							<div className="container">
								<div className="bg_title_search d-md-block d-none">
									<h2>Find a <b className="color_change">Doctor.</b> Book an <b className="color_change">Appointment.</b>  Track in <b className="color_change">Real-Time.</b> </h2>
								</div>
								<div className="bg_title_search d-md-none d-block">
									<h2 className="mt-5">Find a <b className="color_change">Doctor.</b> <br />Book an <b className="color_change">Appointment.</b><br />  Track in <b className="color_change">Real-Time.</b> </h2>
								</div>
								<div className="search">
									<form>
										<div className="row d-flex row justify-content-center">
											<div className="loc_sec col-md-4 col-12">
												<span className="fa fa-map-marker-alt"></span>
												<input className="location form-control" placeholder="Enter Location" />
												<a href="#" className="detect"><img width="21" src={require('../../../assets/images/gps.svg').default} alt="" /> Detect</a>
											</div>

											<div className="loc_sec col-md-4 col-12">
												<input className="specialization form-control" id="specialization" placeholder="Search Specialization or Name" value={this.state.speciality}
													onChange={(e) => { this.fetchSpecialityList(e) }}
													onClick={(e) => { this.toggleSpecialityDropDown(e) }}
												// onBlur={(e) => {this.handleBlur(e)}}
												/>
												{this.state.isSpecialityClicked ? <DashBoardItemsComponent itemList={this.specialityItemList} onSelectItem={this.onSelectItem} ></DashBoardItemsComponent> : ''}
											</div>
											<div className="col-md-2 col-12">
												<Link className='btn btn-primary w-100 searchBtn' to={{
													pathname: '/search',
													state: {
														specialityId: this.state.specialityId,
														insuranceId: this.state.insuranceId,
														from: 'SEARCH_CLICK'
													}
												}} >
												<ion-icon className="mr-3" name="search"></ion-icon> Search</Link>
											</div>

										</div>
									</form>
								</div>
							</div>
						</section>
						{/*way */}
						<section className="hori_timeline2  m-md-3 m-0 bg_whit ">
							<div className='container'>
								<div className="row">
									<div className="col-md-9 mt-md-3 mt-0">
										<div className='timeline'>
											<div className='start'></div>
										</div>

										<div className='entries row'>
											<div className='entry'>
												<div className="w-100">
													<div className="howto_icon">
														<div className='dot find'></div>
													</div>
													<div className="howto_text">
														<div className='label pl-md-0 pl-3'>
															<div className='time pl-0  mb-0'>
																Find Doctor.
															</div>
															<div className='detail'>
																Search for Doctor in your area.
															</div>
															<div className="mt-1">
																<a href="#">Learn More</a>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className='entry'>
												<div className="w-100">
													<div className="howto_icon">
														<div className='dot appoint'></div>
													</div>

													<div className="howto_text">
														<div className='label'>
															<div className='time pl-0  mb-0'>
																Schedule An Appointment.
															</div>
															<div className='detail'>
																Conveniently schedule your appointment online.
															</div>
															<div className="mt-1">
																<a href="#">Learn More</a>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className='entry'>
												<div className="w-100">
													<div className="howto_icon">
														<div className='dot track'></div>
													</div>
													<div className="howto_text">
														<div className='label'>
															<div className='time pl-0 mb-0'>
																Track your waiting number in Real-time.
															</div>
															<div className='detail'>
																Check your waiting number in real-time to avoid long waiting.
															</div>
															<div className="mt-1">
																<a href="#">Learn More</a>
															</div>
														</div>
													</div>
												</div>

											</div>
										</div>
									</div>

									<div className="col-md-3 mt-md-0 mt-4 d-md-block d-none">
										<img src={viewImg} alt="view" />
									</div>
								</div>
							</div>
						</section>

					</div>
				</div>
			</React.Fragment>
		)
	}
}
export default UserSearchComponent;