import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Link, withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { UserLogout } from "./redux/Main/actions";

const Logout = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["RoleID"]);

  const history = useHistory();

  useEffect(async () => {
    props.Logout({});

    await removeCookie("RoleID");
    history.push("/");
  }, []);

  console.log("Props", props);

  return <div></div>;
};

const mapDispatchToProps = (dispatch) => ({
  Logout: (params) => dispatch(UserLogout(params)),
});

const mapStateToProps = (state) => {
  return { ...state };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
