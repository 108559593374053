import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as $ from 'jquery';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';


class Doctorprofile extends Component{
    state = {
		date: new Date(),
	}
	onChange = date => this.setState({ date })
    render(){
        return(
            <>
                <div className="content">
                    <div className="container">
                        <div className="card mt-2">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12 text-right">
                                        <Link to='/hospitalname/editdoctor' className='btn btn-outline-primary'><i className="fas fa-user-edit"></i> Edit</Link>
                                    </div>
                                </div>
                                <div className="doctor-widget">
                                    <div className="doc-info-left">
                                        <div className="doctor-img">
                                            <div className="small-12 medium-2 large-2 columns">
                                                <div className="circle_profile mb-3">
                                                    <img className="profile-pic" src={require('../../../assets/images/home/doctor.png').default} />
                                                    <div className="profile_image">
                                                        <i className="fa fa-camera upload-button"></i>
                                                        <input className="file-upload" type="file" accept="image/*" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="doc-info-cont">
                                            <h5>Dr. Jack <span className='text-primary'>(ID: 5474)</span></h5>
                                            <p className="doc-speciality"><i className="fas fa-phone-alt"></i> +91 123654789</p>
                                            <p className="doc-department"><i className="fas fa-envelope"></i> demo@gmail.com</p>
                                            <a href="#" className="doc-department text-primary"><i className="fas fa-key"></i> Change Password</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-body">
                                <nav className="user-tabs mb-4">
                                    <ul className="nav nav-tabs nav-tabs-bottom">
                                        <li className="nav-item">
                                            <Link className="nav-link active rounded-left" to="#doc_overview" data-bs-toggle="tab">Education Qualification</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link rounded-right" to="#doc_clinic_hours" data-bs-toggle="tab">Doctor Timing</Link>
                                        </li>
                                    </ul>
                                </nav>

                                <div className="tab-content pt-0">
                                    <div role="tabpanel" id="doc_overview" className="tab-pane fade show active">
                                        <div className="row">
                                            <div className="col-md-4 col-lg-4">
                                                <div className="widget education-widget">
                                                    <h4 className="widget-title">Education</h4>
                                                    <div className="experience-box">
                                                        <ul className="experience-list">
                                                            <li>
                                                                <div className="experience-user">
                                                                    <div className="before-circle"></div>
                                                                </div>
                                                                <div className="experience-content">
                                                                    <div className="timeline-content">
                                                                        <Link to="#/" className="name">Dental Medical
                                                                            University</Link>
                                                                        <div>BDS</div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="experience-user">
                                                                    <div className="before-circle"></div>
                                                                </div>
                                                                <div className="experience-content">
                                                                    <div className="timeline-content">
                                                                        <Link to="#/" className="name">Dental Medical
                                                                            University</Link>
                                                                        <div>MDS</div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="service-list">
                                                    <h4 className='widget-title'>Specializations</h4>
                                                    <ul className="clearfix">
                                                        <li>Children Care</li>
                                                        <li>Dental Care</li>
                                                        <li>Oral and Maxillofacial Surgery </li>
                                                        <li>Orthodontist</li>
                                                        <li>Periodontist</li>
                                                        <li>Prosthodontics</li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className='col-md-12 mb-4'>
                                                <h4 className='widget-title'>Registration Details</h4>
                                                <div className="row ml-4">
                                                    <div className="col-md-4">
                                                        <p className='fw-bold'>Registration No.</p>
                                                        <p>ADR123654</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <p className='fw-bold'>Registration Council</p>
                                                        <p>Delhi Medical Council</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <p className='fw-bold'>Registration Type</p>
                                                        <p>Specialist</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <h4 className='widget-title'>Uploaded Documents</h4>
                                                <div className='row'>
                                                    <div className="col-md-2">
                                                        <div className="card">
                                                            <div className="card-body text-center">
                                                                <img className='m-auto' src={require('../../../assets/images/doc.png').default} width='80' alt="documents" />
                                                                <p>Regitration Detail</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="card">
                                                            <div className="card-body text-center">
                                                                <img className='m-auto' src={require('../../../assets/images/doc.png').default} width='80' alt="documents" />
                                                                <p>Degree</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="card">
                                                            <div className="card-body text-center">
                                                                <img className='m-auto' src={require('../../../assets/images/doc.png').default} width='80' alt="documents" />
                                                                <p>Degree</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div role="tabpanel" id="doc_clinic_hours" className="tab-pane fade">
                                        <ul className="nav nav-tabs border-0 w-100">
                                            <li className="nav-item w-50">
                                                <a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime" role="tab" aria-controls="general" aria-selected="true">General Time <br/>
                                                    <small>(First come, First Served - Waiting time visible - Avg time visible) (Online + In-person)</small>
                                                </a>
                                            </li>
                                            <li className='nav-item w-50'>
                                                <a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime" role="tab" aria-controls="slot" aria-selected="true">Slot time <br/>
                                                    <small>(priority fixed appointments) (Online + In-person)</small>
                                                </a>
                                            </li>
                                        </ul>

                                        <div className="tab-content p-2">
                                            <div id="generalTime" className="tab-pane fade show active" role="tabpanel" aria-labelledby="general-time">
                                                <div className="row">
                                                    <div className="col-md-6 offset-md-3">
                                                        <div className="widget business-widget">
                                                            <div className="widget-content">
                                                                <div className="listing-hours">
                                                                    <div className="card-header">
                                                                        <div className="listing-day current mb-0 pb-0">
                                                                            <div className="day">Today
                                                                            </div>
                                                                            <div className="time-items">
                                                                                <span className="open-status"><span
                                                                                        className="badge success-status mb-1">Open
                                                                                        Now</span></span>
                                                                                <span className="time">07:00 AM - 09:00 PM</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="listing-day">
                                                                            <div className="day">Monday</div>
                                                                            <div className="time-items">
                                                                                <span className="time">07:00 AM - 09:00 PM</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="listing-day">
                                                                            <div className="day">Tuesday</div>
                                                                            <div className="time-items">
                                                                                <span className="time">07:00 AM - 09:00 PM</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="listing-day">
                                                                            <div className="day">Wednesday</div>
                                                                            <div className="time-items">
                                                                                <span className="time">07:00 AM - 09:00 PM</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="listing-day">
                                                                            <div className="day">Thursday</div>
                                                                            <div className="time-items">
                                                                                <span className="time">07:00 AM - 09:00 PM</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="listing-day">
                                                                            <div className="day">Friday</div>
                                                                            <div className="time-items">
                                                                                <span className="time">07:00 AM - 09:00 PM</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="listing-day">
                                                                            <div className="day">Saturday</div>
                                                                            <div className="time-items">
                                                                                <span className="time">07:00 AM - 09:00 PM</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="listing-day closed">
                                                                            <div className="day">Sunday</div>
                                                                            <div className="time-items">
                                                                                <span className="time"><span
                                                                                        className="badge danger-status">Closed</span></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div id="slotTime" className="tab-pane fade" role="tabpanel" aria-labelledby="slot-time-tab">
                                                    <div className="profile-box">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <div className="session-time flex-wrap">
                                                                            <div>
                                                                                <h6 className="mb-0 me-3 fw-bold">Slot time / Average consultation time</h6>
                                                                                <p>20 Min</p>
                                                                            </div>
                                                                            <div>
                                                                                <h6 className="mb-0 w-100 mr-2 fw-bold">Consultation hours Start: </h6>
                                                                                9:00AM
                                                                            </div>
                                                                            <div>
                                                                                <h6 className="mb-0 w-100 mr-2 fw-bold">Consultation hours End: </h6>
                                                                                5:00PM
                                                                            </div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="card shadow border-0 mt-3 overflow-hidden selectTimeSlot">
                                                                            <div className="card-body p-0">
                                                                                <div className="row">
                                                                                    <div className="col-md-6 p-4">
                                                                                        <div className="calendar-wrap">
                                                                                            <Calendar
                                                                                                onChange={this.onChange}
                                                                                                value={this.state.date}
                                                                                            />
                                                                                            <h5 className="card-title mt-2 mb-2">Timezone</h5>
                                                                                            <select className="form-control select2">
                                                                                                <option>UTC -07:00 Pacific Time (US & Canada)</option>
                                                                                                <option>UTC -08:00 Arizona Time (US & Canada)</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-6 p-4">
                                                                                        <div className="d-grid gap-2 timeSlot">
                                                                                            <h5 className="card-title">Available Time slot</h5>
                                                                                            <div className='confirmBtn'>
                                                                                                <button type="button" className="selectSlot btn btn-outline-secondary change">9:00 AM</button>
                                                                                            </div>
                                                                                            <div className='confirmBtn'>
                                                                                                <button type="button" className="selectSlot btn btn-outline-secondary change">9:15 AM</button>
                                                                                            </div>
                                                                                            <div className='confirmBtn'>
                                                                                                <button type="button" className="selectSlot btn btn-outline-secondary change">9:30 AM</button>
                                                                                            </div>
                                                                                            <div className='confirmBtn'>
                                                                                                <button type="button" className="selectSlot btn btn-outline-secondary change">9:45 AM</button>
                                                                                            </div>
                                                                                            <div className='confirmBtn'>
                                                                                                <button type="button" className="selectSlot btn btn-outline-secondary change">10:00 AM</button>
                                                                                            </div>
                                                                                            <div className='confirmBtn'>
                                                                                                <button type="button" className="selectSlot btn btn-outline-secondary change">10:15 AM</button>
                                                                                            </div>
                                                                                            <div className='confirmBtn'>
                                                                                                <button type="button" className="selectSlot btn btn-outline-secondary change">10:30 AM</button>
                                                                                            </div>
                                                                                            <div className='confirmBtn'>
                                                                                                <button type="button" className="selectSlot btn btn-outline-secondary change">10:45 AM</button>
                                                                                            </div>
                                                                                            <div className='confirmBtn'>
                                                                                                <button type="button" className="selectSlot btn btn-outline-secondary change">11:00 AM</button>
                                                                                            </div>
                                                                                            <div className='confirmBtn'>
                                                                                                <button type="button" className="selectSlot btn btn-outline-secondary change">11:15 AM</button>
                                                                                            </div>
                                                                                            <div className='confirmBtn'>
                                                                                                <button type="button" className="selectSlot btn btn-outline-secondary change">11:30 AM</button>
                                                                                            </div>
                                                                                            <div className='confirmBtn'>
                                                                                                <button type="button" className="selectSlot btn btn-outline-secondary change">11:45 AM</button>
                                                                                            </div>
                                                                                            <div className='confirmBtn'>
                                                                                                <button type="button" className="selectSlot btn btn-outline-secondary change">12:00 AM</button>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


export default Doctorprofile