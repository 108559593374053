
import React, { Component } from 'react';
import HospitalFilterComponent from '../../Search/SearchFilter/HospitalFilter';
import { UserAppointment } from '../../../mock/UserAppointment';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import AppointmentFilterComponent from '../../Search/SearchFilter/AppointmentFilter';
import MyAppointmentList from '../../../mock/MyAppointmentList';
import MyAppointmentListComponent from './MyAppointmentList';

class MyAppointmentComponent extends Component {

    dirtyCount = 0;
    url = '';
    constructor(props) {
        super(props);
        this.state = {
            searchFilter: {
                hospitalId: 0,
                locationId: 0,
                dayId: 0,
                from: '',
                showList: false,
            },
            userAppointment: UserAppointment,
            myAppointmentList: []
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(prevState.searchFilter.showList);
        // console.log("new state"+this.state.searchFilter.showList);
        // console.log(prevProps.location.state.link.to);
        // console.log("new prop"+this.props.location.state.link.to);

        if (this.props.location.state.key !== prevProps.location.state.key) {
            let searchFilter = { ...this.state.searchFilter, showList: false };
            this.setState({ searchFilter: searchFilter });
        }

        // if(this.props.location.state.link.to === "/user/myappointment" &&  this.state.searchFilter.showList === true){
        //     let searchFilter={...this.state.searchFilter,showList:false};
        //     this.setState({searchFilter:searchFilter});
        //     //prevProps.location.state=undefined;
        //     //this.props.location.state.link.to='';
        //     //this.dirtyCount=0;
        // }
        // console.log(" did update called"+prevProps.location.state.link.to)
    }


    selectAppointment = (val) => {
        console.log('fiilter selected ');
    }


    openGoogemap = (e, userAppointment) => {
        if (userAppointment.doctor != null && userAppointment.doctor != undefined) {
            window.open("https://maps.google.com/?q=" + userAppointment.doctor.lattitude + "," + userAppointment.doctor.longitude);
        }

    }

    selectedFilter = (selectedVal) => {

        let hospitalId = selectedVal.hospital;
        let locationId = selectedVal.location;
        let dayId = selectedVal.day;

        let searchFilter = { ...this.state.searchFilter, hospitalId: hospitalId, locationId: locationId, dayId: dayId, showList: true };
        this.setState({
            ...this.state, searchFilter: searchFilter,
            myAppointmentList: MyAppointmentList.filter((doctor) => {
                if ((hospitalId == 0 || searchFilter.hospitalId == doctor.hospital.id)
                    && (locationId == 0 || searchFilter.locationId == doctor.location.id)
                    && (dayId == 0 || searchFilter.dayId == doctor.day.id)
                ) {
                    return true;
                }
            })
        });
        //this.url=this.props.location.state.link.to;
        this.dirtyCount = 1;
    }

    render() {

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 d-none d-md-block">
                        <HospitalFilterComponent onSelectFilter={this.selectedFilter} />
                    </div>
                </div>
                <div className="direction_row">
                    <div className="d-flex">
                        <AppointmentFilterComponent onSelectAppointment={this.selectAppointment} />
                        <p className="w-100 p-2"><strong>Apollo Delhi Hospital</strong></p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="m-4 card p-2 shadow-lg">
                        <p className='text-center h5 text-primary'>Slot time appointment</p>
                        {this.state.searchFilter.showList === false ?
                            <div className="dashboard_box">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className="box">
                                        <div className='mb-3'>
                                            <h5 className='fw-bold'>Online Appointment</h5>
                                            <Link className='text-primary'>Google meet Link : sdv-ert-hgn</Link>
                                        </div>

                                    </div>

                                    <div className="box">
                                        <div className='mb-3'>
                                            <p className="badge badge-pill badge-success">Upcoming</p>
                                            <div>
                                                <p>Appointment Date - </p>
                                                <p className="waiting">13/09/2022</p>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="box" >
                                        <p>Slot Time</p>
                                        <p className="waiting">11:30am</p>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className="box">
                                        <div className="mb-3">
                                            <p>Patient Name</p>
                                            <p className="waiting">{this.state.userAppointment.patientName}</p>
                                        </div>
                                        <div>
                                            <p><strong>ID Number : </strong>{this.state.userAppointment.id}</p>
                                            <p> Status - {this.state.userAppointment.status}</p>
                                        </div>
                                    </div>

                                    <div className="box">
                                        <div className="mb-3">
                                            <p>Appointment with</p>
                                            <p className="waiting">Dr. Jack</p>
                                        </div>
                                        <div className="text-left">
                                            <p className="sub_font mb-md-0 mb-1"><i className="fa fa-graduation-cap"></i> Dentist</p>
                                            <p className="sub_font mb-md-0 mb-1"
                                                style={{ cursor: 'pointer' }} onClick={(e) => { this.openGoogemap(e, this.state.userAppointment) }}
                                            ><i className="fa fa-map-marker mr-2"></i>Apollo Delhi</p>
                                            <Link className="btn-primary btn"
                                                onClick={(e) => { this.openGoogemap(e, this.state.userAppointment) }}
                                            >Get Direction</Link>
                                            <a className="sub_font mb-md-0 mb-1" href="tel:12345789"><i className="fa fa-mobile-alt mr-2"></i>{this.state.userAppointment.doctor.phone}</a><br/>
                                        </div>
                                    </div>

                                    <div className="box">
                                        <a href="javascript:void(0)" className='btn btn-primary mb-2' data-toggle="modal" data-target=".cancelmodel"><i className="fas fa-window-close"></i> Cancel Appointment</a>
                                        <a href='javascript:void(0)' className='btn btn-primary' data-toggle="modal" data-target="#rescheduleModal"><i className="fas fa-history"></i> Reschedule Appointment</a>
                                    </div>
                                </div>

                            </div> : <MyAppointmentListComponent doctorList={this.state.myAppointmentList} />
                        }
                        </div>

                    </div>

                    <div className="col-md-12">
                        <div className='card p-2 shadow-lg m-4'>
                            <p className='text-center h5 text-primary'>General time appointment</p>
                            {this.state.searchFilter.showList === false ?
                                <div className="dashboard_box">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className="box">
                                            <div className='mb-2'>
                                                <h5 className='fw-bold'>Offline Appointment</h5>
                                                <p>Your Waiting No.</p>
                                                <p className="waiting">{this.state.userAppointment.waitingNo}</p>
                                            </div>

                                        </div>

                                        <div className="box">
                                            <div>
                                                <p>Appointment Date - </p>
                                                <p className="waiting">{this.state.userAppointment.appointmentDate}</p>
                                            </div>
                                        </div>

                                        <div className="box" >
                                            <div className='mb-3'>
                                                <p>Estimeted Time</p>
                                                <p className="waiting">{this.state.userAppointment.estimatedTime}</p>
                                            </div>
                                            <div>
                                                <p>Avg. Time / Patient -</p>
                                                <p className="waiting">{this.state.userAppointment.avgTime}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className="box">
                                            <div className="mb-3">
                                                <p>Patient Name</p>
                                                <p className="waiting">{this.state.userAppointment.patientName}</p>
                                            </div>
                                            <div>
                                                <p><strong>ID Number : </strong>{this.state.userAppointment.id}</p>
                                                <p> Status - {this.state.userAppointment.status}</p>
                                            </div>
                                        </div>

                                        <div className="box">
                                            <div className="mb-3">
                                                <p>Available Doctor</p>
                                                <p className="waiting">{this.state.userAppointment.doctor.name}</p>
                                            </div>
                                            <div className="text-left">
                                                <p className="sub_font mb-md-0 mb-1"><i className="fa fa-graduation-cap"></i> Dentist</p>
                                                <p className="sub_font mb-md-0 mb-1"
                                                    style={{ cursor: 'pointer' }} onClick={(e) => { this.openGoogemap(e, this.state.userAppointment) }}
                                                ><i className="fa fa-map-marker mr-2"></i>{this.state.userAppointment.doctor.address}</p>
                                                <a className="sub_font mb-md-0 mb-1" href="tel:12345789"><i className="fa fa-mobile-alt mr-2"></i>{this.state.userAppointment.doctor.phone}</a>
                                            </div>
                                        </div>

                                        <div className="box">
                                            <a href="javascript:void(0)" className='btn btn-primary mb-2' data-toggle="modal" data-target=".cancelmodel"><i className="fas fa-window-close"></i> Cancel Appointment</a>
                                            <a href='javascript:void(0)' className='btn btn-primary' data-toggle="modal" data-target="#rescheduleModal"><i className="fas fa-history"></i> Reschedule Appointment</a>
                                        </div>
                                    </div>

                                </div> : <MyAppointmentListComponent doctorList={this.state.myAppointmentList} />
                            }
                        </div>

                    </div>
                </div>

                <div className="row margb_60">
                    <div className="col-md-12 text-center">
                        <div className="direction_btn d-block d-md-none">
                            <a className="btn-primary btn btn-mobile" href="#">GET DIRECTION</a>
                        </div>
                    </div>
                </div>

                {/* reschedule modal */}
                <div className="modal fade" id="rescheduleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Reschadule Appointment</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <ul className="nav nav-tabs" id="rescheduleTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="generalTime-tab" data-toggle="tab" href="#generalTime" role="tab" aria-controls="home" aria-selected="true">General Time</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="slotTime-tab" data-toggle="tab" href="#slotTime" role="tab" aria-controls="slot" aria-selected="true">Slot Time</a>
                                    </li>
                                </ul>

                                <div className="tab-content pt-2">
                                    <div className="tab-pane fade show active" id="generalTime" role="tabpanel">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="booking-doc-info d-flex justify-content-between align-items-center">
                                                            <div className="d-flex">
                                                                <a href="#" className="booking-doc-img border aounded">
                                                                    <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image"/>
                                                                </a>
                                                                <div className="booking-info">
                                                                    <h5><a href="#">Dr. Wilson</a></h5>
                                                                    <p className="doc-speciality mb-1">BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                                    <p className="doc-department"><i className="fas fa-user-md"></i> Dentist</p>
                                                                </div>
                                                            </div>

                                                            <div className="dateSelect">
                                                                <label htmlFor="select date">Select Appointment Date</label>
                                                                <input type="date"  className='form-control'/>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12 text-right">
                                                                <button className='btn btn-primary text-right'>Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="slotTime" role="tabpanel">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="booking-doc-info">
                                                            <a href="#" className="booking-doc-img border aounded">
                                                                <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image"/>
                                                            </a>
                                                            <div className="booking-info">
                                                                <h5><a href="#">Dr. Wilson</a></h5>
                                                                <p className="doc-speciality mb-1">BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                                <p className="doc-department"><i className="fas fa-user-md"></i> Dentist</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card booking-schedule schedule-widget">
                                                    <div className="schedule-header">
                                                        <div className="date-booking">
                                                            <div className="row align-items-center">
                                                                <div className="col-12 col-sm-6 col-md-4 col-lg-4">
                                                                    <h4 className="mb-0">13 September 2022</h4>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-md-2 col-lg-4 text-sm-center">
                                                                    <p className="text-muted mb-0">Tuesday</p>
                                                                </div>
                                                                <div className="col-12 col-sm-8 col-md-6 col-lg-4 text-md-end">
                                                                    <div className="bookingrange btn btn-white btn-sm">
                                                                        <i className="far fa-calendar-alt me-2"></i>
                                                                        <span></span>
                                                                        <i className="fas fa-chevron-down ms-2"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="schedule-cont">
                                                        <div className="row">
                                                            <div className="col-md-12">

                                                                <div className="day-slot">
                                                                    <ul>
                                                                        <li>
                                                                            <div className="days">
                                                                                <span>Mon</span>
                                                                                <span className="slot-date">12 Sep <small
                                                                                        className="slot-year">2022</small></span>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="days">
                                                                                <span>Tue</span>
                                                                                <span className="slot-date">13 Sep <small
                                                                                        className="slot-year">2022</small></span>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="days">
                                                                                <span>Wed</span>
                                                                                <span className="slot-date">14 Sep <small
                                                                                        className="slot-year">2022</small></span>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="days">
                                                                                <span>Thu</span>
                                                                                <span className="slot-date">15 Sep <small
                                                                                        className="slot-year">2022</small></span>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="days">
                                                                                <span>Fri</span>
                                                                                <span className="slot-date">16 Sep <small
                                                                                        className="slot-year">2022</small></span>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="days">
                                                                                <span>Sat</span>
                                                                                <span className="slot-date">17 Sep <small
                                                                                        className="slot-year">2022</small></span>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <div className="days">
                                                                                <span>Sun</span>
                                                                                <span className="slot-date">18 Sep <small
                                                                                        className="slot-year">2022</small></span>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="time-slot">
                                                                    <ul className="clearfix">
                                                                        <li>
                                                                            <a className="timing" href="#">
                                                                                <span>9:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing" href="#">
                                                                                <span>10:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing" href="#">
                                                                                <span>11:00</span> <span>AM</span>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="timing selected" href="#">
                                                                                <span>9:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing selected" href="#">
                                                                                <span>10:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing selected" href="#">
                                                                                <span>11:00</span> <span>AM</span>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="timing" href="#">
                                                                                <span>9:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing" href="#">
                                                                                <span>10:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing" href="#">
                                                                                <span>11:00</span> <span>AM</span>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="timing" href="#">
                                                                                <span>9:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing" href="#">
                                                                                <span>10:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing" href="#">
                                                                                <span>11:00</span> <span>AM</span>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="timing" href="#">
                                                                                <span>9:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing" href="#">
                                                                                <span>10:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing" href="#">
                                                                                <span>11:00</span> <span>AM</span>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="timing" href="#">
                                                                                <span>9:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing" href="#">
                                                                                <span>10:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing" href="#">
                                                                                <span>11:00</span> <span>AM</span>
                                                                            </a>
                                                                        </li>
                                                                        <li>
                                                                            <a className="timing" href="#">
                                                                                <span>9:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing" href="#">
                                                                                <span>10:00</span> <span>AM</span>
                                                                            </a>
                                                                            <a className="timing" href="#">
                                                                                <span>11:00</span> <span>AM</span>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 text-right">
                                                                <a className='btn btn-primary mt-3 text-right' href="#">Select Slot</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* general time modal */}
				<div className="modal fade" id="generalTime" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLabel">Select Appointment Date</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-12">
										<div className="card">
											<div className="card-body">
												<div className="booking-doc-info d-flex justify-content-between align-items-center">
													<div className="d-flex">
														<a href="#" className="booking-doc-img border aounded">
															<img src={require('../../../assets/images/home/doctor.png').default} alt="User Image"/>
														</a>
														<div className="booking-info">
															<h5><a href="#">Dr. Wilson</a></h5>
															<p className="doc-speciality mb-1">BDS, MDS - Oral & Maxillofacial Surgery</p>
															<p className="doc-department"><i className="fas fa-user-md"></i> Dentist</p>
														</div>
													</div>

													<div className="dateSelect">
														<label htmlFor="select date">Select Appointment Date</label>
														<input type="date"  className='form-control'/>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12 text-right">
														<button className='btn btn-primary text-right'>Save</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				{/* slot time modal */}
				<div className="modal fade" id="slotTime" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLabel">Select Appointment Slot</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-12">
										<div className="card">
											<div className="card-body">
												<div className="booking-doc-info">
													<a href="#" className="booking-doc-img border aounded">
														<img src={require('../../../assets/images/home/doctor.png').default} alt="User Image"/>
													</a>
													<div className="booking-info">
														<h5><a href="#">Dr. Wilson</a></h5>
														<p className="doc-speciality mb-1">BDS, MDS - Oral & Maxillofacial Surgery</p>
														<p className="doc-department"><i className="fas fa-user-md"></i> Dentist</p>
													</div>
												</div>
											</div>
										</div>

										<div className="card booking-schedule schedule-widget">
											<div className="schedule-header">
												<div className="date-booking">
													<div className="row align-items-center">
														<div className="col-12 col-sm-6 col-md-4 col-lg-4">
															<h4 className="mb-0">13 September 2022</h4>
														</div>
														<div className="col-12 col-sm-6 col-md-2 col-lg-4 text-sm-center">
															<p className="text-muted mb-0">Tuesday</p>
														</div>
														<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-md-end">
															<div className="bookingrange btn btn-white btn-sm">
																<i className="far fa-calendar-alt me-2"></i>
																<span></span>
																<i className="fas fa-chevron-down ms-2"></i>
															</div>
														</div>
													</div>
												</div>
											</div>


											<div className="schedule-cont">
												<div className="row">
													<div className="col-md-12">

														<div className="day-slot">
															<ul>
																<li>
																	<div className="days">
																		<span>Mon</span>
																		<span className="slot-date">12 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
																<li>
																	<div className="days">
																		<span>Tue</span>
																		<span className="slot-date">13 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
																<li>
																	<div className="days">
																		<span>Wed</span>
																		<span className="slot-date">14 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
																<li>
																	<div className="days">
																		<span>Thu</span>
																		<span className="slot-date">15 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
																<li>
																	<div className="days">
																		<span>Fri</span>
																		<span className="slot-date">16 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
																<li>
																	<div className="days">
																		<span>Sat</span>
																		<span className="slot-date">17 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
																<li>
																	<div className="days">
																		<span>Sun</span>
																		<span className="slot-date">18 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
															</ul>
														</div>

													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="time-slot">
															<ul className="clearfix">
																<li>
																	<a className="timing" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
																<li>
																	<a className="timing selected" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing selected" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing selected" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
																<li>
																	<a className="timing" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
																<li>
																	<a className="timing" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
																<li>
																	<a className="timing" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
																<li>
																	<a className="timing" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
																<li>
																	<a className="timing" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
															</ul>
														</div>
													</div>
													<div className="col-md-12 text-right">
														<a className='btn btn-primary mt-3 text-right' href="#">Select Slot</a>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


                {/* <!-- Cancel model --> */}
                <div className="modal fade cancelmodel" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancel Appointment</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row mt-2">
                                        <div className="col-md-12 text-center">
                                            <p><i className="fas fa-exclamation-circle modal_icon mb-2"></i></p>
                                            <h2>Are You Sure?</h2>
                                            <p>You Want to Cancel this Appointment?</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                                <button type="button" className="btn btn-primary cancelmodel_yesbtn" data-toggle="modal" data-target=".confirmcancelmodel">Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*
                <!-- confirm Cancel model --> */}
                <div className="modal fade confirmcancelmodel" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cancel Appointment</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row mt-2">
                                        <div className="col-md-12 text-center">
                                            <p><i className="far fa-check-circle modal_icon2 mb-2"></i></p>
                                            <h4>Your Appointment is Cancelled Successfully</h4>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="modal fade rescheduleModel" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Reschedule Appointment</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row mt-2">
                                        <div className="col-md-12 text-center">
                                            <p><i className="fas fa-exclamation-circle modal_icon mb-2"></i></p>
                                            <h2>Are You Sure?</h2>
                                            <p>You Want to Reschedule this Appointment?</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
                                <a className="btn btn-primary cancelmodel_yesbtn" href="http://krupaal.com/aajivan/user/newappointment.php"> Yes</a>
                            </div>
                        </div>
                    </div>
                </div> */}


            </React.Fragment>

        )
    }
}
export default MyAppointmentComponent;