import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/style.css';

class FooterComponent extends Component{
    render() {
        return (
            <div>
                <section className="footer_bg d-md-block d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 mt-md-5 mt-2">
                                <h5 className="footer_tile d-md-block d-none">Quick Links</h5>
                                <hr className="d-md-block d-none" />
                                <ul className="quick_ul">
                                    <li><Link to='/user/book/appointment' >Book Appointment</Link></li>
                                    <li><Link to='/howItWorks' >How It Works (FAQ)</Link></li>
                                    <li><Link to='/blog' >Blog</Link></li>
                                    <li><Link to='/aboutus' >About Us</Link></li>
                                    <li><Link to='/contactUs' >Contact Us</Link></li>
                                    <li> <Link to='/termsconditions' >Terms & Condition</Link></li>
                                    <li><Link to='/privacypolicy' >Privacy Policy</Link></li>
                                    <li><Link to='/#' >Sitemap</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-3 mt-md-5 mt-2">
                                <h5 className="footer_tile d-md-block d-none">Contact Imformation</h5>
                                <hr className="d-md-block d-none" />
                                    <ul className="contact">
                                        <li><strong>Phone : </strong><a href="tel:+91-123456789" title="+91-123456789"> <span> +91-123456789</span></a>
                                        </li>

                                        <li><i className="fa fa-envelope-o text-color-color"></i><strong>Email : </strong><a href="mailto:contact.aajivan@gmail.com" title="contact.aajivan@gmail.com"><span>  aajivan@gmail.com</span></a>
                                        </li>
                                    </ul>
                            </div>
                            <div className="col-md-3 mt-md-5 mt-2">
                                <h5 className="footer_tile d-md-block d-none">Follow Us </h5>
                                <hr className="d-md-block d-none" />
                                <ul className="social-icons">
                                    <li className="social_i_facebook bacground"><a href="/#" target="_blank" title="Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                    <li className="social_i_twitter bacground"><a href="/#" target="_blank" title="Twitter"><i className="fab fa-twitter"></i></a></li>
                                    <li className="social_i_linkedin bacground"><a href="/#" target="_blank" title="linkedin"><i className="fab fa-linkedin-in"></i></a></li>
                                </ul>
                                <br />
                                <ul className="social-icons">
                                    <li className="social_i_instagram bacground"><a href="/#" target="_blank" title="instagram"><i className="fab fa-instagram"></i></a></li>
                                    <li className="social_i_pinterest bacground"><a href="/#" target="_blank" title="pinterest"><i className="fab fa-pinterest" aria-hidden="true"></i></a></li>
                                    <li className="social_i_youtube bacground"><a href="/#" target="_blank" title="youtube"><i className="fab fa-youtube"></i></a></li>
                                </ul>
                            </div>
                            <div className="col-md-3 mt-md-5 mt-2 text-center">
                                <h5 className="footer_tile d-md-block d-none">Join Us on Facebook</h5>
                                <hr className="d-md-block d-none" />
                                <iframe title="facebook" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Faajivan%2F&tabs=timeline&width=200&height=200&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="200" height="200"></iframe>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="copyright d-md-block d-none">
                    <div className="col-md-12 text-center">
                        <p className="copyright_p">
                            <a href="https://www.holo.in.net/">&copy; Copyright 2022 by Holo.in.net. All Rights Reserved</a></p>
                    </div>
                </section>

                {/* delete modal */}
                <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header p-2">
                                <h5 class="modal-title">Delete</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body text-center">
                                <h5>Are you sure?</h5>
                                <small>You want to delete this.</small>
                            </div>
                            <div class="modal-footer p-2">
                                <button type="button" class="btn btn-primary">Yes</button>
                                <button type="button" class="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}
export default FooterComponent;