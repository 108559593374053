import React from 'react';
import { connect } from 'react-redux';

import { Link } from "react-router-dom";

import { getAppointmentsCountAction, getStaffListByDateAction, getPatientPrescriptionAction } from 'src/redux/Main/actions';
import { useCookies } from 'react-cookie';
const AllPrescriptionResults = (props) => {

    console.log("AllPrescriptionResults", props)

    return (
    <div className="col-md-6 mb-2">
        <Link to={{
            pathname: '/user/prescription',
            state: {
                doctorId: props.prescription.id,
                patientId: props.prescription.patient.id
            }
        }} key={props.prescription.id} >
            <div className="card">
                <div className="d-flex justify-content-between align-items-center box_bg">
                    <div className='d-flex align-items-center'>
                        <div className="pr-1 pt-1 img_block text-center">
                            <img src={require('../../../assets/images/home/doctor.png').default} width="80" alt="User Image"/>
                        </div>
                        <div className="pr-0 docinfo">
                            <p className="title_name mb-md-0 mb-2"><strong> {props.prescription.name}</strong> (<span className="font_13">Prescription Number:{props.prescription.id}</span>)</p>
                            <p className="sub_font mb-md-0 mb-1"><i className="fa fa-graduation-cap"></i> Dentist</p>
                            <p className="sub_font mb-md-0 mb-1"><i className="fa fa-map-marker mr-2"></i>  {props.prescription.hospital.name}</p>
                            <p className="sub_font mb-md-0 mb-1"><i className="fa fa-mobile-alt mr-2"></i> {props.prescription.phone}</p>
                        </div>
                    </div>

                    <div className="mt-2">
                        <p className="mb-1 title_name"><strong>{props.prescription.appointmentDay}</strong></p>
                        <p className="title_name mb-2"><i className="far fa-clock"></i> <strong>{props.prescription.appointmentTime}</strong></p>
                        <p className="title_name"><i className="far fa-hospital"></i> <strong>{props.prescription.address}</strong></p>
                        <p className="title_name mb-2" href="#"><strong>{props.prescription.patient.name}</strong></p>
                    </div>
                </div>
            </div>
        </Link>
    </div>

    );

}


const mapDispatchToProps = dispatch => ({
	getPatientPrescriptionAction: (params) => dispatch(getPatientPrescriptionAction(params)),

})

const mapStateToProps = state => {
	return {
		prescription:
			state.mainReducer?.patientPrescriptionReducer?.response?.data || [],
		staffList:
			state.mainReducer?.getStaffListByDateReducer?.response?.data || [],

	}
}


export default  connect(mapStateToProps, mapDispatchToProps) (AllPrescriptionResults);