import React, { Component } from 'react';
import { Link   } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"

class Editappointmentdetail extends React.Component{

	componentDidMount() {
        document.title = "Edit Appointment detail"

	}

	render(){
		return(
			<>
				<div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar dct-dashbd-lft">
                            <div className="card widget-profile pat-widget-profile mb-3">
                                <div className="card-body">
                                    <div className="pro-widget-content">
                                        <div className="profile-info-widget">
                                            <a href="#" className="booking-doc-img">
                                                <img src={require('../../../assets/images/doctor.jpg').default} width="80" alt="User Image"/>
                                            </a>
                                            <div className="profile-det-info">
                                                <h3>Dr. Jack</h3>
                                                <div className="patient-details">
                                                    <h5><b>BDS, MDS - Oral & Maxillofacial Surgery</b></h5>
                                                    <h5 className="mb-0"><i className="fas fa-graduation-cap"></i> Dentist</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="patient-info">
                                        <ul>
                                            <li>Hospital <span>Apollo Delhi</span></li>
                                            <li>Address <span>Delhi</span></li>
                                            <li>Phone <span>+1 1236547895</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-8 col-xl-9 dct-appoinment">
                            <div className="card p-2">
                                <div className="row">
                                    <div className="col-md-12">
                                        <nav className="user-tabs mb-3">
                                            <ul className="nav nav-tabs nav-tabs-bottom">
                                                <li className="nav-item">
                                                    <Link className="nav-link active rounded-left" to="#patientProfile" data-bs-toggle="tab">Patient Profile</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to="#diagnosis" data-bs-toggle="tab">Diagnosis</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to="#prescription" data-bs-toggle="tab">Prescription</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link rounded-end" to="#invoice" data-bs-toggle="tab">Invoice</Link>
                                                </li>
                                            </ul>
                                        </nav>

                                        <div className="tab-content pt-0">
                                            <div role="tabpanel" id="patientProfile" className="tab-pane fade show active">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <ul className='liveappointmentDetail'>
                                                            <li>
                                                                <img src={require('../../../assets/images/profile_circle-1.png').default} width="150" alt="User" />
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="col-md-5">
                                                        <ul className='liveappointmentDetail editAppointment'>
                                                            <li className="d-flex">
                                                                <span className='w-50'><strong>Appintment Date : </strong></span>
                                                                <input type="date" className='form-control w-50' value='25/03/2019' />
                                                            </li>
                                                            <li className="d-flex">
                                                                <span className='w-50'><strong>Doctor Name : </strong></span>
                                                                <input type="text" className='form-control w-50' value="Dr. Jack" placeholder='Enter Doctor name' />
                                                            </li>
                                                            <li className="d-flex">
                                                                <span className='w-50'><strong>Phone : </strong></span>
                                                                <input type="text" className='form-control w-50' value="12345+7899" placeholder='Enter Phone' />
                                                            </li>
                                                            <li className="d-flex">
                                                                <span className='w-50'><strong>DOB : </strong></span>
                                                                <input type="date" className='form-control w-50' value='13/05/1981' />
                                                            </li>
                                                            <li className="d-flex">
                                                                <span className='w-50'><strong>Address : </strong></span>
                                                                <input type="text" className='form-control w-50' value="Delhi, India" placeholder='Enter Address' />
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="col-md-5">
                                                        <ul className='liveappointmentDetail editAppointment'>
                                                            <li className="d-flex">
                                                                <span className='w-50'><strong>Time Slot : </strong></span>
                                                                <input type="time" className='form-control w-50' value='' />
                                                            </li>
                                                            <li className="d-flex">
                                                                <span className='w-50'><strong>Blood Group : </strong></span>
                                                                <select className='form-control w-50'>
                                                                    <option value="1">A+</option>
                                                                    <option value="2">B+</option>
                                                                    <option value="3">AB+</option>
                                                                    <option value="4">O+</option>
                                                                    <option value="5">A-</option>
                                                                    <option value="6">B-</option>
                                                                    <option value="7">AB-</option>
                                                                    <option value="8">O-</option>
                                                                </select>
                                                            </li>
                                                            <li className="d-flex">
                                                                <span className='w-50'><strong>Police Case : </strong></span>
                                                                <select className='form-control w-50'>
                                                                    <option value="1">Yes</option>
                                                                    <option value="2">No</option>
                                                                </select>
                                                            </li>
                                                            <li className="d-flex">
                                                                <span className='w-50'><strong>Urgency Type : </strong></span>
                                                                <select className='form-control w-50'>
                                                                    <option value="1">Normal</option>
                                                                    <option value="2">Emergency</option>
                                                                </select>
                                                            </li>
                                                            <li className="d-flex">
                                                                <span className='w-50'><strong>Case Type : </strong></span>
                                                                <select className='form-control w-50'>
                                                                    <option value="1">New</option>
                                                                    <option value="2">Old</option>
                                                                </select>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div role="tabpanel" id="diagnosis" className="tab-pane fade">
                                                <h5 className='text-primary'><strong>Diagnosis</strong></h5>
                                                <textarea type="text" className='form-control'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad quidem placeat nostrum ipsum dolorem ipsam beatae laboriosam nulla temporibus, consequatur deserunt repellendus harum consequuntur? Architecto quos ullam molestiae ab iusto!</textarea>
                                            </div>

                                            <div role="tabpanel" id="prescription" className="tab-pane fade">
                                                <div className="table-responsive">
                                                    <table className="table table-hover table-white m-0">
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Medicine Name</th>
                                                                <th>Days</th>
                                                                <th>Timing</th>
                                                                <th>Comment</th>
                                                                <th> </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="add_pre_row11">
                                                                <td>1</td>
                                                                <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Name"
                                                                    name="name"
                                                                />
                                                                </td>
                                                                <td>
                                                                    <div className="form-group">
                                                                        <input type="text" className="form-control" aria-describedby="helpId" placeholder="Enter Days"/>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="form-group">
                                                                        <select className="form-control">
                                                                            <option>Select Timing</option>
                                                                            <option>1-1-1</option>
                                                                            <option>1-0-1</option>
                                                                        </select>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Comment"
                                                                    name="comment"
                                                                />
                                                                </td>
                                                                <td>
                                                                    <a className="btn btn-primary mr-2" title="Add"><i className="fa fa-plus"></i></a>
                                                                    <a className="btn btn-danger" title="Delete"><i className="fa fa-minus"></i></a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div role="tabpanel" id="invoice" className="tab-pane fade">
                                                <div className="table-responsive">
                                                    <table class="table text-center table_pad">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr No.</th>
                                                                <th>Description</th>
                                                                <th>Unit Cost</th>
                                                                <th>Quantity</th>
                                                                <th>Amount($)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>
                                                                    <input type="text" className='form-control' value="Dolo 200" placeholder='Medicine Name'/>
                                                                </td>
                                                                <td>
                                                                    <input type="number" className='form-control' value="3" placeholder='Unit Cost'/>
                                                                </td>
                                                                <td>
                                                                    <input type="number" className='form-control' value="50" placeholder='Quantity'/>
                                                                </td>
                                                                <td>
                                                                    <input type="number" className='form-control' value="150" placeholder='Amount ($)'/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>
                                                                    <input type="text" className='form-control' value="Dolo 200" placeholder='Medicine Name'/>
                                                                </td>
                                                                <td>
                                                                    <input type="number" className='form-control' value="3" placeholder='Unit Cost'/>
                                                                </td>
                                                                <td>
                                                                    <input type="number" className='form-control' value="50" placeholder='Quantity'/>
                                                                </td>
                                                                <td>
                                                                    <input type="number" className='form-control' value="150" placeholder='Amount ($)'/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>
                                                                    <input type="text" className='form-control' value="Dolo 200" placeholder='Medicine Name'/>
                                                                </td>
                                                                <td>
                                                                    <input type="number" className='form-control' value="3" placeholder='Unit Cost'/>
                                                                </td>
                                                                <td>
                                                                    <input type="number" className='form-control' value="50" placeholder='Quantity'/>
                                                                </td>
                                                                <td>
                                                                    <input type="number" className='form-control' value="150" placeholder='Amount ($)'/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td class="small"><b>Sub Total</b></td>
                                                                <td class="small">450</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td class="small"><b>Discount</b></td>
                                                                <td class="small">
                                                                    <input type="number" className='form-control text-center' value="10" placeholder='Discount'/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td class="small"><b>Tax</b></td>
                                                                <td class="small">
                                                                    <input type="number" className='form-control text-center' value="100" placeholder='Tax'/>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td><b>Total</b></td>
                                                                <td class="fw-bold text-primary">540</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</>
		)
	}
}


export default Editappointmentdetail