
import React, { Component } from 'react';
import * as $ from 'jquery';
import HospitalFilterComponent from  '../../Search/SearchFilter/HospitalFilter';
import {NearByDoctors} from '../../../mock/NearByDoctors';
import NearByResults from './NearByResults';

class NearByComponent extends Component{

    constructor(props) {
        super(props);
        this.state = {
          nearByDoctors: NearByDoctors
        }
      }

    componentDidMount(){

    }
    selectedFilter = (selectedVal) => {
        console.log(" menu selescted ");
     }


    render() {
        return (
        <React.Fragment>
            <div className="d-block d-md-none">
                <div styles="background: #eee;" className="row d-flex justify-content-between">
                    <div className="mt-2 mb-1">
                        <select className="form-control form-size">
                            <option value="">Bngalore</option>
                        </select>
                    </div>
                    <div className="mt-2 mb-1">
                        <select className="form-control form-size">
                            <option value="">By Insurance</option>
                            <option value="">Insurance 1</option>
                            <option value="">Insurance 2</option>
                            <option value="">Insurance 3</option>
                            <option value="">Insurance 4</option>
                        </select>
                    </div>
                    <div className="mt-2 mb-1">
                        <select className="form-control form-size">
                            <option value="">Today</option>
                        </select>
                    </div>

                </div>
            </div>

            <div className="row">
                <div className="col-md-12 d-none d-md-block">
                    <HospitalFilterComponent onSelectFilter={this.selectedFilter} />
                </div>

                <div className="col-md-12 mt-md-0 mt-1">
                    <div className="col-md-12 pr-md-0 mb-2 mt-3">
                        <form className="desk_form">
                            <i className="fa fa-search search_icon"></i>
                            <input className="pl-5 location form-control" list="specialization" name="browser" placeholder="Search Doctor / Hospital" />
                            <datalist id="specialization">
                                <option value="Dentist" />
                                <option value="Dermetologist" />
                                <option value="Orthopedic Surgeon" />
                                <option value="Cardiologist" />
                                <option value="Neurologist" />
                                <option value="Padiatrician" />
                                <option value="Gynecologist" />
                                <option value="Sexologyst(M/F)" />
                                <option value="ENT Specialist" />
                                <option value="EYE Specialist" />
                                <option value="Hair Transplant" />
                                <option value="Animal Doctor" /> </datalist>
                        </form>
                    </div>

                    <div className="row">
                        {(this.state.nearByDoctors !== undefined && this.state.nearByDoctors !== null && this.state.nearByDoctors.length > 0) ?
                        this.state.nearByDoctors.map((doctor, i) => {
                            return <NearByResults key={doctor.id} doctor={doctor} ></NearByResults>
                        }) :''}
                    </div>

                </div>
            </div>
        </React.Fragment>

        )
    }
}
export default NearByComponent;