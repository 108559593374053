import React, { Component, useEffect, useState } from 'react';
import { Link, Route, withRouter, useHistory } from 'react-router-dom';
import UserOTPComponent from './UserOTP/UserOtp';
import './UserLogin.css';
import * as $ from 'jquery';

import { getCountryCodes, userLogin } from '../../../redux/Main/actions';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Autocomplete, TextField, Box, FormControl, OutlinedInput, InputAdornment, IconButton, Button, Grid } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
const UserLoginComponent = (props) => {
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['RoleID']);

  const [state, setState] = useState({
    dial_code: null,
    mobile: null,
    password: null,
    remember: false,
    isOtp: false,
    isOtpSent: false,
    LoginWithOtp: false,
  })

  const [loggedIn, setLoggedIn] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [autoCompleteValue, setAutoCompleteValue] = useState({ name: "", value: "" })

  useEffect(() => {
    props.getCountryCodes({});
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        if (data) {
          setAutoCompleteValue({ name: data.country_name?.toUpperCase(), value: data.country_calling_code?.slice(1) })
          setState({
            ...state,
            dial_code: data.country_calling_code?.slice(1),
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
    return () => setLoggedIn(false)

  }, [])

  useEffect(async () => {

    if (props.RoleID && loggedIn) {
      await setCookie('RoleID', props.RoleID)
      setLoggedIn(false)
    }

    const { RoleID = null } = cookies || {}
    if (RoleID) {
      history.push("/user/dashboard");

    }

    return () => setLoggedIn(false)


  }, [props.RoleID, loggedIn, cookies])

  const handleClickShowPassword = () => setShowPassword((show) => !show);


  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const stateCopy = { ...state }
    if (name === 'dial_code') {
      stateCopy['dial_code_error'] = false;
      stateCopy['dial_code'] = value;


    }
    if (name === 'password') {
      stateCopy['psw_error'] = false;
      stateCopy['password'] = value;

    }
    if (name === 'mobile') {
      stateCopy['mobile_error'] = false;
      stateCopy['mobile'] = value;
    }

    setState(stateCopy)

  }


  const loginHandler = () => {
    setLoggedIn(true)
    const { dial_code = null, mobile = null, password = null, isOtp = false } = state || {}

    let errors = {}
    let errCount = 0;

    if (!dial_code) {
      errors['dial_code_error'] = true;
      errCount++;
    }
    if (!mobile) {
      errors['mobile_error'] = true
      errCount++;
    }


    if (isOtp) {
      setState({ ...state, isOtpSent: true, ...errors })
      return;
    } else {
      if (!password) {
        errors['psw_error'] = true
        errCount++;
      }
    }

    console.log(errors)

    if (errCount > 0) {
      setState({
        ...state,
        ...errors
      })

      return;
    }


    const params = {
      dial_code: dial_code,
      mobile: mobile,
      password: password,
    };
    props.onLogin(params)
  }

  const { countriesList = [] } = props || {}

  return (
    <section className="login_area pt-md-5 pt-0">
      <div className="container">
        {!state.isOtpSent
          ?
          <div className="row justify-content-center">
            <div className="col-md-6 d-flex align-items-end justify-content-center order-md-1 order-2">
              <img src={require('../../../assets/images/home/doctors.png').default} alt="1" />
            </div>

            <div className="col-md-6 order-md-2 order-1">
              <div id="msform" className='login'>
                <h4 className='mb-2 h2 fw-bold'>Welcome to Aajivan</h4>
                <p className='mb-2'>Enter Your Phone Number and we will send you an OTP</p>
                <form id="userLogin" className="w-100 m-auto needs-validation" noValidate>
                  <label htmlFor="">Mobile Number</label>
                  <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={12} sm={12} md={4}>
                      <Autocomplete
                        id="country-select-demo"
                        options={countriesList}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          if (value) {
                            const stateCopy = { ...state }
                            stateCopy['dial_code_error'] = false;
                            stateCopy['dial_code'] = value.phonecode;
                            setState(stateCopy)
                            setAutoCompleteValue({ name: value.name, value: value.phonecode })
                          }
                        }}
                        value={autoCompleteValue}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose a country"
                            size='small'
                            error={state.dial_code_error}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <TextField
                        placeholder='Mobile Number'
                        value={`+ ${state.dial_code}`}
                        name='mobile'
                        size='small'
                        fullWidth
                        disabled
                        error={state.mobile_error}
                        onChange={onChangeHandler}

                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        placeholder='Mobile Number'
                        value={state.mobile}
                        name='mobile'
                        fullWidth size='small'
                        error={state.mobile_error}
                        onChange={onChangeHandler}

                      />
                    </Grid>
                  </Grid>

                  <div className="form-group mt-2">
                    <div htmlFor="">Password</div>
                    <Grid container>

                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl fullWidth>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            size="small"
                            disabled={state.isOtp}
                            fullWidth
                            error={state.psw_error}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                  </div>

                  <div className="form-check d-flex justify-content-between align-items-center">
                    <input className="form-check-input" type="checkbox" value="" id="remember" checked={state.remember} onChange={(e) => setState({ ...state, remember: e.target.checked })} />
                    <label className="form-check-label small m-0" htmlFor="remember">
                      Remember me
                    </label>
                    <Link to="/userforgotpassword" className='small customAnchor'>Forgot Password?</Link>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="withOtp" checked={state.isOtp} name='isOtp' onChange={(e) => setState({ ...state, isOtp: e.target.checked })} />
                    <label className="form-check-label small" htmlFor="withOtp">
                      Login with OTP instead of password
                    </label>
                  </div>

                  <br />
                  <Button
                    onClick={loginHandler}
                    className='primary-button w-100 mb-2'
                  >
                    Login
                  </Button>

                  <p className="text-muted">Don't have a account? <Link to="./usersignup" className="customAnchor"> Sign up</Link> </p>
                </form>
              </div>
            </div>
          </div>
          :
          <UserOTPComponent info={state} countries={countriesList} setLoggedIn={setLoggedIn} />
        }
      </div>
    </section>

  )
}

const mapDispatchToProps = dispatch => ({
  onLogin: params => dispatch(userLogin(params)),
  getCountryCodes: params => dispatch(getCountryCodes(params))
})

const mapStateToProps = state => {
  return {
    // countriesList: state.mainReducer?.getCountryCodesReducer?.response?.data?.map(({ phonecode, iso3 }) => ({ name: iso3, value: phonecode })) || [],
    countriesList: state.mainReducer?.getCountryCodesReducer?.response?.data || [],
    RoleID: state.mainReducer?.loginReducer?.response?.data[0]?.roleId || null,
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserLoginComponent));