import React, { Component } from 'react';
import { Link, Route, withRouter } from 'react-router-dom';


class UserForgotpassword extends Component{
    render() {
        return (
            <section className="login_area pt-md-5 pt-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 d-flex align-items-end justify-content-center order-md-1 order-2">
                            <img src={require('../../../assets/images/home/doctors.png').default} alt="1"/>
                        </div>

                        <div className="col-md-6 order-md-2 order-1">
                            <div id="msform" className='login'>
                                <h4 className='mb-2 h2 fw-bold'>Forgot Password</h4>
                                <p className='mb-4'>Enter Your Phone Number and we will send you an OTP</p>
                                <form className="w-100 m-auto">
                                    <label htmlFor="">Mobile Number</label>
                                    <div className="input-group">
                                        <select className='form-control w-25 input-group-text'>
                                            <option value="1">Select Country</option>
                                            <option value="1">+91(IND)</option>
                                            <option value="1">+1(USA)</option>
                                            <option value="1">+60(MYS)</option>
                                            <option value="1">+62(IDN)</option>
                                            <option value="1">+55(BRA)</option>
                                            <option value="1">+52(MEX)</option>
                                        </select>
                                        <input type="text" className="form-control w-75" placeholder="Mobile Number" />
                                        <div className="d-flex flex-row w-100">
                                            <div className="invalid-feedback small d-block">
                                                Please select Country.
                                            </div>
                                            <div className="invalid-feedback small d-block">
                                                Please enter mobile.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mt-2">
                                        <label htmlFor="">OTP</label>
                                        <input type="text" className="form-control" placeholder="Enter OTP" aria-describedby="helpId"/>
                                        <div className="invalid-feedback small d-block">
                                            please enter valid otp.
                                        </div>
                                    </div>
                                    <Link to="/hospitalresetpassword" className='btn btn-primary w-100 mt-2'>Verify OTP</Link>
                                </form>
                                <p className="text-muted mt-3">Don't have a account? <Link to="./hospitalsignup" className="customAnchor"> Sign up</Link> </p>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

        )
    }
}
export default withRouter(UserForgotpassword);