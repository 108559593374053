import React, { Component } from 'react';
import { Link   } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"

class Editstaff extends React.Component{

	componentDidMount() {
		document.title = "All Staff"
		$('#allDoctors').DataTable({
			dom: '<"top"Bflp>rt<"bottom"ip>',
            pageLength: 5,
            buttons: ['copy', 'csv', 'print', 'excel']
		});
	}

	render(){
		return(
			<>
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<h5 className='mt-3 fw-bold mb-3'>Edit Staff</h5>

						</div>
					</div>
				</div>
			</>
		)
	}
}


export default Editstaff