import React, { Component } from 'react';
import * as $ from 'jquery';
import { Link } from 'react-router-dom';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import HospitalProfile from '../../../mock/hospital/HospitalProfile';
import {
    GetHospitalDetail, HospitalAccountVerification, getHospitalDocuments, getCityList, getStateList, HospitalOwnerSettings,
    CountryList
} from 'src/redux/Main/actions';
import { connect } from "react-redux";
import { withCookies, Cookies } from 'react-cookie';
import StaffDetails from './StaffDetails';
import Location from './Locations';
import Documents from './Documents';
import Doctors from './Doctors';
import { Tabs, Tab, Box } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';
import OwnerAccountSettings from './AccountSettings'
import AccessManagment from './AccessMangment';
import SubscriptionPayment from './SubscriptionPayment';

class ProfileComponent extends Component {

    registrationCouncilList = [];
    registrationTypeList = [];
    constructor(props) {
        const { cookies } = props;

        super(props);
        this.state = {
            hospitalProfile: HospitalProfile,
            authtoken: cookies.get('authtoken') || null,
            ownerAccount: {
                authToken: cookies.get('authtoken') || null,
                name: '',
                mobile: '',
                email: '',
                country_id: "0",
                state_id: "0",
                city_id: "0",
                gender: 'Male',
                current_password: "",
                new_password: "",
                confirm_password: "",
                documents: [],
            },
            staffState: {
                authToken: cookies.get('authtoken') || null,
                staff_role_id: '0',
                name: '',
                mobile: "",
                email: "",
                password: "",
                confirm_password: "",
                day: "",
                from_time: "",
                to_time: "",

            },
            selectedTab: 0

        };
    }
    componentDidMount() {

        this.props.getDocuments({ authToken: this.state.authtoken })
        this.props.HospitalDetails({ authToken: this.state.authtoken })
        this.props.getCountryList({})
        $("#add_location").hide();
        $(".new_location").on('click', function () {
            $("#add_location").show();
        });

        $(".addTime").on('click', function () {
            console.log('yes');
        });



        // upload profile
        var readURL = function (input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    $('.profile-pic').attr('src', e.target.result);
                }
                reader.readAsDataURL(input.files[0]);
            }
        }

        $(".file-upload").on('change', function () {
            readURL(this);
        });

        $(".upload-button").on('click', function () {
            $(".file-upload").click();
        });


        // add more general time
        $(".addMoreBtnGeneral").on("click", function () {
            var html = $(".addMoreGeneralTime").first().clone();
            $(html).find(".change").html("<a href='#' className='btn btn-outline-danger removeGeneralTime'>Remove</a>");

            $(".addMoreGeneralTime").last().after(html);
        });
        $(".removeGeneralTime").on("click", function () {
            alert('yes');
            $(this).parents(".addMoreGeneralTime").remove();
        });

        // add more Qualification
        $(".addMoreBtnQualification").on("click", function () {
            var html = $(".addMoreQualification").first().clone();
            $(html).find(".change").html("<a href='javascript:void(0)' className='btn btn-outline-danger removeQualification'>Remove</a>");

            $(".addMoreQualification").first().after(html);
        });
        $(".removeQualification").on("click", function () {
            alert('yes');
            $(this).parents(".addMoreQualification").remove();
        });

        $('.btn-toggle').on('click', function () {
            $(this).find('.btn').toggleClass('active');
            if ($(this).find('.btn-primary').length > 0) {
                $(this).find('.btn').toggleClass('btn-primary');
            }
        });

    }

    handleChange = (e) => {
        // e.stopPropagation();
        // e.preventDefault();
        if (["registrationNo", "registrationCouncil", "registrationType", "comment"].includes(e.target.name)) {
            let name = e.target.name;
            const hospitalProfile = { ...this.state.hospitalProfile, [e.target.name]: e.target.value };
            this.setState({ hospitalProfile: hospitalProfile });
        }
        // let hospitalProfile=this.state.hospitalProfile;
        // hospitalProfile.registrationNo=e.target.value;
        // this.setState({hospitalProfile:hospitalProfile});
    }

    handleSelect(ranges) {
        console.log(ranges);
    }

    OwnerChangeHandler = (e) => {

        const { name, value, checked } = e.target;
        console.log("Name", name, "Value", value, "checked", checked)
        if (name === 'country_id') {
            this.props.getStates({ countryId: value })
        } if (name === 'state_id') {
            this.props.getCities({ stateId: value })
        }

        let obj = { ...this.state.ownerAccount };
        obj[name] = value
        if (name === 'gender' && checked) {
            obj['gender'] = obj.gender === "Male" ? "Female" : "Male"
        }

        this.setState({ ownerAccount: obj })

    }

    SubmitOwnerAccountHandler = (event) => {
        event.preventDefault();
        this.props.HospitalOwnerSettings(this.state.ownerAccount)

    }

    VerifyAccountHandler = (e) => {
        e.preventDefault();
        this.props.VerifyHospital({ authToken: this.state.authtoken })

        this.componentDidMount();

    }

    FileUploadHanler = (event) => {
        console.log(event);

        let obj = { ...this.state.ownerAccount };

        const file = event.target.files[0];
        console.log(file);

        const files = [...obj.documents]

        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
            // console.log('called: ', reader.result.split(',')[1])
            await files.push(reader.result.split(',')[1])
            // setBase64IMG(reader.result)
            console.log(files);
            obj['documents'] = files;
            this.setState({ ownerAccount: obj })
        }



    }

    render() {

        const {profile_image = null} = this.props.hospitalInfo || {};
        console.log(this.props.hospitalInfo)

        return (
            <React.Fragment>
                <div className="container-fluid bootstrap snippet">
                    <div className="row mt-md-5 mt-0">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="text-center">
                                        <div className="col-md-12 col-12">
                                            <div className="small-12 medium-2 large-2 columns">
                                                <div className="circle_profile mb-3">
                                                    <img className="profile-pic" src={profile_image} />
                                                    <div className="profile_image">
                                                        <i className="fa fa-camera upload-button"></i>
                                                        <input className="file-upload" type="file" accept="image/*" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <ul className="nav nav-tabs textarea_size" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Owner's Account Setting & Password</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#clinic" role="tab" aria-controls="clinic" aria-selected="true">Our Doctors</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#subscriptionPayment" role="tab" aria-controls="subscriptionPayment" aria-selected="true">Subscription Payment </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#documents" role="tab" aria-controls="documents" aria-selected="true">Documents </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#location" role="tab" aria-controls="lcaotion" aria-selected="true">Locations </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#staff" role="tab" aria-controls="documents" aria-selected="true">Staff </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#usermanagement" role="tab" aria-controls="usermanagement" aria-selected="true">Doctor/Staff Access Management </a>
                                        </li>
                                    </ul> */}
                                <div className="col-sm-9">
                                    <h5 className='fw-bold text-md-start text-center h3 mb-2'>{this.props?.hospitalInfo?.hospital_name} &nbsp;
                                        {this.props?.hospitalInfo?.status !== "0" && <span className='text-success'><i className="fas fa-check"></i> Account Verification Successfully</span>}
                                        {this.props?.hospitalInfo?.status === "0" && <span className='text-danger' style={{ cursor: 'pointer' }} onClick={this.VerifyAccountHandler}><i className="fas fa-times-circle"></i> Account Verification Pending</span>}

                                    </h5>
                                    <Box sx={{ maxWidth: { xs: "100%", sm: "100%" }, bgcolor: 'background.paper' }}>

                                        <Tabs
                                            value={this.state.selectedTab}
                                            onChange={(event, newValue) => this.setState({ selectedTab: newValue })}
                                            variant="scrollable"
                                            scrollButtons
                                            allowScrollButtonsMobile
                                            sx={{
                                                [`& .${tabsClasses.scrollButtons}`]: {
                                                    '&.Mui-disabled': { opacity: 0.3 },
                                                },
                                            }}
                                            aria-label="scrollable force tabs example">
                                            <Tab label="Owner's Account Setting & Password" />
                                            <Tab label="Our Doctors" />
                                            <Tab label="Subscription Payment" />
                                            <Tab label="Documents" />
                                            <Tab label="Locations" />
                                            <Tab label="Staff" />
                                            <Tab label="Doctor/Staff Access Management" />
                                        </Tabs>
                                    </Box>

                                    <Box style={{ padding: '10px 30px' }}>

                                        {this.state.selectedTab === 0 && <OwnerAccountSettings />}
                                        {this.state.selectedTab === 1 && <Doctors />}
                                        {this.state.selectedTab === 2 && < SubscriptionPayment />}
                                        {this.state.selectedTab === 3 && <Documents />}
                                        {this.state.selectedTab === 4 && <Location />}
                                        {this.state.selectedTab === 5 && <StaffDetails />}
                                        {this.state.selectedTab === 6 && <AccessManagment />}
                                    </Box>


                                    <div className="tab-content" id="myTabContent">


                                        {/* <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div>
                                                <form action="">
                                                    <div className="row">
                                                        <div className="col-md-4 col-6 mt-2">
                                                            <input type="text" className="form-control textarea_size" placeholder="Name" name='name'
                                                                onChange={this.OwnerChangeHandler} value={this.state.ownerAccount?.name} />
                                                        </div>

                                                        <div className="col-md-4 col-6 mt-2">
                                                            <input type="text" className="form-control textarea_size" placeholder="Phone Number" name='mobile'
                                                                onChange={this.OwnerChangeHandler} value={this.state.ownerAccount?.mobile} />
                                                        </div>

                                                        <div className="col-md-4 col-12 mt-2">
                                                            <input type="text" className="form-control textarea_size" placeholder="Email Id" name='email'
                                                                onChange={this.OwnerChangeHandler} value={this.state.ownerAccount?.email} />
                                                        </div>

                                                        <div className="col-md-4 col-6 mt-2">
                                                            <select
                                                                className="form-control"
                                                                name="country_id"
                                                                value={this.state.ownerAccount?.country_id}
                                                                onChange={this.OwnerChangeHandler}
                                                            >
                                                                <option value="0">Select Country</option>
                                                                {this.props?.countriesList.map((item) => (
                                                                    <option
                                                                        value={item.id}
                                                                        key={item.id}
                                                                    >{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div className="col-md-4 col-6 mt-2">
                                                            <select
                                                                className="form-control"
                                                                name="state_id"
                                                                value={this.state.ownerAccount?.state_id}
                                                                onChange={this.OwnerChangeHandler}
                                                            >
                                                                <option value="0">Select State</option>
                                                                {this.props?.stateList.map((item) => (
                                                                    <option
                                                                        value={item.id}
                                                                        key={item.id}
                                                                    >{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>

                                                        <div className="col-md-4 col-6 mt-2">
                                                            <select
                                                                className="form-control"
                                                                name="city_id"
                                                                value={this.state.ownerAccount?.city_id}
                                                                onChange={this.OwnerChangeHandler}
                                                            >
                                                                <option value="0">Select City</option>
                                                                {this.props?.cityList.map((item) => (
                                                                    <option
                                                                        value={item.id}
                                                                        key={item.id}
                                                                    >{item.name}</option>
                                                                ))}
                                                            </select>

                                                        </div>

                                                        <div className="col-md-4 col-6 mt-2">
                                                            <input type="text" className="form-control textarea_size" placeholder="Pincode" name='pincode'
                                                                value={this.state.ownerAccount?.pincode} onChange={this.OwnerChangeHandler} />
                                                        </div>

                                                        <div className="col-md-4 col-12 mt-2 mb-3 d-flex align-items-center">
                                                            <label className='mr-3 mt-2' htmlFor="gender"><strong>Gender</strong></label>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="male" name="gender" className="custom-control-input" checked={this.state.ownerAccount?.gender === "Male"} value={this.state.ownerAccount?.gender} onChange={this.OwnerChangeHandler} />
                                                                <label className="custom-control-label" htmlFor="male">Male</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="female" name="gender" className="custom-control-input" checked={this.state.ownerAccount?.gender === "Female"} value={this.state.ownerAccount?.gender} onChange={this.OwnerChangeHandler} />
                                                                <label className="custom-control-label" htmlFor="female">Female</label>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="col-md-12">
                                                            <h6 className='text-primary fw-bold'>Change Password</h6>
                                                            <div className="row">
                                                                <div className="col-md-6 mt-2">
                                                                    <input type="text" className="form-control" placeholder="Current Password" value={this.state.ownerAccount?.current_password} onChange={this.OwnerChangeHandler} name='current_password' />
                                                                </div>
                                                                <div className="col-md-6">
                                                                </div>
                                                                <div className="col-md-6 mt-2">
                                                                    <input type="text" className="form-control" placeholder="New Password" value={this.state.ownerAccount?.new_password} onChange={this.OwnerChangeHandler} name='new_password' />
                                                                </div>
                                                                <div className="col-md-6 mt-2">
                                                                    <input type="text" className="form-control" placeholder="Confirm Password" value={this.state.ownerAccount?.confirm_password} onChange={this.OwnerChangeHandler} name='confirm_password' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="col-md-12 mt-2">
                                                            <h6 className='text-primary fw-bold'>Upload ID (Optional)</h6>
                                                            <div className="row">
                                                                <div className="col-md-6 mt-2">
                                                                    <input type="file" className="form-control" onChange={this.FileUploadHanler} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mb-5 mb-md-0 mt-2">
                                                        {/* <Link className="btn btn-primary float-right mb-3" to="#" data-toggle="modal" data-target="#personalinfo_success">Save</Link> *
                                                        <button type='button' className="btn btn-primary float-right mb-3" onClick={this.SubmitOwnerAccountHandler}>Save</button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div> */}

                                        <div className="tab-pane fade show" id="subscriptionPayment" role="tabpanel" aria-labelledby="home-tab">
                                            <div className="container-fluid mt-3">
                                                <button className='btn btn-primary mb-2' data-toggle="modal" data-target="#subscriptionPlanModal">Make a Payment</button>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Active Plan</th>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Price</th>
                                                            <th>Status</th>
                                                            <th>Document/Items</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td scope="row">Monthly</td>
                                                            <td>15-08-2022 11:20AM</td>
                                                            <td>15-09-2022 11:20AM</td>
                                                            <td>Rs. 250</td>
                                                            <td><span className="badge badge-pill badge-success">Paid</span></td>
                                                            <td>
                                                                <Link className='btn btn-primary mr-2'><i className="fas fa-file-download"></i> Invoice</Link>
                                                                <Link className='btn btn-primary'><i className="fas fa-file-download"></i></Link>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="row">Monthly</td>
                                                            <td>15-09-2022 2:30PM</td>
                                                            <td>15-10-2022 2:30PM</td>
                                                            <td>Rs. 250</td>
                                                            <td><span className="badge badge-pill badge-warning">Due</span></td>
                                                            <td>
                                                                <Link className='btn btn-success btn-sm mr-2'>Renew</Link>
                                                                <Link className='btn btn-primary btn-sm'><i className="fas fa-file-download"></i> Invoice</Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <div className="modal fade" id="subscriptionPlanModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-xl modal-dialog-centered">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title mr-4">Subscription Plans</h5>
                                                                <p>Autorenew
                                                                    <div class="btn-group btn-toggle border ml-2">
                                                                        <button class="btn btn-sm btn-default">ON</button>
                                                                        <button class="btn btn-sm btn-primary active">OFF</button>
                                                                    </div>
                                                                </p>

                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="card-deck mb-3 text-center">
                                                                    <div className="card mb-4 shadow-sm active">
                                                                        <div className="card-header">
                                                                            <span className="badge badge-primary">Active Plan</span>
                                                                            <h4 className="my-0 font-weight-normal">Monthly</h4>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <p className='fw-bold mb-2 text-primary small'>Start date: 15-09-2022 to Due Date: 15-10-2022</p>
                                                                            <h1 className="card-title pricing-card-title"><i className="fa fa-rupee-sign mr-2"></i>250 <small className="text-muted">/ mo</small></h1>
                                                                            <ul className="list-unstyled mt-3 mb-4">
                                                                                <li>10 users included</li>
                                                                                <li>Email support</li>
                                                                                <li>Help center access</li>
                                                                            </ul>
                                                                            <button type="button" className="btn btn-lg btn-block btn-primary disabled">Renew Plan</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card mb-4 shadow-sm">
                                                                        <div className="card-header">
                                                                            <h4 className="my-0 font-weight-normal">3 Months</h4>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <h1 className="card-title pricing-card-title"><i className="fa fa-rupee-sign mr-2"></i>750 <small className="text-muted">/ mo</small></h1>
                                                                            <ul className="list-unstyled mt-3 mb-4">
                                                                                <li>20 users included</li>
                                                                                <li>Priority email support</li>
                                                                                <li>Help center access</li>
                                                                            </ul>
                                                                            <button type="button" className="btn btn-lg btn-block btn-primary">Get started</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card mb-4 shadow-sm">
                                                                        <div className="card-header">
                                                                            <h4 className="my-0 font-weight-normal">Yearly</h4>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <h1 className="card-title pricing-card-title"><i className="fa fa-rupee-sign mr-2"></i>2000 <small className="text-muted">/ mo</small></h1>
                                                                            <ul className="list-unstyled mt-3 mb-4">
                                                                                <li>50 users included</li>
                                                                                <li>Phone and email support</li>
                                                                                <li>Help center access</li>
                                                                            </ul>
                                                                            <button type="button" className="btn btn-lg btn-block btn-primary">Get started</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div className="tab-pane fade show" id="usermanagement" role="tabpanel" aria-labelledby="usermanagement-tab">
                                            <a href="#" className='btn btn-primary mb-3 mt-2' data-toggle="modal" data-target="#addUserAccess">Add User</a>
                                            <table className="table table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email ID</th>
                                                        <th>Status</th>
                                                        <th>User Role</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td scope="row">Hospital Name</td>
                                                        <td>hospital@gmail.com</td>
                                                        <td>
                                                            <span className="badge badge-pill badge-success">Active</span>
                                                        </td>
                                                        <td>Hospital</td>
                                                        <td>
                                                            <a href="#" className='btn btn-success mr-2' data-toggle="modal" data-target="#editUserAccess">Edit</a>
                                                            <a href="#" className='btn btn-danger'>Delete</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Dr. Wilson</td>
                                                        <td>wilson@gmail.com</td>
                                                        <td>
                                                            <span className="badge badge-pill badge-success">Active</span>
                                                        </td>
                                                        <td>Doctor</td>
                                                        <td>
                                                            <a href="#" className='btn btn-success mr-2' data-toggle="modal" data-target="#editUserAccess">Edit</a>
                                                            <a href="#" className='btn btn-danger'>Delete</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Nurse</td>
                                                        <td>nurse@gmail.com</td>
                                                        <td>
                                                            <span className="badge badge-pill badge-success">Active</span>
                                                        </td>
                                                        <td>Nurse</td>
                                                        <td>
                                                            <a href="#" className='btn btn-success mr-2' data-toggle="modal" data-target="#editUserAccess">Edit</a>
                                                            <a href="#" className='btn btn-danger'>Delete</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Receptionist</td>
                                                        <td>receptionist@gmail.com</td>
                                                        <td>
                                                            <span className="badge badge-pill badge-success">Active</span>
                                                        </td>
                                                        <td>Receptionist</td>
                                                        <td>
                                                            <a href="#" className='btn btn-success mr-2' data-toggle="modal" data-target="#editUserAccess">Edit</a>
                                                            <a href="#" className='btn btn-danger'>Delete</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="hosdetail_success" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">Successfully Saved</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row mt-2">
                                        <div className="col-md-12 text-center">
                                            <p><i className="far fa-check-circle modal_icon2 mb-2"></i></p>
                                            <h5>Your Hospital/Clinic Details Saved Successfully</h5>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>



                {/* // <!-- password  model --> */}
                <div id="personalinfo_success" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">Successfully Saved</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row mt-2">
                                        <div className="col-md-12 text-center">
                                            <p><i className="far fa-check-circle modal_icon2 mb-2"></i></p>
                                            <h5>Your Personal Information Saved Successfully</h5>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* // <!-- Insurance  model --> */}
                <div id="insurance_success" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">Successfully Saved</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row mt-2">
                                        <div className="col-md-12 text-center">
                                            <p><i className="far fa-check-circle modal_icon2 mb-2"></i></p>
                                            <h5>Your Insurance Saved Successfully</h5>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* // <!-- password  model --> */}
                <div id="newpass_success" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">Successfully Saved</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row mt-2">
                                        <div className="col-md-12 text-center">
                                            <p><i className="far fa-check-circle modal_icon2 mb-2"></i></p>
                                            <h5>Your New Password Saved Successfully</h5>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*
                // <!-- add Location --> */}
                <div className="modal fade" id="add_hospital" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <Link className="mt-2 new_location" to="#">Create</Link>
                                <h5 styles='margin-left: 140px;' className="modal-title text-center" id="exampleModalCenterTitle">Locations</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="forn-group d-flex" id="add_location">
                                    <input type="text" className="form-control mr-2" value="#" />
                                    <Link className="btn btn-primary" to="#"><i className="fas fa-check"></i></Link>
                                </div>
                                <hr />
                                <input type="text" className="form-control mb-3" placeholder="Search" />
                                <ul styles="padding:0;list-style:none">
                                    <li><Link className="text-muted" to="#">#Location 1</Link></li>
                                    <li><Link className="text-muted" to="#">#Location 2</Link></li>
                                    <li><Link className="text-muted" to="#">#Location 3</Link></li>
                                    <li><Link className="text-muted" to="#">#Location 4</Link></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>






                {/* <!-- add User Access model --> */}
                <div className="modal fade" id="addUserAccess" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add Doctor/Staff Access</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input type="text" className="form-control mb-2" placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Email</label>
                                                <input type="text" className="form-control mb-2" placeholder="Email" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Password</label>
                                                <input type="password" className="form-control mb-2" placeholder="Password" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Status</label>
                                                <select className='form-control'>
                                                    <option value="1">Active</option>
                                                    <option value="1">Inactive</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Select Role</label>
                                                <select className='form-control'>
                                                    <option value="1">Hospital Owner</option>
                                                    <option value="2">Doctor</option>
                                                    <option value="3">Nurse</option>
                                                    <option value="4">Receptionist</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="pageParmission" id="menus">
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary" for="menu1"><input type="checkbox" name="menu[]" id="menu1" value="1" />Owner's Account Setting & Password</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary" for="menu2"><input type="checkbox" name="menu[]" id="menu2" value="2" />Staff</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary active" for="menu3"><input type="checkbox" name="menu[]" id="menu3" value="3" />Apollo Delhi</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary active" for="menu5"><input type="checkbox" name="menu[]" id="menu5" value="5" />Apollo Mumbai</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary active" for="menu4"><input type="checkbox" name="menu[]" id="menu4" value="4" />Payment</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- edit User Access model --> */}
                <div className="modal fade" id="editUserAccess" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Edit User Access</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input type="text" className="form-control mb-2" value="Hospital Name" placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Email</label>
                                                <input type="text" className="form-control mb-2" value="hospital@gmail.com" placeholder="Email" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Password</label>
                                                <input type="password" className="form-control mb-2" value="hospital" placeholder="Password" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Status</label>
                                                <select className='form-control'>
                                                    <option value="1">Active</option>
                                                    <option value="1">Inactive</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Select Role</label>
                                                <select className='form-control'>
                                                    <option value="1">Hospital Owner</option>
                                                    <option value="2">Doctor</option>
                                                    <option value="3">Nurse</option>
                                                    <option value="4">Receptionist</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="pageParmission" id="menus">
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary" for="menu1"><input type="checkbox" name="menu[]" id="menu1" value="1" />Owner's Account Setting & Password</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary" for="menu2"><input type="checkbox" name="menu[]" id="menu2" value="2" />Staff</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary active" for="menu3"><input type="checkbox" name="menu[]" id="menu3" value="3" />Apollo Delhi</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary active" for="menu5"><input type="checkbox" name="menu[]" id="menu5" value="5" />Apollo Mumbai</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary active" for="menu4"><input type="checkbox" name="menu[]" id="menu4" value="4" />Payment</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    getDocuments: (params) => dispatch(getHospitalDocuments(params)),
    getCountryList: (params) => dispatch(CountryList(params)),
    getStates: params => dispatch(getStateList(params)),
    getCities: params => dispatch(getCityList(params)),
    HospitalOwnerSettings: params => dispatch(HospitalOwnerSettings(params)),
    HospitalDetails: params => dispatch(GetHospitalDetail(params)),
    VerifyHospital: params => dispatch(HospitalAccountVerification(params))
});

const mapStateToProps = (state) => {
    return {
        countriesList:
            state.mainReducer?.countryListReducer?.response?.data || [],
        stateList:
            state.mainReducer?.stateListReducer?.response?.data || [],
        cityList:
            state.mainReducer?.cityListReducer?.response?.data || [],
        hospitalInfo: state.mainReducer.hospitalDetailsReducer?.response?.data[0] || {},
        authToken:
            state.mainReducer?.userRegistrationReducer?.response?.data?.authToken ||
            null,
        OTPMatch:
            state.mainReducer?.userVerifyOTPReducer?.response?.status === 200 ||
            false,
        RegistrationSucess:
            state.mainReducer?.HospitalRegistrationReducer?.response?.status === 200 ||
            false,
    };
};


export default withCookies(connect(mapStateToProps, mapDispatchToProps)(ProfileComponent));