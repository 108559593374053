import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Paper } from "@mui/material";
import React, { useState } from "react";
import TextInput from "src/common/TextInput";

const Prescription = (props) => {

  const [prescription, setPrescription] = useState([
    {
      medicine: '',
      days: '',
      timing: '',
      comment: '',

    }
  ])


  const onChangeTextHandler = (event, index) => {
    const { name, value } = event.target
    const items = [...prescription]
    items[index][name] = value;
    setPrescription(items)
    props.prescriptionTextHandler(items)

  }

  const AddMoreRows = () => {

    setPrescription([...prescription, {
      medicine: '',
      days: '',
      timing: '',
      comment: '',

    }])

  }

  const removeRowsHandler = (index) => {
    let objCopy = [...prescription];
    objCopy.splice(index, 1);
    setPrescription(objCopy)

  }

  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className="font-weight-bold">#</TableCell>
              <TableCell className="font-weight-bold">Medicine Name</TableCell>
              <TableCell className="font-weight-bold">Days</TableCell>
              <TableCell className="font-weight-bold">Timing</TableCell>
              <TableCell className="font-weight-bold">Comment</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prescription?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{index}</TableCell>
                <TableCell>
                  <TextInput
                    name={'medicine'}
                    value={item.medicine}
                    onChange={(e) => onChangeTextHandler(e, index)}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    name={'days'}
                    value={item.days}
                    onChange={(e) => onChangeTextHandler(e, index)}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    name={'timing'}
                    value={item.timing}
                    onChange={(e) => onChangeTextHandler(e, index)}

                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    name={'comment'}
                    value={item.comment}
                    onChange={(e) => onChangeTextHandler(e, index)}

                  />
                </TableCell>
                <TableCell>
                  {index === 0 ?
                    <i styles="margin-top:10px" className="fa fa-plus" onClick={AddMoreRows} />

                    :
                    <i styles="margin-top:10px" className="fa fa-minus" onClick={() => removeRowsHandler(index)} />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>
    </Box>
  )
}


export default Prescription;

