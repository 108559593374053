import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { getCityList, getStateList, CountryList, GetHospitalLocations, AddHospitalLocationAction, EditHospitalLocationAction, GetDoctorList, getStaffList } from 'src/redux/Main/actions';
import { useCookies } from 'react-cookie';

import { Box, Button, Grid, DialogTitle, DialogContent, DialogActions, Checkbox, FormControlLabel, Divider, FormControl, FormGroup, FormLabel } from "@mui/material";
import TextInput from "src/common/TextInput";
import DialogBox from "src/common/DialogBox";
import DropDown from 'src/common/DropDown';
import DeleteModal from 'src/common/DeleteModal';

const Location = (props) => {
    const [cookies, setCookie] = useCookies(['authtoken']);
    const { authtoken = null } = cookies

    const [openModal, setOpenModal] = useState(false);

    const [payload, setPayload] = useState({
        authToken: authtoken,
        location_name: null,
        mobile: null,
        email: null,
        address: null,
        country_id: '0',
        state_id: '0',
        city_id: '0',
        staff_ids: null,
        doctor_ids: null,
    });

    const [staffs, setStaffs] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [mode, setMode] = useState("ADD");
    const [onCreate, setOnCreate] = useState(false);


    useEffect(() => {
        props.getCountryList({})
        props.GetDoctorList({ authToken: authtoken })
        props.getStaffList({ authToken: authtoken })
        props.GetHospitalLocations({ authToken: authtoken })

    }, [onCreate])


    useEffect(() => {
        if ((openModal && props.addLocationRes === 200) || (openModal && props.editLocationRes === 200)) {
            setOpenModal(false)
            setOnCreate(true)
        }

    }, [openModal, props])


    const OnChangeHandler = (event) => {
        const { target: { name, value } } = event || {};
        let stateCopy = { ...payload };
        if (name === 'country_id') {
            props.getStates({ countryId: value })
        } if (name === 'state_id') {
            props.getCities({ stateId: value })
        }
        stateCopy[name] = value;
        setPayload(stateCopy)
    }


    const onChangeStaffHandler = (e, id) => {
        if (staffs.includes(id)) {
            setStaffs(staffs.filter(e => e !== id));
        } else {
            setStaffs([...staffs, id])
        }
    }

    const onChangeDoctorHandler = (e, id) => {
        if (doctors.includes(id)) {
            setDoctors(doctors.filter(e => e !== id));
        } else {
            setDoctors([...doctors, id])
        }
    }

    const submitHandler = () => {

        let requesPaylod = { ...payload, staff_ids: staffs.toString(), doctor_ids: doctors.toString() }

        const errCount = 0;
        if (staffs.length === 0) {
            errCount++;
        }
        if (doctors.length === 0) {
            errCount++;
        }

        if (errCount > 0) {
            alert('Please fill mandatory fields');
            return;
        }


        props.AddHospitalLocationAction(requesPaylod)
    }

    const EditLocationHandler = () => {

    }

    const { countriesList = [], stateList = [], cityList = [], DoctorsList = [], staffList = [] } = props || {};

    console.log(props)

    return (
        <>
            <Box>
                <Button className='primary-button'
                    size='small'
                    onClick={() => {
                        setOpenModal(true)
                        setMode("ADD")
                    }}
                >Add New Location
                </Button>
            </Box>


            <DialogBox
                open={openModal}
                handleClose={() => setOpenModal(false)}

            >
                <DialogTitle>Add New Location</DialogTitle>

                <DialogContent dividers>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextInput
                                name={'location_name'}
                                placeholder={'Location Name *'}
                                value={payload?.location_name}
                                onChange={(e) => OnChangeHandler(e)}
                                error={!payload?.location_name}

                            />
                            <TextInput
                                name={'email'}
                                placeholder={'Enter Email *'}
                                value={payload?.email}
                                onChange={(e) => OnChangeHandler(e)}
                                error={!payload?.email}

                            />

                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextInput
                                name={'mobile'}
                                placeholder={'Enter Phone *'}
                                value={payload?.mobile}
                                onChange={(e) => OnChangeHandler(e)}
                                error={!payload?.mobile}

                            />
                            <TextInput
                                name={'address'}
                                placeholder={'Enter Location Address *'}
                                value={payload?.address}
                                onChange={(e) => OnChangeHandler(e)}
                                error={!payload?.address}

                            />

                        </Grid>

                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            <DropDown
                                selectLabel={"Please Select Conutry"}
                                name={'country_id'}
                                placeholder={'Select Conutry *'}
                                value={payload?.country_id}
                                onChange={(e) => OnChangeHandler(e)}
                                error={payload?.country_id === '0'}
                                options={countriesList?.map((item) => ({ name: item.name, value: item.id }))}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DropDown
                                selectLabel={"Please Select State"}
                                name={'state_id'}
                                placeholder={'Select State *'}
                                value={payload?.state_id}
                                onChange={(e) => OnChangeHandler(e)}
                                error={payload?.state_id === '0'}

                                options={stateList?.map((item) => ({ name: item.name, value: item.id }))}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <DropDown
                                selectLabel={"Please Select City"}
                                name={'city_id'}
                                placeholder={'Select City *'}
                                error={payload?.city_id === '0'}

                                value={payload?.city_id}
                                onChange={(e) => OnChangeHandler(e)}
                                options={cityList?.map((item) => ({ name: item.name, value: item.id }))}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Divider />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl
                                required
                                error={staffs.length <= 0}
                                component="fieldset"
                                sx={{ m: 3 }}
                                variant="standard"
                            >
                                <FormLabel component="legend">Select Staff</FormLabel>
                                <FormGroup>
                                    {staffList?.map((staff) => (

                                        <FormControlLabel
                                            key={staff.user_id}
                                            value={staff.user_id}
                                            control={<Checkbox color="primary" checked={staffs.includes(staff.user_id)} />}
                                            label={staff.name}
                                            onChange={(e) => onChangeStaffHandler(e, staff.user_id)}
                                            labelPlacement="end"
                                        />
                                    ))}
                                </FormGroup>


                            </FormControl>

                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                            <FormControl
                                required
                                error={doctors.length <= 0}
                                component="fieldset"
                                sx={{ m: 3 }}
                                variant="standard"
                            >
                                <FormLabel component="legend">Select Doctor</FormLabel>
                                <FormGroup>
                                    {DoctorsList?.map((doc) => (
                                        <FormControlLabel
                                            key={doc.user_id}
                                            value={doc.user_id}
                                            control={<Checkbox color="primary" checked={doctors.includes(doc.user_id)} />}
                                            label={doc.name}
                                            onChange={(e) => onChangeDoctorHandler(e, doc.user_id)}
                                            labelPlacement="end"
                                        />
                                    ))}
                                </FormGroup>


                            </FormControl>

                        </Grid>


                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenModal(false)}
                    >
                        Close
                    </Button>
                    <Button className='primary-button'
                        size="small" onClick={(e) => { mode === "ADD" ? submitHandler(e) : EditLocationHandler(e) }} variant='contained'>Save</Button>

                </DialogActions>

            </DialogBox>
            {/* <a href="#" className='btn btn-outline-primary m-2' data-toggle="modal" data-target=".addLocation"><i className="fas fa-plus"></i> Add New Location</a> */}
            <ul className="nav nav-tabs border-0" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#location1" role="tab">Appolo Delhi</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#location2" role="tab">Appolo Mumbai</a>
                </li>
            </ul>

            <div className="tab-content">
                <div className="tab-pane fade show active" id="location1" role="tabpanel">
                    <div className="d-flex flex-wrap">
                        <div className="locationCard">
                            <div className='d-flex justify-content-between align-items-center'>
                                <h5 className='fw-bold'>Appolo Delhi - <span className='border p-2 rounded shadow-sm'>Lalkila, Delhi, India</span></h5>
                                <a href="#" className='btn btn-primary' data-toggle="modal" data-target=".addLocation">Edit</a>
                            </div>


                            <div className="row">
                                <div className="col-md-12 col-sm-6 col-lg-4">
                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                        <div className="card-body">
                                            <div className="pro-widget-content">
                                                <div className="profile-info-widget">
                                                    <a href="#" className="booking-doc-img">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                    </a>
                                                    <div className="profile-det-info">
                                                        <h3><a href="#">Dr. Wilson</a></h3>
                                                        <p><i className="fas fa-user-clock"></i> BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="patient-info">
                                                <div className="patient-details">
                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                </div>

                                                <div className="shadow-sm card p-1 mt-2">
                                                    <p className='text-center'>My availability</p>
                                                    <ul className="nav nav-tabs border-0 w-100">
                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime4">Slot time </a>

                                                        </li>
                                                    </ul>

                                                    <div className="tab-content p-2">
                                                        <div id="generalTime4" className="tab-pane fade show active">
                                                            <div className="mb-2">
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                            </div>
                                                        </div>
                                                        <div id="slotTime4" className="tab-pane fade">
                                                            <div className="mb-2">
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-6 col-lg-4">
                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                        <div className="card-body">
                                            <div className="pro-widget-content">
                                                <div className="profile-info-widget">
                                                    <a href="#" className="booking-doc-img">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                    </a>
                                                    <div className="profile-det-info">
                                                        <h3><a href="#">Dr. Wilson</a></h3>
                                                        <p><i className="fas fa-user-clock"></i> BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="patient-info">
                                                <div className="patient-details">
                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                </div>

                                                <div className="shadow-sm card p-1 mt-2">
                                                    <p className='text-center'>My availability</p>
                                                    <ul className="nav nav-tabs border-0 w-100">
                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime5">Slot time </a></li>
                                                    </ul>

                                                    <div className="tab-content p-2">
                                                        <div id="generalTime5" className="tab-pane fade show active">
                                                            <div className="mb-2">
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                            </div>
                                                        </div>
                                                        <div id="slotTime5" className="tab-pane fade">
                                                            <div className="mb-2">
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-6 col-lg-4">
                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                        <div className="card-body">
                                            <div className="pro-widget-content">
                                                <div className="profile-info-widget">
                                                    <a href="#" className="booking-doc-img">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                    </a>
                                                    <div className="profile-det-info">
                                                        <h3><a href="#">Dr. Wilson</a></h3>
                                                        <p><i className="fas fa-user-clock"></i> BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="patient-info">
                                                <div className="patient-details">
                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                </div>

                                                <div className="shadow-sm card p-1 mt-2">
                                                    <p className='text-center'>My availability</p>
                                                    <ul className="nav nav-tabs border-0 w-100">
                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime6">Slot time </a></li>
                                                    </ul>

                                                    <div className="tab-content p-2">
                                                        <div id="generalTime6" className="tab-pane fade show active">
                                                            <div className="mb-2">
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                            </div>
                                                        </div>
                                                        <div id="slotTime6" className="tab-pane fade">
                                                            <div className="mb-2">
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-6 col-lg-4">
                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                        <div className="card-body">
                                            <div className="pro-widget-content">
                                                <div className="profile-info-widget">
                                                    <a href="#" className="booking-doc-img">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                    </a>
                                                    <div className="profile-det-info">
                                                        <h3><a href="#">Staff 1</a></h3>
                                                        <p><i className="fas fa-user-clock"></i> Nurse</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="patient-info">
                                                <div className="patient-details">
                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                </div>

                                                <div className="shadow-sm card p-1 mt-2">
                                                    <p className='text-center'>My availability</p>
                                                    <ul className="nav nav-tabs border-0 w-100">
                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime7">Slot time </a>

                                                        </li>
                                                    </ul>

                                                    <div className="tab-content p-2">
                                                        <div id="generalTime7" className="tab-pane fade show active">
                                                            <div className="mb-2">
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                            </div>
                                                        </div>
                                                        <div id="slotTime7" className="tab-pane fade">
                                                            <div className="mb-2">
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-6 col-lg-4">
                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                        <div className="card-body">
                                            <div className="pro-widget-content">
                                                <div className="profile-info-widget">
                                                    <a href="#" className="booking-doc-img">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                    </a>
                                                    <div className="profile-det-info">
                                                        <h3><a href="#">Staff 2</a></h3>
                                                        <p><i className="fas fa-user-clock"></i> Receptionist 1</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="patient-info">
                                                <div className="patient-details">
                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                </div>

                                                <div className="shadow-sm card p-1 mt-2">
                                                    <p className='text-center'>My availability</p>
                                                    <ul className="nav nav-tabs border-0 w-100">
                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime8">Slot time </a>

                                                        </li>
                                                    </ul>

                                                    <div className="tab-content p-2">
                                                        <div id="generalTime8" className="tab-pane fade show active">
                                                            <div className="mb-2">
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                            </div>
                                                        </div>
                                                        <div id="slotTime8" className="tab-pane fade">
                                                            <div className="mb-2">
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-6 col-lg-4">
                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                        <div className="card-body">
                                            <div className="pro-widget-content">
                                                <div className="profile-info-widget">
                                                    <a href="#" className="booking-doc-img">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                    </a>
                                                    <div className="profile-det-info">
                                                        <h3><a href="#">Staff 3</a></h3>
                                                        <p><i className="fas fa-user-clock"></i> Receptionist 2</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="patient-info">
                                                <div className="patient-details">
                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                </div>

                                                <div className="shadow-sm card p-1 mt-2">
                                                    <p className='text-center'>My availability</p>
                                                    <ul className="nav nav-tabs border-0 w-100">
                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime9">Slot time </a>

                                                        </li>
                                                    </ul>

                                                    <div className="tab-content p-2">
                                                        <div id="generalTime9" className="tab-pane fade show active">
                                                            <div className="mb-2">
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                            </div>
                                                        </div>
                                                        <div id="slotTime9" className="tab-pane fade">
                                                            <div className="mb-2">
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade show" id="location2" role="tabpanel">
                    <div className="d-flex flex-wrap">
                        <div className="locationCard">
                            <div className='d-flex justify-content-between align-items-center'>
                                <h5 className='fw-bold'>Appolo Mumbai - <span className='border p-2 rounded shadow-sm'>Thane, Mumbai, India</span></h5>
                                <a href="#" className='btn btn-primary' data-toggle="modal" data-target=".addLocation">Edit</a>
                            </div>


                            <div className="row">
                                <div className="col-md-12 col-sm-6 col-lg-4">
                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                        <div className="card-body">
                                            <div className="pro-widget-content">
                                                <div className="profile-info-widget">
                                                    <a href="#" className="booking-doc-img">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                    </a>
                                                    <div className="profile-det-info">
                                                        <h3><a href="#">Dr. Wilson</a></h3>
                                                        <p><i className="fas fa-user-clock"></i> BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="patient-info">
                                                <div className="patient-details">
                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                </div>

                                                <div className="shadow-sm card p-1 mt-2">
                                                    <p className='text-center'>My availability</p>
                                                    <ul className="nav nav-tabs border-0 w-100">
                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime4">Slot time </a>

                                                        </li>
                                                    </ul>

                                                    <div className="tab-content p-2">
                                                        <div id="generalTime4" className="tab-pane fade show active">
                                                            <div className="mb-2">
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                            </div>
                                                        </div>
                                                        <div id="slotTime4" className="tab-pane fade">
                                                            <div className="mb-2">
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-6 col-lg-4">
                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                        <div className="card-body">
                                            <div className="pro-widget-content">
                                                <div className="profile-info-widget">
                                                    <a href="#" className="booking-doc-img">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                    </a>
                                                    <div className="profile-det-info">
                                                        <h3><a href="#">Dr. Wilson</a></h3>
                                                        <p><i className="fas fa-user-clock"></i> BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="patient-info">
                                                <div className="patient-details">
                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                </div>

                                                <div className="shadow-sm card p-1 mt-2">
                                                    <p className='text-center'>My availability</p>
                                                    <ul className="nav nav-tabs border-0 w-100">
                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime5">Slot time </a></li>
                                                    </ul>

                                                    <div className="tab-content p-2">
                                                        <div id="generalTime5" className="tab-pane fade show active">
                                                            <div className="mb-2">
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                            </div>
                                                        </div>
                                                        <div id="slotTime5" className="tab-pane fade">
                                                            <div className="mb-2">
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-6 col-lg-4">
                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                        <div className="card-body">
                                            <div className="pro-widget-content">
                                                <div className="profile-info-widget">
                                                    <a href="#" className="booking-doc-img">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                    </a>
                                                    <div className="profile-det-info">
                                                        <h3><a href="#">Dr. Wilson</a></h3>
                                                        <p><i className="fas fa-user-clock"></i> BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="patient-info">
                                                <div className="patient-details">
                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                </div>

                                                <div className="shadow-sm card p-1 mt-2">
                                                    <p className='text-center'>My availability</p>
                                                    <ul className="nav nav-tabs border-0 w-100">
                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime6">Slot time </a></li>
                                                    </ul>

                                                    <div className="tab-content p-2">
                                                        <div id="generalTime6" className="tab-pane fade show active">
                                                            <div className="mb-2">
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                            </div>
                                                        </div>
                                                        <div id="slotTime6" className="tab-pane fade">
                                                            <div className="mb-2">
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                <p>Monday : 10:AM to 05:PM</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-6 col-lg-4">
                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                        <div className="card-body">
                                            <div className="pro-widget-content">
                                                <div className="profile-info-widget">
                                                    <a href="#" className="booking-doc-img">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                    </a>
                                                    <div className="profile-det-info">
                                                        <h3><a href="#">Staff 1</a></h3>
                                                        <p><i className="fas fa-user-clock"></i> Nurse</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="patient-info">
                                                <div className="patient-details">
                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                </div>

                                                <div className="shadow-sm card p-1 mt-2">
                                                    <p className='text-center'>My availability</p>
                                                    <ul className="nav nav-tabs border-0 w-100">
                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime7">Slot time </a>

                                                        </li>
                                                    </ul>

                                                    <div className="tab-content p-2">
                                                        <div id="generalTime7" className="tab-pane fade show active">
                                                            <div className="mb-2">
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                            </div>
                                                        </div>
                                                        <div id="slotTime7" className="tab-pane fade">
                                                            <div className="mb-2">
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-6 col-lg-4">
                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                        <div className="card-body">
                                            <div className="pro-widget-content">
                                                <div className="profile-info-widget">
                                                    <a href="#" className="booking-doc-img">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                    </a>
                                                    <div className="profile-det-info">
                                                        <h3><a href="#">Staff 2</a></h3>
                                                        <p><i className="fas fa-user-clock"></i> Receptionist 1</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="patient-info">
                                                <div className="patient-details">
                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                </div>

                                                <div className="shadow-sm card p-1 mt-2">
                                                    <p className='text-center'>My availability</p>
                                                    <ul className="nav nav-tabs border-0 w-100">
                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime8">Slot time </a>

                                                        </li>
                                                    </ul>

                                                    <div className="tab-content p-2">
                                                        <div id="generalTime8" className="tab-pane fade show active">
                                                            <div className="mb-2">
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                            </div>
                                                        </div>
                                                        <div id="slotTime8" className="tab-pane fade">
                                                            <div className="mb-2">
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-sm-6 col-lg-4">
                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                        <div className="card-body">
                                            <div className="pro-widget-content">
                                                <div className="profile-info-widget">
                                                    <a href="#" className="booking-doc-img">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                    </a>
                                                    <div className="profile-det-info">
                                                        <h3><a href="#">Staff 3</a></h3>
                                                        <p><i className="fas fa-user-clock"></i> Receptionist 2</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="patient-info">
                                                <div className="patient-details">
                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                </div>

                                                <div className="shadow-sm card p-1 mt-2">
                                                    <p className='text-center'>My availability</p>
                                                    <ul className="nav nav-tabs border-0 w-100">
                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime9">Slot time </a>

                                                        </li>
                                                    </ul>

                                                    <div className="tab-content p-2">
                                                        <div id="generalTime9" className="tab-pane fade show active">
                                                            <div className="mb-2">
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                            </div>
                                                        </div>
                                                        <div id="slotTime9" className="tab-pane fade">
                                                            <div className="mb-2">
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>
                                                                <p>Monday</p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}

            {/* // <!-- Add new Location  model --> */}
            <div className="modal fade addLocation" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title">Add new Location</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="locationname">Enter Location Name</label>
                                            <input type="text" className='form-control' placeholder='Location Name' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="phone">Enter Email</label>
                                            <input type="text" className='form-control' placeholder='Email' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="phone">Enter Phone</label>
                                            <input type="text" className='form-control' placeholder='Phone' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="locationaddress">Enter Location Address</label>
                                            <input type="text" className='form-control' placeholder='Location Address' />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="locationaddress">Select Conutry</label>
                                            <select className='form-control'>
                                                <option value="2">India</option>
                                                <option value="1">USA</option>
                                                <option value="3">Canada</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="locationaddress">Select State</label>
                                            <select className='form-control'>
                                                <option value="2">Gujarat</option>
                                                <option value="1">Delhi</option>
                                                <option value="3">Maharatra</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="locationaddress">Select City</label>
                                            <select className='form-control'>
                                                <option value="1">Ahmedabad</option>
                                                <option value="2">Surat</option>
                                                <option value="3">Vadodara</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label className='fw-bold'>Select Staff</label>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="doc1" />
                                                    <label className="form-check-label small" htmlFor="doc1">
                                                        Dr. XYZ
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="nurse1" />
                                                    <label className="form-check-label small" htmlFor="nurse1">
                                                        Ms. Rossy
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label className='fw-bold'>Select Doctor</label>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="doc1" />
                                                    <label className="form-check-label small" htmlFor="doc1">
                                                        Dr. XYZ
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="nurse1" />
                                                    <label className="form-check-label small" htmlFor="nurse1">
                                                        Ms. Rossy
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Add</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}



const mapDispatchToProps = dispatch => ({
    AddHospitalLocationAction: params => dispatch(AddHospitalLocationAction(params)),
    EditHospitalLocationAction: params => dispatch(EditHospitalLocationAction(params)),
    GetHospitalLocations: params => dispatch(GetHospitalLocations(params)),
    GetDoctorList: params => dispatch(GetDoctorList(params)),
    getStaffList: (params) => dispatch(getStaffList(params)),
    getCountryList: (params) => dispatch(CountryList(params)),
    getStates: params => dispatch(getStateList(params)),
    getCities: params => dispatch(getCityList(params)),
})

const mapStateToProps = state => {
    return {
        countriesList:
            state.mainReducer?.countryListReducer?.response?.data || [],
        addLocationRes: state.mainReducer?.addStaffReducer?.response?.status || null,
        editLocationRes: state.mainReducer?.editStaffReducer?.response?.status || null,
        stateList:
            state.mainReducer?.stateListReducer?.response?.data || [],
        cityList:
            state.mainReducer?.cityListReducer?.response?.data || [],
        DoctorsList:
            state.mainReducer?.doctorListReducer?.response?.data || [],
        staffList:
            state.mainReducer?.staffListReducer?.response?.data || [],
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Location)