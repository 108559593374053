const RegistrationTypeList=[
    {
        id:1,  
        name:"Reg Type 1",
    },
    {
        id:2,  
        name:"Reg Type 2",
    },
    {
        id:3,  
        name:"Reg Type 3",
    },
    {
        id:4,  
        name:"Reg Type 4",
    }

];

export default RegistrationTypeList;