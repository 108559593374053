
import React, { Component, useEffect } from 'react';
import HospitalFilterComponent from '../../Search/SearchFilter/HospitalFilter';
import AppointmentFilterComponent from '../../Search/SearchFilter/AppointmentFilter';
import { PrescriptionDTO } from '../../../mock/PrescriptionDTO';
import { Link } from 'react-router-dom';
import { MedicineRowComponent, PrecautionRowComponent } from './MedicineRow';
import { connect } from 'react-redux';
import { getAppointmentsCountAction, getStaffListByDateAction, getPatientPrescriptionAction } from 'src/redux/Main/actions';
import { useCookies } from 'react-cookie';

const UserPrescription = (props) => {
	const [cookies, setCookie] = useCookies(['authtoken']);

	const { authtoken = null } = cookies

	const { location: { state: { patient_id = null, apt_id = null, hospital_name = null, } } } = props



	useEffect(() => {
		props.getPatientPrescriptionAction({
			authToken: authtoken,
			patient_id: patient_id,
			apt_id: apt_id
		})

	}, [patient_id, apt_id])


	console.log("UserPrescription", props.location.state)
	console.log(props.prescription)

	const { prescription = [] } = props;


	return (
		<>

			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="d-flex justify-content-between align-items-center">
							<div className="mt-3">
								<span className="fw-bold">Prescription Number:</span>
							</div>
							<div className="mt-3">
								<Link to="#" className='btn btn-outline-primary mr-2'>Download from here <i className="fa fa-download"></i></Link>
								<div className="dropdown btn btn-outline-primary mr-2">
									<i className="fa fa-share"></i>
									<div className="dropdown-content">
										<Link to="#">Share</Link>
										<Link to="#">Share on gmail</Link>
									</div>
								</div>
								<Link to='/user/allPrescription' className="btn btn-primary mr-2" type="btn">All Prescription</Link>
								<Link to={{
									pathname: '/user/invoice',
									state: {
										patient_id: patient_id,
										apt_id: apt_id,
									}
								}} className="btn btn-primary"
								// key={this.state.prescriptionDto.id} 
								>Invoice</Link>
							</div>
						</div>
						<hr />
						<div className="row dashboard_box">
							{prescription?.map((item) => (
								<>
									<div className="row" key={item.apt_id}>

										<div className="col-md-6 col-12 mb-3">
											<div className="row">
												<div className="col-md-4 col-6">
													<p><b>Doctor Name  </b></p>
													<p><b>Patient Name   </b></p>
													<p><b>Phone Number  </b></p>
													<p><b>Date Of Birth  </b></p>
													<p><b>Time Slot  </b></p>
													<p><b>Address </b></p>
												</div>

												<div className="col-md-8 col-6">
													<p>: &nbsp; {item.doctor_name}</p>
													<p>: &nbsp; {item.patient_name}</p>
													<p>: &nbsp; {item.phone}</p>
													<p>: &nbsp; {item.dob}</p>
													<p>: &nbsp; {item.timeSlot}</p>
													<p>: &nbsp; {item.doctor_address} </p>
												</div>
											</div>


										</div>
										<div className="col-md-6 col-12 mb-3">
											<div className="row">
												<div className="col-md-4 col-6">
													<p><b>Appintment Date</b></p>
													<p><b>Blood Group </b></p>
													<p><b>Police Case</b></p>
													<p><b>Argency Type</b></p>
													<p><b>Case Type</b></p>
												</div>

												<div className="col-md-8 col-6">
													<p>: &nbsp; {item.appointment_date}</p>
													<p>: &nbsp; {item.blood_group}</p>
													<p>: &nbsp; {item.police_case}</p>
													<p>: &nbsp; {item.agency_type}</p>
													<p>: &nbsp; {item.case_type}</p>
												</div>
											</div>
										</div>
									</div>

									<hr />
									<div className="col-12 mb-3">
										<p>Problem:
											{item.app_comment}
										</p>
									</div>

									<div className="col-md-12 mb-3">
										<table className="table text-center table_pad">
											<thead>
												<tr>
													<th className="">Sr No.</th>
													<th className="">Medicine</th>
													<th className="">Quantity</th>
													<th className="">Course*</th>
												</tr>
											</thead>
											<tbody>

												{
													item.prescription?.map((medicine, i) => (
														<tr key={medicine.id}>
															<td>{medicine.id}</td>
															<td>{medicine.medicine_name}</td>
															<td>{medicine.days}</td>
															<td>{medicine.comment}</td>

														</tr>

													))}
											</tbody>
										</table>
									</div>
								</>

							))}





							<div className="col-md-12">
								<div className="d-flex justify-content-between">
									<div className='d-flex flex-column'>
										{/* <p className='fw-bold'>Note : </p>{(this.state.prescriptionDto.precaution !== undefined && this.state.prescriptionDto.precaution !== null &&
											this.state.prescriptionDto.precaution.length > 0) ?
											this.state.prescriptionDto.precaution.map((precaution, i) => {
												return <PrecautionRowComponent key={precaution.id} precaution={precaution} />
											}) : ''} */}
									</div>
									<p className='text-muted'>*Mornin-Afternoon-Evening-Night</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)

}

const mapDispatchToProps = dispatch => ({
	getPatientPrescriptionAction: (params) => dispatch(getPatientPrescriptionAction(params)),

})

const mapStateToProps = state => {
	return {
		prescription:
			state.mainReducer?.patientPrescriptionReducer?.response?.data || [],
		staffList:
			state.mainReducer?.getStaffListByDateReducer?.response?.data || [],

	}
}


export default connect(mapStateToProps, mapDispatchToProps)(UserPrescription)


// class UserPrescriptionComponent extends Component {

// 	prescriptionDto = null;
// 	doctorId = '';
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			prescriptionDto: PrescriptionDTO
// 		};
// 		this.getUserPrescription = this.getUserPrescription.bind(this);

// 	}



// 	componentDidMount() {
// 		// setTimeout(() => {
// 		// 	this.setState({ prescriptionDto: PrescriptionDTO });
// 		// }, 10);
// 	}

// 	getUserPrescription() {

// 		// this.doctorId = this.props.location.state.doctorId;

// 		//this.state.prescriptionDto=PrescriptionDTO;
// 		//this.state.setState({prescriptionDto:PrescriptionDTO});
// 	}
// 	selectAppointment = (val) => {
// 		console.log('fiilter selected ');
// 	}

// 	selectedFilter = (selectedVal) => {
// 		console.log(" menu selescted ");
// 	}

// 	render() {

// 		this.getUserPrescription();

// 		return (
// 			<React.Fragment>
// 				{/* <div className="w-100 pos_fix">
// 					<div className="direction_row filter d-flex justify-content-between">
// 						<div>
// 							<AppointmentFilterComponent onSelectAppointment={this.selectAppointment} />
// 						</div>
// 						<div>
// 							<p className="w-100 text-light"><strong className="font_13">Hospital Name: {this.state.prescriptionDto.hospital.name}</strong></p>
// 						</div>
// 						<div className="">
// 							<Link to='/user/allPrescription' className="btn btn-secondary" type="btn">All Prescription</Link>
// 						</div>
// 					</div>
// 				</div> */}
// 				{/* <div className="row">
// 					<div className="col-md-12">
// 						<HospitalFilterComponent onSelectFilter={this.selectedFilter} />
// 					</div>
// 				</div> */}
// 				<div className="container">
// 					<div className="row">

// 						<div className="col-md-12">
// 							<div className="d-flex justify-content-between align-items-center">
// 								<div className="mt-3">
// 									<span className="fw-bold">Prescription Number:{this.state.prescriptionDto.id}</span>
// 								</div>
// 								<div className="mt-3">
// 									<Link to="#" className='btn btn-outline-primary mr-2'>Download from here <i className="fa fa-download"></i></Link>
// 									<div className="dropdown btn btn-outline-primary mr-2">
// 										<i className="fa fa-share"></i>
// 										<div className="dropdown-content">
// 											<Link to="#">Share</Link>
// 											<Link to="#">Share on gmail</Link>
// 										</div>
// 									</div>
// 									<Link to='/user/allPrescription' className="btn btn-primary mr-2" type="btn">All Prescription</Link>
// 									<Link to={{
// 										pathname: '/user/invoice',
// 										state: {
// 											doctorId: this.state.prescriptionDto.doctor.id,
// 											patientId: this.state.prescriptionDto.patient.id
// 										}
// 									}} className="btn btn-primary" key={this.state.prescriptionDto.id} >Invoice</Link>
// 								</div>
// 							</div>
// 							<hr />
// 							<div className="row dashboard_box">
// 								<div className="col-md-6 col-12 mb-3">
// 									<div className="row">
// 										<div className="col-md-4 col-6">
// 											<p><b>Doctor Name :  </b></p>
// 											<p><b>Patient Name :  </b></p>
// 											<p><b>Phone Number : </b></p>
// 											<p><b>Date Of Birth : </b></p>
// 											<p><b>Time Slot : </b></p>
// 											<p><b>Address :</b></p>
// 										</div>

// 										<div className="col-md-8 col-6">
// 											<p>{this.state.prescriptionDto.doctor.name}</p>
// 											<p>{this.state.prescriptionDto.patient.name}</p>
// 											<p>{this.state.prescriptionDto.patient.phone}</p>
// 											<p>{this.state.prescriptionDto.patient.dob}</p>
// 											<p>{this.state.prescriptionDto.patient.timeSlot}</p>
// 											<p>{this.state.prescriptionDto.patient.address} </p>
// 										</div>
// 									</div>
// 								</div>

// 								<div className="col-md-6 col-12 mb-3">
// 									<div className="row">
// 										<div className="col-md-4 col-6">
// 											<p><b>Appintment Date :</b></p>
// 											<p><b>Blood Group :</b></p>
// 											<p><b>Police Case :</b></p>
// 											<p><b>Argency Type :</b></p>
// 											<p><b>Case Type :</b></p>
// 										</div>

// 										<div className="col-md-8 col-6">
// 											<p>{this.state.prescriptionDto.patient.appointmentDate}</p>
// 											<p>{this.state.prescriptionDto.patient.bloodGroup}</p>
// 											<p>{this.state.prescriptionDto.patient.policeCase}</p>
// 											<p>{this.state.prescriptionDto.patient.agencyType}</p>
// 											<p>{this.state.prescriptionDto.patient.caseType}</p>
// 										</div>
// 									</div>
// 								</div>

// 								<hr />
// 								<div className="col-12 mb-3">
// 									<p>Problem: {this.state.prescriptionDto.patient.problem} </p>
// 								</div>

// 								<div className="col-md-12 mb-3">
// 									<table className="table text-center table_pad">
// 										<thead>
// 											<tr>
// 												<th className="">Sr No.</th>
// 												<th className="">Medicine</th>
// 												<th className="">Quantity</th>
// 												<th className="">Course*</th>
// 											</tr>
// 										</thead>
// 										<tbody>
// 											{(this.state.prescriptionDto.medicine !== undefined && this.state.prescriptionDto.medicine !== null &&
// 												this.state.prescriptionDto.medicine.length > 0) ?
// 												this.state.prescriptionDto.medicine.map((medicine, i) => {
// 													return <MedicineRowComponent key={medicine.id} medicine={medicine} from={'PRESCRIPTION'} />
// 												}) : ''}
// 										</tbody>
// 									</table>
// 								</div>

// 								<div className="col-md-12">
// 									<div className="d-flex justify-content-between">
// 										<div className='d-flex flex-column'>
// 											<p className='fw-bold'>Note : </p>{(this.state.prescriptionDto.precaution !== undefined && this.state.prescriptionDto.precaution !== null &&
// 											this.state.prescriptionDto.precaution.length > 0) ?
// 											this.state.prescriptionDto.precaution.map((precaution, i) => {
// 												return <PrecautionRowComponent key={precaution.id} precaution={precaution} />
// 											}) : ''}
// 										</div>
// 										<p className='text-muted'>*Mornin-Afternoon-Evening-Night</p>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>

// 			</React.Fragment>

// 		)
// 	}
// }
// export default UserPrescriptionComponent;