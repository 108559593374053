import React from 'react';
import { Link, withRouter, useHistory, NavLink } from "react-router-dom";
import '../../assets/style.css';
// import logImage from "../../assets/images/aajivan3.png";
import { UserAuthService } from '../../service/UserAuthService';
import { UserContext, UserDispatchContext } from '../../state/UserState';
import SubHeaderComponent from './SubHeader';


const HeaderLoggedInComponent = () => {

    const history = useHistory();
    const userDetails = React.useContext(UserContext);
    const setUserDetails = React.useContext(UserDispatchContext);
    // const [activeLinkId, setActiveLinkId] = useState(0);

    const logout = (e) => {
        e.preventDefault();
        UserAuthService.removeToken();
        UserAuthService.removeRole();
        setUserDetails({
            ...userDetails,
            isLogin: false,
            role: '',
            token: UserAuthService.getToken()
        });
        history.push('/');
    }


    return (
        <React.Fragment>
            <nav className="navbar navbar-expand-lg navbar-light nav_bg">
                <div className="container">
                    <div className="row w-100">
                        <div className="col-md-4 col-5">
                            <Link to="/user/dashboard" className="navbar-brand" >Aajivan</Link>
                        </div>

                        <div className="col-md-8 col-7 text-right pt-2 p-0 d-flex justify-content-end align-items-center">
                            <div className="dropdown_profile">
                                <div className='dropdown-toggle d-flex align-items-center' data-toggle="dropdown">
                                    <img className='mr-2' src={require('../../assets/images/profile_circle-1.png').default} width="40" alt="" />
                                    <p className="my-2 my-sm-0 mr-0 mr-md-2">Patient Name</p>
                                </div>
                                <div className="dropdown_profile-content">
                                    <ul className="list-unstyled dropdown-menu">
                                        <li>
                                            <Link to="/#" className='d-flex justify-content-start align-items-center'>
                                                <div className='mr-2'>
                                                    <img src={require('../../assets/images/profile_circle-1.png').default} width="40" alt="profile" />
                                                </div>
                                                <div>
                                                    <p><strong>Patient Name</strong></p>
                                                    <small>+91 123654789</small>
                                                </div>
                                            </Link>
                                        </li>
                                        <li className="dropdown-item"><Link to="/user/book/appointment">Book Appointment</Link></li>
                                        <li className="dropdown-item" ><Link to="/howItWorks">How It Works (FAQ)</Link></li>
                                        <li className="dropdown-item"><Link to="/blog">Blog</Link></li>
                                        <li className="dropdown-item"><Link to="/aboutus">About Us</Link></li>
                                        <li className="dropdown-item"><Link to="/contactUs">Contact Us</Link></li>
                                        <li className="dropdown-item"><Link to="/termsconditions">Terms & Condition</Link></li>
                                        <li className="dropdown-item"><Link to="/privacypolicy">Privacy Policy</Link></li>
                                        {/* <li className="dropdown-item"><Link to="/#">Sitemap</Link></li> */}
                                        <li className="dropdown-item"> <NavLink to="/Logout" >Logout</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="d-flex justify-content-between subheader">
                <SubHeaderComponent />
            </div>

            <div className="mobile_header d-md-none d-block">
                <SubHeaderComponent screen={'mobile'} />
            </div>

        </React.Fragment>
    );
}
export default withRouter(HeaderLoggedInComponent);