import React from "react";

import { connect } from "react-redux";
import { useCookies } from 'react-cookie';
import { Box } from "@mui/material";
import { getPatientAccountProfileAction } from "src/redux/Main/actions";
import { useEffect } from "react";

const PatientProfile = (props) => {

    const [cookies, setCookie] = useCookies(['authtoken']);


    console.log("PatientProfile", props);

    useEffect(() => {
        const { authtoken = null } = cookies
        //props.getPatientAccountProfileAction({ authToken: authtoken })
    }, [])

    const { location: { state: { appointmentId = null, name = null, patient_id = null, profile_image = null,
        appointmentDate = null,
        doctor_name = null,
        phone = null,
        address = null,
        slotTime = null,
        police_case = null,
        case_type = null,
        blood_group = null,
        agency_type = null,
        dob = null,

    } } } = props


    return (
        <Box>
            <div className="row">
                <div className="col-md-2">
                    <ul className='liveappointmentDetail'>
                        <li>
                            <img src={profile_image} width="150" alt="User" />
                        </li>
                    </ul>
                </div>

                <div className="col-md-5">
                    <ul className='liveappointmentDetail'>
                        <li className="d-flex">
                            <span><strong>Appintment Date : </strong></span>
                            <span>{appointmentDate}</span>
                        </li>
                        <li className="d-flex">
                            <span><strong>Doctor Name : </strong></span>
                            <span>{`Dr . ${doctor_name}`}</span>
                        </li>
                        <li className="d-flex">
                            <span><strong>Phone : </strong></span>
                            <span>{phone}</span>
                        </li>
                        <li className="d-flex">
                            <span><strong>DOB : </strong></span>
                            <span>{dob}</span>
                        </li>
                        <li className="d-flex">
                            <span><strong>Address : </strong></span>
                            <span>{address}</span>
                        </li>
                    </ul>
                </div>

                <div className="col-md-5">
                    <ul className='liveappointmentDetail'>
                        <li className="d-flex">
                            <span><strong>Time Slot : </strong></span>
                            <span>{slotTime}</span>
                        </li>
                        <li className="d-flex">
                            <span><strong>Blood Group : </strong></span>
                            <span>{blood_group}</span>
                        </li>
                        <li className="d-flex">
                            <span><strong>Police Case : </strong></span>
                            <span>{police_case}</span>
                        </li>
                        <li className="d-flex">
                            <span><strong>Urgency Type : </strong></span>
                            <span>{agency_type}</span>
                        </li>
                        <li className="d-flex">
                            <span><strong>Case Type : </strong></span>
                            <span>{case_type}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </Box>
    )

}



const mapDispatchToProps = dispatch => ({
    getPatientAccountProfileAction: (params) => dispatch(getPatientAccountProfileAction(params)),

})

const mapStateToProps = state => {
    return {
        patientProfile:
            state.mainReducer?.getPatientAccountProfileReducer?.response?.data || [],

    }

}


export default connect(mapStateToProps, mapDispatchToProps)(PatientProfile);

