import actionTypes from "../actionTypes";

export function AdminTabChange(params) {
    return {
        type: actionTypes.ADMIN_NAV_TAB_CHANGE,
        params
    }
}
export function getCountryCodes(params) {
    console.log("params", params)
    return {
        type: actionTypes.GET_COUNTRY_CODES_REQUEST,
        params
    }
}

export function userLogin(params) {
    return {
        type: actionTypes.USER_LOGIN_REQUEST,
        params
    }
}
export function userLoginWithOTP(params) {
    return {
        type: actionTypes.USER_LOGIN_WITH_OTP_REQUEST,
        params
    }
}
export function hospitalLogin(params) {
    return {
        type: actionTypes.HOSPITAL_LOGIN_REQUEST,
        params
    }
}
export function userRegistration(params) {
    return {
        type: actionTypes.USER_REGISTRATION_REQUEST,
        params
    }
}
export function userVerifyOTP(params) {
    return {
        type: actionTypes.USER_VERIFY_OTP_REQUEST,
        params
    }
}
export function AddUserDetails(params) {
    return {
        type: actionTypes.ADD_USER_DETAILS_REQUEST,
        params
    }
}
export function ForgotPassword(params) {
    return {
        type: actionTypes.FORGOT_PSWD_REQUEST,
        params
    }
}
export function ChangePassword(params) {
    return {
        type: actionTypes.CHANGE_PSWD_REQUEST,
        params
    }
}
export function VerifyOTPForPswd(params) {
    return {
        type: actionTypes.FORGOT_PSWD_VERYFY_OTP_REQUEST,
        params
    }
}

export function HospitalRegistration(params) {
    return {
        type: actionTypes.HOSPITAL_REGISTRATION_REQUEST,
        params
    }
}

export function UserLogout(params) {
    return {
        type: actionTypes.USER_LOGOUT,
        params
    }
}
export function emptyUserRegistration(params) {
    return {
        type: actionTypes.EMPTY_USER_REGISTRATION,
        params
    }
}

export function GetHospitalDetail(params) {
    return { type: actionTypes.GET_HOSPITAL_DETAILS_REQUEST, params }
}
export function HospitalAccountVerification(params) {
    return { type: actionTypes.HOSPITAL_ACC_VERIFY_REQUEST, params }
}

export function HospitalOwnerSettings(params) {
    return {
        type: actionTypes.HOSPITAL_OWNER_ACCOUNT_REQUEST,
        params
    }
}
export function getHospitalDocuments(params) {
    console.log('params')
    return {
        type: actionTypes.HOSPITAL_DOCUMENTS_LIST_REQUEST,
        // type: actionTypes.GET_STATE_LIST_REQUEST,
        params
    }
}
export function getStateList(params) {
    console.log('params')
    return {
        type: actionTypes.GET_STATE_LIST_REQUEST,
        params
    }
}
export function getCityList(params) {
    console.log('params')
    return {
        type: actionTypes.GET_CITY_LIST_REQUEST,
        params
    }
}

export function GetStaffCategoryList(params) {
    return {
        type: actionTypes.STAFF_CATE_LIST_REQUEST,
        params
    }
}

export function getStaffList(params) {
    return {
        type: actionTypes.HOSPITAL_STAFF_LIST_REQUEST,
        params
    }
}
export function AddStaff(params) {
    return {
        type: actionTypes.HOSPITAL_ADD_STAFF_REQUEST,
        params
    }
}

export function EditStaff(params) {
    return {
        type: actionTypes.HOSPITAL_EDIT_STAFF_REQUEST,
        params
    }
}

export function DeleteStaff(params) {
    return {
        type: actionTypes.HOSPITAL_DELETE_STAFF_REQUEST,
        params
    }
}

export function DegreeList(params) {
    return {
        type: actionTypes.GET_DEGREE_LIST_REQUEST,
        params
    }
}
export function CollegeList(params) {
    return {
        type: actionTypes.GET_COLLEGE_LIST_REQUEST,
        params
    }
}
export function DoctorSpecList(params) {
    return {
        type: actionTypes.GET_DOCTOR_SPEC_LIST_REQUEST,
        params
    }
}
export function GetDoctorList(params) {
    return {
        type: actionTypes.GET_DOCTOR_LIST_REQUEST,
        params
    }
}

export function AddDoctorAction(params) {
    return {
        type: actionTypes.ADD_DOCTOR_REQUEST,
        params
    }
}

export function EditDoctorAction(params) {
    return {
        type: actionTypes.EDIT_DOCTOR_REQUEST,
        params
    }
}
export function AddHospitalLocationAction(params) {
    return {
        type: actionTypes.HOSPITAL_ADD_LOCATION_REQUEST,
        params
    }
}
export function EditHospitalLocationAction(params) {
    return {
        type: actionTypes.HOSPITAL_EDIT_LOCATION_REQUEST,
        params
    }
}


export function CountryList(params) {
    return {
        type: actionTypes.GET_COUNTRY_LIST_REQUEST,
        params
    }
}

export function GetHospitalLocations(params) {
    return {
        type: actionTypes.GET_LOCATION_LIST_REQUEST,
        params
    }
}


// USER ACCESS

export function addUserForAccessAction(params) {
    return {
        type: actionTypes.ADD_USER_FOR_ACCESS_REQUEST,
        params
    }
}

export function editUserForAccessAction(params) {
    return {
        type: actionTypes.EDIT_USER_FOR_ACCESS_REQUEST,
        params
    }
}

export function deleteUserForAccessAction(params) {
    return {
        type: actionTypes.DELETE_USER_FOR_ACCESS_REQUEST,
        params
    }
}

export function getRolesListAction(params) {
    return {
        type: actionTypes.GET_ROLE_LIST_REQUEST,
        params
    }
}
export function getMenusListAction(params) {
    return {
        type: actionTypes.GET_MENUS_LIST_REQUEST,
        params
    }
}

export function userAccessMangementListAction(params) {
    return {
        type: actionTypes.GET_USER_ACCESS_MANAGEMENT_LIST_REQUEST,
        params
    }
}
export function getAllAppointmentsAction(params) {
    return {
        type: actionTypes.GET_ALL_APPOINTMENTS_REQUEST,
        params
    }
}
export function getStaffListByDateAction(params) {
    return {
        type: actionTypes.GET_STAFF_LIST_BY_DATE_REQUEST,
        params
    }
}
export function getAppointmentsCountAction(params) {
    return {
        type: actionTypes.GET_APPOINTMENTS_COUNT_REQUEST,
        params
    }
}

export function insuranceListAction(params) {
    return {
        type: actionTypes.INSURANCE_LIST_REQUEST,
        params
    }
}

export function bloodGroupListAction(params) {
    return {
        type: actionTypes.BLOOD_GROUP_LIST_REQUEST,
        params
    }
}

export function addPatientProfileAction(params) {
    return {
        type: actionTypes.ADD_PATIENT_PROFILE_REQUEST,
        params
    }
}
export function getSlotTypesAction(params) {
    return {
        type: actionTypes.GET_SLOT_TYPES_REQUEST,
        params
    }
}
export function bookAppointmentAction(params) {
    return {
        type: actionTypes.BOOK_APPOINTMENT_REQUEST,
        params
    }
}
export function getPatientAccountProfileAction(params) {
    return {
        type: actionTypes.GET_PATIENT_ACCOUNT_PROFILE_REQUEST,
        params
    }
}
export function saveAppointmentDataAction(params) {
    return {
        type: actionTypes.SAVE_APPOINTMENT_DATA_REQUEST,
        params
    }
}

export function getPatientPrescriptionAction(params) {
    return {
        type: actionTypes.GET_PATIENT_PRESCRIPTION_REQUEST,
        params
    }
}
export function getPatientInvoiceAction(params) {
    return {
        type: actionTypes.GET_PATIENT_INVOICE_REQUEST,
        params
    }
}

export function startAppointmentAction(params) {
    return {
        type: actionTypes.START_APPOINTMENT_REQUEST,
        params
    }
}
export function getAllPatientInvoiceReportAction(params) {
    return {
        type: actionTypes.GET_ALL_PATIENT_INVOICE_REPORT_REQUEST,
        params
    }
}

export function getAppointmentHistoryAction(params){
    return{
        type:actionTypes.GET_APPOINTMENT_HISTORY_REQUEST,
        params
    }
}

export function getAllHospitalWithLocationAction(params){
    return{
        type:actionTypes.GET_ALL_HOSPITAL_WITH_LOCATION_REQUEST,
        params
    }
}


export function getAllDoctorByLocationAction(params){
    return{
        type:actionTypes.GET_ALL_DOCTOR_By_LOCATION_REQUEST,
        params
    }
}
export function getPatientListAction(params){
    return{
        type:actionTypes.GET_PATIENTS_LIST_REQUEST,
        params
    }
}

export function getPatientMedicalHistory(params){
    return{
        type: actionTypes.GET_PATIENT_MEDICAL_HISTORY_REQUEST,
        params
    }
}

export function getPatientMedicalProfileAction(params){
    return{
        type: actionTypes.GET_PATIENT_MEDICAL_PROFILE_REQUEST,
        params
    }

}

export function emptyStaffAccessManagment(params){
    return{
        type:actionTypes.EMPTY_STAFF_ACCESS_MANAGMENT,
        params
    }
}

export function emptyStaffAction(params){
    return {
        type: actionTypes.EMPTY_STAFF,
        params
    }
}

export function emptyLiveAppointment(params){
    return {
        type: actionTypes.EMPTY_LIVE_APPOINTMENT,
        params
    }
}




