import React from "react";
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const DialogBox = ({ open, handleClose, handleSubmit, children }) => {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'lg'}
            aria-describedby="alert-dialog-slide-description"
        >

            {children}

        </Dialog>
    )
}

export default DialogBox