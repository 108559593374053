import React, { Component } from 'react';
import { Link   } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"

class Userdetail extends React.Component{

	componentDidMount() {
		document.title = "Patient Detail"
		$('#patientAppointment').DataTable({
			dom: '<"top"Bflp>rt<"bottom"ip>',
            pageLength: 5,
            buttons: ['copy', 'csv', 'print', 'excel']
		});
	}

	render(){
		return(
			<>
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar dct-dashbd-lft">
                            <div className="card widget-profile pat-widget-profile mb-3">
                                <div className="card-body">
                                    <div className="pro-widget-content">
                                        <div className="profile-info-widget">
                                            <a href="#" className="booking-doc-img">
                                                <img src={require('../../../assets/images/patient.jpg').default} width="80" alt="User Image"/>
                                            </a>
                                            <div className="profile-det-info">
                                                <h3>Mukesh Sharma</h3>
                                                <div className="patient-details">
                                                    <h5><b>Patient ID :</b> PT001</h5>
                                                    <h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> Delhi, India</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="patient-info">
                                        <ul>
                                            <li>Phone <span>+1 1236547895</span></li>
                                            <li>Age <span>29 Years, Male</span></li>
                                            <li>Blood Group <span>AB+</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-8 col-xl-9 dct-appoinment">
                            <div className="card p-2">
                                <div className="card-body p-0">
                                    <div className="tab-content pt-0">
                                        <div className="tab-pane fade show active">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


export default Userdetail