import React, { Component } from 'react';
import { Link   } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"

class Editpatient extends React.Component{

	componentDidMount() {
		document.title = "Edit Patient Detail"
	}

	render(){
		return(
			<>
                <div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar dct-dashbd-lft">
                            <div className="card widget-profile pat-widget-profile mb-3">
                                <div className="card-body">
                                    <div className="pro-widget-content">
                                        <div className="profile-info-widget">
                                            <a href="#" className="booking-doc-img">
                                                <img src={require('../../../assets/images/patient.jpg').default} width="80" alt="User Image"/>
                                            </a>
                                            <div className="profile-det-info">
                                                <h3>Mukesh Sharma</h3>
                                                <div className="patient-details">
                                                    <h5><b>Patient ID :</b> PT001</h5>
                                                    <h5 className="mb-0"><i className="fas fa-map-marker-alt"></i> Delhi, India</h5>
                                                </div>
                                            </div>
                                            <button className='btn btn-outline-primary profileEdit' data-toggle="modal" data-target="#editMedicalprofileModal">Edit</button>
                                        </div>
                                    </div>
                                    <div className="patient-info">
                                        <ul>
                                            <li>Phone <span>+1 1236547895</span></li>
                                            <li>Age <span>29 Years, Male</span></li>
                                            <li>Blood Group <span>AB+</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Last Booking</h5>
                                </div>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <div className="media align-items-center d-flex">
                                            <div className="me-3 flex-shrink-0">
                                                <img alt="Image placeholder" src={require('../../../assets/images/doctor.jpg').default} width="60"
                                                    className="avatar  rounded-circle"/>
                                            </div>
                                            <div className="media-body flex-grow-1">
                                                <h5 className="d-block mb-0">Dr. Jack </h5>
                                                <span className="d-block text-sm text-muted">Dentist</span>
                                                <span className="d-block text-sm text-muted">14 Aug 2022 5:00 PM</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="media align-items-center d-flex">
                                            <div className="me-3 flex-shrink-0">
                                                <img alt="Image placeholder" src={require('../../../assets/images/doctor.jpg').default} width="60"
                                                    className="avatar  rounded-circle"/>
                                            </div>
                                            <div className="media-body flex-grow-1">
                                                <h5 className="d-block mb-0">Dr. Jack </h5>
                                                <span className="d-block text-sm text-muted">Dentist</span>
                                                <span className="d-block text-sm text-muted">10 Aug 2022 5:00 AM</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-8 col-xl-9 dct-appoinment">
                            <div className="card p-2">
                                <div className="card-header p-2">
                                    <h5>All Appointments</h5>
                                </div>
                                <div className="card-body p-0">
                                    <div className="tab-content pt-0">
                                        <div id="pat_appointments" className="tab-pane fade show active">
                                            <div className="card-table mb-0">
                                                <div className="card-body p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-center mb-0" id="patientAppointment">
                                                            <thead>
                                                                <tr>
                                                                    <th>Doctor</th>
                                                                    <th>Hospital</th>
                                                                    <th>Location</th>
                                                                    <th>Appt Date</th>
                                                                    <th>Booking Date</th>
                                                                    <th>Amount</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a href="./appointmentdetail"
                                                                                className="avatar avatar-sm me-2">
                                                                                <img className="avatar-img rounded-circle"
                                                                                    src={require('../../../assets/images/doctor.jpg').default} width="50"
                                                                                    alt="User Image"/>
                                                                            </a>
                                                                            <a href="./appointmentdetail">Dr. Jack
                                                                                <span>Dental</span>
                                                                            </a>
                                                                        </h2>
                                                                    </td>
                                                                    <td>Apollo Delhi</td>
                                                                    <td>Delhi</td>
                                                                    <td>14 Aug 2022 <span className="d-block text-info">5:00
                                                                            AM</span></td>
                                                                    <td>12 Aug 2022</td>
                                                                    <td>Rs. 750</td>
                                                                    <td>
                                                                        <span className="badge badge-pill success-status">Complated</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a href="./appointmentdetail"
                                                                                className="avatar avatar-sm me-2">
                                                                                <img className="avatar-img rounded-circle"
                                                                                    src={require('../../../assets/images/doctor.jpg').default} width="50"
                                                                                    alt="User Image"/>
                                                                            </a>
                                                                            <a href="./appointmentdetail">Dr. Jack
                                                                                <span>Dental</span>
                                                                            </a>
                                                                        </h2>
                                                                    </td>
                                                                    <td>Apollo Delhi</td>
                                                                    <td>Delhi</td>
                                                                    <td>10 Aug 2022 <span className="d-block text-info">5:00
                                                                            AM</span></td>
                                                                    <td>8 Aug 2022</td>
                                                                    <td>Rs. 750</td>
                                                                    <td>
                                                                        <span className="badge badge-pill success-status">Complated</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="medical">
                                            <div className="border-top card-table mb-0">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-center mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th>Date </th>
                                                                    <th>Description</th>
                                                                    <th>Attachment</th>
                                                                    <th>Created</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><a href="javascript:void(0);">#MR-0010</a></td>
                                                                    <td>14 Aug 2022</td>
                                                                    <td>Dental Filling</td>
                                                                    <td><a href="#">dental-test.pdf</a></td>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a href="#"
                                                                                className="avatar avatar-sm me-2">
                                                                                <img className="avatar-img rounded-circle"
                                                                                    src={require('../../../assets/images/doctor.jpg').default} width="50"
                                                                                    alt="User Image"/>
                                                                            </a>
                                                                            <a href="#">Dr. Jack
                                                                                <span>Dental</span></a>
                                                                        </h2>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="pres">
                                            <div className="border-top card-table mb-0">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-center mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Date </th>
                                                                    <th>Name</th>
                                                                    <th>Created by </th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>14 Aug 2022</td>
                                                                    <td>Prescription 1</td>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a href="#"
                                                                                className="avatar avatar-sm me-2">
                                                                                <img className="avatar-img rounded-circle"
                                                                                    src={require('../../../assets/images/doctor.jpg').default} width="50"
                                                                                    alt="User Image"/>
                                                                            </a>
                                                                            <a href="#">Dr. Jack
                                                                                <span>Dental</span></a>
                                                                        </h2>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="medical">
                                            <div className="border-top card-table mb-0">
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover table-center mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th>Date </th>
                                                                    <th>Description</th>
                                                                    <th>Attachment</th>
                                                                    <th>Created</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><a href="javascript:void(0);">#MR-0010</a></td>
                                                                    <td>14 Aug 2022</td>
                                                                    <td>Dental Filling</td>
                                                                    <td><a href="#">dental-test.pdf</a></td>
                                                                    <td>
                                                                        <h2 className="table-avatar">
                                                                            <a href="#"
                                                                                className="avatar avatar-sm me-2">
                                                                                <img className="avatar-img rounded-circle"
                                                                                    src={require('../../../assets/images/doctor.jpg').default} width="50"
                                                                                    alt="User Image"/>
                                                                            </a>
                                                                            <a href="#">Dr. Jack
                                                                                <span>Dental</span></a>
                                                                        </h2>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="modal fade" id="editMedicalprofileModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Medical Profile</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-md-12 col-12 text-right">
                                            <button type="submit" className='btn btn-primary mr-2'>Save</button>
                                        </div>
                                        <div className="col-md-12">
                                            <h6 className='text-primary fw-bold'>Select Insurance</h6>
                                            <div className='insuranceSec mb-3 d-flex justify-content-between align-items-center'>
                                                <div className="form-group flex-fill mb-0 mr-2">
                                                    <select className='form-control'>
                                                        <option value="1">Select Insurnace</option>
                                                        <option value="2">Medcare Insurnace</option>
                                                        <option value="3">Insurnace 1</option>
                                                        <option value="4">Insurnace 2</option>
                                                    </select>
                                                </div>
                                                <div className="form-group flex-fill mb-0 mr-2">
                                                    <input type="text" className='form-control' placeholder='Enter Policy Number' />
                                                </div>
                                                <div className='change'>
                                                    <Link to="#" className='btn btn-outline-primary addMoreInsurance'>Add More</Link>
                                                </div>
                                            </div>
                                            <div id="newInsurance"></div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="insurance">Age</label>
                                                <input type="text" className='form-control' placeholder='Enter Age' />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="insurance">Weight</label>

                                                <div className="input-group">
                                                    <input type="text" className='form-control w-50' placeholder='Enter Weight' />
                                                    <select className='form-control w-50 input-group-text' required>
                                                        <option value="">kg</option>
                                                        <option value="">lb</option>
                                                    </select>
                                                </div>

                                            </div>
                                        </div>
                                        <hr className='mt-2 mb-2'/>
                                        <div className="row">
                                            <div className="col-md-4 p-0">
                                                <div className="col-md-12 col-12 text-center">
                                                    <div className="card">
                                                        <h6><strong>Blood Group</strong></h6>
                                                        <div className="d-flex justify-content-around btns">
                                                            <p className="blood_btn">A+</p>
                                                            <p className="blood_btn">A-</p>
                                                        </div>

                                                        <div className="d-flex justify-content-around btns">
                                                            <p className="blood_btn">B+</p>
                                                            <p className="blood_btn">B-</p>
                                                        </div>

                                                        <div className="d-flex justify-content-around btns">
                                                            <p className="blood_btn">AB+</p>
                                                            <p className="blood_btn">AB-</p>
                                                        </div>

                                                        <div className="d-flex justify-content-around btns">
                                                            <p className="blood_btn">O+</p>
                                                            <p className="blood_btn">O-</p>
                                                        </div>

                                                        <div className="d-flex justify-content-around btns">
                                                            <p className="blood_btn">Don't Know</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-0">
                                                <div className="row">
                                                    <div className="col-md-4 col-6 text-center">
                                                        <div className="card customCheckboxBtn">
                                                            <h6><strong>Disabilities</strong></h6>
                                                            <div className="d-flex justify-content-around">
                                                                <p className="disabilityBtn">Yes</p>
                                                                <p className="disabilityBtn">No</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-6 text-center">
                                                        <div className="card customCheckboxBtn">
                                                            <h6><strong>Gender</strong></h6>
                                                            <div className="d-flex justify-content-around">
                                                                <p className="genderBtn">Male</p>
                                                                <p className="genderBtn">Female</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-6 text-center">
                                                        <div className="card customCheckboxBtn">
                                                            <h6><strong>HIV ?</strong></h6>
                                                            <div className="d-flex justify-content-around">
                                                                <p className="hivBtn">Yes</p>
                                                                <p className="hivBtn">No</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 col-12 text-center">
                                                        <div className="card mt-2">
                                                            <h6><strong>Medical Condition</strong></h6>
                                                            <div className="d-flex justify-content-around btns">
                                                                <p className="blood_btn">High Blood Presure</p>
                                                                <p className="blood_btn">Diabetis</p>
                                                            </div>
                                                            <div className="d-flex justify-content-around btns">
                                                                <p className="blood_btn">Thyroid</p>
                                                                <p className="blood_btn">Don't Know</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}


export default Editpatient