
import React, { Component, useEffect } from 'react';
import HospitalFilterComponent from '../../Search/SearchFilter/HospitalFilter';
import AppointmentFilterComponent from '../../Search/SearchFilter/AppointmentFilter';

import { Link } from 'react-router-dom';
import { InvoiceDTO } from '../../../mock/InvoiceDTO';
import { MedicineRowComponent } from '../prescription/MedicineRow';
import { getAppointmentsCountAction, getStaffListByDateAction, getPatientInvoiceAction } from 'src/redux/Main/actions';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';

const UserInvoiceComponent = (props) => {

	const [cookies, setCookie] = useCookies(['authtoken']);

	const { authtoken = null } = cookies

	const { location: { state: { patient_id = null, apt_id = null, hospital_name = null, } } } = props



	useEffect(() => {
		props.getPatientInvoiceAction({
			authToken: authtoken,
			patient_id: patient_id,
			apt_id: apt_id
		})

	}, [patient_id, apt_id])


	console.log("UserInvoiceComponent", props.location.state)
	console.log(props.invoice)

	const { invoice = [] } = props
	const { invoice_number = null } = invoice[0] || {};


	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="d-flex justify-content-between align-items-center">
							<div className="mt-3">
								<span className="fw-bold">Invoice Number: {invoice_number} </span>
							</div>
							<div className="mt-3">
								<Link to="#" className='btn btn-outline-primary mr-2'>Download from here <i className="fa fa-download"></i></Link>
								<div className="dropdown btn btn-outline-primary mr-2">
									<i className="fa fa-share"></i>
									<div className="dropdown-content">
										<Link to="#">Share</Link>
										<Link to="#">Share on gmail</Link>
									</div>
								</div>
								<Link to='/user/allPrescription' className="btn btn-primary mr-2" type="btn">All Invoices</Link>
								<Link to={{
									pathname: '/user/prescription',
									state: {
										patient_id: patient_id,
										apt_id: apt_id
									}
								}} className="btn btn-primary"
								>Prescription</Link>
							</div>
						</div>
						<hr />
						<div className="row dashboard_box">
							{invoice?.map((item) => (
								<>
									<div className="row" key={item.apt_id}>

										<div className="col-md-6 col-12 mb-3">
											<div className="row">
												<div className="col-md-4 col-6">
													<p><b>Doctor Name  </b></p>
													<p><b>Patient Name   </b></p>
													<p><b>Phone Number  </b></p>
													<p><b>Date Of Birth  </b></p>
													<p><b>Time Slot  </b></p>
													<p><b>Address </b></p>
												</div>

												<div className="col-md-8 col-6">
													<p>: &nbsp; {item.doctor_name}</p>
													<p>: &nbsp; {item.patient_name}</p>
													<p>: &nbsp; {item.phone}</p>
													<p>: &nbsp; {item.dob}</p>
													<p>: &nbsp; {item.timeSlot}</p>
													<p>: &nbsp; {item.doctor_address} </p>
												</div>
											</div>


										</div>
										<div className="col-md-6 col-12 mb-3">
											<div className="row">
												<div className="col-md-4 col-6">
													<p><b>Appintment Date</b></p>
													<p><b>Blood Group </b></p>
													<p><b>Police Case</b></p>
													<p><b>Argency Type</b></p>
													<p><b>Case Type</b></p>
												</div>

												<div className="col-md-8 col-6">
													<p>: &nbsp; {item.appointment_date}</p>
													<p>: &nbsp; {item.blood_group}</p>
													<p>: &nbsp; {item.police_case}</p>
													<p>: &nbsp; {item.agency_type}</p>
													<p>: &nbsp; {item.case_type}</p>
												</div>
											</div>
										</div>
									</div>

									<hr />
									<div className="col-12 mb-3">
										<p>Problem:
											{item.app_comment}
										</p>
									</div>

									<div className="col-md-12 mb-3">
										<table className="table text-center table_pad">
											<thead>
												<tr>
													<th className="">Sr No.</th>
													<th className="">Description</th>
													<th className="">Unit Cost</th>
													<th className="">Quantity</th>
													<th className="">Amount($)</th>
												</tr>
											</thead>
											<tbody>

												{
													item.medicine?.map((medicine, i) => (
														<tr key={medicine.medicine_name}>
															<td>{i}</td>
															<td>{medicine.medicine_name}</td>
															<td>{medicine.unitCost}</td>
															<td>{medicine.quantity}</td>
															<td>{medicine.amount}</td>

														</tr>

													))}
											</tbody>
										</table>
									</div>
								</>

							))}

						</div>
					</div>
				</div>
			</div>
		</>
	)
}


const mapDispatchToProps = dispatch => ({
	getPatientInvoiceAction: (params) => dispatch(getPatientInvoiceAction(params)),

})

const mapStateToProps = state => {
	return {
		invoice:
			state.mainReducer?.patientInvoiceReducer?.response?.data || [],

	}
}


export default connect(mapStateToProps, mapDispatchToProps)(UserInvoiceComponent)

// class UserInvoiceComponent extends Component {
//    prescriptionDto = null;
//    doctorId = '';
//    constructor(props) {
//       super(props);
//       this.state = {
//          invoiceDTO: InvoiceDTO
//       };
//       this.getUserInvoice = this.getUserInvoice.bind(this);

//    }




//    componentDidMount() {
//       // setTimeout(() => {
//       //    this.setState({ invoiceDTO: InvoiceDTO });
//       // }, 10);
//    }

//    getUserInvoice() {

//       // this.doctorId = this.props.location.state.doctorId;

//       //this.state.prescriptionDto=PrescriptionDTO;
//       //this.state.setState({prescriptionDto:PrescriptionDTO});
//    }

//    selectAppointment = (val) => {
//       console.log('fiilter selected ');
//    }

//    selectedFilter = (selectedVal) => {
//       console.log(" menu selescted ");
//    }

//    render() {

//       this.getUserInvoice();
//       return (
//          <React.Fragment>
//             {/* <div className="w-100 pos_fix">
//                <div className="direction_row d-flex justify-content-between">
//                   <div>
//                      <AppointmentFilterComponent onSelectAppointment={this.selectAppointment} />
//                   </div>
//                   <div>
//                      <p className="w-100 p-2"><strong className="font_13">{this.state.invoiceDTO.hospital.name}</strong></p>
//                   </div>
//                   <div className="mt-2">

//                   </div>
//                </div>
//             </div> */}
//             <div className="row">
//                <div className="col-md-12">
//                   <HospitalFilterComponent onSelectFilter={this.selectedFilter} />
//                </div>

//                <div className="col-md-12">
//                   <div className="row dashboard_box">
//                      <div className="d-flex justify-content-between align-items-center mb-2">
//                         <div className="pr-0">
//                            <span className="mb-0 fw-bold">Invoice Number: 12345</span>
//                         </div>
//                         <div className="text-right">
//                            <button className='btn btn-primary mr-2'>Download from here <i className="fa fa-download"></i></button>

//                            <div className="dropdown btn btn-outline-primary mr-2">
//                               <i className="fa fa-share"></i>
//                               <div className="dropdown-content">
//                                  <Link to="#">Share</Link>
//                                  <Link to="#">Share on gmail</Link>
//                               </div>
//                            </div>

//                            <Link to='/user/allInvoice' className="btn btn-primary mr-2" type="btn">All Invoice</Link>

//                            <Link to={{
//                               pathname: '/user/prescription',
//                               state: {
//                                  doctorId: this.state.invoiceDTO.doctor.id,
//                                  patientId: this.state.invoiceDTO.patient.id
//                               }
//                            }} className=" btn btn-primary" key={this.state.invoiceDTO.id} >Prescription</Link><br />
//                         </div>
//                      </div>

//                      <hr />
//                      <div className="col-md-6 col-12">
//                         <div className="row">
//                            <div className="col-md-4 col-6">
//                               <p><b>Doctor Name :  </b></p>
//                               <p><b>Patient Name :  </b></p>
//                               <p><b>Phone Number : </b></p>
//                               <p><b>Date Of Birth : </b></p>
//                               <p><b>Time Slot : </b></p>
//                               <p><b>Address :</b></p>
//                            </div>
//                            <div className="col-md-8 col-6">
//                               <p>{this.state.invoiceDTO.doctor.name}</p>
//                               <p>{this.state.invoiceDTO.patient.name}</p>
//                               <p>{this.state.invoiceDTO.patient.phone}</p>
//                               <p>{this.state.invoiceDTO.patient.dob}</p>
//                               <p>{this.state.invoiceDTO.patient.timeSlot}</p>
//                               <p>{this.state.invoiceDTO.patient.address}</p>
//                            </div>
//                         </div>
//                      </div>
//                      <div className="col-md-6 pr-0 col-12">
//                         <div className="row">
//                            <div className="col-md-4 col-6">
//                               <p><b>Appintment Date :</b></p>
//                               <p><b>Blood Group :</b></p>
//                               <p><b>Police Case :</b></p>
//                               <p><b>Argency Type :</b></p>
//                               <p><b>Case Type :</b></p>
//                            </div>
//                            <div className="col-md-8 col-12">
//                               <p>{this.state.invoiceDTO.patient.appointmentDate}</p>
//                               <p>{this.state.invoiceDTO.patient.bloodGroup}</p>
//                               <p>{this.state.invoiceDTO.patient.policeCase}</p>
//                               <p>{this.state.invoiceDTO.patient.agencyType}</p>
//                               <p>{this.state.invoiceDTO.patient.caseType}</p>
//                            </div>
//                         </div>
//                      </div>

//                      <hr />
//                      <div className="col-md-12 mb-3">
//                         <p><span className='fw-bold'>Problem :</span> {this.state.invoiceDTO.patient.problem}</p>
//                      </div>
//                      <div className="col-md-12">
//                         <table className="table text-center table_pad">
//                            <thead>
//                               <tr>
//                                  <th>Sr No.</th>
//                                  <th>Description</th>
//                                  <th>Unit Cost</th>
//                                  <th>Quantity</th>
//                                  <th>Amount($)</th>
//                               </tr>
//                            </thead>
//                            <tbody>
//                               {(this.state.invoiceDTO.medicine !== undefined && this.state.invoiceDTO.medicine !== null &&
//                                  this.state.invoiceDTO.medicine.length > 0) ?
//                                  this.state.invoiceDTO.medicine.map((medicine, i) => {
//                                     return <MedicineRowComponent key={medicine.id} medicine={medicine} from={'INVOICE'} />
//                                  }) : ''}
//                               <tr>
//                                  <td></td>
//                                  <td></td>
//                                  <td></td>
//                                  <td className='small'><b>Sub Total</b></td>
//                                  <td className='small'>{this.state.invoiceDTO.subTotal}</td>
//                               </tr>
//                               <tr>
//                                  <td></td>
//                                  <td></td>
//                                  <td></td>
//                                  <td className='small'><b>Discount</b></td>
//                                  <td className='small'>{this.state.invoiceDTO.discount.amount}</td>
//                               </tr>
//                               <tr>
//                                  <td></td>
//                                  <td></td>
//                                  <td></td>
//                                  <td className='small'><b>Tax</b></td>
//                                  <td className='small'>{this.state.invoiceDTO.tax.amount}</td>
//                               </tr>
//                               <tr>
//                                  <td></td>
//                                  <td></td>
//                                  <td></td>
//                                  <td><b>Total</b></td>
//                                  <td className='fw-bold text-primary'>{this.state.invoiceDTO.total}</td>
//                               </tr>
//                            </tbody>
//                         </table>
//                      </div>
//                   </div>
//                </div>
//             </div>

//          </React.Fragment>

//       )
//    }
// }
// export default UserInvoiceComponent;