import React, { useState } from "react";
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Paper } from "@mui/material";
import TextInput from "src/common/TextInput";

const Invoice = (props) => {

  const [treatment, setTreatment] = useState([
    {
      treatment: '',
      treatment_price: '',

    }
  ])
  const [medicineCharges, setMedicineCharges] = useState([
    {
      medicine: '',
      quantity: '',
      unit_cost: '',

    }
  ])


  const onChangeTextHandler = (event, index) => {
    const { name, value } = event.target;

    let itmes = [...treatment];
    itmes[index][name] = value;
    setTreatment(itmes)
    props.treatmentTextHandler(itmes)

  }


  const onChangeMedicineTextHandler = (event, index) => {
    const { name, value } = event.target;
    let itmes = [...medicineCharges];
    itmes[index][name] = value;
    setMedicineCharges(itmes)

    props.medicineChargesTextHandler(itmes)

  }

  const AddMoreRows = () => {

    setTreatment([...treatment, {
      treatment: '',
      treatment_price: '',
    }])

  }

  const removeRowsHandler = (index) => {
    let objCopy = [...treatment];
    objCopy.splice(index, 1);
    setTreatment(objCopy)

  }

  const AddMoreMedicineRows = () => {

    setMedicineCharges([...medicineCharges, {
      medicine: '',
      quantity: '',
      unit_cost: '',

    }])

  }

  const removeMedicineRowsHandler = (index) => {
    let objCopy = [...medicineCharges];
    objCopy.splice(index, 1);
    setMedicineCharges(objCopy)

  }


  return (
    <Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className="font-weight-bold">#</TableCell>
              <TableCell className="font-weight-bold">Treatement</TableCell>
              <TableCell className="font-weight-bold">Price</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {treatment?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{index}</TableCell>
                <TableCell>
                  <TextInput
                    name={'treatment'}
                    value={item.treatment}
                    onChange={(e) => onChangeTextHandler(e, index)}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    name={'treatment_price'}
                    value={item.treatment_price}
                    onChange={(e) => onChangeTextHandler(e, index)}
                  />
                </TableCell>
                <TableCell>
                  {index === 0 ?
                    <i styles="margin-top:10px" className="fa fa-plus" onClick={AddMoreRows} />

                    :
                    <i styles="margin-top:10px" className="fa fa-minus" onClick={() => removeRowsHandler(index)} />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <label className='text-primary mt-3'>Medicines Charges</label>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell className="font-weight-bold">#</TableCell>
              <TableCell className="font-weight-bold">Medicine Name</TableCell>
              <TableCell className="font-weight-bold">Quantity</TableCell>
              <TableCell className="font-weight-bold">Unit Cost</TableCell>
              <TableCell className="font-weight-bold">Amount</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {medicineCharges?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{index}</TableCell>
                <TableCell>
                  <TextInput
                    name={'medicine'}
                    value={item.medicine}
                    onChange={(e) => onChangeMedicineTextHandler(e, index)}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    name={'quantity'}
                    value={item.quantity}
                    onChange={(e) => onChangeMedicineTextHandler(e, index)}
                  />
                </TableCell>
                <TableCell>
                  <TextInput
                    name={'unit_cost'}
                    value={item.unit_cost}
                    onChange={(e) => onChangeMedicineTextHandler(e, index)}
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  {index === 0 ?
                    <i styles="margin-top:10px" className="fa fa-plus" onClick={AddMoreMedicineRows} />
                    :
                    <i styles="margin-top:10px" className="fa fa-minus" onClick={() => removeMedicineRowsHandler(index)} />
                  }
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell colSpan={4} className='text-right'><strong>Total</strong></TableCell>
              <TableCell>200</TableCell>
            </TableRow>
          </TableBody>

        </Table>
      </TableContainer>
    </Box>
  )
}


export default Invoice

