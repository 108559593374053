import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import {
    DialogTitle, Box, Button, TextField, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, DialogContent, DialogActions,
    FormGroup, FormControlLabel, Switch
} from '@mui/material';
import { Link } from 'react-router-dom';


import DialogBox from 'src/common/DialogBox'
import TextInput from 'src/common/TextInput';
import DropDown from 'src/common/DropDown';
import DeleteModal from 'src/common/DeleteModal';

const SubscriptionPayment = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [onDelete, setOnDelete] = useState(false);
    const [mode, setMode] = useState("ADD");
    const [openModal, setOpenModal] = useState(false);

    const [state, setState] = useState({

    })


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    return (
        <>
            {onDelete &&
                <DeleteModal

                    open={onDelete}

                    handleClose={() => setOnDelete(false)}
                    handleSubmit={() => { }}


                />
            }

            <DialogBox
                open={openModal}
                handleClose={() => setOpenModal(false)}

            >
                <DialogTitle>
                    Subscription Plans
                    <FormControlLabel control={<Switch defaultChecked />} label="Autorenew" labelPlacement='start' />
                </DialogTitle>
                <DialogContent dividers>
                    <div className="card-deck mb-3 text-center">
                        <div className="card mb-4 shadow-sm active">
                            <div className="card-header">
                                <span className="badge badge-primary">Active Plan</span>
                                <h4 className="my-0 font-weight-normal">Monthly</h4>
                            </div>
                            <div className="card-body">
                                <p className='fw-bold mb-2 text-primary small'>Start date: 15-09-2022 to Due Date: 15-10-2022</p>
                                <h1 className="card-title pricing-card-title"><i className="fa fa-rupee-sign mr-2"></i>250 <small className="text-muted">/ mo</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>10 users included</li>
                                    <li>Email support</li>
                                    <li>Help center access</li>
                                </ul>
                                <button type="button" className="btn btn-lg btn-block btn-primary disabled">Renew Plan</button>
                            </div>
                        </div>
                        <div className="card mb-4 shadow-sm">
                            <div className="card-header">
                                <h4 className="my-0 font-weight-normal">3 Months</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title"><i className="fa fa-rupee-sign mr-2"></i>750 <small className="text-muted">/ mo</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>20 users included</li>
                                    <li>Priority email support</li>
                                    <li>Help center access</li>
                                </ul>
                                <button type="button" className="btn btn-lg btn-block btn-primary">Get started</button>
                            </div>
                        </div>
                        <div className="card mb-4 shadow-sm">
                            <div className="card-header">
                                <h4 className="my-0 font-weight-normal">Yearly</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title"><i className="fa fa-rupee-sign mr-2"></i>2000 <small className="text-muted">/ mo</small></h1>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>50 users included</li>
                                    <li>Phone and email support</li>
                                    <li>Help center access</li>
                                </ul>
                                <button type="button" className="btn btn-lg btn-block btn-primary">Get started</button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </DialogBox>
            <Box>
                <Button className='primary-button'
                    size='small'
                    onClick={() => {
                        setMode('ADD')
                        setOpenModal(true)
                    }}
                >Make a Payment
                </Button>
                <br />
                <br />

                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table" size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Active Plan</TableCell>
                                    <TableCell>Start Date</TableCell>
                                    <TableCell>End Date</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Document/Items</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Monthly</TableCell>
                                    <TableCell>15-08-2022 11:20AM</TableCell>
                                    <TableCell>15-09-2022 11:20AM</TableCell>
                                    <TableCell>Rs. 250</TableCell>
                                    <TableCell>
                                        <span className="badge badge-pill badge-success">Paid</span>
                                    </TableCell>
                                    <TableCell>
                                        <Link className='btn btn-primary mr-2'><i className="fas fa-file-download"></i> Invoice</Link>
                                        <Link className='btn btn-primary'><i className="fas fa-file-download"></i></Link>
                                    </TableCell>
                                </TableRow>
                                {/* {props.staffList
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover key={row.user_id} rowSpan={row.duty_timings?.length}>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.mobile}</TableCell>
                                                <TableCell>{row.role}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>
                                                    <ul>
                                                        {row.duty_timings?.map((item) => (

                                                            <li key={item.id}>{`${item.day}: ${item.from_time} - ${item.to_time}`}</li>
                                                        ))}
                                                    </ul>
                                                </TableCell>
                                                <TableCell>{row.uniqueIdNumber}</TableCell>
                                                <TableCell>
                                                    <ul className="d-flex options">
                                                        <li className="p-2 btn btn-sm btn-outline-primary mr-2" data-toggle="modal" data-target=".viewModal" onClick={() => setState({ ...state, staff_id: row.user_id, confirm_password: row.password, ...row, })}><i className="fas fa-eye"></i></li>
                                                        <li className="p-2 btn btn-sm btn-outline-success mr-2" data-toggle="modal" data-target=".editemodel" onClick={() => setState({ ...state, staff_id: row.user_id, ...row, })}><i className="fas fa-pencil-alt"></i></li>
                                                        <li className="p-2 btn btn-sm btn-outline-danger" onClick={() => {
                                                            setStaffID(row.user_id);
                                                            setOnDelete(true);
                                                        }}>
                                                            <i className="fas fa-trash-alt" ></i></li>
                                                    </ul>
                                                </TableCell>

                                            </TableRow>
                                        );
                                    })} */}


                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={props.staffList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    // getStaffCateList: (params) => dispatch(GetStaffCategoryList(params)),
    // getStaffList: (params) => dispatch(getStaffList(params)),
    // AddStaff: params => dispatch(AddStaff(params)),
    // EditStaff: params => dispatch(EditStaff(params)),
    // DeleteStaff: params => dispatch(DeleteStaff(params))

})

const mapStateToProps = state => {
    return {
        staffList:
            state.mainReducer?.staffListReducer?.response?.data || [],
        staffCateList:
            state.mainReducer?.staffCategoryListReducer?.response?.data || [],
        deleteStaffStatus: state.mainReducer?.deleteStaffReducer?.response?.status,

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPayment)