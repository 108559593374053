
import React, { Component } from 'react';
import SearchFilterComponent from '../../Search/SearchFilter/SearchFilter';
import { Link } from 'react-router-dom';
import { AllPrescriptionDTOs } from '../../../mock/AllPrescription';
import { MedicineRowComponent } from '../prescription/MedicineRow';
import HospitalFilterComponent from '../../Search/SearchFilter/HospitalFilter';
import AllInvoiceResults from '../invoice/AllInvoiceResult';
import AllPrescriptionResults from './AllPrescriptionResults';
import { connect } from 'react-redux';
import { getAppointmentsCountAction, getStaffListByDateAction, getPatientPrescriptionAction } from 'src/redux/Main/actions';
import { useCookies } from 'react-cookie';


const UserAllPrescriptionComponent = (props) => {
    console.log(props)
    return (
        <>
            <React.Fragment>
                <div className="w-100 pos_fix">
                    <div className="direction_row d-flex justify-content-between">
                        <div>
                            <select className="form-control textarea_size">
                                <option value="">Today's Appointment</option>
                                <option value="">Tomorrow's Appointment</option>
                                <option value="">Yesterday's Appointment</option>
                                <option value="">This Week's Appointment</option>
                                <option value="">Last Month's Appointment</option>
                                <option value="">Last 6 Month's Appointment</option>
                                <option value="">Last Year's Appointment</option>
                                <option value="">Next Week's Appointment</option>
                                <option value="">Next Month's Appointment</option>
                                <option value="">Next 6 Month's Appointment</option>
                                <option value="">Next Year's Appointment</option>
                                <option value="">Till Date (All) Appointment</option>
                            </select>
                        </div>
                        <div>
                            <p className="w-100 p-2"><strong className="font_13">BACKER HEART HOSPITAL</strong></p>
                        </div>
                        <div className="mt-2">
                            <Link to='/user/allInvoice' className="btn-primary p-1" >All Invoice</Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 d-none d-md-block">
                        {/* <HospitalFilterComponent onSelectFilter={this.selectedFilter} /> */}
                    </div>
                    <div className="row mt-2">
                        {/* {(this.state.prescriptionDTOs !== undefined && this.state.prescriptionDTOs !== null && this.state.prescriptionDTOs.length > 0) ?
                             this.state.prescriptionDTOs.map((prescription, i) => {
                                 return <AllPrescriptionResults key={prescription.id} prescription={prescription} ></AllPrescriptionResults>
                             }) : ''} */}
                        <AllPrescriptionResults />
                    </div>
                </div>
            </React.Fragment>z
        </>
    )

}


const mapDispatchToProps = dispatch => ({
    getPatientPrescriptionAction: (params) => dispatch(getPatientPrescriptionAction(params)),

})

const mapStateToProps = state => {
    return {
        prescription:
            state.mainReducer?.patientPrescriptionReducer?.response?.data || [],
        staffList:
            state.mainReducer?.getStaffListByDateReducer?.response?.data || [],

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAllPrescriptionComponent);

// class UserAllPrescriptionComponent extends Component {
//     prescriptionDto = null;
//     doctorId = '';
//     constructor(props) {
//         super(props);
//         this.state = {
//             prescriptionDTOs: AllPrescriptionDTOs
//         };
//         this.getUserPrescription = this.getUserPrescription.bind(this);

//     }



//     componentDidMount() {
//         setTimeout(() => {
//             this.setState({ prescriptionDTOs: AllPrescriptionDTOs });
//         }, 10);



//     }

//     getUserPrescription() {

//         //this.doctorId=this.props.location.state.doctorId;

//         //this.state.prescriptionDto=PrescriptionDTO;
//         //this.state.setState({prescriptionDto:PrescriptionDTO});
//     }


//     render() {

//         this.getUserPrescription();
//         return (
//             <React.Fragment>
//                 {/* <div className="w-100 pos_fix">
//                     <div className="direction_row d-flex justify-content-between">
//                         <div>
//                             <select className="form-control textarea_size">
//                                 <option value="">Today's Appointment</option>
//                                 <option value="">Tomorrow's Appointment</option>
//                                 <option value="">Yesterday's Appointment</option>
//                                 <option value="">This Week's Appointment</option>
//                                 <option value="">Last Month's Appointment</option>
//                                 <option value="">Last 6 Month's Appointment</option>
//                                 <option value="">Last Year's Appointment</option>
//                                 <option value="">Next Week's Appointment</option>
//                                 <option value="">Next Month's Appointment</option>
//                                 <option value="">Next 6 Month's Appointment</option>
//                                 <option value="">Next Year's Appointment</option>
//                                 <option value="">Till Date (All) Appointment</option>
//                             </select>
//                         </div>
//                         <div>
//                             <p className="w-100 p-2"><strong className="font_13">BACKER HEART HOSPITAL</strong></p>
//                         </div>
//                         <div className="mt-2">
//                             <Link to='/user/allInvoice' className="btn-primary p-1" >All Invoice</Link>
//                         </div>
//                     </div>
//                 </div> */}

//                 <div className="row">
//                     <div className="col-md-12 d-none d-md-block">
//                         <HospitalFilterComponent onSelectFilter={this.selectedFilter} />
//                     </div>

//                     <div className="row mt-2">
//                         {(this.state.prescriptionDTOs !== undefined && this.state.prescriptionDTOs !== null && this.state.prescriptionDTOs.length > 0) ?
//                             this.state.prescriptionDTOs.map((prescription, i) => {
//                                 return <AllPrescriptionResults key={prescription.id} prescription={prescription} ></AllPrescriptionResults>
//                             }) : ''}
//                     </div>
//                 </div>


//             </React.Fragment>

//         )
//     }
// }
// export default UserAllPrescriptionComponent;