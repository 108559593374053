import actionTypes from "../actionTypes";

const countryState = {
    isFetching: false,
    response: null

}
export const getCountryCodesReducer = (state = countryState, action) => {
    switch (action.type) {
        case actionTypes.GET_COUNTRY_CODES_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_COUNTRY_CODES_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_COUNTRY_CODES_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const loginState = {
    isFetching: false,
    response: null
}

export const loginReducer = (state = loginState, action) => {
    switch (action.type) {
        case actionTypes.USER_LOGIN_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.USER_LOGIN_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.USER_LOGIN_FAILURE:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.USER_LOGOUT:
            return { ...state, response: null }

        default:
            return state;
    }
}

const loginOtpState = {
    isFetching: false,
    response: null
}
export const loginWithOTPReducer = (state = loginOtpState, action) => {
    switch (action.type) {
        case actionTypes.USER_LOGIN_WITH_OTP_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.USER_LOGIN_WITH_OTP_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.USER_LOGIN_WITH_OTP_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const hospitalLogin = {
    isFetching: false,
    response: null
}
export const hospitalLoginReducer = (state = hospitalLogin, action) => {
    switch (action.type) {
        case actionTypes.HOSPITAL_LOGIN_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.HOSPITAL_LOGIN_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.HOSPITAL_LOGIN_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const registation = {
    isFetching: false,
    response: null
}
export const userRegistrationReducer = (state = registation, action) => {
    switch (action.type) {
        case actionTypes.USER_REGISTRATION_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.USER_REGISTRATION_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.USER_REGISTRATION_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const verifyOtp = {
    isFetching: false,
    response: null
}
export const userVerifyOTPReducer = (state = verifyOtp, action) => {
    switch (action.type) {
        case actionTypes.USER_VERIFY_OTP_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.USER_VERIFY_OTP_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.USER_VERIFY_OTP_FAILURE:
            return { ...state, isFetching: false, response: action.response };
            case actionTypes.EMPTY_USER_REGISTRATION:
                return { ...state, isFetching: false, response: verifyOtp };


        default:
            return state;
    }
}

const addUserDetails = {
    isFetching: false,
    response: null
}
export const addUserDetailsReducer = (state = addUserDetails, action) => {
    switch (action.type) {
        case actionTypes.ADD_USER_DETAILS_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.ADD_USER_DETAILS_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.ADD_USER_DETAILS_FAILURE:
            return { ...state, isFetching: false, response: action.response }
        case actionTypes.EMPTY_USER_REGISTRATION:
            return { ...state, isFetching: false, response: addUserDetails }

        default:
            return state;
    }
}

const forgotPswd = {
    isFetching: false,
    response: null
}

export const ForgotPasswordReducer = (state = forgotPswd, action) => {
    switch (action.type) {
        case actionTypes.FORGOT_PSWD_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.FORGOT_PSWD_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.FORGOT_PSWD_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const changePswd = {
    isFetching: false,
    response: null
}

export const ChangePasswordReducer = (state = changePswd, action) => {
    switch (action.type) {
        case actionTypes.CHANGE_PSWD_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.CHANGE_PSWD_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.CHANGE_PSWD_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const verifyOtpForPswd = {
    isFetching: false,
    response: null
}

export const VerifyOTPForPswdReducer = (state = verifyOtpForPswd, action) => {
    switch (action.type) {
        case actionTypes.FORGOT_PSWD_VERYFY_OTP_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.FORGOT_PSWD_VERYFY_OTP_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.FORGOT_PSWD_VERYFY_OTP_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const hospitalReg = {
    isFetching: false,
    response: null
}

export const HospitalRegistrationReducer = (state = hospitalReg, action) => {
    switch (action.type) {
        case actionTypes.HOSPITAL_REGISTRATION_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.HOSPITAL_REGISTRATION_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.HOSPITAL_REGISTRATION_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}