import React from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
import '../../assets/style.css';
import logImage from "../../assets/images/aajivan3.png";
import HeaderLoggedIn from './HeaderLoggedIn';
import HospitalLoggedInComponent from './HospitalLoggedIn';
import AdminHeader from './Adminheader';
import { UserContext } from '../../state/UserState';
import Adminheader from './Adminheader';
// import {UserAuthService} from '../../service/UserAuthService';
import { connect } from "react-redux";
import { useCookies } from 'react-cookie';


const HeaderComponent = (props) => {

  console.log('props', props)
  const userDetails = React.useContext(UserContext);
  const [cookies, setCookie] = useCookies(['RoleID']);


  const { RoleID = null } = cookies || {}

  return (
    <div className="header">
      {(() => {
        if (RoleID === '3') {
          return <HeaderLoggedIn />
        } else if (RoleID === '2') {
          return <HospitalLoggedInComponent />
        }
        else if (RoleID === '1') {
          return <Adminheader />
        }
        // else if(userDetails.token !== null && userDetails.token == '111' && userDetails.role == 'ADMIN'){
        //   return <Adminheader/>
        // }

        // if(userDetails.token !== null && userDetails.token == '123' && userDetails.role == 'HOSPITAL'){
        //     return <HospitalLoggedInComponent/>
        // if(userDetails.token !== null && userDetails.token == '111' && userDetails.role == 'ADMIN'){
        //   return <Adminheader/>
        // }

        else {
          return (
            <nav className="navbar navbar-expand-lg navbar-light nav_bg">
              <div className="container">
                <div className="w-100 d-flex justify-content-between">
                  <div className="text-left d-flex align-items-center">
                    <Link className="navbar-brand" to="/"><img className="mb-1 logo_img" src={logImage} alt="logo" /> Aajivan</Link>
                  </div>
                  <div className="text-right d-flex">
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                      data-target="#selectProfile" aria-controls="selectProfile"
                      aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="selectProfile">
                      <Link to="/userLogin" className="btn btn-primary my-2 my-sm-0 mr-0 mr-md-2">User Login</Link>
                      <Link to="/hospitalLogin" className="btn btn-primary my-2 my-sm-0 mr-0 mr-md-2">Hospital/Doctor Login</Link>
                      <Link to="/adminlogin" className="btn btn-primary my-2 my-sm-0">Admin Login</Link>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          )
        }
      })()}
    </div>
  )
  // }
}

export default withRouter(HeaderComponent)