import React, { Component } from 'react';
import * as $ from 'jquery';
import { Link } from 'react-router-dom';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import RegistrationCouncilList from '../../../mock/hospital/RegistrationCouncil';
import HospitalProfile from '../../../mock/hospital/HospitalProfile';
import RegistrationTypeList from '../../../mock/hospital/RegistrationType';


class Hospitaldetail extends Component{

	registrationCouncilList=[];
	registrationTypeList=[];
	constructor(props) {
		super(props);
		this.state = {
            hospitalProfile:HospitalProfile
        };
	}
    componentDidMount() {
		document.title = "Hospital Detail"

        $("#add_location").hide();
        $(".new_location").on('click', function(){
            $("#add_location").show();
        });

        $(".addTime").on('click', function(){
            console.log('yes');
        });



        // upload profile
        var readURL = function(input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    $('.profile-pic').attr('src', e.target.result);
                }
                reader.readAsDataURL(input.files[0]);
            }
        }

        $(".file-upload").on('change', function(){
            readURL(this);
        });

        $(".upload-button").on('click', function() {
            $(".file-upload").click();
        });


        // add more general time
        $(".addMoreBtnGeneral").on("click", function (){
            var html = $(".addMoreGeneralTime").first().clone();
            $(html).find(".change").html("<a href='#' className='btn btn-outline-danger removeGeneralTime'>Remove</a>");

            $(".addMoreGeneralTime").last().after(html);
        });
        $(".removeGeneralTime").on("click",function (){
            alert('yes');
            $(this).parents(".addMoreGeneralTime").remove();
        });

        // add more Qualification
        $(".addMoreBtnQualification").on("click", function (){
            var html = $(".addMoreQualification").first().clone();
            $(html).find(".change").html("<a href='javascript:void(0)' className='btn btn-outline-danger removeQualification'>Remove</a>");

            $(".addMoreQualification").first().after(html);
        });
        $(".removeQualification").on("click",function(){
            alert('yes');
            $(this).parents(".addMoreQualification").remove();
        });


        $('.btn-toggle').on('click', function() {
            $(this).find('.btn').toggleClass('active');
            if ($(this).find('.btn-primary').length>0) {
                $(this).find('.btn').toggleClass('btn-primary');
            }
        });

    }

    getInitialData(){
        this.registrationCouncilList=RegistrationCouncilList;
        this.registrationTypeList=RegistrationTypeList;
    }

    handleChange=(e)=>{
        // e.stopPropagation();
		// e.preventDefault();
        if (["registrationNo", "registrationCouncil", "registrationType", "comment"].includes(e.target.name)) {
            let name=e.target.name;
            const hospitalProfile = { ...this.state.hospitalProfile,[e.target.name]:e.target.value};
            this.setState({hospitalProfile:hospitalProfile});
        }
        // let hospitalProfile=this.state.hospitalProfile;
        // hospitalProfile.registrationNo=e.target.value;
		// this.setState({hospitalProfile:hospitalProfile});
    }

    handleSelect(ranges){
        console.log(ranges);
    }

    render() {
        const selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }

        this.getInitialData();
		const userDetails= this.context;
        return (
			<React.Fragment>
                <div className="container-fluid bootstrap snippet">
                    <div className="row mt-md-5 mt-0">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="text-center">
                                        <div className="col-md-12 col-12">
                                            <div className="small-12 medium-2 large-2 columns">
                                                <div className="circle_profile mb-3">
                                                    <img className="profile-pic" src={require('../../../assets/images/logo.jpg').default} />
                                                    <div className="profile_image">
                                                        <i className="fa fa-camera upload-button"></i>
                                                        <input className="file-upload" type="file" accept="image/*" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-9">
                                    <h5 className='fw-bold text-md-start text-center h3 mb-2 d-flex justify-content-between'>Hospital Name
                                        <span className='d-flex align-items-center'>
                                            <p className='small ml-4'>Account Status:</p>
                                            <div class="btn-group btn-toggle border ml-2">
                                                <button class="btn btn-sm btn-default">Active</button>
                                                <button class="btn btn-sm btn-primary active">Deactive</button>
                                            </div>
                                        </span>

                                        <div>
                                        <button className='btn btn-primary mr-2'>Verified</button>
                                        <button type="submit" className='btn btn-primary'>Save</button>
                                        </div>
                                    </h5>
                                    <ul className="nav nav-tabs textarea_size" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Owner's Account Setting & Password</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#clinic" role="tab" aria-controls="clinic" aria-selected="true">Our Doctors</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#subscriptionPayment" role="tab" aria-controls="subscriptionPayment" aria-selected="true">Subscription Payment </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#documents" role="tab" aria-controls="documents" aria-selected="true">Documents </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#location" role="tab" aria-controls="lcaotion" aria-selected="true">Locations </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#staff" role="tab" aria-controls="documents" aria-selected="true">Staff </a>
                                        </li>
                                    </ul>

                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show" id="clinic" role="tabpanel" aria-labelledby="home-tab">
                                            <div>
                                                <form action="">
                                                    <div className="row">
                                                        <div className="col-md-12 col-12 mt-2 d-flex">
                                                            <Link className='btn btn-outline-primary ml-3 d-flex align-items-center' data-toggle="modal" data-target=".addDoctormodel"><i className="fas fa-2x fa-plus mr-2"></i> Add Doctors at this hospital</Link>
                                                        </div>

                                                        <div className="col-md-12">
                                                            <div className="accordion mt-3 mb-2 row" id="docVarification">
                                                                <div className="col-md-4 col-12">
                                                                    <Link to="/hospitalname/drjack" className='d-block'>
                                                                        <div className="card">
                                                                            <Link to="/hospitalname/editdoctor" className='btn btn-outline-primary btn-sm editDoctor'>Edit</Link>
                                                                            <button className='btn btn-outline-danger btn-sm deleteDoctor'>Delete</button>
                                                                            <div className="card-header">
                                                                                <div className="mb-0" data-toggle="collapse" data-target="#docCol1" aria-expanded="true" aria-controls="docCol1">
                                                                                    <div className="cardImg mr-2">
                                                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="Doctor Photo" />
                                                                                    </div>
                                                                                    <div className="docInfo">
                                                                                        <h5>Dr. Jack</h5>
                                                                                        <Link to="tel:1236547898"><i className="fas fa-phone-alt"></i> +91 1234567890</Link>
                                                                                        <Link to="mailto:test@gmail.com"><i className="fas fa-envelope"></i> test@gmail.com</Link>

                                                                                        <div className="shadow-sm card p-1 mt-2">
                                                                                            <p className='text-center'>My availability</p>
                                                                                            <ul className="nav nav-tabs border-0 w-100">
                                                                                                <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                                <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime1">Slot time </a>

                                                                                                </li>
                                                                                            </ul>

                                                                                            <div className="tab-content p-2">
                                                                                                <div id="generalTime1" className="tab-pane fade show active">
                                                                                                    <div className="mb-2">
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div id="slotTime1" className="tab-pane fade">
                                                                                                    <div className="mb-2">
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row">
                                                                                            <div className="col-md-4 col-12 mt-2 mt-md-2">
                                                                                                <label>Registration No.</label>
                                                                                                <input type="text" className="form-control"
                                                                                                placeholder="Registration Number"
                                                                                                name="registrationNo"
                                                                                                value={this.state.hospitalProfile.registrationNo}
                                                                                                onChange={ (e) => {this.handleChange(e)}}
                                                                                                />
                                                                                            </div>

                                                                                            <div className="col-md-4 col-6 mt-2">
                                                                                                <label>Registration Council</label>
                                                                                                <input className="form-control" list="council"
                                                                                                placeholder="Registration Council"
                                                                                                name="registrationCouncil"
                                                                                                value={this.state.hospitalProfile.registrationCouncil.name}
                                                                                                onChange={ (e) => {this.handleChange(e)}}
                                                                                                />
                                                                                                <datalist id="council">
                                                                                                    {this.registrationCouncilList.map((location) => {
                                                                                                        return <option key={location.id} value={location.name}></option>
                                                                                                    })}
                                                                                                </datalist>
                                                                                            </div>

                                                                                            <div className="col-md-4 col-6 mt-2">
                                                                                                <label>Registration Type</label>
                                                                                                <input className="form-control"
                                                                                                list="type"
                                                                                                name="registrationType"
                                                                                                placeholder="Registration Type"
                                                                                                value={this.state.hospitalProfile.registrationType.name}
                                                                                                onChange={ (e) => {this.handleChange(e)}}
                                                                                                />
                                                                                                <datalist id="type">
                                                                                                    {this.registrationTypeList.map((location) => {
                                                                                                        return <option key={location.id} value={location.name}></option>
                                                                                                    })}
                                                                                                </datalist>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>

                                                                <div className="col-md-4 col-12">
                                                                    <div className="card">
                                                                        <Link to="/hospitalname/editdoctor" className='btn btn-outline-primary btn-sm editDoctor'>Edit</Link>
                                                                        <button className='btn btn-outline-danger btn-sm deleteDoctor'>Delete</button>
                                                                        <div className="card-header">
                                                                            <div className="mb-0" data-toggle="collapse" data-target="#docCol2" aria-expanded="true" aria-controls="docCol1">
                                                                                <div className="cardImg mr-2">
                                                                                    <img src={require('../../../assets/images/home/doctor.png').default} alt="Doctor Photo" />
                                                                                </div>
                                                                                <div className="docInfo">
                                                                                    <h5>Dr. Jack</h5>
                                                                                    <Link to="tel:1236547898"><i className="fas fa-phone-alt"></i> +91 1234567890</Link>
                                                                                    <Link to="mailto:test@gmail.com"><i className="fas fa-envelope"></i> test@gmail.com</Link>

                                                                                    <div className="shadow-sm card p-1 mt-2">
                                                                                        <p className='text-center'>My availability</p>
                                                                                        <ul className="nav nav-tabs border-0 w-100">
                                                                                            <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                            <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime2">Slot time </a>

                                                                                            </li>
                                                                                        </ul>

                                                                                        <div className="tab-content p-2">
                                                                                            <div id="generalTime2" className="tab-pane fade show active">
                                                                                                <div className="mb-2">
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div id="slotTime2" className="tab-pane fade">
                                                                                                <div className="mb-2">
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-md-4 col-12 mt-2 mt-md-2">
                                                                                            <label>Registration No.</label>
                                                                                            <input type="text" className="form-control"
                                                                                            placeholder="Registration Number"
                                                                                            name="registrationNo"
                                                                                            value={this.state.hospitalProfile.registrationNo}
                                                                                            onChange={ (e) => {this.handleChange(e)}}
                                                                                            />
                                                                                        </div>

                                                                                        <div className="col-md-4 col-6 mt-2">
                                                                                            <label>Registration Council</label>
                                                                                            <input className="form-control" list="council"
                                                                                            placeholder="Registration Council"
                                                                                            name="registrationCouncil"
                                                                                            value={this.state.hospitalProfile.registrationCouncil.name}
                                                                                            onChange={ (e) => {this.handleChange(e)}}
                                                                                            />
                                                                                            <datalist id="council">
                                                                                                {this.registrationCouncilList.map((location) => {
                                                                                                    return <option key={location.id} value={location.name}></option>
                                                                                                })}
                                                                                            </datalist>
                                                                                        </div>

                                                                                        <div className="col-md-4 col-6 mt-2">
                                                                                            <label>Registration Type</label>
                                                                                            <input className="form-control"
                                                                                            list="type"
                                                                                            name="registrationType"
                                                                                            placeholder="Registration Type"
                                                                                            value={this.state.hospitalProfile.registrationType.name}
                                                                                            onChange={ (e) => {this.handleChange(e)}}
                                                                                            />
                                                                                            <datalist id="type">
                                                                                                {this.registrationTypeList.map((location) => {
                                                                                                    return <option key={location.id} value={location.name}></option>
                                                                                                })}
                                                                                            </datalist>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4 col-12">
                                                                    <div className="card">
                                                                        <Link to="/hospitalname/editdoctor" className='btn btn-outline-primary btn-sm editDoctor'>Edit</Link>
                                                                        <button className='btn btn-outline-danger btn-sm deleteDoctor'>Delete</button>
                                                                        <div className="card-header">
                                                                            <div className="mb-0" data-toggle="collapse" data-target="#docCol3" aria-expanded="true" aria-controls="docCol1">
                                                                                <div className="cardImg mr-2">
                                                                                    <img src={require('../../../assets/images/home/doctor.png').default} alt="Doctor Photo" />
                                                                                </div>
                                                                                <div className="docInfo">
                                                                                    <h5>Dr. Jack</h5>
                                                                                    <Link to="tel:1236547898"><i className="fas fa-phone-alt"></i> +91 1234567890</Link>
                                                                                    <Link to="mailto:test@gmail.com"><i className="fas fa-envelope"></i> test@gmail.com</Link>
                                                                                    <div className="shadow-sm card p-1 mt-2">
                                                                                        <p className='text-center'>My availability</p>
                                                                                        <ul className="nav nav-tabs border-0 w-100">
                                                                                            <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                            <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime3">Slot time </a>

                                                                                            </li>
                                                                                        </ul>

                                                                                        <div className="tab-content p-2">
                                                                                            <div id="generalTime3" className="tab-pane fade show active">
                                                                                                <div className="mb-2">
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div id="slotTime3" className="tab-pane fade">
                                                                                                <div className="mb-2">
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                    <p>Monday : 10:AM to 05:PM</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row">
                                                                                        <div className="col-md-4 col-12 mt-2 mt-md-2">
                                                                                            <label>Registration No.</label>
                                                                                            <input type="text" className="form-control"
                                                                                            placeholder="Registration Number"
                                                                                            name="registrationNo"
                                                                                            value={this.state.hospitalProfile.registrationNo}
                                                                                            onChange={ (e) => {this.handleChange(e)}}
                                                                                            />
                                                                                        </div>

                                                                                        <div className="col-md-4 col-6 mt-2">
                                                                                            <label>Registration Council</label>
                                                                                            <input className="form-control" list="council"
                                                                                            placeholder="Registration Council"
                                                                                            name="registrationCouncil"
                                                                                            value={this.state.hospitalProfile.registrationCouncil.name}
                                                                                            onChange={ (e) => {this.handleChange(e)}}
                                                                                            />
                                                                                            <datalist id="council">
                                                                                                {this.registrationCouncilList.map((location) => {
                                                                                                    return <option key={location.id} value={location.name}></option>
                                                                                                })}
                                                                                            </datalist>
                                                                                        </div>

                                                                                        <div className="col-md-4 col-6 mt-2">
                                                                                            <label>Registration Type</label>
                                                                                            <input className="form-control"
                                                                                            list="type"
                                                                                            name="registrationType"
                                                                                            placeholder="Registration Type"
                                                                                            value={this.state.hospitalProfile.registrationType.name}
                                                                                            onChange={ (e) => {this.handleChange(e)}}
                                                                                            />
                                                                                            <datalist id="type">
                                                                                                {this.registrationTypeList.map((location) => {
                                                                                                    return <option key={location.id} value={location.name}></option>
                                                                                                })}
                                                                                            </datalist>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                            <div>
                                                <form>
                                                    <div className="row">
                                                        <div className="col-md-4 col-6 mt-2">
                                                            <input type="text" className="form-control" value="Prathap Reddy" placeholder="Name" />
                                                        </div>

                                                        <div className="col-md-4 col-6 mt-2">
                                                            <input type="text" className="form-control" value="1236547898" placeholder="Phone Number" />
                                                        </div>

                                                        <div className="col-md-4 col-12 mt-2">
                                                            <input type="text" className="form-control" value="demo@mail.com" placeholder="Email Id" />
                                                        </div>

                                                        <div className="col-md-4 col-6 mt-2">
                                                            <input className="form-control" list="city" value="Delhi" placeholder="City" />
                                                            <datalist id="city">
                                                                <option defaultValue="Select City" />
                                                                <option value="Vadodara" />
                                                                <option value="Ahmedabad" />
                                                                <option value="Delhi" />
                                                                <option value="Surat" />
                                                                <option value="Pune" />
                                                            </datalist>
                                                        </div>

                                                        <div className="col-md-4 col-6 mt-2">
                                                            <input className="form-control" list="State" value="Delhi" placeholder="State" />
                                                            <datalist id="State">
                                                                <option defaultValue="Select State" />
                                                                <option value="Gujarat" />
                                                                <option value="Maharashtra" />
                                                                <option value="Uttar Pradesh" />
                                                                <option value="Rajasthan" />
                                                                <option value="Kerala" />
                                                            </datalist>
                                                        </div>

                                                        <div className="col-md-4 col-6 mt-2">
                                                            <input className="form-control" list="country" value="India" placeholder="Country" />
                                                            <datalist id="country">
                                                                <option defaultValue="Select Country" />
                                                                <option value="India" />
                                                                <option value="Canada" />
                                                                <option value="USA" />
                                                                <option value="UK" />
                                                                <option value="Russia" />
                                                            </datalist>
                                                        </div>

                                                        <div className="col-md-4 col-6 mt-2">
                                                            <input type="text" className="form-control" value="110005" placeholder="Pincode" />
                                                        </div>

                                                        <div className="col-md-4 col-12 pt-2 mb-3 d-flex align-items-center">
                                                            <label className='mr-3' htmlFor="gender"><strong>Gender</strong></label>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="male" name="customRadioInline" className="custom-control-input" checked/>
                                                                <label className="custom-control-label" htmlFor="male">Male</label>
                                                            </div>
                                                            <div className="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="female" name="customRadioInline" className="custom-control-input"/>
                                                                <label className="custom-control-label" htmlFor="female">Female</label>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="col-md-12">
                                                            <h6 className='text-primary fw-bold'>Change Password</h6>
                                                            <div className="row mb-2">
                                                                <div className="col-md-6 mt-2">
                                                                    <input type="text" className="form-control" placeholder="Current Password" />
                                                                </div>
                                                                <div className="col-md-6">
                                                                </div>
                                                                <div className="col-md-6 mt-2">
                                                                    <input type="text" className="form-control" placeholder="New Password" />
                                                                </div>
                                                                <div className="col-md-6 mt-2">
                                                                    <input type="text" className="form-control" placeholder="Confirm Password" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                        <div className="col-md-12 mt-2">
                                                            <h6 className='text-primary fw-bold'>Upload ID</h6>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <p className='fw-bold'>Uploaded Document</p>
                                                                    <div className='d-flex'>
                                                                        <div className="card mr-2">
                                                                            <div className="card-body text-center">
                                                                                <div className="btn btn-danger btn-sm editDoctor">
                                                                                    <i class="fas fa-trash-alt"></i>
                                                                                </div>
                                                                                <img className='m-auto' src={require('../../../assets/images/doc.png').default} width='80' alt="documents" />
                                                                                <p>Regitration Detail</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card mr-2">
                                                                            <div className="card-body text-center">
                                                                                <div className="btn btn-danger btn-sm editDoctor">
                                                                                    <i class="fas fa-trash-alt"></i>
                                                                                </div>
                                                                                <img className='m-auto' src={require('../../../assets/images/doc.png').default} width='80' alt="documents" />
                                                                                <p>Regitration Detail</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card mr-2">
                                                                            <div className="card-body text-center">
                                                                                <div className="btn btn-danger btn-sm editDoctor">
                                                                                    <i class="fas fa-trash-alt"></i>
                                                                                </div>
                                                                                <img className='m-auto' src={require('../../../assets/images/doc.png').default} width='80' alt="documents" />
                                                                                <p>Regitration Detail</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mt-2">
                                                                    <input type="file" className="form-control" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-12 mb-5 mb-md-0 mt-2">
                                                        <Link className="btn btn-primary float-right mb-3" to="#" data-toggle="modal" data-target="#personalinfo_success">Save</Link>
                                                    </div> */}

                                                </form>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade show" id="subscriptionPayment" role="tabpanel" aria-labelledby="home-tab">
                                            <div className="container-fluid mt-3">
                                                <button className='btn btn-primary mb-2' data-toggle="modal" data-target="#subscriptionPlanModal">Make a Payment</button>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Active Plan</th>
                                                            <th>Start Date</th>
                                                            <th>End Date</th>
                                                            <th>Price</th>
                                                            <th>Status</th>
                                                            <th>Document/Items</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td scope="row">Monthly</td>
                                                            <td>15-08-2022 11:20AM</td>
                                                            <td>15-09-2022 11:20AM</td>
                                                            <td>Rs. 250</td>
                                                            <td><span className="badge badge-pill badge-success">Paid</span></td>
                                                            <td>
                                                                <Link className='btn btn-primary mr-2'>Invoice</Link>
                                                                <Link className='btn btn-primary'><i className="fas fa-file-download"></i></Link>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td scope="row">Monthly</td>
                                                            <td>15-09-2022 2:30PM</td>
                                                            <td>15-10-2022 2:30PM</td>
                                                            <td>Rs. 250</td>
                                                            <td><span className="badge badge-pill badge-warning">Due</span></td>
                                                            <td>
                                                                <Link className='btn btn-success mr-2'>Renew</Link>
                                                                <Link className='btn btn-primary mr-2'>Invoice</Link>
                                                                <Link className='btn btn-primary'><i className="fas fa-file-download"></i></Link>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <div className="modal fade" id="subscriptionPlanModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-xl modal-dialog-centered">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title mr-4">Subscription Plans</h5>
                                                                <p>Autorenew
                                                                <label class="switch">
                                                                    <input type="checkbox" checked/>
                                                                    <span class="slider round"></span>
                                                                </label>
                                                                </p>

                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="card-deck mb-3 text-center">
                                                                    <div className="card mb-4 shadow-sm active">
                                                                        <div className="card-header">
                                                                            <span className="badge badge-primary">Active Plan</span>
                                                                            <h4 className="my-0 font-weight-normal">Monthly</h4>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <p className='fw-bold mb-2 text-primary'>Due Date: 15-10-2022</p>
                                                                            <h1 className="card-title pricing-card-title"><i className="fa fa-rupee-sign mr-2"></i>250 <small className="text-muted">/ mo</small></h1>
                                                                            <ul className="list-unstyled mt-3 mb-4">
                                                                                <li>10 users included</li>
                                                                                <li>Email support</li>
                                                                                <li>Help center access</li>
                                                                            </ul>
                                                                            <button type="button" className="btn btn-lg btn-block btn-primary disabled">Renew Plan</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card mb-4 shadow-sm">
                                                                        <div className="card-header">
                                                                            <h4 className="my-0 font-weight-normal">3 Months</h4>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <h1 className="card-title pricing-card-title"><i className="fa fa-rupee-sign mr-2"></i>750 <small className="text-muted">/ mo</small></h1>
                                                                            <ul className="list-unstyled mt-3 mb-4">
                                                                                <li>20 users included</li>
                                                                                <li>Priority email support</li>
                                                                                <li>Help center access</li>
                                                                            </ul>
                                                                            <button type="button" className="btn btn-lg btn-block btn-primary">Get started</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card mb-4 shadow-sm">
                                                                        <div className="card-header">
                                                                            <h4 className="my-0 font-weight-normal">Yearly</h4>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <h1 className="card-title pricing-card-title"><i className="fa fa-rupee-sign mr-2"></i>2000 <small className="text-muted">/ mo</small></h1>
                                                                            <ul className="list-unstyled mt-3 mb-4">
                                                                                <li>50 users included</li>
                                                                                <li>Phone and email support</li>
                                                                                <li>Help center access</li>
                                                                            </ul>
                                                                            <button type="button" className="btn btn-lg btn-block btn-primary">Get started</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="tab-pane fade show" id="documents" role="tabpanel" aria-labelledby="home-tab">
                                            <div className="mt-md-2 mt-2">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="card shadow-sm p-2">
                                                            <img src={require('../../../assets/images/doc.png').default} alt="pdf1" />
                                                            <p>PDF 1</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="card shadow-sm p-2">
                                                            <img src={require('../../../assets/images/doc.png').default} alt="pdf1" />
                                                            <p>Document 2</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="card shadow-sm p-2">
                                                            <img src={require('../../../assets/images/doc.png').default} alt="pdf1" />
                                                            <p>Document 2</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="card shadow-sm p-2">
                                                            <img src={require('../../../assets/images/doc.png').default} alt="pdf1" />
                                                            <p>Document 3</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="location" role="tabpanel" aria-labelledby="location-tab">
                                            <a href="#" className='btn btn-outline-primary m-2' data-toggle="modal" data-target=".addLocation"><i className="fas fa-plus"></i> Add New Location</a>
                                            <ul className="nav nav-tabs border-0" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" data-toggle="tab" href="#location1" role="tab">Appolo Delhi</a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" data-toggle="tab" href="#location2" role="tab">Appolo Mumbai</a>
                                                </li>
                                            </ul>

                                            <div className="tab-content">
                                                <div className="tab-pane fade show active" id="location1" role="tabpanel">
                                                    <div className="d-flex flex-wrap">
                                                        <div className="locationCard">
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='fw-bold'>Appolo Delhi - <span className='border p-2 rounded shadow-sm'>Lalkila, Delhi, India</span></h5>
                                                                <a href="#" className='btn btn-primary' data-toggle="modal" data-target=".addLocation">Edit</a>
                                                            </div>


                                                            <div className="row">
                                                                <div className="col-md-12 col-sm-6 col-lg-4">
                                                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                                                        <div className="card-body">
                                                                            <div className="pro-widget-content">
                                                                                <div className="profile-info-widget">
                                                                                    <a href="#" className="booking-doc-img">
                                                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                                                    </a>
                                                                                    <div className="profile-det-info">
                                                                                        <h3><a href="#">Dr. Wilson</a></h3>
                                                                                        <p><i className="fas fa-user-clock"></i> BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="patient-info">
                                                                                <div className="patient-details">
                                                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                                                </div>

                                                                                <div className="shadow-sm card p-1 mt-2">
                                                                                    <p className='text-center'>My availability</p>
                                                                                    <ul className="nav nav-tabs border-0 w-100">
                                                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime4">Slot time </a>

                                                                                        </li>
                                                                                    </ul>

                                                                                    <div className="tab-content p-2">
                                                                                        <div id="generalTime4" className="tab-pane fade show active">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div id="slotTime4" className="tab-pane fade">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-sm-6 col-lg-4">
                                                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                                                        <div className="card-body">
                                                                            <div className="pro-widget-content">
                                                                                <div className="profile-info-widget">
                                                                                    <a href="#" className="booking-doc-img">
                                                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                                                    </a>
                                                                                    <div className="profile-det-info">
                                                                                        <h3><a href="#">Dr. Wilson</a></h3>
                                                                                        <p><i className="fas fa-user-clock"></i> BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="patient-info">
                                                                                <div className="patient-details">
                                                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                                                </div>

                                                                                <div className="shadow-sm card p-1 mt-2">
                                                                                    <p className='text-center'>My availability</p>
                                                                                    <ul className="nav nav-tabs border-0 w-100">
                                                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime5">Slot time </a></li>
                                                                                    </ul>

                                                                                    <div className="tab-content p-2">
                                                                                        <div id="generalTime5" className="tab-pane fade show active">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div id="slotTime5" className="tab-pane fade">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-sm-6 col-lg-4">
                                                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                                                        <div className="card-body">
                                                                            <div className="pro-widget-content">
                                                                                <div className="profile-info-widget">
                                                                                    <a href="#" className="booking-doc-img">
                                                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                                                    </a>
                                                                                    <div className="profile-det-info">
                                                                                        <h3><a href="#">Dr. Wilson</a></h3>
                                                                                        <p><i className="fas fa-user-clock"></i> BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="patient-info">
                                                                                <div className="patient-details">
                                                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                                                </div>

                                                                                <div className="shadow-sm card p-1 mt-2">
                                                                                    <p className='text-center'>My availability</p>
                                                                                    <ul className="nav nav-tabs border-0 w-100">
                                                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime6">Slot time </a></li>
                                                                                    </ul>

                                                                                    <div className="tab-content p-2">
                                                                                        <div id="generalTime6" className="tab-pane fade show active">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div id="slotTime6" className="tab-pane fade">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-sm-6 col-lg-4">
                                                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                                                        <div className="card-body">
                                                                            <div className="pro-widget-content">
                                                                                <div className="profile-info-widget">
                                                                                    <a href="#" className="booking-doc-img">
                                                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                                                    </a>
                                                                                    <div className="profile-det-info">
                                                                                        <h3><a href="#">Staff 1</a></h3>
                                                                                        <p><i className="fas fa-user-clock"></i> Nurse</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="patient-info">
                                                                                <div className="patient-details">
                                                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                                                </div>

                                                                                <div className="shadow-sm card p-1 mt-2">
                                                                                    <p className='text-center'>My availability</p>
                                                                                    <ul className="nav nav-tabs border-0 w-100">
                                                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime7">Slot time </a>

                                                                                        </li>
                                                                                    </ul>

                                                                                    <div className="tab-content p-2">
                                                                                        <div id="generalTime7" className="tab-pane fade show active">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div id="slotTime7" className="tab-pane fade">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-sm-6 col-lg-4">
                                                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                                                        <div className="card-body">
                                                                            <div className="pro-widget-content">
                                                                                <div className="profile-info-widget">
                                                                                    <a href="#" className="booking-doc-img">
                                                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                                                    </a>
                                                                                    <div className="profile-det-info">
                                                                                        <h3><a href="#">Staff 2</a></h3>
                                                                                        <p><i className="fas fa-user-clock"></i> Receptionist 1</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="patient-info">
                                                                                <div className="patient-details">
                                                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                                                </div>

                                                                                <div className="shadow-sm card p-1 mt-2">
                                                                                    <p className='text-center'>My availability</p>
                                                                                    <ul className="nav nav-tabs border-0 w-100">
                                                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime8">Slot time </a>

                                                                                        </li>
                                                                                    </ul>

                                                                                    <div className="tab-content p-2">
                                                                                        <div id="generalTime8" className="tab-pane fade show active">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div id="slotTime8" className="tab-pane fade">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-sm-6 col-lg-4">
                                                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                                                        <div className="card-body">
                                                                            <div className="pro-widget-content">
                                                                                <div className="profile-info-widget">
                                                                                    <a href="#" className="booking-doc-img">
                                                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                                                    </a>
                                                                                    <div className="profile-det-info">
                                                                                        <h3><a href="#">Staff 3</a></h3>
                                                                                        <p><i className="fas fa-user-clock"></i> Receptionist 2</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="patient-info">
                                                                                <div className="patient-details">
                                                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                                                </div>

                                                                                <div className="shadow-sm card p-1 mt-2">
                                                                                    <p className='text-center'>My availability</p>
                                                                                    <ul className="nav nav-tabs border-0 w-100">
                                                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime9">Slot time </a>

                                                                                        </li>
                                                                                    </ul>

                                                                                    <div className="tab-content p-2">
                                                                                        <div id="generalTime9" className="tab-pane fade show active">
                                                                                            <div className="mb-2">
                                                                                            <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div id="slotTime9" className="tab-pane fade">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade show" id="location2" role="tabpanel">
                                                <div className="d-flex flex-wrap">
                                                        <div className="locationCard">
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <h5 className='fw-bold'>Appolo Mumbai - <span className='border p-2 rounded shadow-sm'>Thane, Mumbai, India</span></h5>
                                                                <a href="#" className='btn btn-primary' data-toggle="modal" data-target=".addLocation">Edit</a>
                                                            </div>


                                                            <div className="row">
                                                                <div className="col-md-12 col-sm-6 col-lg-4">
                                                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                                                        <div className="card-body">
                                                                            <div className="pro-widget-content">
                                                                                <div className="profile-info-widget">
                                                                                    <a href="#" className="booking-doc-img">
                                                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                                                    </a>
                                                                                    <div className="profile-det-info">
                                                                                        <h3><a href="#">Dr. Wilson</a></h3>
                                                                                        <p><i className="fas fa-user-clock"></i> BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="patient-info">
                                                                                <div className="patient-details">
                                                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                                                </div>

                                                                                <div className="shadow-sm card p-1 mt-2">
                                                                                    <p className='text-center'>My availability</p>
                                                                                    <ul className="nav nav-tabs border-0 w-100">
                                                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime4">Slot time </a>

                                                                                        </li>
                                                                                    </ul>

                                                                                    <div className="tab-content p-2">
                                                                                        <div id="generalTime4" className="tab-pane fade show active">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div id="slotTime4" className="tab-pane fade">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-sm-6 col-lg-4">
                                                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                                                        <div className="card-body">
                                                                            <div className="pro-widget-content">
                                                                                <div className="profile-info-widget">
                                                                                    <a href="#" className="booking-doc-img">
                                                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                                                    </a>
                                                                                    <div className="profile-det-info">
                                                                                        <h3><a href="#">Dr. Wilson</a></h3>
                                                                                        <p><i className="fas fa-user-clock"></i> BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="patient-info">
                                                                                <div className="patient-details">
                                                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                                                </div>

                                                                                <div className="shadow-sm card p-1 mt-2">
                                                                                    <p className='text-center'>My availability</p>
                                                                                    <ul className="nav nav-tabs border-0 w-100">
                                                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime5">Slot time </a></li>
                                                                                    </ul>

                                                                                    <div className="tab-content p-2">
                                                                                        <div id="generalTime5" className="tab-pane fade show active">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div id="slotTime5" className="tab-pane fade">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-sm-6 col-lg-4">
                                                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                                                        <div className="card-body">
                                                                            <div className="pro-widget-content">
                                                                                <div className="profile-info-widget">
                                                                                    <a href="#" className="booking-doc-img">
                                                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                                                    </a>
                                                                                    <div className="profile-det-info">
                                                                                        <h3><a href="#">Dr. Wilson</a></h3>
                                                                                        <p><i className="fas fa-user-clock"></i> BDS, MDS - Oral & Maxillofacial Surgery</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="patient-info">
                                                                                <div className="patient-details">
                                                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                                                </div>

                                                                                <div className="shadow-sm card p-1 mt-2">
                                                                                    <p className='text-center'>My availability</p>
                                                                                    <ul className="nav nav-tabs border-0 w-100">
                                                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime6">Slot time </a></li>
                                                                                    </ul>

                                                                                    <div className="tab-content p-2">
                                                                                        <div id="generalTime6" className="tab-pane fade show active">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div id="slotTime6" className="tab-pane fade">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                                <p>Monday : 10:AM to 05:PM</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-sm-6 col-lg-4">
                                                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                                                        <div className="card-body">
                                                                            <div className="pro-widget-content">
                                                                                <div className="profile-info-widget">
                                                                                    <a href="#" className="booking-doc-img">
                                                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                                                    </a>
                                                                                    <div className="profile-det-info">
                                                                                        <h3><a href="#">Staff 1</a></h3>
                                                                                        <p><i className="fas fa-user-clock"></i> Nurse</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="patient-info">
                                                                                <div className="patient-details">
                                                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                                                </div>

                                                                                <div className="shadow-sm card p-1 mt-2">
                                                                                    <p className='text-center'>My availability</p>
                                                                                    <ul className="nav nav-tabs border-0 w-100">
                                                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime7">Slot time </a>

                                                                                        </li>
                                                                                    </ul>

                                                                                    <div className="tab-content p-2">
                                                                                        <div id="generalTime7" className="tab-pane fade show active">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div id="slotTime7" className="tab-pane fade">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-sm-6 col-lg-4">
                                                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                                                        <div className="card-body">
                                                                            <div className="pro-widget-content">
                                                                                <div className="profile-info-widget">
                                                                                    <a href="#" className="booking-doc-img">
                                                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                                                    </a>
                                                                                    <div className="profile-det-info">
                                                                                        <h3><a href="#">Staff 2</a></h3>
                                                                                        <p><i className="fas fa-user-clock"></i> Receptionist 1</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="patient-info">
                                                                                <div className="patient-details">
                                                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                                                </div>

                                                                                <div className="shadow-sm card p-1 mt-2">
                                                                                    <p className='text-center'>My availability</p>
                                                                                    <ul className="nav nav-tabs border-0 w-100">
                                                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime8">Slot time </a>

                                                                                        </li>
                                                                                    </ul>

                                                                                    <div className="tab-content p-2">
                                                                                        <div id="generalTime8" className="tab-pane fade show active">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div id="slotTime8" className="tab-pane fade">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-12 col-sm-6 col-lg-4">
                                                                    <div className="card widget-profile pat-widget-profile shadow-sm">
                                                                        <div className="card-body">
                                                                            <div className="pro-widget-content">
                                                                                <div className="profile-info-widget">
                                                                                    <a href="#" className="booking-doc-img">
                                                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="User Image" />
                                                                                    </a>
                                                                                    <div className="profile-det-info">
                                                                                        <h3><a href="#">Staff 3</a></h3>
                                                                                        <p><i className="fas fa-user-clock"></i> Receptionist 2</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="patient-info">
                                                                                <div className="patient-details">
                                                                                    <h5><i className="fas fa-envelope"></i> demo@example.com</h5>
                                                                                    <h5 className="mb-0"><i className="fas fa-phone"></i> +91 123654799</h5>
                                                                                    <a className='' href="#"><i className="fas fa-map-marker-alt"></i> Whatsapp</a>
                                                                                </div>

                                                                                <div className="shadow-sm card p-1 mt-2">
                                                                                    <p className='text-center'>My availability</p>
                                                                                    <ul className="nav nav-tabs border-0 w-100">
                                                                                        <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                                        <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime9">Slot time </a>

                                                                                        </li>
                                                                                    </ul>

                                                                                    <div className="tab-content p-2">
                                                                                        <div id="generalTime9" className="tab-pane fade show active">
                                                                                            <div className="mb-2">
                                                                                            <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div id="slotTime9" className="tab-pane fade">
                                                                                            <div className="mb-2">
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>
                                                                                                <p>Monday</p>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade show" id="staff" role="tabpanel" aria-labelledby="home-tab">
                                            <div className="mt-md-2 mt-2">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <Link className='btn btn-outline-primary mt-2 mb-3' data-toggle="modal" data-target=".addStaffmodel">Add New Staff</Link>
                                                        <div className='overflow-auto w-100'>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Name</th>
                                                                        <th scope="col">Phone No.</th>
                                                                        <th scope="col">Staff Category</th>
                                                                        <th scope="col">Duty Timing</th>
                                                                        <th scope="col">ID No.</th>
                                                                        <th scope="col">Password</th>
                                                                        <th scope="col">Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Ms. Rossy</td>
                                                                        <td>123459877</td>
                                                                        <td>Nurse</td>
                                                                        <td>
                                                                            <ul>
                                                                                <li>Monday: 10:00 AM - 11:00 AM</li>
                                                                                <li>Tuesday: 11:00 AM - 12:00 AM</li>
                                                                                <li>Friday: 12:00 AM - 1:00 PM</li>
                                                                            </ul>
                                                                        </td>
                                                                        <td>12635</td>
                                                                        <td>admin123</td>
                                                                        <td>
                                                                            <ul className="d-flex options">
                                                                                <li className="p-2 btn btn-sm btn-outline-primary mr-2"><i className="fas fa-eye"></i></li>
                                                                                <li className="p-2 btn btn-sm btn-outline-success mr-2" data-toggle="modal" data-target=".editemodel"><i className="fas fa-pencil-alt"></i></li>
                                                                                <li className="p-2 btn btn-sm btn-outline-danger" data-toggle="modal" data-target=".deletemodel"><i className="fas fa-trash-alt"></i></li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade show" id="usermanagement" role="tabpanel" aria-labelledby="usermanagement-tab">
                                            <a href="#" className='btn btn-primary mb-3 mt-2' data-toggle="modal" data-target="#addUserAccess">Add User</a>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email ID</th>
                                                        <th>Status</th>
                                                        <th>User Role</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td scope="row">Hospital Name</td>
                                                        <td>hospital@gmail.com</td>
                                                        <td>
                                                            <span className="badge badge-pill badge-success">Active</span>
                                                        </td>
                                                        <td>Hospital</td>
                                                        <td>
                                                            <a href="#" className='btn btn-success mr-2' data-toggle="modal" data-target="#editUserAccess">Edit</a>
                                                            <a href="#" className='btn btn-danger'>Delete</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Dr. Wilson</td>
                                                        <td>wilson@gmail.com</td>
                                                        <td>
                                                            <span className="badge badge-pill badge-success">Active</span>
                                                        </td>
                                                        <td>Doctor</td>
                                                        <td>
                                                            <a href="#" className='btn btn-success mr-2' data-toggle="modal" data-target="#editUserAccess">Edit</a>
                                                            <a href="#" className='btn btn-danger'>Delete</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Nurse</td>
                                                        <td>nurse@gmail.com</td>
                                                        <td>
                                                            <span className="badge badge-pill badge-success">Active</span>
                                                        </td>
                                                        <td>Nurse</td>
                                                        <td>
                                                            <a href="#" className='btn btn-success mr-2' data-toggle="modal" data-target="#editUserAccess">Edit</a>
                                                            <a href="#" className='btn btn-danger'>Delete</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Receptionist</td>
                                                        <td>receptionist@gmail.com</td>
                                                        <td>
                                                            <span className="badge badge-pill badge-success">Active</span>
                                                        </td>
                                                        <td>Receptionist</td>
                                                        <td>
                                                            <a href="#" className='btn btn-success mr-2' data-toggle="modal" data-target="#editUserAccess">Edit</a>
                                                            <a href="#" className='btn btn-danger'>Delete</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="hosdetail_success" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title">Successfully Saved</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                        <form>
                                <div className="row mt-2">
                                    <div className="col-md-12 text-center">
                                        <p><i className="far fa-check-circle modal_icon2 mb-2"></i></p>
                                        <h5>Your Hospital/Clinic Details Saved Successfully</h5>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
                        </div>
                        </div>
                    </div>
                </div>

                {/* // <!-- Add new Location  model --> */}
                <div className="modal fade addLocation" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">Add new Location</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="locationname">Enter Location Name</label>
                                                <input type="text" className='form-control' placeholder='Location Name'/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="phone">Enter Email</label>
                                                <input type="text" className='form-control' placeholder='Email'/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="phone">Enter Phone</label>
                                                <input type="text" className='form-control' placeholder='Phone'/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="locationaddress">Enter Location Address</label>
                                                <input type="text" className='form-control' placeholder='Location Address'/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="locationaddress">Select Conutry</label>
                                                <select className='form-control'>
                                                    <option value="2">India</option>
                                                    <option value="1">USA</option>
                                                    <option value="3">Canada</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="locationaddress">Select State</label>
                                                <select className='form-control'>
                                                    <option value="2">Gujarat</option>
                                                    <option value="1">Delhi</option>
                                                    <option value="3">Maharatra</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="locationaddress">Select City</label>
                                                <select className='form-control'>
                                                    <option value="1">Ahmedabad</option>
                                                    <option value="2">Surat</option>
                                                    <option value="3">Vadodara</option>
                                                </select>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className='fw-bold'>Select Staff</label>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="doc1"/>
                                                        <label className="form-check-label small" htmlFor="doc1">
                                                            Dr. XYZ
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="nurse1"/>
                                                        <label className="form-check-label small" htmlFor="nurse1">
                                                            Ms. Rossy
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className='fw-bold'>Select Doctor</label>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="doc1"/>
                                                        <label className="form-check-label small" htmlFor="doc1">
                                                            Dr. XYZ
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="nurse1"/>
                                                        <label className="form-check-label small" htmlFor="nurse1">
                                                            Ms. Rossy
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" data-dismiss="modal">Add</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* // <!-- password  model --> */}
                <div id="personalinfo_success" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title">Successfully Saved</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                        <form>
                                <div className="row mt-2">
                                    <div className="col-md-12 text-center">
                                        <p><i className="far fa-check-circle modal_icon2 mb-2"></i></p>
                                        <h5>Your Personal Information Saved Successfully</h5>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
                        </div>
                        </div>
                    </div>
                </div>

                {/* // <!-- Insurance  model --> */}
                <div id="insurance_success" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title">Successfully Saved</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                        <form>
                                <div className="row mt-2">
                                    <div className="col-md-12 text-center">
                                        <p><i className="far fa-check-circle modal_icon2 mb-2"></i></p>
                                        <h5>Your Insurance Saved Successfully</h5>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
                        </div>
                        </div>
                    </div>
                </div>

                {/* // <!-- password  model --> */}
                <div id="newpass_success" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title">Successfully Saved</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                        <form>
                                <div className="row mt-2">
                                    <div className="col-md-12 text-center">
                                        <p><i className="far fa-check-circle modal_icon2 mb-2"></i></p>
                                        <h5>Your New Password Saved Successfully</h5>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
                        </div>
                        </div>
                    </div>
                </div>
                {/*
                // <!-- add Location --> */}
                <div className="modal fade" id="add_hospital" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <Link className="mt-2 new_location" to="#">Create</Link>
                            <h5 styles='margin-left: 140px;' className="modal-title text-center" id="exampleModalCenterTitle">Locations</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="forn-group d-flex" id="add_location">
                                <input type="text" className="form-control mr-2" value="#" />
                                <Link className="btn btn-primary" to="#"><i className="fas fa-check"></i></Link>
                            </div>
                            <hr />
                            <input type="text" className="form-control mb-3" placeholder="Search" />
                            <ul styles="padding:0;list-style:none">
                                <li><Link className="text-muted" to="#">#Location 1</Link></li>
                                <li><Link className="text-muted" to="#">#Location 2</Link></li>
                                <li><Link className="text-muted" to="#">#Location 3</Link></li>
                                <li><Link className="text-muted" to="#">#Location 4</Link></li>
                            </ul>

                        </div>
                    </div>
                    </div>
                </div>

                {/* <!-- Add new staff model --> */}
                <div className="modal fade addStaffmodel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add New Staff Member</h5>
                                <select className='form-control w-50 ml-4'>
                                    <option value="1">- Select Staff Category</option>
                                    <option value="2">Nurse</option>
                                    <option value="3">Receptionist</option>
                                </select>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-12">
                                        <div className="small-12 medium-2 large-2 columns uploadProfile">
                                            <div className="circle">
                                                <img className="profile-pic" src={require('../../../assets/images/user.png').default}/>
                                            </div>
                                            <div className="p-image">
                                                <i className="fa fa-camera upload-button"></i>
                                                <input className="file-upload" type="file" accept="image/*"/>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="col-md-6">
                                            <input type="text" className="form-control mb-2" placeholder="Name" />
                                            <input type="text" className="form-control mb-2" placeholder="Phone Number" />
                                            <input type="email" className="form-control mb-2" placeholder="Email" />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <input type="text" className="form-control mb-2" placeholder="ID No." />
                                            <input type="text" className="form-control mb-2" placeholder="Password" />
                                            <input type="text" className="form-control mb-2" placeholder="Confirm Password" />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="time">Duty Timing</label>
                                            <div className="d-flex align-items-center justify-content-between mb-2">
                                                <div className="row w-75">
                                                    <div className="col-md-4">
                                                        <select className="form-control" defaultValue={'0'}>
                                                            <option value="0"  disabled>Day</option>
                                                            <option value="0">Monday</option>
                                                            <option value="0">Tuesday</option>
                                                            <option value="0">Wednesday</option>
                                                            <option value="0">Thursday</option>
                                                            <option value="0">Friday</option>
                                                            <option value="0">Saturday</option>
                                                            <option value="0">Sunday</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className='d-flex justify-ceontent-between align-items-center'>
                                                            <input type="time" className="form-control" />
                                                            <p className='p-2'>to</p>
                                                            <input type="time" className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='w-25'>
                                                    <button className='btn btn-outline-primary'>+ Add more</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary">OK</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Add new Doctor model --> */}
                <div className="modal fade addDoctormodel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add New Dcotor</h5>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="small-12 medium-2 large-2 columns uploadProfile">
                                                <div className="circle">
                                                    <img className="profile-pic" src={require('../../../assets/images/user.png').default}/>
                                                </div>
                                                <div className="p-image">
                                                    <i className="fa fa-camera upload-button"></i>
                                                    <input className="file-upload" type="file" accept="image/*"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <input type="text" className="form-control mb-2" placeholder="Name" />
                                            <input type="text" className="form-control mb-2" placeholder="Phone Number" />
                                            <input type="email" className="form-control mb-2" placeholder="Email" />
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <input type="text" className="form-control mb-2" placeholder="ID No." />
                                            <input type="text" className="form-control mb-2" placeholder="Password" />
                                            <input type="text" className="form-control mb-2" placeholder="Confirm Password" />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="card shadowCustom p-2">
                                                <p className='fw-bold text-center mb-1'>My Availability</p>
                                                <ul className="nav nav-tabs border-0 w-100">
                                                    <li className="nav-item w-50">
                                                        <a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime" role="tab" aria-controls="general" aria-selected="true">General Time <br/>
                                                            <small>(First come, First Served - Waiting time visible - Avg time visible) (Online + In-person)</small>
                                                        </a>
                                                    </li>
                                                    <li className='nav-item w-50'>
                                                        <a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime" role="tab" aria-controls="slot" aria-selected="true">Slot time <br/>
                                                            <small>(priority fixed appointments) (Online + In-person)</small>
                                                        </a>
                                                    </li>
                                                </ul>

                                                <div className="tab-content p-2">
                                                    <div id="generalTime" className="tab-pane fade show active" role="tabpanel" aria-labelledby="general-time">
                                                        <label htmlFor="time">Duty Timing</label>
                                                        <div className="mb-2">
                                                            <div className="row addMoreGeneralTime">
                                                                <div className="col-md-3">
                                                                    <select className="form-control" defaultValue={'0'}>
                                                                        <option value="0"  disabled>Day</option>
                                                                        <option value="0">Monday</option>
                                                                        <option value="0">Tuesday</option>
                                                                        <option value="0">Wednesday</option>
                                                                        <option value="0">Thursday</option>
                                                                        <option value="0">Friday</option>
                                                                        <option value="0">Saturday</option>
                                                                        <option value="0">Sunday</option>
                                                                    </select>
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <div className='d-flex justify-ceontent-between align-items-center'>
                                                                        <input type="time" className="form-control" />
                                                                        <p className='p-2'>to</p>
                                                                        <input type="time" className="form-control" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-md-2 change'>
                                                                    <a href="#" className='btn btn-outline-primary addMoreBtnGeneral'><i className="fa fa-plus"></i> Add more</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="slotTime" className="tab-pane fade" role="tabpanel" aria-labelledby="slot-time-tab">
                                                        <div className="profile-box">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <h6 className='m-0'>Select Slot time</h6>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <div className="row">
                                                                                <div className="col-md-7">
                                                                                    <DateRangePicker
                                                                                        ranges={[selectionRange]}
                                                                                        onChange={this.handleSelect}
                                                                                    />
                                                                                </div>
                                                                                <div className="col-md-5">
                                                                                    <div className="row">
                                                                                        <div className='col-md-12 mb-3'>
                                                                                            <div className="d-flex align-items-center">
                                                                                                <h6 className="mb-0 me-3 w-50">Slot time / <br/> Average consultation time</h6>
                                                                                                <select className="form-control w-25 mr-2">
                                                                                                    <option value="1">1</option>
                                                                                                    <option value="1">2</option>
                                                                                                    <option value="1">3</option>
                                                                                                    <option value="1">4</option>
                                                                                                    <option value="1">5</option>
                                                                                                    <option value="1">6</option>
                                                                                                    <option value="1">7</option>
                                                                                                    <option value="1">60</option>
                                                                                                </select>
                                                                                                <p>Mins</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-md-12 d-flex'>
                                                                                            <button className='btn btn-outline-primary mr-2'>+</button>
                                                                                            <div className='mr-2 w-100'>
                                                                                                <h6 className="mb-0 w-100 mr-2">Consultation <br/> hours Start: </h6>
                                                                                                <input type="time" value="10:30" className='form-control'/>
                                                                                            </div>
                                                                                            <div className='w-100'>
                                                                                                <h6 className="mb-0 w-100 mr-2">Consultation <br/> hours End: </h6>
                                                                                                <input type="time" value="18:30" className='form-control'/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="text-start mt-2">
                                                                                <button className="btn btn-primary save-btn">Save</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className='my-2'/>
                                        <div className="col-md-12">
                                            <form>
                                                <div className='addMoreQualification'>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h5 className='text-primary fw-bold'>Education Qualification</h5>
                                                        <div className="change">
                                                            <a href="#" className='btn btn-outline-primary addMoreBtnQualification'>Add More</a>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label for="speciality">Specialization</label>
                                                                <select className='form-control'>
                                                                    <option value="1">Dentist</option>
                                                                    <option value="2">General Physician</option>
                                                                    <option value="3">Homoeopath</option>
                                                                    <option value="4">Gynecologist/obstetrician</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label for="speciality">Degree</label>
                                                                <select className='form-control'>
                                                                    <option value="1">Bachelor of Medicine, Bachelor of Surgery - MBBS</option>
                                                                    <option value="2">Master of Surgery - MS</option>
                                                                    <option value="3">Doctor of Medicine - MD</option>
                                                                    <option value="4">Bachelor of Ayurvedic Medicine and Surgery - BAMS</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label for="speciality">Collage Name</label>
                                                                <select className='form-control'>
                                                                    <option value="1">Collage Name</option>
                                                                    <option value="2">Master of Surgery - MS</option>
                                                                    <option value="3">Doctor of Medicine - MD</option>
                                                                    <option value="4">Bachelor of Ayurvedic Medicine and Surgery - BAMS</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 col-12 mt-2 mt-md-2">
                                                            <label>Registration No.</label>
                                                            <input type="text" className="form-control" placeholder="Registration Number" name="registrationNo" />
                                                        </div>
                                                        <div className="col-md-4 col-6 mt-2">
                                                            <label>Registration Council</label>
                                                            <input className="form-control" list="council" placeholder="Registration Council" name="registrationCouncil" />
                                                            <datalist id="council">
                                                                <option value="Council 1"></option>
                                                                <option value="Council 2"></option>
                                                                <option value="Council 3"></option>
                                                                <option value="Council 4"></option>
                                                            </datalist>
                                                        </div>
                                                        <div className="col-md-4 col-6 mt-2">
                                                            <label>Registration Type</label><input className="form-control" list="type" name="registrationType" placeholder="Registration Type" />
                                                            <datalist id="type">
                                                                <option value="Reg Type 1"></option>
                                                                <option value="Reg Type 2"></option>
                                                                <option value="Reg Type 3"></option>
                                                                <option value="Reg Type 4"></option>
                                                            </datalist>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p>upload Document</p>
                                                            <div className="form-group">
                                                                <input type="file" className='form-control' multiple/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-primary">OK</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- edit model --> */}
                <div className="modal fade editemodel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit Staff Member</h5>
                            <select className='form-control w-25 ml-4'>
                                <option selected value="2">Nurse</option>
                                <option value="3">Receptionist</option>
                            </select>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="">
                                <div className="row mt-4">
                                    <div className="col-md-6">
                                        <input type="text" className="form-control mb-2" value="Ms. Rossy" placeholder="Name" />
                                        <input type="text" className="form-control mb-2" value="123459877" placeholder="Phone Number" />
                                        <input type="email" className="form-control mb-2" value="demo@gmail.com" placeholder="Email" />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <input type="text" className="form-control mb-2" value="12635" placeholder="ID No." />
                                        <input type="text" className="form-control mb-2" value="" placeholder="Password" />
                                        <input type="text" className="form-control mb-2" value="" placeholder="Confirm Password" />
                                    </div>

                                    <div className="col-md-12">
                                        <label htmlFor="time">Duty Timing</label>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row w-85">
                                                <div className="col-md-4">
                                                    <select className="form-control mb-2" defaultValue={'0'}>
                                                        <option value="0"  disabled>Day</option>
                                                        <option value="0">Monday</option>
                                                        <option value="0">Monday</option>
                                                        <option value="0">Monday</option>
                                                        <option value="0">Monday</option>
                                                        <option value="0">Monday</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className='d-flex justify-ceontent-between align-items-center'>
                                                        <input type="time" className="form-control" />
                                                        <p className='p-2'>to</p>
                                                        <input type="time" className="form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-15'>
                                                <button className='btn btn-sm btn-outline-primary'>+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">OK</button>
                        </div>
                        </div>
                    </div>
                </div>

                {/* <!-- add User Access model --> */}
                <div className="modal fade" id="addUserAccess" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add Doctor/Staff Access</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input type="text" className="form-control mb-2" placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Email</label>
                                                <input type="text" className="form-control mb-2" placeholder="Email" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Password</label>
                                                <input type="password" className="form-control mb-2" placeholder="Password" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Status</label>
                                                <select className='form-control'>
                                                    <option value="1">Active</option>
                                                    <option value="1">Inactive</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Select Role</label>
                                                <select className='form-control'>
                                                    <option value="1">Hospital Owner</option>
                                                    <option value="2">Doctor</option>
                                                    <option value="3">Nurse</option>
                                                    <option value="4">Receptionist</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="pageParmission" id="menus">
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary" for="menu1"><input type="checkbox" name="menu[]" id="menu1" value="1" />Owner's Account Setting & Password</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary" for="menu2"><input type="checkbox" name="menu[]" id="menu2" value="2" />Staff</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary active" for="menu3"><input type="checkbox" name="menu[]" id="menu3" value="3" />Apollo Delhi</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary active" for="menu5"><input type="checkbox" name="menu[]" id="menu5" value="5" />Apollo Mumbai</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary active" for="menu4"><input type="checkbox" name="menu[]" id="menu4" value="4" />Payment</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- edit User Access model --> */}
                <div className="modal fade" id="editUserAccess" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Edit User Access</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form action="">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="name">Name</label>
                                                <input type="text" className="form-control mb-2" value="Hospital Name" placeholder="Name" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Email</label>
                                                <input type="text" className="form-control mb-2" value="hospital@gmail.com" placeholder="Email" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Password</label>
                                                <input type="password" className="form-control mb-2" value="hospital" placeholder="Password" />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Status</label>
                                                <select className='form-control'>
                                                    <option value="1">Active</option>
                                                    <option value="1">Inactive</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="name">Select Role</label>
                                                <select className='form-control'>
                                                    <option value="1">Hospital Owner</option>
                                                    <option value="2">Doctor</option>
                                                    <option value="3">Nurse</option>
                                                    <option value="4">Receptionist</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="pageParmission" id="menus">
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary" for="menu1"><input type="checkbox" name="menu[]" id="menu1" value="1" />Owner's Account Setting & Password</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary" for="menu2"><input type="checkbox" name="menu[]" id="menu2" value="2" />Staff</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary active" for="menu3"><input type="checkbox" name="menu[]" id="menu3" value="3" />Apollo Delhi</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary active" for="menu5"><input type="checkbox" name="menu[]" id="menu5" value="5" />Apollo Mumbai</label>
                                                </div>
                                                <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                                    <label className="form-check-label btn btn-outline-primary active" for="menu4"><input type="checkbox" name="menu[]" id="menu4" value="4" />Payment</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Delete model --> */}
                <div className="modal fade deletemodel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">Delete Staff Member</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                        <form action="">
                                <div className="row mt-2">
                                    <div className="col-md-12 text-center">
                                        <p><i className="fas fa-exclamation-circle modal_icon mb-2"></i></p>
                                        <h4>Are You Sure?</h4>
                                        <p>You Want to Delete this Record?</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary" data-dismiss="modal">No</button>
                            <button type="button" className="btn btn-primary">Yes</button>
                        </div>
                        </div>
                    </div>
                </div>
		   </React.Fragment>
        )
    }
}


export default Hospitaldetail;