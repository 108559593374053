
import React, { Component } from 'react';
import AppointmentFilterComponent from '../../Search/SearchFilter/AppointmentFilter';
import { Link } from 'react-router-dom';
import { AllInvoiceDTOs } from '../../../mock/AllInvoice';
import { MedicineRowComponent } from '../prescription/MedicineRow';
import HospitalFilterComponent from '../../Search/SearchFilter/HospitalFilter';
import AllInvoiceResults from './AllInvoiceResult';

class UserAllInvoiceComponent extends Component {
    prescriptionDto = null;
    doctorId = '';
    constructor(props) {
        super(props);
        this.state = {
            invoiceDTOs: AllInvoiceDTOs
        };
        this.getUserInvoice = this.getUserInvoice.bind(this);
    }



    componentDidMount() {
        setTimeout(() => {
            this.setState({ invoiceDTO: AllInvoiceDTOs });
        }, 10);



    }

    selectAppointment = (val) => {
        // console.log('fiilter selected ');
    }


    getUserInvoice() {

        //this.doctorId=this.props.location.state.doctorId;

        //this.state.prescriptionDto=PrescriptionDTO;
        //this.state.setState({prescriptionDto:PrescriptionDTO});
    }

    selectedFilter = (selectedVal) => {
        // console.log(" menu selescted ");
    }


    render() {

        this.getUserInvoice();
        return (
            <React.Fragment>
                {/* <div className="w-100 pos_fix">
                    <div className="direction_row d-flex justify-content-between">
                        <div>
                            <AppointmentFilterComponent onSelectAppointment={this.selectAppointment} />
                        </div>
                        <div>
                            <p className="w-100 p-2"><strong className="font_13">BACKER HEART HOSPITAL</strong></p>
                        </div>
                        <div className="mt-2">
                            <Link to='/user/allPrescription' className="btn-primary p-1" >All Prescription</Link>
                        </div>
                    </div>
                </div> */}

                <div className="row">
                    <div className="col-md-12 d-none d-md-block">
                        <HospitalFilterComponent onSelectFilter={this.selectedFilter} />
                    </div>

                    <div className="col-md-12 mt-2">
                        <div className="row">
                            {(this.state.invoiceDTOs !== undefined && this.state.invoiceDTOs !== null && this.state.invoiceDTOs.length > 0) ?
                                this.state.invoiceDTOs.map((invoice, i) => {
                                    return <AllInvoiceResults key={invoice.id} invoice={invoice} ></AllInvoiceResults>
                                }) : ''}
                        </div>
                    </div>
                </div>


            </React.Fragment>

        )
    }
}
export default UserAllInvoiceComponent;