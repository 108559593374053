import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import SearchFilterComponent from './SearchFilter/SearchFilter';
import SearchResults from './SearchFilter/SearchResults';
// import ReactPaginate from 'react-paginate';
// import PropTypes from 'prop-types';
import AppointmentComponent from '../appointment/Appointment';
import {Doctor} from '../../mock/UpcomingDoctor';

class SearchComponent extends Component{
    doctorList=[];

    perPage=2;
    //showAppointment=false;
    constructor(props) {
		super(props);
		this.state = {
            searchFilter:{
                location:0,
                insurance:0,
                specialization:0,
                from:''
            },
            doctorList:Doctor,
            offset: 0,
            pageCount:Doctor.length,
            showAppointment:false
            };

      }


      selectedFilter = (selectedVal) => {
        setTimeout(() => {
        this.setState({searchFilter: selectedVal,showAppointment:false,doctorList:this.doctorList.filter( (doctor) =>{
                var searchFilter=selectedVal;
                if((searchFilter.location === 0 || searchFilter.location === doctor.location.id)
                && (searchFilter.insurance === 0 ||  searchFilter.insurance === doctor.insurance.id )
                && ( searchFilter.specialization === 0 ||  searchFilter.specialization === doctor.specialization.id )
                ){
                    return true;
                }
            })
        });
        this.setState({pageCount:this.state.doctorList.length} );

        },10);
    }

    filterDoctor(){

    }

    handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.perPage);

        this.setState({ offset: offset }, () => {
          //
        });
      };
      setBookAppointmentFlg =(data) =>{
       // this.showAppointment=data;
       setTimeout(() => {
        this.setState({ showAppointment: data });
       },10);

      }

      getSelectedFilter(doctorList){
        let specialityId=this.props.location.state.specialityId;
        let insuranceId=this.props.location.state.insuranceId;
        let location=0;


        let searchFilter={...this.state.searchFilter,location:location,insurance:insuranceId,specialization:specialityId};
        this.setState({searchFilter: searchFilter,showAppointment:false,
            doctorList:doctorList.filter( (doctor) =>{
                if((searchFilter.location === 0 || searchFilter.location === doctor.location.id)
                && (searchFilter.insurance === 0 ||  searchFilter.insurance === doctor.insurance.id )
                && ( searchFilter.specialization === 0 ||  searchFilter.specialization === doctor.specialization.id )
                ){
                    return true;
                }
            }),pageCount:this.state.doctorList.length
        });
      }

    //   componentWillMount() {
    //     let specialityId=this.props.location.state.specialityId;
    //     let insuranceId=this.props.location.state.insuranceId;
    //     let location=0;
    //     let searchFrom=this.props.location.state.from;
    //     console.log(specialityId+" found in serach naviage ");
    //     console.log(this.state.doctorList);
    //     if(searchFrom === 'SEARCH_CLICK'){
    //         let searchFilter={...this.state.searchFilter,location:location,insurance:insuranceId,specialization:specialityId,from:searchFrom};
    //         this.setState({searchFilter: searchFilter,showAppointment:false,doctorList:this.state.doctorList.filter( (doctor) =>{
    //                 if((searchFilter.location === 0 || searchFilter.location === doctor.location.id)
    //                 && (searchFilter.insurance === 0 ||  searchFilter.insurance === doctor.insurance.id )
    //                 && ( searchFilter.specialization === 0 ||  searchFilter.specialization === doctor.specialization.id )
    //                 ){
    //                     return true;
    //                 }
    //             }),pageCount:this.state.doctorList.length
    //         });

    //     }

    //   }

    render() {
        this.doctorList=Doctor;
        //this.getSelectedFilter(this.doctorList);
        return (

            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 d-none d-md-block">
                        <SearchFilterComponent onSelectFilter={this.selectedFilter} item={this.state.searchFilter} />
                    </div>

                    <div className="col-md-12">
                        <div className="col-md-12 mt-3">
                            <form className="desk_form mb-2 d-flex">
                                <i className="fa fa-search search_icon"></i>
                                <input className="location form-control" list="specialization" name="browser" placeholder="Search Doctor / Hospital" />
                                <datalist id="specialization">
                                    <option value="Dentist" />
                                </datalist>
                            </form>
                        </div>
                        <div className="row">
                        {(this.state.doctorList !== undefined && this.state.doctorList !== null && this.state.doctorList.length > 0 && !this.state.showAppointment) ?
                            this.state.doctorList.map((doctor, i) => {
                                return <SearchResults key={i} doctor={doctor} onBookAppointment={this.setBookAppointmentFlg}></SearchResults>
                            }) : ''}

                        </div>

                        {this.state.showAppointment && <AppointmentComponent/>}

                        {/* {!this.state.showAppointment &&
                        // <ReactPaginate
                        //   previousLabel={'previous'}
                        //   nextLabel={'next'}
                        //   breakLabel={'...'}
                        //   breakClassName={'break-me'}
                        //   pageCount={this.state.pageCount}
                        //   marginPagesDisplayed={2}
                        //   pageRangeDisplayed={5}
                        //   onPageChange={this.handlePageClick}
                        //   containerClassName={'pagination justify-content-center mt-3'}
                        //   subContainerClassName={'pages pagination'}
                        //   pageClassName={'page-item'}
                        //   pageLinkClassName={'page-link'}
                        //   previousClasses={'page-item'}
                        //   previousLinkClassName={'page-link'}
                        //   nextClasses={'page-item'}
                        //   nextLinkClassName={'page-link'}
                        //   activeClassName={'active'}
                        // />
                    }  */}

                        {/* <ul className="pagination justify-content-center mt-3">
                            <li className="page-item disabled">
                            <a className="page-link" href="#" tabIndex="-1"><i className="fa fa-chevron-left"></i></a>
                            </li>
                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                            <li className="page-item ">
                            <a className="page-link" href="#">2 <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item"><a className="page-link" href="#">...</a></li>
                            <li className="page-item"><a className="page-link" href="#">10</a></li>
                            <li className="page-item">
                            <a className="page-link" href="#"><i className="fa fa-chevron-right"></i></a>
                            </li>
                        </ul> */}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(SearchComponent);