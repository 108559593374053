import { put, call } from 'redux-saga/effects';
import { config, API, getFullUrl } from '../../../apiConfig';
import actionTypes from '../actionTypes';


export const countryList = function* (action) {
    try {
        const response = yield call(
            config.GET,
            getFullUrl(API.Country_list),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_COUNTRY_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_COUNTRY_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_COUNTRY_LIST_FAILURE
        })
    }
}
export const HospitalDetails = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.hospitalDetail),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_HOSPITAL_DETAILS_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_HOSPITAL_DETAILS_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_HOSPITAL_DETAILS_FAILURE
        })
    }
}
export const HospitalAccountVerification = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.hospital_AccountVerification),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.HOSPITAL_ACC_VERIFY_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.HOSPITAL_ACC_VERIFY_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.HOSPITAL_ACC_VERIFY_FAILURE
        })
    }
}

export const HospitalOwnerSettings = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.hospitalownersAccountSetting),
            action.params

        );

        if (response) {
            yield put({
                type: actionTypes.HOSPITAL_OWNER_ACCOUNT_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.HOSPITAL_OWNER_ACCOUNT_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.HOSPITAL_OWNER_ACCOUNT_FAILURE
        })
    }
}

export const GetHospitalDocuments = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.getHospitalDocuments),
            action.params

        );

        if (response) {
            yield put({
                type: actionTypes.HOSPITAL_DOCUMENTS_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.HOSPITAL_DOCUMENTS_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.HOSPITAL_DOCUMENTS_LIST_FAILURE
        })
    }
}

export const GetStateList = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.getStateList),
            action.params

        );

        if (response) {
            yield put({
                type: actionTypes.GET_STATE_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_STATE_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_STATE_LIST_FAILURE
        })
    }
}
export const GetCityList = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.getCityList),
            action.params
        );

        console.log(' ============================ >', response)
        if (response) {
            yield put({
                type: actionTypes.GET_CITY_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_CITY_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_CITY_LIST_FAILURE
        })
    }
}
export const GetStaffCategoryList = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.staffCategoryList),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.STAFF_CATE_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.STAFF_CATE_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.STAFF_CATE_LIST_FAILURE
        })
    }
}
export const GetStaffList = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.staffList),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.HOSPITAL_STAFF_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.HOSPITAL_STAFF_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.HOSPITAL_STAFF_LIST_FAILURE
        })
    }
}
export const AddStaff = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.addStaff),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.HOSPITAL_ADD_STAFF_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.HOSPITAL_ADD_STAFF_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.HOSPITAL_ADD_STAFF_FAILURE
        })
    }
}
export const EditStaff = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.editStaff),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.HOSPITAL_EDIT_STAFF_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.HOSPITAL_EDIT_STAFF_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.HOSPITAL_EDIT_STAFF_FAILURE
        })
    }
}
export const DeleteStaff = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.deleteStaff),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.HOSPITAL_DELETE_STAFF_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.HOSPITAL_DELETE_STAFF_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.HOSPITAL_DELETE_STAFF_FAILURE
        })
    }
}
export const DegreeListSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.degree_list),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_DEGREE_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_DEGREE_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_DEGREE_LIST_FAILURE
        })
    }
}
export const CollegeListSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.collage_list),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_COLLEGE_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_COLLEGE_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_COLLEGE_LIST_FAILURE
        })
    }
}
export const DoctorSpecListSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.doctor_specialiazation_list),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_DOCTOR_SPEC_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_DOCTOR_SPEC_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_DOCTOR_SPEC_LIST_FAILURE
        })
    }
}
export const GetDoctorListSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.getDoctorsList),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_DOCTOR_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_DOCTOR_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_DOCTOR_LIST_FAILURE
        })
    }
}


export const AddDoctorSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.addDoctor),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.ADD_DOCTOR_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.ADD_DOCTOR_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.ADD_DOCTOR_FAILURE
        })
    }
}
export const EditDoctorSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.editDoctor),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.EDIT_DOCTOR_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.EDIT_DOCTOR_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.EDIT_DOCTOR_FAILURE
        })
    }
}
export const AddHospitalLocationSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.addHospitalLocation),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.HOSPITAL_ADD_LOCATION_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.HOSPITAL_ADD_LOCATION_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.HOSPITAL_ADD_LOCATION_FAILURE
        })
    }
}
export const EditHospitalLocationSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.editHospitalLocation),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.HOSPITAL_EDIT_LOCATION_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.HOSPITAL_EDIT_LOCATION_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.HOSPITAL_EDIT_LOCATION_FAILURE
        })
    }
}

export const GetHospitalLicationsSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.getLocationList),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_LOCATION_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_LOCATION_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_LOCATION_LIST_FAILURE
        })
    }
}



// USER ACCESS

export const addUserForAccessSaga = function* (action) {
    console.log("addUserForAccessSaga", action)
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.addUserForAccess),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.ADD_USER_FOR_ACCESS_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.ADD_USER_FOR_ACCESS_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.ADD_USER_FOR_ACCESS_FAILURE
        })
    }
}

export const editUserForAccessSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.EditUserForAccess),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.EDIT_USER_FOR_ACCESS_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.EDIT_USER_FOR_ACCESS_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.EDIT_USER_FOR_ACCESS_FAILURE
        })
    }
}
export const deleteUserForAccessSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.deleteUserForAccess),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.DELETE_USER_FOR_ACCESS_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.DELETE_USER_FOR_ACCESS_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.DELETE_USER_FOR_ACCESS_FAILURE
        })
    }
}

export const getRolesListSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.role_list),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_ROLE_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_ROLE_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_ROLE_LIST_FAILURE
        })
    }
}
export const getMenusListSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.menus_list),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_MENUS_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_MENUS_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_MENUS_LIST_FAILURE
        })
    }
}

export const getUserAccessManagementListSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.getUserAccessManagementList),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_USER_ACCESS_MANAGEMENT_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_USER_ACCESS_MANAGEMENT_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_USER_ACCESS_MANAGEMENT_LIST_FAILURE
        })
    }
}

export const getAllAppointmentsSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.getAllAppointments),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_ALL_APPOINTMENTS_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_ALL_APPOINTMENTS_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_ALL_APPOINTMENTS_FAILURE
        })
    }
}

export const getStaffListByDateSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.getStaffListNew),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_STAFF_LIST_BY_DATE_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_STAFF_LIST_BY_DATE_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_STAFF_LIST_BY_DATE_FAILURE
        })
    }
}
export const getAppointmentsCountSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.getAppointmentCounts),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_APPOINTMENTS_COUNT_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_APPOINTMENTS_COUNT_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_APPOINTMENTS_COUNT_FAILURE
        })
    }
}

export const getPatientAccountProfileSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.getPatientAccountProfile),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_PATIENT_ACCOUNT_PROFILE_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_PATIENT_ACCOUNT_PROFILE_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_PATIENT_ACCOUNT_PROFILE_FAILURE
        })
    }
}
export const saveAppointmentDataSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.saveAppointmentData),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.SAVE_APPOINTMENT_DATA_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.SAVE_APPOINTMENT_DATA_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.SAVE_APPOINTMENT_DATA_FAILURE
        })
    }
}

export const getPatientPrescriptionSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.patientPrescription),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_PATIENT_PRESCRIPTION_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_PATIENT_PRESCRIPTION_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_PATIENT_PRESCRIPTION_FAILURE
        })
    }
}

export const getPatientInvoiceSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.patientInvoice),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_PATIENT_INVOICE_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_PATIENT_INVOICE_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_PATIENT_INVOICE_FAILURE
        })
    }
}

export const startAppointmentSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.startAppointment),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.START_APPOINTMENT_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.START_APPOINTMENT_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.START_APPOINTMENT_FAILURE
        })
    }
}
export const getAppointmentHistorySaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.getAppointmentHistory),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_APPOINTMENT_HISTORY_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_APPOINTMENT_HISTORY_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_APPOINTMENT_HISTORY_FAILURE
        })
    }
}
export const allPatientInvoiceReportSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.allPatientInvoiceReport),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_ALL_PATIENT_INVOICE_REPORT_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_ALL_PATIENT_INVOICE_REPORT_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_ALL_PATIENT_INVOICE_REPORT_FAILURE
        })
    }
}
export const getPatientListSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.getPatientList),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_PATIENTS_LIST_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_PATIENTS_LIST_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_PATIENTS_LIST_FAILURE
        })
    }
}

export const getPatientMedicalHistorySaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.patientMedicalHistory),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_PATIENT_MEDICAL_HISTORY_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_PATIENT_MEDICAL_HISTORY_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_PATIENT_MEDICAL_HISTORY_FAILURE
        })
    }
}
export const getPatientMedicalProfileSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.getPatientMedicalProfile),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_PATIENT_MEDICAL_PROFILE_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_PATIENT_MEDICAL_PROFILE_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_PATIENT_MEDICAL_PROFILE_FAILURE
        })
    }
}

export const getAllHospitalWithLocationSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.get_all_hospital_with_location),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_ALL_HOSPITAL_WITH_LOCATION_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_ALL_HOSPITAL_WITH_LOCATION_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_ALL_HOSPITAL_WITH_LOCATION_FAILURE
        })
    }
}

export const getAllDoctorByLocationSaga = function* (action) {
    try {
        const response = yield call(
            config.POST,
            getFullUrl(API.get_all_doctor_by_location),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.GET_ALL_DOCTOR_By_LOCATION_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_ALL_DOCTOR_By_LOCATION_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_ALL_DOCTOR_By_LOCATION_FAILURE
        })
    }
}