import { put, call } from 'redux-saga/effects';
import { config, API, getFullUrl } from '../../../apiConfig';
import actionTypes from '../actionTypes';


export const countryCodes = function* (action) {
    try {
        const response = yield call(
            config.GET,
            getFullUrl(API.countryCodes),
            action.params

        );

        if (response) {
            yield put({
                type: actionTypes.GET_COUNTRY_CODES_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.GET_COUNTRY_CODES_FAILURE
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.GET_COUNTRY_CODES_FAILURE
        })
    }
}
export const login = function* (action) {
    try {
        console.log(action.params)
        const response = yield call(
            config.POST,
            getFullUrl(API.userLogin),
            action.params
        );


        console.log(response.status)

        if (response) {
            yield put({
                type: actionTypes.USER_LOGIN_SUCCESS,
                response: response.data
            })
        } else {
            console.log(response)
            yield put({
                type: actionTypes.USER_LOGIN_FAILURE,
                response: response
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.USER_LOGIN_FAILURE,
            response: error.message
        })
    }
}

export const loginWithOTP = function* (action) {
    try {
        console.log(action.params)
        const response = yield call(
            config.POST,
            getFullUrl(API.loginWithOtp),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.USER_LOGIN_WITH_OTP_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.USER_LOGIN_WITH_OTP_FAILURE,
                response: response
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.USER_LOGIN_WITH_OTP_FAILURE,
        })
    }
}

export const hospitalLogin = function* (action) {
    try {
        console.log(action.params)
        const response = yield call(
            config.POST,
            getFullUrl(API.hospitalLogin),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.HOSPITAL_LOGIN_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.HOSPITAL_LOGIN_FAILURE,
                response: response
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.HOSPITAL_LOGIN_FAILURE,
        })
    }
}

export const userRegistration = function* (action) {
    try {
        console.log(action.params)
        const response = yield call(
            config.POST,
            getFullUrl(API.userRegistration),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.USER_REGISTRATION_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.USER_REGISTRATION_FAILURE,
                response: response
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.USER_REGISTRATION_FAILURE,
        })
    }
}
export const userVerifyOTP = function* (action) {
    try {
        console.log(action.params)
        const response = yield call(
            config.POST,
            getFullUrl(API.userVerifyOtp),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.USER_VERIFY_OTP_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.USER_VERIFY_OTP_FAILURE,
                response: response
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.USER_VERIFY_OTP_FAILURE,
        })
    }
}
export const AddUserDetails = function* (action) {
    try {
        console.log(action.params)
        const response = yield call(
            config.POST,
            getFullUrl(API.AddUserDetails),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.ADD_USER_DETAILS_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.ADD_USER_DETAILS_FAILURE,
                response: response
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.ADD_USER_DETAILS_FAILURE,
        })
    }
}

export const ForgotPassword = function* (action) {
    try {
        console.log(action.params)
        const response = yield call(
            config.POST,
            getFullUrl(API.forgotPassword),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.FORGOT_PSWD_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.FORGOT_PSWD_FAILURE,
                response: response
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.FORGOT_PSWD_FAILURE,
        })
    }
}


export const ChangePassword = function* (action) {
    try {
        console.log(action.params)
        const response = yield call(
            config.POST,
            getFullUrl(API.changePswd),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.CHANGE_PSWD_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.CHANGE_PSWD_FAILURE,
                response: response
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.CHANGE_PSWD_FAILURE,
        })
    }
}


export const verifyOTPForPswd = function* (action) {
    try {
        console.log(action.params)
        const response = yield call(
            config.POST,
            getFullUrl(API.verifyOTPForPswd),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.FORGOT_PSWD_VERYFY_OTP_SUCCESS,
                response: response.data
            })
        } else {
            yield put({
                type: actionTypes.FORGOT_PSWD_VERYFY_OTP_FAILURE,
                response: response
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.FORGOT_PSWD_VERYFY_OTP_FAILURE,
        })
    }
}



export const hospitalRegistration  = function* (action) {
    try {
        console.log(action.params)
        const response = yield call(
            config.POST,
            getFullUrl(API.hospitalRegistration),
            action.params
        );

        if (response) {
            yield put({
                type: actionTypes.HOSPITAL_REGISTRATION_SUCCESS,
                             response: response.data
            })
        } else {
            yield put({
                type: actionTypes.HOSPITAL_REGISTRATION_SUCCESS,
                response: response
            })
        }
    }
    catch (error) {
        yield put({
            type: actionTypes.HOSPITAL_REGISTRATION_FAILURE,
        })
    }
}