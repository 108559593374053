import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import AppointmentFilter from '../../../mock/dropdown/AppointmentFilter';


class AppointmentFilterComponent extends Component{
    appointmentList=[];
    constructor(props) {
		super(props);
		this.state = {
            searchFilter:{
                appointment:0,
               
            }
            };
            this.appointmentChange=this.appointmentChange.bind(this);
      }

      appointmentChange(event){
          //const value={...this.state.searchFilter,location:event.target.value};
          //console.log(event.target.options[event.target.selectedIndex].text);
          const value={...this.state.searchFilter,appointment:parseInt(event.target.value)};
          this.setState({searchFilter:value}, () => {
            this.props.onSelectAppointment(this.state.searchFilter);
          });
          
        }

    componentDidMount(){

    }

    getInitialData(){
        this.appointmentList=AppointmentFilter;
    }
   
    render() {
        
        this.getInitialData();
        return (
           <React.Fragment>           
                <select className="form-control textarea_size" name="location" value={this.state.searchFilter.hospital}
                onChange={ (e) => {this.appointmentChange(e)}}
                >
                    {this.appointmentList.map((appointment) => {
                        return <option key={appointment.id} value={appointment.id}>{appointment.name}</option>
                    })}
                </select>
                   
                  
            </React.Fragment>

        );
    }
}
export default withRouter(AppointmentFilterComponent);