import React, { Component, useState } from 'react';
import { Link } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"




import DataTableGrid from 'src/common/DataGrid/DataGrid';
import { connect } from 'react-redux';

import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import DialogBox from 'src/common/DialogBox';
import DeleteModal from 'src/common/DeleteModal';
import TextInput from 'src/common/TextInput';
import DropDown from 'src/common/DropDown';
import TextareaAutosize from '@mui/base/TextareaAutosize';

const Usermanagement = () => {

    const [openModal, setOpenModal] = useState(false);
    const [onDelete, setOnDelete] = useState(false);
    const [state, setState] = useState({

    })

    const rows = [
        {
            id: 1, col1: "Hello",
            col2: "World",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 2, col1: "MUI X",
            col2: "is awesome",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 3, col1: "Material UI",
            col2: "is amazing",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 4, col1: "MUI",
            col2: "Lokesh",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "1.00 PM - 1.15 PM",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 5, col1: "Joy UI",
            col2: "is awesome",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "1.00 PM - 1.15 PM",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 6, col1: "MUI Base",
            col2: "is amazing",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "1.00 PM - 1.15 PM",
            col7: "World",
            col8: "World",
            col9: "World",
        }
    ];


    const columns = [
        { field: "id", headerName: "#", },
        { field: "col1", headerName: "Name", width: 150 },
        { field: "col4", headerName: "mail ID", width: 150 },
        { field: "col3", headerName: "Role", width: 150 },
        { field: "col5", headerName: "Status", width: 150 },
        {
            headerName: "Action",
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    // onClick={deleteUser(params.id)}
                    onClick={() => setOnDelete(true)}
                />,
                <GridActionsCellItem
                    icon={<SecurityIcon />}
                    label="View"
                    onClick={() => { }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<FileCopyIcon />}
                    label="Edit"
                    onClick={() => { }}
                    showInMenu
                />,
            ]
        },];


    const handleSubmit = () => {

    }

    const onChangeHandler = () => {

    }

    return (
        <div className="container dct-appoinment">
            <br />

            <div className='d-flex d-flex justify-content-between mb-3 align-items-center'>
                <h5 className='fw-bold'>Admin - User Management</h5>
                <Button className='primary-button'
                    size='small'
                    onClick={() => {
                        setOpenModal(true)
                    }}
                >Add User
                </Button>
            </div>


            {onDelete &&
                <DeleteModal
                    open={onDelete}

                    handleClose={() => setOnDelete(false)}
                    handleSubmit={() => { }}
                />
            }
            <DataTableGrid
                rows={rows}
                columns={columns}
                rowCountState={rows.length}

            />

            <DialogBox
                open={openModal}
                handleClose={() => setOpenModal(false)}
            >

                <DialogTitle>Add Doctor/Staff Access</DialogTitle>

                <DialogContent dividers>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextInput
                                name={'name'}
                                placeholder={'Name'}
                                value={state?.name}
                                onChange={(e) => onChangeHandler(e)}
                                error={''}

                            />

                            <TextInput
                                name={'email'}
                                placeholder={'Email'}
                                value={state?.email}
                                onChange={(e) => onChangeHandler(e)}
                                error={''}

                            />
                            <TextInput
                                name={'password'}
                                placeholder={'Password'}
                                value={state?.email}
                                onChange={(e) => onChangeHandler(e)}
                                error={''}

                            />
                            <DropDown
                                name={'status'}
                                placeholder={'Status'}
                                value={0}
                                selectLabel={'Please Select'}
                                onChange={(e) => onChangeHandler(e)}
                                error={''}
                                options={[{ name: 'Active', label: 'Active' }, { name: "Inactive", label: "Inactive" }]}

                            />
                            <DropDown
                                name={'status'}
                                placeholder={'Role'}
                                value={0}
                                selectLabel={'Please Select'}
                                onChange={(e) => onChangeHandler(e)}
                                error={''}
                                options={[{ name: 'nurse', label: 'Nurse' }, { name: "Patient", label: "Patient" }]}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="form-check-label btn btn-outline-primary" for="menu1"><input type="checkbox" name="menu[]" id="menu1" value="1" />Dashboard - Iframe</label>
                            </div>
                            <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="form-check-label btn btn-outline-primary" for="menu2"><input type="checkbox" name="menu[]" id="menu2" value="2" />Dashboard - Google analytics</label>
                            </div>
                            <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="form-check-label btn btn-outline-primary active" for="menu3"><input type="checkbox" name="menu[]" id="menu3" value="3" />All Hospitals</label>
                            </div>
                            <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="form-check-label btn btn-outline-primary active" for="menu4"><input type="checkbox" name="menu[]" id="menu4" value="4" />All Patients</label>
                            </div>
                            <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="form-check-label btn btn-outline-primary" for="menu5"><input type="checkbox" name="menu[]" id="menu5" value="5" />All Users</label>
                            </div>
                            <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="form-check-label btn btn-outline-primary" for="menu6"><input type="checkbox" name="menu[]" id="menu6" value="6" />All Invoices</label>
                            </div>
                            <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="form-check-label btn btn-outline-primary" for="menu7"><input type="checkbox" name="menu[]" id="menu7" value="7" />All Prescriptions</label>
                            </div>
                            <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="form-check-label btn btn-outline-primary" for="menu8"><input type="checkbox" name="menu[]" id="menu8" value="8" />All Subscriptions</label>
                            </div>
                            <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="form-check-label btn btn-outline-primary" for="menu9"><input type="checkbox" name="menu[]" id="menu9" value="9" />All Doctors</label>
                            </div>
                            <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="form-check-label btn btn-outline-primary" for="menu10"><input type="checkbox" name="menu[]" id="menu10" value="10" />All Staff</label>
                            </div>
                            <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="form-check-label btn btn-outline-primary" for="menu11"><input type="checkbox" name="menu[]" id="menu11" value="11" />Admin - User Management</label>
                            </div>
                            <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="form-check-label btn btn-outline-primary" for="menu12"><input type="checkbox" name="menu[]" id="menu12" value="12" />All Specializations</label>
                            </div>
                            <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
                                <label className="form-check-label btn btn-outline-primary" for="menu13"><input type="checkbox" name="menu[]" id="menu13" value="13" />All Insurances</label>
                            </div>

                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)}
                        variant='outlined'>close</Button>
                    <Button onClick={handleSubmit} variant='contained'>Save</Button>
                </DialogActions>

            </DialogBox>
        </div>
    )
}

export default connect()(Usermanagement)


// class Allstaff extends React.Component{

// 	componentDidMount() {
// 		document.title = "User Management"
// 		$('#usermanagement').DataTable({
// 			dom: '<"top"Bflp>rt<"bottom"ip>',
//             pageLength: 5,
//             buttons: ['copy', 'csv', 'print', 'excel']
// 		});
// 	}

// 	render(){
// 		return(
// 			<>
// 				<div className="container">
// 					<div className="row">
// 						<div className="col-md-12">
// 							<h5 className='mt-3 fw-bold mb-3'>Admin - User Management</h5>
// 							<a href="#" className='btn btn-primary mb-3 mt-2' data-toggle="modal" data-target="#addUserAccess">Add User</a>
//                             <table className="table" id="usermanagement">
//                                 <thead>
//                                     <tr>
//                                         <th>Name</th>
//                                         <th>Email ID</th>
//                                         <th>Status</th>
//                                         <th>User Role</th>
//                                         <th>Action</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     <tr>
//                                         <td scope="row">Admin</td>
//                                         <td>test@gmail.com</td>
//                                         <td>
//                                             <span className="badge badge-pill badge-success">Active</span>
//                                         </td>
//                                         <td>Hospital</td>
//                                         <td>
//                                             <a href="#" className='btn btn-outline-success mr-2' data-toggle="modal" data-target="#addUserAccess"><i className="fas fa-edit"></i></a>
//                                             <a href="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></a>
//                                         </td>
//                                     </tr>
//                                 </tbody>
//                             </table>
// 						</div>
// 					</div>
// 				</div>

//                 {/* <!-- add User Access model --> */}
//                 <div className="modal fade" id="addUserAccess" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
//                     <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
//                         <div className="modal-content">
//                             <div className="modal-header">
//                                 <h5 className="modal-title" id="exampleModalLabel">Add Doctor/Staff Access</h5>
//                                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
//                                     <span aria-hidden="true">&times;</span>
//                                 </button>
//                             </div>
//                             <div className="modal-body">
//                                 <form action="">
//                                     <div className="row">
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label htmlFor="name">Name</label>
//                                                 <input type="text" className="form-control mb-2" placeholder="Name" />
//                                             </div>
//                                             <div className="form-group">
//                                                 <label htmlFor="name">Email</label>
//                                                 <input type="text" className="form-control mb-2" placeholder="Email" />
//                                             </div>
//                                             <div className="form-group">
//                                                 <label htmlFor="name">Password</label>
//                                                 <input type="password" className="form-control mb-2" placeholder="Password" />
//                                             </div>
//                                             <div className="form-group">
//                                                 <label htmlFor="name">Status</label>
//                                                 <select className='form-control'>
//                                                     <option value="1">Active</option>
//                                                     <option value="1">Inactive</option>
//                                                 </select>
//                                             </div>
//                                             <div className="form-group">
//                                                 <label htmlFor="name">Select Role</label>
//                                                 <select className='form-control'>
//                                                     <option value="1">Super Admin</option>
//                                                     <option value="2">Admin</option>
//                                                     <option value="3">Assistant</option>
//                                                 </select>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6 col-12">
//                                             <div className="pageParmission" id="menus">
//                                                 <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
//                                                     <label className="form-check-label btn btn-outline-primary" for="menu1"><input type="checkbox" name="menu[]" id="menu1" value="1" />Dashboard - Iframe</label>
//                                                 </div>
//                                                 <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
//                                                     <label className="form-check-label btn btn-outline-primary" for="menu2"><input type="checkbox" name="menu[]" id="menu2" value="2" />Dashboard - Google analytics</label>
//                                                 </div>
//                                                 <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
//                                                     <label className="form-check-label btn btn-outline-primary active" for="menu3"><input type="checkbox" name="menu[]" id="menu3" value="3" />All Hospitals</label>
//                                                 </div>
//                                                 <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
//                                                     <label className="form-check-label btn btn-outline-primary active" for="menu4"><input type="checkbox" name="menu[]" id="menu4" value="4" />All Patients</label>
//                                                 </div>
//                                                 <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
//                                                     <label className="form-check-label btn btn-outline-primary" for="menu5"><input type="checkbox" name="menu[]" id="menu5" value="5" />All Users</label>
//                                                 </div>
//                                                 <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
//                                                     <label className="form-check-label btn btn-outline-primary" for="menu6"><input type="checkbox" name="menu[]" id="menu6" value="6" />All Invoices</label>
//                                                 </div>
//                                                 <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
//                                                     <label className="form-check-label btn btn-outline-primary" for="menu7"><input type="checkbox" name="menu[]" id="menu7" value="7" />All Prescriptions</label>
//                                                 </div>
//                                                 <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
//                                                     <label className="form-check-label btn btn-outline-primary" for="menu8"><input type="checkbox" name="menu[]" id="menu8" value="8" />All Subscriptions</label>
//                                                 </div>
//                                                 <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
//                                                     <label className="form-check-label btn btn-outline-primary" for="menu9"><input type="checkbox" name="menu[]" id="menu9" value="9" />All Doctors</label>
//                                                 </div>
//                                                 <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
//                                                     <label className="form-check-label btn btn-outline-primary" for="menu10"><input type="checkbox" name="menu[]" id="menu10" value="10" />All Staff</label>
//                                                 </div>
//                                                 <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
//                                                     <label className="form-check-label btn btn-outline-primary" for="menu11"><input type="checkbox" name="menu[]" id="menu11" value="11" />Admin - User Management</label>
//                                                 </div>
//                                                 <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
//                                                     <label className="form-check-label btn btn-outline-primary" for="menu12"><input type="checkbox" name="menu[]" id="menu12" value="12" />All Specializations</label>
//                                                 </div>
//                                                 <div className="form-group p-0 m-0 form-check btn-group btn-group-toggle" data-toggle="buttons">
//                                                     <label className="form-check-label btn btn-outline-primary" for="menu13"><input type="checkbox" name="menu[]" id="menu13" value="13" />All Insurances</label>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </form>
//                             </div>
//                             <div className="modal-footer">
//                                 <button type="button" className="btn btn-primary">Save</button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
// 			</>
// 		)
// 	}
// }


// export default Allstaff