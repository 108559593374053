import React, { Component } from 'react';
import viewImg from '../../assets/images/view.png';
// import '../../assets/style.css';
import './Dashboard.css';
import * as $ from 'jquery';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import DashBoardItemsComponent from './dashboard-items/DashBoardItems';
import SpecialityList from '../../mock/dropdown/SpecialityList';
import InsuranceList from '../../mock/dropdown/InsuranceList';
import { Link } from 'react-router-dom';
import { UserContext } from '../../state/UserState';


class DashboardComponent extends Component {

	static contextType = UserContext;
	specialityItemList = {
		speciality: '',
		specialityList: '',
		key: ''
	};

	insuranceItemList = {
		insurance: '',
		insuranceList: '',
		key: ''
	}

	constructor(props) {
		super(props);
		this.state = {
			speciality: '', specialityList: '', insurance: '',
			insuranceList: '',
			specialityId: 0,
			insuranceId: 0,
			location: 0,
			isSpecialityClicked: false,
			isInsuranceClicked: false,
			isMobile: false
		};
		//this.fetchSpecialityList = this.fetchSpecialityList.bind(this);
		this.fetchInsuranceList = this.fetchInsuranceList.bind(this);
		//this.handleBlur=this.handleBlur.bind(this);
		this.specialityItemList.specialityList = SpecialityList;
		this.insuranceItemList.insuranceList = InsuranceList;
	}

	resize() {
		let currentHideNav = (window.innerWidth <= 760);
		if (currentHideNav !== this.state.hideNav) {
			this.setState({ isMobile: currentHideNav });
		}
	}


	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}


	componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
		this.itemList = SpecialityList;

		this.insuranceList = InsuranceList;

		var self = this;
		$('body').on('click', function (evt) {
			if (evt.target.id != "specialization" && evt.target.id != "insurance") {
				self.setState({ isSpecialityClicked: false, isInsuranceClicked: false });
				return;
			}
		});


		$('#mobileapp').owlCarousel({
			loop: true,
			autoplay: true,
			autoplayTimeout: 2000,
			margin: 0,
			nav: false,
			responsive: {
				0: {
					items: 1
				},
				600: {
					items: 1
				},
				1000: {
					items: 1
				}
			}
		})

	}

	toggleSpecialityDropDown = (event) => {
		this.setState({ isSpecialityClicked: true, isInsuranceClicked: false });
		this.specialityItemList.key = 'speciality';
	}

	toggleInsuranceDropDown = (event) => {
		this.setState({ isSpecialityClicked: false, isInsuranceClicked: true });
		this.insuranceItemList.key = 'insurance';
	}

	fetchSpecialityList = (event) => {
		event.stopPropagation();
		event.preventDefault();
		this.setState({ speciality: event.target.value, isSpecialityClicked: true });
		this.specialityItemList.speciality = event.target.value;
		this.specialityItemList.specialityList = this.itemList;
		this.specialityItemList.key = 'speciality';
	}

	fetchInsuranceList = (event) => {
		event.stopPropagation();
		event.preventDefault();
		this.setState({ insurance: event.target.value, isInsuranceClicked: true });
		this.insuranceItemList.insurance = event.target.value;
		this.insuranceItemList.insuranceList = this.insuranceList;
		this.insuranceItemList.key = 'insurance';
	}

	onSelectItem = (value, flag, title) => {
		//console.log("received in dashboad"+value+flag);
		if (flag === 'speciality') {
			this.setState({ speciality: title, specialityId: value });
		} else if (flag === 'insurance') {
			this.setState({ insurance: title, insuranceId: value });
		}
		this.isInsuranceClicked = false;
		this.isSpecialityClicked = false;
	}

	componentWillUnmount() {
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state, callback) => {
			return;
		};
	}

	componentDidMount() {
		$('.hideBackground').on('click', function () {
			$('.tempFullscreen').addClass('tempHide');
		});
	}

	render() {
		const userDetails = this.context;
		console.log(userDetails);
		return (
			<React.Fragment>
				<div className="container">
					<div className="search mt-5">
						<form>
							<div className="d-flex row justify-content-center">
								<div className="loc_sec col-md-4">
									<span className="fa fa-map-marker-alt"></span>
									<input className="location form-control mb-2" placeholder="Enter Location" />
									<a href="#" className="detect"><img width="21" src={require('../../assets/images/gps.svg').default} alt="" /> Detect</a>
								</div>
								<div className="col-md-4">
									<div className="loc_sec">
										<span><ion-icon name="search"></ion-icon></span>
										<input className="specialization form-control mb-2" id="specialization" placeholder="Search Specialization or Name" value={this.state.speciality}
											onChange={(e) => { this.fetchSpecialityList(e) }}
											onClick={(e) => { this.toggleSpecialityDropDown(e) }}
										/>
										{this.state.isSpecialityClicked ? <DashBoardItemsComponent itemList={this.specialityItemList} onSelectItem={this.onSelectItem} ></DashBoardItemsComponent> : ''}
									</div>
								</div>
								<div className="col-md-2 col-12 p-md-0">
									<Link className='btn btn-primary w-100 searchBtn' to={{
										pathname: '/search',
										state: {
											specialityId: this.state.specialityId,
											insuranceId: this.state.insuranceId,
											location: this.state.location,
											from: 'SEARCH_CLICK'
										}
									}} >
									<ion-icon className="mr-3" name="search"></ion-icon> Search</Link>
								</div>
							</div>
						</form>
					</div>

					<section className="slider_bgimage mb-5">
						<div className="bg_title">
							<div className="mainTitle">
								<ul>
									<li>
										{/* <img src={require('../../assets/images/1.png').default} alt="1"/> */}
										<h4>1. Find an <span className="color_change">Ayurvedic Doctor</span></h4>
									</li>
									<li>
										{/* <img src={require('../../assets/images/2.png').default} alt="1"/> */}
										<h4>2. Book an <span className="color_change">Appointment</span></h4>
									</li>
									<li>
										{/* <img src={require('../../assets/images/3.png').default} alt="1"/> */}
										<h4>3. Track in <span className="color_change">real-time</span></h4>
									</li>
								</ul>
								<p className='text-light mb-3'>Discover the best doctors, clinic & hospital the city nearest to you.</p>
								<a href="#" className='btn btn-light'>Know More</a>
							</div>
						</div>
						<div className='sliderImg'>
							<img src={require('../../assets/images/home/doctors.png').default} alt="1"/>
						</div>
					</section>

				</div>

				{/*way */}
				<section className="hori_timeline">
					<div className='container'>
						<div className="row">
							<div className="col-md-8 mt-3">
								<div className='timeline'>
									<div className='start'></div>
								</div>

								<div className='entries row'>
									<div className='entry'>
										<div className="w-100">
											<div className="howto_icon">
												<div className='dot find'></div>
											</div>
											<div className="howto_text">
												<div className='label pl-md-0 pl-3'>
													<div className='time pl-0'>
														Find Ayurvedic Doctor.
													</div>
													<div className='detail'>
														Search for Doctor in your area.
													</div>
													<div className="mt-3">
														<a href="blog.php">Learn More</a>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='entry'>
										<div className="w-100">
											<div className="howto_icon">
												<div className='dot appoint'></div>
											</div>
											<div className="howto_text">
												<div className='label'>
													<div className='time pl-0'>
														Schedule An Appointment.
													</div>
													<div className='detail'>
														Conveniently schedule your appointment online.
													</div>
													<div className="mt-3">
														<a href="blog.php">Learn More</a>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className='entry'>
										<div className="w-100">
											<div className="howto_icon">
												<div className='dot track'></div>
											</div>
											<div className="howto_text">
												<div className='label'>
													<div className='time pl-0'>
														Track your waiting number in Real-time.
													</div>
													<div className='detail'>
														Check your waiting number in real-time to avoid long waiting.
													</div>
													<div className="mt-3">
														<a href="blog.php">Learn More</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-md-4 mt-md-0 mt-4">
								<img src={viewImg} alt="view" />
							</div>
						</div>
					</div>
				</section>

				<section className='features'>
					<div className="container">
						<div className="featureSec">
							<Link to="#" className='featureBlock'>
								<div className='featureImg'>
									<img src={require('../../assets/images/home/1.webp').default} alt="Specialisty" />
								</div>
								<div className='detail'>
									<h5>Instant Video Consultation</h5>
									<p className='text-muted'>Connect within 60 secounds</p>
								</div>
							</Link>

							<Link to="#" className='featureBlock'>
								<div className='featureImg'>
									<img src={require('../../assets/images/home/2.webp').default} alt="Specialisty" />
								</div>
								<div className='detail'>
									<h5>Find Doctors Near You</h5>
									<p className='text-muted'>Confirmed appointments</p>
								</div>
							</Link>

							<Link to="#" className='featureBlock'>
								<div className='featureImg'>
									<img src={require('../../assets/images/home/3.png').default} alt="Specialisty" />
								</div>
								<div className='detail'>
									<h5>Medicines</h5>
									<p className='text-muted'>Essentials at your doorstep</p>
								</div>
							</Link>

							<Link to="#" className='featureBlock'>
								<div className='featureImg'>
									<img src={require('../../assets/images/home/4.png').default} alt="Specialisty" />
								</div>
								<div className='detail'>
									<h5>Lab Tests</h5>
									<p className='text-muted'>Sample pickup at your home</p>
								</div>
							</Link>

							<Link to="#" className='featureBlock'>
								<div className='featureImg'>
									<img src={require('../../assets/images/home/doctor.png').default} alt="Specialisty" />
								</div>
								<div className='detail'>
									<h5>Blogs & News</h5>
									<p className='text-muted'>Read top articles from health experts</p>
								</div>
							</Link>
						</div>
					</div>
				</section>

				<section className='docSpecialitySec'>
					<div className="container">
						<div className='d-flex justify-content-between align-items-center'>
							<div className='heading'>
								<h5>25+ Specialities</h5>
								<p className='text-muted'>Consult with top doctors across specialities</p>
							</div>
							<div>
								<a className='btn btn-outline-dark' href="#">See all Specialities</a>
							</div>
						</div>
						<div className='docSpeciality'>
							<div className='specialityBlock'>
								<div className='specialityImg'>
									<img src={require('../../assets/images/home/speciality/1.svg').default} alt="Specialisty" />
								</div>
								<div>
									<p>Ayurvedic</p>
									<a href="#">Consult now</a>
								</div>
							</div>
							<div className='specialityBlock'>
								<div className='specialityImg'>
									<img src={require('../../assets/images/home/speciality/2.webp').default} alt="Specialisty" />
								</div>
								<div>
									<p>Ayurvedic</p>
									<a href="#">Consult now</a>
								</div>
							</div>
							<div className='specialityBlock'>
								<div className='specialityImg'>
									<img src={require('../../assets/images/home/speciality/3.svg').default} alt="Specialisty" />
								</div>
								<div>
									<p>Ayurvedic</p>
									<a href="#">Consult now</a>
								</div>
							</div>
							<div className='specialityBlock'>
								<div className='specialityImg'>
									<img src={require('../../assets/images/home/speciality/4.webp').default} alt="Specialisty" />
								</div>
								<div>
									<p>Ayurvedic</p>
									<a href="#">Consult now</a>
								</div>
							</div>
							<div className='specialityBlock'>
								<div className='specialityImg'>
									<img src={require('../../assets/images/home/speciality/5.svg').default} alt="Specialisty" />
								</div>
								<div>
									<p>Ayurvedic</p>
									<a href="#">Consult now</a>
								</div>
							</div>
							<div className='specialityBlock'>
								<div className='specialityImg'>
									<img src={require('../../assets/images/home/speciality/6.webp').default} alt="Specialisty" />
								</div>
								<div>
									<p>Ayurvedic</p>
									<a href="#">Consult now</a>
								</div>
							</div>
						</div>
					</div>
				</section>


				{/* moblie app */}
				<section className="get_app_bg d-md-block ">
					<div className="container">
						<div className="row">

							<div className="col-md-6 app_demo mt-5">
								<img width="320" src={require('../../assets/images/home/app_demo.png').default} alt="" />
								<div className="owl-carousel" id="mobileapp">

									<div className="item">
										<img src={require('../../assets/images/home/home.png').default} alt="" />
									</div>
									<div className="item">
										<img src={require('../../assets/images/home/ss1.png').default} alt="" />
									</div>
									<div className="item">
										<img src={require('../../assets/images/home/ss2.png').default} alt="" />
									</div>
									<div className="item">
										<img src={require('../../assets/images/home/ss3.png').default} alt="" />
									</div>
									<div className="item">
										<img src={require('../../assets/images/home/ss4.png').default} alt="" />
									</div>
									<div className="item">
										<img src={require('../../assets/images/home/ss5.png').default} alt="" />
									</div>
								</div>

							</div>

							<div className="col-md-6 padd-130">
								<h2 className="app_title">Get the <span>Aajivan</span> app.</h2>
								<ul className="app_info mt-4">
									<li>Find nearby doctors in your network</li>
									<li>Browse doctor reviews by real patients</li>
									<li>Book doctor appointments with a tap</li>
								</ul>
								<h5 className="mt-4 get_app"><b>Get the app from </b></h5>
								<div className="store_img">
									<a href='#'><img width="200" src={require('../../assets/images/home/appstore.png').default} alt="" /></a>
									<a href='#'><img width="170" src={require('../../assets/images/home/googleplay.png').default} alt="" /></a>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* doctor info app */}
				<section className="doctor_bg">
					<div className="container">
						<div className="row">
							<div className="col-md-6 doctor_image" >
								<img src={require('../../assets/images/home/4.jpg').default} alt="" />
							</div>
							<div className="col-md-6 d-flex align-items-start flex-column justify-content-start">
								<h2 className="doctor_title">Are you a five-star doctor?</h2>
								<p className="doctor_subtitle">List your practice to reach millions of patients.</p>
								<ul className="app_info mt-4">
									<li>Attract and engage new patients</li>
									<li>Build and strengthen your online reputation</li>
									<li>Deliver a premium experience patients love</li>
								</ul>
								<a className="btn btn-primary mt-3" href="hospitalregistration.php" title=""><b>Get Started Today!</b></a>
							</div>
						</div>
					</div>
				</section>

			</React.Fragment>
		)
	}
}


export default DashboardComponent;