import { CheckBox } from "@mui/icons-material";
import { ListItemText, MenuItem, Select, OutlinedInput, InputLabel } from "@mui/material";
import React from "react";



const MultiSelectDropDown = ({ label, name, value, onChange, options = [], }) => {



    return (
        <>
            <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>

            <Select
                multiple
                value={value}
                onChange={onChange}
                name={name}
                renderValue={(selected) => selected.join(', ')}
                input={<OutlinedInput label="Tag" />}
                fullWidth

            >
                {options.map((name) => (
                    <MenuItem>
                        <CheckBox checked={value.includes(name)} />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}

            </Select>
        </>
    )
}


export default MultiSelectDropDown