
const TermConditionList=[
    {
        id:1,
        title:"1. Culpa qui officia",
        details :[
            {
                id:1,
                title:'Aenean vulputate eleifend tellus. <b>Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac  enim. Aliquam lorem ante, dapibus in. Duis aute irure dolor in reprehenderit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat nonproident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            },
            {
                id:2,
                title:'eque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.'
            },
            {
                id:3,
                title:'Aenean vulputate eleifend tellus. <b>Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac  enim. Aliquam lorem ante, dapibus in. Duis aute irure dolor in reprehenderit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat nonproident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            }
        ],
        conditionList:[]
    },
    {
        id:1,
        title:"2. Culpa qui officia 2",
        
        conditionList:[
            {
                id:1,
                title:'sapien ut libero venenatis faucibu. Nullam quis ante.'
            },
            {
                id:2,
                title:'orci eget eros faucibus tincidunt Duis aute irure.'
            },
            {
                id:3,
                title:'qui officia deserunt mollit anim id est laborum.'
            }
        ]
    },
    {
        id:3,
        title:"3. Culpa qui officia 3",
        details :[
            {
                id:1,
                title:'Aenean vulputate eleifend tellus. <b>Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac  enim. Aliquam lorem ante, dapibus in. Duis aute irure dolor in reprehenderit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat nonproident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            },
            {
                id:2,
                title:'eque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.'
            },
            {
                id:3,
                title:'Aenean vulputate eleifend tellus. <b>Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac  enim. Aliquam lorem ante, dapibus in. Duis aute irure dolor in reprehenderit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat nonproident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            }
        ],
        conditionList:[
            {
                id:1,
                title:'sapien ut libero venenatis faucibu. Nullam quis ante.'
            },
            {
                id:2,
                title:'orci eget eros faucibus tincidunt Duis aute irure.'
            },
            {
                id:3,
                title:'qui officia deserunt mollit anim id est laborum.'
            }
        ]
    }

];


export {TermConditionList};