import React, { Component } from 'react';
import { Link   } from "react-router-dom";

class Invoice extends Component{
    render(){
        return(
            <>
                <div className="container">
                    <div class="page-wrapper">
                        <div class="content container-fluid">
                            <div class="invoice-container">
                                <div class="row">
                                    <div class="col-sm-6 m-b-20">
                                        <img alt="Logo" class="inv-logo img-fluid" src="assets/img/logo.png"/>
                                    </div>
                                    <div class=" col-sm-6 m-b-20">
                                        <div class="invoice-details">
                                            <h3 class="text-uppercase">Invoice #INV-0001</h3>
                                            <ul class="list-unstyled mb-0">
                                                <li>Date: <span>September 30, 2022</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 m-b-20">
                                        <ul class="list-unstyled mb-0">
                                            <li>Apollo Delhi Hospital</li>
                                            <li>Lal Killa,</li>
                                            <li>Delhi, 110005</li>
                                            <li>GST No:</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6 col-lg-7 col-xl-8 m-b-20">
                                        <h6>Invoice to</h6>
                                        <ul class="list-unstyled mb-0">
                                            <li>
                                                <h5 class="mb-0"><strong>Prathap Reddy</strong></h5>
                                            </li>
                                            <li>4417 Goosetown Drive</li>
                                            <li>Taylorsville, NC, 28681</li>
                                            <li>United States</li>
                                            <li>1236547899</li>
                                            <li><a href="#"></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-sm-6 col-lg-5 col-xl-4 m-b-20">
                                        <h6>Payment Details</h6>
                                        <ul class="list-unstyled invoice-payment-details mb-0">
                                            <li>
                                                <h5>Total Due: <span class="text-end">800</span></h5>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>ITEM</th>
                                                <th class="d-none d-sm-table-cell">DESCRIPTION</th>
                                                <th class="text-nowrap">UNIT COST</th>
                                                <th>QTY</th>
                                                <th>TOTAL</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Monthly</td>
                                                <td class="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur
                                                    adipiscing elit</td>
                                                <td>750</td>
                                                <td>1</td>
                                                <td>750</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <div class="row invoice-payment">
                                        <div class="col-sm-7">
                                        </div>
                                        <div class="col-sm-5">
                                            <div class="m-b-20">
                                                <h6 class="mt-3">Total due</h6>
                                                <div class="table-responsive no-border">
                                                    <table class="table mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <th>Subtotal:</th>
                                                                <td class="text-end">750</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Tax: <span class="text-regular">(18%)</span></th>
                                                                <td class="text-end">50</td>
                                                            </tr>
                                                            <tr>
                                                                <th>Total:</th>
                                                                <td class="text-end text-primary">
                                                                    <h5>800</h5>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="invoice-info">
                                        <h5>Other information</h5>
                                        <p class="text-muted mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
                                            sed dictum ligula, cursus blandit risus. Maecenas eget metus non tellus dignissim
                                            aliquam ut a ex. Maecenas sed vehicula dui, ac suscipit lacus. Sed finibus leo vitae
                                            lorem interdum, eu scelerisque tellus fermentum. Curabitur sit amet lacinia lorem.
                                            Nullam finibus pellentesque libero.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


export default Invoice