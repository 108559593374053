import React, { Component } from "react";
import HospitalFilterComponent from "../../Search/SearchFilter/HospitalFilter";
import { RescheduleDoctors } from "../../../mock/RescheduleDoctors";
import { Link } from "react-router-dom";

class UserRescheduleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAppointment: RescheduleDoctors,
    };
  }
  selectedFilter = (selectedVal) => {
    console.log(" menu selescted ");
  };
  openGoogemap = (e, doctor) => {
    if (doctor !== null && doctor !== undefined) {
      window.open(
        "https://maps.google.com/?q=" +
          doctor.lattitude +
          "," +
          doctor.longitude
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 d-none d-md-block">
            <HospitalFilterComponent onSelectFilter={this.selectedFilter} />
          </div>
          <div className="col-md-12">
            <div className="scroll_upcoming">
              {/* 1 */}
              {this.state.userAppointment !== undefined &&
              this.state.userAppointment !== null &&
              this.state.userAppointment.length > 0
                ? this.state.userAppointment.map((appointment, i) => {
                    return (
                     <div className="dashboard_box card" key={i}>
                        <div className="d-flex justify-content-start">
                           <div className="img_block text-center">
                              <img src={require('../../../assets/images/doc1.png').default} alt="doctor profile" />
                           </div>
                           <div className="pr-0 docinfo">
                              <p className="title_name mb-md-0 mb-2">
                                 <strong>{appointment.doctor.name}</strong>
                              </p>
                              <p className="sub_font mb-md-0 mb-1">
                                 <i className="fa fa-graduation-cap"></i>{" "}
                                 {appointment.doctor.specialization.name}
                              </p>
                              <p
                                 className="sub_font mb-md-0 mb-1"
                                 style={{ cursor: "pointer" }}
                                 onClick={(e) => {
                                    this.openGoogemap(e, appointment.doctor);
                                 }}
                              >
                                 <i className="fa fa-map-marker mr-2"></i>{" "}
                                 {appointment.doctor.address}
                              </p>
                              <p className="sub_font mb-md-0 mb-1">
                                 <i className="fa fa-mobile-alt mr-2"></i>{" "}
                                 {appointment.doctor.phone}
                              </p>
                           </div>
                        </div>
                        <div>
                           <div className="text-right mt-2">
                              <p className="title_name mb-2" href="#">
                                 <strong>{appointment.patient.name}</strong>
                              </p>
                              <p className="title_name" data-toggle="modal" data-target="#reschedulemodel" >
                                 <strong>Schedule Again</strong>
                              </p>
                           </div>
                           <div className="text-right">
                              <Link to={{ pathname: "/user/prescription", state: { doctorId: appointment.doctor.id, patientId: appointment.patient.id, },}} className="btn btn-primary mb-2 m_font_10" key={appointment.id}>
                                 View Prescription
                              </Link>

                              <Link to={{ pathname: "/user/invoice", state: { doctorId: appointment.doctor.id, patientId: appointment.patient.id, },}} className="btn btn-primary m_font_10" key={appointment.id}>
                                 View Invoice
                              </Link>
                           </div>
                        </div>

                        <div className="mt-2">
                           <p className="title_name mb-2">
                              <i className="far fa-clock"></i>{" "}
                              <strong>{appointment.scheduleTime}</strong>
                           </p>
                           <p className="title_name">
                              <i className="far fa-hospital"></i>{" "}
                              <strong>{appointment.doctor.location.name}</strong>
                           </p>
                        </div>

                     </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default UserRescheduleComponent;
