import { Grid, Button, Box, RadioGroup, Radio, FormControlLabel, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TextInput from 'src/common/TextInput';
import DropDown from 'src/common/DropDown';
import { useCookies } from 'react-cookie';

import {
    GetHospitalDetail, HospitalAccountVerification, getHospitalDocuments, getCityList, getStateList, HospitalOwnerSettings,
    CountryList
} from 'src/redux/Main/actions';

const OwnerAccountSettings = (props) => {
    const [cookies, setCookie] = useCookies(['authtoken']);

    const [state, setState] = useState({
        name: '',
        mobile: '',
        email: '',
        pincode: '',
        country_id: "0",
        state_id: "0",
        city_id: "0",
        gender: 'Male',
        current_password: "",
        new_password: "",
        confirm_password: "",
        documents: [],
    })




    useEffect(() => {
        const { authtoken = null } = cookies

        props.getCountryList({});
        props.HospitalDetails({ authToken: authtoken })

    }, [])


    useEffect(() => {

        setState({ ...state, ...props.hospitalInfo })

    }, [props.hospitalInfo])


    const OwnerChangeHandler = (e) => {

        const { name, value, checked } = e.target;
        if (name === 'country_id') {
            props.getStates({ countryId: value })
        } if (name === 'state_id') {
            props.getCities({ stateId: value })
        }

        let obj = { ...state };
        obj[name] = value;

        setState(obj)
    }


    const FileUploadHanler = (event) => {
        let obj = { ...state };
        const file = event.target.files[0];
        console.log(file);
        const files = [...obj.documents]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
            // console.log('called: ', reader.result.split(',')[1])
            await files.push(reader.result.split(',')[1])
            // setBase64IMG(reader.result)
            console.log(files);
            obj['documents'] = files;
            setState(obj)
        }

    }


    const SubmitOwnerAccountHandler = (event) => {
        event.preventDefault();
        const { authtoken = null } = cookies

        props.HospitalOwnerSettings({ authToken: authtoken, ...state })
    }

    const { countriesList = [], stateList = [], cityList = [] } = props || {};

    console.log("Account Settings", props)

    return (
        <>
            <Grid container spacing={4} style={{ paddingTop: 30 }}>
                <Grid item xs={12} md={4} sm={6} className='pt-0'>

                    <TextInput
                        placeholder={"Name"}
                        name="name"
                        value={state.name}
                        onChange={OwnerChangeHandler}

                    />

                </Grid>
                <Grid item xs={12} md={4} sm={6} className='pt-0'>

                    <TextInput
                        placeholder={"Phone Number"}
                        name='mobile'
                        value={state?.mobile}
                        onChange={OwnerChangeHandler}

                    />

                </Grid>
                <Grid item xs={12} md={4} sm={6} className='pt-0'>

                    <TextInput
                        placeholder={"Email Id"}
                        onChange={OwnerChangeHandler}
                        name='email'
                        value={state?.email}
                    />

                </Grid>
                <Grid item xs={12} md={4} sm={6} className='pt-0'>

                    <DropDown
                        placeholder={"Select Country"}
                        selectLabel={"Select Country"}
                        value={state.country_id}
                        name="country_id"
                        options={countriesList?.map((item) => ({ name: item.name, value: item.id }))}
                        onChange={OwnerChangeHandler}



                    />

                </Grid>
                <Grid item xs={12} md={4} sm={6} className='pt-0'>

                    <DropDown
                        placeholder={"Select State"}
                        selectLabel={"Select State"}
                        value={state.state_id}
                        name="state_id"
                        options={stateList?.map((item) => ({ name: item.name, value: item.id }))}
                        onChange={OwnerChangeHandler}
                    />

                </Grid>
                <Grid item xs={12} md={4} sm={6} className='pt-0'>

                    <DropDown
                        placeholder={"Select City"}
                        selectLabel={"Select City"}
                        value={state.city_id}
                        name="city_id"
                        options={cityList?.map((item) => ({ name: item.name, value: item.id }))}
                        onChange={OwnerChangeHandler}

                    />

                </Grid>
                <Grid item xs={12} md={4} sm={6} className='pt-0'>

                    <TextInput
                        placeholder={"Pin Code"}
                        name="pincode"
                        value={state.pincode}
                        onChange={OwnerChangeHandler}

                    />

                </Grid>
                <Grid item xs={12} md={4} sm={6} className='pt-0'>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <p style={{ padding: '18px' }}>Gender</p>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            row
                            name="gender"
                            onChange={OwnerChangeHandler}

                        >
                            <FormControlLabel value="Male" control={<Radio checked={state?.gender === "Male"} />} label="Male" />
                            <FormControlLabel value="Female" control={<Radio checked={state?.gender === "Female"} />} label="Female" />
                        </RadioGroup>
                    </Box>


                </Grid>
            </Grid>

            <hr />
            <h6 className='text-primary fw-bold'>Change Password</h6>

            <Grid container spacing={4} style={{ paddingTop: '30px' }}>
                <Grid item xs={12} md={4} sm={6} className='pt-0'>

                    <TextInput
                        placeholder={"current Password"}
                        name="current_password"
                        value={state.current_password}
                        onChange={OwnerChangeHandler}

                    />

                </Grid>
                <Grid item xs={12} md={4} sm={6} className='pt-0'>

                    <TextInput
                        placeholder={"New Password"}
                        name="new_password"
                        value={state.new_password}
                        onChange={OwnerChangeHandler}

                    />

                </Grid>
                <Grid item xs={12} md={4} sm={6} className='pt-0'>

                    <TextInput
                        placeholder={"Confirm Password"}
                        name="confirm_password"
                        value={state.confirm_password}
                        onChange={OwnerChangeHandler}

                    />

                </Grid>
            </Grid>

            <hr />

            <h6 className='text-primary fw-bold'>Upload ID (Optional)</h6>

            <Grid container >
                <Grid item xs={12} md={4} sm={6} >
                    <input type="file" className="form-control" onChange={FileUploadHanler} />

                </Grid>

            </Grid>

            <Button
                className='primary-button float-right mb-3'
                onClick={SubmitOwnerAccountHandler}
            >
                Save
            </Button>

        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    getDocuments: (params) => dispatch(getHospitalDocuments(params)),
    getCountryList: (params) => dispatch(CountryList(params)),
    getStates: params => dispatch(getStateList(params)),
    getCities: params => dispatch(getCityList(params)),
    HospitalOwnerSettings: params => dispatch(HospitalOwnerSettings(params)),
    HospitalDetails: params => dispatch(GetHospitalDetail(params)),
    VerifyHospital: params => dispatch(HospitalAccountVerification(params))
});

const mapStateToProps = (state) => {
    return {
        countriesList:
            state.mainReducer?.countryListReducer?.response?.data || [],
        stateList:
            state.mainReducer?.stateListReducer?.response?.data || [],
        cityList:
            state.mainReducer?.cityListReducer?.response?.data || [],
        hospitalInfo: state.mainReducer.hospitalDetailsReducer?.response?.data[0] || {},
        authToken:
            state.mainReducer?.userRegistrationReducer?.response?.data?.authToken ||
            null,
        OTPMatch:
            state.mainReducer?.userVerifyOTPReducer?.response?.status === 200 ||
            false,
        RegistrationSucess:
            state.mainReducer?.HospitalRegistrationReducer?.response?.status === 200 ||
            false,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerAccountSettings)