const UserHelp=[
{
    id:1,
    title:'Help Title 1 ',
    answer:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt deserunt mollit anim id est laborum.   '
},
{
    id:2,
    title:'Help Title 2 ',
    answer:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt deserunt mollit anim id est laborum.   '
},
{
    id:3,
    title:'Help Title 3 ',
    answer:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt deserunt mollit anim id est laborum.   '
},
{
    id:4,
    title:'Help Title 4 ',
    answer:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt deserunt mollit anim id est laborum.   '
},
{
    id:5,
    title:'Help Title 5 ',
    answer:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt deserunt mollit anim id est laborum.   '
},
{
    id:6,
    title:'Help Title 6 ',
    answer:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt deserunt mollit anim id est laborum.   '
},
{
    id:7,
    title:'Help Title 7 ',
    answer:'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt deserunt mollit anim id est laborum.   '
}
];

export {UserHelp};