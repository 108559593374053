import React, { Component } from 'react';
import { Link } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"


import DataTableGrid from 'src/common/DataGrid/DataGrid';
import { connect } from 'react-redux';

import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const Allpatients = () => {

    const rows = [
        {
            id: 1, col1: "Hello",
            col2: "World",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 2, col1: "MUI X",
            col2: "is awesome",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 3, col1: "Material UI",
            col2: "is amazing",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 4, col1: "MUI",
            col2: "Lokesh",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 5, col1: "Joy UI",
            col2: "is awesome",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 6, col1: "MUI Base",
            col2: "is amazing",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        }
    ];


    const columns = [
        { field: "id", headerName: "Patient ID", },
        { field: "col1", headerName: "Patient Name", width: 150 },
        { field: "col5", headerName: "Age", width: 150 },
        { field: "col2", headerName: "Address", width: 150 },
        { field: "col4", headerName: "Phone", width: 150 },
        { field: "col3", headerName: "Last Visit", width: 150 },
        { field: "col6", headerName: "Paid", width: 150 },
        {
            headerName: "Action",
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    // onClick={deleteUser(params.id)}
                    onClick={() => { }}
                />,
                <GridActionsCellItem
                    icon={<SecurityIcon />}
                    label="View"
                    onClick={() => { }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<FileCopyIcon />}
                    label="Edit"
                    onClick={() => { }}
                    showInMenu
                />,
            ]
        },    ];

    return (
        <div className="container dct-appoinment">
            <h5 className='mt-3 fw-bold mb-3'>All Patients</h5>

            <DataTableGrid
                rows={rows}
                columns={columns}
                rowCountState={rows.length}

            />
        </div>
    )
}

export default connect()(Allpatients)

// class Allpatients extends React.Component{

// 	componentDidMount() {
// 		document.title = "All Patients"
// 		$('#allPatients').DataTable({
// 			dom: '<"top"Bflp>rt<"bottom"ip>',
//             pageLength: 5,
//             buttons: ['copy', 'csv', 'print', 'excel']
// 		});
// 	}

// 	render(){
// 		return(
// 			<>
// 				<div className="container">
// 					<div className="row">
// 						<div className="col-md-12">
// 							<h5 className='mt-3 fw-bold mb-3'>All Patients</h5>
// 							<div className="card-table mb-0 card p-2">
// 								<div className="card-body p-0">
// 									<div className="table-responsive">
// 										<table className="datatable table table-hover table-center mb-0" id="allPatients">
// 											<thead>
// 												<tr>
// 													<th>Patient ID</th>
// 													<th>Patient Name</th>
// 													<th>Age</th>
// 													<th>Address</th>
// 													<th>Phone</th>
// 													<th>Last Visit</th>
// 													<th>Paid</th>
// 													<th>Action</th>
// 												</tr>
// 											</thead>
// 											<tbody>
// 												<tr>
// 													<td>#PT001</td>
// 													<td>
// 														<p>Mukesh Sharma</p>
// 													</td>
// 													<td>29</td>
// 													<td>Mumbai</td>
// 													<td>8286329170</td>
// 													<td>20 Oct 2022</td>
// 													<td>Rs. 250</td>
// 													<td>
// 														<Link to="/management/patientdetail" className='btn btn-outline-primary mr-2'><i className="fas fa-eye"></i></Link>
// 														<Link to="/management/editpatient" className='btn btn-outline-success mr-2'><i className="fas fa-edit"></i></Link>
// 														<Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
// 													</td>
// 												</tr>
// 												<tr>
// 													<td>#PT002</td>
// 													<td>
// 														<p>Ramesh Sharma</p>
// 													</td>
// 													<td>31</td>
// 													<td>Mumbai</td>
// 													<td>8286329170</td>
// 													<td>20 Sept 2022</td>
// 													<td>Rs. 750</td>
// 													<td>
// 														<Link to="/management/patientdetail" className='btn btn-outline-primary mr-2'><i className="fas fa-eye"></i></Link>
// 														<Link to="/management/editpatient" className='btn btn-outline-success mr-2'><i className="fas fa-edit"></i></Link>
// 														<Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
// 													</td>
// 												</tr>
// 											</tbody>
// 										</table>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</>
// 		)
// 	}
// }


// export default Allpatients