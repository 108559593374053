import React, { Component } from 'react';
import * as $ from 'jquery';


class HelpComponent extends Component{


    componentDidMount(){
        $("div.bhoechie-tab-menu>div.list-group>a").click(function(e) {
            e.preventDefault();
            $(this).siblings('a.active').removeClass("active");
            $(this).addClass("active");
            var index = $(this).index();
            $("div.bhoechie-tab>div.bhoechie-tab-content").removeClass("active");
            $("div.bhoechie-tab>div.bhoechie-tab-content").eq(index).addClass("active");
        });
    }

    
    render() {

        return (
            <React.Fragment>

<div className="container">
    <div className="row">
        <div className="col-lg-12 col-md-12 col-12 bhoechie-tab-container">
            <div className="row"> 
                <div className="col-lg-3 col-md-3 col-3 bhoechie-tab-menu">
                  <div className="list-group pl_15">
                    <a href="#faq" className="list-group-item active text-center">
                      <i className="fas fa-question help_icon"></i><br/>FAQ
                    </a>
                    <a href="#" className="list-group-item text-center">
                      <i className="far fa-comment-alt help_icon"></i><br/>Feedback
                    </a>
                    <a href="#" className="list-group-item text-center">
                      <i className="fas fa-info help_icon"></i><br/>Inquiry
                    </a>
                    <a href="#" className="list-group-item text-center">
                      <i className="fas fa-handshake help_icon"></i><br/>Customer Support
                    </a>
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 col-9 bhoechie-tab">
                    <div id="accordion" className="p-2 bhoechie-tab-content active">
                        <div className="card">
                            <article className="card-group-item">
                                <header className="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse11" aria-expanded="true" className="">
                                        <i className="icon-action fa fa-chevron-down float-right primary_text"></i>
                                        <h6 className="title">Lorem ipsum dolor sit amet?</h6>
                                    </a>
                                </header>
                                <div className="filter-content collapse" id="collapse11" >
                                    <div className="card-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt deserunt mollit anim id est laborum.  </p>            
                                    </div>
                                </div>
                            </article>

                            <article className="card-group-item">
                                <header className="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse22" aria-expanded="true" className="">
                                        <i className="icon-action fa fa-chevron-down float-right primary_text"></i>
                                        <h6 className="title">Lorem ipsum dolor sit amet?</h6>
                                    </a>
                                </header>
                                <div className="filter-content collapse" id="collapse22" >
                                    <div className="card-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt deserunt mollit anim id est laborum.  </p>            
                                    </div>
                                </div>
                            </article>

                            <article className="card-group-item">
                                <header className="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse33" aria-expanded="true" className="">
                                        <i className="icon-action fa fa-chevron-down float-right primary_text"></i>
                                        <h6 className="title">Lorem ipsum dolor sit amet?</h6>
                                    </a>
                                </header>
                                <div className="filter-content collapse" id="collapse33" >
                                    <div className="card-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt deserunt mollit anim id est laborum.  </p>            
                                    </div>
                                </div>
                            </article>
                            <article className="card-group-item">
                                <header className="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse44" aria-expanded="false" className="collapsed">
                                        <i className="icon-action fa fa-chevron-down float-right primary_text"></i>
                                        <h6 className="title">Tempor incididunt deserunt mollit anim id est laborum?</h6>
                                    </a>
                                </header>
                                <div className="filter-content collapse" id="collapse44" >
                                    <div className="card-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt deserunt mollit anim id est laborum.  </p>
                                    </div>
                                </div>
                            </article>                        
                            <article className="card-group-item">
                                <header className="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse55" aria-expanded="false" className="collapsed">
                                        <i className="icon-action fa fa-chevron-down float-right primary_text"></i>
                                        <h6 className="title">Consectetur adipisicing elit, sed do eiusmod?</h6>
                                    </a>
                                </header>
                                <div className="filter-content collapse" id="collapse55" >
                                    <div className="card-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt deserunt mollit anim id est laborum.  </p>
                                    </div>
                                </div>
                            </article>                        
                            <article className="card-group-item">
                                <header className="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse66" aria-expanded="false" className="collapsed">
                                        <i className="icon-action fa fa-chevron-down float-right primary_text"></i>
                                        <h6 className="title">Consectetur adipisicing elit, sed do eiusmod?</h6>
                                    </a>
                                </header>
                                <div className="filter-content collapse" id="collapse66" >
                                    <div className="card-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt deserunt mollit anim id est laborum.  </p>
                                    </div>
                                </div>
                            </article> 
                            <article className="card-group-item">
                                <header className="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse77" aria-expanded="false" className="collapsed">
                                        <i className="icon-action fa fa-chevron-down float-right primary_text"></i>
                                        <h6 className="title">Consectetur adipisicing elit, sed do eiusmod?</h6>
                                    </a>
                                </header>
                                <div className="filter-content collapse" id="collapse77" >
                                    <div className="card-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt deserunt mollit anim id est laborum.  </p>
                                    </div>
                                </div>
                            </article> 
                            <article className="card-group-item">
                                <header className="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse88" aria-expanded="false" className="collapsed">
                                        <i className="icon-action fa fa-chevron-down float-right primary_text"></i>
                                        <h6 className="title">Consectetur adipisicing elit, sed do eiusmod?</h6>
                                    </a>
                                </header>
                                <div className="filter-content collapse" id="collapse88" >
                                    <div className="card-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt deserunt mollit anim id est laborum.  </p>
                                    </div>
                                </div>
                            </article> 
                            <article className="card-group-item">
                                <header className="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse99" aria-expanded="false" className="collapsed">
                                        <i className="icon-action fa fa-chevron-down float-right primary_text"></i>
                                        <h6 className="title">Consectetur adipisicing elit, sed do eiusmod?</h6>
                                    </a>
                                </header>
                                <div className="filter-content collapse" id="collapse99" >
                                    <div className="card-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt deserunt mollit anim id est laborum.  </p>
                                    </div>
                                </div>
                            </article> 
                            <article className="card-group-item">
                                <header className="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse111" aria-expanded="false" className="collapsed">
                                        <i className="icon-action fa fa-chevron-down float-right primary_text"></i>
                                        <h6 className="title">Do eiusmod tempor incididunt deserunt?</h6>
                                    </a>
                                </header>
                                <div className="filter-content collapse" id="collapse111" >
                                    <div className="card-body">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt deserunt mollit anim id est laborum.  </p>
                                    </div>
                                </div>
                            </article>                                              
                        </div>
                    </div>
                    {/* <!-- Feedback section --> */}
                    <div className="bhoechie-tab-content">
                        <h4 className="text-center">Give Your Feedback Here:</h4>
                        <div className="mt-4">
                            <form>
                                <div className="row">
                                    <div className="col-md-4 col-12 mt-2 mt-md-0">
                                        <input type="text" className="form-control textarea_size" placeholder="Name" />
                                    </div>

                                    <div className="col-md-4 col-12 mt-2 mt-md-0">
                                        <div className="input-group">
                                            <span className="input-group-addon input-group_span">+91</span>
                                            <input type="text" className="form-control textarea_size" placeholder="Phone Number" />
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-12 mt-2 mt-md-0">
                                        <input type="text" className="form-control textarea_size" placeholder="Email Id" />                  
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <input type="text" className="form-control textarea_size" placeholder="Feedback title" />
                                    </div>              

                                    <div className="col-md-12 mt-2">
                                        <textarea className="form-control textarea_size" cols="20" rows="3" placeholder="Feedback"></textarea>
                                    </div>

                                    <div className="row w-100">
                                        <div className="col-12 text-right mb-5 mb-md-0 mt-4 p-0">
                                            <a className="btn btn-danger" href="#">Cancel</a>
                                            <a className="btn btn-primary" href="#">Submit</a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>                        
                    </div>

        
                    {/* <!-- Inquiry search --> */}
                    <div className="bhoechie-tab-content">
                        <h4 className="text-center">Send Your Inquiry Here:</h4>
                        <div className="mt-4">
                            <form>
                                <div className="row">
                                    <div className="col-md-4 col-12 mt-2 mt-md-0">
                                        <input type="text" className="form-control textarea_size" placeholder="Name" />
                                    </div>

                                    <div className="col-md-4 col-12 mt-2 mt-md-0">
                                        <div className="input-group">
                                            <span className="input-group-addon input-group_span">+91</span>
                                            <input type="text" className="form-control textarea_size" placeholder="Phone Number" />
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-12 mt-2 mt-md-0">
                                        <input type="text" className="form-control textarea_size" placeholder="Email Id" />                  
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <input type="text" className="form-control textarea_size" placeholder="Inquiry title" />
                                    </div>              

                                    <div className="col-md-12 mt-2">
                                        <textarea className="form-control textarea_size" cols="20" rows="3" placeholder="Inquiry"></textarea>
                                    </div>

                                    <div className="row w-100">
                                        <div className="col-12 text-right mb-5 mb-md-0 mt-4 p-0">
                                            <a className="btn btn-danger" href="#">Cancel</a>
                                            <a className="btn btn-primary" href="#">Submit</a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <!-- Customer Support --> */}
                    <div className="bhoechie-tab-content">
                        <h4 className="text-center">We Provide 24*7 Customer Support</h4>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <i className="fas fa-users modal_icon3"></i> <a className="modal_icon3_a" href="tel:+91-123456789" title="+91-123456789"> <span> +91-123456789</span></a>
                                    </div>
                                    <div className="col-md-6">
                                        <i className="fas fa-envelope modal_icon3"></i> <a className="modal_icon3_a" href="mailto:contact.aajivan@gmail.com" title="contact.aajivan@gmail.com"><span>  aajivan@gmail.com</span></a>   
                                    </div>
                                </div>                          
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

            </React.Fragment>
        )
    }

}

export default HelpComponent;