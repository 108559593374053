import React, { Component, useState } from 'react';
import { Link } from "react-router-dom";
import * as $ from 'jquery';
import { render } from '@testing-library/react';

import DataTableGrid from 'src/common/DataGrid/DataGrid';
import { connect } from 'react-redux';
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import DeleteModal from 'src/common/DeleteModal';

import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DialogBox from 'src/common/DialogBox';
import TextInput from 'src/common/TextInput';

const Allinsurance = () => {

    const [openModal, setOpenModal] = useState(false);
    const [onDelete, setOnDelete] = useState(false);
    const rows = [
        {
            id: 1, col1: "Hello",
            col2: "World",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 2, col1: "MUI X",
            col2: "is awesome",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 3, col1: "Material UI",
            col2: "is amazing",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 4, col1: "MUI",
            col2: "Lokesh",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 5, col1: "Joy UI",
            col2: "is awesome",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 6, col1: "MUI Base",
            col2: "is amazing",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        }
    ];


    const columns = [
        { field: "id", headerName: "#", width: 150 },
        { field: "col1", headerName: "Insurance Icon", width: 150 },
        { field: "col2", headerName: "Insurance Name", width: 150 },
        {
            headerName: "Action",
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    // onClick={deleteUser(params.id)}
                    onClick={() => { }}
                />,
                <GridActionsCellItem
                    icon={<SecurityIcon />}
                    label="View"
                    onClick={() => { }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<FileCopyIcon />}
                    label="Edit"
                    onClick={() => { }}
                    showInMenu
                />,
            ]
        },
    ];


    const handleSubmit = () => {

    }

    return (
        <div className="container dct-appoinment">
            <br />
            <div className='d-flex d-flex justify-content-between mb-3 align-items-center'>
                <h5 className='fw-bold'>All Insurances</h5>
                <Button className='primary-button'
                    size='small'
                    onClick={() => {
                        setOpenModal(true)
                    }}
                >Add Insurance
                </Button>
            </div>

            <DataTableGrid
                rows={rows}
                columns={columns}
                rowCountState={rows.length}

            />

            {onDelete &&
                <DeleteModal
                    open={onDelete}

                    handleClose={() => setOnDelete(false)}
                    handleSubmit={() => { }}
                />
            }

            <DialogBox
                open={openModal}
                handleClose={() => setOpenModal(false)}
            >

                <DialogTitle>Add Insurance</DialogTitle>
                <DialogContent dividers>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sm={6}>
                            <Typography>Select Icon</Typography>
                            <input type="file" className="form-control mb-2" placeholder="Select Icon" />

                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                            <TextInput
                                placeholder={'Insurances Name'}
                                name='name'
                                onChange={() => { }}

                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenModal(false)}
                        variant='outlined'>close</Button>
                    <Button onClick={handleSubmit} variant='contained'>Submit</Button>
                </DialogActions>
            </DialogBox>
        </div>
    )
}

export default connect()(Allinsurance)

// class Allinsurance extends React.Component{

//     componentDidMount() {
// 		document.title = "All Insurance"
// 		$('#allInsurance').DataTable({
// 			dom: '<"top"Bflp>rt<"bottom"ip>',
//             pageLength: 5,
//             buttons: ['copy', 'csv', 'print', 'excel']
// 		});
// 	}

//     render(){
//         return(
//             <>
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-12">
//                             <h5 className="mt-3 fw-bold">All Insurances</h5>
//                             <a href="#" className='btn btn-primary mb-3 mt-2' data-toggle="modal" data-target="#addInsurance">Add Specialization</a>
//                             <div className="card-table mb-0 card p-2">
//                                 <div className="card-body p-0">
//                                     <table className="table" id="allInsurance">
//                                         <thead>
//                                             <tr>
//                                                 <th>#</th>
//                                                 <th>Insurance Icon</th>
//                                                 <th>Insurance Name</th>
//                                                 <th>Action</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody>
//                                             <tr>
//                                                 <td>1</td>
//                                                 <td scope="row">
//                                                     <img src={require('../../../assets/images/1.png').default} width="50" alt="" />
//                                                 </td>
//                                                 <td>Medcare</td>
//                                                 <td>
//                                                     <Link to="#" className='btn btn-outline-success mr-2' data-toggle="modal" data-target="#addInsurance"><i className="fas fa-edit"></i></Link>
//                                                     <Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                                 </td>
//                                             </tr>
//                                             <tr>
//                                                 <td>2</td>
//                                                 <td scope="row">
//                                                     <img src={require('../../../assets/images/1.png').default} width="50" alt="" />
//                                                 </td>
//                                                 <td scope="row">Ayushman Health Insurance</td>
//                                                 <td>
//                                                     <Link to="#" className='btn btn-outline-success mr-2' data-toggle="modal" data-target="#addInsurance"><i className="fas fa-edit"></i></Link>
//                                                     <Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                                 </td>
//                                             </tr>
//                                             <tr>
//                                                 <td>3</td>
//                                                 <td scope="row">
//                                                     <img src={require('../../../assets/images/1.png').default} width="50" alt="" />
//                                                 </td>
//                                                 <td scope="row">Dental Insurance</td>
//                                                 <td>
//                                                     <Link to="#" className='btn btn-outline-success mr-2' data-toggle="modal" data-target="#addInsurance"><i className="fas fa-edit"></i></Link>
//                                                     <Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                                 </td>
//                                             </tr>
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="modal fade" id="addInsurance" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
//                     <div className="modal-dialog modal-dialog-centered" role="document">
//                         <div className="modal-content">
//                             <div className="modal-header">
//                                 <h5 className="modal-title" id="exampleModalLabel">Add Insurance</h5>
//                                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
//                                     <span aria-hidden="true">&times;</span>
//                                 </button>
//                             </div>
//                             <div className="modal-body">
//                                 <form>
//                                     <div className="row">
//                                         <div className="col-md-12">
//                                             <div className="form-group">
//                                                 <label htmlFor="name">Select Icon</label>
//                                                 <input type="file" className="form-control mb-2" placeholder="Select Icon" />
//                                             </div>
//                                         </div>
//                                         <div className="col-md-12">
//                                             <div className="form-group">
//                                                 <label htmlFor="name">Insurance</label>
//                                                 <input type="text" className="form-control mb-2" placeholder="Name" />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </form>
//                             </div>
//                             <div className="modal-footer">
//                                 <button type="button" className="btn btn-primary">Submit</button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </>
//         )
//     }
// }


// export default Allinsurance