
import React, { Component } from 'react';
import { withRouter} from "react-router-dom";



class DashBoardItemComponent extends Component{

    constructor(props) {
        super(props);
        this.state = {date: new Date()};
      }

      onItemClick = (e)=>{
            //console.log(this.props.item.id);
            this.props.onClickItems(this.props.item.id,this.props.flag,this.props.item.title);
      }
   
    render() {
        return (
            
            <li key={this.props.item.id} onClick={this.onItemClick}>
            <div className="col-md-12">
            <div className="row">
                <div className="col-md-1 col-2">
                    <i className="fa fa-search"></i>
                </div>
                <div className="col-md-8 col-6">
                    <a href="#"><p className="mt-2">{this.props.item.title}</p></a>
                </div>
                {this.props.flag === 'speciality' ?
                (<div className="col-md-3 col-4 speciality_text">
                    <p className="mt-2 text-right">{this.props.item.speciality}</p>
                </div>) : ''}
            </div>
        </div>
        </li>
        )
    }
}
export default withRouter(DashBoardItemComponent);