import actionTypes from "../actionTypes";


const adminTabValue = {
    activeTab: 0
}

export const AdminNavTabReducer = (state = adminTabValue, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_NAV_TAB_CHANGE:
            return { ...state, activeTab: action.params }

        default:
            return state;
    }
}

const hospitalDetails = {
    isFetching: false,
    response: null
}

export const hospitalDetailsReducer = (state = hospitalDetails, action) => {
    switch (action.type) {
        case actionTypes.GET_HOSPITAL_DETAILS_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_HOSPITAL_DETAILS_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_HOSPITAL_DETAILS_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const hospitalAccVerification = {
    isFetching: false,
    response: null
}
export const hospitalAccountVerifyReducer = (state = hospitalAccVerification, action) => {
    switch (action.type) {
        case actionTypes.HOSPITAL_ACC_VERIFY_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.HOSPITAL_ACC_VERIFY_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.HOSPITAL_ACC_VERIFY_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const hospitalownersAccountSetting = {
    isFetching: false,
    response: null
}
export const hospitalownersAccountSettingReducer = (state = hospitalownersAccountSetting, action) => {
    switch (action.type) {
        case actionTypes.HOSPITAL_OWNER_ACCOUNT_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.HOSPITAL_OWNER_ACCOUNT_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.HOSPITAL_OWNER_ACCOUNT_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const docState = {
    isFetching: false,
    response: null
}
export const hospitalDocumentsReducer = (state = docState, action) => {
    switch (action.type) {
        case actionTypes.HOSPITAL_DOCUMENTS_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.HOSPITAL_DOCUMENTS_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.HOSPITAL_DOCUMENTS_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const countryList = {
    isFetching: false,
    response: null
}
export const countryListReducer = (state = countryList, action) => {
    switch (action.type) {
        case actionTypes.GET_COUNTRY_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_COUNTRY_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_COUNTRY_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const stateList = {
    isFetching: false,
    response: null
}
export const stateListReducer = (state = stateList, action) => {
    switch (action.type) {
        case actionTypes.GET_STATE_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_STATE_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_STATE_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const cityList = {
    isFetching: false,
    response: null
}
export const cityListReducer = (state = cityList, action) => {
    switch (action.type) {
        case actionTypes.GET_CITY_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_CITY_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_CITY_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const staffCateList = {
    isFetching: false,
    response: null
}
export const staffCategoryListReducer = (state = staffCateList, action) => {
    switch (action.type) {
        case actionTypes.STAFF_CATE_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.STAFF_CATE_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.STAFF_CATE_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const staffList = {
    isFetching: false,
    response: null
}
export const staffListReducer = (state = staffList, action) => {
    switch (action.type) {
        case actionTypes.HOSPITAL_STAFF_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.HOSPITAL_STAFF_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.HOSPITAL_STAFF_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const addStaff = {
    isFetching: false,
    response: null
}
export const addStaffReducer = (state = addStaff, action) => {
    switch (action.type) {
        case actionTypes.HOSPITAL_ADD_STAFF_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.HOSPITAL_ADD_STAFF_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.HOSPITAL_ADD_STAFF_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const editStaff = {
    isFetching: false,
    response: null
}
export const editStaffReducer = (state = editStaff, action) => {
    switch (action.type) {
        case actionTypes.HOSPITAL_EDIT_STAFF_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.HOSPITAL_EDIT_STAFF_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.HOSPITAL_EDIT_STAFF_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const deleteStaff = {
    isFetching: false,
    response: null
}
export const deleteStaffReducer = (state = deleteStaff, action) => {
    switch (action.type) {
        case actionTypes.HOSPITAL_DELETE_STAFF_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.HOSPITAL_DELETE_STAFF_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.HOSPITAL_DELETE_STAFF_FAILURE:
            return { ...state, isFetching: false, response: action.response }
        case actionTypes.EMPTY_STAFF:
            return { ...state, isFetching: false, response: deleteStaff }
        default:
            return state;
    }
}
const doctorSpec = {
    isFetching: false,
    response: null
}
export const doctorSpecListReducer = (state = doctorSpec, action) => {
    switch (action.type) {
        case actionTypes.GET_DOCTOR_SPEC_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_DOCTOR_SPEC_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_DOCTOR_SPEC_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const degreeList = {
    isFetching: false,
    response: null
}
export const degreeListReducer = (state = degreeList, action) => {
    switch (action.type) {
        case actionTypes.GET_DEGREE_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_DEGREE_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_DEGREE_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const collegeList = {
    isFetching: false,
    response: null
}
export const collegeListReducer = (state = collegeList, action) => {
    switch (action.type) {
        case actionTypes.GET_COLLEGE_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_COLLEGE_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_COLLEGE_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }
        default:
            return state;
    }
}

const docList = {
    isFetching: false,
    response: null
}

export const doctorListReducer = (state = docList, action) => {
    switch (action.type) {
        case actionTypes.GET_DOCTOR_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_DOCTOR_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_DOCTOR_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const addDoctor = {
    isFetching: false,
    response: null

}

export const addDoctorReducer = (state = addDoctor, action) => {
    switch (action.type) {
        case actionTypes.ADD_DOCTOR_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.ADD_DOCTOR_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.ADD_DOCTOR_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const EditDoctor = {
    isFetching: false,
    response: null

}

export const editDoctorReducer = (state = EditDoctor, action) => {
    switch (action.type) {
        case actionTypes.EDIT_DOCTOR_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.EDIT_DOCTOR_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.EDIT_DOCTOR_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const locationDetails = {
    isFetching: false,
    response: null
}

export const getHospitalLocationsReducer = (state = locationDetails, action) => {
    switch (action.type) {
        case actionTypes.GET_LOCATION_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_LOCATION_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_LOCATION_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}


const addLocation = {
    isFetching: false,
    response: null
}

export const addHospitalLocationReducer = (state = addLocation, action) => {
    switch (action.type) {
        case actionTypes.HOSPITAL_ADD_LOCATION_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.HOSPITAL_ADD_LOCATION_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.HOSPITAL_ADD_LOCATION_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const editLocation = {
    isFetching: false,
    response: null
}

export const editHospitalLocationReducer = (state = editLocation, action) => {
    switch (action.type) {
        case actionTypes.HOSPITAL_EDIT_LOCATION_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.HOSPITAL_EDIT_LOCATION_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.HOSPITAL_EDIT_LOCATION_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}



const addUser = {
    isFetching: false,
    response: null
}

export const addUserForAccessReducer = (state = addUser, action) => {
    switch (action.type) {
        case actionTypes.ADD_USER_FOR_ACCESS_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.ADD_USER_FOR_ACCESS_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.ADD_USER_FOR_ACCESS_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const editUser = {
    isFetching: false,
    response: null
}

export const editUserForAccessReducer = (state = editUser, action) => {
    switch (action.type) {
        case actionTypes.EDIT_USER_FOR_ACCESS_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.EDIT_USER_FOR_ACCESS_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.EDIT_USER_FOR_ACCESS_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const getRoles = {
    isFetching: false,
    response: null
}

export const getRolesListReducer = (state = getRoles, action) => {
    switch (action.type) {
        case actionTypes.GET_ROLE_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_ROLE_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_ROLE_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const getMenus = {
    isFetching: false,
    response: null
}

export const getMenusListReducer = (state = getMenus, action) => {
    switch (action.type) {
        case actionTypes.GET_MENUS_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_MENUS_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_MENUS_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const deleteUser = {
    isFetching: false,
    response: null
}

export const deleteUserForAccessReducer = (state = deleteUser, action) => {
    switch (action.type) {
        case actionTypes.DELETE_USER_FOR_ACCESS_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.DELETE_USER_FOR_ACCESS_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.DELETE_USER_FOR_ACCESS_FAILURE:
            return { ...state, isFetching: false, response: action.response };
        case actionTypes.EMPTY_STAFF_ACCESS_MANAGMENT:
            return { ...state, isFetching: false, response: deleteUser }


        default:
            return state;
    }
}

const userAccessMngList = {
    isFetching: false,
    response: null
}

export const userAccessMangListReducer = (state = userAccessMngList, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_ACCESS_MANAGEMENT_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_USER_ACCESS_MANAGEMENT_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_USER_ACCESS_MANAGEMENT_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const getAllAppointments = {
    isFetching: false,
    response: null
}

export const getAllAppointmentsReducer = (state = getAllAppointments, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_APPOINTMENTS_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_ALL_APPOINTMENTS_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_ALL_APPOINTMENTS_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const staffListByDate = {
    isFetching: false,
    response: null
}

export const getStaffListByDateReducer = (state = staffListByDate, action) => {
    switch (action.type) {
        case actionTypes.GET_STAFF_LIST_BY_DATE_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_STAFF_LIST_BY_DATE_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_STAFF_LIST_BY_DATE_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const getAppointmentCount = {
    isFetching: false,
    response: null
}

export const getAppointmentsCountReducer = (state = getAppointmentCount, action) => {
    switch (action.type) {
        case actionTypes.GET_APPOINTMENTS_COUNT_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_APPOINTMENTS_COUNT_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_APPOINTMENTS_COUNT_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const getPatientAccount = {
    isFetching: false,
    response: null
}

export const getPatientAccountProfileReducer = (state = getPatientAccount, action) => {
    switch (action.type) {
        case actionTypes.GET_PATIENT_ACCOUNT_PROFILE_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_PATIENT_ACCOUNT_PROFILE_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_PATIENT_ACCOUNT_PROFILE_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const saveAppointment = {
    isFetching: false,
    response: null
}

export const saveAppointmentDataReducer = (state = saveAppointment, action) => {
    switch (action.type) {
        case actionTypes.SAVE_APPOINTMENT_DATA_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.SAVE_APPOINTMENT_DATA_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.SAVE_APPOINTMENT_DATA_FAILURE:
            return { ...state, isFetching: false, response: action.response };
        case actionTypes.EMPTY_LIVE_APPOINTMENT:
                return {...state,  isFetching: false, response: saveAppointment}

        default:
            return state;
    }
}

const patinetInvoice = {
    isFetching: false,
    response: null
}

export const patientInvoiceReducer = (state = patinetInvoice, action) => {
    switch (action.type) {
        case actionTypes.GET_PATIENT_INVOICE_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_PATIENT_INVOICE_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_PATIENT_INVOICE_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const patinetPrescription = {
    isFetching: false,
    response: null
}

export const patientPrescriptionReducer = (state = patinetPrescription, action) => {
    switch (action.type) {
        case actionTypes.GET_PATIENT_PRESCRIPTION_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_PATIENT_PRESCRIPTION_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_PATIENT_PRESCRIPTION_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const startAppointment = {
    isFetching: false,
    response: null
}

export const startAppointmentReducer = (state = startAppointment, action) => {
    switch (action.type) {
        case actionTypes.START_APPOINTMENT_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.START_APPOINTMENT_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.START_APPOINTMENT_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const getAppointmenthis = {
    isFetching: false,
    response: null
}

export const getAppointmentHistoryReducer = (state = getAppointmenthis, action) => {
    switch (action.type) {
        case actionTypes.GET_APPOINTMENT_HISTORY_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_APPOINTMENT_HISTORY_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_APPOINTMENT_HISTORY_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const allPatientInvoiceReport = {
    isFetching: false,
    response: null
}

export const allPatientInvoiceReportReducer = (state = allPatientInvoiceReport, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_PATIENT_INVOICE_REPORT_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_ALL_PATIENT_INVOICE_REPORT_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_ALL_PATIENT_INVOICE_REPORT_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const allHospitalsByLocation = {
    isFetching: false,
    response: null
}

export const getAllHospitalWithLocationReducer = (state = allHospitalsByLocation, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_HOSPITAL_WITH_LOCATION_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_ALL_HOSPITAL_WITH_LOCATION_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_ALL_HOSPITAL_WITH_LOCATION_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

const allDoctorsByLocation = {
    isFetching: false,
    response: null
}

export const getAllDoctorByLocationReducer = (state = allDoctorsByLocation, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_DOCTOR_By_LOCATION_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_ALL_DOCTOR_By_LOCATION_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_ALL_DOCTOR_By_LOCATION_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const getPatientList = {
    isFetching: false,
    response: null
}

export const getPatientListReducer = (state = getPatientList, action) => {
    switch (action.type) {
        case actionTypes.GET_PATIENTS_LIST_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_PATIENTS_LIST_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_PATIENTS_LIST_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}
const getPatientMedicalHistory = {
    isFetching: false,
    response: null
}

export const getPatientMedicalHistoryReducer = (state = getPatientMedicalHistory, action) => {
    switch (action.type) {
        case actionTypes.GET_PATIENT_MEDICAL_HISTORY_REQUEST:
            return { ...state, isFetching: true };
        case actionTypes.GET_PATIENT_MEDICAL_HISTORY_SUCCESS:
            return { ...state, isFetching: false, response: action.response };

        case actionTypes.GET_PATIENT_MEDICAL_HISTORY_FAILURE:
            return { ...state, isFetching: false, response: action.response }

        default:
            return state;
    }
}

