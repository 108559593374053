import React, { Component } from 'react';
import * as $ from 'jquery';
import { Route, Link, withRouter } from "react-router-dom";


class UserProfileComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            genderSelected: false
        }
    }


    componentDidMount() {
        $(".blood_btn").on('click', function () {
            $(".blood_btn").removeClass('selected');
            $(this).addClass('selected');
        });

        $(".emergen_btn").on('click', function () {
            $(".emergen_btn").removeClass('selected');
            $(this).addClass('selected');
        });

        $(".ex_btn").on('click', function () {
            $(".ex_btn").removeClass('selected');
            $(this).addClass('selected');
        });

        $(".police_btn").on('click', function () {
            $(".police_btn").removeClass('selected');
            $(this).addClass('selected');
        });
        $(".disabilityBtn").on('click', function () {
            $(".disabilityBtn").removeClass('selected');
            $(this).addClass('selected');
        });
        $(".genderBtn").on('click', function () {
            $(".genderBtn").removeClass('selected');
            $(this).addClass('selected');
        });
        $(".disabilityBtn").on('click', function () {
            $(".disabilityBtn").removeClass('selected');
            $(this).addClass('selected');
        });
        $(".hivBtn").on('click', function () {
            $(".hivBtn").removeClass('selected');
            $(this).addClass('selected');
        });


        // add more insurance
        $(".addMoreInsurance").on("click", function (){
            var html = $(".insuranceSec").first().clone();
            $(html).find(".change").html("<a href='javascript:void(0)' className='btn btn-outline-danger removeInsurance'>Remove</a>");

            $(".insuranceSec").last().after(html);
        });
        $(".removeInsurance").on("click",function(){
            alert('yes');
            $(this).parents(".insuranceSec").remove();
        });

        $('#shareMoreModal .nav-tabs a').on('click', function (e) {
            e.preventDefault()
            $(this).tab('show')
        })


        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }


    render() {
        return (
            <React.Fragment>
                <div className="container bootstrap snippet">
                    <div className="row mt-md-5 mt-0">
                        <div className="col-md-2">
                            <div className="text-center">
                                <div className="col-md-12 col-12">
                                    <div className="small-12 medium-2 large-2 columns">
                                        <div className="circle_profile">
                                            <img className="profile-pic" src={require('../../../assets/images/user.png').default} alt="patient photo" />
                                        </div>
                                        <div className="profile_image">
                                            <i className="fa fa-camera upload-button"></i>
                                            <input className="file-upload" type="file" accept="image/*" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-10">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="home-tab" data-toggle="tab" href="#medicalhistory" role="tab" aria-controls="home" aria-selected="true">Medical History</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">My Account Profile</a>
                                </li>
                            </ul>
                            <div className="tab-content pt-2">
                                {/* <div className="col-md-12 col-12 text-right">
                                    <button className='btn btn-outline-primary text-right shareBtn' data-toggle="modal" data-target="#shareAccessModal">Share</button>
                                </div> */}
                                <div className="tab-pane fade show active" id="medicalhistory" role="tabpanel">
                                    <div className="row">
                                        <div className="col-md-12 text-right">
                                            <button type="button" className='btn btn-outline-primary' data-toggle="modal" data-target="#shareMoreModal">Share</button>
                                        </div>
                                    </div>
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#medicalprofile" role="tab" aria-controls="home" aria-selected="true">My Medical Profile</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#myappointment" role="tab" aria-selected="true">My Appointment </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#labResult" role="tab" aria-selected="true">Lab Result </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#permission" role="tab" aria-selected="true">Permission Sharing - Doctor Access </a>
                                        </li>
                                    </ul>

                                    <div className="tab-content pt-2">
                                        <div className="tab-pane fade show active" id="medicalprofile" role="tabpanel">
                                            <div className="row">
                                                <div className="col-md-12 text-right mb-2">
                                                    <a href="#" className='btn btn-primary mr-2' data-toggle="modal" data-target="#editMedicalprofileModal">Edit</a>
                                                    <button className='btn btn-outline-primary text-right shareBtn' data-toggle="modal" data-target="#shareAccessModal">Share</button>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/blood-type.png').default} alt="blood group" width="65"/>
                                                            </div>
                                                            <h5>Blood group</h5>
                                                            <h6>A+</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/age.png').default} alt="age" width="65"/>
                                                            </div>
                                                            <h5>Age</h5>
                                                            <h6>32 Years</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/weight.png').default} alt="weight" width="65"/>
                                                            </div>
                                                            <h5>Weight</h5>
                                                            <h6>72 kg</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
                                                            </div>
                                                            <h5>Gender</h5>
                                                            <h6>Male</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
                                                            </div>
                                                            <h5>Insurance</h5>
                                                            <h6>Medcare</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
                                                            </div>
                                                            <h5>Any Disabilities</h5>
                                                            <h6>No</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
                                                            </div>
                                                            <h5>HIV</h5>
                                                            <h6>No</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                    <div className="card">
                                                        <div className="card-body text-center">
                                                            <div className="mb-3">
                                                                <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
                                                            </div>
                                                            <h5>Medical Condition</h5>
                                                            <h6>Common</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="modal fade" id="editMedicalprofileModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Edit Medical Profile</h5>
                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <form>
                                                                <div className="row">
                                                                    <div className="col-md-12 col-12 text-right">
                                                                        <button type="submit" className='btn btn-primary mr-2'>Save</button>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <h6 className='text-primary fw-bold'>Select Insurance</h6>
                                                                        <div className='insuranceSec mb-3 d-flex justify-content-between align-items-center'>
                                                                            <div className="form-group flex-fill mb-0 mr-2">
                                                                                <select className='form-control'>
                                                                                    <option value="1">Select Insurnace</option>
                                                                                    <option value="2">Medcare Insurnace</option>
                                                                                    <option value="3">Insurnace 1</option>
                                                                                    <option value="4">Insurnace 2</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="form-group flex-fill mb-0 mr-2">
                                                                                <input type="text" className='form-control' placeholder='Enter Policy Number' />
                                                                            </div>
                                                                            <div className='change'>
                                                                                <Link to="#" className='btn btn-outline-primary addMoreInsurance'>Add More</Link>
                                                                            </div>
                                                                        </div>
                                                                        <div id="newInsurance"></div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="insurance">Age</label>
                                                                            <input type="text" className='form-control' placeholder='Enter Age' />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor="insurance">Weight</label>

                                                                            <div className="input-group">
                                                                                <input type="text" className='form-control w-50' placeholder='Enter Weight' />
                                                                                <select className='form-control w-50 input-group-text' required>
                                                                                    <option value="">kg</option>
                                                                                    <option value="">lb</option>
                                                                                </select>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <hr className='mt-2 mb-2'/>
                                                                    <div className="row">
                                                                        <div className="col-md-4 p-0">
                                                                            <div className="col-md-12 col-12 text-center">
                                                                                <div className="card">
                                                                                    <h6><strong>Blood Group</strong></h6>
                                                                                    <div className="d-flex justify-content-around btns">
                                                                                        <p className="blood_btn">A+</p>
                                                                                        <p className="blood_btn">A-</p>
                                                                                    </div>

                                                                                    <div className="d-flex justify-content-around btns">
                                                                                        <p className="blood_btn">B+</p>
                                                                                        <p className="blood_btn">B-</p>
                                                                                    </div>

                                                                                    <div className="d-flex justify-content-around btns">
                                                                                        <p className="blood_btn">AB+</p>
                                                                                        <p className="blood_btn">AB-</p>
                                                                                    </div>

                                                                                    <div className="d-flex justify-content-around btns">
                                                                                        <p className="blood_btn">O+</p>
                                                                                        <p className="blood_btn">O-</p>
                                                                                    </div>

                                                                                    <div className="d-flex justify-content-around btns">
                                                                                        <p className="blood_btn">Don't Know</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-8 p-0">
                                                                            <div className="row">
                                                                                <div className="col-md-4 col-6 text-center">
                                                                                    <div className="card customCheckboxBtn">
                                                                                        <h6><strong>Disabilities</strong></h6>
                                                                                        <div className="d-flex justify-content-around">
                                                                                            <p className="disabilityBtn">Yes</p>
                                                                                            <p className="disabilityBtn">No</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-6 text-center">
                                                                                    <div className="card customCheckboxBtn">
                                                                                        <h6><strong>Gender</strong></h6>
                                                                                        <div className="d-flex justify-content-around">
                                                                                            <p className="genderBtn">Male</p>
                                                                                            <p className="genderBtn">Female</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-4 col-6 text-center">
                                                                                    <div className="card customCheckboxBtn">
                                                                                        <h6><strong>HIV ?</strong></h6>
                                                                                        <div className="d-flex justify-content-around">
                                                                                            <p className="hivBtn">Yes</p>
                                                                                            <p className="hivBtn">No</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-12 col-12 text-center">
                                                                                    <div className="card mt-2">
                                                                                        <h6><strong>Medical Condition</strong></h6>
                                                                                        <div className="d-flex justify-content-around btns">
                                                                                            <p className="blood_btn">High Blood Presure</p>
                                                                                            <p className="blood_btn">Diabetis</p>
                                                                                        </div>
                                                                                        <div className="d-flex justify-content-around btns">
                                                                                            <p className="blood_btn">Thyroid</p>
                                                                                            <p className="blood_btn">Don't Know</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>


                                        </div>

                                        <div className="tab-pane fade" id="myappointment" role="tabpanel">
                                            <div className="col-md-12 col-12 text-right">
                                                <button className='btn btn-outline-primary text-right shareBtn mb-2' data-toggle="modal" data-target="#shareAccessModal">Share</button>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-md-row flex-column">
                                                        <div className="d-flex align-items-center flex-md-row flex-column w-100">
                                                            <div className="img_block mr-2">
                                                                <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                                            </div>
                                                            <div className="docinfo">
                                                                <Link className="text-primary" to="/hospital/liveappointmentid123">
                                                                    <strong>Dr. Wilson</strong>
                                                                </Link>
                                                                <br />
                                                                <p className="m_font_10"><strong>Appointment ID Number : </strong>1</p>
                                                                <Link className="m_font_10" to="tel:12345789">
                                                                    <i className="fa fa-mobile-alt" ></i>
                                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                                </Link>
                                                                <p className="m_font_10">
                                                                    <i className="fa fa-map-marker"></i>
                                                                    <span style={{ paddingLeft: '4px' }}>Apollo Delhi</span>
                                                                </p>
                                                                <div className='moreBtns'>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="Upcoming">Upcoming</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="View Details">View Details</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="Reschedule">Reschedule</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="Review">Review</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className='d-md-none d-block' />
                                                        <div className="d-flex justify-content-between align-items-center flex-md-column flex-row m-100">
                                                            <div className='w-100'>
                                                                <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 12-09-2022</p>
                                                                <p className="mb-1 small"><i className="far fa-clock"></i> 1:30pm</p>
                                                            </div>
                                                            <div className='w-100'>
                                                                <p className='fw-bold small'><i className="far fa-clock"></i> Slot time-online</p>
                                                                <Link to="#" className="mb-1 small text-primary"><i className="far fa-clock"></i> Google meet link</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-md-row flex-column">
                                                        <div className="d-flex align-items-center flex-md-row flex-column w-100">
                                                            <div className="img_block mr-2">
                                                                <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                                            </div>
                                                            <div className="docinfo">
                                                                <Link className="text-primary" to="/hospital/liveappointmentid123">
                                                                    <strong>Dr. Jack</strong>
                                                                </Link>
                                                                <br />
                                                                <p className="m_font_10"><strong>Appointment ID Number : </strong>1</p>
                                                                <Link className="m_font_10" to="tel:12345789">
                                                                    <i className="fa fa-mobile-alt" ></i>
                                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                                </Link>
                                                                <p className="m_font_10">
                                                                    <i className="fa fa-map-marker"></i>
                                                                    <span style={{ paddingLeft: '4px' }}>Apollo Mumbai</span>
                                                                </p>

                                                                <div className='moreBtns'>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Completed</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">View Details</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="/user/book/appointment" role="button">Book again</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="/user/book/appointment" role="button">Diagosis</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="/user/prescription" role="button">Prescription</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="/user/invoice" role="button">Invoice</Link>
                                                                    <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Review</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr className='d-md-none d-block' />
                                                        <div className="d-flex justify-content-between align-items-center flex-md-column flex-row m-100">
                                                            <div className="w-100">
                                                                <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 12-09-2022</p>
                                                                <p className="mb-1 small"><i className="far fa-clock"></i> 1:30pm</p>
                                                            </div>
                                                            <div className="w-100">
                                                                <p className='fw-bold small'><i className="far fa-clock"></i> General time-online</p>
                                                                <Link to="#" className="mb-1 small text-primary"><i className="far fa-clock"></i> Google meet link</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="labResult" role="tabpanel">
                                            <div className="col-md-12 col-12 text-right">
                                                <button className='btn btn-outline-primary text-right shareBtn' data-toggle="modal" data-target="#shareAccessModal">Share</button>
                                            </div>
                                            <div id="pat_medicalrecords" className="tab-pane fade active show">
                                                <div className="card-body text-start">
                                                    <a href="#" className="add-new-btn mb-0" data-toggle="modal" data-target="#addLabResult">Add Lab Result</a>
                                                </div>
                                                <div className="border-top card-table mb-0">
                                                    <div className="card-body p-0">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover table-center mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>Doctor Name</th>
                                                                        <th>Date</th>
                                                                        <th>Description</th>
                                                                        <th>Attachment</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td>Richard Wilson</td>
                                                                        <td>11 Nov 2022 <span className="d-block text-info">10.00 AM</span></td>
                                                                        <td>Blood Report</td>
                                                                        <td>
                                                                            <a href="javascript:void(0);" title="Download attachment" className="btn btn-primary btn-sm"> <i className="fa fa-download"></i></a>
                                                                        </td>
                                                                        <td>
                                                                            <a href="javascript:void(0);" className="btn btn-sm bg-success-light mr-2" data-toggle="modal" data-target="#addLabResult">
                                                                                <i className="fa fa-user-edit"></i>
                                                                            </a>
                                                                            <a href="javascript:void(0);" className="btn btn-sm bg-danger-light">
                                                                                <i className="far fa-trash-alt"></i>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2</td>
                                                                        <td>Vena</td>
                                                                        <td>3 Nov 2022 <span className="d-block text-info">11.00 AM</span></td>
                                                                        <td>Urine Report</td>
                                                                        <td>
                                                                            <a href="javascript:void(0);" title="Download attachment" className="btn btn-outline-primary btn-sm"> <i className="fas fa-paperclip"></i></a>
                                                                        </td>
                                                                        <td>
                                                                            <a href="javascript:void(0);" className="btn btn-sm bg-success-light mr-2" data-toggle="modal" data-target="#addLabResult">
                                                                                <i className="fa fa-user-edit"></i>
                                                                            </a>
                                                                            <a href="javascript:void(0);" className="btn btn-sm bg-danger-light">
                                                                                <i className="far fa-trash-alt"></i>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td>Tressie</td>
                                                                        <td>1 Nov 2022 <span className="d-block text-info">1.00 PM</span></td>
                                                                        <td>Sugar Report</td>
                                                                        <td>
                                                                            <a href="javascript:void(0);" title="Download attachment" className="btn btn-outline-primary btn-sm"> <i className="fas fa-paperclip"></i></a>
                                                                        </td>
                                                                        <td>
                                                                            <a href="javascript:void(0);" className="btn btn-sm bg-success-light mr-2" data-toggle="modal" data-target="#addLabResult">
                                                                                <i className="fa fa-user-edit"></i>
                                                                            </a>
                                                                            <a href="javascript:void(0);" className="btn btn-sm bg-danger-light">
                                                                                <i className="far fa-trash-alt"></i>
                                                                            </a>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="tab-pane fade" id="permission" role="tabpanel">
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <Link className='btn btn-primary mb-2' data-toggle="modal" data-target="#shareMoreModal">+ Share More</Link>
                                                </div>
                                                <div className="col-md-6">
                                                    <a className="card" data-toggle="collapse" href="#shareDoc1" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                        <span className="badge badge-success sharedBadge">Shared <br/> Access</span>
                                                        <div className="d-flex justify-content-between align-items-center p-2 flex-md-row flex-column w-100">
                                                            <div className="d-flex align-items-center flex-md-row flex-column w-100">
                                                                <div className="img_block mr-2">
                                                                    <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                                                </div>
                                                                <div className="docinfo">
                                                                    <Link className="m_font_10" to="/hospital/liveappointmentid123">
                                                                        <strong>Dr. Jack</strong>
                                                                    </Link>
                                                                    <br />
                                                                    <Link className="m_font_10" to="tel:12345789">
                                                                        <i className="fa fa-mobile-alt" ></i>
                                                                        <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                                    </Link>
                                                                    <p className="m_font_10">
                                                                        <i className="fa fa-map-marker"></i>
                                                                        <span style={{ paddingLeft: '4px' }}>Apollo Mumbai</span>
                                                                    </p>
                                                                    <a className="btn btn-primary btn-sm mr-2" href="#" role="button">Remove Sharing access</a>
                                                                    <a className="btn btn-primary btn-sm mr-2" href="#" role="button">+</a>
                                                                </div>
                                                            </div>
                                                            <hr className='d-block d-md-none'/>
                                                            <div className="w-50 m-100">
                                                                <span className="badge badge-primary mb-1">Permission Granted</span>
                                                                <div className="d-flex justify-content-between flex-row flex-md-column">
                                                                    <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> Past Appointments</p>
                                                                    <p className="mb-1 small"><i className="far fa-clock"></i> Prescriptions</p>
                                                                </div>
                                                                <div className="d-flex justify-content-between flex-row flex-md-column">
                                                                    <p className='fw-bold small'><i className="far fa-clock"></i> Lab Reports</p>
                                                                    <p className="mb-1 small"><i className="far fa-clock"></i> Medical Profile</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="collapse" id="shareDoc1">
                                                            <div className="row p-2">
                                                                <div className="col-md-6">
                                                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-column">
                                                                        <div className="d-flex align-items-center flex-column w-100">
                                                                            <div className="img_block">
                                                                                <img className='' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                                                            </div>
                                                                            <div className="docinfo text-center">
                                                                                <Link className="" to="/hospital/liveappointmentid123">
                                                                                    <strong className='text-primary p'>Dr. Jack</strong>
                                                                                </Link>
                                                                                <br />
                                                                                <p className="text-muted m_font_10"><strong>Appointment ID Number : </strong>1</p>
                                                                                <Link className="m_font_10" to="tel:12345789">
                                                                                    <i className="fa fa-mobile-alt" ></i>
                                                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                                                </Link>
                                                                                <p className="m_font_10">
                                                                                    <i className="fa fa-map-marker"></i>
                                                                                    <span style={{ paddingLeft: '4px' }}>Apollo Mumbai</span>
                                                                                </p>
                                                                                <a className="btn btn-primary btn-sm mr-2" href="#" role="button">Completed</a>

                                                                            </div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div className="w-100">
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 12-09-2022</p>
                                                                                <p className="mb-1 small"><i className="far fa-clock"></i> 1:30pm</p>
                                                                            </div>
                                                                            <p className='fw-bold small'><i className="far fa-clock"></i> General time-online</p>
                                                                            <p className="mb-1 small"><i className="far fa-clock"></i> Google meet link</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-column">
                                                                        <div className="d-flex align-items-center flex-column w-100">
                                                                            <div className="img_block">
                                                                                <img className='' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                                                            </div>
                                                                            <div className="docinfo text-center">
                                                                                <Link className="" to="/hospital/liveappointmentid123">
                                                                                    <strong className='text-primary p'>Dr. Jack</strong>
                                                                                </Link>
                                                                                <br />
                                                                                <p className="text-muted m_font_10"><strong>Appointment ID Number : </strong>1</p>
                                                                                <Link className="m_font_10" to="tel:12345789">
                                                                                    <i className="fa fa-mobile-alt" ></i>
                                                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                                                </Link>
                                                                                <p className="m_font_10">
                                                                                    <i className="fa fa-map-marker"></i>
                                                                                    <span style={{ paddingLeft: '4px' }}>Apollo Mumbai</span>
                                                                                </p>
                                                                                <a className="btn btn-primary btn-sm mr-2" href="#" role="button">Completed</a>

                                                                            </div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div className="w-100">
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 10-09-2022</p>
                                                                                <p className="mb-1 small"><i className="far fa-clock"></i> 3:30pm</p>
                                                                            </div>
                                                                            <p className='fw-bold small'><i className="far fa-clock"></i> Slot time-online</p>
                                                                            <p className="mb-1 small"><i className="far fa-clock"></i> Google meet link</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>

                                                <div className="col-md-6">
                                                    <a className="card" data-toggle="collapse" href="#shareDoc2" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                        <span className="badge badge-success sharedBadge">Shared <br/> Access</span>
                                                        <div className="d-flex justify-content-between align-items-center p-2 flex-md-row flex-column w-100">
                                                            <div className="d-flex align-items-center flex-md-row flex-column w-100">
                                                                <div className="img_block mr-2">
                                                                    <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                                                </div>
                                                                <div className="docinfo">
                                                                    <Link className="m_font_10" to="/hospital/liveappointmentid123">
                                                                        <strong>Dr. Wilson</strong>
                                                                    </Link>
                                                                    <br />
                                                                    <Link className="m_font_10" to="tel:12345789">
                                                                        <i className="fa fa-mobile-alt" ></i>
                                                                        <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                                    </Link>
                                                                    <p className="m_font_10">
                                                                        <i className="fa fa-map-marker"></i>
                                                                        <span style={{ paddingLeft: '4px' }}>Apollo Mumbai</span>
                                                                    </p>
                                                                    <a className="btn btn-primary btn-sm mr-2" href="#" role="button">Remove Sharing access</a>
                                                                    <a className="btn btn-primary btn-sm mr-2" href="#" role="button">+</a>
                                                                </div>
                                                            </div>
                                                            <hr className='d-block d-md-none'/>
                                                            <div className="w-50 m-100">
                                                                <span className="badge badge-primary mb-1">Permission Granted</span>
                                                                <div className="d-flex justify-content-between flex-row flex-md-column">
                                                                    <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> Past Appointments</p>
                                                                    <p className="mb-1 small"><i className="far fa-clock"></i> Prescriptions</p>
                                                                </div>
                                                                <div className="d-flex justify-content-between flex-row flex-md-column">
                                                                    <p className='fw-bold small'><i className="far fa-clock"></i> Lab Reports</p>
                                                                    <p className="mb-1 small"><i className="far fa-clock"></i> Medical Profile</p>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="collapse" id="shareDoc2">
                                                            <div className="row p-2">
                                                                <div className="col-md-6">
                                                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-column">
                                                                        <div className="d-flex align-items-center flex-column w-100">
                                                                            <div className="img_block mr-2">
                                                                                <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                                                            </div>
                                                                            <div className="docinfo text-center">
                                                                                <Link className="" to="/hospital/liveappointmentid123">
                                                                                    <strong className='text-primary p'>Dr. Jack</strong>
                                                                                </Link>
                                                                                <br />
                                                                                <p className="text-muted m_font_10"><strong>Appointment ID Number : </strong>1</p>
                                                                                <Link className="m_font_10" to="tel:12345789">
                                                                                    <i className="fa fa-mobile-alt" ></i>
                                                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                                                </Link>
                                                                                <p className="m_font_10">
                                                                                    <i className="fa fa-map-marker"></i>
                                                                                    <span style={{ paddingLeft: '4px' }}>Apollo Delhi</span>
                                                                                </p>
                                                                                <a className="btn btn-primary btn-sm mr-2" href="#" role="button">Completed</a>

                                                                            </div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div className="w-100">
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 11-09-2022</p>
                                                                                <p className="mb-1 small"><i className="far fa-clock"></i> 4:30pm</p>
                                                                            </div>
                                                                            <p className='fw-bold small'><i className="far fa-clock"></i> Slot time-online</p>
                                                                            <p className="mb-1 small"><i className="far fa-clock"></i> Google meet link</p>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-6">
                                                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-column">
                                                                        <div className="d-flex align-items-center flex-column w-100">
                                                                            <div className="img_block mr-2">
                                                                                <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                                                            </div>
                                                                            <div className="docinfo text-center">
                                                                                <Link className="" to="/hospital/liveappointmentid123">
                                                                                    <strong className='text-primary p'>Dr. Jack</strong>
                                                                                </Link>
                                                                                <br />
                                                                                <p className="text-muted m_font_10"><strong>Appointment ID Number : </strong>1</p>
                                                                                <Link className="m_font_10" to="tel:12345789">
                                                                                    <i className="fa fa-mobile-alt" ></i>
                                                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                                                </Link>
                                                                                <p className="m_font_10">
                                                                                    <i className="fa fa-map-marker"></i>
                                                                                    <span style={{ paddingLeft: '4px' }}>Apollo Delhi</span>
                                                                                </p>
                                                                                <a className="btn btn-primary btn-sm mr-2" href="#" role="button">Completed</a>

                                                                            </div>
                                                                        </div>
                                                                        <hr/>
                                                                        <div className="w-100">
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 9-09-2022</p>
                                                                                <p className="mb-1 small"><i className="far fa-clock"></i> 12:30pm</p>
                                                                            </div>
                                                                            <p className='fw-bold small'><i className="far fa-clock"></i> Slot time-offline</p>
                                                                            <p className="mb-1 small"><i className="far fa-clock"></i> Google meet link</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="p-1">
                                        <form>
                                            <div className="row">
                                                <div className="col-md-4 col-12 mt-3">
                                                    <input type="text" className="form-control" placeholder="Name" />
                                                </div>

                                                <div className="col-md-4 col-12 mt-3">
                                                    <input type="text" className="form-control" placeholder="Phone Number" />
                                                </div>

                                                <div className="col-md-4 col-12 mt-3">
                                                    <input type="text" className="form-control" placeholder="Email Id" />
                                                </div>

                                                <div className="col-md-4 col-6 mt-2">
                                                    <input className="form-control" list="city" placeholder="City" />
                                                    <datalist id="city">
                                                        <option value="Vadodara" />
                                                        <option value="Ahmedabad" />
                                                        <option value="Aanand" />
                                                        <option value="Surat" />
                                                        <option value="Pune" />
                                                    </datalist>
                                                </div>

                                                <div className="col-md-4 col-6 mt-2">
                                                    <input className="form-control textarea_size" list="State" placeholder="State" />
                                                    <datalist id="State">
                                                        <option value="Gujarat" />
                                                        <option value="Maharashtra" />
                                                        <option value="Uttar Pradesh" />
                                                        <option value="Rajasthan" />
                                                        <option value="Kerala" />
                                                    </datalist>
                                                </div>

                                                <div className="col-md-4 col-6 mt-2">
                                                    <input className="form-control textarea_size" list="country" placeholder="Country" />
                                                    <datalist id="country">
                                                        <option value="India" />
                                                        <option value="Canada" />
                                                        <option value="USA" />
                                                        <option value="UK" />
                                                        <option value="Russia" />
                                                    </datalist>
                                                </div>

                                                <div className="col-md-4 col-6 mt-2">
                                                    <input type="text" className="form-control textarea_size" placeholder="Pincode" />
                                                </div>

                                                <div className="col-md-4 col-6 mt-2">
                                                    <input type="date" className="form-control textarea_size" placeholder="Date Of Birth" />
                                                </div>

                                                <div className="col-md-3 col-6 mt-2 d-flex align-items-center">
                                                    <label className='mr-3 mt-2' htmlFor="gender"><strong>Gender</strong></label>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="male" name="customRadioInline" className="custom-control-input" />
                                                        <label className="custom-control-label" htmlFor="male">Male</label>
                                                    </div>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="female" name="customRadioInline" className="custom-control-input" />
                                                        <label className="custom-control-label" htmlFor="female">Female</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <h4 className="primary_text mt-2 mb-2 fw-bold mt-3">Change Password</h4>
                                            <div>
                                                <div className="row">
                                                    <div className="col-md-6 mt-2">
                                                        <input type="text" className="form-control textarea_size" placeholder="Current Password" />
                                                    </div>
                                                    <div className="col-md-6">

                                                    </div>
                                                    <div className="col-md-6 mt-2">
                                                        <input type="text" className="form-control textarea_size" placeholder="New Password" />
                                                    </div>
                                                    <div className="col-md-6 mt-2">
                                                        <input type="text" className="form-control textarea_size" placeholder="Confirm Password" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                        <div className="row">
                                            <div className="col-12 mb-5 mb-md-0 mt-2">
                                                <a className="btn btn-primary float-right" href="#" data-toggle="modal" data-target="#basicinfo_success">Save</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id="basicinfo_success" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">Successfully Saved</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row mt-2">
                                        <div className="col-md-12 text-center">
                                            <p><i className="far fa-check-circle modal_icon2 mb-2"></i></p>
                                            <h5>Your Basic Informantion Saved Successfully</h5>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="newpass_success" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h6 className="modal-title">Successfully Saved</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row mt-2">
                                        <div className="col-md-12 text-center">
                                            <p><i className="far fa-check-circle modal_icon2 mb-2"></i></p>
                                            <h5>Your New Password Saved Successfully</h5>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* sharing modal */}
                <div className="modal fade" id="shareAccessModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Share</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <input type="text" className='form-control' placeholder='Doctor name / Doctor ID / Hospital ID'/>
                                    </div>
                                    <p className='mb-2'>Doctor/Hospial with access</p>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="30" alt=""/>
                                            <p className='fw-bold'>Apollo Mumbai</p>
                                        </div>
                                        <div>
                                            <select className='form-control'>
                                                <option value="1">View</option>
                                                <option value="2">Remove</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="30" alt=""/>
                                            <p className='fw-bold'>Dr. Wilson</p>
                                        </div>
                                        <div>
                                            <select className='form-control'>
                                                <option value="1">View</option>
                                                <option value="2">Remove</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="30" alt=""/>
                                            <p className='fw-bold'>Dr. Jack</p>
                                        </div>
                                        <div>
                                            <select className='form-control'>
                                                <option value="1">View</option>
                                                <option value="2">Remove</option>
                                            </select>
                                        </div>
                                    </div>
                                    <hr />

                                    <p className='mb-2 mt-2'>General Access</p>
                                    <div className=''>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="30" alt=""/>
                                            <select className='form-control w-25'>
                                                <option defaultValue value="1">View</option>
                                                <option value="2">Remove</option>
                                            </select>
                                        </div>
                                        <div>
                                            <small>All Doctors/Hospital view your information</small>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary">Share</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* add lab result */}
                <div className="modal fade" id="addLabResult" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add Lab Results</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" className='form-control' placeholder='Doctor/Hospital name'/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="date" className='form-control' placeholder='Date'/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" className='form-control' placeholder='Description'/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="file" className='form-control' placeholder='Attechment'/>
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <button type="button" className="btn btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* share access modal */}
                <div className="modal fade" id="shareMoreModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Share More</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" href="#medicalProfileShare" role="tab" aria-controls="home" aria-selected="true">
                                            <span className="form-check">
                                                <input className="form-check-input" type="checkbox" id="medicalCheck"/>
                                                <label className="form-check-label" htmlFor="medicalCheck">
                                                    My Medical Profile
                                                </label>
                                            </span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#appointmentShare" role="tab" aria-controls="home" aria-selected="true">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="appointmentCheck"/>
                                                <label className="form-check-label" htmlFor="appointmentCheck">
                                                    My Appointment
                                                </label>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="home-tab" data-toggle="tab" href="#labShare" role="tab" aria-controls="home" aria-selected="true">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="labCheck"/>
                                                <label className="form-check-label" htmlFor="labCheck">
                                                    Lab Result
                                                </label>
                                            </div>
                                        </a>
                                    </li>
                                </ul>

                                <div className="tab-content pt-2">
                                    <div className="tab-pane fade active show" id="medicalProfileShare" role="tabpanel">
                                        <div className="row">
                                            <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <div className="mb-3"><img src="/krupaltesting/aajivan/static/media/blood-type.8c23e864.png" alt="blood group" width="65" /></div>
                                                        <h5>Blood group</h5>
                                                        <h6>A+</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <div className="mb-3"><img src="/krupaltesting/aajivan/static/media/age.7e849550.png" alt="age" width="65" /></div>
                                                        <h5>Age</h5>
                                                        <h6>32 Years</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <div className="mb-3"><img src="/krupaltesting/aajivan/static/media/weight.58823e3f.png" alt="weight" width="65" /></div>
                                                        <h5>Weight</h5>
                                                        <h6>72 kg</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <div className="mb-3"><img src="/krupaltesting/aajivan/static/media/gender.4b0c9cea.png" alt="gender" width="65" /></div>
                                                        <h5>Gender</h5>
                                                        <h6>Male</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <div className="mb-3"><img src="/krupaltesting/aajivan/static/media/gender.4b0c9cea.png" alt="gender" width="65" /></div>
                                                        <h5>Insurance</h5>
                                                        <h6>Medcare</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <div className="mb-3"><img src="/krupaltesting/aajivan/static/media/gender.4b0c9cea.png" alt="gender" width="65" /></div>
                                                        <h5>Any Disabilities</h5>
                                                        <h6>No</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <div className="mb-3"><img src="/krupaltesting/aajivan/static/media/gender.4b0c9cea.png" alt="gender" width="65" /></div>
                                                        <h5>HIV</h5>
                                                        <h6>No</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                                <div className="card">
                                                    <div className="card-body text-center">
                                                        <div className="mb-3"><img src="/krupaltesting/aajivan/static/media/gender.4b0c9cea.png" alt="gender" width="65" /></div>
                                                        <h5>Medical Condition</h5>
                                                        <h6>Common</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="tab-pane fade" id="appointmentShare" role="tabpanel">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="d-flex justify-content-between align-items-center p-2 card flex-md-row flex-column">
                                                    <div className="form-check selectAppointment">
                                                        <input className="form-check-input" type="checkbox" id="appointmentCheck1"/>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-md-row flex-column w-100" htmlFor="appointmentCheck1">
                                                        <div className="img_block mr-2"><img className="mr-2" src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt="" /></div>
                                                        <div className="docinfo">
                                                            <a className="text-primary" href="#"><strong>Dr. Wilson</strong></a><br />
                                                            <p className="m_font_10"><strong>Appointment ID Number : </strong>1</p>
                                                            <a className="m_font_10" href="tel:12345789"><i className="fa fa-mobile-alt"></i><span>12345789</span></a>
                                                            <p className="m_font_10"><i className="fa fa-map-marker"></i><span>Apollo Delhi</span></p>
                                                            <div className="moreBtns">
                                                                <a className="btn btn-primary btn-sm mr-2" role="button" data-toggle="tooltip" data-placement="bottom" href="/krupaltesting/aajivan/user/profile">Upcoming</a>
                                                                <a className="btn btn-primary btn-sm mr-2" role="button" data-toggle="tooltip" data-placement="bottom" href="/krupaltesting/aajivan/user/profile">View Details</a>
                                                                <a className="btn btn-primary btn-sm mr-2" role="button" data-toggle="tooltip" data-placement="bottom" href="/krupaltesting/aajivan/user/profile">Reschedule</a>
                                                                <a className="btn btn-primary btn-sm mr-2" role="button" data-toggle="tooltip" data-placement="bottom" href="/krupaltesting/aajivan/user/profile">Review</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="d-md-none d-block" />
                                                    <div className="d-flex justify-content-between align-items-center flex-md-column flex-row m-100">
                                                        <div className="w-100">
                                                            <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 12-09-2022</p>
                                                            <p className="mb-1 small"><i className="far fa-clock"></i> 1:30pm</p>
                                                        </div>
                                                        <div className="w-100">
                                                            <p className="fw-bold small"><i className="far fa-clock"></i> Slot time-online</p>
                                                            <a className="mb-1 small text-primary" href="/krupaltesting/aajivan/user/profile"><i className="far fa-clock"></i> Google meet link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="d-flex justify-content-between align-items-center p-2 card flex-md-row flex-column">
                                                <div className="form-check selectAppointment">
                                                        <input className="form-check-input" type="checkbox" id="appointmentCheck1"/>
                                                    </div>
                                                    <div className="d-flex align-items-center flex-md-row flex-column w-100">
                                                        <div className="img_block mr-2"><img className="mr-2" src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt="" /></div>
                                                        <div className="docinfo">
                                                            <a className="text-primary" href="#"><strong>Dr. Jack</strong></a><br />
                                                            <p className="m_font_10"><strong>Appointment ID Number : </strong>1</p>
                                                            <a className="m_font_10" href="tel:12345789"><i className="fa fa-mobile-alt"></i><span >12345789</span></a>
                                                            <p className="m_font_10"><i className="fa fa-map-marker"></i><span>Apollo Mumbai</span></p>
                                                            <div className="moreBtns">
                                                                <a className="btn btn-primary btn-sm mr-2" role="button" href="/krupaltesting/aajivan/user/profile">Completed</a>
                                                                <a className="btn btn-primary btn-sm mr-2" role="button" href="/krupaltesting/aajivan/user/profile">View Details</a>
                                                                <a className="btn btn-primary btn-sm mr-2" role="button" href="/krupaltesting/aajivan/user/book/appointment">Book again</a>
                                                                <a className="btn btn-primary btn-sm mr-2" role="button" href="/krupaltesting/aajivan/user/book/appointment">Diagosis</a>
                                                                <a className="btn btn-primary btn-sm mr-2" role="button" href="/krupaltesting/aajivan/user/prescription">Prescription</a>
                                                                <a className="btn btn-primary btn-sm mr-2" role="button" href="/krupaltesting/aajivan/user/invoice">Invoice</a><a className="btn btn-primary btn-sm mr-2" role="button" href="/krupaltesting/aajivan/user/profile">Review</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr className="d-md-none d-block" />
                                                    <div className="d-flex justify-content-between align-items-center flex-md-column flex-row m-100">
                                                        <div className="w-100">
                                                            <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 12-09-2022</p>
                                                            <p className="mb-1 small"><i className="far fa-clock"></i> 1:30pm</p>
                                                        </div>
                                                        <div className="w-100">
                                                            <p className="fw-bold small"><i className="far fa-clock"></i> General time-online</p>
                                                            <a className="mb-1 small text-primary" href="/krupaltesting/aajivan/user/profile"><i className="far fa-clock"></i> Google meet link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="labShare" role="tabpanel">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-center mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Select</th>
                                                        <th>#</th>
                                                        <th>Doctor Name</th>
                                                        <th>Date</th>
                                                        <th>Description</th>
                                                        <th>Attachment</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1"/>
                                                            </div>
                                                        </td>
                                                        <td>1</td>
                                                        <td>Richard Wilson</td>
                                                        <td>11 Nov 2022 <span className="d-block text-info">10.00 AM</span></td>
                                                        <td>Blood Report</td>
                                                        <td>
                                                            <a href="javascript:void(0);" title="Download attachment" className="btn btn-primary btn-sm"> <i className="fa fa-download"></i></a>
                                                        </td>
                                                        <td>
                                                            <a href="javascript:void(0);" className="btn btn-sm bg-success-light mr-2" data-toggle="modal" data-target="#addLabResult">
                                                                <i className="fa fa-user-edit"></i>
                                                            </a>
                                                            <a href="javascript:void(0);" className="btn btn-sm bg-danger-light">
                                                                <i className="far fa-trash-alt"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck2"/>
                                                            </div>
                                                        </td>
                                                        <td>2</td>
                                                        <td>Vena</td>
                                                        <td>3 Nov 2022 <span className="d-block text-info">11.00 AM</span></td>
                                                        <td>Urine Report</td>
                                                        <td>
                                                            <a href="javascript:void(0);" title="Download attachment" className="btn btn-outline-primary btn-sm"> <i className="fas fa-paperclip"></i></a>
                                                        </td>
                                                        <td>
                                                            <a href="javascript:void(0);" className="btn btn-sm bg-success-light mr-2" data-toggle="modal" data-target="#addLabResult">
                                                                <i className="fa fa-user-edit"></i>
                                                            </a>
                                                            <a href="javascript:void(0);" className="btn btn-sm bg-danger-light">
                                                                <i className="far fa-trash-alt"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck3"/>
                                                            </div>
                                                        </td>
                                                        <td>3</td>
                                                        <td>Tressie</td>
                                                        <td>1 Nov 2022 <span className="d-block text-info">1.00 PM</span></td>
                                                        <td>Sugar Report</td>
                                                        <td>
                                                            <a href="javascript:void(0);" title="Download attachment" className="btn btn-outline-primary btn-sm"> <i className="fas fa-paperclip"></i></a>
                                                        </td>
                                                        <td>
                                                            <a href="javascript:void(0);" className="btn btn-sm bg-success-light mr-2" data-toggle="modal" data-target="#addLabResult">
                                                                <i className="fa fa-user-edit"></i>
                                                            </a>
                                                            <a href="javascript:void(0);" className="btn btn-sm bg-danger-light">
                                                                <i className="far fa-trash-alt"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Share</button>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}
export default UserProfileComponent;