import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  getCountryCodes,
  ForgotPassword,
  VerifyOTPForPswd,
} from "../../../redux/Main/actions";
import { connect } from "react-redux";
import UserResetpassword from "./UserResetpassword";
import { Autocomplete, TextField, Box } from '@mui/material';

class UserForgotpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dial_code: null,
      mobile: "",
      password: null,
      remember: false,
      isOtp: false,
      isOtpSent: false,
      LoginWithOtp: false,
    };
  }

  componentDidMount() {
    this.props.getCountryCodes({});
  }

  onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const stateCopy = { ...this.state, [name]: value };
    if (name === "mobile") {
      stateCopy["mobile_error"] = false;
      stateCopy["mobile"] = value;
    }
    if (name === "otp") {
      stateCopy["otp_error"] = false;
      stateCopy["otp"] = value;
    }

    this.setState(stateCopy);
  };

  sendOTPHandler = () => {
    const { dial_code = null, mobile = null, isOtp = false } = this.state || {};

    let errors = {};
    let errCount = 0;

    if (!dial_code) {
      errors["dial_code_error"] = true;
      errCount++;
    }
    if (!mobile) {
      errors["mobile_error"] = true;
      errCount++;
    }

    if (errCount > 0) {
      this.setState({
        ...this.state,
        ...errors,
      });

      return;
    }

    const params = {
      dial_code: dial_code,
      mobile: mobile,
    };
    this.props.ForgotPassword(params);
  };

  veryfyOTPHandler = () => {
    const { dial_code = null, mobile = null, otp = null } = this.state || {};

    if (!otp) {
      this.setState({ ...this.state, otp_error: true });
    }

    const params = {
      dial_code: dial_code,
      mobile: mobile,
      otp: otp,
    };

    this.props.veryfyOTP(params);
  };

  render() {
    console.log("Props", this.props);
    if (this.props.otpVerified) {
      return <UserResetpassword {...this.state} />;
    } else {
      return (
        <section className="login_area pt-md-5 pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6 d-flex align-items-end justify-content-center order-md-1 order-2">
                <img
                  src={
                    require("../../../assets/images/home/doctors.png").default
                  }
                  alt="1"
                />
              </div>

              <div className="col-md-6 order-md-2 order-1">
                <div id="msform" className="login">
                  <h4 className="mb-2 h2 fw-bold">Forgot Password</h4>
                  <p className="mb-4">
                    Enter Your Phone Number and we will send you an OTP
                  </p>
                  <form className="w-100 m-auto">
                    <label htmlFor="">Mobile Number</label>
                    <div className="input-group">

                      <Autocomplete
                        id="country-select-demo"
                        sx={{ width: 200 }}
                        options={this.props?.countriesList}
                        autoHighlight
                        getOptionLabel={(option) => option.iso3}
                        onChange={(event, value) => {
                          if (value) {
                            const stateCopy = { ...this.state }
                            stateCopy['dial_code_error'] = false;
                            stateCopy['dial_code'] = value.phonecode;

                            this.setState(stateCopy)

                          }
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            +{option.phonecode} ({option.iso3})
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose a country"
                            size='small'
                            error={this.state.dial_code_error}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      {/* <select className='form-control w-25 input-group-text' required name='dial_code' value={state.dial_code} onChange={onChangeHandler}>
                      <option value="0">Select Country</option>
                      {countriesList.map((item) => (
                        <option value={item.phonecode} key={item.id}>{`+ ${item.phonecode} (${item.iso3})`}</option>
                      ))}
                    </select> */}

                      <TextField
                        placeholder='Mobile Number'
                        value={this.state.mobile}
                        name='mobile'
                        sx={{ width: 420 }}

                        size='small'
                        error={this.state.mobile_error}
                        onChange={this.onChangeHandler}

                      />
                    </div>
                    {/* <div className="input-group">
                      <select
                        className="form-control w-25 input-group-text"
                        name="dial_code"
                        value={this.state.dial_code}
                        onChange={this.onChangeHandler}
                      >
                        <option value="0">Select Country</option>
                        {this.props?.countriesList.map((item) => (
                          <option
                            value={item.phonecode}
                            key={item.id}
                          >{`+ ${item.phonecode} (${item.iso3})`}</option>
                        ))}
                      </select>
                      <input
                        type="text"
                        className="form-control w-75"
                        placeholder="Mobile Number"
                        value={this.state.mobile}
                        name="mobile"
                        required
                        onChange={this.onChangeHandler}
                      />
                    </div> */}
                    {/* <div className="d-flex flex-row w-100">
                      {this.state.dial_code_error && (
                        <div className="invalid-feedback small d-block">
                          Please select Country.
                        </div>
                      )}
                      {this.state.mobile_error && (
                        <div className="invalid-feedback small d-block">
                          Please enter mobile.
                        </div>
                      )}
                    </div> */}

                    {!this.props.OTP && (
                      <button
                        type="button"
                        className="btn btn-primary w-100 mt-2"
                        onClick={this.sendOTPHandler}
                      >
                        Send OTP
                      </button>
                    )}

                    {this.props.OTP && (
                      <>
                        <div class="form-group mt-2">
                          <label htmlFor="">OTP</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter OTP"
                            aria-describedby="helpId"
                            name="otp"
                            value={this.state.otp}
                            onChange={this.onChangeHandler}
                          />
                          {this.state.otp_error && (
                            <div className="invalid-feedback small d-block">
                              please enter valid otp.
                            </div>
                          )}
                        </div>
                        <button
                          type="button"
                          // to="/userresetpassword"
                          className="btn btn-primary w-100 mt-2"
                          onClick={this.veryfyOTPHandler}
                        >
                          Verify OTP
                        </button>
                      </>
                    )}
                  </form>
                  <p className="text-muted mt-3">
                    Don't have a account?{" "}
                    <Link to="./usersignup" className="customAnchor">
                      {" "}
                      Sign up
                    </Link>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}
const mapDispatchToProps = (dispatch) => ({
  ForgotPassword: (params) => dispatch(ForgotPassword(params)),
  getCountryCodes: (params) => dispatch(getCountryCodes(params)),
  veryfyOTP: (params) => dispatch(VerifyOTPForPswd(params)),
});

const mapStateToProps = (state) => {
  return {
    countriesList:
      state.mainReducer?.getCountryCodesReducer?.response?.data || [],
    authtoken:
      state.mainReducer?.userRegistrationReducer?.response?.data?.authtoken ||
      null,
    OTP: state.mainReducer?.ForgotPasswordReducer?.response?.otp || null,
    otpVerified:
      state.mainReducer?.VerifyOTPForPswdReducer?.response?.status === 200 ||
      null,
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserForgotpassword)
);
