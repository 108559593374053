
import React, { Component } from 'react';
import { TermConditionList } from '../../../mock/TermConditionList';


class HowItWorks extends Component{
  
    constructor(props){
        super(props);
        this.state = {
			termConditions:TermConditionList
         };
    }

	

	componentDidMount(){
    //     setTimeout(() => {
	// 	this.setState({invoiceDTO:AllInvoiceDTOs});
	//    }, 10);



    }


    render() {

        return (
           <React.Fragment>
      <section class="how_it_work_section">
    <div class="container">
    	<h4 class="section_title text-center">How It Works:</h4>
        <div class="row mt-5">
			<div class="col-md-8 mt-3">
				<div class='timeline'>
	    			<div class='start'></div>
	  			</div>

				<div class='entries row'>           
                    <div class='entry'>
                        <div class="w-100">
                            <div class="howto_icon">
                              <div class='dot find'></div>
                            </div>
                            <div class="howto_text">
                                <div class='label pl-0'>
                                    <div class='time pl-0'>
                                        Find Doctor. 
                                    </div>
                                    <div class='detail'>
                                        Search for Doctor in your area.
                                    </div>
                                    <div class="mt-3">
                                        <a href="blog.php">Learn More</a>                               
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class='entry'>
                        <div class="w-100">
                            <div class="howto_icon">
                                <div class='dot appoint'></div>
                            </div>
                        
                            <div class="howto_text">
                                <div class='label'>
                                    <div class='time pl-0'>
                                        Schedule An Appointment.
                                    </div>
                                    <div class='detail'>
                                        Conveniently schedule your appointment online. 
                                    </div>
                                    <div class="mt-3">
                                        <a href="blog.php">Learn More</a>                               
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class='entry'>
                        <div class="w-100">
                            <div class="howto_icon">
                                <div class='dot track'></div>
                            </div>
                            <div class="howto_text">
                                <div class='label'>
                                    <div class='time pl-0'>
                                        Track your waiting number in Real-time.
                                    </div>
                                    <div class='detail'>
                                        Check your waiting number in real-time to avoid long waiting.
                                    </div>
                                    <div class="mt-3">
                                        <a href="blog.php">Learn More</a>                               
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
			</div>

			<div class="col-md-4 mt-md-0 mt-4">
                <img 
                src={require('../../../assets/images/view.png').default}
                alt="view" />                  
            </div>
		</div>
    </div>


</section>


<section class="faq">
	<div class="container">
		<div class="row">
			<div class="col-md-12 mb-5">
				<h4 class="section_title text-center">FAQ</h4>				
			</div>
			<div class="col-md-7">
				<div id="accordion" class="p-2 bhoechie-tab-content active">
                        <div class="card">
                            <article class="card-group-item">
                                <header class="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse22" aria-expanded="true" class="">
                                        <i class="icon-action1 fa fa-chevron-down float-right primary_text"></i>
                                        <h6 class="title">What is Aajivan?</h6>
                                    </a>
                                </header>
                                <div class="filter-content collapse in" id="collapse22" >
                                    <div class="card-body">
                                        <p>Aajivan allows patients to schedule an appointment with a local participating doctor, online and in real time. We make it easy for patients to check their real time waiting number to avoid long lines.</p>            
                                    </div>
                                </div>
                            </article>

                            <article class="card-group-item">
                                <header class="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse33" aria-expanded="true" class="">
                                        <i class="icon-action2 fa fa-chevron-down float-right primary_text"></i>
                                        <h6 class="title">Is Aajian free?</h6>
                                    </a>
                                </header>
                                <div class="filter-content collapse" id="collapse33" >
                                    <div class="card-body">
                                        <p>Aajivan is free for all users.</p>            
                                    </div>
                                </div>
                            </article>
                            <article class="card-group-item">
                                <header class="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse44" aria-expanded="false" class="collapsed">
                                        <i class="icon-action3 fa fa-chevron-down float-right primary_text"></i>
                                        <h6 class="title">What if Aajivan is not in my city?</h6>
                                    </a>
                                </header>
                                <div class="filter-content collapse" id="collapse44" >
                                    <div class="card-body">
                                        <p>We are expanding to new cities. If you want to help being Aajivan to your city, contact us.</p>
                                    </div>
                                </div>
                            </article>                        
                            <article class="card-group-item">
                                <header class="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse55" aria-expanded="false" class="collapsed">
                                        <i class="icon-action4 fa fa-chevron-down float-right primary_text"></i>
                                        <h6 class="title">Will it work for my prectice?</h6>
                                    </a>
                                </header>
                                <div class="filter-content collapse" id="collapse55" >
                                    <div class="card-body">
                                        <p>Aajivan is made completely customizable to fix any prectice.</p>
                                    </div>
                                </div>
                            </article>                        
                            <article class="card-group-item">
                                <header class="card-header">
                                    <a href="#" data-toggle="collapse" data-target="#collapse66" aria-expanded="false" class="collapsed">
                                        <i class="icon-action5 fa fa-chevron-down float-right primary_text"></i>
                                        <h6 class="title">Is Aajivan HIPPA compliant?</h6>
                                    </a>
                                </header>
                                <div class="filter-content collapse" id="collapse66" >
                                    <div class="card-body">
                                        <p>Yes, Aajivan is HIPPA compliant. We do not store any information.</p>
                                    </div>
                                </div>
                            </article>                                                                           

                        </div>
                    </div>
			</div>
			<div class="col-md-5 text-right">
				<img 
                src={require('../../../assets/images/how1.png').default}
                 alt="" width="400" />
			</div>

		</div>
		
	</div>
</section>
           </React.Fragment>
    
        )
    }
}
export default HowItWorks;