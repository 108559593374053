import React, { useState } from 'react';
import { Link, withRouter, useHistory, NavLink } from "react-router-dom";
import '../../assets/style.css';
// import logImage from "../../assets/images/aajivan3.png";
import { UserAuthService } from '../../service/UserAuthService';
import { UserContext, UserDispatchContext } from '../../state/UserState';
// import SubHeaderComponent from './SubHeader';
import HospitalSubHeaderComponent from './HospitalSubHeader';


const HospitalLoggedInComponent = () => {

    const history = useHistory();
    const userDetails = React.useContext(UserContext);
    const setUserDetails = React.useContext(UserDispatchContext);
    const [loggedIn, setLoggedIn] = useState(true);

    const logout = (e) => {
        e.preventDefault();
        UserAuthService.removeToken();
        UserAuthService.removeRole();
        setLoggedIn(false);
        setUserDetails({
            ...userDetails,
            isLogin: false,
            token: UserAuthService.getToken()
        });
        history.push('/');
    }


    return (
        <React.Fragment>
            <nav className="navbar navbar-expand-lg navbar-light nav_bg">
                <div className="container">
                    <div className="row w-100">
                        <div className="col-md-4 col-5">
                            <Link to="/hospital/dashboard" className="navbar-brand" >Aajivan</Link>
                        </div>

                        <div className="col-md-8 col-7 text-right pt-2 p-0">
                            <div className="dropdown_profile2">
                                <Link to="/hospital/profile" styles="color: #fff;fontSize: 12px" className="my-2 my-sm-0 mr-0 mr-md-2"> Hospital Account</Link>
                                {/* <a href="http://krupaal.com/aajivandone/hospital/profile.php" style="color: #fff;font-size: 12px" className="my-2 my-sm-0 mr-0 mr-md-2">Hospital Account</a> */}
                                <img src={require('../../assets/images/profile_circle-1.png').default} width="40" alt="" className="dropdown-toggle" data-toggle="dropdown" />
                                <div className="dropdown_profile-content">
                                    <Link to="/hospital/profile" className="my-2 my-sm-0 mr-0 mr-md-2"><i className="fas fa-user-circle"></i> Profile</Link>
                                    <Link to="/hospital/help" className="my-2 my-sm-0 mr-0 mr-md-2"><i className="fas fa-user-circle"></i> Help</Link>
                                    <NavLink to="/Logout" className="my-2 my-sm-0 mr-0 mr-md-2"><i className="fas fa-sign-out-alt"></i> Logout</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="d-flex justify-content-between subheader" styles="padding-top: 65px;">
                <HospitalSubHeaderComponent />
            </div>

            {
                loggedIn ? (
                    <div className="mobile_header d-md-none d-block">
                        <div className="d-flex justify-content-between">
                            <HospitalSubHeaderComponent screen={'mobile'} />
                        </div>
                    </div>
                ) : ''
            }


        </React.Fragment>
    );
}
export default withRouter(HospitalLoggedInComponent);