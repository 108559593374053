import React, { Component } from 'react';
import { Route,  Link, withRouter} from "react-router-dom";

class UserDashBoardResultComponent extends Component{

    constructor(props) {
		super(props);
		this.state = {
            searchFilter:{
                location:'',
                insurance:'',
                specialization:''
            },
            showAppointment:false
            };

      }

      openGoogemap =(e, doctor) =>{
        if(doctor != null && doctor !=undefined){
            window.open("https://maps.google.com/?q="+doctor.lattitude+","+doctor.longitude);
        }
    }

    render() {
        return (
                <div className="col-md-6">
                    <Link to='/user/doctorProfile'>
                        <div className="appointmentList">
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <div className="img_block text-center">
                                        <img src={require('../../assets/images/doc1.png').default} alt="doctor profile" />
                                    </div>
                                    <div className="docinfo">
                                        <p className="title_name mb-md-0 mb-2"><strong>{this.props.doctor.name}</strong></p>
                                        <p className="sub_font mb-md-0 mb-1"><i className="fas fa-graduation-cap"></i>BDS, MDS - Oral & Maxillofacial Surgery</p>
                                        <p className="sub_font mb-md-0 mb-1"><i className="fas fa-graduation-cap"></i>Dentist</p>
                                        <p className="sub_font mb-md-0 mb-1"><i className="fas fa-map-marker mr-1"></i> {this.props.doctor.hospital.name}</p>
                                        <p className="sub_font mb-md-0 mb-1"><i className="fas fa-mobile-alt mr-2"></i>{this.props.doctor.phone}</p>
                                        <p className="sub_font mb-md-0 mb-1"><i className="fas fa-thumbs-up"></i> 87% | 21 Feedbacks</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <Link className='btn btn-primary' to="/user/book/appointment">Book Appointment</Link>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>


        )
    }
}
export default withRouter(UserDashBoardResultComponent);