import InsuranceList from "./dropdown/InsuranceList";
import LocationList from "./dropdown/LocationList";
import SpecialityList from "./dropdown/SpecialityList";

const UserAppointment=
    {
        id:12334,
        estimatedTime:'11:15 am',
        scheduleTime:'10:20-11:45',
        avgTime:'5:30 min',
        patientName:'Mukesh Kumar',
        status:'Confirmed',
        doctor:{
            id:4,
            name:"Dr. Wilson",
            address:"Apollo Delhi",
            phone:1234567890,
            lattitude:'28.5245°N',
            longitude:'77.1855°E',
            location:LocationList[1],
            specialization:SpecialityList[1],
            insurance:InsuranceList[1],
            imageUrl:''
        },
        waitingNo:23,
        appointmentDate:'18/03/2022'
    };

export {UserAppointment};