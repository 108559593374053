
import React, { Component } from 'react';
import { TermConditionList } from '../../../mock/TermConditionList';


class AboutUs extends Component{
  
    constructor(props){
        super(props);
        this.state = {
			termConditions:TermConditionList
         };
    }

	

	componentDidMount(){
    //     setTimeout(() => {
	// 	this.setState({invoiceDTO:AllInvoiceDTOs});
	//    }, 10);



    }


    render() {

        return (
           <React.Fragment>
      <section className="mission">
	<div className="container-fluid">
        <div className="row">                 
            <div className="col-md-12 col-sm-6 col-xs-12 p-0">
                <div className="mission_text">
					<h3>Our Mission</h3>
					<h4>Userfriendly Appointment Booking </h4>
					<p>	Our main mission is to make appointment booking system user friendly and easy for all who wants to book Appointment. Through our website you can easy book your appointment for any hospital by searching your appropriate hospitals in your area or for the particular hospital.</p>

					<p>	Another mission is to save a time of user besause in normal appointment booking user don't know that how much time he/she have to wait for thair turn but if user use your appointment booking website, we provide a waiting number for each user they get notified every hour that how much he/she left for his turn. So there is no west of time at all. </p>

					<p>	We have another main Mission for hospital. Hospital member i.s. doctor or receptionist can also user this system for daily book appointment of thair paients. So no more paperwork booking. make your hospital digital. For this You just have to register your hospital on our website.</p>
                </div>	
            </div>
        </div>
    </div>
</section>

<section className="aboutus">
	<div className="container">
        <div className="row">                 
            <div className="col-md-12 col-sm-6 col-xs-12 p-0">
                <div className="aboutus_text">
					<h3>About Us</h3>
					<h4>Your Home for Health</h4>
					<p>	For millions of people, Aajivan is the trusted and familiar home where they know they’ll find a Hospitals. It connects them with everything they need to take good care of themselves and their family - assessing health issues, finding the right doctor and learning new ways to live healthier.</p>
					<div className="row">
    						<div className="col-md-4 padd_50">
    							<div>
    								<img 
                                     src={require('../../../assets/images/about/about2.png').default}
                                    alt="about"/>
    								<p> 
    									No time resriction. Use any time. 
    								 </p>	
    							</div>	
    						</div>
    						<div className="col-md-4 padd_50">
    							<div>
    								<img 
                                    src={require('../../../assets/images/about/about3.png').default}
                                     alt="about"/>
    								<p> 
    									It's too easy To Book an appointment online.
    								 </p>
    							</div>
    						</div>
    						<div className="col-md-4 padd_50">
    							<div>
    								<img 
                                     src={require('../../../assets/images/about/about1.png').default}
                                      alt="about" />
    								<p> 
    									Make your Hospital Digital. No paper work.
    								 </p>
    							</div>
    						</div>

						<div className="col-md-12">
							<p>	We LOVE what we do and we do our BEST to support our clients all the way. 
                                
                                We are constantly improving our services and are proud to say that most of our new 
                                Aajivan users come through referrals from existing clients. We look forward to having you, 
                                as one of our new users in the future!</p>							
						</div>						
					</div>	
            	</div>
       		</div>
    	</div>
    </div>
</section>


{/* <!-- Team --> */}
<section id="team" className="pb-5">
    <div className="container-fluid">
        <h3>Our Team</h3>
        <div className="row">
            {/* <!-- Team member 1 --> */}
            <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="image-flip">
                    <div className="mainflip">
                        <div className="frontside">
                            <div className="card">
                                <div className="card-body text-center">
                                    <p><img className=" img-fluid" 
                                    src={require('../../../assets/images/about/team1.png').default}
                                     alt="card image" /></p>
                                    <h4 className="card-title mt-4">Sunlimetech</h4>
                                    <p className="card-text">Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className="backside">
                            <div className="card">
                                <div className="card-body text-center mt-4">
                                    <h4 className="card-title">Sunlimetech</h4>
                                    <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- ./Team member 1-->
            <!-- Team member 2--> */}
            <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="image-flip">
                    <div className="mainflip">
                        <div className="frontside">
                            <div className="card">
                                <div className="card-body text-center">
                                    <p><img className=" img-fluid" 
                                    src={require('../../../assets/images/about/team1.png').default}
                                   alt="card image" /></p>
                                    <h4 className="card-title mt-4">Sunlimetech</h4>
                                    <p className="card-text">Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className="backside">
                            <div className="card">
                                <div className="card-body text-center mt-4">
                                    <h4 className="card-title">Sunlimetech</h4>
                                    <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- ./Team member 2-->
            <!-- Team member 3--> */}
            <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="image-flip">
                    <div className="mainflip">
                        <div className="frontside">
                            <div className="card">
                                <div className="card-body text-center">
                                    <p><img className=" img-fluid" 
                                     src={require('../../../assets/images/about/team1.png').default}
                                     alt="card image" /></p>
                                    <h4 className="card-title mt-4">Sunlimetech</h4>
                                    <p className="card-text">Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className="backside">
                            <div className="card">
                                <div className="card-body text-center mt-4">
                                    <h4 className="card-title">Sunlimetech</h4>
                                    <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- ./Team member 3-->
            <!-- Team member 4--> */}
            <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="image-flip">
                    <div className="mainflip">
                        <div className="frontside">
                            <div className="card">
                                <div className="card-body text-center">
                                    <p><img className=" img-fluid" 
                                     src={require('../../../assets/images/about/team1.png').default}
                                     alt="card image" /></p>
                                    <h4 className="card-title mt-4">Sunlimetech</h4>
                                    <p className="card-text">Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className="backside">
                            <div className="card">
                                <div className="card-body text-center mt-4">
                                    <h4 className="card-title">Sunlimetech</h4>
                                    <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- ./Team member 4-->
            <!-- Team member 5--> */}
            <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="image-flip">
                    <div className="mainflip">
                        <div className="frontside">
                            <div className="card">
                                <div className="card-body text-center">
                                    <p><img className=" img-fluid" 
                                     src={require('../../../assets/images/about/team1.png').default}
                                    alt="card image" /></p>
                                    <h4 className="card-title mt-4">Sunlimetech</h4>
                                    <p className="card-text">Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className="backside">
                            <div className="card">
                                <div className="card-body text-center mt-4">
                                    <h4 className="card-title">Sunlimetech</h4>
                                    <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- ./Team member 5-->
            <!-- Team member 6--> */}
            <div className="col-xs-12 col-sm-6 col-md-4">
                <div className="image-flip">
                    <div className="mainflip">
                        <div className="frontside">
                            <div className="card">
                                <div className="card-body text-center">
                                    <p><img className=" img-fluid" 
                                     src={require('../../../assets/images/about/team1.png').default}
                                     alt="card image" /></p>
                                    <h4 className="card-title mt-4">Sunlimetech</h4>
                                    <p className="card-text">Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className="backside">
                            <div className="card">
                                <div className="card-body text-center mt-4">
                                    <h4 className="card-title">Sunlimetech</h4>
                                    <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                    quis nostrud exercitation ullamco</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- ./Team member 6--> */}

        </div>
    </div>
</section>
           </React.Fragment>
    
        )
    }
}
export default AboutUs;