import { Tabs, Tab, Box } from '@mui/material';
import React, { Component } from "react";
import { Link } from "react-router-dom";
import '../../assets/style.css';
import { tabsClasses } from '@mui/material/Tabs';
import { useState } from 'react';
import { connect } from 'react-redux';

import { AdminTabChange } from 'src/redux/Main/actions';
import { useEffect } from 'react';


const AdminSubHeader = (props) => {

  const [links, setLinks] = useState(
    [{
      id: 1,
      name: "Dashboard - Iframe",
      to: "/management/dashboard",
      className: "fas fa-desktop"
    },
    {
      id: 2,
      name: "Dashboard - Google analytics",
      to: "/management/dashboard",
      className: "fas fa-desktop"
    },
    {
      id: 3,
      name: "All Hospitals",
      to: "/management/allhospitals",
      className: "fas fa-address-book"
    },
    {
      id: 4,
      name: "All Appointments",
      to: "/management/allappointments",
      className: "fas fa-calendar-check"
    },
    {
      id: 5,
      name: "All Patients",
      to: "/management/allpatients",
      className: "fas fa-search"
    },
    {
      id: 6,
      name: "All Users",
      to: "/management/allusers",
      className: "fas fa-question"
    },
    {
      id: 7,
      name: "All Invoices",
      to: "/management/allinvoices",
      className: "fas fa-question"
    },
    {
      id: 8,
      name: "All Prescriptions",
      to: "/management/allprescriptions",
      className: "fas fa-question"
    },
    {
      id: 9,
      name: "Ongoing Subscriptions",
      to: "/management/allsubscriptions",
      className: "fas fa-question"
    },
    {
      id: 10,
      name: "All plans/Create plan",
      to: "/management/addnewplan",
      className: "fas fa-question"
    },
    {
      id: 11,
      name: "All Doctors",
      to: "/management/alldoctors",
      className: "fas fa-question"
    },
    {
      id: 12,
      name: "All Staff",
      to: "/management/allstaff",
      className: "fas fa-question"
    },
    {
      id: 13,
      name: "Admin - User Management",
      to: "/management/usermanagement",
      className: "fas fa-question"
    },
    {
      id: 14,
      name: "All Specializations",
      to: "/management/allspecializations",
      className: "fas fa-question"
    },
    {
      id: 15,
      name: "All Insurances",
      to: "/management/allinsurances",
      className: "fas fa-question"
    }
    ])

  const [activeTab, setActiveTap] = useState(0)



  useEffect(() => {
    props.AdminTabChange(activeTab)
  }, [activeTab])

  const handleChange = (event, newValue) => {
    setActiveTap(newValue);
  };


  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          maxWidth:'100%'
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          aria-label="visible arrows tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {links.map((item, idx) => (
            <Tab label={item.name} value={idx} />

          ))}

        </Tabs>

      </Box>
    </>
  )



}


const mapDispatchToProps = dispatch => ({
  AdminTabChange: (params) => dispatch(AdminTabChange(params)),

})

const mapStateToProps = state => {
  return {
    staffList:
      state.mainReducer?.staffListReducer?.response?.data || [],
    staffCateList:
      state.mainReducer?.staffCategoryListReducer?.response?.data || [],
    addStaffStatus: state.mainReducer?.addStaffReducer?.response?.status,
    editStaffStatus: state.mainReducer?.editStaffReducer?.response?.status,
    deleteStaffStatus: state.mainReducer?.deleteStaffReducer?.response?.status,

  }

}


export default connect(mapStateToProps, mapDispatchToProps)(AdminSubHeader)


// class AdminSubHeader extends Component{
//     state = {
//         links: [
//           {
//             id: 1,
//             name: "Dashboard - Iframe",
//             to: "/management/dashboard",
//             className: "fas fa-desktop"
//           },
//           {
//             id: 2,
//             name: "Dashboard - Google analytics",
//             to: "/management/dashboard",
//             className: "fas fa-desktop"
//           },
//           {
//             id: 3,
//             name: "All Hospitals",
//             to: "/management/allhospitals",
//             className: "fas fa-address-book"
//           },
//           {
//             id: 4,
//             name: "All Appointments",
//             to: "/management/allappointments",
//             className: "fas fa-calendar-check"
//           },
//           {
//             id: 5,
//             name: "All Patients",
//             to: "/management/allpatients",
//             className: "fas fa-search"
//           },
//           {
//             id: 6,
//             name: "All Users",
//             to: "/management/allusers",
//             className: "fas fa-question"
//           },
//           {
//             id: 7,
//             name: "All Invoices",
//             to: "/management/allinvoices",
//             className: "fas fa-question"
//           },
//           {
//             id: 8,
//             name: "All Prescriptions",
//             to: "/management/allprescriptions",
//             className: "fas fa-question"
//           },
//           {
//             id: 9,
//             name: "Ongoing Subscriptions",
//             to: "/management/allsubscriptions",
//             className: "fas fa-question"
//           },
//           {
//             id: 10,
//             name: "All plans/Create plan",
//             to: "/management/addnewplan",
//             className: "fas fa-question"
//           },
//           {
//             id: 11,
//             name: "All Doctors",
//             to: "/management/alldoctors",
//             className: "fas fa-question"
//           },
//           {
//             id: 12,
//             name: "All Staff",
//             to: "/management/allstaff",
//             className: "fas fa-question"
//           },
//           {
//             id: 13,
//             name: "Admin - User Management",
//             to: "/management/usermanagement",
//             className: "fas fa-question"
//           },
//           {
//             id: 14,
//             name: "All Specializations",
//             to: "/management/allspecializations",
//             className: "fas fa-question"
//           },
//           {
//             id: 15,
//             name: "All Insurances",
//             to: "/management/allinsurances",
//             className: "fas fa-question"
//           }
//         ],
//         activeLink: null,
//         activeTab:0,
//       };

//       handleClick = id => {
//         this.setState({ activeLink: id });
//       };

//       handleChange = (event, newValue) => {
//         this.setState({activeTab: newValue});
//       };


//       render() {
//         const { links, activeLink } = this.state;
//         console.log("activeTab", this.state.activeTab)

//         return (
//           <React.Fragment>
//             <Box
//             sx={{
//               flexGrow: 1,
//               // maxWidth: { xs: 320, sm: 480 },
//               bgcolor: 'background.paper',
//             }}
//             >
//               <Tabs
//               value={this.state.activeTab}
//               onChange={this.handleChange}
//               variant="scrollable"
//               scrollButtons
//               aria-label="visible arrows tabs example"
//               sx={{
//                 [`& .${tabsClasses.scrollButtons}`]: {
//                   '&.Mui-disabled': { opacity: 0.3 },
//                 },
//               }}
//               >
//                 {links.map((item,idx)=>(
//                   <Tab  label={item.name} value={idx} />

//                 ))}

//               </Tabs>

//             </Box>
//             {/* {links.map(link => {
//               return (
//                 <div key={link.id} className="main_tabs adminSubHeader">
//                     <Link to={{
//                       pathname: link.to,
//                       state: {
//                         link: link,
//                         key:Math.random()
//                       }
//                     }}
//                       onClick={() => this.handleClick(link.id)}
//                       className={link.id === activeLink ? "menuactive" : ""}
//                     >
//                         {(this.props.screen === 'mobile' ) ? <React.Fragment><i style={{fontSize: "16px"}} className={link.className}></i></React.Fragment>: ''}
//                       {link.name}
//                     </Link>
//                 </div>
//               );
//             })} */}
//           </React.Fragment>
//         );
//       }


// }

// export default AdminSubHeader;