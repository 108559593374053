const UserAuthService = {


    saveToken(token) {
        if (token !== null && token !== '') {
            window.sessionStorage.setItem('auth_token', token);
        }
    },

    getToken() {
        return window.sessionStorage.getItem('auth_token');
    },

    removeToken() {
        window.sessionStorage.removeItem('auth_token');
    },

    refreshToken() {

    },

    saveRole(token) {
        if (token !== null && token !== '') {
            window.sessionStorage.setItem('role', token);
        }

    },

    getRole() {
        return window.sessionStorage.getItem('role');
    },

    removeRole() {
        window.sessionStorage.removeItem('role');
    },

    // refreshToken(){

    // }


}

export { UserAuthService };