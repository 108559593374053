import React from 'react';

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const NearByResults=(props)=>{
    let history = useHistory();
   //const {t,setT} =useState('');


    const bookAppointment =(doctorId,patientId) =>{
        console.log(doctorId)
        console.log(patientId);
        history.push('/user/book/appointment');
    }

    return (
        <div className="col-md-6">
            <Link to={{
                pathname:'/user/book/appointment',
                state: {
                    doctorId: props.doctor.id,
                    patientId: props.doctor.patient.id
                }
            }}  key={props.doctor.id} >
                <div className='dashboard_box card box_bg mb-2'>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-start align-items-center">
                            <div className="mr-2 img_block text-center">
                                <img src={require('../../../assets/images/doc1.png').default} width="100" alt="doctor profile" />
                            </div>
                            <div className="pr-0 docinfo">
                                <p className="title_name mb-md-0 mb-2"><strong>{props.doctor.name}</strong></p>
                                <p className="sub_font mb-md-0 mb-1"><i className="fas fa-graduation-cap"></i>BDS, MDS - Oral & Maxillofacial Surgery</p>
                                <p className="sub_font mb-md-0 mb-1"><i className="fas fa-graduation-cap"></i>Dentist</p>
                                <p className="sub_font mb-md-0 mb-1"><i className="fa fa-map-marker mr-2"></i> {props.doctor.hospital.name}</p>
                                <p className="sub_font mb-md-0 mb-1"><i className="fa fa-mobile-alt mr-2"></i>{props.doctor.phone}</p>
                                <p className="sub_font mb-md-0 mb-1"><i className="fas fa-thumbs-up"></i> 87% | 21 Feedbacks</p>
                            </div>
                        </div>
                        <div className="pr-2 pr-md-3 text-right mt-2">
                            <button className="btn btn-primary  m_font_10" onClick={()=>bookAppointment(props.doctor.id,props.doctor.patient.id)}>Book Appointment</button>
                        </div>
                    </div>
                </div>

            </Link>
        </div>

    );

}

export default NearByResults;