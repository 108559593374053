export default {
    ADMIN_NAV_TAB_CHANGE:"ADMIN_NAV_TAB_CHANGE",
    USER_LOGIN_WITH_OTP_REQUEST:'USER_LOGIN_WITH_OTP_REQUEST',
    USER_LOGIN_WITH_OTP_SUCCESS:'USER_LOGIN_WITH_OTP_SUCCESS',
    USER_LOGIN_WITH_OTP_FAILURE:'USER_LOGIN_WITH_OTP_FAILURE',

    USER_LOGIN_REQUEST:'USER_LOGIN_REQUEST',
    USER_LOGIN_SUCCESS:'USER_LOGIN_SUCCESS',
    USER_LOGIN_FAILURE:'USER_LOGIN_FAILURE',


    GET_COUNTRY_CODES_REQUEST:"GET_COUNTRY_CODES_REQUEST",
    GET_COUNTRY_CODES_SUCCESS:"GET_COUNTRY_CODES_SUCCESS",
    GET_COUNTRY_CODES_FAILURE:"GET_COUNTRY_CODES_FAILURE",

    HOSPITAL_LOGIN_REQUEST:"HOSPITAL_LOGIN_REQUEST",
    HOSPITAL_LOGIN_SUCCESS:"HOSPITAL_LOGIN_SUCCESS",
    HOSPITAL_LOGIN_FAILURE:"HOSPITAL_LOGIN_FAILURE",

    USER_REGISTRATION_REQUEST:"USER_REGISTRATION_REQUEST",
    USER_REGISTRATION_SUCCESS:"USER_REGISTRATION_SUCCESS",
    USER_REGISTRATION_FAILURE:"USER_REGISTRATION_FAILURE",

    USER_VERIFY_OTP_REQUEST:"USER_VERIFY_OTP_REQUEST",
    USER_VERIFY_OTP_SUCCESS:"USER_VERIFY_OTP_SUCCESS",
    USER_VERIFY_OTP_FAILURE:"USER_VERIFY_OTP_FAILURE",

    ADD_USER_DETAILS_REQUEST:"ADD_USER_DETAILS_REQUEST",
    ADD_USER_DETAILS_SUCCESS:"ADD_USER_DETAILS_SUCCESS",
    ADD_USER_DETAILS_FAILURE:"ADD_USER_DETAILS_FAILURE",

    FORGOT_PSWD_REQUEST:"FORGOT_PSWD_REQUEST",
    FORGOT_PSWD_SUCCESS:"FORGOT_PSWD_SUCCESS",
    FORGOT_PSWD_FAILURE:"FORGOT_PSWD_FAILURE",

    CHANGE_PSWD_REQUEST:"CHANGE_PSWD_REQUEST",
    CHANGE_PSWD_SUCCESS:"CHANGE_PSWD_SUCCESS",
    CHANGE_PSWD_FAILURE:"CHANGE_PSWD_FAILURE",

    FORGOT_PSWD_VERYFY_OTP_REQUEST:"FORGOT_PSWD_VERYFY_OTP_REQUEST",
    FORGOT_PSWD_VERYFY_OTP_SUCCESS:"FORGOT_PSWD_VERYFY_OTP_SUCCESS",
    FORGOT_PSWD_VERYFY_OTP_FAILURE:"FORGOT_PSWD_VERYFY_OTP_FAILURE",

    HOSPITAL_REGISTRATION_REQUEST:"HOSPITAL_REGISTRATION_REQUEST",
    HOSPITAL_REGISTRATION_SUCCESS:"HOSPITAL_REGISTRATION_SUCCESS",
    HOSPITAL_REGISTRATION_FAILURE:"HOSPITAL_REGISTRATION_FAILURE",

    USER_LOGOUT:"USER_LOGOUT",
    EMPTY_USER_REGISTRATION:"EMPTY_USER_REGISTRATION",

    GET_COUNTRY_LIST_REQUEST:"GET_COUNTRY_LIST_REQUEST",
    GET_COUNTRY_LIST_SUCCESS:"GET_COUNTRY_LIST_SUCCESS",
    GET_COUNTRY_LIST_FAILURE:"GET_COUNTRY_LIST_FAILURE",

    GET_STATE_LIST_REQUEST:"GET_STATE_LIST_REQUEST",
    GET_STATE_LIST_SUCCESS:"GET_STATE_LIST_SUCCESS",
    GET_STATE_LIST_FAILURE:"GET_STATE_LIST_FAILURE",

    GET_CITY_LIST_REQUEST:"GET_CITY_LIST_REQUEST",
    GET_CITY_LIST_SUCCESS:"GET_CITY_LIST_SUCCESS",
    GET_CITY_LIST_FAILURE:"GET_CITY_LIST_FAILURE",


    GET_HOSPITAL_DETAILS_REQUEST:"GET_HOSPITAL_DETAILS_REQUEST",
    GET_HOSPITAL_DETAILS_SUCCESS:"GET_HOSPITAL_DETAILS_SUCCESS",
    GET_HOSPITAL_DETAILS_FAILURE:"GET_HOSPITAL_DETAILS_FAILURE",


    HOSPITAL_ACC_VERIFY_REQUEST:"HOSPITAL_ACC_VERIFY_REQUEST",
    HOSPITAL_ACC_VERIFY_SUCCESS:"HOSPITAL_ACC_VERIFY_SUCCESS",
    HOSPITAL_ACC_VERIFY_FAILURE:"HOSPITAL_ACC_VERIFY_FAILURE",


    HOSPITAL_OWNER_ACCOUNT_REQUEST:"HOSPITAL_OWNER_ACCOUNT_REQUEST",
    HOSPITAL_OWNER_ACCOUNT_SUCCESS:"HOSPITAL_OWNER_ACCOUNT_SUCCESS",
    HOSPITAL_OWNER_ACCOUNT_FAILURE:"HOSPITAL_OWNER_ACCOUNT_FAILURE",

    ADD_HOSPITAL_LOCATION_REQUEST:"ADD_HOSPITAL_LOCATION_REQUEST",
    ADD_HOSPITAL_LOCATION_SUCCESS:"ADD_HOSPITAL_LOCATION_SUCCESS",
    ADD_HOSPITAL_LOCATION_FAILURE:"ADD_HOSPITAL_LOCATION_FAILURE",

    HOSPITAL_STAFF_LIST_REQUEST:"HOSPITAL_STAFF_LIST_REQUEST",
    HOSPITAL_STAFF_LIST_SUCCESS:"HOSPITAL_STAFF_LIST_SUCCESS",
    HOSPITAL_STAFF_LIST_FAILURE:"HOSPITAL_STAFF_LIST_FAILURE",

    HOSPITAL_ADD_STAFF_REQUEST:"HOSPITAL_ADD_STAFF_REQUEST",
    HOSPITAL_ADD_STAFF_SUCCESS:"HOSPITAL_ADD_STAFF_SUCCESS",
    HOSPITAL_ADD_STAFF_FAILURE:"HOSPITAL_ADD_STAFF_FAILURE",

    HOSPITAL_EDIT_STAFF_REQUEST:"HOSPITAL_EDIT_STAFF_REQUEST",
    HOSPITAL_EDIT_STAFF_SUCCESS:"HOSPITAL_EDIT_STAFF_SUCCESS",
    HOSPITAL_EDIT_STAFF_FAILURE:"HOSPITAL_EDIT_STAFF_FAILURE",

    HOSPITAL_DELETE_STAFF_REQUEST:"HOSPITAL_DELETE_STAFF_REQUEST",
    HOSPITAL_DELETE_STAFF_SUCCESS:"HOSPITAL_DELETE_STAFF_SUCCESS",
    HOSPITAL_DELETE_STAFF_FAILURE:"HOSPITAL_DELETE_STAFF_FAILURE",

    HOSPITAL_DOCUMENTS_LIST_REQUEST:"HOSPITAL_DOCUMENTS_LIST_REQUEST",
    HOSPITAL_DOCUMENTS_LIST_SUCCESS:"HOSPITAL_DOCUMENTS_LIST_SUCCESS",
    HOSPITAL_DOCUMENTS_LIST_FAILURE:"HOSPITAL_DOCUMENTS_LIST_FAILURE",

    STAFF_CATE_LIST_REQUEST:"STAFF_CATE_LIST_REQUEST",
    STAFF_CATE_LIST_SUCCESS:"STAFF_CATE_LIST_SUCCESS",
    STAFF_CATE_LIST_FAILURE:"STAFF_CATE_LIST_FAILURE",


    GET_DEGREE_LIST_REQUEST:"GET_DEGREE_LIST_REQUEST",
    GET_DEGREE_LIST_SUCCESS:"GET_DEGREE_LIST_SUCCESS",
    GET_DEGREE_LIST_FAILURE:"GET_DEGREE_LIST_FAILURE",

    GET_COLLEGE_LIST_REQUEST:"GET_COLLEGE_LIST_REQUEST",
    GET_COLLEGE_LIST_SUCCESS:"GET_COLLEGE_LIST_SUCCESS",
    GET_COLLEGE_LIST_FAILURE:"GET_COLLEGE_LIST_FAILURE",

    GET_DOCTOR_SPEC_LIST_REQUEST:"GET_DOCTOR_SPEC_LIST_REQUEST",
    GET_DOCTOR_SPEC_LIST_SUCCESS:"GET_DOCTOR_SPEC_LIST_SUCCESS",
    GET_DOCTOR_SPEC_LIST_FAILURE:"GET_DOCTOR_SPEC_LIST_FAILURE",

    ADD_DOCTOR_REQUEST:"ADD_DOCTOR_REQUEST",
    ADD_DOCTOR_SUCCESS:"ADD_DOCTOR_SUCCESS",
    ADD_DOCTOR_FAILURE:"ADD_DOCTOR_FAILURE",

    GET_DOCTOR_LIST_REQUEST:"GET_DOCTOR_LIST_REQUEST",
    GET_DOCTOR_LIST_SUCCESS:"GET_DOCTOR_LIST_SUCCESS",
    GET_DOCTOR_LIST_FAILURE:"GET_DOCTOR_LIST_FAILURE",

    DELETE_DUTY_TIME_REQUEST:"DELETE_DUTY_TIME_REQUEST",
    DELETE_DUTY_TIME_SUCCESS:"DELETE_DUTY_TIME_SUCCESS",
    DELETE_DUTY_TIME_FAILURE:"DELETE_DUTY_TIME_FAILURE",

    EDIT_DOCTOR_REQUEST:"EDIT_DOCTOR_REQUEST",
    EDIT_DOCTOR_SUCCESS:"EDIT_DOCTOR_SUCCESS",
    EDIT_DOCTOR_FAILURE:"EDIT_DOCTOR_FAILURE",

    DELETE_DOCTOR_DOCUMENT_REQUEST:"DELETE_DOCTOR_DOCUMENT_REQUEST",
    DELETE_DOCTOR_DOCUMENT_SUCCESS:"DELETE_DOCTOR_DOCUMENT_SUCCESS",
    DELETE_DOCTOR_DOCUMENT_FAILURE:"DELETE_DOCTOR_DOCUMENT_FAILURE",

    HOSPITAL_ADD_LOCATION_REQUEST:"HOSPITAL_ADD_LOCATION_REQUEST",
    HOSPITAL_ADD_LOCATION_SUCCESS:"HOSPITAL_ADD_LOCATION_SUCCESS",
    HOSPITAL_ADD_LOCATION_FAILURE:"HOSPITAL_ADD_LOCATION_FAILURE",

    HOSPITAL_EDIT_LOCATION_REQUEST:"HOSPITAL_EDIT_LOCATION_REQUEST",
    HOSPITAL_EDIT_LOCATION_SUCCESS:"HOSPITAL_EDIT_LOCATION_SUCCESS",
    HOSPITAL_EDIT_LOCATION_FAILURE:"HOSPITAL_EDIT_LOCATION_FAILURE",

    GET_LOCATION_LIST_REQUEST:"GET_LOCATION_LIST_REQUEST",
    GET_LOCATION_LIST_SUCCESS:"GET_LOCATION_LIST_SUCCESS",
    GET_LOCATION_LIST_FAILURE:"GET_LOCATION_LIST_FAILURE",

    EDIT_LOCATION_REQUEST:"EDIT_LOCATION_REQUEST",
    EDIT_LOCATION_SUCCESS:"EDIT_LOCATION_SUCCESS",
    EDIT_LOCATION_FAILURE:"EDIT_LOCATION_FAILURE",


    // USER ACCESS

    ADD_USER_FOR_ACCESS_REQUEST:"ADD_USER_FOR_ACCESS_REQUEST",
    ADD_USER_FOR_ACCESS_SUCCESS:"ADD_USER_FOR_ACCESS_SUCCESS",
    ADD_USER_FOR_ACCESS_FAILURE:"ADD_USER_FOR_ACCESS_FAILURE",

    EDIT_USER_FOR_ACCESS_REQUEST:"EDIT_USER_FOR_ACCESS_REQUEST",
    EDIT_USER_FOR_ACCESS_SUCCESS:"EDIT_USER_FOR_ACCESS_SUCCESS",
    EDIT_USER_FOR_ACCESS_FAILURE:"EDIT_USER_FOR_ACCESS_FAILURE",

    DELETE_USER_FOR_ACCESS_REQUEST:"DELETE_USER_FOR_ACCESS_REQUEST",
    DELETE_USER_FOR_ACCESS_SUCCESS:"DELETE_USER_FOR_ACCESS_SUCCESS",
    DELETE_USER_FOR_ACCESS_FAILURE:"DELETE_USER_FOR_ACCESS_FAILURE",

    GET_ROLE_LIST_REQUEST:"GET_ROLE_LIST_REQUEST",
    GET_ROLE_LIST_SUCCESS:"GET_ROLE_LIST_SUCCESS",
    GET_ROLE_LIST_FAILURE:"GET_ROLE_LIST_FAILURE",

    GET_MENUS_LIST_REQUEST:"GET_MENUS_LIST_REQUEST",
    GET_MENUS_LIST_SUCCESS:"GET_MENUS_LIST_SUCCESS",
    GET_MENUS_LIST_FAILURE:"GET_MENUS_LIST_FAILURE",

    GET_USER_ACCESS_MANAGEMENT_LIST_REQUEST:"GET_USER_ACCESS_MANAGEMENT_LIST_REQUEST",
    GET_USER_ACCESS_MANAGEMENT_LIST_SUCCESS:"GET_USER_ACCESS_MANAGEMENT_LIST_SUCCESS",
    GET_USER_ACCESS_MANAGEMENT_LIST_FAILURE:"GET_USER_ACCESS_MANAGEMENT_LIST_FAILURE",

    GET_ALL_APPOINTMENTS_REQUEST:"GET_ALL_APPOINTMENTS_REQUEST",
    GET_ALL_APPOINTMENTS_SUCCESS:"GET_ALL_APPOINTMENTS_SUCCESS",
    GET_ALL_APPOINTMENTS_FAILURE:"GET_ALL_APPOINTMENTS_FAILURE",

    GET_APPOINTMENTS_COUNT_REQUEST:"GET_APPOINTMENTS_COUNT_REQUEST",
    GET_APPOINTMENTS_COUNT_SUCCESS:"GET_APPOINTMENTS_COUNT_SUCCESS",
    GET_APPOINTMENTS_COUNT_FAILURE:"GET_APPOINTMENTS_COUNT_FAILURE",

    GET_STAFF_LIST_BY_DATE_REQUEST:"GET_STAFF_LIST_BY_DATE_REQUEST",
    GET_STAFF_LIST_BY_DATE_SUCCESS:"GET_STAFF_LIST_BY_DATE_SUCCESS",
    GET_STAFF_LIST_BY_DATE_FAILURE:"GET_STAFF_LIST_BY_DATE_FAILURE",

    INSURANCE_LIST_REQUEST:"INSURANCE_LIST_REQUEST",
    INSURANCE_LIST_SUCCESS:"INSURANCE_LIST_SUCCESS",
    INSURANCE_LIST_FAILURE:"INSURANCE_LIST_FAILURE",

    BLOOD_GROUP_LIST_REQUEST:"BLOOD_GROUP_LIST_REQUEST",
    BLOOD_GROUP_LIST_SUCCESS:"BLOOD_GROUP_LIST_SUCCESS",
    BLOOD_GROUP_LIST_FAILURE:"BLOOD_GROUP_LIST_FAILURE",

    ADD_PATIENT_PROFILE_REQUEST:"ADD_PATIENT_PROFILE_REQUEST",
    ADD_PATIENT_PROFILE_SUCCESS:"ADD_PATIENT_PROFILE_SUCCESS",
    ADD_PATIENT_PROFILE_FAILURE:"ADD_PATIENT_PROFILE_FAILURE",

    GET_SLOT_TYPES_REQUEST:"GET_SLOT_TYPES_REQUEST",
    GET_SLOT_TYPES_SUCCESS:"GET_SLOT_TYPES_SUCCESS",
    GET_SLOT_TYPES_FAILURE:"GET_SLOT_TYPES_FAILURE",

    BOOK_APPOINTMENT_REQUEST:"BOOK_APPOINTMENT_REQUEST",
    BOOK_APPOINTMENT_SUCCESS:"BOOK_APPOINTMENT_SUCCESS",
    BOOK_APPOINTMENT_FAILURE:"BOOK_APPOINTMENT_FAILURE",

    GET_PATIENT_ACCOUNT_PROFILE_REQUEST:"GET_PATIENT_ACCOUNT_PROFILE_REQUEST",
    GET_PATIENT_ACCOUNT_PROFILE_SUCCESS:"GET_PATIENT_ACCOUNT_PROFILE_SUCCESS",
    GET_PATIENT_ACCOUNT_PROFILE_FAILURE:"GET_PATIENT_ACCOUNT_PROFILE_FAILURE",

    SAVE_APPOINTMENT_DATA_REQUEST:"SAVE_APPOINTMENT_DATA_REQUEST",
    SAVE_APPOINTMENT_DATA_SUCCESS:"SAVE_APPOINTMENT_DATA_SUCCESS",
    SAVE_APPOINTMENT_DATA_FAILURE:"SAVE_APPOINTMENT_DATA_FAILURE",

    GET_PATIENT_PRESCRIPTION_REQUEST:"GET_PATIENT_PRESCRIPTION_REQUEST",
    GET_PATIENT_PRESCRIPTION_FAILURE:"GET_PATIENT_PRESCRIPTION_FAILURE",
    GET_PATIENT_PRESCRIPTION_SUCCESS:"GET_PATIENT_PRESCRIPTION_SUCCESS",

    GET_PATIENT_INVOICE_REQUEST:"GET_PATIENT_INVOICE_REQUEST",
    GET_PATIENT_INVOICE_FAILURE:"GET_PATIENT_INVOICE_FAILURE",
    GET_PATIENT_INVOICE_SUCCESS:"GET_PATIENT_INVOICE_SUCCESS",

    GET_ALL_PATIENT_PRESCRIPTION_REQUEST:"GET_ALL_PATIENT_PRESCRIPTION_REQUEST",
    GET_ALL_PATIENT_PRESCRIPTION_SUCCESS:"GET_ALL_PATIENT_PRESCRIPTION_SUCCESS",
    GET_ALL_PATIENT_PRESCRIPTION_FAILURE:"GET_ALL_PATIENT_PRESCRIPTION_FAILURE",

    START_APPOINTMENT_REQUEST:"START_APPOINTMENT_REQUEST",
    START_APPOINTMENT_SUCCESS:"START_APPOINTMENT_SUCCESS",
    START_APPOINTMENT_FAILURE:"START_APPOINTMENT_FAILURE",

    GET_ALL_PATIENT_INVOICE_REPORT_REQUEST:"GET_ALL_PATIENT_INVOICE_REPORT_REQUEST",
    GET_ALL_PATIENT_INVOICE_REPORT_SUCCESS:"GET_ALL_PATIENT_INVOICE_REPORT_SUCCESS",
    GET_ALL_PATIENT_INVOICE_REPORT_FAILURE:"GET_ALL_PATIENT_INVOICE_REPORT_FAILURE",

    GET_APPOINTMENT_HISTORY_REQUEST:"GET_APPOINTMENT_HISTORY_REQUEST",
    GET_APPOINTMENT_HISTORY_SUCCESS:"GET_APPOINTMENT_HISTORY_SUCCESS",
    GET_APPOINTMENT_HISTORY_FAILURE:"GET_APPOINTMENT_HISTORY_FAILURE",

    GET_ALL_HOSPITAL_WITH_LOCATION_REQUEST:"GET_ALL_HOSPITAL_WITH_LOCATION_REQUEST",
    GET_ALL_HOSPITAL_WITH_LOCATION_SUCCESS:"GET_ALL_HOSPITAL_WITH_LOCATION_SUCCESS",
    GET_ALL_HOSPITAL_WITH_LOCATION_FAILURE:"GET_ALL_HOSPITAL_WITH_LOCATION_FAILURE",

    GET_ALL_DOCTOR_By_LOCATION_REQUEST:"GET_ALL_DOCTOR_By_LOCATION_REQUEST",
    GET_ALL_DOCTOR_By_LOCATION_SUCCESS:"GET_ALL_DOCTOR_By_LOCATION_SUCCESS",
    GET_ALL_DOCTOR_By_LOCATION_FAILURE:"GET_ALL_DOCTOR_By_LOCATION_FAILURE",


    GET_PATIENTS_LIST_REQUEST:"GET_PATIENTS_LIST_REQUEST",
    GET_PATIENTS_LIST_SUCCESS:"GET_PATIENTS_LIST_SUCCESS",
    GET_PATIENTS_LIST_FAILURE:"GET_PATIENTS_LIST_FAILURE",

    GET_PATIENT_MEDICAL_HISTORY_REQUEST:"GET_PATIENT_MEDICAL_HISTORY_REQUEST",
    GET_PATIENT_MEDICAL_HISTORY_SUCCESS:"GET_PATIENT_MEDICAL_HISTORY_SUCCESS",
    GET_PATIENT_MEDICAL_HISTORY_FAILURE:"GET_PATIENT_MEDICAL_HISTORY_FAILURE",


    GET_PATIENT_MEDICAL_PROFILE_REQUEST:"GET_PATIENT_MEDICAL_PROFILE_REQUEST",
    GET_PATIENT_MEDICAL_PROFILE_SUCCESS:"GET_PATIENT_MEDICAL_PROFILE_SUCCESS",
    GET_PATIENT_MEDICAL_PROFILE_FAILURE:"GET_PATIENT_MEDICAL_PROFILE_FAILURE",

    EMPTY_STAFF_ACCESS_MANAGMENT:"EMPTY_STAFF_ACCESS_MANAGMENT",
    EMPTY_STAFF:"EMPTY_STAFF",
    EMPTY_LIVE_APPOINTMENT:"EMPTY_LIVE_APPOINTMENT"


}