import React, { useEffect, useState } from 'react';
import './HospitalLogin.css';
import { Link, withRouter, useHistory } from 'react-router-dom';
import HospitalSignupComponent from './Signup/HospitalSignup';
import { UserAuthService } from '../../../service/UserAuthService';
import { UserContext, UserDispatchContext } from '../../../state/UserState';
import { hospitalLogin } from "../../../redux/Main/actions";
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Typography, TextField, Button, FormControl, OutlinedInput, InputAdornment, IconButton, Grid } from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const HospitalLoginComponent = (props) => {

  const history = useHistory();
  const userDetails = React.useContext(UserContext);
  const setUserDetails = React.useContext(UserDispatchContext);
  const [login, setLogin] = useState(false)
  // const [loggedIn, setLoggedIn] = useState(false);
  const [cookies, setCookie] = useCookies(['RoleID']);

  const [state, setState] = useState({
    username: null,
    password: null
  })

  const [showPassword, setShowPassword] = useState(false);


  const handleClickShowPassword = () => setShowPassword((show) => !show);


  const saveLogin = (e) => {
    setLogin(true)
    // UserAuthService.saveToken('123');
    // UserAuthService.saveRole('HOSPITAL');
    // setUserDetails({
    //     ...userDetails,
    //     isLogin: true,
    //     role:UserAuthService.getRole(),
    //     token: UserAuthService.getToken()
    // });
    // history.push('/hospital/profile');
    const { username = null, password = null } = state || {};
    let errors = {};
    let errCount = 0;
    if (!username) {
      errors['username_err'] = true;
      errCount++;

    }
    if (!password) {
      errors['pswd_err'] = true;
      errCount++;

    }

    if (errCount > 0) {

      setState({ ...state, ...errors })
      return;
    }
    const params = {
      username: username,
      password: password
    }
    props.onLogin(params)
  };
  useEffect(async () => {
    const { message = null } = props.loginResponse || {}
    if (login && message === "Login successfully."
    ) {

      console.log("Response", props.loginResponse)

      await setCookie('RoleID', props.RoleID)
      await setCookie('authtoken', props.authtoken)
      history.push('/hospital/profile');

    }
    setLogin(false)

  }, [props.loginResponse, props.RoleID])

  const onChangeHandler = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })

  }


  return (

    <React.Fragment>
      <Grid container spacing={4} className=' container login_area'>
        <Grid item sx={12} sm={6} md={6} className='d-flex align-items-end justify-content-center'>
          <img src={require('../../../assets/images/home/doctors.png').default} alt="1" />

        </Grid>
        <Grid item sx={12} sm={6} md={6}>
          <section className="multi_step_form mt-4">
            <div className="login">
              <h4 className='mb-2 h2 fw-bold text-left'>Welcome to Aajivan</h4>

              <Grid>
                <Typography variant="p"><strong>Username</strong></Typography>
                <TextField
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder="Enter Name"
                  name='username'
                  value={state.username}
                  error={state.username_err}
                  onChange={onChangeHandler}
                />
              </Grid>
              <Grid>
                <Typography variant="p"><strong>Password</strong></Typography>
                <FormControl sx={{ width: '100%' }}>
                  {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    size="small"
                    fullWidth
                    name='password'
                    placeholder='Enter Password'
                    error={state.pswd_err}
                    onChange={onChangeHandler}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  // label="Password"
                  />
                </FormControl>
              </Grid>

              <br />

              <Button
                className='primary-button w-100 mb-2'
                onClick={(e) => { saveLogin(e) }}
              >
                Login
              </Button>

              <div className='d-flex justify-content-between align-itmes-center'>
                <p className="text-muted">Don't have a account? <Link to="./hospitalSignup" className="customAnchor"> Sign up</Link> </p>
                <Link to="/userforgotpassword" className="customAnchor">Forgot Password</Link>
              </div>
            </div>
          </section>
        </Grid>
      </Grid>
    </React.Fragment>)
}


const mapDispatchToProps = dispatch => ({
  onLogin: params => dispatch(hospitalLogin(params)),
})

const mapStateToProps = state => {
  return {
    loginResponse: state.mainReducer?.hospitalLoginReducer?.response || null,
    RoleID: state.mainReducer?.hospitalLoginReducer?.response?.data[0].roleId || null,
    authtoken: state.mainReducer?.hospitalLoginReducer?.response?.data[0].authtoken || null,

  }

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HospitalLoginComponent));
