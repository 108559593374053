
import React, { Component } from 'react';
import { TermConditionList } from '../../../mock/TermConditionList';


class PrivacyPolicy extends Component{
  
    constructor(props){
        super(props);
        this.state = {
			termConditions:TermConditionList
         };
    }

	

	componentDidMount(){
    //     setTimeout(() => {
	// 	this.setState({invoiceDTO:AllInvoiceDTOs});
	//    }, 10);



    }

    selectAppointment= (val) => {
       // console.log('fiilter selected ');
    }
   
   
	getUserInvoice (){
           
		//this.doctorId=this.props.location.state.doctorId;
		
		//this.state.prescriptionDto=PrescriptionDTO;
		//this.state.setState({prescriptionDto:PrescriptionDTO});
	}

    selectedFilter = (selectedVal) => {
      // console.log(" menu selescted ");
    }
   

    render() {

        return (
           <React.Fragment>
      <div className="tnc-section">
    <div className="container">
        <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
                <h2 className="aboutus-title">Privacy Policy</h2>
                <div className="tnc_content mt-md-5 mt-4">
                    <h4>Introduction</h4>
                    <p>Aenean vulputate eleifend tellus. <b>Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac </b> enim. Aliquam lorem ante, dapibus in. Duis aute irure dolor in reprehenderit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat nonproident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p><b>eque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.</b> </p>

                    <p>Aenean vulputate eleifend tellus. <b>Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac </b> enim. Aliquam lorem ante, dapibus in. Duis aute irure dolor in reprehenderit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat nonproident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
                <hr />    
                <div className="tnc_content">
                    <h4>Deserunt mollit anim id</h4>
                    <p>Donec vitae sapien ut libero venenatis faucibu. Nullam quis ante. Etiam sit amet orci eget eros faucibus tincidunt Duis aute irure dolor in reprehenderit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat nonproident, sunt in culpa qui officia deserunt mollit anim id est laborum:</p>

                    <ul >
                        <li>sapien ut libero venenatis faucibu. Nullam quis ante.</li>
                        <li>orci eget eros faucibus tincidunt Duis aute irure.</li>
                        <li>qui officia deserunt mollit anim id est laborum.</li>
                    </ul>
                </div>
                <hr />    
                <div className="tnc_content">
                    <h4>Exercitation ullamco Policy:</h4>
                    <p>Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in. Duis aute irure dolor in reprehenderit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat nonproident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in. Duis aute irure dolor in reprehenderit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat nonproident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in. Duis aute irure dolor in reprehenderit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat nonproident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
                <hr />    
                <div className="tnc_content">
                    <h4>Mollit anim Policy</h4>
                    <p>Reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <p>Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
                <hr />    
                <div className="tnc_content">
                    <h4>Ulputate eleifend Policy</h4>
                    <p>Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in. Duis aute irure dolor in reprehenderit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat nonproident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <p><b>eque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.</b> </p>
                </div>
                <hr />    
                <div className="tnc_content">
                    <h4>Aenean leo Policy</h4>
                    <p>Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in. Duis aute irure dolor in reprehenderit in voluptate velit essecillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat nonproident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
                <hr />
                <div className="tnc_content">
                    <h4>Contact Information</h4>
                    <p>To ask questions or comment about this privacy policy and Company's privacy practices, contact us at:</p>
                    <p><a href="#">support@Aajivan.com</a> or +91 12547 5846</p>
                </div>   

            </div>
        </div>
    </div>
</div>
           </React.Fragment>
    
        )
    }
}
export default PrivacyPolicy;