const RegistrationCouncilList=[
    {
        id:1,  
        name:"Council 1",
    },
    {
        id:2,  
        name:"Council 2",
    },
    {
        id:3,  
        name:"Council 3",
    },
    {
        id:4,  
        name:"Council 4",
    }

];

export default RegistrationCouncilList;