const LocationList=[
     {
        id:0,
         name:'Select Location'
     },
    {
        id:1,
        name:'Banglore'
    },
    {
        id:2,
        name:'Delhi'
    },
    {
        id:3,
        name:'Mumbai'
    },
    {
        id:4,
        name:'Kolkata'
    }
    
    ];

    
const InsuranceList=[
     {
         id:0,
         name:'Select Insurance'
     },
{
    id:1,
    name:'Aetna'
},
{
    id:2,
    name:'Blue Cross'
}

];

const SpecialityList=[
     {
         id:0,
         name:'Select Speciality'
     },
{
    id:1,
    name:'Dentist'
},
{
    id:2,
    name:'Gyanocologist'
},
{
    id:3,
    name:'Physician'
}

];

export {LocationList,InsuranceList,SpecialityList};