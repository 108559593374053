import { TextField, Typography } from "@mui/material";
import React from "react";

const TextInput = ({ placeholder, name, value, onChange, error = false, disabled= false, }) => {
    return (
        <>
            <Typography>{placeholder}</Typography>
            <TextField
                name={name}
                value={value}
                onChange={onChange}
                margin='dense'
                variant="outlined"
                fullWidth
                size="small"
                error={error}
                disabled={disabled}
                placeholder={placeholder}

            />
        </>

    )
}


export default TextInput