
import React, { Component } from 'react';
import {  withRouter} from "react-router-dom";
import DashBoardItemComponent from './DashBoardItem/DashBoardItem';



class DashBoardItemsComponent extends Component{
    constructor(props) {
        super(props);
      }


    itemList=[];
    componentDidMount(){

    }

    onClickItems =(value,flag,title) => {
        console.log(" received in items "+value);
        this.props.onSelectItem(value,flag,title);
    }

    render() {


        const key=this.props.itemList.key;

        if(key === 'speciality'){
            const searchText=this.props.itemList.speciality;
            const items=this.props.itemList.specialityList;
            if(searchText === ''){
                this.itemList=items;
            }else{
                this.itemList=items.filter((item) => item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
            }

        }else if (key === 'insurance'){
            const searchText=this.props.itemList.insurance;
            const items=this.props.itemList.insuranceList;
            if(searchText === ''){
                this.itemList=items;
            }else{
                this.itemList=items.filter((item) => item.title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
            }
        }


        return (
        <div className="search_popup search_popup_show">
            <ul className="search_popup_ul">
               {(this.itemList !== undefined && this.itemList !== null && this.itemList.length > 0) ?
               this.itemList.map((item, i) => {
                   return <DashBoardItemComponent key={i} item={item} flag={this.props.itemList.key} onClickItems={this.onClickItems}></DashBoardItemComponent>
               }) : ''}
            </ul>
        </div>
        )
    }
}
export default withRouter(DashBoardItemsComponent);