import { combineReducers } from "redux";

import mainReducer from '../redux/Main/reducers';


const appReducer = combineReducers({
    mainReducer
})

const rootReducer = (state, action)=>{
    return appReducer(state, action)
}

export default rootReducer