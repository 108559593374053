
const DayList=[
    {
        id:0,
        name:'Select Day'
    },
    {
        id:1,
        name:'Today'
    },
    {
        id:2,
        name:'Yestarday'
    }
    
    ];

export default DayList;

