import { take, takeEvery } from "redux-saga/effects";
import actionTypes from "../actionTypes";
import {
    countryCodes, login, loginWithOTP, hospitalLogin, userVerifyOTP, userRegistration, AddUserDetails,
    verifyOTPForPswd, ChangePassword, ForgotPassword, hospitalRegistration,


} from './authentication.saga';

import {
    HospitalDetails,
    GetHospitalDocuments,
    GetStateList,
    GetCityList,
    GetStaffList,
    AddStaff,
    EditStaff,
    DeleteStaff,
    GetStaffCategoryList,
    HospitalOwnerSettings,
    HospitalAccountVerification,
    DegreeListSaga,
    CollegeListSaga,
    DoctorSpecListSaga,
    GetDoctorListSaga,
    countryList,
    GetHospitalLicationsSaga,
    AddHospitalLocationSaga,
    EditHospitalLocationSaga,

    // user access

    addUserForAccessSaga,
    editUserForAccessSaga,
    getRolesListSaga,
    getMenusListSaga,
    deleteUserForAccessSaga,
    getUserAccessManagementListSaga,

    getAllAppointmentsSaga,
    getAppointmentsCountSaga,
    getStaffListByDateSaga,

    getPatientAccountProfileSaga,
    saveAppointmentDataSaga,
    getPatientInvoiceSaga,
    getPatientPrescriptionSaga,
    startAppointmentSaga,
    getAppointmentHistorySaga,
    allPatientInvoiceReportSaga,
    getPatientListSaga,
    getPatientMedicalHistorySaga,
    getPatientMedicalProfileSaga,

    getAllHospitalWithLocationSaga,
    getAllDoctorByLocationSaga
} from './hospital.saga';


export default function* MainSagas() {
    yield takeEvery(
        actionTypes.FORGOT_PSWD_REQUEST,
        ForgotPassword
    )
    yield takeEvery(
        actionTypes.HOSPITAL_REGISTRATION_REQUEST,
        hospitalRegistration
    )
    yield takeEvery(
        actionTypes.CHANGE_PSWD_REQUEST,
        ChangePassword
    )
    yield takeEvery(
        actionTypes.FORGOT_PSWD_VERYFY_OTP_REQUEST,
        verifyOTPForPswd
    )
    yield takeEvery(
        actionTypes.ADD_USER_DETAILS_REQUEST,
        AddUserDetails
    )
    yield takeEvery(
        actionTypes.USER_VERIFY_OTP_REQUEST,
        userVerifyOTP
    )
    yield takeEvery(
        actionTypes.USER_REGISTRATION_REQUEST,
        userRegistration
    )
    yield takeEvery(
        actionTypes.HOSPITAL_LOGIN_REQUEST,
        hospitalLogin
    )
    yield takeEvery(
        actionTypes.USER_LOGIN_REQUEST,
        login
    )
    yield takeEvery(
        actionTypes.USER_LOGIN_WITH_OTP_REQUEST,
        loginWithOTP
    )
    yield takeEvery(
        actionTypes.GET_COUNTRY_CODES_REQUEST,
        countryCodes
    )

    // HOSPITAL 
    yield takeEvery(
        actionTypes.GET_STATE_LIST_REQUEST,
        GetStateList
    )
    yield takeEvery(
        actionTypes.GET_CITY_LIST_REQUEST,
        GetCityList
    )
    yield takeEvery(
        actionTypes.HOSPITAL_OWNER_ACCOUNT_REQUEST,
        HospitalOwnerSettings
    )
    yield takeEvery(
        actionTypes.HOSPITAL_DOCUMENTS_LIST_REQUEST,
        GetHospitalDocuments
    )
    yield takeEvery(
        actionTypes.HOSPITAL_STAFF_LIST_REQUEST,
        GetStaffList
    )
    yield takeEvery(
        actionTypes.STAFF_CATE_LIST_REQUEST,
        GetStaffCategoryList
    )
    yield takeEvery(
        actionTypes.HOSPITAL_ADD_STAFF_REQUEST,
        AddStaff
    )
    yield takeEvery(
        actionTypes.HOSPITAL_EDIT_STAFF_REQUEST,
        EditStaff
    )
    yield takeEvery(
        actionTypes.HOSPITAL_DELETE_STAFF_REQUEST,
        DeleteStaff
    )
    yield takeEvery(
        actionTypes.HOSPITAL_ACC_VERIFY_REQUEST,
        HospitalAccountVerification
    )
    yield takeEvery(
        actionTypes.GET_HOSPITAL_DETAILS_REQUEST,
        HospitalDetails
    )
    yield takeEvery(
        actionTypes.GET_DEGREE_LIST_REQUEST,
        DegreeListSaga
    )
    yield takeEvery(
        actionTypes.GET_COLLEGE_LIST_REQUEST,
        CollegeListSaga
    )
    yield takeEvery(
        actionTypes.GET_DOCTOR_SPEC_LIST_REQUEST,
        DoctorSpecListSaga
    )
    yield takeEvery(
        actionTypes.GET_COUNTRY_LIST_REQUEST,
        countryList
    )
    yield takeEvery(
        actionTypes.GET_DOCTOR_LIST_REQUEST,
        GetDoctorListSaga
    )
    yield takeEvery(
        actionTypes.GET_LOCATION_LIST_REQUEST,
        GetHospitalLicationsSaga
    )
    yield takeEvery(
        actionTypes.HOSPITAL_ADD_LOCATION_REQUEST,
        AddHospitalLocationSaga
    )
    yield takeEvery(
        actionTypes.HOSPITAL_EDIT_LOCATION_REQUEST,
        EditHospitalLocationSaga
    )

    // USER ACCESS


    yield takeEvery(
        actionTypes.ADD_USER_FOR_ACCESS_REQUEST,
        addUserForAccessSaga
    )
    yield takeEvery(
        actionTypes.EDIT_USER_FOR_ACCESS_REQUEST,
        editUserForAccessSaga
    )
    yield takeEvery(
        actionTypes.GET_ROLE_LIST_REQUEST,
        getRolesListSaga
    )
    yield takeEvery(
        actionTypes.GET_MENUS_LIST_REQUEST,
        getMenusListSaga
    )
    yield takeEvery(
        actionTypes.DELETE_USER_FOR_ACCESS_REQUEST,
        deleteUserForAccessSaga
    )
    yield takeEvery(
        actionTypes.GET_USER_ACCESS_MANAGEMENT_LIST_REQUEST,
        getUserAccessManagementListSaga
    )

    yield takeEvery(
        actionTypes.GET_ALL_APPOINTMENTS_REQUEST,
        getAllAppointmentsSaga
    )
    yield takeEvery(
        actionTypes.GET_STAFF_LIST_BY_DATE_REQUEST,
        getStaffListByDateSaga
    )
    yield takeEvery(
        actionTypes.GET_APPOINTMENTS_COUNT_REQUEST,
        getAppointmentsCountSaga
    )

    yield takeEvery(
        actionTypes.GET_PATIENT_ACCOUNT_PROFILE_REQUEST,
        getPatientAccountProfileSaga
    )
    yield takeEvery(
        actionTypes.SAVE_APPOINTMENT_DATA_REQUEST,
        saveAppointmentDataSaga
    )
    yield takeEvery(
        actionTypes.GET_PATIENT_INVOICE_REQUEST,
        getPatientInvoiceSaga
    )
    yield takeEvery(
        actionTypes.GET_PATIENT_PRESCRIPTION_REQUEST,
        getPatientPrescriptionSaga
    )
    yield takeEvery(
        actionTypes.START_APPOINTMENT_REQUEST,
        startAppointmentSaga
    )
    yield takeEvery(
        actionTypes.GET_APPOINTMENT_HISTORY_REQUEST,
        getAppointmentHistorySaga
    )
    yield takeEvery(
        actionTypes.GET_ALL_PATIENT_INVOICE_REPORT_REQUEST,
        allPatientInvoiceReportSaga
    )
    yield takeEvery(

        actionTypes.GET_PATIENTS_LIST_REQUEST,
        getPatientListSaga
    )
    yield takeEvery(
        actionTypes.GET_PATIENT_MEDICAL_HISTORY_REQUEST,
        getPatientMedicalHistorySaga
    )
    yield takeEvery(
        actionTypes.GET_PATIENT_MEDICAL_PROFILE_REQUEST,
        getPatientMedicalProfileSaga
    )
    yield takeEvery(
        actionTypes.GET_ALL_HOSPITAL_WITH_LOCATION_REQUEST,
        getAllHospitalWithLocationSaga
    )
    yield takeEvery(
        actionTypes.GET_ALL_DOCTOR_By_LOCATION_REQUEST,
        getAllDoctorByLocationSaga
    )
}