import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteModal = ({ open, handleClose, handleSubmit }) => {

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            // style={{width:470}}
            fullWidth={true}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>{"Are You Sure Delete"}</DialogTitle>
            <DialogContent dividers>
                <div className="col-md-12 text-center">
                    <p><i className="fas fa-exclamation-circle modal_icon mb-2"></i></p>
                    <h4>Are You Sure?</h4>
                    <p>You Want to Delete this Record?</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined'>No</Button>
                <Button onClick={handleSubmit} variant='contained'>Yes</Button>
            </DialogActions>

        </Dialog>
    )
}

export default DeleteModal  