
import React, { Component } from 'react';
import { TermConditionList } from '../../../mock/TermConditionList';


class TermConditions extends Component{

    constructor(props){
        super(props);
        this.state = {
			termConditions:TermConditionList
         };
    }



	componentDidMount(){
    //     setTimeout(() => {
	// 	this.setState({invoiceDTO:AllInvoiceDTOs});
	//    }, 10);



    }

    selectAppointment= (val) => {
       // console.log('fiilter selected ');
    }


	getUserInvoice (){

		//this.doctorId=this.props.location.state.doctorId;

		//this.state.prescriptionDto=PrescriptionDTO;
		//this.state.setState({prescriptionDto:PrescriptionDTO});
	}

    selectedFilter = (selectedVal) => {
      // console.log(" menu selescted ");
    }


    render() {

        return (
           <React.Fragment>

      <div className="tnc-section">
    <div className="container">
        <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
                <h2 className="aboutus-title">Terms & Conditions</h2>

                {
                    this.state.termConditions.map(tm =>{
                    return (
                    <div className="tnc_content mt-md-5 mt-4">
                    <h4>{tm.title}</h4>
                    {
                     (tm.details !== null &&  tm.details !== undefined && tm.details.length > 0 )?
                               <React.Fragment>{
                                tm.details.map(dt =>{
                                    return <p>{dt.title}</p>
                                })
                                }
                                </React.Fragment> :''
                    }
                    {
                     (tm.conditionList != null &&  tm.conditionList != undefined && tm.conditionList.length > 0 )?
                         (<ul>
                             {
                                    tm.conditionList.map(ct =>{
                                            return <li>{ct.title}</li>
                                    })
                            }
                         </ul>) :''
                    }
                    </div>
                    )
                    })
                }

                <hr/>


            </div>
        </div>
    </div>
</div>

           </React.Fragment>

        )
    }
}
export default TermConditions;