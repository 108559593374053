import React, { Component } from "react";
import { Link, Route, withRouter } from "react-router-dom";

import { ChangePassword } from "../../../redux/Main/actions";
import { connect } from "react-redux";

class UserResetpassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: null,
      confirm_password: null,
    };
  }

  onChanageHandler = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  submitHandler = () => {
    const { dial_code = null, mobile = null } = this.props || {};
    const { password = null, confirm_password = null } = this.state || {};

    const params = {
      dial_code: dial_code,
      mobile: mobile,
      password: password,
      confirm_password: confirm_password,
    };

    this.props.ChangePassword(params);
  };

  render() {
    if (this.props.successMsg) {
      this.props.history.push("/userLogin");
    }
    return (
      <section className="login_area pt-md-5 pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 d-flex align-items-end justify-content-center order-md-1 order-2">
              <img
                src={require("../../../assets/images/home/doctors.png").default}
                alt="1"
              />
            </div>

            <div className="col-md-6 order-md-2 order-1">
              <div id="msform" className="login">
                <h4 className="mb-2 h2 fw-bold">Reset Password</h4>
                <form className="w-100 m-auto">
                  <div className="form-group">
                    <label htmlFor="Password">Password</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Password"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChanageHandler}
                    />
                  </div>

                  <div class="form-group mt-2">
                    <label htmlFor="">Confirm Password</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter confirm Password"
                      aria-describedby="helpId"
                      name="confirm_password"
                      value={this.state.confirm_password}
                      onChange={this.onChanageHandler}
                    />
                    <div className="invalid-feedback small d-block">
                      your password not match.
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary w-100 mt-2"
                    onClick={this.submitHandler}
                  >
                    Submit{" "}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  ChangePassword: (params) => dispatch(ChangePassword(params)),
});

const mapStateToProps = (state) => {
  return {
    successMsg:
      state.mainReducer?.ChangePasswordReducer?.response?.status === 200 ||
      false,
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserResetpassword)
);
