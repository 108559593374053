const HospitalList=[
    {
        id:0,
        name:'Select Hospital'
    },
{
    id:1,
    name:'ABC HOspital'
},
{
    id:2,
    name:'ABC 2 HOspital'
},
{
    id:3,
    name:'ABC 3 HOspital'
},
{
    id:4,
    name:'ABC 4 HOspital'
}

];


export default HospitalList;



