import React, { Component } from 'react';
import { Link, Route, withRouter } from 'react-router-dom';


class UserResetpassword extends Component{
    render() {
        return (
            <section className="login_area pt-md-5 pt-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 d-flex align-items-end justify-content-center order-md-1 order-2">
                            <img src={require('../../../assets/images/home/doctors.png').default} alt="1"/>
                        </div>

                        <div className="col-md-6 order-md-2 order-1">
                            <div id="msform" className='login'>
                                <h4 className='mb-2 h2 fw-bold'>Reset Password</h4>
                                <form className="w-100 m-auto">
                                    <div className="form-group">
                                        <label htmlFor="Password">Password</label>
                                        <input type="text" className="form-control" placeholder="Enter Password" />
                                    </div>

                                    <div className="form-group mt-2">
                                        <label htmlFor="">Confirm Password</label>
                                        <input type="text" className="form-control" placeholder="Enter confirm Password" aria-describedby="helpId"/>
                                        <div className="invalid-feedback small d-block">
                                            your password not match
                                        </div>
                                    </div>
                                    <Link to="/userLogin" className='btn btn-primary w-100 mt-2'>Reset Password</Link>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default withRouter(UserResetpassword);