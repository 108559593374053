import React, { Component } from 'react';
import { Route, Link, withRouter, useHistory } from "react-router-dom";
import '../../../assets/style.css';
import './doctorProfile.css';

class doctorProfile extends Component {
    render() {
        return (
            <div className="content">
                <div className="container">
                    <div className="card mt-2">
                        <div className="card-body">
                            <div className="doctor-widget">
                                <div className="doc-info-left">
                                    <div className="doctor-img">
                                        <img src={require('../../../assets/images/home/doctor.png').default} className="img-fluid" alt="User Image"/>
                                    </div>
                                    <div className="doc-info-cont">
                                        <h4 className="doc-name">Dr. Wilson</h4>
                                        <p className="doc-speciality"><i class="fas fa-graduation-cap"></i> BDS, MDS - Oral & Maxillofacial Surgery</p>
                                        <p className="doc-department"><i className="fas fa-user-md"></i> Dentist</p>
                                        <div className="clinic-details">
                                            <p className="doc-location"><i className="fas fa-map-marker-alt"></i> Apollo Delhi <Link
                                                to="javascript:void(0);">Get Directions</Link></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="doc-info-right">
                                    <div className="clini-infos">
                                        <ul>
                                            <li><i className="far fa-thumbs-up"></i> 99%</li>
                                            <li><i className="fas fa-map-marker-alt"></i> Lalkila, Delhi, India</li>
                                            {/* <li><i className="far fa-money-bill-alt"></i> $100 - $300 <i className="feather-info"data-bs-toggle="tooltip" title="Lorem Ipsum"></i> </li> */}
                                        </ul>
                                    </div>
                                    <div className="clinic-booking">
                                        <Link className="apt-btn" to="./book/appointment">Book Appointment</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <nav className="user-tabs mb-4">
                                <ul className="nav nav-tabs nav-tabs-bottom">
                                    <li className="nav-item">
                                        <Link className="nav-link active rounded-left" to="#doc_overview" data-bs-toggle="tab">Overview</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="#doc_locations" data-bs-toggle="tab">Locations</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="#doc_reviews" data-bs-toggle="tab">Reviews</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link rounded-right" to="#doc_clinic_hours" data-bs-toggle="tab">Clinic Hours</Link>
                                    </li>
                                </ul>
                            </nav>

                            <div className="tab-content pt-0">
                                <div role="tabpanel" id="doc_overview" className="tab-pane fade show active">
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12">

                                            <div className="widget about-widget">
                                                <h4 className="widget-title">About Me</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                                    veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                                                    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                                                    velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                                    occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                                                    mollit anim id est laborum.</p>
                                            </div>


                                            <div className="widget education-widget">
                                                <h4 className="widget-title">Education</h4>
                                                <div className="experience-box">
                                                    <ul className="experience-list">
                                                        <li>
                                                            <div className="experience-user">
                                                                <div className="before-circle"></div>
                                                            </div>
                                                            <div className="experience-content">
                                                                <div className="timeline-content">
                                                                    <Link to="#/" className="name">Dental Medical
                                                                        University</Link>
                                                                    <div>BDS</div>
                                                                    <span className="time">1998 - 2003</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="experience-user">
                                                                <div className="before-circle"></div>
                                                            </div>
                                                            <div className="experience-content">
                                                                <div className="timeline-content">
                                                                    <Link to="#/" className="name">Dental Medical
                                                                        University</Link>
                                                                    <div>MDS</div>
                                                                    <span className="time">2003 - 2005</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>


                                            {/* <div className="widget experience-widget">
                                                <h4 className="widget-title">Work & Experience</h4>
                                                <div className="experience-box">
                                                    <ul className="experience-list">
                                                        <li>
                                                            <div className="experience-user">
                                                                <div className="before-circle"></div>
                                                            </div>
                                                            <div className="experience-content">
                                                                <div className="timeline-content">
                                                                    <Link to="#/" className="name">Smiles Family Dental
                                                                        Clinic</Link>
                                                                    <span className="time">2010 - Present (12 years)</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="experience-user">
                                                                <div className="before-circle"></div>
                                                            </div>
                                                            <div className="experience-content">
                                                                <div className="timeline-content">
                                                                    <Link to="#/" className="name">DentalCare Dental Clinic</Link>
                                                                    <span className="time">2007 - 2010 (3 years)</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="experience-user">
                                                                <div className="before-circle"></div>
                                                            </div>
                                                            <div className="experience-content">
                                                                <div className="timeline-content">
                                                                    <Link to="#/" className="name">Dream Smile Dental</Link>
                                                                    <span className="time">2005 - 2007 (2 years)</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> */}

                                            <div className="service-list">
                                                <h4>Specializations</h4>
                                                <ul className="clearfix">
                                                    <li>Children Care</li>
                                                    <li>Dental Care</li>
                                                    <li>Oral and Maxillofacial Surgery </li>
                                                    <li>Orthodontist</li>
                                                    <li>Periodontist</li>
                                                    <li>Prosthodontics</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div role="tabpanel" id="doc_locations" className="tab-pane fade">
                                    <div className="location-list">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="clinic-content">
                                                    <h4 className="clinic-name"><Link to="#">Smile Dental Care Center</Link>
                                                    </h4>
                                                    <p className="doc-speciality">MDS - Periodontology and Oral Implantology, BDS</p>

                                                    <div className="clinic-details mb-0">
                                                        <h5 className="clinic-direction"> <i className="feather-map-pin"></i> Apollo Delhi Location 1 <br/><Link
                                                                to="javascript:void(0);">Get Directions</Link></h5>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className="clinic-timing">
                                                    <div>
                                                        <p className="timings-days">
                                                            <span> Mon - Sat </span>
                                                        </p>
                                                        <p className="timings-times">
                                                            <span>10:00 AM - 2:00 PM</span>
                                                            <span>4:00 PM - 9:00 PM</span>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className="timings-days">
                                                            <span>Sun</span>
                                                        </p>
                                                        <p className="timings-times">
                                                            <span>10:00 AM - 2:00 PM</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="location-list">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="clinic-content">
                                                    <h4 className="clinic-name"><Link to="#">Family Dentistry Clinic</Link></h4>
                                                    <p className="doc-speciality">MDS - Periodontology and Oral Implantology,
                                                        BDS</p>
                                                    <div className="clinic-details mb-0">
                                                        <p className="clinic-direction"> <i className="feather-map-pin"></i> Apollo Delhi Location 2 <br/><Link
                                                                to="javascript:void(0);">Get Directions</Link></p>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-md-6">
                                                <div className="clinic-timing">
                                                    <div>
                                                        <p className="timings-days">
                                                            <span> Tue - Fri </span>
                                                        </p>
                                                        <p className="timings-times">
                                                            <span>11:00 AM - 1:00 PM</span>
                                                            <span>6:00 PM - 11:00 PM</span>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className="timings-days">
                                                            <span>Sat - Sun</span>
                                                        </p>
                                                        <p className="timings-times">
                                                            <span>8:00 AM - 10:00 AM</span>
                                                            <span>3:00 PM - 7:00 PM</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div role="tabpanel" id="doc_reviews" className="tab-pane fade">
                                    <div className="widget review-listing">
                                        <ul className="comments-list">
                                            <li>
                                                <div className="comment">
                                                    <img className="avatar avatar-sm rounded-circle" alt="User Image"
                                                        src={require('../../../assets/images/home/doctor.png').default}/>
                                                    <div className="comment-body">
                                                        <div className="meta-data">
                                                            <span className="comment-author">Richard Wilson</span>
                                                            <span className="comment-date">Reviewed 2 Days ago</span>
                                                            <div className="review-count rating">
                                                                <i className="fas fa-star filled"></i>
                                                                <i className="fas fa-star filled"></i>
                                                                <i className="fas fa-star filled"></i>
                                                                <i className="fas fa-star filled"></i>
                                                                <i className="fas fa-star"></i>
                                                            </div>
                                                        </div>
                                                        <p className="recommended"><i className="far fa-thumbs-up"></i> I recommend
                                                            the doctor</p>
                                                        <p className="comment-content">
                                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                            sed do eiusmod tempor incididunt ut labore et dolore magna
                                                            aliqua.
                                                            Ut enim ad minim veniam, quis nostrud exercitation.
                                                            Curabitur non nulla sit amet nisl tempus
                                                        </p>
                                                        <div className="comment-reply">
                                                            <Link className="comment-btn" to="#">
                                                                <i className="fas fa-reply"></i> Reply
                                                            </Link>
                                                            <p className="recommend-btn">
                                                                <span>Recommend?</span>
                                                                <Link to="#" className="like-btn">
                                                                    <i className="far fa-thumbs-up"></i> Yes
                                                                </Link>
                                                                <Link to="#" className="dislike-btn">
                                                                    <i className="far fa-thumbs-down"></i> No
                                                                </Link>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>

                                        <div className="all-feedback load-more mb-0">
                                            <Link to="#" className="btn btn-primary btn-sm">
                                                Show all feedback
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div role="tabpanel" id="doc_clinic_hours" className="tab-pane fade">
                                    <div className="row">
                                        <div className="col-md-6 offset-md-3">
                                            <div className="widget business-widget">
                                                <div className="widget-content">
                                                    <div className="listing-hours">
                                                        <div className="card-header">
                                                            <div className="listing-day current mb-0 pb-0">
                                                                <div className="day">Today
                                                                </div>
                                                                <div className="time-items">
                                                                    <span className="open-status"><span
                                                                            className="badge success-status mb-1">Open
                                                                            Now</span></span>
                                                                    <span className="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="listing-day">
                                                                <div className="day">Monday</div>
                                                                <div className="time-items">
                                                                    <span className="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                            <div className="listing-day">
                                                                <div className="day">Tuesday</div>
                                                                <div className="time-items">
                                                                    <span className="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                            <div className="listing-day">
                                                                <div className="day">Wednesday</div>
                                                                <div className="time-items">
                                                                    <span className="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                            <div className="listing-day">
                                                                <div className="day">Thursday</div>
                                                                <div className="time-items">
                                                                    <span className="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                            <div className="listing-day">
                                                                <div className="day">Friday</div>
                                                                <div className="time-items">
                                                                    <span className="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                            <div className="listing-day">
                                                                <div className="day">Saturday</div>
                                                                <div className="time-items">
                                                                    <span className="time">07:00 AM - 09:00 PM</span>
                                                                </div>
                                                            </div>
                                                            <div className="listing-day closed">
                                                                <div className="day">Sunday</div>
                                                                <div className="time-items">
                                                                    <span className="time"><span
                                                                            className="badge danger-status">Closed</span></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withRouter(doctorProfile);