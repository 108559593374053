import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';

import {
    getAppointmentsCountAction, getStaffListByDateAction
} from 'src/redux/Main/actions';
import { Box, Card, Grid, CardContent, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TextField } from "@mui/material";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DashBoard = (props) => {
    const [cookies, setCookie] = useCookies(['authtoken']);
    const [date, setDate] = useState(new Date())


    const { authtoken = null } = cookies
    
    useEffect(() => {
        const { authtoken = null } = cookies
        props.getAppointmentsCountAction({ authToken: authtoken })
    }, [])


    useEffect(() => {

        props.getStaffListByDateAction(
            {
                authToken: authtoken,
                date: date
            }
        )
    }, [date])




    const { appointments: { offline = 0, online = 0 } } = props


    return (
        <>
            <Box style={{ margin: 30 }}>
                <Grid container spacing={4}>
                    <Grid item sx={12} sm={12} md={4}>
                        <Card variant="outlined">

                            <CardContent>
                                <Typography variant="h6" component="div" className="text-center"> Today's Total Online Appointment</Typography>
                                <Typography variant="h3" component="div" className="waiting text-center"> <Link to="/hospital/allappointment">{online}</Link></Typography>
                                <hr />
                                <Typography variant="h6" component="div" className="text-center"> Today's Total Offline Appointment</Typography>
                                <Typography variant="h3" component="div" className="waiting text-center"> <Link to="/hospital/allappointment">{offline}</Link></Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sx={12} sm={12} md={8}>
                        <Card variant="outlined">
                            <CardContent>

                                <Typography variant="h6" component="div" className="text-center fw-bold"> Medital Rep(MR) Meetings</Typography>
                                <Grid container spacing={4}>
                                    <Grid item>

                                    </Grid>
                                </Grid>
                            </CardContent>

                        </Card>

                    </Grid>
                </Grid>

                <br />

                <Card variant="outlined">

                    <CardContent>
                        <div className="d-flex justify-content-between">
                            <Typography variant="h6" component="div" className="text-center fw-bold">Today's Staff</Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <DatePicker
                                    value={date}
                                    onChange={(date) => setDate(date)}
                                    renderInput={(params) => <TextField {...params} size='small' />}

                                />
                            </LocalizationProvider>
                        </div>
                        <br />
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Timing</TableCell>
                                        <TableCell>Day</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.staffList?.map((item) => (
                                        <TableRow key={item.user_id}>
                                            <TableCell>{item.name}</TableCell>
                                            <TableCell>
                                                {item.duty_timings?.map((time) => (
                                                    <p>{`${time.from_time} - ${time.to_time}`}</p>
                                                ))}
                                            </TableCell>
                                            <TableCell>
                                                {item.duty_timings?.map((day) => (
                                                    <p>{`${day.day}`}</p>
                                                ))}
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                </TableBody>

                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>

            </Box>
        </>
    )

}




const mapDispatchToProps = dispatch => ({
    getAppointmentsCountAction: (params) => dispatch(getAppointmentsCountAction(params)),
    getStaffListByDateAction: (params) => dispatch(getStaffListByDateAction(params)),

})

const mapStateToProps = state => {
    return {
        appointments:
            state.mainReducer?.getAppointmentsCountReducer?.response?.data || {},
        staffList:
            state.mainReducer?.getStaffListByDateReducer?.response?.data || [],

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);