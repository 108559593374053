import React, { Component } from 'react';
import * as $ from 'jquery';
import RegistrationCouncilList from '../../../mock/hospital/RegistrationCouncil';
import HospitalProfile from '../../../mock/hospital/HospitalProfile';
import RegistrationTypeList from '../../../mock/hospital/RegistrationType';


class ManageStaffComponent extends Component{

    constructor(props) {
		super(props);
		this.state = {
            tabClicked:true
        };

      }

    onTabClicked=(e)=>{
        this.setState({
            tabClicked: !this.state.tabClicked
          });
    }
    render() {

        return (
            <React.Fragment>

<div className="row">
   {/* <!--  <div className="col-md-2 p-0 d-none d-md-block">
        <div className="filter">
            <p className="text-center mb-3"><strong>Filters</strong></p>
            <select className="form-control">
                <option value="">By Day</option>
                <option value="">By Month</option>
                <option value="">By Year</option>
            </select>

            <select className="form-control mt-3">
                <option value="">By Old Case</option>
                <option value="">By New Case</option>
            </select>
        </div>
    </div> --> */}

    <div className="col-md-12">
        <div className="tab-wrap">
            <input type="radio" name="tabs" id="tab1"
            onChange={() => this.onTabClicked()}
            checked={this.state.tabClicked}

            />
            <div className="tab-label-content" id="tab1-content">
                <label htmlFor="tab1">Add New Staff</label>
                <div className="tab-content text-center mt-2 pr-3">
                    <form action="">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6 col-4">
                                        <div className="small-12 medium-2 large-2 columns">
                                            <div className="circle">
                                                <img className="profile-pic"
                                                src="http://cdn.cutestpaw.com/wp-content/uploads/2012/07/l-Wittle-puppy-yawning.jpg" />
                                                <i className="fa fa-user fa-3x mt-md-5 mt-3 mr-3"></i>
                                            </div>
                                            <div className="p-image">
                                                <i className="fa fa-camera upload-button"></i>
                                                <input className="file-upload" type="file" accept="image/*" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-8 pl-0">
                                        <div className="row">
                                            <div className="col-6 pr-1">
                                                <input type="text" className="form-control mb-2 form-size" placeholder="Name" />
                                            </div>
                                            <div className="col-6 pl-1">
                                                <input type="text" className="form-control mb-2 form-size" placeholder="Phone Number" />
                                            </div>
                                            <div className="col-6 pr-1">
                                                <select className="form-control form-size  mb-2">
                                                    <option value="">Staff Type</option>
                                                    <option value="">Doctor</option>
                                                    <option value="">Receptionist</option>
                                                    <option value="">Nurse</option>
                                                </select>
                                            </div>
                                            <div className="col-6 pl-1">
                                                <input type="text" className="form-control mb-2 form-size" placeholder="ID No." />
                                            </div>
                                            <div className="col-6 pr-1">
                                                <input type="text" className="form-control mb-2 form-size" placeholder="Password" />
                                            </div>
                                            <div className="col-6 pl-1">
                                                <input type="text" className="form-control mb-2 form-size" placeholder="Confirm Password" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-12 mt-md-5">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Days</th>
                                                <th>Timing</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="td_padd">Monday</td>
                                                <td className="td_padd">
                                                   <select className="form-control form-size textarea_size ">
                                                        <option value="" disabled>Time Slot</option>
                                                        <option value="">12:00 AM - 01:00 AM</option>
                                                        <option value="">01:00 AM - 02:00 AM</option>
                                                        <option value="">02:00 AM - 03:00 AM</option>
                                                        <option value="">03:00 AM - 04:00 AM</option>
                                                        <option value="">04:00 AM - 05:00 AM</option>
                                                        <option value="">05:00 AM - 06:00 AM</option>
                                                        <option value="">06:00 AM - 07:00 AM</option>
                                                        <option value="">07:00 AM - 08:00 AM</option>
                                                        <option value="">08:00 AM - 09:00 AM</option>
                                                        <option value="">09:00 AM - 10:00 AM</option>
                                                        <option value="">10:00 AM - 11:00 AM</option>
                                                        <option value="">11:00 AM - 12:00 AM</option>
                                                        <option value="">12:00 PM - 01:00 PM</option>
                                                        <option value="">01:00 PM - 02:00 PM</option>
                                                        <option value="">02:00 PM - 03:00 PM</option>
                                                        <option value="">03:00 PM - 04:00 PM</option>
                                                        <option value="">04:00 PM - 05:00 PM</option>
                                                        <option value="">05:00 PM - 06:00 PM</option>
                                                        <option value="">06:00 PM - 07:00 PM</option>
                                                        <option value="">07:00 PM - 08:00 PM</option>
                                                        <option value="">08:00 PM - 09:00 PM</option>
                                                        <option value="">09:00 PM - 10:00 PM</option>
                                                        <option value="">10:00 PM - 11:00 PM</option>
                                                        <option value="">11:00 PM - 12:00 AM</option>
                                                        <option value="">Off</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="td_padd">Tuesday</td>
                                                <td className="td_padd">
                                                   <select className="form-control form-size textarea_size ">
                                                        <option value="" disabled>Time Slot</option>
                                                        <option value="">12:00 AM - 01:00 AM</option>
                                                        <option value="">01:00 AM - 02:00 AM</option>
                                                        <option value="">02:00 AM - 03:00 AM</option>
                                                        <option value="">03:00 AM - 04:00 AM</option>
                                                        <option value="">04:00 AM - 05:00 AM</option>
                                                        <option value="">05:00 AM - 06:00 AM</option>
                                                        <option value="">06:00 AM - 07:00 AM</option>
                                                        <option value="">07:00 AM - 08:00 AM</option>
                                                        <option value="">08:00 AM - 09:00 AM</option>
                                                        <option value="">09:00 AM - 10:00 AM</option>
                                                        <option value="">10:00 AM - 11:00 AM</option>
                                                        <option value="">11:00 AM - 12:00 AM</option>
                                                        <option value="">12:00 PM - 01:00 PM</option>
                                                        <option value="">01:00 PM - 02:00 PM</option>
                                                        <option value="">02:00 PM - 03:00 PM</option>
                                                        <option value="">03:00 PM - 04:00 PM</option>
                                                        <option value="">04:00 PM - 05:00 PM</option>
                                                        <option value="">05:00 PM - 06:00 PM</option>
                                                        <option value="">06:00 PM - 07:00 PM</option>
                                                        <option value="">07:00 PM - 08:00 PM</option>
                                                        <option value="">08:00 PM - 09:00 PM</option>
                                                        <option value="">09:00 PM - 10:00 PM</option>
                                                        <option value="">10:00 PM - 11:00 PM</option>
                                                        <option value="">11:00 PM - 12:00 AM</option>
                                                        <option value="">Off</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="td_padd">Wednesday</td>
                                                <td className="td_padd">
                                                   <select className="form-control form-size textarea_size ">
                                                        <option value="" disabled>Time Slot</option>
                                                        <option value="">12:00 AM - 01:00 AM</option>
                                                        <option value="">01:00 AM - 02:00 AM</option>
                                                        <option value="">02:00 AM - 03:00 AM</option>
                                                        <option value="">03:00 AM - 04:00 AM</option>
                                                        <option value="">04:00 AM - 05:00 AM</option>
                                                        <option value="">05:00 AM - 06:00 AM</option>
                                                        <option value="">06:00 AM - 07:00 AM</option>
                                                        <option value="">07:00 AM - 08:00 AM</option>
                                                        <option value="">08:00 AM - 09:00 AM</option>
                                                        <option value="">09:00 AM - 10:00 AM</option>
                                                        <option value="">10:00 AM - 11:00 AM</option>
                                                        <option value="">11:00 AM - 12:00 AM</option>
                                                        <option value="">12:00 PM - 01:00 PM</option>
                                                        <option value="">01:00 PM - 02:00 PM</option>
                                                        <option value="">02:00 PM - 03:00 PM</option>
                                                        <option value="">03:00 PM - 04:00 PM</option>
                                                        <option value="">04:00 PM - 05:00 PM</option>
                                                        <option value="">05:00 PM - 06:00 PM</option>
                                                        <option value="">06:00 PM - 07:00 PM</option>
                                                        <option value="">07:00 PM - 08:00 PM</option>
                                                        <option value="">08:00 PM - 09:00 PM</option>
                                                        <option value="">09:00 PM - 10:00 PM</option>
                                                        <option value="">10:00 PM - 11:00 PM</option>
                                                        <option value="">11:00 PM - 12:00 AM</option>
                                                        <option value="">Off</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="td_padd">Thursday</td>
                                                <td className="td_padd">
                                                   <select className="form-control form-size textarea_size ">
                                                        <option value="" disabled>Time Slot</option>
                                                        <option value="">12:00 AM - 01:00 AM</option>
                                                        <option value="">01:00 AM - 02:00 AM</option>
                                                        <option value="">02:00 AM - 03:00 AM</option>
                                                        <option value="">03:00 AM - 04:00 AM</option>
                                                        <option value="">04:00 AM - 05:00 AM</option>
                                                        <option value="">05:00 AM - 06:00 AM</option>
                                                        <option value="">06:00 AM - 07:00 AM</option>
                                                        <option value="">07:00 AM - 08:00 AM</option>
                                                        <option value="">08:00 AM - 09:00 AM</option>
                                                        <option value="">09:00 AM - 10:00 AM</option>
                                                        <option value="">10:00 AM - 11:00 AM</option>
                                                        <option value="">11:00 AM - 12:00 AM</option>
                                                        <option value="">12:00 PM - 01:00 PM</option>
                                                        <option value="">01:00 PM - 02:00 PM</option>
                                                        <option value="">02:00 PM - 03:00 PM</option>
                                                        <option value="">03:00 PM - 04:00 PM</option>
                                                        <option value="">04:00 PM - 05:00 PM</option>
                                                        <option value="">05:00 PM - 06:00 PM</option>
                                                        <option value="">06:00 PM - 07:00 PM</option>
                                                        <option value="">07:00 PM - 08:00 PM</option>
                                                        <option value="">08:00 PM - 09:00 PM</option>
                                                        <option value="">09:00 PM - 10:00 PM</option>
                                                        <option value="">10:00 PM - 11:00 PM</option>
                                                        <option value="">11:00 PM - 12:00 AM</option>
                                                        <option value="">Off</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="td_padd">Friday</td>
                                                <td className="td_padd">
                                                   <select className="form-control form-size textarea_size ">
                                                        <option value="" disabled>Time Slot</option>
                                                        <option value="">12:00 AM - 01:00 AM</option>
                                                        <option value="">01:00 AM - 02:00 AM</option>
                                                        <option value="">02:00 AM - 03:00 AM</option>
                                                        <option value="">03:00 AM - 04:00 AM</option>
                                                        <option value="">04:00 AM - 05:00 AM</option>
                                                        <option value="">05:00 AM - 06:00 AM</option>
                                                        <option value="">06:00 AM - 07:00 AM</option>
                                                        <option value="">07:00 AM - 08:00 AM</option>
                                                        <option value="">08:00 AM - 09:00 AM</option>
                                                        <option value="">09:00 AM - 10:00 AM</option>
                                                        <option value="">10:00 AM - 11:00 AM</option>
                                                        <option value="">11:00 AM - 12:00 AM</option>
                                                        <option value="">12:00 PM - 01:00 PM</option>
                                                        <option value="">01:00 PM - 02:00 PM</option>
                                                        <option value="">02:00 PM - 03:00 PM</option>
                                                        <option value="">03:00 PM - 04:00 PM</option>
                                                        <option value="">04:00 PM - 05:00 PM</option>
                                                        <option value="">05:00 PM - 06:00 PM</option>
                                                        <option value="">06:00 PM - 07:00 PM</option>
                                                        <option value="">07:00 PM - 08:00 PM</option>
                                                        <option value="">08:00 PM - 09:00 PM</option>
                                                        <option value="">09:00 PM - 10:00 PM</option>
                                                        <option value="">10:00 PM - 11:00 PM</option>
                                                        <option value="">11:00 PM - 12:00 AM</option>
                                                        <option value="">Off</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="td_padd">Saturday</td>
                                                <td className="td_padd">
                                                   <select className="form-control form-size textarea_size ">
                                                        <option value="" disabled>Time Slot</option>
                                                        <option value="">12:00 AM - 01:00 AM</option>
                                                        <option value="">01:00 AM - 02:00 AM</option>
                                                        <option value="">02:00 AM - 03:00 AM</option>
                                                        <option value="">03:00 AM - 04:00 AM</option>
                                                        <option value="">04:00 AM - 05:00 AM</option>
                                                        <option value="">05:00 AM - 06:00 AM</option>
                                                        <option value="">06:00 AM - 07:00 AM</option>
                                                        <option value="">07:00 AM - 08:00 AM</option>
                                                        <option value="">08:00 AM - 09:00 AM</option>
                                                        <option value="">09:00 AM - 10:00 AM</option>
                                                        <option value="">10:00 AM - 11:00 AM</option>
                                                        <option value="">11:00 AM - 12:00 AM</option>
                                                        <option value="">12:00 PM - 01:00 PM</option>
                                                        <option value="">01:00 PM - 02:00 PM</option>
                                                        <option value="">02:00 PM - 03:00 PM</option>
                                                        <option value="">03:00 PM - 04:00 PM</option>
                                                        <option value="">04:00 PM - 05:00 PM</option>
                                                        <option value="">05:00 PM - 06:00 PM</option>
                                                        <option value="">06:00 PM - 07:00 PM</option>
                                                        <option value="">07:00 PM - 08:00 PM</option>
                                                        <option value="">08:00 PM - 09:00 PM</option>
                                                        <option value="">09:00 PM - 10:00 PM</option>
                                                        <option value="">10:00 PM - 11:00 PM</option>
                                                        <option value="">11:00 PM - 12:00 AM</option>
                                                        <option value="">Off</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="td_padd">Sunday</td>
                                                <td className="td_padd">
                                                   <select className="form-control form-size textarea_size ">
                                                        <option value="" disabled>Time Slot</option>
                                                        <option value="">12:00 AM - 01:00 AM</option>
                                                        <option value="">01:00 AM - 02:00 AM</option>
                                                        <option value="">02:00 AM - 03:00 AM</option>
                                                        <option value="">03:00 AM - 04:00 AM</option>
                                                        <option value="">04:00 AM - 05:00 AM</option>
                                                        <option value="">05:00 AM - 06:00 AM</option>
                                                        <option value="">06:00 AM - 07:00 AM</option>
                                                        <option value="">07:00 AM - 08:00 AM</option>
                                                        <option value="">08:00 AM - 09:00 AM</option>
                                                        <option value="">09:00 AM - 10:00 AM</option>
                                                        <option value="">10:00 AM - 11:00 AM</option>
                                                        <option value="">11:00 AM - 12:00 AM</option>
                                                        <option value="">12:00 PM - 01:00 PM</option>
                                                        <option value="">01:00 PM - 02:00 PM</option>
                                                        <option value="">02:00 PM - 03:00 PM</option>
                                                        <option value="">03:00 PM - 04:00 PM</option>
                                                        <option value="">04:00 PM - 05:00 PM</option>
                                                        <option value="">05:00 PM - 06:00 PM</option>
                                                        <option value="">06:00 PM - 07:00 PM</option>
                                                        <option value="">07:00 PM - 08:00 PM</option>
                                                        <option value="">08:00 PM - 09:00 PM</option>
                                                        <option value="">09:00 PM - 10:00 PM</option>
                                                        <option value="">10:00 PM - 11:00 PM</option>
                                                        <option value="">11:00 PM - 12:00 AM</option>
                                                        <option value="">Off</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="col-md-12 mt-1 text-right">
                                        <button className="btn btn-primary font-size_mheader" type="submit">Done</button>
                                    </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>


            <input type="radio" name="tabs" id="tab2" />
            <div className="tab-label-content" id="tab2-content">
                <label htmlFor="tab2">All Staff Members</label>
                <div className="tab-content mt-2" style={{ width: '100%' }}>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Phone No.</th>
                                <th scope="col">Timing</th>
                                <th scope="col">Duty Day</th>
                                <th scope="col">ID No.</th>
                                <th scope="col">Password</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Ms. Rossy</td>
                                <td>123459877</td>
                                <td>
                                    <ul>
                                        <li>10:00 AM - 11:00 AM</li>
                                        <li>11:00 AM - 12:00 AM</li>
                                        <li>12:00 AM - 1:00 PM</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Monday</li>
                                        <li>Tuesday</li>
                                        <li>Friday</li>
                                    </ul>
                                </td>
                                <td>12635</td>
                                <td>admin123</td>
                                <td>
                                    <ul className="d-flex options">
                                        <li className="p-2"><i className="fas fa-eye"></i></li>
                                        <li className="p-2" data-toggle="modal" data-target=".editemodel"><i className="fas fa-pencil-alt"></i></li>
                                        <li className="p-2" data-toggle="modal" data-target=".deletemodel"><i className="fas fa-trash-alt"></i></li>
                                    </ul>
                                </td>
                            </tr>

                            <tr>
                                <td>Ms. Rossy</td>
                                <td>123459877</td>
                                <td>
                                    <ul>
                                        <li>10:00 AM - 11:00 AM</li>
                                        <li>11:00 AM - 12:00 AM</li>
                                        <li>12:00 AM - 1:00 PM</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Monday</li>
                                        <li>Tuesday</li>
                                        <li>Friday</li>
                                    </ul>
                                </td>
                                <td>12635</td>
                                <td>admin123</td>
                                <td>
                                    <ul className="d-flex options">
                                        <li className="p-2"><i className="fas fa-eye"></i></li>
                                        <li className="p-2" data-toggle="modal" data-target=".editemodel"><i className="fas fa-pencil-alt"></i></li>
                                        <li className="p-2" data-toggle="modal" data-target=".deletemodel"><i className="fas fa-trash-alt"></i></li>
                                    </ul>
                                </td>
                            </tr>

                            <tr>
                                <td>Ms. Rossy</td>
                                <td>123459877</td>
                                <td>
                                    <ul>
                                        <li>10:00 AM - 11:00 AM</li>
                                        <li>11:00 AM - 12:00 AM</li>
                                        <li>12:00 AM - 1:00 PM</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Monday</li>
                                        <li>Tuesday</li>
                                        <li>Friday</li>
                                    </ul>
                                </td>
                                <td>12635</td>
                                <td>admin123</td>
                                <td>
                                    <ul className="d-flex options">
                                        <li className="p-2"><i className="fas fa-eye"></i></li>
                                        <li className="p-2" data-toggle="modal" data-target=".editemodel"><i className="fas fa-pencil-alt"></i></li>
                                        <li className="p-2" data-toggle="modal" data-target=".deletemodel"><i className="fas fa-trash-alt"></i></li>
                                    </ul>
                                </td>
                            </tr>

                            <tr>
                                <td>Ms. Rossy</td>
                                <td>123459877</td>
                                <td>
                                    <ul>
                                        <li>10:00 AM - 11:00 AM</li>
                                        <li>11:00 AM - 12:00 AM</li>
                                        <li>12:00 AM - 1:00 PM</li>
                                    </ul>
                                </td>
                                <td>
                                    <ul>
                                        <li>Monday</li>
                                        <li>Tuesday</li>
                                        <li>Friday</li>
                                    </ul>
                                </td>
                                <td>12635</td>
                                <td>admin123</td>
                                <td>
                                    <ul className="d-flex options">
                                        <li className="p-2" data-toggle="modal" data-target=".viewmodel"><i className="fas fa-eye"></i></li>
                                        <li className="p-2" data-toggle="modal" data-target=".editemodel"><i className="fas fa-pencil-alt"></i></li>
                                        <li className="p-2" data-toggle="modal" data-target=".deletemodel"><i className="fas fa-trash-alt"></i></li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="slide"></div>
        </div>
    </div>
</div>
{/*

<!-- view model -->
<!-- <div className="modal fade viewmodel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">All Staff Members</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
           <form action="">
                <div className="row mt-4">
                    <div className="col-md-6">
                        <input type="text" className="form-control mb-2" placeholder="Name">
                        <input type="text" className="form-control mb-2" placeholder="Phone Number">
                        <select className="form-control mb-2">
                            <option value="" selected disabled>Timing</option>
                        </select>
                        <select className="form-control mb-2">
                            <option value="" selected disabled>Duty Days</option>
                        </select>
                    </div>
                    <div className="col-md-6 col-12">
                        <input type="text" className="form-control mb-2" placeholder="ID No.">
                        <input type="text" className="form-control mb-2" placeholder="Password">
                        <input type="text" className="form-control mb-2" placeholder="Confirm Password">
                    </div>
                </div>
            </form>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Done</button>
        </div>
        </div>
    </div>
</div> --> */}

{/* <!-- edit model --> */}
<div className="modal fade editemodel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Edit Staff Member</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
           <form action="">
                <div className="row mt-4">
                    <div className="col-md-6">
                        <input type="text" className="form-control mb-2" placeholder="Name" />
                        <input type="text" className="form-control mb-2" placeholder="Phone Number" />
                        <select className="form-control mb-2" defaultValue={'0'}>
                            <option value="0"  disabled>Timing</option>
                        </select>
                        <select className="form-control mb-2" defaultValue={'0'}>
                            <option value="0"  disabled>Duty Days</option>
                        </select>
                    </div>
                    <div className="col-md-6 col-12">
                        <input type="text" className="form-control mb-2" placeholder="ID No." />
                        <input type="text" className="form-control mb-2" placeholder="Password" />
                        <input type="text" className="form-control mb-2" placeholder="Confirm Password" />
                    </div>
                </div>
            </form>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary">Done</button>
        </div>
        </div>
    </div>
</div>

{/* <!-- Delete model --> */}
<div className="modal fade deletemodel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
        <div className="modal-header">
            <h6 className="modal-title" id="exampleModalLabel">Delete Staff Member</h6>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
           <form action="">
                <div className="row mt-2">
                    <div className="col-md-12 text-center">
                        <p><i className="fas fa-exclamation-circle modal_icon mb-2"></i></p>
                        <h4>Are You Sure?</h4>
                        <p>You Want to Delete this Record?</p>
                    </div>
                </div>
            </form>
        </div>
        <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">No</button>
            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#confirmcancelmodel">Yes</button>
        </div>
        </div>
    </div>
</div>

{/* <!-- confirm Cancel model --> */}
<div className="modal fade" id="confirmcancelmodel" tabIndex="-1" role="dialog" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h6 className="modal-title">Delete Staff Member</h6>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
               <form>
                    <div className="row mt-2">
                        <div className="col-md-12 text-center">
                            <p><i className="far fa-check-circle modal_icon2 mb-2"></i></p>
                            <h5>Staff Member Information Deleted Successfully</h5>
                        </div>
                    </div>
                </form>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
            </div>
        </div>
    </div>
</div>


            </React.Fragment>

        );
    }

}

export default ManageStaffComponent;

