import React, { Component, useState, useEffect } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import { UserContext, UserDispatchContext } from "../../../../state/UserState";
import UserDashBoard from "../../../user/UserDashBoard";
import { UserAuthService } from "../../../../service/UserAuthService";
import { connect } from "react-redux";
import { userLogin, userLoginWithOTP } from "../../../../redux/Main/actions";
import { useCookies } from 'react-cookie';
import OtpInput from "react-otp-input";
import { Grid, Typography, TextField, Box, Autocomplete, Button } from "@mui/material";

function UserOTPComponent(props) {
  const [state, setState] = useState(null);
  const [modalState, setModalState] = useState({
    dial_code: "0",
    mobile: null,
    dial_code_error: false,
    mobile_error: false,
  });

  const [otpState, setOtpState] = useState({ otp: null, otp_error: false })
  const [verify, setVerify] = useState(false)
  const [cookies, setCookie] = useCookies(['RoleID']);
  const [open, setOpen] = React.useState(false);

  const userDetails = React.useContext(UserContext);
  const setUserDetails = React.useContext(UserDispatchContext);
  const history = useHistory();

  useEffect(() => {
    props.setLoggedIn(false)
    setState(props.info);
    const { dial_code = "0", mobile = null } = props.info || {};
    const params = {
      dial_code: dial_code,
      mobile: mobile,
      password: "",
    };
    props.onLogin(params);
  }, []);

  const onChangeHandler = (e) => {
    setModalState({ ...modalState, [e.target.name]: e.target.value });
  };

  const sendOtpHandler = () => {
    const { dial_code = "0", mobile = null } = modalState || {};

    let errCount = 0;
    let errors = {};
    if (dial_code === "0") {
      errors["dial_code_error"] = true;
      errCount++;
    }

    if (!mobile) {
      errors["mobile_error"] = true;
      errCount++;
    }

    if (errCount > 0) {
      setModalState({ ...modalState, ...errors });
      return;
    }

    const params = {
      dial_code: dial_code,
      mobile: mobile,
      password: "",
    };


    props.onLogin(params);
  };


  const verifyHandler = () => {
    const { otp = null } = otpState || {};
    if (!otp) {
      setOtpState({ ...otpState, otp_error: true })
      return;
    }
    const params = {
      otp: otp,

    };
    setVerify(true)

    props.LoginWithOTP(params)

  }

  useEffect(async () => {
    if (verify && props.loginWithOtpResponse) {
      if (props.RoleID) {
        await setCookie('RoleID', props.RoleID)
      }

      history.push("/user/dashboard");

      setVerify(false)
    }
  }, [props.loginWithOtpResponse, props.RoleID, verify])

  const { dial_code = null, mobile = null } = state || {};
  const { countries = [] } = props || {};

  return (
    <>
      <section className="login_area pt-md-5 pt-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 d-flex align-items-end justify-content-center order-md-1 order-2">
              <img
                src={
                  require("../../../../assets/images/home/doctors.png").default
                }
                alt="1"
              />
            </div>

            <div className="col-md-6 text-center mt-md-0 order-md-2 order-1">
              <div className="login">
                <h4>Verify 6 digit OTP Code</h4>
                <p>
                  We have sent you an OTP on{" "}
                  <span className="fw-bold">{`+${dial_code} ${mobile}`}</span>{" "}
                  <Link
                    to="#"
                    className="btn btn-primary btn-sm"
                    data-toggle="modal"
                    data-target="#editPhone"
                  >
                    {" "}
                    <i className="far fa-edit"></i> Edit
                  </Link>
                </p>
                <form>
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex align-items-center justify-content-center">
                        <OtpInput
                          value={otpState.otp}
                          onChange={(otp) => setOtpState({ ...otpState, otp: otp, otp_error: false })}
                          numInputs={4}
                          shouldAutoFocus={true}
                          inputStyle={{
                            border: "1px solid #CFD3DB",
                            borderRadius: "8px",
                            width: "54px",
                            height: "54px",
                            fontSize: "12px",
                            color: "#000",
                            fontWeight: "400",
                            caretColor: "blue"
                          }}
                          separator={<span style={{ width: "28px" }}></span>}
                        />

                      </div>
                      {otpState?.otp_error &&

                        <div className="invalid-feedback small d-block">
                          please enter valid otp.
                        </div>
                      }
                    </div>
                    <br />
                    <div className="col-12 mt-2">
                      <p className="resend">
                        Still not received OTP?
                        <span className="resend">
                          <button type="button" class="btn btn-link" onClick={sendOtpHandler}>click here to resend OTP</button>
                        </span>
                      </p>
                      <button type="button" className="btn btn-primary w-100" onClick={verifyHandler}>Verify</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* // edit phone number modal */}
      <div
        className="modal fade"
        id="editPhone"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header p-2">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Phone
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={12} sm={12} md={4}>
                  <Autocomplete
                    id="country-select-demo"
                    options={countries}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      if (value) {
                        const stateCopy = { ...state }
                        stateCopy['dial_code'] = value.phonecode;
                        setState(stateCopy)
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a country"
                        size='small'
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    placeholder='Mobile Number'
                    value={`+ ${modalState.dial_code}`}
                    name='mobile'
                    size='small'
                    fullWidth
                    disabled

                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    placeholder='Mobile Number'
                    value={modalState.mobile}
                    name='mobile'
                    fullWidth size='small'
                    error={modalState.mobile_error}
                    onChange={onChangeHandler}

                  />
                </Grid>
              </Grid>
            </div>
            <div className="modal-footer p-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={sendOtpHandler}
              >
                Send OTP
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  onLogin: (params) => dispatch(userLogin(params)),
  LoginWithOTP: (params) => dispatch(userLoginWithOTP(params)),
});

const mapStateToProps = (state) => ({
  loginResponse: state.mainReducer?.loginReducer?.response || {},
  loginWithOtpResponse: state.mainReducer?.loginWithOTPReducer?.response?.status === 200 || false,
  RoleID: state.mainReducer?.loginWithOTPReducer?.response?.data[0].roleId || null,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserOTPComponent)
);
