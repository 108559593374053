import axios from "axios";

export const API = {
    userLogin: 'api/UserAuthentication/UserLogin',
    countryCodes: 'api/UserAuthentication/CountryCode',
    loginWithOtp: 'api/UserAuthentication/LoginOTP',
    hospitalLogin: 'api/UserAuthentication/hospitallogin',
    userRegistration: 'api/UserAuthentication/registerUser',
    AddUserDetails: 'api/UserAuthentication/AddUserDetails',
    userVerifyOtp: 'api/UserAuthentication/VerifyOTP',
    forgotPassword: 'api/UserAuthentication/ForgotPassword',
    verifyOTPForPswd: 'api/UserAuthentication/VerifyOTP_forgotpassword',
    changePswd: 'api/UserAuthentication/change_password',
    hospitalRegistration: 'api/UserAuthentication/registerHospital',


    // HOSPITAL 
    hospitalDetail:"api/hospital/HospitalDetails/hospitalDetail",
    hospital_AccountVerification:"api/hospital/HospitalDetails/hospital_AccountVerification",
    hospitalownersAccountSetting:'api/hospital/HospitalDetails/ownersAccountSetting',
    getHospitalDocuments:'api/hospital/HospitalDetails/hospitalDocuments',
    Country_list:'api/hospital/HospitalDetails/Country_list',
    getStateList:'api/hospital/HospitalDetails/state',
    getCityList:"api/hospital/HospitalDetails/city",
    staffCategoryList:"api/hospital/HospitalDetails/staff_categories_list",
    addStaff:'api/hospital/HospitalDetails/addStaff',
    staffList:"api/hospital/HospitalDetails/getStaffList",
    editStaff:"api/hospital/HospitalDetails/editStaff",
    deleteStaff:"api/hospital/HospitalDetails/deleteStaff",
    degree_list:"api/hospital/HospitalDetails/degree_list",
    collage_list:"api/hospital/HospitalDetails/collage_list",
    doctor_specialiazation_list:"api/hospital/HospitalDetails/doctor_specialiazation_list",
    addDoctor:"api/hospital/HospitalDetails/addDoctor",
    getDoctorsList:"api/hospital/HospitalDetails/getDoctorsList",
    deleteDutyTiming:"api/hospital/HospitalDetails/deleteDutyTiming",
    editDoctor:"api/hospital/HospitalDetails/editDoctor",
    deleteDoctorDocument:"api/hospital/HospitalDetails/deleteDoctorDocument",
    addHospitalLocation:"api/hospital/HospitalDetails/addHospitalLocation",
    getLocationList:"api/hospital/HospitalDetails/getLocationList",
    editHospitalLocation:"api/hospital/HospitalDetails/editHospitalLocation",
    
    // USER ACCESS
    addUserForAccess:"api/hospital/HospitalDetails/addUserForAccess",
    EditUserForAccess:"api/hospital/HospitalDetails/EditUserForAccess",
    role_list:"api/hospital/HospitalDetails/role_list",
    menus_list:"api/hospital/HospitalDetails/menus_list",
    deleteUserForAccess:"api/hospital/HospitalDetails/deleteUserForAccess",
    getUserAccessManagementList:"api/hospital/HospitalDetails/getUserAccessManagementList",
    
    getAllAppointments:"api/hospital/HospitalDetails/getAllAppointments",
    getAppointmentCounts:"api/hospital/HospitalDetails/getAppointmentCounts",
    getStaffListNew:"api/hospital/HospitalDetails/getStaffListNew",
    insurance_list:"api/hospital/HospitalDetails/insurance_list",
    bloodgroup_list:"api/hospital/HospitalDetails/bloodgroup_list",
    addPatientProfile:"api/hospital/HospitalDetails/addPatientProfile",
    getSlotTypes:"api/hospital/HospitalDetails/getSlotTypes",
    bookAppointment:"api/hospital/HospitalDetails/bookAppointment",
    getPatientAccountProfile:"api/hospital/HospitalDetails/getPatientAccountProfile",
    saveAppointmentData:"api/hospital/HospitalDetails/saveAppointmentData",
    patientInvoice:"api/hospital/HospitalDetails/patientInvoice",
    patientPrescription:"api/hospital/HospitalDetails/patientPrescription",
    startAppointment:"api/hospital/HospitalDetails/startAppointment",
    allPatientInvoiceReport:"api/hospital/HospitalDetails/allPatientInvoiceReport",
    getAppointmentHistory:"api/hospital/HospitalDetails/getAppointmentHistory",
    getPatientList:"api/hospital/HospitalDetails/getPatientList",
    patientMedicalHistory:"api/hospital/HospitalDetails/patientMedicalHistory",
    getPatientMedicalProfile:"api/patient/PatientDetails/getPatientMedicalProfile",
    


    get_all_hospital_with_location:"/patient/PatientDetails/get_all_hospital_with_location",
    get_all_doctor_by_location:"/patient/PatientDetails/get_all_doctor_by_location",
}

const headers = {
    'content-type': 'application/x-www-form-urlencoded',
}

const baseUrl = 'https://vedas.website/hospitalapi/';
// const baseUrl = 'https://vedas.website/krupaltesting/aajivanapitest/';

export const getFullUrl = url => baseUrl+ url;
export const config = {
    GET: url =>{
        return axios.get(url, headers);
    },
    GET_WITH_HEADER: url =>{
        return axios.get(url, headers)
    },
    POST: (url, params)=> {
        return axios.post(url, params, {headers:headers})
    }
}