import React, { Component, useState } from 'react';

import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const AllInvoiceResults=(props)=>{
    let history = useHistory();
   //const {t,setT} =useState('');


    const bookAppointment =(doctorId,patientId) =>{
        console.log(doctorId)
        console.log(patientId);
        history.push('/user/book/appointment');
    }

    return (
        <div className="col-md-6 mb-2">
            <Link to={{
                pathname:'/user/invoice',
                state: {
                doctorId: props.invoice.id,
                patientId: props.invoice.patient.id
            }
            }}  key={props.invoice.id} >
                    <div className="card">
                        <div className="dashboard_box d-flex justify-content-between align-items-center box_bg">
                            <div className="d-flex align-items-center">
                                <div className="img_block text-center mr-2">
                                    <img src={require('../../../assets/images/home/doctor.png').default} width="80" alt="User Image"/>
                                </div>
                                <div className="docinfo">
                                    <p className="title_name mb-md-0 mb-2"><strong> {props.invoice.name}</strong> (<span className="font_13">Invoice Number:{props.invoice.id}</span>)</p>
                                    <p className="sub_font mb-md-0 mb-1"><i className="fa fa-graduation-cap"></i> Dentist</p>
                                    <p className="sub_font mb-md-0 mb-1"><i className="fa fa-map-marker mr-2"></i>  {props.invoice.hospital.name}</p>
                                    <p className="sub_font mb-md-0 mb-1"><i className="fa fa-mobile-alt mr-2"></i> {props.invoice.phone}</p>
                                </div>
                            </div>

                            <div className="">
                                <p className="title_name"><strong>{props.invoice.appointmentDay}</strong></p>
                                <p className="title_name"><i className="far fa-clock"></i> <strong>{props.invoice.appointmentTime}</strong></p>
                                <p className="title_name"><i className="far fa-hospital"></i> <strong>{props.invoice.address}</strong></p>
                                <p className="title_name" href="#"><strong>{props.invoice.patient.name}</strong></p>
                            </div>
                        </div>
                    </div>
            </Link>
        </div>

    );

}

export default AllInvoiceResults;