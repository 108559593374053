import React, { Component } from 'react';
import * as $ from 'jquery';
import { Dashboard } from '../../../mock/hospital/Dashboard';
import { Link } from 'react-router-dom';
import { LiveAppointment } from '../../../mock/hospital/LiveAppointment';
import AllAppointmentList from '../../../mock/hospital/Allappointments';
import HospitalFilterComponent from  '../../Search/SearchFilter/HospitalFilter';

class AllAppointmentComponent extends Component {



    constructor(props) {
        super(props);
        this.state = {
            allAppointmentList: AllAppointmentList
        };
    }



    componentDidMount() {


    }





    render() {
        const userDetails = this.context;
        return (
            <React.Fragment>
                <div className="">
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-md-12 d-none d-md-block">
                            <HospitalFilterComponent onSelectFilter={this.selectedFilter} />
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12">
                        <div className="scroll_allappointment">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-row">
                                        <div className="d-flex align-items-center">
                                            <div className="img_block mr-2">
                                                <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                            </div>
                                            <div className="docinfo">
                                                <Link className="m_font_10" to="/hospital/liveappointmentid123">
                                                    <strong>Ramesh Sharma</strong>
                                                    <span className="ml-3"><strong>Appointment ID Number : </strong>1</span>
                                                </Link>
                                                <br />
                                                <Link className="m_font_10" to="tel:12345789">
                                                    <i className="fa fa-mobile-alt" ></i>
                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                </Link>
                                                <p className="m_font_10">
                                                    <i className="fa fa-map-marker"></i>
                                                    <span style={{ paddingLeft: '4px' }}>Patient Address</span>
                                                </p>
                                                <Link className="btn btn-primary btn-sm mr-2" to="./liveappointmentid123" role="button">Start</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">View Details</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Reschedule</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Prescription</Link>
                                                <Link className="btn btn-primary btn-sm" to="#" role="button">Invoice</Link>
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 12-09-2022</p>
                                            <p className="mb-1 small"><i className="far fa-clock"></i> 1:30pm</p>
                                            <p className='fw-bold small'><i className="far fa-clock"></i> Slot time-online</p>
                                            <p className='fw-bold small'><i className="fas fa-user-md"></i> Dr. Wilson</p>
                                            <p className='fw-bold small'><i className="fa fa-map-marker"></i> Apollo Delhi</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-row">
                                        <div className="d-flex align-items-center">
                                            <div className="img_block mr-2">
                                                <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                            </div>
                                            <div className="docinfo">
                                                <Link className="m_font_10" to="/hospital/liveappointmentid123">
                                                    <strong>Suresh Varma</strong>
                                                    <span className="ml-3"><strong>Appointment ID Number : </strong>4</span>
                                                </Link>
                                                <br />
                                                <Link className="m_font_10" to="tel:12345789">
                                                    <i className="fa fa-mobile-alt" ></i>
                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                </Link>
                                                <p className="m_font_10">
                                                    <i className="fa fa-map-marker"></i>
                                                    <span style={{ paddingLeft: '4px' }}>Apollo Mumbai</span>
                                                </p>
                                                <Link className="btn btn-secondary disabled mr-2 btn-sm" to="#" role="button">Completed</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">View Details</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Reschedule</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Prescription</Link>
                                                <Link className="btn btn-primary btn-sm" to="#" role="button">Invoice</Link>
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 12-09-2022</p>
                                            <p className="mb-1 small"><i className="far fa-clock"></i> 1:30pm</p>
                                            <p className='fw-bold small'><i className="far fa-clock"></i> General time-Offline</p>
                                            <p className='fw-bold small'><i className="fas fa-user-md"></i> Dr. Jack</p>
                                            <p className='fw-bold small'><i className="fa fa-map-marker"></i> Apollo Mumbai</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-row">
                                        <div className="d-flex align-items-center">
                                            <div className="img_block mr-2">
                                                <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                            </div>
                                            <div className="docinfo">
                                                <Link className="m_font_10" to="/hospital/liveappointmentid123">
                                                    <strong>Mukesh Singh</strong>
                                                    <span className="ml-3"><strong>Appointment ID Number : </strong>6</span>
                                                </Link>
                                                <br />
                                                <Link className="m_font_10" to="tel:12345789">
                                                    <i className="fa fa-mobile-alt" ></i>
                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                </Link>
                                                <p className="m_font_10">
                                                    <i className="fa fa-map-marker"></i>
                                                    <span style={{ paddingLeft: '4px' }}>Apollo Mumbai</span>
                                                </p>
                                                <Link className="btn btn-secondary disabled mr-2 btn-sm" to="#" role="button">Completed</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">View Details</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Reschedule</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Prescription</Link>
                                                <Link className="btn btn-primary btn-sm" to="#" role="button">Invoice</Link>
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 8-09-2022</p>
                                            <p className="mb-1 small"><i className="far fa-clock"></i> 4:30pm</p>
                                            <p className='fw-bold small'><i className="far fa-clock"></i> General time-Online</p>
                                            <p className='fw-bold small'><i className="fas fa-user-md"></i> Dr. Wilson</p>
                                            <p className='fw-bold small'><i className="fa fa-map-marker"></i> Apollo Delhi</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-row">
                                        <div className="d-flex align-items-center">
                                            <div className="img_block mr-2">
                                                <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                            </div>
                                            <div className="docinfo">
                                                <Link className="m_font_10" to="/hospital/liveappointmentid123">
                                                    <strong>Lokesh Kumar</strong>
                                                    <span className="ml-3"><strong>Appointment ID Number : </strong>7</span>
                                                </Link>
                                                <br />
                                                <Link className="m_font_10" to="tel:12345789">
                                                    <i className="fa fa-mobile-alt" ></i>
                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                </Link>
                                                <p className="m_font_10">
                                                    <i className="fa fa-map-marker"></i>
                                                    <span style={{ paddingLeft: '4px' }}>Apollo Delhi</span>
                                                </p>
                                                <Link className="btn btn-primary btn-sm mr-2" to="./liveappointmentid123" role="button">Start</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">View Details</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Reschedule</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Prescription</Link>
                                                <Link className="btn btn-primary btn-sm" to="#" role="button">Invoice</Link>
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 13-09-2022</p>
                                            <p className="mb-1 small"><i className="far fa-clock"></i> 4:30pm</p>
                                            <p className='fw-bold small'><i className="far fa-clock"></i> Slot time-Offline</p>
                                            <p className='fw-bold small'><i className="fas fa-user-md"></i> Dr. Jack</p>
                                            <p className='fw-bold small'><i className="fa fa-map-marker"></i> Apollo Mumbai</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-row">
                                        <div className="d-flex align-items-center">
                                            <div className="img_block mr-2">
                                                <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                            </div>
                                            <div className="docinfo">
                                                <Link className="m_font_10" to="/hospital/liveappointmentid123">
                                                    <strong>Lokesh Kumar</strong>
                                                    <span className="ml-3"><strong>Appointment ID Number : </strong>112</span>
                                                </Link>
                                                <br />
                                                <Link className="m_font_10" to="tel:12345789">
                                                    <i className="fa fa-mobile-alt" ></i>
                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                </Link>
                                                <p className="m_font_10">
                                                    <i className="fa fa-map-marker"></i>
                                                    <span style={{ paddingLeft: '4px' }}>Apollo Mumbai</span>
                                                </p>
                                                <Link className="btn btn-primary btn-sm mr-2" to="./liveappointmentid123" role="button">Start</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">View Details</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Reschedule</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Prescription</Link>
                                                <Link className="btn btn-primary btn-sm" to="#" role="button">Invoice</Link>
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 11-09-2022</p>
                                            <p className="mb-1 small"><i className="far fa-clock"></i> 11:30am</p>
                                            <p className='fw-bold small'><i className="far fa-clock"></i> Slot time-Online</p>
                                            <p className='fw-bold small'><i className="fas fa-user-md"></i> Dr. Jack</p>
                                            <p className='fw-bold small'><i className="fa fa-map-marker"></i> Apollo Mumbai</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="d-flex justify-content-between align-items-center p-2 card flex-row">
                                        <div className="d-flex align-items-center">
                                            <div className="img_block mr-2">
                                                <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
                                            </div>
                                            <div className="docinfo">
                                                <Link className="m_font_10" to="/hospital/liveappointmentid123">
                                                    <strong>Mukesh Singh</strong>
                                                    <span className="ml-3"><strong>Appointment ID Number : </strong>6</span>
                                                </Link>
                                                <br />
                                                <Link className="m_font_10" to="tel:12345789">
                                                    <i className="fa fa-mobile-alt" ></i>
                                                    <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                </Link>
                                                <p className="m_font_10">
                                                    <i className="fa fa-map-marker"></i>
                                                    <span style={{ paddingLeft: '4px' }}>Apollo Delhi</span>
                                                </p>
                                                <Link className="btn btn-success disabled btn-sm mr-2" to="#" role="button">Ongoing</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">View Details</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Reschedule</Link>
                                                <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Prescription</Link>
                                                <Link className="btn btn-primary btn-sm" to="#" role="button">Invoice</Link>
                                            </div>
                                        </div>
                                        <div className="text-left">
                                            <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 11-09-2022</p>
                                            <p className="mb-1 small"><i className="far fa-clock"></i> 2:00pm</p>
                                            <p className='fw-bold small'><i className="far fa-clock"></i> General time-Online</p>
                                            <p className='fw-bold small'><i className="fas fa-user-md"></i> Dr. Wilson</p>
                                            <p className='fw-bold small'><i className="fa fa-map-marker"></i> Apollo Delhi</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default AllAppointmentComponent;