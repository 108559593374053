const AppointmentFilter =[
    {
        id:0,
        name:'Select Appointment '
    },
    {
        id:1,
        name:"Today's Appointment"
    },
    {
        id:2,
        name:"Tomorrow's Appointment"
    },
    {
        id:3,
        name:"Yesterday's Appointment"
    },
    {
        id:4,
        name:"This Week's Appointment"
    },
    {
        id:5,
        name:"Last Month's Appointment"
    },
    {
        id:6,
        name:"Last 6 Month's Appointment"
    },
    {
        id:7,
        name:"Last Year's Appointment"
    },
    {
        id:8,
        name:"Next Week's Appointment"
    },
    {
        id:9,
        name:"Next Month's Appointment"
    },
    {
        id:10,
        name:"Next 6 Month's Appointment"
    },
    {
        id:11,
        name:"Next Year's Appointment"
    },
    {
        id:12,
        name:"Till Date (All) Appointment"
    }
];

export default AppointmentFilter;