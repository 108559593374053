import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import TextInput from "src/common/TextInput";

const Diagnosis = (props) => {

  return (
    <Box>

      <TextInput
        name={'diagnosis'}
        value={props.diagnosis || ''}
        onChange={(e) => props.setDiagnosis(e.target.value)}
        disabled={false}
        error={!props.diagnosis}
        placeholder='Diagnosis'
      />
    </Box>
  )
}


export default Diagnosis