import React, { Component } from 'react';
import UserDashBoardResultComponent from '../UserDashBoardResult';


class MyAppointmentListComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
           // userAppointment:UserAppointment
        }

    }

    render() {
        return (
            <React.Fragment>
            <h6 className="dashboard_box mb-md-0 mb-0"><strong>My Appointments</strong></h6>
            <div className="scroll_upcoming">

                {(this.props.doctorList !== undefined && this.props.doctorList !== null && this.props.doctorList.length > 0 ) ?
               this.props.doctorList.map((doctor, i) => {
                   return <UserDashBoardResultComponent key={i} doctor={doctor} ></UserDashBoardResultComponent>
               }) : ''} 
            </div>
         
    </React.Fragment>
)
    }

}


export default MyAppointmentListComponent;
