import React, { Component } from 'react';
import { Link   } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"

class Appointmentdetail extends React.Component{

	componentDidMount() {
        document.title = "Appointment detail"
		$('#allAppointment').DataTable({
			dom: '<"top"Bflp>rt<"bottom"ip>',
            pageLength: 5,
            buttons: ['copy', 'csv', 'print', 'excel']
		});
	}

	render(){
		return(
			<>
				<div className="container-fluid mt-3">
                    <div className="row">
                        <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar dct-dashbd-lft">
                            <div className="card widget-profile pat-widget-profile mb-3">
                                <div className="card-body">
                                    <div className="pro-widget-content">
                                        <div className="profile-info-widget">
                                            <a href="#" className="booking-doc-img">
                                                <img src={require('../../../assets/images/doctor.jpg').default} width="80" alt="User Image"/>
                                            </a>
                                            <div className="profile-det-info">
                                                <h3>Dr. Jack</h3>
                                                <div className="patient-details">
                                                    <h5><b>BDS, MDS - Oral & Maxillofacial Surgery</b></h5>
                                                    <h5 className="mb-0"><i className="fas fa-graduation-cap"></i> Dentist</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="patient-info">
                                        <ul>
                                            <li>Hospital <span>Apollo Delhi</span></li>
                                            <li>Address <span>Delhi</span></li>
                                            <li>Phone <span>+1 1236547895</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-8 col-xl-9 dct-appoinment">
                            <div className="card p-2">
                                <div className="row">
                                    <div className="col-md-12">
                                        <nav className="user-tabs mb-3">
                                            <ul className="nav nav-tabs nav-tabs-bottom">
                                                <li className="nav-item">
                                                    <Link className="nav-link active rounded-left" to="#patientProfile" data-bs-toggle="tab">Patient Profile</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to="#diagnosis" data-bs-toggle="tab">Diagnosis</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link" to="#prescription" data-bs-toggle="tab">Prescription</Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link className="nav-link rounded-end" to="#invoice" data-bs-toggle="tab">Invoice</Link>
                                                </li>
                                            </ul>
                                        </nav>

                                        <div className="tab-content pt-0">
                                            <div role="tabpanel" id="patientProfile" className="tab-pane fade show active">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <ul className='liveappointmentDetail'>
                                                            <li>
                                                                <img src={require('../../../assets/images/profile_circle-1.png').default} width="150" alt="User" />
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="col-md-5">
                                                        <ul className='liveappointmentDetail'>
                                                            <li className="d-flex">
                                                                <span><strong>Appintment Date : </strong></span>
                                                                <span>25/03/2019</span>
                                                            </li>
                                                            <li className="d-flex">
                                                                <span><strong>Doctor Name : </strong></span>
                                                                <span>Dr. xyz</span>
                                                            </li>
                                                            <li className="d-flex">
                                                                <span><strong>Phone : </strong></span>
                                                                <span>3226215144</span>
                                                            </li>
                                                            <li className="d-flex">
                                                                <span><strong>DOB : </strong></span>
                                                                <span>13/05/1981</span>
                                                            </li>
                                                            <li className="d-flex">
                                                                <span><strong>Address : </strong></span>
                                                                <span>Lorem ipsum dolor sit amet</span>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="col-md-5">
                                                        <ul className='liveappointmentDetail'>
                                                            <li className="d-flex">
                                                                <span><strong>Time Slot : </strong></span>
                                                                <span>8:30pm</span>
                                                            </li>
                                                            <li className="d-flex">
                                                                <span><strong>Blood Group : </strong></span>
                                                                <span>A+</span>
                                                            </li>
                                                            <li className="d-flex">
                                                                <span><strong>Police Case : </strong></span>
                                                                <span>No</span>
                                                            </li>
                                                            <li className="d-flex">
                                                                <span><strong>Urgency Type : </strong></span>
                                                                <span>Normal</span>
                                                            </li>
                                                            <li className="d-flex">
                                                                <span><strong>Case Type : </strong></span>
                                                                <span>Old</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div role="tabpanel" id="diagnosis" className="tab-pane fade">
                                                <h5 className='text-primary'><strong>Diagnosis</strong></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi, deserunt? Iusto animi consequatur eligendi nihil amet laborum rem dolorem eos quis neque quia explicabo ad cupiditate nisi modi, maxime quos.</p>
                                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam laudantium ratione quo facilis. Repudiandae nostrum, labore dolor assumenda officia excepturi dolorem reiciendis neque nulla incidunt. Officia voluptate laudantium qui vel.</p>
                                            </div>

                                            <div role="tabpanel" id="prescription" className="tab-pane fade">
                                                <div className="table-responsive">
                                                    <table class="table text-center table_pad">
                                                        <thead>
                                                            <tr>
                                                                <th class="">Sr No.</th>
                                                                <th class="">Medicine</th>
                                                                <th class="">Quantity</th>
                                                                <th class="">Course*</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>Dolo 400</td>
                                                                <td>3</td>
                                                                <td>1-0-0-1</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>Dolo 200</td>
                                                                <td>1</td>
                                                                <td>1-0-0-1</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>AAA Dolo 400</td>
                                                                <td>4</td>
                                                                <td>1-0-0-1</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                            <div role="tabpanel" id="invoice" className="tab-pane fade">
                                                <div className="table-responsive">
                                                    <table class="table text-center table_pad">
                                                        <thead>
                                                            <tr>
                                                                <th>Sr No.</th>
                                                                <th>Description</th>
                                                                <th>Unit Cost</th>
                                                                <th>Quantity</th>
                                                                <th>Amount($)</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1</td>
                                                                <td>Dolo 400</td>
                                                                <td>3</td>
                                                                <td>50</td>
                                                                <td>150</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2</td>
                                                                <td>Dolo 200</td>
                                                                <td>1</td>
                                                                <td>50</td>
                                                                <td>50</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3</td>
                                                                <td>AAA Dolo 400</td>
                                                                <td>4</td>
                                                                <td>50</td>
                                                                <td>200</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td class="small"><b>Sub Total</b></td>
                                                                <td class="small">1310</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td class="small"><b>Discount</b></td>
                                                                <td class="small">10</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td class="small"><b>Tax</b></td>
                                                                <td class="small">100</td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td><b>Total</b></td>
                                                                <td class="fw-bold text-primary">1400</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</>
		)
	}
}


export default Appointmentdetail