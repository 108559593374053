import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import { connect } from "react-redux";

import {
    getAllAppointmentsAction,
    startAppointmentAction
} from 'src/redux/Main/actions';
import { Box, Grid } from "@mui/material";
import { Link } from 'react-router-dom';
import LiveAppointments from "./liveAppointment/LiveAppointments";


const AllAppointments = (props) => {
    const [cookies, setCookie] = useCookies(['authtoken']);
    const [state, setState] = useState({ state: {} })


    const { authtoken = null } = cookies
    useEffect(() => {
        props.getAllAppointmentsAction({ authToken: authtoken })
    }, [])



    const startAppointment = (data) => {
        console.log("startAppointment", data)

        if (data.apt_id) {

            const payload =
            {
                "authToken": authtoken,
                "status": "Ongoing",
                "start_time": getTime(),
                "appointmen_id": data.apt_id

            }
            props.startAppointmentAction(payload)
        }

    }

    const getTime = () => {
        return `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getMilliseconds()}`
    }


    const { appointments: { offline_data = [], online_data = [] } } = props

    return (
        <Box style={{ margin: 30 }}>
            {offline_data?.map((item, index) => (
                <Grid container spacing={3} key={item.appointmentId}>
                    <Grid item xs={6} sm={12} md={6}>
                        <div className="d-flex justify-content-between align-items-center p-2 card flex-row">
                            <div className="d-flex align-items-center">
                                <div className="img_block mr-2">
                                    <img className='mr-2' src={item.profile_image} width="80" alt="" />
                                </div>
                                <div className="docinfo">
                                    <Link className="m_font_10"
                                        to={{
                                            pathname: "/hospital/liveappointments",
                                            state: item // your data array of objects
                                        }}
                                    >
                                        <strong>{item.name}</strong>
                                        <span className="ml-3"><strong>Appointment ID Number : </strong>{item.appointmentId}</span>
                                    </Link>
                                    <br />
                                    <Link className="m_font_10" to="tel:12345789">
                                        <i className="fa fa-mobile-alt" ></i>
                                        <span style={{ paddingLeft: '4px' }}>{item.phone}</span>
                                    </Link>
                                    <p className="m_font_10">
                                        <i className="fa fa-map-marker"></i>
                                        <span style={{ paddingLeft: '4px' }}>Patient Address</span>
                                    </p>
                                    <Link className="btn btn-primary btn-sm mr-2"
                                        // onClick={() => startAppointment(item)}
                                        to={{
                                            pathname: "/hospital/liveappointments",
                                            state: item // your data array of objects
                                        }}
                                        role="button">Start</Link>
                                    <button type='button' className='btn btn-primary btn-sm m_font_10 mr-2' data-toggle="modal" data-target="#viewDetails"
                                        onClick={() => setState({ state: item })}
                                    >View Details</button>
                                    {/* <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">View Details</Link> */}
                                    <Link className="btn btn-primary btn-sm mr-2" to={{
                                        pathname: "/hospital/newappointment",
                                    }} role="button">Reschedule</Link>
                                    <Link className="btn btn-primary btn-sm mr-2"

                                        to={{
                                            pathname: "/user/prescription",
                                            state: item // your data array of objects
                                        }}
                                        role="button"

                                    >Prescription</Link>
                                    <Link className="btn btn-primary btn-sm"
                                        role="button"

                                        to={{
                                            pathname: "/user/invoice",
                                            state: item // your data array of objects
                                        }}

                                    >Invoice</Link>
                                </div>
                            </div>
                            <div className="text-left">
                                <p className="mb-1 small"><i className="fas fa-calendar-alt"></i>{item.appointmentDate}</p>
                                <p className="mb-1 small"><i className="far fa-clock"></i> {item.slotTime}</p>
                                <p className='fw-bold small'><i className="far fa-clock"></i> Slot time-online</p>
                                <p className='fw-bold small'><i className="fas fa-user-md"></i>{item.doctor_name}</p>
                                <p className='fw-bold small'><i className="fa fa-map-marker"></i> {item.hospital_loc}</p>
                            </div>
                        </div>

                    </Grid>
                </Grid>
            ))}
            {online_data?.map((item, index) => (
                <Grid container spacing={3} key={item.appointmentId}>
                    <Grid item xs={6} sm={12} md={6}>
                        <div className="d-flex justify-content-between align-items-center p-2 card flex-row">
                            <div className="d-flex align-items-center">
                                <div className="img_block mr-2">
                                    <img className='mr-2' src={require('../../assets/images/profile_circle-1.png').default} width="80" alt="" />
                                </div>
                                <div className="docinfo">
                                    <Link className="m_font_10"
                                        to={{
                                            pathname: "/hospital/liveappointments",
                                            state: item // your data array of objects
                                        }}
                                    //   to="/hospital/liveappointmentid123"
                                    >
                                        <strong>{item.name}</strong>
                                        <span className="ml-3"><strong>Appointment ID Number : </strong>{item.appointmentId}</span>
                                    </Link>
                                    <br />
                                    <Link className="m_font_10" to="tel:12345789">
                                        <i className="fa fa-mobile-alt" ></i>
                                        <span style={{ paddingLeft: '4px' }}>{item.phone}</span>
                                    </Link>
                                    <p className="m_font_10">
                                        <i className="fa fa-map-marker"></i>
                                        <span style={{ paddingLeft: '4px' }}>Patient Address</span>
                                    </p>
                                    <Link className="btn btn-primary btn-sm mr-2"
                                        to={{
                                            pathname: "/hospital/liveappointments",
                                            state: item // your data array of objects
                                        }}
                                        role="button">Start</Link>
                                    <button type='button' className='btn btn-primary btn-sm m_font_10 mr-2' data-toggle="modal" data-target="#viewDetails"
                                        onClick={() => setState({ state: item })}
                                    >View Details</button>
                                    <Link className="btn btn-primary btn-sm mr-2" to={{
                                        pathname: "/hospital/newappointment",
                                    }} role="button">Reschedule</Link>
                                    <Link className="btn btn-primary btn-sm mr-2"

                                        to={{
                                            pathname: "/user/prescription",
                                            state: item // your data array of objects
                                        }}
                                        role="button"

                                    >Prescription</Link>
                                    <Link className="btn btn-primary btn-sm"
                                        role="button"

                                        to={{
                                            pathname: "/user/invoice",
                                            state: item // your data array of objects
                                        }}

                                    >Invoice</Link>
                                </div>
                            </div>
                            <div className="text-left">
                                <p className="mb-1 small"><i className="fas fa-calendar-alt"></i>{item.appointmentDate}</p>
                                <p className="mb-1 small"><i className="far fa-clock"></i> {item.slotTime}</p>
                                <p className='fw-bold small'><i className="far fa-clock"></i> Slot time-online</p>
                                <p className='fw-bold small'><i className="fas fa-user-md"></i>{item.doctor_name}</p>
                                <p className='fw-bold small'><i className="fa fa-map-marker"></i> {item.hospital_loc}</p>
                            </div>
                        </div>

                    </Grid>
                </Grid>
            ))}

            {/* View medical history modal */}
            <div class="modal fade" id="viewDetails" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">View Details</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <LiveAppointments location={state} />

                        </div>
                    </div>
                </div>
            </div>
        </Box>
    )
}




const mapDispatchToProps = dispatch => ({
    getAllAppointmentsAction: (params) => dispatch(getAllAppointmentsAction(params)),
    startAppointmentAction: (params) => dispatch(startAppointmentAction(params)),

})

const mapStateToProps = state => {
    return {
        appointments:
            state.mainReducer?.getAllAppointmentsReducer?.response?.data || {},


    }

}

export default connect(mapStateToProps, mapDispatchToProps)(AllAppointments);