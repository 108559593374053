import React, { Component, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';
import DayList from '../../../mock/dropdown/DayList';
import HospitalList from '../../../mock/dropdown/HospitalList';
import LocationList from '../../../mock/dropdown/LocationList';
import { connect } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useCookies } from 'react-cookie';

import { getAllHospitalWithLocationAction, getAllDoctorByLocationAction } from 'src/redux/Main/actions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const checkedIcon = <CheckBoxIcon fontSize="small" />;

const HospitalFilterComponent = (props) => {
    const [cookies, setCookie] = useCookies(['authtoken']);

    const { authtoken = null } = cookies

    useEffect(() => {
        props.getAllHospitalWithLocationAction({ authToken: authtoken })
    }, [])

    return (
        <>
            <div className="filter">
                <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={[{ title: "lokesh", name: "arava" }]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.title}
                        </li>
                    )}
                    style={{ width: 250 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Hospital" size='small' placeholder="Favorites" />
                    )}

                />
                <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={[{ title: "lokesh", name: "arava" }]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.title}
                        </li>
                    )}
                    style={{ width: 250 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Doctor" size='small' placeholder="Favorites" />
                    )}

                />
                <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={[{ title: "lokesh", name: "arava" }]}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.title}
                        </li>
                    )}
                    style={{ width: 250 }}
                    renderInput={(params) => (
                        <TextField {...params} label="Select Appointment type" size='small' placeholder="Favorites" />
                    )}

                />
                <div>Select Date</div>
            </div>

        </>
    )
}

const mapDispatchToProps = dispatch => ({
    getAllHospitalWithLocationAction: (params) => dispatch(getAllHospitalWithLocationAction(params)),
    getAllDoctorByLocationAction: (params) => dispatch(getAllDoctorByLocationAction(params)),

})

const mapStateToProps = state => {
    return {
        patientInvoiceReport:
            state.mainReducer?.getAllHospitalWithLocationReducer?.response?.data || [],

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(HospitalFilterComponent)


// class HospitalFilterComponent extends Component{
//     hospitalList=[];
//     locationList=[];
//     dayList=[];
//     constructor(props) {
// 		super(props);
// 		this.state = {
//             searchFilter:{
//                 hospital:0,
//                 location:0,
//                 day:0
//             }
//             };
//             this.hospitalChange=this.hospitalChange.bind(this);
//             this.locationChange=this.locationChange.bind(this);
//             this.dayChange=this.dayChange.bind(this);
//       }

//       hospitalChange(event){
//           //const value={...this.state.searchFilter,location:event.target.value};
//           //console.log(event.target.options[event.target.selectedIndex].text);
//           const value={...this.state.searchFilter,hospital:parseInt(event.target.value)};
//           this.setState({searchFilter:value}, () => {
//             this.props.onSelectFilter(this.state.searchFilter);
//           });

//         }

//       locationChange(event){
//         const value={...this.state.searchFilter,location:parseInt(event.target.value)};
//         this.setState({searchFilter:value}, () => {
//             this.props.onSelectFilter(this.state.searchFilter);
//           });
//       }

//       dayChange(event){
//         const value={...this.state.searchFilter,day:parseInt(event.target.value)};
//         this.setState({searchFilter:value}, () => {
//             this.props.onSelectFilter(this.state.searchFilter);
//           });
//       }
//     componentDidMount(){

//     }

//     getInitialData(){
//         this.hospitalList=HospitalList;
//         this.locationList=LocationList;
//         this.dayList=DayList;
//     }


//     render() {

//         this.state = {
//             options: [{name: 'Apollo Delhi', id: 1},{name: 'Apollo Mumbai', id: 2}]
//         };

//         this.getInitialData();
//         return (
//             <div className="filter">
//                 {/* <Multiselect
//                     options={this.state.options}
//                     selectedValues={this.state.selectedValue}
//                     onSelect={this.onSelect}
//                     onRemove={this.onRemove}
//                     showCheckbox={true}
//                     displayValue="name"
//                     placeholder='Select Hospital'
//                 /> */}
//                 <div className="dropdown">
//                     <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
//                         Select Hospital
//                     </button>

//                     <div className="dropdown-menu pl-2">
//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="all"/>
//                             <label className="custom-control-label" for="all">All</label>
//                         </div>
//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="apollodelhi"/>
//                             <label className="custom-control-label" for="apollodelhi">Apollo Delhi</label>
//                         </div>

//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="apollomumbai"/>
//                             <label className="custom-control-label" for="apollomumbai">Apollo Mumbai</label>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="dropdown">
//                     <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
//                         Select Doctor
//                     </button>

//                     <div className="dropdown-menu pl-2">
//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="allDoc"/>
//                             <label className="custom-control-label" for="allDoc">All</label>
//                         </div>
//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="doc1"/>
//                             <label className="custom-control-label" for="doc1">Dr. Wilson</label>
//                         </div>

//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="doc2"/>
//                             <label className="custom-control-label" for="doc2">Dr. Jack</label>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="dropdown">
//                     <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
//                         Select Appointment type
//                     </button>

//                     <div className="dropdown-menu pl-2">
//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="allLocation"/>
//                             <label className="custom-control-label" for="allLocation">All</label>
//                         </div>
//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="apollolalkila"/>
//                             <label className="custom-control-label" for="apollolalkila">General Timing - Online</label>
//                         </div>
//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="apollolalkila"/>
//                             <label className="custom-control-label" for="apollolalkila">General Timing - Offline</label>
//                         </div>

//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="apollo"/>
//                             <label className="custom-control-label" for="apollo">Slot Time - Online</label>
//                         </div>
//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="apollo"/>
//                             <label className="custom-control-label" for="apollo">Slot Time - Offline</label>
//                         </div>
//                     </div>
//                 </div>

//                 <div className="dropdown">
//                     <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
//                         Select Date
//                     </button>

//                     <div className="dropdown-menu pl-2">
//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="allLocation"/>
//                             <label className="custom-control-label" for="allLocation">All</label>
//                         </div>
//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="today"/>
//                             <label className="custom-control-label" for="today">Today</label>
//                         </div>
//                         <div className="custom-control custom-checkbox">
//                             <input type="checkbox" className="custom-control-input" id="Week"/>
//                             <label className="custom-control-label" for="Week">Week ago </label>
//                         </div>
//                     </div>
//                 </div>
//                 {/* <select className="form-control mr-2" name="location" value={this.state.searchFilter.hospital}
//                 onChange={ (e) => {this.hospitalChange(e)}}
//                 >
//                     {this.hospitalList.map((hospital) => {
//                         return <option key={hospital.id} value={hospital.id}>{hospital.name}</option>
//                     })}
//                 </select> */}
//                 {/* <select className="form-control mr-2" name="insurance" value={this.state.searchFilter.location}
//                 onChange={ (e) => {this.locationChange(e)}}
//                 >
//                     {this.locationList.map((location) => {
//                         return <option key={location.id} value={location.id}>{location.name}</option>;
//                     })}
//                 </select>
//                 <select className="form-control" name="specialization" value={this.state.searchFilter.day}
//                 onChange={ (e) => {this.dayChange(e)}}

//                 >
//                     {this.dayList.map((day) => {
//                         return <option key={day.id} value={day.id}>{day.name}</option>;
//                     })}
//                 </select> */}
//             </div>
//         )
//     }
// }
// export default withRouter(HospitalFilterComponent);