import React from 'react';
import './App.css';
import './assets/style.css';
import './assets/responsive.css';
import * as $ from 'jquery';
// import { createBrowserHistory } from 'history';

import HeaderComponent from './common/Header/Header';
import FooterComponent from './common/Footer/Footer';
import { BrowserRouter as Router, Routes, Route, Switch } from "react-router-dom";
import DashboardComponent from './components/dashboard/Dashboard';
import UserLoginComponent from './components/Login/UserLogin/UserLogin';
import HospitalLoginComponent from './components/Login/HospitalLogin/HospitalLogin';
import SearchComponent from './components/Search/Search';
import { UserContext } from './state/UserState';
import UserDashBoard from './components/user/UserDashBoard';
import UserProfileComponent from './components/user/profile/UserProfile';
import MyAppointmentComponent from './components/user/myappointment/MyAppointment';
import UserHelpComponent from './components/user/help/Help';
import UserSearchComponent from './components/user/search/UserSearch';
import UserRescheduleComponent from './components/user/reschedule/UserReschedule';
import UserPrescription from './components/user/prescription/UesrPrescription';
import UserInvoiceComponent from './components/user/invoice/UserInvoice';
import NewAppointmentComponent from './components/user/appointment/NewAppointment';
import NearByComponent from './components/user/nearby/NearBy';
import UserAllInvoiceComponent from './components/user/invoice/UserAllInvoice';
import UserAllPrescriptionComponent from './components/user/prescription/UserAllPrescription';
import {
  ROUTE_DASHBOARD, ROUTE_USER_LOGIN, ROUTE_HOSPITAL_LOGIN,
  ROUTE_SEARCH, ROUTE_USER_SEARCH, ROUTE_USER_PROFILE,
  ROUTE_USER_MYAPPOINTMENT, ROUTE_USER_DASHBOARD, ROUTE_USER_HELP,
  ROUTE_USER_RESCHEDULE, ROUTE_USER_INVOICE, ROUTE_USER_PRESCRIPTION, ROUTE_USER_NEW_APPOINTMENT,
  ROUTE_USER_NEAR_BY_SEARCH, ROUTE_USER_ALL_INVOICE, ROUTE_USER_ALL_PRESCRIPTION
} from './constants/Constants';
import TermConditions from './components/user/navlink/TermConditions';
import PrivacyPolicy from './components/user/navlink/PrivacyPolicy';
import AboutUs from './components/user/navlink/AboutUs';
import Blog from './components/user/navlink/Blog';
import HowItWorks from './components/user/navlink/HowItWorks';
import ContactUs from './components/user/navlink/ContactUs';
import ScrollToTop from './utils/ScrollToTop';
// import { Dashboard } from './mock/hospital/Dashboard';
import HospitalDashboardComponent from './components/hospital/dashboard/HospitalDashboardComponent';
import LiveAppointmentComponent from './components/hospital/liveappointment1/Liveappointment';
// import AllAppointmentList from './mock/hospital/Allappointments';
import AllAppointmentComponent from './components/hospital/allapointment/Allapointment';
import AllAppointments from './components/hospital/AllAppointments';
import Dashboard from './components/hospital/Dashboard';
import BookAppointmentComponent from './components/hospital/bookappointment/BookAppointment';
import PatientHistoryComponent from './components/hospital/PatientHistory/PatientHistory';
import ProfileComponent from './components/hospital/profile/Profile';
import ManageStaffComponent from './components/hospital/manage/ManageStaff';
import HelpComponent from './components/hospital/help/Help';

import DoctorProfile from './components/user/doctorProfile/DoctorProfile';
import UserSignup from './components/Login/UserLogin/UserSignup/UserSignup';
import HospitalSignup from './components/Login/HospitalLogin/Signup/HospitalSignup';
import UserOTPComponent from './components/Login/UserLogin/UserOTP/UserOtp';
import UserForgotpassword from './components/Login/UserLogin/UserForgotpassword';
import UserResetpassword from './components/Login/UserLogin/UserResetpassword';
import HospitalForgotpassword from './components/Login/HospitalLogin/HospitalForgotpassword';
import HospitalResetpassword from './components/Login/HospitalLogin/HospitalResetpassword';

import Doctorprofile from './components/hospital/doctorprofile/Doctorprofile';
import Editdoctorprofile from './components/hospital/doctorprofile/Editdoctorprofile';

// admin
import Adminlogin from './components/Login/Adminlogin/Login';
import Admindashboard from './components/management/dashboard/Dashboard';
import Allhospitals from './components/management/hospital/Allhospitals';
import Hospitaldetail from './components/management/hospital/Hospitaldetail';
import Transaction from './components/management/payment/Transaction';
import Invoice from './components/management/payment/Invoice';
import AllPatients from './components/management/patient/Allpatients';
// import Patientdetail from './components/management/patient/Patientdetail';
import Editpatient from './components/management/patient/Editpatient';
import Allusers from './components/management/users/Allusers';
import Userdetail from './components/management/users/Userdetail';
import Allappointments from './components/management/appointment/Allappointments';
import Allinvoice from './components/management/invoice/Allinvoice';
import Invoicedetail from './components/management/invoice/Invoicedetail';
import Editinvoice from './components/management/invoice/Editinvoice';
import Allprescription from './components/management/prescription/Allprescriptions';
import Prescriptiondetail from './components/management/prescription/Prescriptiondetail';
import Editprescription from './components/management/prescription/Editprescription';
import Allsubscription from './components/management/subscription/Allsubscription';
import Newplan from './components/management/subscription/Newplan';
import Alldoctors from './components/management/doctor/Alldoctor';
import Editdoctor from './components/management/doctor/Editdoctor';
import Doctordetail from './components/management/doctor/Doctordetail';
import Allstaff from './components/management/staff/Allstaff';
import Editstaff from './components/management/staff/Editstaff';
import Usermanagement from './components/management/usermanagement/Usermanagement';
import Allspecializations from './components/management/allspecializations/Allspecializations';
import Allinsurances from './components/management/insurance/Allinsurance';
import Appointmentdetail from './components/management/appointment/Appointmentdetail';
import Editappointment from './components/management/appointment/Editappointment';

// import Login from './components/Login';
import Logout from './Logout';
import LiveAppointments from './components/hospital/liveAppointment/LiveAppointments';


class App extends React.Component {
  static contextType = UserContext;

  componentDidMount() {
    $("#selectProfile a").on('click', function () {
      $("#selectProfile a").removeClass("active");
      $(this).addClass("active");
    });
  }

  render() {
    const userDetails = this.context;
    return (
      <div className="mobile_body_fix">

        <Router>
          <HeaderComponent />
          <div className={(userDetails !== undefined && userDetails.token !== null && userDetails.token !== '') ? "main_area" : "main_area"}>
            <ScrollToTop>
              <Switch>
                <Route path='/' exact component={DashboardComponent} />
                <Route path={ROUTE_USER_LOGIN} component={UserLoginComponent} />
                <Route path={ROUTE_HOSPITAL_LOGIN} component={HospitalLoginComponent} />
                <Route path={ROUTE_SEARCH} component={SearchComponent} />
                <Route path={ROUTE_USER_SEARCH} component={UserSearchComponent} />
                <Route path={ROUTE_USER_DASHBOARD} component={UserDashBoard} />
                <Route path={ROUTE_USER_PROFILE} component={UserProfileComponent} />
                <Route path={ROUTE_USER_MYAPPOINTMENT} component={MyAppointmentComponent} />
                <Route path={ROUTE_USER_HELP} component={UserHelpComponent} />
                <Route path={ROUTE_USER_RESCHEDULE} component={UserRescheduleComponent} />
                <Route path={ROUTE_USER_INVOICE} component={UserInvoiceComponent} />
                <Route path={ROUTE_USER_PRESCRIPTION} component={UserPrescription} />
                <Route path={ROUTE_USER_NEW_APPOINTMENT} component={NewAppointmentComponent} />
                <Route path={ROUTE_USER_NEAR_BY_SEARCH} component={NearByComponent} />
                <Route path={ROUTE_USER_ALL_INVOICE} component={UserAllInvoiceComponent} />
                <Route path='/user/allPrescription' component={UserAllPrescriptionComponent} />
                <Route path='/termsconditions' component={TermConditions} />
                <Route path='/privacypolicy' component={PrivacyPolicy} />
                <Route path='/aboutus' component={AboutUs} />
                <Route path='/blog' component={Blog} />
                <Route path='/howItWorks' component={HowItWorks} />
                <Route path='/contactUs' component={ContactUs} />
                {/* <Route path='/hospital/dashboard' component={HospitalDashboardComponent} /> */}
                <Route path='/hospital/dashboard' component={Dashboard} />
                {/* <Route path='/hospital/liveappointmentid123' component={LiveAppointmentComponent} /> */}
                {/* <Route path='/hospital/liveappointments' component={LiveAppointmentComponent} /> */}
                <Route path='/hospital/liveappointments' component={LiveAppointments} />
                {/* <Route path='/hospital/allappointment' component={AllAppointmentComponent} /> */}
                <Route path='/hospital/allappointment' component={AllAppointments} />
                <Route path='/hospital/newappointment' component={BookAppointmentComponent} />
                <Route path='/hospital/patientHistory' component={PatientHistoryComponent} />
                <Route path='/hospital/profile' component={ProfileComponent} />
                <Route path='/hospital/manageStaff' component={ManageStaffComponent} />
                <Route path='/hospital/help' component={HelpComponent} />

                <Route path='/user/doctorProfile' component={DoctorProfile} />
                <Route path='/usersignup' component={UserSignup} />
                <Route path='/hospitalSignup' component={HospitalSignup} />
                <Route path='/userotp' component={UserOTPComponent} />
                <Route path='/userforgotpassword' exact component={UserForgotpassword} />
                <Route path='/userresetpassword' exact component={UserResetpassword} />

                <Route path='/hospitalsignup' component={HospitalSignup} />
                <Route path='/hospitalforgotpassword' exact component={HospitalForgotpassword} />
                <Route path='/hospitalresetpassword' exact component={HospitalResetpassword} />
                <Route path='/hospitalname/drjack' exact component={Doctorprofile} />
                <Route path='/hospitalname/editdoctor' exact component={Editdoctorprofile} />

                {/* Admin */}
                <Route path='/adminlogin' exact component={Adminlogin} />
                <Route path='/management/dashboard' exact component={Admindashboard} />
                <Route path='/management/allhospitals' exact component={Allhospitals} />
                <Route path='/management/hospitaldetail' exact component={Hospitaldetail} />
                <Route path='/management/transaction' exact component={Transaction} />
                <Route path='/management/invoice' exact component={Invoice} />
                <Route path='/management/allpatients' exact component={AllPatients} />
                {/* <Route path='/management/patientdetail' exact component={Patientdetail} /> */}
                <Route path='/management/editpatient' exact component={Editpatient} />
                <Route path='/management/allusers' exact component={Allusers} />
                <Route path='/management/userdetail' exact component={Userdetail} />
                <Route path='/management/allappointments' exact component={Allappointments} />
                <Route path='/management/allinvoices' exact component={Allinvoice} />
                <Route path='/management/invoicedetail' exact component={Invoicedetail} />
                <Route path='/management/editinvoice' exact component={Editinvoice} />
                <Route path='/management/allprescriptions' exact component={Allprescription} />
                <Route path='/management/prescriptiondetail' exact component={Prescriptiondetail} />
                <Route path='/management/editprescription' exact component={Editprescription} />
                <Route path='/management/allsubscriptions' exact component={Allsubscription} />
                <Route path='/management/addnewplan' exact component={Newplan} />
                <Route path='/management/alldoctors' exact component={Alldoctors} />
                <Route path='/management/editdoctor' exact component={Editdoctor} />
                <Route path='/management/doctordetail' exact component={Doctordetail} />
                <Route path='/management/allstaff' exact component={Allstaff} />
                <Route path='/management/editstaff' exact component={Editstaff} />
                <Route path='/management/usermanagement' exact component={Usermanagement} />
                <Route path='/management/allspecializations' exact component={Allspecializations} />
                <Route path='/management/allinsurances' exact component={Allinsurances} />
                <Route path='/management/appointmentdetail' exact component={Appointmentdetail} />
                <Route path='/management/editappointment' exact component={Editappointment} />
                <Route path='/Logout' exact component={Logout} />
              </Switch>
            </ScrollToTop>
          </div>
          <FooterComponent />

        </Router>
      </div>
    );

  }
}

export default App;
