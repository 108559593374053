import React, { Component } from 'react';
import { Link } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"
import DataTableGrid from 'src/common/DataGrid/DataGrid';
import { connect } from 'react-redux';


import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
const Allhospitals = () => {

    const rows = [
        {
            id: 1, col1: "Hello",
            col2: "World",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 2, col1: "MUI X",
            col2: "is awesome",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 3, col1: "Material UI",
            col2: "is amazing",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 4, col1: "MUI",
            col2: "Lokesh",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 5, col1: "Joy UI",
            col2: "is awesome",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 6, col1: "MUI Base",
            col2: "is amazing",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        }
    ];


    const columns = [
        { field: "id", headerName: "#", },
        { field: "col1", headerName: "Hospital", width: 150 },
        { field: "col2", headerName: "Speciality", width: 150 },
        { field: "col3", headerName: "Member Since", width: 150 },
        { field: "col4", headerName: "Location", width: 150 },
        { field: "col5", headerName: "Doctor", width: 150 },
        { field: "col6", headerName: "Staff", width: 150 },
        { field: "col7", headerName: "Verification", width: 150 },
        { field: "col8", headerName: "Subscription Date", width: 150 },
        {
            headerName: "Action",
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    // onClick={deleteUser(params.id)}
                    onClick={() => { }}
                />,
                <GridActionsCellItem
                    icon={<SecurityIcon />}
                    label="View"
                    onClick={() => { }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<FileCopyIcon />}
                    label="Edit"
                    onClick={() => { }}
                    showInMenu
                />,
            ]
        },    ];

    return (
        <div className="container dct-appoinment">
            <h5 className='mt-3 fw-bold mb-3'>All Hospital</h5>

            <DataTableGrid
                rows={rows}
                columns={columns}
                rowCountState={rows.length}

            />
        </div>
    )
}

export default connect()(Allhospitals)

// class Allhospitals extends Component{

//         componentDidMount() {
// 		document.title = "All Hospital"
//         $('#allHospital').DataTable({
//                 dom: '<"top"Bflp>rt<"bottom"ip>',
//                 pageLength: 5,
//                 buttons: ['copy', 'csv', 'print', 'excel']
//             });
//         }
//         render(){



//             return(
//                 <>
//                     <div className="container dct-appoinment">
//                         <div className="row">
//                             <div className="col-md-12 mb-3">
//                                 <h5 className='mt-3 fw-bold mb-3'>All Hospital</h5>
//                                 <div className="card-table mb-0 card p-2">
//                                     <div className="card-body p-0">
//                                         <table className="table table-hover mt-3" id="allHospital">
//                                             <thead>
//                                                 <tr>
//                                                     <th scope="col">#</th>
//                                                     <th scope="col">Hospital</th>
//                                                     <th scope="col">Speciality</th>
//                                                     <th scope="col">Membar Since</th>
//                                                     <th scope="col">Location</th>
//                                                     <th scope="col">Doctors</th>
//                                                     <th scope="col">Staff</th>
//                                                     <th scope="col">Verification</th>
//                                                     <th scope="col">Subscription date</th>
//                                                     <th scope="col">Actions</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 <tr>
//                                                     <th scope="row">1</th>
//                                                     <td>Apollo Delhi</td>
//                                                     <td>Dental</td>
//                                                     <td>5 May 2021</td>
//                                                     <td>Delhi</td>
//                                                     <td>4</td>
//                                                     <td>10</td>
//                                                     <td>
//                                                         <span className="badge badge-pill btn-primary">Complated</span>
//                                                     </td>
//                                                     <td>
//                                                         10-09-2022 to 10-10-2022
//                                                     </td>
//                                                     <td>
//                                                         <Link to="/management/hospitaldetail" className='btn btn-outline-primary mr-2'><i className="fas fa-eye"></i></Link>
//                                                         <Link to="/management/hospitaldetail" className='btn btn-outline-success mr-2'><i className="fas fa-edit"></i></Link>
//                                                         <Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <th scope="row">2</th>
//                                                     <td>Apollo Mumbai</td>
//                                                     <td>Cardiology</td>
//                                                     <td>5 May 2021</td>
//                                                     <td>Mumbai</td>
//                                                     <td>7</td>
//                                                     <td>18</td>
//                                                     <td>
//                                                         <span className="badge badge-pill btn-primary">Complated</span>
//                                                     </td>
//                                                     <td>
//                                                         10-09-2022 to 10-10-2022
//                                                     </td>
//                                                     <td>
//                                                         <Link to="/management/hospitaldetail" className='btn btn-outline-primary mr-2'><i className="fas fa-eye"></i></Link>
//                                                         <Link to="/management/hospitaldetail" className='btn btn-outline-success mr-2'><i className="fas fa-edit"></i></Link>
//                                                         <Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <th scope="row">3</th>
//                                                     <td>Apollo Gujarat</td>
//                                                     <td>Ayurveda</td>
//                                                     <td>5 May 2021</td>
//                                                     <td>Vadodara</td>
//                                                     <td>4</td>
//                                                     <td>10</td>
//                                                     <td>
//                                                         <span className="badge badge-pill btn-warning">Pending</span>
//                                                     </td>
//                                                     <td>
//                                                         10-09-2022 to 10-10-2022
//                                                     </td>
//                                                     <td>
//                                                         <Link to="/management/hospitaldetail" className='btn btn-outline-primary mr-2'><i className="fas fa-eye"></i></Link>
//                                                         <Link to="/management/hospitaldetail" className='btn btn-outline-success mr-2'><i className="fas fa-edit"></i></Link>
//                                                         <Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <th scope="row">4</th>
//                                                     <td>Apollo Delhi</td>
//                                                     <td>Dental</td>
//                                                     <td>5 May 2021</td>
//                                                     <td>Delhi</td>
//                                                     <td>4</td>
//                                                     <td>10</td>
//                                                     <td>
//                                                         <span className="badge badge-pill btn-warning">Pending</span>
//                                                     </td>
//                                                     <td>
//                                                         10-09-2022 to 10-10-2022
//                                                     </td>
//                                                     <td>
//                                                         <Link to="/management/hospitaldetail" className='btn btn-outline-primary mr-2'><i className="fas fa-eye"></i></Link>
//                                                         <Link to="/management/hospitaldetail" className='btn btn-outline-success mr-2'><i className="fas fa-edit"></i></Link>
//                                                         <Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <th scope="row">5</th>
//                                                     <td>Apollo Mumbai</td>
//                                                     <td>Dental</td>
//                                                     <td>5 May 2021</td>
//                                                     <td>Mumbai</td>
//                                                     <td>4</td>
//                                                     <td>10</td>
//                                                     <td>
//                                                         <span className="badge badge-pill btn-primary">Complated</span>
//                                                     </td>
//                                                     <td>
//                                                         10-09-2022 to 10-10-2022
//                                                     </td>
//                                                     <td>
//                                                         <Link to="/management/hospitaldetail" className='btn btn-outline-primary mr-2'><i className="fas fa-eye"></i></Link>
//                                                         <Link to="/management/hospitaldetail" className='btn btn-outline-success mr-2'><i className="fas fa-edit"></i></Link>
//                                                         <Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                                     </td>
//                                                 </tr>
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </>
//             )
//         }

//     }


// export default Allhospitals