import { MenuItem, TextField, Typography } from "@mui/material";
import React from "react";

const DropDown = ({ label, selectLabel, name, value, onChange, placeholder, options = [], error }) => {

    return (
        <>
            <Typography>{placeholder}</Typography>
            <TextField
                variant="outlined"
                margin="dense"
                value={value}
                onChange={onChange}
                name={name}
                select
                label={label}
                fullWidth
                size="small"
                error={error}
            >
                <MenuItem value='0' disabled>{selectLabel}</MenuItem>
                {options?.map((item, idx) => (
                    <MenuItem value={item.value} key={idx}>{item.name}</MenuItem>

                ))}

            </TextField>
        </>

    )
}

export default DropDown