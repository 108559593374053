
const SpecialityList=[
    //{id:0,title:'Select Speciality',speciality:'Test'},
    {id:1,title:'Dentist',speciality:'Test'},
    {id:2,title:'Gyanocologist',speciality:'Test'},
    {id:3,title:'Physician',speciality:'Test'}
   
    ];

export default SpecialityList;

