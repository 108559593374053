import React, { Component } from 'react';
import { Link   } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"

class Prescriptiondetail extends React.Component{

	componentDidMount() {
        document.title = "Prescription Detail"
		$('#allAppointment').DataTable({
			dom: '<"top"Bflp>rt<"bottom"ip>',
            pageLength: 5,
            buttons: ['copy', 'csv', 'print', 'excel']
		});
	}

	render(){
		return(
			<>
				<div className="container">
					<div className="row">
                        <div className="col-md-12">
                            <div className="row dashboard_box">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div className="pr-0">
                                    <span className="mb-0 fw-bold">Invoice Number: 12345</span>
                                    </div>
                                    <div className="text-right">
                                    <button className='btn btn-primary mr-2'>Download from here <i className="fa fa-download"></i></button>

                                    <div className="dropdown btn btn-outline-primary mr-2">
                                            <i className="fa fa-share"></i>
                                            <div className="dropdown-content">
                                                <Link to="#">Share</Link>
                                                <Link to="#">Share on gmail</Link>
                                            </div>
                                    </div>

                                    <Link to="#" className=" btn btn-primary">Prescription</Link><br />
                                    </div>
                                </div>

                                <hr />
                                <div className="col-md-6 col-12">
                                    <div className="row">
                                        <div className="col-md-4 col-6">
                                            <p><b>Doctor Name :  </b></p>
                                            <p><b>Patient Name :  </b></p>
                                            <p><b>Phone Number : </b></p>
                                            <p><b>Date Of Birth : </b></p>
                                            <p><b>Time Slot : </b></p>
                                            <p><b>Address :</b></p>
                                        </div>
                                        <div className="col-md-8 col-6">
                                            <p>Dr. Jack</p>
                                            <p>Mukesh Sharma</p>
                                            <p>+91 1236547898</p>
                                            <p>12 May 1987</p>
                                            <p>7:00-7:40</p>
                                            <p>Delhi</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 pr-0 col-12">
                                    <div className="row">
                                        <div className="col-md-4 col-6">
                                            <p><b>Appintment Date :</b></p>
                                            <p><b>Blood Group :</b></p>
                                            <p><b>Police Case :</b></p>
                                            <p><b>Argency Type :</b></p>
                                            <p><b>Case Type :</b></p>
                                        </div>
                                        <div className="col-md-8 col-12">
                                            <p>14 Aug 2022</p>
                                            <p>AB+</p>
                                            <p>No</p>
                                            <p>Normal</p>
                                            <p>Old</p>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className="col-md-12 mb-3">
                                    <p><span className='fw-bold'>Problem :</span> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod empor incididunt ut labore.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod empor incididunt ut labore.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod empor incididunt ut labore.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod empor incididunt ut labore.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod empor incididunt ut labore.</p>
                                </div>
                                <div className="col-md-12">
                                    <table class="table text-center table_pad">
                                        <thead>
                                            <tr>
                                                <th class="">Sr No.</th>
                                                <th class="">Medicine</th>
                                                <th class="">Quantity</th>
                                                <th class="">Course*</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Dolo 400</td>
                                                <td>3</td>
                                                <td>1-0-0-1</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Dolo 200</td>
                                                <td>1</td>
                                                <td>1-0-0-1</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>AAA Dolo 400</td>
                                                <td>4</td>
                                                <td>1-0-0-1</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</>
		)
	}
}


export default Prescriptiondetail