import HospitalList from "../dropdown/HospitalList";
import LocationList from "../dropdown/LocationList";


const AllAppointmentList=[
    {
        id:1,
        name:"Ramesh Sharma",
        address:"AAAAAAAAAABangalore ",
        phone:123444490,
        location:LocationList[1],
        imageUrl:'',
        appointmentTime:'1:30pm',
        appointmentDay:'24/10/2018',
        hospital:HospitalList[1]
    },{
        id:2,  
        name:"Ramesh Sharma 2",
        address:"ABC Nagar Bangalore 2",
        phone:2234567890,
        location:LocationList[2],
        imageUrl:'',
        appointmentTime:'1:30pm',
        appointmentDay:'24/10/2018',
        hospital:HospitalList[1]
        
    },{
        id:3,
        name:"Ramesh Sharma 3",
        address:"ABC Nagar 3 Bangalore ",
        phone:3234567890,
        location:LocationList[2],
        imageUrl:'',
        appointmentTime:'1:30pm',
        appointmentDay:'12/04/2018',
        hospital:HospitalList[1]
        
    },{
        id:4,
        name:"Ramesh Sharma 4",
        address:"ABC Nagar 4 Bangalore ",
        phone:4234567890,
        location:LocationList[2],
        imageUrl:'',
        appointmentTime:'11:30pm',
        appointmentDay:'04/04/2018',
        hospital:HospitalList[1]
        
    },{
        id:5,
        name:"Ramesh Sharma 5",
        address:"ABC Nagar 5 Bangalore ",
        phone:5234567890,
        location:LocationList[1],
        imageUrl:'',
        appointmentTime:'02:30pm',
        appointmentDay:'24/06/2018',
        hospital:HospitalList[1]
        
    }

];


export default AllAppointmentList;