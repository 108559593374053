import React, { Component } from 'react';
import { Link, Route, withRouter } from 'react-router-dom';
import * as $ from 'jquery';
import HospitalFilterComponent from '../../Search/SearchFilter/HospitalFilter';
import MultiSelectDropDown from 'src/common/MultiSelectDropDown';
import TextInput from 'src/common/TextInput';

class BookAppointmentComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitAppointmentSuccess: false
		};

	}

	componentDidMount() {
		// document.getElementById('dateBookAppointment').valueAsDate = new Date();

		$(".blood_btn").click(function () {
			$(".blood_btn").removeClass('selected');
			$(this).addClass('selected');
		});

		$(".emergen_btn").click(function () {
			$(".emergen_btn").removeClass('selected');
			$(this).addClass('selected');
		});

		$(".ex_btn").click(function () {
			$(".ex_btn").removeClass('selected');
			$(this).addClass('selected');
		});

		$(".police_btn").click(function () {
			$(".police_btn").removeClass('selected');
			$(this).addClass('selected');
		});
	}

	selectedFilter = (selectedVal) => {
		setTimeout(() => {
			this.setState({ submitAppointmentSuccess: true });
			$("#appbook_success").modal('show');
		}, 10);
	}

	render() {
		return (
			<React.Fragment>
				<div className="d-block d-md-none">
					<div styles="background: #eee;" className="row d-flex justify-content-between">
						<MultiSelectDropDown

						/>
						{/* <div className="mt-2 mb-1">
							<select className="form-control form-size">
								<option value="">By Location</option>
								<option value="">Location 1</option>
								<option value="">Location 2</option>
								<option value="">Location 3</option>
								<option value="">Location 4</option>
							</select>
						</div> */}
						<div className="mt-2 mb-1">
							<select className="form-control form-size">
								<option value="">By Insurance</option>
								<option value="">Insurance 1</option>
								<option value="">Insurance 2</option>
								<option value="">Insurance 3</option>
								<option value="">Insurance 4</option>
							</select>
						</div>
						<div className="mt-2 mb-1">
							<select className="form-control form-size">
								<option value="">By Specialization</option>
								<option value="">specialization 1 </option>
								<option value="">specialization 2 </option>
								<option value="">specialization 3 </option>
								<option value="">specialization 4 </option>
							</select>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 d-none d-md-block">
						<HospitalFilterComponent onSelectFilter={this.selectedFilter} />
					</div>

					<div className="col-md-12 mt-md-3 mt-1">
						<div className="newappoint container">
							<form>
								<div className="row">
									<div className="col-md-12">
										<p className=" float-left mb-2"><strong>Doctor Name / Hospital Name (Dynamic)</strong></p>
										<p className="float-right"><strong>ID Number : </strong>00092XYZ</p>
									</div>
									<div className="col-md-3 col-6 mt-2 mt-md-0">
										<input type="text" className="form-control " placeholder="Name" />
									</div>
									<div className="col-md-3 col-6 mt-2 mt-md-0">
										<input type="text" className="form-control " placeholder="Phone Number" />
									</div>
									<div className="col-md-3 col-6 mt-2 mt-md-0">
										<a href="#" className='btn btn-primary w-100' data-toggle="modal" data-target="#generalTime">General Time</a>
									</div>
									<div className="col-md-3 col-6 mt-2 mt-md-0">
										<a href="#" className='btn btn-primary w-100' data-toggle="modal" data-target="#slotTime">Slot Time</a>
									</div>
									<div className="col-md-3 col-12 mt-2">
										<input type="text" className="form-control " placeholder="Address" />
									</div>
									<div className="col-md-3 col-12 mt-2">
										<input type="email" className="form-control " placeholder="Email (Optional)" />
									</div>
									<div className="col-md-3 col-6 mt-2">
										<input type="text" className="form-control " placeholder="City Name" />
									</div>
									<div className="col-md-3 col-6 mt-2">
										<input type="text" className="form-control " placeholder="Pin Code" />
									</div>
									<div className="col-md-12 mt-2">
										<textarea className="form-control " cols="20" rows="1" placeholder="Comment"></textarea>
									</div>
								</div>
							</form>
							<h4 className="primary_text mt-2 mb-2 fw-bold mb-2">Extra Information</h4>
							<div className="row blood_group">
								<div className="col-md-3 col-12 text-center" id="myDIV">
									<div className="card">
										<h6><strong>Blood Group</strong></h6>
										<div className="d-flex justify-content-around btns">
											<p className="blood_btn">A+</p>
											<p className="blood_btn">A-</p>
										</div>
										<div className="d-flex justify-content-around btns">
											<p className="blood_btn">B+</p>
											<p className="blood_btn">B-</p>
										</div>
										<div className="d-flex justify-content-around btns">
											<p className="blood_btn">AB+</p>
											<p className="blood_btn">AB-</p>
										</div>
										<div className="d-flex justify-content-around btns">
											<p className="blood_btn">O+</p>
											<p className="blood_btn">O-</p>
										</div>
										<div className="d-flex justify-content-around btns">
											<p className="blood_btn">Don't Know</p>
										</div>
									</div>
								</div>
								<div className="col-md-3 col-12 text-center">
									<div className="card">
										<h6><strong>Police Case ?</strong></h6>
										<div className="d-flex justify-content-around">
											<p className="police_btn">Yes</p>
											<p className="police_btn">No</p>
										</div>
									</div>
								</div>
								<div className="col-md-3 col-12 text-center">
									<div className="card">
										<h6 className="d-md-block d-none"><strong>Argency Type</strong></h6>
										<div className="d-flex justify-content-around">
											<p className="emergen_btn">Emergency</p>
											<p className="emergen_btn">Normal</p>
										</div>
									</div>
								</div>
								<div className="col-md-3 col-12 text-center">
									<div className="card">
										<h6 className="d-md-block d-none"><strong>Case Type</strong></h6>
										<div className="d-flex justify-content-around">
											<p className="ex_btn">New Case</p>
											<p className="ex_btn">Old Case</p>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 mb-5 mb-md-0 mt-md-0 mt-2">
									<Link className="btn btn-primary float-right mb-3" to="#" data-toggle="modal" data-target="#appbook_success" onClick={this.submitAppointment}>Submit</Link>
								</div>
							</div>
							<div id="appbook_success" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h6 className="modal-title">Successfully Booked</h6>
											<button type="button" className="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
										</div>
										<div className="modal-body">
											<form>
												<div className="row mt-2">
													<div className="col-md-12 text-center">
														<p><i className="fa fa-check-circle modal_icon2 mb-2"></i></p>
														<h5>Appointment Booked Successfully</h5> </div>
												</div>
											</form>
										</div>
										<div className="modal-footer">
											<button type="button" className="btn btn-secondary " data-dismiss="modal">Ok</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* general time modal */}
				<div className="modal fade" id="generalTime" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLabel">Select Appointment Date</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-12">
										<div className="card">
											<div className="card-body">
												<div className="booking-doc-info d-flex justify-content-between align-items-center">
													<div className="d-flex">
														<a href="#" className="booking-doc-img border aounded">
															<img src={require('../../../assets/images/home/doctor.png').default} alt="User Image"/>
														</a>
														<div className="booking-info">
															<h5><a href="#">Dr. Wilson</a></h5>
															<p className="doc-speciality mb-1">BDS, MDS - Oral & Maxillofacial Surgery</p>
															<p className="doc-department"><i className="fas fa-user-md"></i> Dentist</p>
														</div>
													</div>

													<div className="dateSelect">
														<label htmlFor="select date">Select Appointment Date</label>
														<input type="date"  className='form-control'/>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12 text-right">
														<button className='btn btn-primary text-right'>Save</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* slot time modal */}
				<div className="modal fade" id="slotTime" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLabel">Select Appointment Slot</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-12">
										<div className="card">
											<div className="card-body">
												<div className="booking-doc-info">
													<a href="#" className="booking-doc-img border aounded">
														<img src={require('../../../assets/images/home/doctor.png').default} alt="User Image"/>
													</a>
													<div className="booking-info">
														<h5><a href="#">Dr. Wilson</a></h5>
														<p className="doc-speciality mb-1">BDS, MDS - Oral & Maxillofacial Surgery</p>
														<p className="doc-department"><i className="fas fa-user-md"></i> Dentist</p>
													</div>
												</div>
											</div>
										</div>

										<div className="card booking-schedule schedule-widget">

											<div className="schedule-header">
												<div className="date-booking">
													<div className="row align-items-center">
														<div className="col-12 col-sm-6 col-md-4 col-lg-4">
															<h4 className="mb-0">13 September 2022</h4>
														</div>
														<div className="col-12 col-sm-6 col-md-2 col-lg-4 text-sm-center">
															<p className="text-muted mb-0">Tuesday</p>
														</div>
														<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-md-end">
															<div className="bookingrange btn btn-white btn-sm">
																<i className="far fa-calendar-alt me-2"></i>
																<span></span>
																<i className="fas fa-chevron-down ms-2"></i>
															</div>
														</div>
													</div>
												</div>
											</div>


											<div className="schedule-cont">
												<div className="row">
													<div className="col-md-12">

														<div className="day-slot">
															<ul>
																<li>
																	<div className="days">
																		<span>Mon</span>
																		<span className="slot-date">12 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
																<li>
																	<div className="days">
																		<span>Tue</span>
																		<span className="slot-date">13 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
																<li>
																	<div className="days">
																		<span>Wed</span>
																		<span className="slot-date">14 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
																<li>
																	<div className="days">
																		<span>Thu</span>
																		<span className="slot-date">15 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
																<li>
																	<div className="days">
																		<span>Fri</span>
																		<span className="slot-date">16 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
																<li>
																	<div className="days">
																		<span>Sat</span>
																		<span className="slot-date">17 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
																<li>
																	<div className="days">
																		<span>Sun</span>
																		<span className="slot-date">18 Sep <small
																				className="slot-year">2022</small></span>
																	</div>
																</li>
															</ul>
														</div>

													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="time-slot">
															<ul className="clearfix">
																<li>
																	<a className="timing" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
																<li>
																	<a className="timing selected" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing selected" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing selected" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
																<li>
																	<a className="timing" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
																<li>
																	<a className="timing" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
																<li>
																	<a className="timing" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
																<li>
																	<a className="timing" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
																<li>
																	<a className="timing" href="#">
																		<span>9:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>10:00</span> <span>AM</span>
																	</a>
																	<a className="timing" href="#">
																		<span>11:00</span> <span>AM</span>
																	</a>
																</li>
															</ul>
														</div>
													</div>
													<div className="col-md-12 text-right">
														<a className='btn btn-primary mt-3 text-right' href="#">Select Slot</a>
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}


export default BookAppointmentComponent;