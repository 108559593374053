import React, { Component } from 'react';
import { Link } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";



import DataTableGrid from 'src/common/DataGrid/DataGrid';
import { connect } from 'react-redux';

import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const Allprescription = () => {

    const rows = [
        {
            id: 1, col1: "Hello",
            col2: "World",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 2, col1: "MUI X",
            col2: "is awesome",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 3, col1: "Material UI",
            col2: "is amazing",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 4, col1: "MUI",
            col2: "Lokesh",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "1.00 PM - 1.15 PM",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 5, col1: "Joy UI",
            col2: "is awesome",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "1.00 PM - 1.15 PM",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 6, col1: "MUI Base",
            col2: "is amazing",
            col3: "World",
            col4: "World",
            col5: "World",
            col6: "1.00 PM - 1.15 PM",
            col7: "World",
            col8: "World",
            col9: "World",
        }
    ];


    const columns = [
        { field: "id", headerName: "#", },
        { field: "col1", headerName: "Hospital", width: 150 },
        { field: "col5", headerName: "Doctor Name", width: 150 },
        { field: "col2", headerName: "Speciality", width: 150 },
        { field: "col4", headerName: "Location", width: 150 },
        { field: "col3", headerName: "Patient Name", width: 150 },
        { field: "col6", headerName: "Appointment Time", width: 150 },
        { field: "col6", headerName: "Medicine", width: 150 },
        { field: "col6", headerName: "Days", width: 150 },
        { field: "col6", headerName: "Timing", width: 150 },
        {
            headerName: "Action",
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    // onClick={deleteUser(params.id)}
                    onClick={() => { }}
                />,
                <GridActionsCellItem
                    icon={<SecurityIcon />}
                    label="View"
                    onClick={() => { }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<FileCopyIcon />}
                    label="Edit"
                    onClick={() => { }}
                    showInMenu
                />,
            ]
        },    ];

    return (
        <div className="container dct-appoinment">
            <h5 className='mt-3 fw-bold mb-3'>All Prescriptions</h5>

            <DataTableGrid
                rows={rows}
                columns={columns}
                rowCountState={rows.length}

            />
        </div>
    )
}

export default connect()(Allprescription)

// class Allprescription extends React.Component{

// 	componentDidMount() {
//         document.title = "All Prescription"
// 		$('#allPrescription').DataTable({
// 			dom: '<"top"Bflp>rt<"bottom"ip>',
//             pageLength: 5,
//             buttons: ['copy', 'csv', 'print', 'excel']
// 		});
// 	}

// 	render(){
// 		return(
// 			<>
// 				<div className="container">
// 					<div className="row">
// 						<div className="col-md-12">
// 							<h5 className='mt-3 fw-bold mb-3'>All Prescriptions</h5>
//                             <div className="card-table mb-0 card p-2">
// 								<div className="card-body p-0">
//                                     <div className="table-responsive">
//                                         <table className="table table-hover table-center mb-0" id="allPrescription">
//                                             <thead>
//                                                 <tr>
//                                                     <th scope="col">#</th>
//                                                     <th scope="col">Hospital</th>
//                                                     <th scope="col">Doctor Name</th>
//                                                     <th scope="col">Speciality</th>
//                                                     <th scope="col">Location</th>
//                                                     <th scope="col">Patient Name</th>
//                                                     <th scope="col">Appointment Time</th>
//                                                     <th scope="col">Medicine</th>
//                                                     <th scope="col">Days</th>
//                                                     {/* <th scope="col">Timing</th> */}
//                                                     <th scope="col">Action</th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 <tr>
//                                                     <td scope="row">1</td>
//                                                     <td>Apollo Mumbai</td>
//                                                     <td>Dr. jack</td>
//                                                     <td>Dentist</td>
//                                                     <td>Mumbai</td>
//                                                     <td>Ramesh Sharma</td>
//                                                     <td>15 Sept 2022 <span className="text-primary d-block">1.00 PM - 1.15 PM</span></td>
//                                                     <td>
//                                                         test
//                                                     </td>
//                                                     <td>3</td>
//                                                     <td>
//                                                         <Link to="./prescriptiondetail" className='btn btn-outline-primary mr-2'><i className="fas fa-eye"></i></Link>
//                                                         <Link to="./editprescription" className='btn btn-outline-success mr-2'><i className="fas fa-edit"></i></Link>
//                                                         <Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td scope="row">2</td>
//                                                     <td>Apollo Delhi</td>
//                                                     <td>Dr. Wilson</td>
//                                                     <td>Cardiology</td>
//                                                     <td>Delhi</td>
//                                                     <td>Mukesh Sharma</td>
//                                                     <td>16 Sept 2022 <span className="text-primary d-block">2.00 PM - 2.30 PM</span></td>
//                                                     <td>
//                                                         test
//                                                     </td>
//                                                     <td>
//                                                         7
//                                                     </td>
//                                                     <td>
//                                                         <Link to="./prescriptiondetail" className='btn btn-outline-primary mr-2'><i className="fas fa-eye"></i></Link>
//                                                         <Link to="./editprescription" className='btn btn-outline-success mr-2'><i className="fas fa-edit"></i></Link>
//                                                         <Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                                     </td>
//                                                 </tr>
//                                                 <tr>
//                                                     <td scope="row">3</td>
//                                                     <td>Apollo Mumbai</td>
//                                                     <td>Dr. Wilson</td>
//                                                     <td>Cardiology</td>
//                                                     <td>Mumbai</td>
//                                                     <td>Rakesh Kumar</td>
//                                                     <td>20 Sept 2022 <span className="text-primary d-block">4.00 PM - 4.30 PM</span></td>
//                                                     <td>
//                                                         test
//                                                     </td>
//                                                     <td>
//                                                         5
//                                                     </td>
//                                                     <td>
//                                                         <Link to="./prescriptiondetail" className='btn btn-outline-primary mr-2'><i className="fas fa-eye"></i></Link>
//                                                         <Link to="./editprescription" className='btn btn-outline-success mr-2'><i className="fas fa-edit"></i></Link>
//                                                         <Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                                     </td>
//                                                 </tr>
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>
// 						</div>
// 					</div>
// 				</div>
// 			</>
// 		)
// 	}
// }


// export default Allprescription