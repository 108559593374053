import React, { Component, useEffect, useState } from 'react';

import * as $ from 'jquery';

import { Link } from 'react-router-dom';
import HospitalFilterComponent from '../../Search/SearchFilter/HospitalFilter';
import { RescheduleDoctors } from '../../../mock/RescheduleDoctors';

import PatientInvoiceReport from './PatientInvoiceReport';
import { connect } from 'react-redux';
import { getPatientListAction, getPatientMedicalHistory, getPatientMedicalProfileAction } from "src/redux/Main/actions";
import { useCookies } from 'react-cookie';

const PatientHistoryComponent = (props) => {
    const [cookies, setCookie] = useCookies(['authtoken']);

    const [userAppointment] = useState(RescheduleDoctors)
    const [selectedPatient, setSelectedPatient] = useState(null)


    const { authtoken = null } = cookies

    useEffect(() => {

        props.getPatientListAction({ authToken: authtoken })
        props.getPatientMedicalProfileAction({ authToken: authtoken, patient_id: '57' })

    }, [])

    console.log("PATIENT HISTORY", props)

    const { patientsList = [] } = props

    return (
        <>
            <div className="row">
                <div className="col-md-12 d-none d-md-block">
                    <HospitalFilterComponent
                    // onSelectFilter={this.selectedFilter}
                    />
                </div>
                <div className="col-md-12 mt-md-3 mt-1">
                    <div className="col-md-12">
                        <form className="desk_form">
                            <i className="fa fa-search search_icon"></i>
                            <input className="location form-control" list="specialization" name="browser"
                                placeholder="Search Patient / Patient ID / Phone Number"
                            //  value={this.state.searchText}
                            //  onChange={ (e) => {this.filterPatients(e)}}
                            />
                        </form>
                    </div>

                    <div className="scroll_upcoming">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div class="accordion" id="accordionExample">
                                    <div class="card shadow mb-2">
                                        <div class="card-header" id="headingOne">
                                            <h2 class="mb-0">
                                                <button class="btn btn-primary btn-block text-center" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    All Patient Invoice Report
                                                </button>
                                            </h2>
                                        </div>

                                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <PatientInvoiceReport />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 1 */}
                            {patientsList?.map((patient, index) => (
                                <div className="col-md-6">
                                    <div className="card" key={patient.user_id}>
                                        <div className='dashboard_box listingBlock box_bg'>
                                            <div className='d-flex justify-content-start'>
                                                <div className="img_block text-center mr-3">
                                                    <img className="profile-pic" src={patient.profile_image} />
                                                </div>
                                                <div className="docinfo">
                                                    <p className="title_name mb-md-0 mb-2"><strong>{patient.name}</strong></p>
                                                    <p className="sub_font mb-md-0 mb-1"><i className="fa fa-graduation-cap"></i> {patient.title}</p>
                                                    <p className="sub_font mb-md-0 mb-1" style={{ cursor: 'pointer' }} onClick={(e) => { this.openGoogemap(e, patient.doctor) }}
                                                    ><i className="fa fa-map-marker mr-2"></i> {patient.address}</p>
                                                    <p className="sub_font mb-md-0 mb-1"><i className="fa fa-mobile-alt mr-2"></i> {patient.mobile}</p>
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <p className="title_name mb-2"><i className="far fa-clock"></i> <strong>{patient.scheduleTime}</strong></p>
                                                <p className="title_name mb-2"><i className="far fa-hospital"></i> <strong>{patient.name}</strong></p>
                                                <p className="title_name" href="#"><i className="far fa-user"></i> <strong>{patient.name}</strong></p>
                                            </div>
                                            <div className="text-right d-flex flex-column">
                                                <Link to={{
                                                    pathname: '/user/prescription',
                                                    state: {
                                                        doctorId: patient.user_id,
                                                        patient_id: patient.user_id
                                                    }
                                                }} className="btn btn-primary mb-2 m_font_10"  >View Prescription</Link>

                                                <Link to={{
                                                    pathname: '/user/invoice',
                                                    state: {
                                                        doctorId: patient.user_id,
                                                        patient_id: patient.user_id
                                                    }
                                                }} className="btn btn-primary m_font_10 mb-2" >View Invoice</Link>
                                                {/* <Link className='btn btn-primary m_font_10' data-toggle="modal" data-target="#viewMedicalHistoryModal">View Medical History</Link> */}
                                                <button type='button' className='btn btn-primary m_font_10' data-toggle="modal" data-target="#viewMedicalHistoryModal"
                                                    onClick={() => {
                                                        setSelectedPatient(patient);
                                                        props.getPatientMedicalHistory({
                                                            authToken: authtoken,
                                                            patient_id: patient.user_id
                                                        })
                                                    }}>View Medical History</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/* {userAppointment.map((appointment, i) => {
                                return (
                                    <div className="col-md-6">
                                        <div className="card" key={i}>
                                            <div className='dashboard_box listingBlock box_bg'>
                                                <div className='d-flex justify-content-start'>
                                                    <div className="img_block text-center mr-3">
                                                        <img className="profile-pic" src={require('../../../assets/images/profile_circle-1.png').default} />
                                                    </div>
                                                    <div className="docinfo">
                                                        <p className="title_name mb-md-0 mb-2"><strong>{appointment.patient.name}</strong></p>
                                                        <p className="sub_font mb-md-0 mb-1"><i className="fa fa-graduation-cap"></i> {appointment.doctor.specialization.title}</p>
                                                        <p className="sub_font mb-md-0 mb-1" style={{ cursor: 'pointer' }} onClick={(e) => { this.openGoogemap(e, appointment.doctor) }}
                                                        ><i className="fa fa-map-marker mr-2"></i> {appointment.doctor.address}</p>
                                                        <p className="sub_font mb-md-0 mb-1"><i className="fa fa-mobile-alt mr-2"></i> {appointment.doctor.phone}</p>
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    <p className="title_name mb-2"><i className="far fa-clock"></i> <strong>{appointment.scheduleTime}</strong></p>
                                                    <p className="title_name mb-2"><i className="far fa-hospital"></i> <strong>{appointment.doctor.location.name}</strong></p>
                                                    <p className="title_name" href="#"><i className="far fa-user"></i> <strong>{appointment.name}</strong></p>
                                                </div>
                                                <div className="text-right d-flex flex-column">
                                                    <Link to={{
                                                        pathname: '/user/prescription',
                                                        state: {
                                                            doctorId: appointment.doctor.id,
                                                            patientId: appointment.patient.id
                                                        }
                                                    }} className="btn btn-primary mb-2 m_font_10"  >View Prescription</Link>

                                                    <Link to={{
                                                        pathname: '/user/invoice',
                                                        state: {
                                                            doctorId: appointment.doctor.id,
                                                            patientId: appointment.patient.id
                                                        }
                                                    }} className="btn btn-primary m_font_10 mb-2" >View Invoice</Link>
                                                    <Link className='btn btn-primary m_font_10' data-toggle="modal" data-target="#viewMedicalHistoryModal">View Medical History</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })} */}

                        </div>


                    </div>
                </div>
            </div>

            {/* View medical history modal */}
            <div class="modal fade" id="viewMedicalHistoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Medical History (Ramesh Sharma)</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="home-tab" data-toggle="tab" href="#medicalprofile" role="tab" aria-controls="home" aria-selected="true">Patient's Medical Profile</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#myappointment" role="tab" aria-selected="true">Patient's Appointments </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#labResult" role="tab" aria-selected="true">Lab Result </a>
                                </li>
                            </ul>

                            <div className="tab-content pt-2">
                                <div className="tab-pane fade show active" id="medicalprofile" role="tabpanel">
                                    <div className="row">
                                        <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <div className="mb-3">
                                                        <img src={require('../../../assets/icons/blood-type.png').default} alt="blood group" width="65" />
                                                    </div>
                                                    <h5>Blood group</h5>
                                                    <h6>A+</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <div className="mb-3">
                                                        <img src={require('../../../assets/icons/age.png').default} alt="age" width="65" />
                                                    </div>
                                                    <h5>Age</h5>
                                                    <h6>32 Years</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <div className="mb-3">
                                                        <img src={require('../../../assets/icons/weight.png').default} alt="weight" width="65" />
                                                    </div>
                                                    <h5>Weight</h5>
                                                    <h6>72 kg</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <div className="mb-3">
                                                        <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65" />
                                                    </div>
                                                    <h5>Gender</h5>
                                                    <h6>Male</h6>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <div className="mb-3">
                                                        <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65" />
                                                    </div>
                                                    <h5>Insurance</h5>
                                                    <h6>Medcare</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <div className="mb-3">
                                                        <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65" />
                                                    </div>
                                                    <h5>Any Disabilities</h5>
                                                    <h6>No</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <div className="mb-3">
                                                        <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65" />
                                                    </div>
                                                    <h5>HIV</h5>
                                                    <h6>No</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <div className="mb-3">
                                                        <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65" />
                                                    </div>
                                                    <h5>Medical Condition</h5>
                                                    <h6>Common</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="myappointment" role="tabpanel">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="d-flex justify-content-between align-items-center p-2 card flex-md-row flex-column">
                                                <div className="d-flex align-items-center flex-md-row flex-column w-100">
                                                    <div className="img_block mr-2">
                                                        <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt="" />
                                                    </div>
                                                    <div className="docinfo">
                                                        <Link className="text-primary" to="/hospital/liveappointmentid123">
                                                            <strong>Dr. Wilson</strong>
                                                        </Link>
                                                        <br />
                                                        <p className="m_font_10"><strong>Appointment ID Number : </strong>1</p>
                                                        <Link className="m_font_10" to="tel:12345789">
                                                            <i className="fa fa-mobile-alt" ></i>
                                                            <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                        </Link>
                                                        <p className="m_font_10">
                                                            <i className="fa fa-map-marker"></i>
                                                            <span style={{ paddingLeft: '4px' }}>Apollo Delhi</span>
                                                        </p>
                                                        <div className='moreBtns'>
                                                            <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="Upcoming">Upcoming</Link>
                                                            <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="View Details">View Details</Link>
                                                            <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="Reschedule">Reschedule</Link>
                                                            <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="Review">Review</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className='d-md-none d-block' />
                                                <div className="d-flex justify-content-between align-items-center flex-md-column flex-row m-100">
                                                    <div className='w-100'>
                                                        <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 12-09-2022</p>
                                                        <p className="mb-1 small"><i className="far fa-clock"></i> 1:30pm</p>
                                                    </div>
                                                    <div className='w-100'>
                                                        <p className='fw-bold small'><i className="far fa-clock"></i> Slot time-online</p>
                                                        <Link to="#" className="mb-1 small text-primary"><i className="far fa-clock"></i> Google meet link</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="d-flex justify-content-between align-items-center p-2 card flex-md-row flex-column">
                                                <div className="d-flex align-items-center flex-md-row flex-column w-100">
                                                    <div className="img_block mr-2">
                                                        <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt="" />
                                                    </div>
                                                    <div className="docinfo">
                                                        <Link className="text-primary" to="/hospital/liveappointmentid123">
                                                            <strong>Dr. Jack</strong>
                                                        </Link>
                                                        <br />
                                                        <p className="m_font_10"><strong>Appointment ID Number : </strong>1</p>
                                                        <Link className="m_font_10" to="tel:12345789">
                                                            <i className="fa fa-mobile-alt" ></i>
                                                            <span style={{ paddingLeft: '4px' }}>12345789</span>
                                                        </Link>
                                                        <p className="m_font_10">
                                                            <i className="fa fa-map-marker"></i>
                                                            <span style={{ paddingLeft: '4px' }}>Apollo Mumbai</span>
                                                        </p>

                                                        <div className='moreBtns'>
                                                            <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Completed</Link>
                                                            <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">View Details</Link>
                                                            <Link className="btn btn-primary btn-sm mr-2" to="/user/book/appointment" role="button">Book again</Link>
                                                            <Link className="btn btn-primary btn-sm mr-2" to="/user/book/appointment" role="button">Diagosis</Link>
                                                            <Link className="btn btn-primary btn-sm mr-2" to="/user/prescription" role="button">Prescription</Link>
                                                            <Link className="btn btn-primary btn-sm mr-2" to="/user/invoice" role="button">Invoice</Link>
                                                            <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Review</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className='d-md-none d-block' />
                                                <div className="d-flex justify-content-between align-items-center flex-md-column flex-row m-100">
                                                    <div className="w-100">
                                                        <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 12-09-2022</p>
                                                        <p className="mb-1 small"><i className="far fa-clock"></i> 1:30pm</p>
                                                    </div>
                                                    <div className="w-100">
                                                        <p className='fw-bold small'><i className="far fa-clock"></i> General time-online</p>
                                                        <Link to="#" className="mb-1 small text-primary"><i className="far fa-clock"></i> Google meet link</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="labResult" role="tabpanel">
                                    <div className="col-md-12 col-12 text-center">
                                        <button className='btn btn-outline-primary text-right shareBtn mt-5' data-toggle="modal" data-target="#confirmRequest">Request Access</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal fade" id="confirmRequest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Confirm Request</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Are you sure you want to request access?
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                            <button type="button" class="btn btn-primary">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

const mapDispatchToProps = dispatch => ({
    getPatientListAction: (params) => dispatch(getPatientListAction(params)),
    getPatientMedicalHistory: (params) => dispatch(getPatientMedicalHistory(params)),
    getPatientMedicalProfileAction: (params) => dispatch(getPatientMedicalProfileAction(params)),

})

const mapStateToProps = state => {
    return {
        patientInvoiceReport:
            state.mainReducer?.allPatientInvoiceReportReducer?.response?.data || [],
        patientsList:
            state.mainReducer?.getPatientListReducer?.response?.data || [],

    }

}

export default connect(mapStateToProps, mapDispatchToProps)(PatientHistoryComponent)

// class PatientHistoryComponent extends Component{

// 	constructor(props){
//         super(props);
//         this.state = {
//             userAppointment:RescheduleDoctors,
//             searchText:''
//         }

//     }
//     selectedFilter = (selectedVal) => {
//       console.log(" menu selescted ");
//     }
//    openGoogemap =(e, doctor) =>{
//       if(doctor != null && doctor !=undefined){
//           window.open("https://maps.google.com/?q="+doctor.lattitude+","+doctor.longitude);
//       }
//     }

//     filterPatients = (e) =>{
//       var filterText=e.target.value;
//       var patientList=RescheduleDoctors;
//       var fiilteredpatients= patientList.filter(function(v, i) {
//         if (v["name"].includes(filterText)|| v["phone"].toString().indexOf(filterText) > -1 ){
//             console.log("matched with "+v["phone"].toString()+" name ="+v["name"]);
//             return true;
//         };
//       });
//       console.log(JSON.stringify(fiilteredpatients));
//       this.setState({
//           userAppointment:fiilteredpatients,
//           searchText:filterText
//         });
//     }


//     render() {
//         return (
//         <React.Fragment>
//             <div className="row">
//                 <div className="col-md-12 d-none d-md-block">
//                     <HospitalFilterComponent onSelectFilter={this.selectedFilter} />
//                 </div>
//                 <div className="col-md-12 mt-md-3 mt-1">
//                         <div className="col-md-12">
//                             <form className="desk_form">
//                                 <i className="fa fa-search search_icon"></i>
//                                 <input className="location form-control" list="specialization" name="browser"
//                                 placeholder="Search Patient / Patient ID / Phone Number"
//                                 value={this.state.searchText}
//                                 onChange={ (e) => {this.filterPatients(e)}}
//                                 />
//                             </form>
//                         </div>




//                     <div className="scroll_upcoming">
//                         <div className="row">
//                             <div className="col-md-12 text-center">
//                                 <div class="accordion" id="accordionExample">
//                                     <div class="card shadow mb-2">
//                                         <div class="card-header" id="headingOne">
//                                             <h2 class="mb-0">
//                                                 <button class="btn btn-primary btn-block text-center" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
//                                                 All Patient Invoice Report
//                                                 </button>
//                                             </h2>
//                                         </div>

//                                         <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
//                                             <div class="card-body">
//                                             <PatientInvoiceReport />

//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         {/* 1 */}
//                         {(this.state.userAppointment !== undefined && this.state.userAppointment !== null && this.state.userAppointment.length > 0 ) ?
//                             this.state.userAppointment.map((appointment, i) => {
//                                 return (
//                                     <div className="col-md-6">
//                                         <div className="card" key={i}>
//                                             <div className='dashboard_box listingBlock box_bg'>
//                                                 <div className='d-flex justify-content-start'>
//                                                     <div className="img_block text-center mr-3">
//                                                         <img className="profile-pic" src={require('../../../assets/images/profile_circle-1.png').default} />
//                                                     </div>
//                                                     <div className="docinfo">
//                                                         <p className="title_name mb-md-0 mb-2"><strong>{appointment.patient.name}</strong></p>
//                                                         <p className="sub_font mb-md-0 mb-1"><i className="fa fa-graduation-cap"></i> {appointment.doctor.specialization.title}</p>
//                                                         <p className="sub_font mb-md-0 mb-1" style={{cursor:'pointer'}} onClick={ (e) => {this.openGoogemap(e,appointment.doctor)}}
//                                                         ><i className="fa fa-map-marker mr-2"></i> {appointment.doctor.address}</p>
//                                                         <p className="sub_font mb-md-0 mb-1"><i className="fa fa-mobile-alt mr-2"></i> {appointment.doctor.phone}</p>
//                                                     </div>
//                                                 </div>
//                                                 <div className="mt-2">
//                                                     <p className="title_name mb-2"><i className="far fa-clock"></i> <strong>{appointment.scheduleTime}</strong></p>
//                                                     <p className="title_name mb-2"><i className="far fa-hospital"></i> <strong>{appointment.doctor.location.name}</strong></p>
//                                                     <p className="title_name" href="#"><i className="far fa-user"></i> <strong>{appointment.name}</strong></p>
//                                                 </div>
//                                                 <div className="text-right d-flex flex-column">
//                                                     <Link to={{ pathname:'/user/prescription',
//                                                         state: {
//                                                             doctorId: appointment.doctor.id,
//                                                             patientId: appointment.patient.id
//                                                         }
//                                                     }} className="btn btn-primary mb-2 m_font_10"  >View Prescription</Link>

//                                                     <Link to={{ pathname:'/user/invoice',
//                                                         state: {
//                                                             doctorId: appointment.doctor.id,
//                                                             patientId: appointment.patient.id
//                                                         }
//                                                     }} className="btn btn-primary m_font_10 mb-2" >View Invoice</Link>
//                                                     <Link className='btn btn-primary m_font_10' data-toggle="modal" data-target="#viewMedicalHistoryModal">View Medical History</Link>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )
//                             }) : ''}

//                         </div>


//                     </div>
//                 </div>
//             </div>

//             {/* View medical history modal */}
//             <div class="modal fade" id="viewMedicalHistoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//                 <div class="modal-dialog modal-xl modal-dialog-centered">
//                     <div class="modal-content">
//                         <div class="modal-header">
//                             <h5 class="modal-title" id="exampleModalLabel">Medical History (Ramesh Sharma)</h5>
//                             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
//                             <span aria-hidden="true">&times;</span>
//                             </button>
//                         </div>
//                         <div class="modal-body">
//                             <ul className="nav nav-tabs" id="myTab" role="tablist">
//                                 <li className="nav-item">
//                                     <a className="nav-link active" id="home-tab" data-toggle="tab" href="#medicalprofile" role="tab" aria-controls="home" aria-selected="true">Patient's Medical Profile</a>
//                                 </li>
//                                 <li className="nav-item">
//                                     <a className="nav-link" data-toggle="tab" href="#myappointment" role="tab" aria-selected="true">Patient's Appointments </a>
//                                 </li>
//                                 <li className="nav-item">
//                                     <a className="nav-link" data-toggle="tab" href="#labResult" role="tab" aria-selected="true">Lab Result </a>
//                                 </li>
//                             </ul>

//                             <div className="tab-content pt-2">
//                                 <div className="tab-pane fade show active" id="medicalprofile" role="tabpanel">
//                                     <div className="row">
//                                         <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
//                                             <div className="card">
//                                                 <div className="card-body text-center">
//                                                     <div className="mb-3">
//                                                         <img src={require('../../../assets/icons/blood-type.png').default} alt="blood group" width="65"/>
//                                                     </div>
//                                                     <h5>Blood group</h5>
//                                                     <h6>A+</h6>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
//                                             <div className="card">
//                                                 <div className="card-body text-center">
//                                                     <div className="mb-3">
//                                                         <img src={require('../../../assets/icons/age.png').default} alt="age" width="65"/>
//                                                     </div>
//                                                     <h5>Age</h5>
//                                                     <h6>32 Years</h6>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
//                                             <div className="card">
//                                                 <div className="card-body text-center">
//                                                     <div className="mb-3">
//                                                         <img src={require('../../../assets/icons/weight.png').default} alt="weight" width="65"/>
//                                                     </div>
//                                                     <h5>Weight</h5>
//                                                     <h6>72 kg</h6>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
//                                             <div className="card">
//                                                 <div className="card-body text-center">
//                                                     <div className="mb-3">
//                                                         <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
//                                                     </div>
//                                                     <h5>Gender</h5>
//                                                     <h6>Male</h6>
//                                                 </div>
//                                             </div>
//                                         </div>

//                                         <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
//                                             <div className="card">
//                                                 <div className="card-body text-center">
//                                                     <div className="mb-3">
//                                                         <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
//                                                     </div>
//                                                     <h5>Insurance</h5>
//                                                     <h6>Medcare</h6>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
//                                             <div className="card">
//                                                 <div className="card-body text-center">
//                                                     <div className="mb-3">
//                                                         <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
//                                                     </div>
//                                                     <h5>Any Disabilities</h5>
//                                                     <h6>No</h6>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
//                                             <div className="card">
//                                                 <div className="card-body text-center">
//                                                     <div className="mb-3">
//                                                         <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
//                                                     </div>
//                                                     <h5>HIV</h5>
//                                                     <h6>No</h6>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-6 col-md-6 col-lg-4 col-xl-3 patient-dashboard-top">
//                                             <div className="card">
//                                                 <div className="card-body text-center">
//                                                     <div className="mb-3">
//                                                         <img src={require('../../../assets/icons/gender.png').default} alt="gender" width="65"/>
//                                                     </div>
//                                                     <h5>Medical Condition</h5>
//                                                     <h6>Common</h6>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="tab-pane fade" id="myappointment" role="tabpanel">
//                                     <div className="row">
//                                         <div className="col-md-6">
//                                             <div className="d-flex justify-content-between align-items-center p-2 card flex-md-row flex-column">
//                                                 <div className="d-flex align-items-center flex-md-row flex-column w-100">
//                                                     <div className="img_block mr-2">
//                                                         <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
//                                                     </div>
//                                                     <div className="docinfo">
//                                                         <Link className="text-primary" to="/hospital/liveappointmentid123">
//                                                             <strong>Dr. Wilson</strong>
//                                                         </Link>
//                                                         <br />
//                                                         <p className="m_font_10"><strong>Appointment ID Number : </strong>1</p>
//                                                         <Link className="m_font_10" to="tel:12345789">
//                                                             <i className="fa fa-mobile-alt" ></i>
//                                                             <span style={{ paddingLeft: '4px' }}>12345789</span>
//                                                         </Link>
//                                                         <p className="m_font_10">
//                                                             <i className="fa fa-map-marker"></i>
//                                                             <span style={{ paddingLeft: '4px' }}>Apollo Delhi</span>
//                                                         </p>
//                                                         <div className='moreBtns'>
//                                                             <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="Upcoming">Upcoming</Link>
//                                                             <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="View Details">View Details</Link>
//                                                             <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="Reschedule">Reschedule</Link>
//                                                             <Link className="btn btn-primary btn-sm mr-2" to="#" role="button" data-toggle="tooltip" data-placement="bottom" title="Review">Review</Link>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <hr className='d-md-none d-block' />
//                                                 <div className="d-flex justify-content-between align-items-center flex-md-column flex-row m-100">
//                                                     <div className='w-100'>
//                                                         <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 12-09-2022</p>
//                                                         <p className="mb-1 small"><i className="far fa-clock"></i> 1:30pm</p>
//                                                     </div>
//                                                     <div className='w-100'>
//                                                         <p className='fw-bold small'><i className="far fa-clock"></i> Slot time-online</p>
//                                                         <Link to="#" className="mb-1 small text-primary"><i className="far fa-clock"></i> Google meet link</Link>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>

//                                         <div className="col-md-6">
//                                             <div className="d-flex justify-content-between align-items-center p-2 card flex-md-row flex-column">
//                                                 <div className="d-flex align-items-center flex-md-row flex-column w-100">
//                                                     <div className="img_block mr-2">
//                                                         <img className='mr-2' src={require('../../../assets/images/profile_circle-1.png').default} width="80" alt=""/>
//                                                     </div>
//                                                     <div className="docinfo">
//                                                         <Link className="text-primary" to="/hospital/liveappointmentid123">
//                                                             <strong>Dr. Jack</strong>
//                                                         </Link>
//                                                         <br />
//                                                         <p className="m_font_10"><strong>Appointment ID Number : </strong>1</p>
//                                                         <Link className="m_font_10" to="tel:12345789">
//                                                             <i className="fa fa-mobile-alt" ></i>
//                                                             <span style={{ paddingLeft: '4px' }}>12345789</span>
//                                                         </Link>
//                                                         <p className="m_font_10">
//                                                             <i className="fa fa-map-marker"></i>
//                                                             <span style={{ paddingLeft: '4px' }}>Apollo Mumbai</span>
//                                                         </p>

//                                                         <div className='moreBtns'>
//                                                             <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Completed</Link>
//                                                             <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">View Details</Link>
//                                                             <Link className="btn btn-primary btn-sm mr-2" to="/user/book/appointment" role="button">Book again</Link>
//                                                             <Link className="btn btn-primary btn-sm mr-2" to="/user/book/appointment" role="button">Diagosis</Link>
//                                                             <Link className="btn btn-primary btn-sm mr-2" to="/user/prescription" role="button">Prescription</Link>
//                                                             <Link className="btn btn-primary btn-sm mr-2" to="/user/invoice" role="button">Invoice</Link>
//                                                             <Link className="btn btn-primary btn-sm mr-2" to="#" role="button">Review</Link>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <hr className='d-md-none d-block' />
//                                                 <div className="d-flex justify-content-between align-items-center flex-md-column flex-row m-100">
//                                                     <div className="w-100">
//                                                         <p className="mb-1 small"><i className="fas fa-calendar-alt"></i> 12-09-2022</p>
//                                                         <p className="mb-1 small"><i className="far fa-clock"></i> 1:30pm</p>
//                                                     </div>
//                                                     <div className="w-100">
//                                                         <p className='fw-bold small'><i className="far fa-clock"></i> General time-online</p>
//                                                         <Link to="#" className="mb-1 small text-primary"><i className="far fa-clock"></i> Google meet link</Link>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="tab-pane fade" id="labResult" role="tabpanel">
//                                     <div className="col-md-12 col-12 text-center">
//                                         <button className='btn btn-outline-primary text-right shareBtn mt-5' data-toggle="modal" data-target="#confirmRequest">Request Access</button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>


//             <div class="modal fade" id="confirmRequest" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//                 <div class="modal-dialog modal-dialog-centered">
//                     <div class="modal-content">
//                         <div class="modal-header">
//                             <h5 class="modal-title" id="exampleModalLabel">Confirm Request</h5>
//                             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
//                             <span aria-hidden="true">&times;</span>
//                             </button>
//                         </div>
//                         <div class="modal-body">
//                             Are you sure you want to request access?
//                         </div>
//                         <div class="modal-footer">
//                             <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
//                             <button type="button" class="btn btn-primary">Yes</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>


//         </React.Fragment>

//         )
//     }
// }

// export default PatientHistoryComponent;