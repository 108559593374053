import React, { Component } from "react";
import { Link } from "react-router-dom";
import '../../assets/style.css';


class HospitalSubHeaderComponent extends Component{
    state = {
        links: [
          {
            id: 1,
            name: " Dashboard",
            to: "/hospital/dashboard",
            className: "fas fa-desktop"
          },
          {
            id: 2,
            name: " Live Appointment",
            to: "/hospital/liveappointments",
            className: "fas fa-address-book"
          },
          {
            id: 3,
            name: " All Appointments",
            to: "/hospital/allappointment",
            className: "fas fa-calendar-check"
          },
          {
            id: 4,
            name: "Book Appointment",
            to: "/hospital/newappointment",
            className: "fas fa-search"
          },
          {
            id: 5,
            name: "Patient History",
            to: "/hospital/patientHistory",
            className: "fas fa-question"
          }
        ],
        activeLink: null
      };

      handleClick = id => {
        this.setState({ activeLink: id });
      };


      render() {
        const { links, activeLink } = this.state;

        return (
          <React.Fragment>
            {links.map(link => {
              return (
                <div key={link.id} className="main_tabs w-100">
                    <Link to={{
                      pathname: link.to,
                      state: {
                        link: link,
                        key:Math.random()
                      }
                    }}
                      onClick={() => this.handleClick(link.id)}
                      className={link.id === activeLink ? "menuactive" : ""}

                    >
                        {(this.props.screen === 'mobile' ) ? <React.Fragment><i style={{fontSize: "16px"}} className={link.className}></i></React.Fragment>: ''}
                      {link.name}
                    </Link>
                </div>
              );
            })}
          </React.Fragment>
        );
      }


}

export default HospitalSubHeaderComponent;