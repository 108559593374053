export const ROUTE_DASHBOARD='/';
export const ROUTE_USER_LOGIN='/userLogin';
export const ROUTE_HOSPITAL_LOGIN='/hospitalLogin';
export const ROUTE_SEARCH='/search';
export const ROUTE_USER_SEARCH='/user/search';
export const ROUTE_USER_DASHBOARD='/user/dashboard';
export const ROUTE_USER_PROFILE="/user/profile";
export const ROUTE_USER_MYAPPOINTMENT="/user/myappointment";
export const ROUTE_USER_HELP="/user/help";
export const ROUTE_USER_RESCHEDULE="/user/reschedule";
export const ROUTE_USER_INVOICE="/user/invoice";
export const ROUTE_USER_PRESCRIPTION="/user/prescription";
export const ROUTE_USER_NEW_APPOINTMENT="/user/book/appointment";
export const ROUTE_USER_NEAR_BY_SEARCH="/user/nearby/search";
export const ROUTE_USER_ALL_INVOICE="/user/allInvoice";
export const ROUTE_USER_ALL_PRESCRIPTION="/user/allPrescription";

