import React, { Component } from 'react';
import { Route, Link, withRouter } from "react-router-dom";
import * as $ from 'jquery';
import './NewAppointment.css';
import HospitalFilterComponent from '../../Search/SearchFilter/HospitalFilter';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

class NewAppointmentComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitAppointmentSuccess: false
		};
	}

	componentDidMount() {
		$(".blood_btn").on('click', function () {
			$(".blood_btn").removeClass('selected');
			$(this).addClass('selected');
		});

		$(".emergen_btn").on('click', function () {
			$(".emergen_btn").removeClass('selected');
			$(this).addClass('selected');
		});

		$(".ex_btn").on('click', function () {
			$(".ex_btn").removeClass('selected');
			$(this).addClass('selected');
		});

		$(".police_btn").on('click', function () {
			$(".police_btn").removeClass('selected');
			$(this).addClass('selected');
		});

		$(".confirmBtn .selectSlot").on("click", function (){
			$(".confirmBtn .confirm").remove();
            $(this).parent().append("<button type='button' class='btn btn-outline-secondary confirm'>Confirm</button>");
        });
	}

	selectedFilter = (selectedVal) => {
		setTimeout(() => {
			this.setState({ submitAppointmentSuccess: true });
			$("#appbook_success").modal('show');
		}, 10);
	}

	state = {
		date: new Date(),
	}
	onChange = date => this.setState({ date })
	render() {

		return (
			<React.Fragment>
				<div className="row">
					{/* <div className="col-md-12">
						<HospitalFilterComponent onSelectFilter={this.selectedFilter} />
					</div> */}
					<div className="col-md-12 mt-md-3">
						<div className="newappoint">
							<form className="desk_form mb-3">
								<i className="fa fa-search search_icon"></i>
								<input className="pl-5 location form-control" list="specialization" name="browser" placeholder="Search Doctor / Hospital" />
								<datalist id="specialization">
									<option value="Dentist" />
									<option value="Dermetologist" />
									<option value="Orthopedic Surgeon" />
									<option value="Cardiologist" />
									<option value="Neurologist" />
									<option value="Padiatrician" />
									<option value="Gynecologist" />
									<option value="Sexologyst(M/F)" />
									<option value="ENT Specialist" />
									<option value="EYE Specialist" />
									<option value="Hair Transplant" />
									<option value="Animal Doctor" />
								</datalist>
							</form>

							<form>
								<div className="row">
									<div className="col-md-12">
										<p className="float-left mb-2"><strong>Doctor Name / Hospital Name (Dynamic)</strong></p>
										<p className="float-right"><strong>ID Number : </strong>00092XYZ</p>
									</div>
									<div className="col-md-3 col-6 mt-2 mt-md-0">
										<input type="text" className="form-control" placeholder="Name" />
									</div>
									<div className="col-md-3 col-6 mt-2 mt-md-0">
										<input type="text" className="form-control" placeholder="Phone Number" />
									</div>
									<div className="col-md-3 col-6 mt-2 mt-md-0">
										<a href="#" className='btn btn-primary w-100' data-toggle="modal" data-target="#generalTime">General Time</a>
									</div>
									<div className="col-md-3 col-6 mt-2 mt-md-0">
										<a href="#" className='btn btn-primary w-100' data-toggle="modal" data-target="#slotTime">Slot Time</a>
									</div>
									<div className="col-md-3 col-12 mt-2">
										<input type="text" className="form-control" placeholder="Address" />
									</div>
									<div className="col-md-3 col-12 mt-2">
										<input type="email" className="form-control " placeholder="Email (Optional)" />
									</div>
									<div className="col-md-3 col-6 mt-2">
										<input type="text" className="form-control" placeholder="City Name" />
									</div>
									<div className="col-md-3 col-6 mt-2">
										<input type="text" className="form-control" placeholder="Pin Code" />
									</div>
									<div className="col-md-12 mt-2">
										<textarea className="form-control" cols="20" rows="1" placeholder="Comment"></textarea>
									</div>
								</div>
							</form>
							<h4 className="primary_text mt-2 mb-2"><strong>Extra Information</strong></h4>
							<div className="row blood_group">
								<div className="col-md-3 col-12 text-center" id="myDIV">
									<div className='card mb-3'>
										<h6><strong>Blood Group</strong></h6>
										<div className="d-flex justify-content-around btns mt-3">
											<p className="blood_btn">A+</p>
											<p className="blood_btn">A-</p>
										</div>
										<div className="d-flex justify-content-around btns">
											<p className="blood_btn">B+</p>
											<p className="blood_btn">B-</p>
										</div>
										<div className="d-flex justify-content-around btns">
											<p className="blood_btn">AB+</p>
											<p className="blood_btn">AB-</p>
										</div>
										<div className="d-flex justify-content-around btns">
											<p className="blood_btn">O+</p>
											<p className="blood_btn">O-</p>
										</div>
										<div className="d-flex justify-content-around btns">
											<p className="blood_btn">Don't Know</p>
										</div>
									</div>
								</div>
								<div className="col-md-3 col-12 text-center">
									<div className="card mb-3">
										<h6><strong>Police Case ?</strong></h6>
										<div className="d-flex justify-content-around mt-3">
											<p className="police_btn">Yes</p>
											<p className="police_btn">No</p>
										</div>
									</div>
								</div>
								<div className="col-md-3 col-12 text-center">
									<div className="card mb-3">
										<h6><strong>Emergency Type</strong></h6>
										<div className="d-flex justify-content-around mt-3">
											<p className="emergen_btn">Emergency</p>
											<p className="emergen_btn">Normal</p>
										</div>
									</div>
								</div>
								<div className="col-md-3 col-12 text-center">
									<div className="card mb-3">
										<h6><strong>Case Type</strong></h6>
										<div className="d-flex justify-content-around mt-3">
											<p className="ex_btn">New Case</p>
											<p className="ex_btn">Old Case</p>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 mb-5 mb-md-0 mt-md-0 mt-2"> <a className="btn btn-primary float-right mb-5" href="#" data-toggle="modal" data-target="#appbook_success" onClick={this.submitAppointment}>Submit</a> </div>
							</div>
							<div id="appbook_success" className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
								<div className="modal-dialog modal-lg modal-dialog-centered" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h6 className="modal-title">Appointment Confirmed</h6>
											<button type="button" className="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
										</div>
										<div className="modal-body">
											<div className="row">
												<div className="col-md-6">
													<ul className="list-group">
														<li className="list-group-item d-flex justify-content-between align-items-center">
															<p className='fw-bold'>on 30th Sept, 2022</p>
															<p className='fw-bold'>at 12:50PM</p>
														</li>
														<li className="list-group-item d-flex justify-content-start align-items-center">
															<div className='mr-2'>
																<img width="80" src={require('../../../assets/images/home/2.webp').default} alt="doctor" />
															</div>
															<div>
																<p className='fw-bold'>Dr. Wilson</p>
																<p className='text-muted'>MBBS</p>
																<p className='text-muted'>General Physician</p>
															</div>
														</li>
														<li className="list-group-item d-flex justify-content-start align-items-center">
															<div className='mr-2'>
																<img width="80" src={require('../../../assets/images/home/2.webp').default} alt="doctor" />
															</div>
															<div>
																<p className='fw-bold'>Apollo Delhi</p>
																<p className='text-muted'>Lalkila, Delhi</p>
																<Link to="#" className='text-primary fw-bold'>Get Direction</Link>
															</div>
														</li>
													</ul>
												</div>
												<div className="col-md-6">
													<h5 className='fw-bold'>Appointment Confirmed</h5>
													<p className='mb-3'>This appointment is guaranteed by Aajivan.</p>
													<p className='text-success'>Your appointment id is <strong>ID123654</strong></p>
													<p className='text-success mb-3'>We have sent you an email and SMS with the details.</p>

													<p><strong>Patient Name:</strong> Ramesh Sharma</p>
													<p><strong>Email:</strong> demo@gmail.com</p>
													<p><strong>Mobile:</strong> 1236547898</p>
												</div>
											</div>
										</div>
										<div className="modal-footer">
											<button type="button" className="btn btn-secondary" data-dismiss="modal">Ok</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				{/* general time modal */}
				<div className="modal fade" id="generalTime" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLabel">Select Appointment Date</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-12">
										<div className="card">
											<div className="card-body">
												<div className="booking-doc-info d-flex justify-content-between align-items-center">
													<div className="d-flex">
														<a href="#" className="booking-doc-img border aounded">
															<img src={require('../../../assets/images/home/doctor.png').default} alt="User Image"/>
														</a>
														<div className="booking-info">
															<h5><a href="#">Dr. Wilson</a></h5>
															<p className="doc-speciality mb-1">BDS, MDS - Oral & Maxillofacial Surgery</p>
															<p className="doc-department"><i className="fas fa-user-md"></i> Dentist</p>
														</div>
													</div>

													<div className="dateSelect">
														<label htmlFor="select date">Select Appointment Date</label>
														<input type="date"  className='form-control'/>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12 text-right">
														<button className='btn btn-primary text-right'>Save</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>


				{/* slot time modal */}
				<div className="modal fade" id="slotTime" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLabel">Select Appointment Slot</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-12">
										<div className="card">
											<div className="card-body">
												<div className="booking-doc-info">
													<a href="#" className="booking-doc-img border aounded">
														<img src={require('../../../assets/images/home/doctor.png').default} alt="User Image"/>
													</a>
													<div className="booking-info">
														<h5><a href="#">Dr. Wilson</a></h5>
														<p className="doc-speciality mb-1">BDS, MDS - Oral & Maxillofacial Surgery</p>
														<p className="doc-department"><i className="fas fa-user-md"></i> Dentist</p>
													</div>
												</div>
											</div>
										</div>

										<div className="card shadow border-0 mt-3 overflow-hidden selectTimeSlot">
											<div className="card-body p-0">
												<div className="row">
													<div className="col-md-6 p-4">
														<div className="calendar-wrap">
															<Calendar
																onChange={this.onChange}
																value={this.state.date}
															/>
															<h5 className="card-title">Timezone</h5>
															<select className="form-control select2 ">
																<option>UTC -07:00 Pacific Time (US & Canada)</option>
																<option>UTC -08:00 Arizona Time (US & Canada)</option>
															</select>
														</div>
													</div>
													<div className="col-md-6 p-4">
														<div className="d-grid gap-2 timeSlot">
															<h5 className="card-title">Available Time slot</h5>
															<div className='confirmBtn'>
																<button type="button" className="selectSlot btn btn-outline-secondary change">9:00 AM</button>
															</div>
															<div className='confirmBtn'>
																<button type="button" className="selectSlot btn btn-outline-secondary change">9:15 AM</button>
															</div>
															<div className='confirmBtn'>
																<button type="button" className="selectSlot btn btn-outline-secondary change">9:30 AM</button>
															</div>
															<div className='confirmBtn'>
																<button type="button" className="selectSlot btn btn-outline-secondary change">9:45 AM</button>
															</div>
															<div className='confirmBtn'>
																<button type="button" className="selectSlot btn btn-outline-secondary change">10:00 AM</button>
															</div>
															<div className='confirmBtn'>
																<button type="button" className="selectSlot btn btn-outline-secondary change">10:15 AM</button>
															</div>
															<div className='confirmBtn'>
																<button type="button" className="selectSlot btn btn-outline-secondary change">10:30 AM</button>
															</div>
															<div className='confirmBtn'>
																<button type="button" className="selectSlot btn btn-outline-secondary change">10:45 AM</button>
															</div>
															<div className='confirmBtn'>
																<button type="button" className="selectSlot btn btn-outline-secondary change">11:00 AM</button>
															</div>
															<div className='confirmBtn'>
																<button type="button" className="selectSlot btn btn-outline-secondary change">11:15 AM</button>
															</div>
															<div className='confirmBtn'>
																<button type="button" className="selectSlot btn btn-outline-secondary change">11:30 AM</button>
															</div>
															<div className='confirmBtn'>
																<button type="button" className="selectSlot btn btn-outline-secondary change">11:45 AM</button>
															</div>
															<div className='confirmBtn'>
																<button type="button" className="selectSlot btn btn-outline-secondary change">12:00 AM</button>
															</div>
														</div>

													</div>
												</div>
											</div>
										</div>



									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</React.Fragment>
		)
	}
}
export default withRouter(NewAppointmentComponent);