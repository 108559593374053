import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { AddStaff, EditStaff, DeleteStaff, GetStaffCategoryList, getStaffList, emptyStaffAction } from '../../../redux/Main/actions';
import { useCookies } from 'react-cookie';
import { DialogTitle, Button, TextField, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, DialogContent, DialogActions } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import DeleteModal from 'src/common/DeleteModal';
import DialogBox from 'src/common/DialogBox'
import DropDown from 'src/common/DropDown';
import TextInput from 'src/common/TextInput';
const StaffDetails = (props) => {
    const [cookies, setCookie] = useCookies(['authtoken']);

    const [state, setState] = useState({
        staff_role_id: '0',
        name: '',
        mobile: "",
        email: "",
        password: "",
        confirm_password: "",
        duty_timings: [
            {
                day: "0",
                from_time: null,
                to_time: null,
            }
        ]
    });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [staffID, setStaffID] = useState(null);
    const [onDelete, setOnDelete] = useState(false);
    const [openStaff, setOpenStaff] = useState(false);
    const [onCreate, setOnCreate] = useState(false);
    const [mode, setMode] = useState("ADD");

    useEffect(() => {
        const { authtoken = null } = cookies
        props.getStaffCateList({})
        props.getStaffList({ authToken: authtoken })
    }, [staffID, onDelete, onCreate])


    useEffect(() => {
        if (onDelete && props.deleteStaffStatus === 200) {
            props.emptyStaffAction();
            setOnDelete(false)
        }
        if ((openStaff && props.addStaffStatus === 200) || (openStaff && props.editStaffStatus === 200)) {
            setOpenStaff(false)
            setOnCreate(true)
        }

    }, [props])


    const StaffChangeHandler = (event) => {
        const { target: { name, value } = {} } = event;
        setState({ ...state, [name]: value })

    }
    const DutyChangeHandler = (event, idx) => {

        const { target: { name, value } = {} } = event;
        let obj = { ...state };

        obj.duty_timings[idx][name] = value;
        setState(obj)
    }

    const DeleteHandler = (e) => {

        if (staffID) {
            props.DeleteStaff({ authToken: cookies.authtoken, staff_id: staffID })
        }

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const EditStaffHandler = (e) => {
        e.preventDefault();

        const params = {
            authToken: cookies.authtoken,
            ...state,
            day: state?.duty_timings.map(({ day }) => day)?.toString(),
            from_time: state?.duty_timings?.map(({ from_time }) => from_time)?.toString(),
            to_time: state?.duty_timings?.map(({ to_time }) => to_time)?.toString(),
        }
        delete params.duty_timings;

        console.log(params)

        props.EditStaff(params)

    }

    const AddStaffHandler = (e) => {
        e.preventDefault();

        const params = {
            authToken: cookies.authtoken,
            ...state,
            day: state?.duty_timings.map(({ day }) => day)?.toString(),
            from_time: state?.duty_timings?.map(({ from_time }) => from_time)?.toString(),
            to_time: state?.duty_timings?.map(({ to_time }) => to_time)?.toString(),
        }

        delete params.duty_timings;

        props.AddStaff(params)

    }

    const AddMoreDuties = () => {
        let objCopy = { ...state };
        const data =
        {
            day: "0",
            from_time: null,
            to_time: null,
        }

        objCopy.duty_timings?.push(data);

        setState(objCopy)
    }

    const RemoveMoreDuties = (idx) => {
        let objCopy = { ...state };
        objCopy.duty_timings?.splice(idx, 1);
        setState(objCopy)
    }

    const TimeFormate = (date, name, idx) => {
        console.log(new Date(date).getHours(), idx)
        let obj = { ...state };

        obj.duty_timings[idx][name] = date;
        setState(obj)

    }

    return (
        <>

            <div className="tab-pane fade show" id="staff" role="tabpanel" aria-labelledby="home-tab">
                <div className="mt-md-2 mt-2">
                    <div className="row">
                        <div className="col-md-12">
                            {/* <a href="#" className='btn btn-outline-primary mt-2 mb-3' data-toggle="modal" data-target="#addStaffmodel" onClick={() => openStaff(true)}>Add New Staff</a> */}
                            <Button
                                variant='contained'
                                size='small'
                                className='primary-button'
                                onClick={() => {
                                    setMode('ADD')
                                    setOpenStaff(true)
                                }}

                            >
                                Add New Staff
                            </Button>
                            <br />
                            <br />

                            {/* <Link className='btn btn-outline-primary mt-2 mb-3' href='#' data-toggle="modal" data-target="#addStaffmodel">Add New Staff</Link> */}
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table" size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Phone No</TableCell>
                                                <TableCell>Staff Category	</TableCell>
                                                <TableCell>Duty Timing		</TableCell>
                                                <TableCell>ID No</TableCell>
                                                <TableCell>Password</TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.staffList
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover key={row.user_id} rowSpan={row.duty_timings?.length}>
                                                            <TableCell>{row.name}</TableCell>
                                                            <TableCell>{row.mobile}</TableCell>
                                                            <TableCell>{row.role}</TableCell>
                                                            <TableCell>{row.name}</TableCell>
                                                            <TableCell>
                                                                <ul>
                                                                    {row.duty_timings?.map((item) => (

                                                                        <li key={item.id}>{`${item.day}: ${item.from_time} - ${item.to_time}`}</li>
                                                                    ))}
                                                                </ul>
                                                            </TableCell>
                                                            <TableCell>{row.uniqueIdNumber}</TableCell>
                                                            <TableCell>
                                                                <ul className="d-flex options">
                                                                    <li className="p-2 btn btn-sm btn-outline-primary mr-2" data-toggle="modal" data-target=".viewModal" onClick={() => setState({ ...state, staff_id: row.user_id, confirm_password: row.password, ...row, })}><i className="fas fa-eye"></i></li>
                                                                    <li className="p-2 btn btn-sm btn-outline-success mr-2" onClick={() => {
                                                                        setMode("EDIT")
                                                                        setOpenStaff(true)
                                                                        setState({ ...state, staff_id: row.user_id, ...row, confirm_password: row.password })
                                                                    }}><i className="fas fa-pencil-alt"></i></li>
                                                                    <li className="p-2 btn btn-sm btn-outline-danger" onClick={() => {
                                                                        setStaffID(row.user_id);
                                                                        setOnDelete(true);
                                                                    }}>
                                                                        <i className="fas fa-trash-alt" ></i></li>
                                                                </ul>
                                                            </TableCell>

                                                        </TableRow>
                                                    );
                                                })}


                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={props.staffList.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>
            </div>

            {onDelete &&
                <DeleteModal
                    open={onDelete}
                    handleClose={() => setOnDelete(false)}
                    handleSubmit={DeleteHandler}

                />
            }



            <DialogBox
                open={openStaff}
                handleClose={() => setOpenStaff(false)}
            >

                <DialogTitle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography variant='h6' style={{ lineHeight: 2.6 }}>
                                {mode === "ADD" ? 'Add New Staff Member' : 'View Staff Member'}

                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <DropDown
                                selectLabel={'- Select Staff Category'}
                                name={'staff_role_id'}
                                value={state?.staff_role_id}
                                onChange={(e) => StaffChangeHandler(e)}
                                options={props?.staffCateList?.map((e) => ({ value: e.roleId, name: e.role }))}
                            />
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="small-12 medium-2 large-2 columns uploadProfile">
                        <div className="circle">
                            <img className="profile-pic" src={require('../../../assets/images/user.png').default} />
                        </div>
                        <div className="p-image">
                            <i className="fa fa-camera upload-button"></i>
                            <input className="file-upload" type="file" accept="image/*" />
                        </div>
                    </div>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextInput
                                name={'name'}
                                placeholder={'Name'}
                                value={state?.name}
                                onChange={(e) => StaffChangeHandler(e)}
                                error={''}

                            />
                            <TextInput
                                name={'mobile'}
                                placeholder={'Phone Number'}
                                value={state?.mobile}
                                onChange={(e) => StaffChangeHandler(e)}
                                error={''}

                            />
                            <TextInput
                                name={'email'}
                                placeholder={'Email'}
                                value={state?.email}
                                onChange={(e) => StaffChangeHandler(e)}
                                error={''}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextInput
                                name={'password'}
                                placeholder={'Password'}
                                value={state?.password}
                                onChange={(e) => StaffChangeHandler(e)}
                                error={''}

                            />
                            <TextInput
                                name={'confirm_password'}
                                placeholder={'Confirm Password'}
                                value={state?.confirm_password}
                                onChange={(e) => StaffChangeHandler(e)}
                                error={''}

                            />

                        </Grid>
                    </Grid>

                    <Typography variant='p'>Duty Timing</Typography>
                    <br />
                    {state.duty_timings?.map((el, idx) => (

                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6} md={3}>
                                <DropDown
                                    selectLabel={"Day"}
                                    name={'day'}
                                    label={'Day'}
                                    value={el?.day}
                                    onChange={(e) => DutyChangeHandler(e, idx)}
                                    options={[
                                        { value: 'Monday', name: "Monday" },
                                        { value: 'Tuesday', name: "Tuesday" },
                                        { value: 'Wednesday', name: "Wednesday" },
                                        { value: 'Thursday', name: "Thursday" },
                                        { value: 'Friday', name: "Friday" },
                                        { value: 'Saturday', name: "Saturday" },
                                        { value: 'Sunday', name: "Sunday" }
                                    ]}


                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <TextField
                                    id="time"
                                    label="From"
                                    name='from_time'
                                    type="time"
                                    fullWidth
                                    value={state?.from_time}
                                    onChange={(e) => DutyChangeHandler(e, idx)}
                                    size='small'

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                />
                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <TimePicker
                                        label="From"
                                        value={el?.from_time}
                                        // onChange={(e) => DutyChangeHandler(e, idx)}
                                        onChange={(date) => TimeFormate(date, idx)}

                                        renderInput={(params) => <TextField {...params} size='small' />}

                                    />
                                </LocalizationProvider> */}

                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <TimePicker
                                        label="To"
                                        value={el?.to_time}
                                        onChange={(e) => DutyChangeHandler(e, idx)}
                                        renderInput={(params) => <TextField {...params} size='small' />}

                                    />
                                </LocalizationProvider> */}
                                <TextField
                                    id="time"
                                    label="To"
                                    name='to_time'
                                    type="time"
                                    fullWidth
                                    size='small'
                                    value={state.to_time}
                                    onChange={(e) => DutyChangeHandler(e, idx)}

                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        step: 300, // 5 min
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                {idx === 0 ? (
                                    <Button

                                        onClick={() => AddMoreDuties()}
                                    >+ Add More</Button>

                                )
                                    : (
                                        <Button

                                            onClick={() => RemoveMoreDuties(idx)}
                                        >- Remove</Button>
                                    )}

                            </Grid>
                        </Grid>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenStaff(false)} variant='outlined'>Close</Button>
                    <Button className='primary-button' onClick={(e) => { mode === "ADD" ? AddStaffHandler(e) : EditStaffHandler(e) }} variant='contained'>Save</Button>
                </DialogActions>


            </DialogBox>


            {/* <!-- Add new staff model --> */}
            <div className="modal fade" id='addStaffmodel' tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add New Staff Member</h5>
                            <select className='form-control w-50 ml-4' name='staff_role_id' value={state?.staff_role_id} onChange={(e) => StaffChangeHandler(e)}>
                                <option value="0">- Select Staff Category</option>
                                {props?.staffCateList?.map((e) => (
                                    <option value={e.roleId} key={e.roleId}>{e.role}</option>

                                ))}
                            </select>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="small-12 medium-2 large-2 columns uploadProfile">
                                            <div className="circle">
                                                <img className="profile-pic" src={require('../../../assets/images/user.png').default} />
                                            </div>
                                            <div className="p-image">
                                                <i className="fa fa-camera upload-button"></i>
                                                <input className="file-upload" type="file" accept="image/*" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control mb-2" placeholder="Name" name='name' value={state?.name} onChange={(e) => StaffChangeHandler(e)} />
                                        <input type="text" className="form-control mb-2" placeholder="Phone Number" name='mobile' value={state?.mobile} onChange={(e) => StaffChangeHandler(e)} />
                                        <input type="email" className="form-control mb-2" placeholder="Email" name='email' value={state?.email} onChange={(e) => StaffChangeHandler(e)} />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <input type="text" className="form-control mb-2" placeholder="Password" name='password' value={state?.password} onChange={(e) => StaffChangeHandler(e)} />
                                        <input type="text" className="form-control mb-2" placeholder="Confirm Password" name='confirm_password' value={state?.confirm_password} onChange={(e) => StaffChangeHandler(e)} />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="time">Duty Timing</label>

                                        {state.duty_timings?.map((el, idx) => (
                                            <>
                                                <div className="d-flex align-items-center justify-content-between mb-2">
                                                    <div className="row w-75 col-lg-12">
                                                        <div className="col-md-4">
                                                            <select className="form-control" name='day' value={el?.day} onChange={(e) => DutyChangeHandler(e, idx)}>
                                                                <option value="0" disabled>Day</option>
                                                                <option value="Monday">Monday</option>
                                                                <option value="Tuesday">Tuesday</option>
                                                                <option value="Wednesday">Wednesday</option>
                                                                <option value="Thursday">Thursday</option>
                                                                <option value="Friday">Friday</option>
                                                                <option value="Saturday">Saturday</option>
                                                                <option value="Sunday">Sunday</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className='d-flex justify-ceontent-between align-items-center'>
                                                                <input type="time" className="form-control" name='from_time' value={el?.from_time} onChange={(e) => DutyChangeHandler(e, idx)} />
                                                                <p className='p-2'>to</p>
                                                                <input type="time" className="form-control" name='to_time' value={el?.to_time} onChange={(e) => DutyChangeHandler(e, idx)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {idx === 0 &&

                                                        <div className='w-25'>
                                                            <button type='button' className='btn btn-outline-primary' onClick={() => AddMoreDuties()}>+ Add more</button>
                                                        </div>
                                                    }
                                                    {idx !== 0 &&

                                                        <div className='w-25'>
                                                            <button type='button' className='btn btn-outline-primary' onClick={() => RemoveMoreDuties(idx)}>- Remove</button>
                                                        </div>
                                                    }
                                                </div>
                                            </>

                                        ))}
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary" onClick={(e) => AddStaffHandler(e)}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- View model --> */}
            <div className="modal fade viewModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <h5 className="modal-title" id="exampleModalLabel">Edit Staff Member</h5> */}
                            <div>

                                <span className="customLabel">Staff Category</span>
                                <p><strong>{state?.role}</strong></p>
                            </div>

                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="">
                                <div className="row mt-4">
                                    <div className='col-lg-4'>

                                        <span className="customLabel">Name</span>
                                        <p><strong>{state?.name}</strong></p>
                                    </div>

                                    <div className='col-lg-4'>
                                        <span className="customLabel">Phone Number</span>
                                        <p><strong>{state?.mobile}</strong></p>
                                    </div>

                                    <div className='col-lg-4'>
                                        <span className="customLabel">Email</span>
                                        <p><strong>{state?.email}</strong></p>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className='col-lg-4'>
                                        <span className="customLabel">ID No</span>
                                        <p><strong>{state?.id}</strong></p>
                                    </div>
                                    <div className='col-lg-4'>
                                        <span className="customLabel">Password</span>
                                        <p><strong>{state?.password}</strong></p>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <label htmlFor="time">Duty Timing</label>
                                    {state.duty_timings?.map((el, idx) => (
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div>
                                                    <span className="customLabel">Day</span>
                                                    <p><strong>{el?.day}</strong></p>
                                                </div>

                                            </div>
                                            <div className="col-lg-8">
                                                <div className='row'>

                                                    <div className="col-md-4">
                                                        <span className="customLabel">From</span>
                                                        <p><strong>{el?.from_time}</strong></p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <span className="customLabel">To</span>
                                                        <p><strong>{el?.to_time}</strong></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- View model --> */}
            <div className="modal fade editemodel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">View Staff Member</h5>
                            <select className='form-control w-50 ml-4' name='staff_role_id' value={state?.staff_role_id} onChange={(e) => StaffChangeHandler(e)}>
                                <option value="0">- Select Staff Category</option>
                                {props?.staffCateList?.map((e) => (
                                    <option value={e.roleId} key={e.roleId}>{e.role}</option>
                                ))}
                            </select>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="">
                                <div className="row mt-4">
                                    <div className="col-md-6">
                                        <input type="text" className="form-control mb-2" placeholder="Name" name='name' value={state?.name} onChange={(e) => StaffChangeHandler(e)} />
                                        <input type="text" className="form-control mb-2" placeholder="Phone Number" name='mobile' value={state?.mobile} onChange={(e) => StaffChangeHandler(e)} />
                                        <input type="email" className="form-control mb-2" placeholder="Email" name='name' value={state?.email} onChange={(e) => StaffChangeHandler(e)} />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <input type="text" className="form-control mb-2" placeholder="ID No." name='id' value={state?.id} onChange={(e) => StaffChangeHandler(e)} disabled />
                                        <input type="text" className="form-control mb-2" placeholder="Password" name='password' value={state?.password} onChange={(e) => StaffChangeHandler(e)} />
                                        <input type="text" className="form-control mb-2" placeholder="Confirm Password" name='confirm_password' value={state?.confirm_password} onChange={(e) => StaffChangeHandler(e)} />
                                    </div>

                                    <div className="col-md-12">
                                        <label htmlFor="time">Duty Timing</label>
                                        {state.duty_timings?.map((el, idx) => (
                                            <>
                                                <div className="d-flex align-items-center justify-content-between mb-2">
                                                    <div className="row w-75 col-lg-12">
                                                        <div className="col-md-4">
                                                            <select className="form-control" name='day' value={el?.day} onChange={(e) => DutyChangeHandler(e, idx)}>
                                                                <option value="0" disabled>Day</option>
                                                                <option value="Monday">Monday</option>
                                                                <option value="Tuesday">Tuesday</option>
                                                                <option value="Wednesday">Wednesday</option>
                                                                <option value="Thursday">Thursday</option>
                                                                <option value="Friday">Friday</option>
                                                                <option value="Saturday">Saturday</option>
                                                                <option value="Sunday">Sunday</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className='d-flex justify-ceontent-between align-items-center'>
                                                                <input type="time" className="form-control" name='from_time' value={el?.from_time} onChange={(e) => DutyChangeHandler(e, idx)} />
                                                                <p className='p-2'>to</p>
                                                                <input type="time" className="form-control" name='to_time' value={el?.to_time} onChange={(e) => DutyChangeHandler(e, idx)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {idx === 0 &&

                                                        <div className='w-25'>
                                                            <button type='button' className='btn btn-outline-primary' onClick={() => AddMoreDuties()}>+ Add more</button>
                                                        </div>
                                                    }
                                                    {idx !== 0 &&

                                                        <div className='w-25'>
                                                            <button type='button' className='btn btn-outline-primary' onClick={() => RemoveMoreDuties(idx)}>- Remove</button>
                                                        </div>
                                                    }
                                                </div>
                                            </>

                                        ))}
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={(e) => EditStaffHandler(e)}>Save</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Delete model --> */}
            <div className="modal fade deletemodel" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">Delete Staff Member</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form action="">
                                <div className="row mt-2">
                                    <div className="col-md-12 text-center">
                                        <p><i className="fas fa-exclamation-circle modal_icon mb-2"></i></p>
                                        <h4>Are You Sure?</h4>
                                        <p>You Want to Delete this Record?</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary" data-dismiss="modal">No</button>
                            <button type="button" className="btn btn-primary" onClick={(e) => DeleteHandler(e)}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}


const mapDispatchToProps = dispatch => ({
    getStaffCateList: (params) => dispatch(GetStaffCategoryList(params)),
    getStaffList: (params) => dispatch(getStaffList(params)),
    AddStaff: params => dispatch(AddStaff(params)),
    EditStaff: params => dispatch(EditStaff(params)),
    DeleteStaff: params => dispatch(DeleteStaff(params)),
    emptyStaffAction: params => dispatch(emptyStaffAction(params))

})

const mapStateToProps = state => {
    return {
        staffList:
            state.mainReducer?.staffListReducer?.response?.data || [],
        staffCateList:
            state.mainReducer?.staffCategoryListReducer?.response?.data || [],
        addStaffStatus: state.mainReducer?.addStaffReducer?.response?.status,
        editStaffStatus: state.mainReducer?.editStaffReducer?.response?.status,
        deleteStaffStatus: state.mainReducer?.deleteStaffReducer?.response?.status,

    }

}


export default connect(mapStateToProps, mapDispatchToProps)(StaffDetails)

