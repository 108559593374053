import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {LocationList,SpecialityList,InsuranceList} from '../../../mock/dropdown/SearchFilter';


class SearchFilterComponent extends Component{
    locationList=[];
    insuranceList=[];
    specializationList=[];
    constructor(props) {
		super(props);
		this.state = {
            searchFilter:{
                location:0,
                insurance:0,
                specialization:0,
                from:''
            }
            };
            this.locationChange=this.locationChange.bind(this);
            this.insuranceChange=this.insuranceChange.bind(this);
            this.specializationChange=this.specializationChange.bind(this);
      }

      locationChange(event){
          //const value={...this.state.searchFilter,location:event.target.value};
          //console.log(event.target.options[event.target.selectedIndex].text);
          const value={...this.state.searchFilter,location:event.target.value};
          this.setState({searchFilter:value}, () => {
            this.props.onSelectFilter(this.state.searchFilter);
          });

        }

      insuranceChange(event){
        const value={...this.state.searchFilter,insurance:event.target.value};
        this.setState({searchFilter:value}, () => {
            this.props.onSelectFilter(this.state.searchFilter);
          });
      }

      specializationChange(event){
        const value={...this.state.searchFilter,specialization:event.target.value};
        this.setState({searchFilter:value}, () => {
            this.props.onSelectFilter(this.state.searchFilter);
          });
      }
    componentDidMount(){
        let searchFrom=this.props.item.from;
        console.log("vale in searchfilter "+searchFrom)
        if("SEARCH_CLICK"  === searchFrom){
            let searchFilter ={location:this.props.item.location,insurance:this.props.item.insurance,specialization:this.props.item.specialization};
            this.setState({searchFilter:searchFilter});
        }



    }

    getInitialData(){
        this.locationList=LocationList;
        this.insuranceList=InsuranceList;
        this.specializationList=SpecialityList;
    }

    render() {

        this.getInitialData();
        return (
            <div className="filter">
                <select className="form-control mr-2" name="location" value={this.state.searchFilter.location}
                onChange={ (e) => {this.locationChange(e)}}
                >
                    {this.locationList.map((location) => {
                        return <option key={location.id} value={location.id}>{location.name}</option>
                    })}
                </select>
                <select className="form-control mr-2" name="insurance" value={this.state.searchFilter.insurance}
                onChange={ (e) => {this.insuranceChange(e)}}
                >
                    {this.insuranceList.map((insurance) => {
                        return <option key={insurance.id} value={insurance.id}>{insurance.name}</option>;
                    })}
                </select>
                <select className="form-control" name="specialization" value={this.state.searchFilter.specialization}
                onChange={ (e) => {this.specializationChange(e)}}

                >
                    {this.specializationList.map((spec) => {
                        return <option key={spec.id} value={spec.id}>{spec.name}</option>;
                    })}
                </select>
            </div>
        )
    }
}
export default withRouter(SearchFilterComponent);