
const Dashboard=
    {
        id:1,
        totalAppointment:125,
        medicalRepList:[
            {
                id:1,
                name:'Mr. Ramesh Sharma',
                time:'3:15 PM',
                duration: '15 min'
            } ,
            {
                id:2,
                name:'Mr. Ramesh Sharma 2',
                time:'3:30 PM',
                duration: '15 min'
            } ,
            {
                id:3,
                name:'Mr. Ramesh Sharma 3',
                time:'4:00 PM',
                duration: '30 min'
            } 
            
        ],
        staffList:[
            {
                id:1,
                name:'Mr. T Sharma',
                time:'10:00am-11:00am',
                dayList:['Mon','Tue'] ,
                title: 'R1'   
            },
            {
                id:2,
                name:'Mr. T Sharma 2',
                time:'10:00am-11:00am',
                dayList:['Mon','Tue', 'Fri'] ,
                title: 'R2'     
            },
            {
                id:3,
                name:'Mr. T Sharma 3',
                time:'10:00am-11:00am',
                dayList:['Mon','Tue'],
                title: 'R3'      
            }
        ]
    };


export {Dashboard};