import { combineReducers } from "redux";
import {
    getCountryCodesReducer,
    loginReducer,
    loginWithOTPReducer,
    addUserDetailsReducer,
    hospitalLoginReducer,
    userVerifyOTPReducer,
    userRegistrationReducer,
    VerifyOTPForPswdReducer,
    ChangePasswordReducer,
    ForgotPasswordReducer,
    HospitalRegistrationReducer
} from './authentication.reducer';

import {
    hospitalDetailsReducer,
    hospitalownersAccountSettingReducer,
    hospitalAccountVerifyReducer,
    hospitalDocumentsReducer,
    stateListReducer,
    cityListReducer,
    staffCategoryListReducer,
    staffListReducer,
    addStaffReducer,
    editStaffReducer,
    deleteStaffReducer,
    doctorSpecListReducer,
    degreeListReducer,
    collegeListReducer,
    countryListReducer,
    AdminNavTabReducer,
    doctorListReducer,
    getHospitalLocationsReducer,
    addDoctorReducer,
    editDoctorReducer,
    addHospitalLocationReducer,
    editHospitalLocationReducer,

    //USER ACCESS
    addUserForAccessReducer,
    editUserForAccessReducer,
    getRolesListReducer,
    getMenusListReducer,
    deleteUserForAccessReducer,
    userAccessMangListReducer,

    getAllAppointmentsReducer,
    getAppointmentsCountReducer,
    getStaffListByDateReducer,

    getPatientAccountProfileReducer,
    saveAppointmentDataReducer,
    patientPrescriptionReducer,
    patientInvoiceReducer,
    startAppointmentReducer,
    getAppointmentHistoryReducer,
    allPatientInvoiceReportReducer,
    getPatientListReducer,
    getPatientMedicalHistoryReducer,

    getAllHospitalWithLocationReducer,
    getAllDoctorByLocationReducer
} from './hospital.reducer';

const mainReducer = combineReducers({
    loginReducer,
    getCountryCodesReducer,
    loginWithOTPReducer,
    hospitalLoginReducer,
    userVerifyOTPReducer,
    userRegistrationReducer,
    addUserDetailsReducer,
    VerifyOTPForPswdReducer,
    ChangePasswordReducer,
    ForgotPasswordReducer,
    HospitalRegistrationReducer,
    hospitalDocumentsReducer,
    stateListReducer,
    cityListReducer,
    staffCategoryListReducer,
    staffListReducer,
    addStaffReducer,
    editStaffReducer,
    deleteStaffReducer,
    hospitalownersAccountSettingReducer,
    hospitalAccountVerifyReducer,
    hospitalDetailsReducer,
    doctorSpecListReducer,
    degreeListReducer,
    collegeListReducer,
    countryListReducer,
    getHospitalLocationsReducer,
    doctorListReducer,
    AdminNavTabReducer,
    addDoctorReducer,
    editDoctorReducer,
    addHospitalLocationReducer,
    editHospitalLocationReducer,

    //USER ACCESS
    addUserForAccessReducer,
    editUserForAccessReducer,
    getRolesListReducer,
    getMenusListReducer,
    deleteUserForAccessReducer,
    userAccessMangListReducer,

    getAllAppointmentsReducer,
    getAppointmentsCountReducer,
    getStaffListByDateReducer,

    getPatientAccountProfileReducer,
    saveAppointmentDataReducer,
    patientInvoiceReducer,
    patientPrescriptionReducer,
    startAppointmentReducer,
    getAppointmentHistoryReducer,
    allPatientInvoiceReportReducer,
    getPatientListReducer,
    getPatientMedicalHistoryReducer,

    getAllHospitalWithLocationReducer,
    getAllDoctorByLocationReducer
})

const rootReducer = (state, action) => {
    return mainReducer(state, action)
}

export default rootReducer