import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import * as $ from 'jquery';
import { GetHospitalDetail, getHospitalDocuments, getCountryCodes, getCityList, getStateList, GetStaffCategoryList, getStaffList, HospitalOwnerSettings } from '../../../redux/Main/actions';
import { useCookies } from 'react-cookie';
import { Card, CardContent, CardActionArea, CardHeader, Grid, Menu, DialogTitle, DialogActions, DialogContent, Button, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import DialogBox from 'src/common/DialogBox';

const Documents = (props) => {
    const [cookies, setCookie] = useCookies(['authtoken']);
    const [openModal, setOpenModal] = useState(false);


    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);


    useEffect(() => {
        const { authtoken = null } = cookies

        props.getDocuments({ authToken: authtoken })
    }, [])


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    console.log(props?.documents)
    return (
        <>

            <Grid container spacing={3}>

                {props?.documents?.map((doc, idx) => (
                    <Grid item sx={12} md={4} sm={2}>
                        <Card>
                            <CardHeader
                                action={
                                    <IconButton aria-label="settings"
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}>
                                        <MoreVertIcon />
                                    </IconButton>
                                } />
                            <CardContent>
                                <CardActionArea>
                                    <img src={require('../../../assets/images/doc.png').default} alt="pdf1" />
                                    <p>PDF 1</p>
                                </CardActionArea>

                            </CardContent>

                        </Card>
                    </Grid>

                ))}
            </Grid>

            <DialogBox
                open={openModal}
                handleClose={() => setOpenModal(false)}


            >
                <DialogTitle>Add New Document</DialogTitle>

                <DialogContent dividers>
                    <input type="file" className="form-control" />


                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenModal(false)}
                    >
                        Close
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                    >
                        Ok
                    </Button>
                </DialogActions>

            </DialogBox>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => setOpenModal(true)}>
                    Add
                </MenuItem>
                <MenuItem>
                    Delete
                </MenuItem>
            </Menu>
        </>
    )
}


const mapDispatchToProps = dispatch => ({
    getDocuments: (params) => dispatch(getHospitalDocuments(params)),
})

const mapStateToProps = state => {
    return {
        documents:
            state.mainReducer?.hospitalDocumentsReducer?.response?.data || [],
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents)
