import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import Allhospitals from '../hospital/Allhospitals';
import Allappointments from '../appointment/Allappointments';
import Allpatients from '../patient/Allpatients';
import Allstaff from '../staff/Allstaff';
import Alldoctor from '../doctor/Alldoctor';
import Allsubscription from '../subscription/Allsubscription';
import Allprescriptions from '../prescription/Allprescriptions';
import Allusers from '../users/Allusers';
import Allspecializations from '../allspecializations/Allspecializations';
import Allinvoice from '../invoice/Allinvoice';
import Allinsurance from '../insurance/Allinsurance';
import Newplan from '../subscription/Newplan';
import Usermanagement from '../usermanagement/Usermanagement';


function Dashboard(props) {
    useEffect(() => {
        document.title = 'Dashboard';
    });

    console.log(props)

    const { activeTab = 0 } = props || {}
    return (
        <>
            <Box className='container'>

                {activeTab === 2 &&
                    <Allhospitals />
                }
                {activeTab === 3 &&
                    <Allappointments />
                }
                {activeTab === 4 &&
                    <Allpatients />
                }
                {activeTab === 11 &&
                    <Allstaff />
                }
                {activeTab === 10 &&
                    <Alldoctor />
                }
                {activeTab === 8 &&
                    <Allsubscription />
                }
                {activeTab === 7 &&
                    <Allprescriptions />
                }
                {activeTab === 13 &&
                    <Allspecializations />
                }
                {activeTab === 5 &&
                    <Allusers />
                }
                {activeTab === 6 &&
                    <Allinvoice />
                }
                {activeTab === 14 &&
                    <Allinsurance />
                }
                {activeTab === 9 &&
                    <Newplan />
                }
                {activeTab === 12 &&
                    <Usermanagement />
                }

            </Box>

        </>
    )
}

const mapDispatchToProps = dispatch => ({
    // AdminTabChange: (params) => dispatch(AdminTabChange(params)),

})

const mapStateToProps = state => {
    return {
        activeTab:
            state.mainReducer?.AdminNavTabReducer?.activeTab || 0,

    }

}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)