import React, { Component } from 'react';
import { Link   } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"

class Editinvoice extends React.Component{

	componentDidMount() {
        document.title = "Edit Invoice Detail"
	}

	render(){
		return(
			<>
				<div className="container">
					<div className="row">
                        <div className="col-md-12">
                            <div className="row dashboard_box">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div className="pr-0">
                                        <span className="mb-0 fw-bold">Invoice Number: 12345</span>
                                    </div>
                                    <div className="text-right">
                                        <button className='btn btn-primary mr-2'>Download from here <i className="fa fa-download"></i></button>
                                        <div className="dropdown btn btn-outline-primary mr-2">
                                            <i className="fa fa-share"></i>
                                            <div className="dropdown-content">
                                                <Link to="#">Share</Link>
                                                <Link to="#">Share on gmail</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <div className="col-md-6 col-12 editAppointment">
                                    <div className="d-flex">
                                        <p className='w-50'><b>Doctor Name :  </b></p>
                                        <input type="text" className='form-control' value="Dr. Jack" placeholder='Enter Doctor Name' />
                                    </div>
                                    <div className="d-flex">
                                        <p className='w-50'><b>Patient Name :  </b></p>
                                        <input type="text" className='form-control' value="Mukesh Sharma" placeholder='Enter Patient Name' />
                                    </div>
                                    <div className="d-flex">
                                        <p className='w-50'><b>Phone Number : </b></p>
                                        <input type="number" className='form-control' value="+91 1236547898" placeholder='Enter Phone' />
                                    </div>
                                    <div className="d-flex">
                                        <p className='w-50'><b>Date Of Birth : </b></p>
                                        <input type="date" className='form-control' placeholder='Enter date of birth' />
                                    </div>
                                    <div className="d-flex">
                                        <p className='w-50'><b>Time Slot : </b></p>
                                        <input type="time" className='form-control' value="7:00-7:40" placeholder='Time Slot' />
                                    </div>
                                    <div className="d-flex">
                                        <p className='w-50'><b>Address :</b></p>
                                        <input type="text" className='form-control' value="Delhi" placeholder='Address' />
                                    </div>
                                </div>
                                <div className="col-md-6 pr-0 col-12 editAppointment">
                                    <div className="d-flex">
                                        <p className='w-50'><b>Appintment Date :</b></p>
                                        <input type="date" className='form-control w-50' placeholder='Enter date of birth' />
                                    </div>
                                    <div className="d-flex">
                                        <p className='w-50'><b>Blood Group :</b></p>
                                        <select className='form-control w-50'>
                                            <option value="1">A+</option>
                                            <option value="2">B+</option>
                                            <option value="3">AB+</option>
                                            <option value="4">O+</option>
                                            <option value="5">A-</option>
                                            <option value="6">B-</option>
                                            <option value="7">AB-</option>
                                            <option value="8">O-</option>
                                        </select>
                                    </div>
                                    <div className="d-flex">
                                        <p className='w-50'><b>Police Case :</b></p>
                                        <select className='form-control w-50'>
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </select>
                                    </div>
                                    <div className="d-flex">
                                        <p className='w-50'><b>Urgency Type :</b></p>
                                        <select className='form-control w-50'>
                                            <option value="1">Normal</option>
                                            <option value="2">Emergency</option>
                                        </select>
                                    </div>
                                    <div className="d-flex">
                                        <p className='w-50'><b>Case Type :</b></p>
                                        <select className='form-control w-50'>
                                            <option value="1">New</option>
                                            <option value="2">Old</option>
                                        </select>
                                    </div>
                                </div>

                                <hr />
                                <div className="col-md-12 mb-3">
                                    <p><span className='fw-bold'>Problem :</span> <textarea className='form-control' cols="30" rows="5">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod empor incididunt ut labore.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod empor incididunt ut labore.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod empor incididunt ut labore.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod empor incididunt ut labore.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod empor incididunt ut labore.</textarea></p>
                                </div>
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table class="table text-center table_pad">
                                            <thead>
                                                <tr>
                                                    <th>Sr No.</th>
                                                    <th>Description</th>
                                                    <th>Unit Cost</th>
                                                    <th>Quantity</th>
                                                    <th>Amount($)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>
                                                        <input type="text" className='form-control' value="Dolo 200" placeholder='Medicine Name'/>
                                                    </td>
                                                    <td>
                                                        <input type="number" className='form-control' value="3" placeholder='Unit Cost'/>
                                                    </td>
                                                    <td>
                                                        <input type="number" className='form-control' value="50" placeholder='Quantity'/>
                                                    </td>
                                                    <td>
                                                        <input type="number" className='form-control' value="150" placeholder='Amount ($)'/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>
                                                        <input type="text" className='form-control' value="Dolo 200" placeholder='Medicine Name'/>
                                                    </td>
                                                    <td>
                                                        <input type="number" className='form-control' value="3" placeholder='Unit Cost'/>
                                                    </td>
                                                    <td>
                                                        <input type="number" className='form-control' value="50" placeholder='Quantity'/>
                                                    </td>
                                                    <td>
                                                        <input type="number" className='form-control' value="150" placeholder='Amount ($)'/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>
                                                        <input type="text" className='form-control' value="Dolo 200" placeholder='Medicine Name'/>
                                                    </td>
                                                    <td>
                                                        <input type="number" className='form-control' value="3" placeholder='Unit Cost'/>
                                                    </td>
                                                    <td>
                                                        <input type="number" className='form-control' value="50" placeholder='Quantity'/>
                                                    </td>
                                                    <td>
                                                        <input type="number" className='form-control' value="150" placeholder='Amount ($)'/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="small"><b>Sub Total</b></td>
                                                    <td class="small">450</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="small"><b>Discount</b></td>
                                                    <td class="small">
                                                        <input type="number" className='form-control text-center' value="10" placeholder='Discount'/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="small"><b>Tax</b></td>
                                                    <td class="small">
                                                        <input type="number" className='form-control text-center' value="100" placeholder='Tax'/>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><b>Total</b></td>
                                                    <td class="fw-bold text-primary">540</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</>
		)
	}
}


export default Editinvoice