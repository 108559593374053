
import React, { Component } from 'react';
import { TermConditionList } from '../../../mock/TermConditionList';


class Blog extends Component{
  
    constructor(props){
        super(props);
        this.state = {
			termConditions:TermConditionList
         };
    }

	

	componentDidMount(){
    //     setTimeout(() => {
	// 	this.setState({invoiceDTO:AllInvoiceDTOs});
	//    }, 10);



    }


    render() {

        return (
           <React.Fragment>
     <div className="container mt-4">
	<div className="row">
		
		<div className="col-md-8">
			<div className="pagination_section">
				<ul className="pagination justify-content-center mb-4">
	                <li className="page-item disabled">
	                    <a className="page-link" href="#" tabindex="-1"><i className="fas fa-chevron-left"></i></a>
	                </li>
	                <li className="page-item"><a className="page-link pagenation_active" href="#">1</a></li>
	                <li className="page-item"><a className="page-link" href="#">2</a></li>
	                <li className="page-item"><a className="page-link" href="#">3</a></li>
	                <li className="page-item">
	                    <a className="page-link" href="#"><i className="fas fa-chevron-right"></i></a>
	                </li>
	            </ul>
			</div>	
			<div className="row">
		        <div className="col-md-6 col-12">
		            <div className="blogcard mb-4">
		                <a href="blogdetail.php"><img src={require('../../../assets/images/join/feature.jpg').default}
                        alt="" /></a>
		                <div className="blogtext">
		                    <div className="blog_title">
		                        <a href="blogdetail.php"><h3>Blog test lllllllllll1</h3></a>
		                    </div>
		                    <div className="blog_dis">
		                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus dolorum minus obcaecati deleniti voluptatum dolor rerum voluptates reiciendis delectus</p>
		                    </div>
		                    <a className="btn btn-primary mt-3" href="blogdetail.php">Read More</a>
		                </div>
		            </div>
		        </div>

		        <div className="col-md-6 col-12">
		            <div className="blogcard mb-4">
		                <a href="blogdetail.php"><img src= {require('../../../assets/images/join/feature.jpg').default} alt="" /></a>
		                <div className="blogtext">
		                    <div className="blog_title">
		                        <a href="blogdetail.php"><h3>Blog test 1</h3></a>
		                    </div>
		                    <div className="blog_dis">
		                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus dolorum minus obcaecati deleniti voluptatum dolor rerum voluptates reiciendis delectus</p>
		                    </div>
		                    <a className="btn btn-primary mt-3" href="blogdetail.php">Read More</a>
		                </div>
		            </div>
		        </div>

		        <div className="col-md-6 col-12">
		            <div className="blogcard mb-3">
		                <a href="blogdetail.php"><img src= {require('../../../assets/images/join/feature.jpg').default} alt="" /></a>
		                <div className="blogtext">
		                    <div className="blog_title">
		                        <a href="blogdetail.php"><h3>Blog test 1</h3></a>
		                    </div>
		                    <div className="blog_dis">
		                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus dolorum minus obcaecati deleniti voluptatum dolor rerum voluptates reiciendis delectus</p>
		                    </div>
		                    <a className="btn btn-primary mt-3" href="blogdetail.php">Read More</a>
		                </div>
		            </div>
		        </div>

		        <div className="col-md-6 col-12">
		            <div className="blogcard mb-4">
		                <a href="blogdetail.php"><img src= {require('../../../assets/images/join/feature.jpg').default} alt="" /></a>
		                <div className="blogtext">
		                    <div className="blog_title">
		                        <a href="blogdetail.php"><h3>Blog test 1</h3></a>
		                    </div>
		                    <div className="blog_dis">
		                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus dolorum minus obcaecati deleniti voluptatum dolor rerum voluptates reiciendis delectus</p>
		                    </div>
		                    <a className="btn btn-primary mt-3" href="blogdetail.php">Read More</a>
		                </div>
		            </div>
		        </div>

		        <div className="col-md-6 col-12">
		            <div className="blogcard mb-4">
		                <a href="blogdetail.php"><img src= {require('../../../assets/images/join/feature.jpg').default} alt="" /></a>
		                <div className="blogtext">
		                    <div className="blog_title">
		                        <a href="blogdetail.php"><h3>Blog test 1</h3></a>
		                    </div>
		                    <div className="blog_dis">
		                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus dolorum minus obcaecati deleniti voluptatum dolor rerum voluptates reiciendis delectus</p>
		                    </div>
		                    <a className="btn btn-primary mt-3" href="blogdetail.php">Read More</a>
		                </div>
		            </div>
		        </div>

		        <div className="col-md-6 col-12">
		            <div className="blogcard mb-4">
		                <a href="blogdetail.php"><img src= {require('../../../assets/images/join/feature.jpg').default} alt="" /></a>
		                <div className="blogtext">
		                    <div className="blog_title">
		                        <a href="blogdetail.php"><h3>Blog test 1</h3></a>
		                    </div>
		                    <div className="blog_dis">
		                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus dolorum minus obcaecati deleniti voluptatum dolor rerum voluptates reiciendis delectus</p>
		                    </div>
		                    <a className="btn btn-primary mt-3" href="blogdetail.php">Read More</a>
		                </div>
		            </div>
		        </div>
		    </div>
		    <div className="pagination_section">
				<ul className="pagination justify-content-center">
	                <li className="page-item disabled">
	                    <a className="page-link" href="#" tabindex="-1"><i className="fas fa-chevron-left"></i></a>
	                </li>
	                <li className="page-item"><a className="page-link pagenation_active" href="#">1</a></li>
	                <li className="page-item"><a className="page-link" href="#">2</a></li>
	                <li className="page-item"><a className="page-link" href="#">3</a></li>
	                <li className="page-item">
	                    <a className="page-link" href="#"><i className="fas fa-chevron-right"></i></a>
	                </li>
	            </ul>
			</div>
		</div>
		{/* <!-- recent post --> */}
		<div className="col-md-4 mt_62">
			<div className="text-right">
				<iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Faajivan%2F%3Fref%3Dbr_rs&tabs=timeline&width=350&height=250&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="350" height="250"></iframe>
			</div>	
			<div className="recernt_post mt-3">
				<h4><b>Recent Posts</b></h4>
				<hr />
				<ul	className="recent_post_ul">
					<li className="d-flex">
						<div className="recreny_post_img">
							<a href="blogdetail.php"><img src= {require('../../../assets/images/join/feature.jpg').default} alt="" width="100" /></a>
						</div>
						<div className="recreny_post_text ml-3">							
							<a href="blogdetail.php"><h5>Blog Test1</h5></a>
							<i className="far fa-calendar-alt"> 22-07-2018</i>	
						</div>						
					</li>	
					<li className="d-flex">
						<div className="recreny_post_img">
							<a href="blogdetail.php"><img src= {require('../../../assets/images/join/feature.jpg').default} alt="" width="100" /></a>
						</div>
						<div className="recreny_post_text ml-3">							
							<a href="blogdetail.php"><h5>Blog Test2</h5></a>
							<i className="far fa-calendar-alt"> 25-07-2018</i>	
						</div>						
					</li>	
					<li className="d-flex">
						<div className="recreny_post_img">
							<a href="blogdetail.php"><img src= {require('../../../assets/images/join/feature.jpg').default} alt="" width="100" /></a>
						</div>
						<div className="recreny_post_text ml-3">							
							<h5><a href="blogdetail.php">Blog Test3</a></h5>
							<i className="far fa-calendar-alt"> 28-07-2018</i>	
						</div>						
					</li>
				</ul>	
			</div>		
		</div>
	</div>
    
</div>
           </React.Fragment>
    
        )
    }
}
export default Blog;