
const LocationList=[
    {
        id:0,
        name:'Select Location'
    },
    {
        id:1,
        name:'Banglore'
    },
    {
        id:2,
        name:'Delhi'
    },
    {
        id:3,
        name:'Mumbai'
    },
    {
        id:4,
        name:'Kolkata'
    }
    
    ];

export default LocationList;

