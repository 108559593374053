import React, { Component } from 'react';
import * as $ from 'jquery';
import { Route, Link, withRouter, useHistory } from "react-router-dom";
import '../../assets/style.css';
import UserDashBoardResultComponent from './UserDashBoardResult';
import HospitalFilterComponent from '../Search/SearchFilter/HospitalFilter';
import { UpcomingDoctor } from '../../mock/UpcomingDoctor';
import DashBoardItemComponent from '../dashboard/dashboard-items/DashBoardItems';
import AdminHeader from '../../common/Header/Adminheader';
import  UserHeader from '../../common/Header/SubHeader';
import HospitalHeader from '../../common/Header/HospitalSubHeader'

class UserDashBoardComponent extends Component {

    doctorList = [];
    constructor(props) {
        super(props);
        this.state = {
            searchFilter: {
                hospital: 0,
                location: 0,
                day: 0
            },
            doctorList: UpcomingDoctor,
            offset: 0,
            pageCount: UpcomingDoctor.length,
            showAppointment: false
        };
        this.doctorList = UpcomingDoctor;
    }

    selectedFilter = (selectedVal) => {
        setTimeout(() => {
            this.setState({
                searchFilter: selectedVal, showAppointment: false, doctorList: this.doctorList.filter((doctor) => {
                    var searchFilter = selectedVal;
                    if ((searchFilter.hospital === 0 || searchFilter.hospital === doctor.hospital.id)
                        && (searchFilter.location === 0 || searchFilter.location === doctor.location.id)
                        && (searchFilter.day === 0 || searchFilter.day === doctor.day.id)
                    ) {
                        return true;
                    }
                })
            });
            this.setState({ pageCount: this.state.doctorList.length });
        }, 10);
    }

    componentDidMount(){
        $('#specialization').on('click', function(){
            $('.search_popup').toggleClass('search_popup_show');
        })
    }

    render() {
        return (
            <React.Fragment>
{/* <UserHeader /> */}

                <div className="search dashboardSearch">
                    <form>
                        <div className="d-flex row justify-content-center">
                            <div className="loc_sec col-md-4">
                                <span className="fa fa-map-marker-alt"></span>
                                <input className="location form-control mb-2" placeholder="Enter Location" />
                                <a href="#" className="detect"><img width="21" src={require('../../assets/images/gps.svg').default} alt="" /> Detect</a>
                            </div>
                            <div className="loc_sec col-md-4">
                                <span><ion-icon name="search"></ion-icon></span>
                                <input className="specialization form-control mb-2" id="specialization" placeholder="Search Specialization or Name" value={this.state.speciality}
                                    onChange={(e) => { this.fetchSpecialityList(e) }}
                                    onClick={(e) => { this.toggleSpecialityDropDown(e) }}
                                />
                                <div className="search_popup">
                                    <ul className="search_popup_ul">
                                        <li>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-1 col-2"><i className="fa fa-search"></i></div>
                                                    <div className="col-md-8 col-6">
                                                        <a href="#"><p className="mt-2">Dentist</p></a>
                                                    </div>
                                                    <div className="col-md-3 col-4 speciality_text"><p className="mt-2 text-right">Test</p></div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-1 col-2"><i className="fa fa-search"></i></div>
                                                    <div className="col-md-8 col-6">
                                                        <a href="#"><p className="mt-2">Gyanocologist</p></a>
                                                    </div>
                                                    <div className="col-md-3 col-4 speciality_text"><p className="mt-2 text-right">Test</p></div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-1 col-2"><i className="fa fa-search"></i></div>
                                                    <div className="col-md-8 col-6">
                                                        <a href="#"><p className="mt-2">Physician</p></a>
                                                    </div>
                                                    <div className="col-md-3 col-4 speciality_text"><p className="mt-2 text-right">Test</p></div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-2 col-12 p-md-0">
                                <Link className='btn btn-primary w-100 searchBtn' to="/search">
                                <ion-icon className="mr-3" name="search"></ion-icon> Search</Link>
                            </div>
                        </div>
                    </form>
                    <div className="row">
                        <div className="col-md-12 d-none d-md-block">
                            <HospitalFilterComponent onSelectFilter={this.selectedFilter} />
                        </div>
                    </div>
                </div>

                <div className=''>
                    <div className="row mt-3 mb-5">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <Link to="/user/book/appointment" className="cursor_pointer" title="">
                                        <div className="top_box_bg">
                                            <i className="far fa-calendar-alt"></i>
                                            <p><b>Book Appointment</b></p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-6 col-6">
                                    <Link to="/user/nearby/search" className="cursor_pointer" title="">
                                        <div className="top_box_bg">
                                            <i className="fas fa-map-marker-alt"></i>
                                            <p><b>Near by Hospital</b></p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="scroll_upcoming">
                                <h6 className="h4"><strong>Doctors available in Vadodara</strong></h6>
                                <p className='mb-3'>Book appointments with minimum wait-time & verified doctor details</p>
                                <div className='row'>
                                    {(this.state.doctorList !== undefined && this.state.doctorList !== null && this.state.doctorList.length > 0) ?
                                        this.state.doctorList.map((doctor, i) => {
                                            return <UserDashBoardResultComponent key={i} doctor={doctor} ></UserDashBoardResultComponent>
                                        }) : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withRouter(UserDashBoardComponent);