import React, { useEffect, useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import TextInput from 'src/common/TextInput';
import { UserAuthService } from '../../../service/UserAuthService';
import { UserContext, UserDispatchContext } from '../../../state/UserState';
import { Grid, Button, Typography, Box, FormControl, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useCookies } from 'react-cookie';

const Login = () => {

    const [cookies, setCookie] = useCookies(['RoleID']);

    const [showPassword, setShowPassword] = useState(false);
    const history = useHistory();
    const userDetails = React.useContext(UserContext);
    const setUserDetails = React.useContext(UserDispatchContext);
    const [loggedIn, setLoggedIn] = useState(false);
    const [state, setState] = useState({
        psw_error: false,
    })

    const saveLogin = async(e) => {
        setLoggedIn(true);
        UserAuthService.saveToken('111');
        UserAuthService.saveRole('ADMIN');
         await setCookie('RoleID', 1)

        // console.log(UserAuthService.saveRole('4'));
        setUserDetails({
            ...userDetails,
            isLogin: true,
            role: UserAuthService.getRole(),
            token: UserAuthService.getToken()
        });
        history.push('/management/dashboard');
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);


    return (
        <form className="form-signin w-25 mt-5 mb-5 m-auto">
            <Grid container>
                <Grid item sx={{ width: 370 }}>
                    <TextInput
                        placeholder='Email address'

                    />
                </Grid>
                <Grid>
                    <Typography>Password</Typography>

                    <FormControl sx={{ width: '25ch' }}>
                        {/* <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel> */}
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            size="small"
                            placeholder='Enter Password'
                            fullWidth
                            sx={{ width: 370 }}
                            error={state.psw_error}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <br />
            <Button
                onClick={(e) => { saveLogin(e) }}
                className='primary-button w-100'
            >
                Login
            </Button>
        </form>

    )
}


export default Login
