import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";


class SearchResultComponent extends Component {
    locationList = [];
    insuranceList = [];
    specializationList = [];
    constructor(props) {
        super(props);

        this.bookAppointment = this.bookAppointment.bind(this);
    }


    componentDidMount() {

    }

    bookAppointment() {
        this.props.onBookAppointment(true);
    }

    openGoogemap = (e, doctor) => {
        if (doctor != null && doctor != undefined) {
            window.open("https://maps.google.com/?q=" + doctor.lattitude + "," + doctor.longitude);
        }
    }

    render() {

        return (
            <div className="col-md-6">
                <div className='drblock mb-2 mb-md-2'>
                    <div className="d-flex justify-content-between align-items-center">
                        <Link to='/user/doctorProfile'>
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="img_block">
                                    <img src={require('../../../assets/images/doc1.png').default} alt="doctor profile" />
                                </div>
                                <div className="docinfo">
                                    <p className=""><strong> {this.props.doctor.name}</strong><span className="ml-3">{this.props.doctor.specialization.name}</span></p>
                                    <p className="sub_font mb-md-0 mb-1"><i className="fas fa-graduation-cap"></i>BDS, MDS - Oral & Maxillofacial Surgery</p>
                                    <p className="sub_font mb-md-0 mb-1"><i className="fas fa-graduation-cap"></i>Dentist</p>
                                    <p className="sub_font" style={{ cursor: 'pointer' }} onClick={(e) => { this.openGoogemap(e, this.props.doctor) }}><i className="fas fa-map-marker mr-1"></i> {this.props.doctor.location.name}</p>
                                    <p className="sub_font"><i className="fas fa-mobile-alt mr-2"></i> {this.props.doctor.phone}</p>
                                    <p className="sub_font mb-md-0 mb-1"><i className="fas fa-thumbs-up"></i> 87% | 21 Feedbacks</p>
                                </div>
                            </div>
                        </Link>
                        <div className="col-md-3 col-3 p-1 pr-3 text-right">
                            {/* <Link to="/create/appointment" className="btn btn-primary  m_font_10" onClick={bookAppointment}>Book Appointment</Link> */}
                            <Link to="#" className="btn btn-primary  m_font_10" onClick={(e) => { this.bookAppointment(e) }}>Book Appointment</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(SearchResultComponent);