import React, { Component } from 'react';
import { Link   } from "react-router-dom";

class Transaction extends Component{
    render(){
        return(
            <>
                <div className="container">
                    <div class="page-header mt-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <h3 class="page-title">Transactions</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="index.html">Dashboard</a></li>
                                    <li class="breadcrumb-item active">Transactions</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mt-2">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="datatable table table-hover table-center mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Invoice Number</th>
                                                    <th>Hospital Name</th>
                                                    <th>Total Amount</th>
                                                    <th className="text-center">Status</th>
                                                    <th className="text-end">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><Link to="/management/invoice">#IN0001 </Link></td>
                                                    <td>
                                                        <p className="table-avatar">
                                                            <Link to="#">Apollo Delhi</Link>
                                                        </p>
                                                    </td>
                                                    <td>2000</td>
                                                    <td className="text-center">
                                                        <span className="badge rounded-pill bg-success inv-badge">Paid</span>
                                                    </td>
                                                    <td className="text-end">
                                                        <div className="actions">
                                                            <Link className="btn btn-sm bg-danger-light" data-bs-toggle="modal"
                                                                to="#delete_modal">
                                                                <i className="fe fe-trash"></i> Delete
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


export default Transaction