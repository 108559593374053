import React, { Component } from 'react';

import * as $ from 'jquery';

import {Dashboard} from '../../../mock/hospital/Dashboard';
import { Link } from 'react-router-dom';


class HospitalDashboardComponent extends Component{



	constructor(props) {
		super(props);
		this.state = {
            dashboard:Dashboard
	    };


	  }

      componentDidMount() {
        document.getElementById('dateStaff').valueAsDate = new Date();

    }





    render() {
		const userDetails= this.context;
        return (
			<React.Fragment>
				<div className="row">
                    <div className="col-md-12">
                        <div className="row dashboard_box">
                            <div className="col-md-4">
                                <div className="box">
                                    <p>Today's Total Online Appointment</p>
                                    <p className="waiting"><Link to="/hospital/allappointment">{this.state.dashboard.totalAppointment}</Link></p>
                                    <hr/>
                                    <p>Today's Total Offline Appointment</p>
                                    <p className="waiting"><Link to="/hospital/allappointment">{this.state.dashboard.totalAppointment}</Link></p>
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div className="box pr-3 pl-3">
                                    <p className="mb-3 fw-bold">Medital Rep(MR) Meetings</p>
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="text-left w-50">
                                            {
                                                (this.state.dashboard.medicalRepList != null &&  this.state.dashboard.medicalRepList != undefined
                                                    && this.state.dashboard.medicalRepList.length > 0 )?
                                                    this.state.dashboard.medicalRepList.map(ct =>{
                                                            return <p className="primary_text mb-2"><strong>{ct.name}</strong></p>;
                                                    })
                                                    :''
                                            }
                                        </div>
                                        <div className="text-left w-25">
                                            {
                                                (this.state.dashboard.medicalRepList != null &&  this.state.dashboard.medicalRepList != undefined
                                                && this.state.dashboard.medicalRepList.length > 0 )?
                                                    this.state.dashboard.medicalRepList.map(ct =>{
                                                            return <p className="text-muted mb-2">{ct.time}</p>;
                                                    })
                                                    :'-'
                                            }
                                        </div>
                                        <div className="text-left w-25">
                                            {
                                                (this.state.dashboard.medicalRepList != null &&  this.state.dashboard.medicalRepList != undefined
                                                && this.state.dashboard.medicalRepList.length > 0 )?
                                                    this.state.dashboard.medicalRepList.map(ct =>{
                                                            return <p className="text-muted mb-2">{ct.duration}</p>;
                                                    })
                                                    :'-'
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="box pr-3 pl-3">
                                    <div className="d-flex justify-content-between w-100">
                                        <div className="mr-3">
                                            <p className="mt-2">Today's Staff</p>
                                        </div>

                                        <div className="">
                                            <input type="date" id="dateStaff" className="form-control textarea_size" />
                                        </div>
                                    </div>


                                    <div className="d-flex justify-content-between w-100 mt-2">
                                        <div className="text-left w-50">
                                            <p className="mb-2 fw-bold">Name</p>
                                            {
                                            (this.state.dashboard.staffList != null &&  this.state.dashboard.staffList != undefined
                                                && this.state.dashboard.staffList.length > 0 )?
                                                this.state.dashboard.staffList.map(ct =>{
                                                        return <p className="primary_text mb-2 pri_small_font"><strong>{ct.name} <span className="text-muted">({ct.title})</span></strong></p>;
                                                })
                                                :''
                                            }

                                        </div>

                                        <div className="text-left w-25">
                                            <p className="mb-2 fw-bold">Timing</p>
                                            {
                                            (this.state.dashboard.staffList != null &&  this.state.dashboard.staffList != undefined
                                                && this.state.dashboard.staffList.length > 0 )?
                                                this.state.dashboard.staffList.map(ct =>{
                                                        return <p className="mb-2">{ct.time} </p>;
                                                })
                                                :''
                                            }
                                        </div>
                                        <div className="text-left w-25">
                                            <p className="mb-2 fw-bold">Day</p>
                                            {
                                            (this.state.dashboard.staffList != null &&  this.state.dashboard.staffList != undefined
                                                && this.state.dashboard.staffList.length > 0 )?
                                                this.state.dashboard.staffList.map(ct =>{
                                                        return <p className="mb-2">{ct.dayList.join(",")} </p>;
                                                })
                                                :''
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

		    </React.Fragment>
        )
    }
}


export default HospitalDashboardComponent;