import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { DateRangePicker } from 'react-date-range';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DegreeList, CollegeList, DoctorSpecList, GetDoctorList, AddDoctorAction } from 'src/redux/Main/actions';
import { connect } from "react-redux";
import { useCookies } from 'react-cookie';
import { Box, Menu, MenuItem, Drawer, TextField, Tooltip, Button, Card, CardHeader, CardMedia, CardContent, CardActions, CardActionArea, Typography, ToggleButtonGroup, ToggleButton, Grid, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import TextInput from "src/common/TextInput";
import DialogBox from "src/common/DialogBox";
import DoctorProfile from "src/components/user/doctorProfile/DoctorProfile";
import DropDown from 'src/common/DropDown';
import DeleteModal from 'src/common/DeleteModal';

const Doctors = (props) => {

    const [cookies, setCookie] = useCookies(['authtoken']);
    const [alignment, setAlignment] = React.useState('General Time');
    const [availability, setAvailability] = useState('General Time')
    const [onDelete, setOnDelete] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDrawer, setOpenDrawer] = useState(false)
    const open = Boolean(anchorEl);
    const [state, setState] = useState({
        staff_role_id: '0',
        name: '',
        mobile: "",
        email: "",
        password: "",
        confirm_password: "",
        duty_timings: [
            {
                day: "0",
                from_time: null,
                to_time: null,
            }
        ]
    });


    const { authtoken = null } = cookies


    const [eduDetails, setEduDetails] = useState([{
        specialist_id: '0',
        degree_id: '0',
        collage_id: '0',
        reg_no: null,
        reg_council: null,
        reg_council: null,
        documents: [],
    }])

    const [payload, setPayload] = useState({
        authToken: authtoken,
        name: null,
        mobile: null,
        email: null,
        password: null,
        confirm_password: null,
        day: null,
        from_time: null,
        to_time: null,
        specialist_id: null,
        degree_id: null,
        collage_id: null,
        reg_no: null,
        reg_council: null,
        reg_type: null,
        from_date: null,
        to_date: null,
        slot_time_interval: null,
        start_time: null,
        end_time: null,
        documents1: null
    })


    useEffect(() => {
        props.GetDoctorList({ authToken: authtoken })
        props.getDegreeList({})
        props.getCollegeList({})
        props.getDoctorSpecList({})
    }, [])


    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const DutyChangeHandler = (event, idx) => {

        const { target: { name, value } = {} } = event;
        let obj = { ...state };

        obj.duty_timings[idx][name] = value;
        setState(obj)
    }

    const AddMoreDuties = () => {
        let objCopy = { ...state };
        const data =
        {
            day: "0",
            from_time: null,
            to_time: null,
        }

        objCopy.duty_timings?.push(data);

        setState(objCopy)
    }

    const RemoveMoreDuties = (idx) => {
        let objCopy = { ...state };
        objCopy.duty_timings?.splice(idx, 1);
        setState(objCopy)
    }
    const RemoveMoreEduDetails = (idx) => {

        let objCopy = [...eduDetails];
        objCopy.splice(idx, 1);
        setEduDetails(objCopy)
    }

    const AddMoreEducations = () => {
        setEduDetails([...eduDetails, {
            specialist_id: null,
            degree_id: null,
            collage_id: null,
            reg_no: null,
            reg_council: null,
            reg_council: null,
            documents: [],
        }])
    }

    const StaffChangeHandler = (event) => {
        const { target: { name, value } = {} } = event;

        let stateCopy = { ...payload };

        stateCopy[name] = value;

        setPayload(stateCopy)

    }

    const EducationChangeHandler = (event, index) => {
        const { target: { name, value } = {} } = event;
        let obj = [...eduDetails];

        obj[index][name] = value;
        setEduDetails(obj)
    }


    const DeleteHandler = (e) => {

        // if (staffID) {
        // props.DeleteStaff({ authToken: cookies.authtoken, staff_id: staffID })
        // }

    }


    const submitHandler = (e) => {

        let reqPaload = {
            ...payload,
            day: state?.duty_timings.map(({ day }) => day)?.toString(),
            from_time: state?.duty_timings?.map(({ from_time }) => from_time)?.toString(),
            to_time: state?.duty_timings?.map(({ to_time }) => to_time)?.toString(),
            specialist_id: eduDetails?.map(({ specialist_id }) => specialist_id)?.toString(),
            degree_id: eduDetails?.map(({ degree_id }) => degree_id)?.toString(),
            collage_id: eduDetails?.map(({ collage_id }) => collage_id)?.toString(),
            reg_no: eduDetails?.map(({ reg_no }) => reg_no)?.toString(),
            reg_council: eduDetails?.map(({ reg_council }) => reg_council)?.toString(),
            reg_type: eduDetails?.map(({ reg_type }) => reg_type)?.toString(),

        }
        props.AddDoctorAction(reqPaload)




    }

    return (
        <>
            <Box>
                <Button className='primary-button'
                    size='small'
                    variant="contained"
                    onClick={() => {
                        setOpenModal(true)
                    }}
                ><i className="fas fa-2x fa-plus mr-2"></i> Add Doctors at this hospital
                </Button>
            </Box>

            <div style={{ display: 'flex', flexDirection: 'row', }}>
                {props?.DoctorsList?.map((item, index) => (

                    <Card sx={{ minWidth: 500, marginRight: 5 }}>
                        <CardHeader

                            action={
                                <IconButton aria-label="settings"
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}>
                                    <MoreVertIcon />
                                    {/* <Link to="/hospitalname/editdoctor" className='btn btn-outline-primary btn-sm editDoctor'><MoreVertIcon /></Link> */}
                                </IconButton>
                            }
                        // title="Shrimp and Chorizo Paella"
                        // subheader="September 14, 2016"
                        />
                        {/* <CardActionArea> */}
                        {/* <Link to="/hospitalname/drjack" className='d-block'> */}

                        <CardMedia
                            component="img"
                            height="140"
                            image="/static/images/cards/contemplative-reptile.jpg"
                            alt="green iguana"
                        />

                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Dr {item.name}
                            </Typography>
                            <Link to="tel:1236547898"><i className="fas fa-phone-alt"></i> +91 {item.mobile}</Link>
                            <div>

                                <Link to="mailto:test@gmail.com"><i className="fas fa-envelope"></i> {item.email}</Link>
                            </div>
                            <br />

                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="General Time">General Time</ToggleButton>
                                <ToggleButton value="Slot time">Slot time </ToggleButton>
                            </ToggleButtonGroup>

                            <br />
                            <br />
                            {alignment === 'General Time' ?
                                <Box>
                                    {item.duty_timings?.map((slot) => (
                                        <p key={slot.id}>{`${slot.day} : ${slot.from_time} to ${slot.to_time}`}</p>

                                    ))}
                                </Box>
                                :


                                <Box>
                                    {item.slot_timings?.map((slot) => (
                                        <p key={slot.id}>{`${slot.date} : ${slot.start_time} to ${slot.end_time}`}</p>

                                    ))}
                                </Box>
                            }
                            <hr />

                            {item.doctors_education_qualification
                                ?.map((edu) => (
                                    <Grid container spacing={2} key={edu.id}>
                                        <Grid item sx={12} sm={6} md={4}>
                                            <TextInput
                                                placeholder={'Registration No'}
                                                value={edu.reg_no}

                                            />
                                        </Grid>
                                        <Grid item sx={12} sm={6} md={4}>
                                            <TextInput
                                                placeholder={'Registration Council'}
                                                value={edu.reg_council}

                                            />
                                        </Grid>
                                        <Grid item sx={12} sm={6} md={4}>
                                            <TextInput
                                                placeholder={'Registration Type'}
                                                value={edu.reg_type}

                                            />
                                        </Grid>
                                    </Grid>

                                ))}

                        </CardContent>

                        {/* </CardActionArea> */}
                        {/* </Link> */}
                    </Card>
                ))}

                {/* <Card sx={{ minWidth: 500 }}>
                    <CardHeader

                        action={
                            <IconButton aria-label="settings"
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}>
                                <MoreVertIcon />
                                {/* <Link to="/hospitalname/editdoctor" className='btn btn-outline-primary btn-sm editDoctor'><MoreVertIcon /></Link> *
                            </IconButton>
                        }
                    // title="Shrimp and Chorizo Paella"
                    // subheader="September 14, 2016"
                    />
                    {/* <CardActionArea> *

                    <CardMedia
                        component="img"
                        height="140"
                        image="/static/images/cards/contemplative-reptile.jpg"
                        alt="green iguana"
                    />
                    <Link to="/hospitalname/drjack" className='d-block'>

                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Dr Lokesh
                            </Typography>
                            <Link to="tel:1236547898"><i className="fas fa-phone-alt"></i> +91 7095656138</Link>
                            <div>

                                <Link to="mailto:test@gmail.com"><i className="fas fa-envelope"></i> lokeshreddy@gmail.com</Link>
                            </div>
                            <br />

                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="General Time">General Time</ToggleButton>
                                <ToggleButton value="Slot time">Slot time </ToggleButton>
                            </ToggleButtonGroup>

                            <br />
                            <br />
                            <Box>
                                <p>Monday : 10:AM to 05:PM</p>
                                <p>Monday : 10:AM to 05:PM</p>
                                <p>Monday : 10:AM to 05:PM</p>
                                <p>Monday : 10:AM to 05:PM</p>
                                <p>Monday : 10:AM to 05:PM</p>
                                <p>Monday : 10:AM to 05:PM</p>
                                <p>Monday : 10:AM to 05:PM</p>
                            </Box>
                            <hr />

                            <Grid container spacing={2}>
                                <Grid item sx={12} sm={6} md={4}>
                                    <TextInput
                                        placeholder={'Registration No'}

                                    />
                                </Grid>
                                <Grid item sx={12} sm={6} md={4}>
                                    <TextInput
                                        placeholder={'Registration Council'}

                                    />
                                </Grid>
                                <Grid item sx={12} sm={6} md={4}>
                                    <TextInput
                                        placeholder={'Registration Type'}

                                    />
                                </Grid>
                            </Grid>
                        </CardContent>

                        {/* </CardActionArea> *
                    </Link>
                </Card> */}
            </div>


            <DialogBox
                open={openModal}
                handleClose={() => setOpenModal(false)}


            >
                <DialogTitle>Add New Dcotor</DialogTitle>

                <DialogContent dividers>
                    <div className="small-12 medium-2 large-2 columns uploadProfile">
                        <div className="circle">
                            <img className="profile-pic" src={require('../../../assets/images/user.png').default} />
                        </div>
                        <div className="p-image">
                            <i className="fa fa-camera upload-button"></i>
                            <input className="file-upload" type="file" accept="image/*" />
                        </div>
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextInput
                                name={'name'}
                                placeholder={'Name'}
                                value={payload?.name}
                                onChange={(e) => StaffChangeHandler(e)}
                                error={''}

                            />
                            <TextInput
                                name={'mobile'}
                                placeholder={'Phone Number'}
                                value={payload?.mobile}
                                onChange={(e) => StaffChangeHandler(e)}
                                error={''}

                            />
                            <TextInput
                                name={'email'}
                                placeholder={'Email'}
                                value={payload?.email}
                                onChange={(e) => StaffChangeHandler(e)}
                                error={''}

                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextInput
                                name={'id'}
                                placeholder={'ID No'}
                                onChange={(e) => StaffChangeHandler(e)}
                                error={''}
                                disabled={true}

                            />
                            <TextInput
                                name={'password'}
                                placeholder={'Password'}
                                value={payload?.password}
                                onChange={(e) => StaffChangeHandler(e)}
                                error={''}

                            />
                            <TextInput
                                name={'confirm_password'}
                                placeholder={'Confirm Password'}
                                value={payload?.confirm_password}
                                onChange={(e) => StaffChangeHandler(e)}
                                error={''}

                            />

                        </Grid>
                    </Grid>

                    <br />

                    <Card>
                        <CardContent>

                            <Typography className='fw-bold text-center mb-1'>My Availability</Typography>
                            <ul className="nav nav-tabs border-0 w-100">
                                <li className="nav-item w-50">
                                    <button className='nav-link w-100 btn btn-outline-primary rounded-left active' onClick={() => setAvailability('General Time')}>General Time <br />
                                        <small>(First come, First Served - Waiting time visible - Avg time visible) (Online + In-person)</small>
                                    </button>
                                </li>
                                <li className='nav-item w-50'>
                                    <button className='nav-link w-100 btn btn-outline-primary rounded-right' onClick={() => setAvailability('Slot Time')}>Slot time <br />
                                        <small>(priority fixed appointments) (Online + In-person)</small>
                                    </button>
                                </li>
                            </ul>
                            <br />

                            <Typography variant='p'>Duty Timing</Typography>
                            <br />
                            {state.duty_timings?.map((el, idx) => (

                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <DropDown
                                            selectLabel={"Day"}
                                            name={'day'}
                                            label={'Day'}
                                            value={el?.day}
                                            onChange={(e) => DutyChangeHandler(e, idx)}
                                            options={[
                                                { value: 'Monday', name: "Monday" },
                                                { value: 'Tuesday', name: "Tuesday" },
                                                { value: 'Wednesday', name: "Wednesday" },
                                                { value: 'Thursday', name: "Thursday" },
                                                { value: 'Friday', name: "Friday" },
                                                { value: 'Saturday', name: "Saturday" },
                                                { value: 'Sunday', name: "Sunday" }
                                            ]}


                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            id="time"
                                            label="From"
                                            name='from_time'
                                            type="time"
                                            fullWidth
                                            value={state?.from_time}
                                            onChange={(e) => DutyChangeHandler(e, idx)}
                                            size='small'

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 min
                                            }}
                                        />
                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <TimePicker
                                        label="From"
                                        value={el?.from_time}
                                        // onChange={(e) => DutyChangeHandler(e, idx)}
                                        onChange={(date) => TimeFormate(date, idx)}

                                        renderInput={(params) => <TextField {...params} size='small' />}

                                    />
                                </LocalizationProvider> */}

                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <TimePicker
                                        label="To"
                                        value={el?.to_time}
                                        onChange={(e) => DutyChangeHandler(e, idx)}
                                        renderInput={(params) => <TextField {...params} size='small' />}

                                    />
                                </LocalizationProvider> */}
                                        <TextField
                                            id="time"
                                            label="To"
                                            name='to_time'
                                            type="time"
                                            fullWidth
                                            size='small'
                                            value={state.to_time}
                                            onChange={(e) => DutyChangeHandler(e, idx)}

                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 min
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        {idx === 0 ? (
                                            <Button

                                                onClick={() => AddMoreDuties()}
                                            >+ Add More</Button>

                                        )
                                            : (
                                                <Button

                                                    onClick={() => RemoveMoreDuties(idx)}
                                                >- Remove</Button>
                                            )}

                                    </Grid>
                                </Grid>
                            ))}


                        </CardContent>
                    </Card>

                    <hr />

                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className='text-primary fw-bold'>Education Qualification</h5>
                        <div className="change">
                            <Button size="small" className='primary-button' onClick={() => AddMoreEducations()} >Add More</Button>
                        </div>
                    </div>
                    <br />
                    {eduDetails?.map((edu, index) => (
                        <div className="d-flex justify-content-between ">

                            <Grid container spacing={2} className='border border-rounded pr-3 pb-2 mb-3'>
                                <Grid item xs={12} sm={6} md={4}>
                                    <DropDown
                                        name={'specialist_id'}
                                        selectLabel={"Select Specialization"}
                                        placeholder={'Specialization'}
                                        value={edu?.specialist_id}
                                        onChange={(e) => EducationChangeHandler(e, index)}
                                        options={props.doctorSpecList?.map((item) => ({ value: item.id, name: item.title }))}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>


                                    <DropDown
                                        name={'degree_id'}
                                        placeholder={'Degree'}
                                        selectLabel={"Select Degree"}

                                        value={edu?.degree_id}
                                        onChange={(e) => EducationChangeHandler(e, index)}
                                        options={props.degreeList?.map((item) => ({ value: item.id, name: item.name }))}


                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>


                                    <DropDown
                                        name={'collage_id'}
                                        placeholder={'Collage Name'}
                                        selectLabel={"Select Collage Name"}
                                        value={edu?.collage_id}
                                        onChange={(e) => EducationChangeHandler(e, index)}
                                        options={props.collegeList?.map((item) => ({ value: item.id, name: item.name }))}


                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>


                                    <TextInput
                                        name={'reg_no'}
                                        placeholder={'Registration No.'}
                                        value={state?.reg_no}
                                        onChange={(e) => EducationChangeHandler(e, index)}
                                        error={''}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>


                                    <TextInput
                                        name={'reg_council'}
                                        placeholder={'Registration Council'}
                                        value={state?.reg_council}
                                        onChange={(e) => EducationChangeHandler(e, index)}
                                        error={''}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>


                                    <TextInput
                                        name={'reg_type'}
                                        placeholder={'Registration Type'}
                                        value={state?.reg_type}
                                        onChange={(e) => EducationChangeHandler(e, index)}
                                        error={''}

                                    />
                                </Grid>
                            </Grid>

                            {eduDetails?.length > 1 &&

                                <Button

                                    onClick={() => RemoveMoreEduDetails(index)}
                                >Remove</Button>
                            }


                        </div>

                    ))}

                    <div className="row">
                        <div className="col-md-12">
                            <p>upload Document</p>
                            <div className="form-group">
                                <input type="file" className='form-control' multiple />
                            </div>
                        </div>
                    </div>

                    {/* <form>
                        <div className="row">


                            <hr className='my-2' />
                            <div className="col-md-12">
                                <form>
                                    <div className='addMoreQualification'>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5 className='text-primary fw-bold'>Education Qualification</h5>
                                            <div className="change">
                                                <a href="#" className='btn btn-outline-primary addMoreBtnQualification'>Add More</a>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label for="speciality">Please Select</label>
                                                    <select className='form-control'>
                                                        <option value="0" disabled>Specialization</option>
                                                        {props.doctorSpecList?.map((item) => (
                                                            <option value={item.id}>{item.title}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label for="speciality">Degree</label>
                                                    <select className='form-control'>
                                                        <option value="0" disabled>Please Select</option>
                                                        {props.degreeList?.map((item) => (
                                                            <option value={item.id}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label for="speciality">Collage Name</label>
                                                    <select className='form-control'>
                                                        <option value="0" disabled>Please Select</option>
                                                        {props.collegeList?.map((item) => (
                                                            <option value={item.id}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 col-12 mt-2 mt-md-2">
                                                <label>Registration No.</label>
                                                <input type="text" className="form-control" placeholder="Registration Number" name="registrationNo" />
                                            </div>
                                            <div className="col-md-4 col-6 mt-2">
                                                <label>Registration Council</label>
                                                <input className="form-control" list="council" placeholder="Registration Council" name="registrationCouncil" />
                                                <datalist id="council">
                                                    <option value="Council 1"></option>
                                                    <option value="Council 2"></option>
                                                    <option value="Council 3"></option>
                                                    <option value="Council 4"></option>
                                                </datalist>
                                            </div>
                                            <div className="col-md-4 col-6 mt-2">
                                                <label>Registration Type</label><input className="form-control" list="type" name="registrationType" placeholder="Registration Type" />
                                                <datalist id="type">
                                                    <option value="Reg Type 1"></option>
                                                    <option value="Reg Type 2"></option>
                                                    <option value="Reg Type 3"></option>
                                                    <option value="Reg Type 4"></option>
                                                </datalist>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p>upload Document</p>
                                                <div className="form-group">
                                                    <input type="file" className='form-control' multiple />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </form> */}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenModal(false)}
                    >
                        Close
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={submitHandler}
                    >
                        Ok
                    </Button>
                </DialogActions>

            </DialogBox>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => setOpenDrawer(true)}>
                    Edit
                </MenuItem>
                <MenuItem>
                    Delete
                </MenuItem>
            </Menu>

            <Drawer
                anchor="right"
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
            >
                <div style={{ width: 600 }}>
                    <DoctorProfile />

                </div>

            </Drawer>

            {onDelete &&
                <DeleteModal
                    open={onDelete}
                    handleClose={() => setOnDelete(false)}
                    handleSubmit={DeleteHandler}

                />
            }



            {/* <div className="tab-pane fade show" id="clinic" role="tabpanel" aria-labelledby="home-tab">
                <div>
                    <form action="">
                        <div className="row">
                            <div className="col-md-12 col-12 mt-2 d-flex">
                                <a className='btn btn-outline-primary ml-3 d-flex align-items-center' href='#' data-toggle="modal" data-target="#addDoctormodel"><i className="fas fa-2x fa-plus mr-2"></i> Add Doctors at this hospital</a>
                            </div>

                            <div className="col-md-12">
                                <div className="accordion mt-3 mb-2 row" id="docVarification">
                                    <div className="col-md-4 col-12">
                                        <Link to="/hospitalname/drjack" className='d-block'>
                                            <div className="card">
                                                <Link to="/hospitalname/editdoctor" className='btn btn-outline-primary btn-sm editDoctor'>Edit</Link>
                                                <div className="card-header">
                                                    <div className="mb-0" data-toggle="collapse" data-target="#docCol1" aria-expanded="true" aria-controls="docCol1">
                                                        <div className="cardImg mr-2">
                                                            <img src={require('../../../assets/images/home/doctor.png').default} alt="Doctor Photo" />
                                                        </div>
                                                        <div className="docInfo">
                                                            <h5>Dr. Jack</h5>
                                                            <Link to="tel:1236547898"><i className="fas fa-phone-alt"></i> +91 1234567890</Link>
                                                            <Link to="mailto:test@gmail.com"><i className="fas fa-envelope"></i> test@gmail.com</Link>

                                                            <div className="shadow-sm card p-1 mt-2">
                                                                <p className='text-center'>My availability</p>
                                                                <ul className="nav nav-tabs border-0 w-100">
                                                                    <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                    <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime1">Slot time </a>

                                                                    </li>
                                                                </ul>

                                                                <div className="tab-content p-2">
                                                                    <div id="generalTime1" className="tab-pane fade show active">
                                                                        <div className="mb-2">
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                        </div>
                                                                    </div>
                                                                    <div id="slotTime1" className="tab-pane fade">
                                                                        <div className="mb-2">
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                            <p>Monday : 10:AM to 05:PM</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-4 col-12 mt-2 mt-md-2">
                                                                    <label>Registration No.</label>
                                                                    <input type="text" className="form-control"
                                                                        placeholder="Registration Number"
                                                                        name="registrationNo"
                                                                    // value={this.state.hospitalProfile.registrationNo}
                                                                    // onChange={(e) => { this.handleChange(e) }}
                                                                    />
                                                                </div>

                                                                <div className="col-md-4 col-6 mt-2">
                                                                    <label>Registration Council</label>
                                                                    <input className="form-control" list="council"
                                                                        placeholder="Registration Council"
                                                                        name="registrationCouncil"
                                                                    // value={this.state.hospitalProfile.registrationCouncil.name}
                                                                    // onChange={(e) => { this.handleChange(e) }}
                                                                    />
                                                                    <datalist id="council">
                                                                        {/* {this.registrationCouncilList.map((location) => {
                                                                                                        return <option key={location.id} value={location.name}></option>
                                                                                                    })} *
                                                                    </datalist>
                                                                </div>

                                                                <div className="col-md-4 col-6 mt-2">
                                                                    <label>Registration Type</label>
                                                                    <input className="form-control"
                                                                        list="type"
                                                                        name="registrationType"
                                                                        placeholder="Registration Type"
                                                                    // value={this.state.hospitalProfile.registrationType.name}
                                                                    // onChange={(e) => { this.handleChange(e) }}
                                                                    />
                                                                    <datalist id="type">
                                                                        {/* {this.registrationTypeList.map((location) => {
                                                                                                        return <option key={location.id} value={location.name}></option>
                                                                                                    })} *
                                                                    </datalist>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>

                                    <div className="col-md-4 col-12">
                                        <div className="card">
                                            <Link to="/hospitalname/editdoctor" className='btn btn-outline-primary btn-sm editDoctor'>Edit</Link>
                                            <div className="card-header">
                                                <div className="mb-0" data-toggle="collapse" data-target="#docCol2" aria-expanded="true" aria-controls="docCol1">
                                                    <div className="cardImg mr-2">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="Doctor Photo" />
                                                    </div>
                                                    <div className="docInfo">
                                                        <h5>Dr. Jack</h5>
                                                        <Link to="tel:1236547898"><i className="fas fa-phone-alt"></i> +91 1234567890</Link>
                                                        <Link to="mailto:test@gmail.com"><i className="fas fa-envelope"></i> test@gmail.com</Link>

                                                        <div className="shadow-sm card p-1 mt-2">
                                                            <p className='text-center'>My availability</p>
                                                            <ul className="nav nav-tabs border-0 w-100">
                                                                <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime2">Slot time </a>

                                                                </li>
                                                            </ul>

                                                            <div className="tab-content p-2">
                                                                <div id="generalTime2" className="tab-pane fade show active">
                                                                    <div className="mb-2">
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                    </div>
                                                                </div>
                                                                <div id="slotTime2" className="tab-pane fade">
                                                                    <div className="mb-2">
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-4 col-12 mt-2 mt-md-2">
                                                                <label>Registration No.</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Registration Number"
                                                                    name="registrationNo"
                                                                // value={this.state.hospitalProfile.registrationNo}
                                                                // onChange={(e) => { this.handleChange(e) }}
                                                                />
                                                            </div>

                                                            <div className="col-md-4 col-6 mt-2">
                                                                <label>Registration Council</label>
                                                                <input className="form-control" list="council"
                                                                    placeholder="Registration Council"
                                                                    name="registrationCouncil"
                                                                // value={this.state.hospitalProfile.registrationCouncil.name}
                                                                // onChange={(e) => { this.handleChange(e) }}
                                                                />
                                                                <datalist id="council">
                                                                    {/* {this.registrationCouncilList.map((location) => {
                                                                                                    return <option key={location.id} value={location.name}></option>
                                                                                                })} *
                                                                </datalist>
                                                            </div>

                                                            <div className="col-md-4 col-6 mt-2">
                                                                <label>Registration Type</label>
                                                                <input className="form-control"
                                                                    list="type"
                                                                    name="registrationType"
                                                                    placeholder="Registration Type"
                                                                // value={this.state.hospitalProfile.registrationType.name}
                                                                // onChange={(e) => { this.handleChange(e) }}
                                                                />
                                                                <datalist id="type">
                                                                    {/* {this.registrationTypeList.map((location) => {
                                                                                                    return <option key={location.id} value={location.name}></option>
                                                                                                })} *
                                                                </datalist>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-12">
                                        <div className="card">
                                            <Link to="/hospitalname/editdoctor" className='btn btn-outline-primary btn-sm editDoctor'>Edit</Link>
                                            <div className="card-header">
                                                <div className="mb-0" data-toggle="collapse" data-target="#docCol3" aria-expanded="true" aria-controls="docCol1">
                                                    <div className="cardImg mr-2">
                                                        <img src={require('../../../assets/images/home/doctor.png').default} alt="Doctor Photo" />
                                                    </div>
                                                    <div className="docInfo">
                                                        <h5>Dr. Jack</h5>
                                                        <Link to="tel:1236547898"><i className="fas fa-phone-alt"></i> +91 1234567890</Link>
                                                        <Link to="mailto:test@gmail.com"><i className="fas fa-envelope"></i> test@gmail.com</Link>
                                                        <div className="shadow-sm card p-1 mt-2">
                                                            <p className='text-center'>My availability</p>
                                                            <ul className="nav nav-tabs border-0 w-100">
                                                                <li className="nav-item w-50"><a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime">General Time </a></li>
                                                                <li className='nav-item w-50'><a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime3">Slot time </a>

                                                                </li>
                                                            </ul>

                                                            <div className="tab-content p-2">
                                                                <div id="generalTime3" className="tab-pane fade show active">
                                                                    <div className="mb-2">
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                    </div>
                                                                </div>
                                                                <div id="slotTime3" className="tab-pane fade">
                                                                    <div className="mb-2">
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                        <p>Monday : 10:AM to 05:PM</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-4 col-12 mt-2 mt-md-2">
                                                                <label>Registration No.</label>
                                                                <input type="text" className="form-control"
                                                                    placeholder="Registration Number"
                                                                    name="registrationNo"
                                                                // value={this.state.hospitalProfile.registrationNo}
                                                                // onChange={(e) => { this.handleChange(e) }}
                                                                />
                                                            </div>

                                                            <div className="col-md-4 col-6 mt-2">
                                                                <label>Registration Council</label>
                                                                <input className="form-control" list="council"
                                                                    placeholder="Registration Council"
                                                                    name="registrationCouncil"
                                                                // value={this.state.hospitalProfile.registrationCouncil.name}
                                                                // onChange={(e) => { this.handleChange(e) }}
                                                                />
                                                                <datalist id="council">
                                                                    {/* {this.registrationCouncilList.map((location) => {
                                                                                                    return <option key={location.id} value={location.name}></option>
                                                                                                })} *
                                                                </datalist>
                                                            </div>

                                                            <div className="col-md-4 col-6 mt-2">
                                                                <label>Registration Type</label>
                                                                <input className="form-control"
                                                                    list="type"
                                                                    name="registrationType"
                                                                    placeholder="Registration Type"
                                                                // value={this.state.hospitalProfile.registrationType.name}
                                                                // onChange={(e) => { this.handleChange(e) }}
                                                                />
                                                                <datalist id="type">
                                                                    {/* {this.registrationTypeList.map((location) => {
                                                                                                    return <option key={location.id} value={location.name}></option>
                                                                                                })} *
                                                                </datalist>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div> */}

            {/* <!-- Add new Doctor model --> */}
            <div className="modal fade" id='addDoctormodel' tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add New Dcotor</h5>

                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="small-12 medium-2 large-2 columns uploadProfile">
                                            <div className="circle">
                                                <img className="profile-pic" src={require('../../../assets/images/user.png').default} />
                                            </div>
                                            <div className="p-image">
                                                <i className="fa fa-camera upload-button"></i>
                                                <input className="file-upload" type="file" accept="image/*" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" className="form-control mb-2" placeholder="Name" />
                                        <input type="text" className="form-control mb-2" placeholder="Phone Number" />
                                        <input type="email" className="form-control mb-2" placeholder="Email" />
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <input type="text" className="form-control mb-2" placeholder="ID No." />
                                        <input type="text" className="form-control mb-2" placeholder="Password" />
                                        <input type="text" className="form-control mb-2" placeholder="Confirm Password" />
                                    </div>
                                    <div className="col-md-12">
                                        <div className="card shadowCustom p-2">
                                            <p className='fw-bold text-center mb-1'>My Availability</p>
                                            <ul className="nav nav-tabs border-0 w-100">
                                                <li className="nav-item w-50">
                                                    <a className='nav-link w-100 btn btn-outline-primary rounded-left active' data-toggle="tab" href="#generalTime" role="tab" aria-controls="general" aria-selected="true">General Time <br />
                                                        <small>(First come, First Served - Waiting time visible - Avg time visible) (Online + In-person)</small>
                                                    </a>
                                                </li>
                                                <li className='nav-item w-50'>
                                                    <a className='nav-link w-100 btn btn-outline-primary rounded-right' data-toggle="tab" href="#slotTime" role="tab" aria-controls="slot" aria-selected="true">Slot time <br />
                                                        <small>(priority fixed appointments) (Online + In-person)</small>
                                                    </a>
                                                </li>
                                            </ul>

                                            <div className="tab-content p-2">
                                                <div id="generalTime" className="tab-pane fade show active" role="tabpanel" aria-labelledby="general-time">
                                                    <label htmlFor="time">Duty Timing</label>
                                                    <div className="mb-2">
                                                        <div className="row addMoreGeneralTime">
                                                            <div className="col-md-3">
                                                                <select className="form-control" defaultValue={'0'}>
                                                                    <option value="0" disabled>Day</option>
                                                                    <option value="0">Monday</option>
                                                                    <option value="0">Tuesday</option>
                                                                    <option value="0">Wednesday</option>
                                                                    <option value="0">Thursday</option>
                                                                    <option value="0">Friday</option>
                                                                    <option value="0">Saturday</option>
                                                                    <option value="0">Sunday</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <div className='d-flex justify-ceontent-between align-items-center'>
                                                                    <input type="time" className="form-control" />
                                                                    <p className='p-2'>to</p>
                                                                    <input type="time" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2 change'>
                                                                <a href="#" className='btn btn-outline-primary addMoreBtnGeneral'><i className="fa fa-plus"></i> Add more</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="slotTime" className="tab-pane fade" role="tabpanel" aria-labelledby="slot-time-tab">
                                                    <div className="profile-box">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h6 className='m-0'>Select Slot time</h6>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div className="col-md-7">
                                                                                <DateRangePicker
                                                                                    ranges={[selectionRange]}
                                                                                // onChange={this.handleSelect}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-5">
                                                                                <div className="row">
                                                                                    <div className='col-md-12 mb-3'>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <h6 className="mb-0 me-3 w-50">Slot time / <br /> Average consultation time</h6>
                                                                                            <select className="form-control w-25 mr-2">
                                                                                                <option value="1">1</option>
                                                                                                <option value="1">2</option>
                                                                                                <option value="1">3</option>
                                                                                                <option value="1">4</option>
                                                                                                <option value="1">5</option>
                                                                                                <option value="1">6</option>
                                                                                                <option value="1">7</option>
                                                                                                <option value="1">60</option>
                                                                                            </select>
                                                                                            <p>Mins</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-md-12 d-flex'>
                                                                                        <button className='btn btn-outline-primary mr-2'>+</button>
                                                                                        <div className='mr-2 w-100'>
                                                                                            <h6 className="mb-0 w-100 mr-2">Consultation <br /> hours Start: </h6>
                                                                                            <input type="time" value="10:30" className='form-control' />
                                                                                        </div>
                                                                                        <div className='w-100'>
                                                                                            <h6 className="mb-0 w-100 mr-2">Consultation <br /> hours End: </h6>
                                                                                            <input type="time" value="18:30" className='form-control' />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="text-start mt-2">
                                                                            <button className="btn btn-primary save-btn">Save</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className='my-2' />
                                    <div className="col-md-12">
                                        <form>
                                            <div className='addMoreQualification'>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className='text-primary fw-bold'>Education Qualification</h5>
                                                    <div className="change">
                                                        <a href="#" className='btn btn-outline-primary addMoreBtnQualification'>Add More</a>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label for="speciality">Please Select</label>
                                                            <select className='form-control'>
                                                                <option value="0" disabled>Specialization</option>
                                                                {props.doctorSpecList?.map((item) => (
                                                                    <option value={item.id}>{item.title}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label for="speciality">Degree</label>
                                                            <select className='form-control'>
                                                                <option value="0" disabled>Please Select</option>
                                                                {props.degreeList?.map((item) => (
                                                                    <option value={item.id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label for="speciality">Collage Name</label>
                                                            <select className='form-control'>
                                                                <option value="0" disabled>Please Select</option>
                                                                {props.collegeList?.map((item) => (
                                                                    <option value={item.id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 mt-2 mt-md-2">
                                                        <label>Registration No.</label>
                                                        <input type="text" className="form-control" placeholder="Registration Number" name="registrationNo" />
                                                    </div>
                                                    <div className="col-md-4 col-6 mt-2">
                                                        <label>Registration Council</label>
                                                        <input className="form-control" list="council" placeholder="Registration Council" name="registrationCouncil" />
                                                        <datalist id="council">
                                                            <option value="Council 1"></option>
                                                            <option value="Council 2"></option>
                                                            <option value="Council 3"></option>
                                                            <option value="Council 4"></option>
                                                        </datalist>
                                                    </div>
                                                    <div className="col-md-4 col-6 mt-2">
                                                        <label>Registration Type</label><input className="form-control" list="type" name="registrationType" placeholder="Registration Type" />
                                                        <datalist id="type">
                                                            <option value="Reg Type 1"></option>
                                                            <option value="Reg Type 2"></option>
                                                            <option value="Reg Type 3"></option>
                                                            <option value="Reg Type 4"></option>
                                                        </datalist>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p>upload Document</p>
                                                        <div className="form-group">
                                                            <input type="file" className='form-control' multiple />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-primary">OK</button>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}


const mapDispatchToProps = dispatch => ({
    AddDoctorAction: params => dispatch(AddDoctorAction(params)),
    GetDoctorList: params => dispatch(GetDoctorList(params)),
    getDegreeList: params => dispatch(DegreeList(params)),
    getCollegeList: params => dispatch(CollegeList(params)),
    getDoctorSpecList: params => dispatch(DoctorSpecList(params)),
})

const mapStateToProps = state => {
    return {
        DoctorsList:
            state.mainReducer?.doctorListReducer?.response?.data || [],
        doctorSpecList:
            state.mainReducer?.doctorSpecListReducer?.response?.data || [],
        collegeList:
            state.mainReducer?.collegeListReducer?.response?.data || [],
        degreeList:
            state.mainReducer?.degreeListReducer?.response?.data || [],
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Doctors)