
import React, { Component } from 'react';
import { TermConditionList } from '../../../mock/TermConditionList';


class ContactUs extends Component{
  
    constructor(props){
        super(props);
        this.state = {
			termConditions:TermConditionList
         };
    }

	

	componentDidMount(){
    //     setTimeout(() => {
	// 	this.setState({invoiceDTO:AllInvoiceDTOs});
	//    }, 10);



    }


    render() {

        return (
           <React.Fragment>
      <section className="tnc-section">
    <div className="container">
    <div className="row">
        <div className="col">
            <div className="card mb-4">
                <div className="card-header bg_conatct_title text-white"><i className="fa fa-envelope"></i> CONTACT US
                </div>
                <div className="card-body">
                    <form>
                        <div className="form-group">
                            <input type="text" className="form-control" id="name" placeholder="Your Name*" required />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" id="email" placeholder="Your Email*" required />
                            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" id="phonenumber" placeholder="Phone Number*" required />
                        </div>
                        <div className="form-group">
                            <textarea className="form-control" id="message" rows="6" placeholder="Message*" required></textarea>
                        </div>
                        <div className="mx-auto">
                        <button type="submit" className="btn btn-primary text-right">Submit</button></div>
                    </form>
                </div>
            </div>
        </div>
        <div className="col-12 col-sm-4">
            <div className="card bg-light mb-3">
                <div className="card-header bg_conatct_title text-white text-uppercase"><i className="fa fa-home"></i> Contact Information</div>
                <div className="card-body">
                    <p>3 rue des Champs Elysées,</p>
            		<p>75008 PARIS,</p>
            		<p className="mb-3">India.</p>
            		<p className="mb-3">Email : email@example.com</p>
            		<p className="mb-3">Tel. +33 12 56 11 51 84</p>

                </div>

            </div>
        </div>
    </div>
</div>
</section>
           </React.Fragment>
    
        )
    }
}
export default ContactUs;