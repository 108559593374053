import React, { Component } from 'react';
import { Link } from "react-router-dom";
import * as $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css"


import DataTableGrid from 'src/common/DataGrid/DataGrid';
import { connect } from 'react-redux';

import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const Allstaff = () => {

    const rows = [
        {
            id: 1, col1: "Hello",
            col2: "World",
            col3: "Monday: 10:00 AM - 11:00 AM",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 2, col1: "MUI X",
            col2: "is awesome",
            col3: "Monday: 10:00 AM - 11:00 AM",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 3, col1: "Material UI",
            col2: "is amazing",
            col3: "Monday: 10:00 AM - 11:00 AM",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 4, col1: "MUI",
            col2: "Lokesh",
            col3: "Monday: 10:00 AM - 11:00 AM",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 5, col1: "Joy UI",
            col2: "is awesome",
            col3: "Monday: 10:00 AM - 11:00 AM",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        },
        {
            id: 6, col1: "MUI Base",
            col2: "is amazing",
            col3: "Monday: 10:00 AM - 11:00 AM",
            col4: "World",
            col5: "World",
            col6: "World",
            col7: "World",
            col8: "World",
            col9: "World",
        }
    ];


    const columns = [
        { field: "id", headerName: "#", },
        { field: "col1", headerName: "Name", width: 150 },
        { field: "col5", headerName: "Staff Category", width: 150 },
        { field: "col2", headerName: "Hospital", width: 150 },
        { field: "col4", headerName: "Location", width: 150 },
        { field: "col3", headerName: "Duty Timing", width: 150 },
        {
            headerName: "Action",
            field: 'actions',
            type: 'actions',
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    // onClick={deleteUser(params.id)}
                    onClick={() => { }}
                />,
                <GridActionsCellItem
                    icon={<SecurityIcon />}
                    label="View"
                    onClick={() => { }}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<FileCopyIcon />}
                    label="Edit"
                    onClick={() => { }}
                    showInMenu
                />,
            ]
        },];

    return (
        <div className="container dct-appoinment">
            <h5 className='mt-3 fw-bold mb-3'>All Staff</h5>

            <DataTableGrid
                rows={rows}
                columns={columns}
                rowCountState={rows.length}

            />
        </div>
    )
}

export default connect()(Allstaff)

// class Allstaff extends React.Component{

// 	componentDidMount() {
// 		document.title = "All Staff"
// 		$('#allDoctors').DataTable({
// 			dom: '<"top"Bflp>rt<"bottom"ip>',
//             pageLength: 5,
//             buttons: ['copy', 'csv', 'print', 'excel']
// 		});
// 	}

// 	render(){
// 		return(
// 			<>
// 				<div className="container">
// 					<div className="row">
// 						<div className="col-md-12">
// 							<h5 className='mt-3 fw-bold mb-3'>All Staff</h5>
// 							<div className="table-responsive">
// 								<div className="table-responsive">
// 									<table className="datatable table table-hover table-center mb-0" id="allDoctors">
//                                         <thead>
//                                             <tr>
//                                                 <th scope="col">#</th>
//                                                 <th scope="col">Name</th>
//                                                 <th scope="col">Staff Category</th>
//                                                 <th scope="col">Hospital</th>
//                                                 <th scope="col">Location</th>
//                                                 <th scope="col">Duty Timing</th>
//                                                 <th scope="col">Action</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody>
//                                             <tr>
//                                                 <th scope="row">DOC1</th>
//                                                 <td>Ms. Rossy</td>
//                                                 <td>Nurse</td>
//                                                 <td>Apollo Mumbai</td>
//                                                 <td>Mumbai</td>
//                                                 <td>
//                                                     <ul>
//                                                         <li>Monday: 10:00 AM - 11:00 AM</li>
//                                                         <li>Tuesday: 11:00 AM - 12:00 AM</li>
//                                                         <li>Friday: 12:00 AM - 1:00 PM</li>
//                                                     </ul>
//                                                 </td>
//                                                 <td>
//                                                     <Link to="#" className='btn btn-outline-success mr-2'><i className="fas fa-edit" data-toggle="modal" data-target="#editStaffModal"></i></Link>
//                                                 	<Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                                 </td>
//                                             </tr>
//                                             <tr>
//                                                 <th scope="row">DOC1</th>
//                                                 <td>Raju</td>
//                                                 <td>Receptionist</td>
//                                                 <td>Apollo Delhi</td>
//                                                 <td>Delhi</td>
//                                                 <td>
//                                                     <ul>
//                                                         <li>Monday: 10:00 AM - 11:00 AM</li>
//                                                         <li>Tuesday: 11:00 AM - 12:00 AM</li>
//                                                         <li>Friday: 12:00 AM - 1:00 PM</li>
//                                                     </ul>
//                                                 </td>
//                                                 <td>
//                                                     <Link to="#" className='btn btn-outline-success mr-2' data-toggle="modal" data-target="#editStaffModal"><i className="fas fa-edit"></i></Link>
//                                                 	<Link to="#" className='btn btn-outline-danger' data-toggle="modal" data-target="#deleteModal"><i className="fas fa-trash-alt"></i></Link>
//                                                 </td>
//                                             </tr>
//                                         </tbody>
// 									</table>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>


//                 {/* <!-- Edit staff model --> */}
//                 <div className="modal fade" id="editStaffModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
//                     <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
//                         <div className="modal-content">
//                             <div className="modal-header">
//                                 <h5 className="modal-title" id="exampleModalLabel">Add New Staff Member</h5>
//                                 <select className='form-control w-50 ml-4'>
//                                     <option value="1">- Select Staff Category</option>
//                                     <option value="2">Nurse</option>
//                                     <option value="3">Receptionist</option>
//                                 </select>
//                                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
//                                     <span aria-hidden="true">&times;</span>
//                                 </button>
//                             </div>
//                             <div className="modal-body">
//                                 <form action="">
//                                     <div className="row">
//                                         <div className="col-md-12">
//                                         <div className="small-12 medium-2 large-2 columns uploadProfile">
//                                             <div className="circle">
//                                                 <img className="profile-pic" src={require('../../../assets/images/user.png').default}/>
//                                             </div>
//                                             <div className="p-image">
//                                                 <i className="fa fa-camera upload-button"></i>
//                                                 <input className="file-upload" type="file" accept="image/*"/>
//                                             </div>
//                                         </div>
//                                         </div>
//                                         <div className="col-md-6">
//                                             <input type="text" className="form-control mb-2" placeholder="Name" />
//                                             <input type="text" className="form-control mb-2" placeholder="Phone Number" />
//                                             <input type="email" className="form-control mb-2" placeholder="Email" />
//                                         </div>
//                                         <div className="col-md-6 col-12">
//                                             <input type="text" className="form-control mb-2" placeholder="ID No." />
//                                             <input type="text" className="form-control mb-2" placeholder="Password" />
//                                             <input type="text" className="form-control mb-2" placeholder="Confirm Password" />
//                                         </div>
//                                         <div className="col-md-12">
//                                             <label htmlFor="time">Duty Timing</label>
//                                             <div className="d-flex align-items-center justify-content-between mb-2">
//                                                 <div className="row w-75">
//                                                     <div className="col-md-4">
//                                                         <select className="form-control" defaultValue={'0'}>
//                                                             <option value="0"  disabled>Day</option>
//                                                             <option value="0">Monday</option>
//                                                             <option value="0">Tuesday</option>
//                                                             <option value="0">Wednesday</option>
//                                                             <option value="0">Thursday</option>
//                                                             <option value="0">Friday</option>
//                                                             <option value="0">Saturday</option>
//                                                             <option value="0">Sunday</option>
//                                                         </select>
//                                                     </div>
//                                                     <div className="col-md-8">
//                                                         <div className='d-flex justify-ceontent-between align-items-center'>
//                                                             <input type="time" className="form-control" />
//                                                             <p className='p-2'>to</p>
//                                                             <input type="time" className="form-control" />
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <div className='w-25'>
//                                                     <button className='btn btn-outline-primary'>+ Add more</button>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </form>
//                             </div>
//                             <div className="modal-footer">
//                                 <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
//                                 <button type="button" className="btn btn-primary">OK</button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
// 			</>
// 		)
// 	}
// }


// export default Allstaff