import HospitalList from "./dropdown/HospitalList";
import InsuranceList from "./dropdown/InsuranceList";
import  LocationList  from "./dropdown/LocationList";
import SpecialityList from "./dropdown/SpecialityList";

const NearByDoctors=[
    {
        id:1,
        name:"Test Doctor ",
        address:"Bangalore ",
        phone:1234567890,
        location:LocationList[2],
        specialization:SpecialityList[1],
        insurance:InsuranceList[1],
        imageUrl:'',
        appointmentTime:'10:30am - 1:30pm',
        appointmentDay:'24/04/2018',
        hospital:HospitalList[1],
        patient:{
            id:1,
            name:'Ramesh Sharma',
            status:'Confirmed'
        }
    },
    {
        id:2,
        name:"Test Doctor 2 ",
        address:"Bangalore ",
        phone:1234567890,
        location:LocationList[2],
        specialization:SpecialityList[2],
        insurance:InsuranceList[2],
        imageUrl:'',
        appointmentTime:'12:30am - 1:30pm',
        appointmentDay:'25/04/2018',
        hospital:HospitalList[2],
        patient:{
            id:2,
            name:'Ramesh Sharma 2',
            status:'Confirmed'
        }
    },
    {
        id:3,
        name:"Test Doctor 3",
        address:"Bangalore ",
        phone:1234567890,
        location:LocationList[1],
        specialization:SpecialityList[1],
        insurance:InsuranceList[1],
        imageUrl:'',
        appointmentTime:'1:30am - 1:30pm',
        appointmentDay:'24/07/2020',
        hospital:HospitalList[1],
        patient:{
            id:3,
            name:'Ramesh Sharma 3',
            status:'Confirmed'
        }
    },
    {
        id:4,
        name:"Test Doctor 4",
        address:"Bangalore ",
        phone:1234567890,
        location:LocationList[2],
        specialization:SpecialityList[2],
        insurance:InsuranceList[2],
        imageUrl:'',
        appointmentTime:'03:30am - 4:30pm',
        appointmentDay:'20/04/2018',
        hospital:HospitalList[1],
        patient:{
            id:4,
            name:'Ramesh Sharma 4',
            status:'Confirmed'
        }
    },
    {
        id:5,
        name:"Test Doctor 5",
        address:"Bangalore ",
        phone:1234567890,
        location:LocationList[1],
        specialization:SpecialityList[1],
        insurance:InsuranceList[2],
        imageUrl:'',
        appointmentTime:'11:30am - 1:30pm',
        appointmentDay:'4/04/2021',
        hospital:HospitalList[1],
        patient:{
            id:5,
            name:'Ramesh Sharma 5',
            status:'Confirmed'
        }
    },
    {
        id:6,
        name:"Test Doctor 6",
        address:"Bangalore ",
        phone:1234567890,
        location:LocationList[1],
        specialization:SpecialityList[2],
        insurance:InsuranceList[1],
        imageUrl:'',
        appointmentTime:'02:30am - 1:30pm',
        appointmentDay:'22/04/2018',
        hospital:HospitalList[2],
        patient:{
            id:6,
            name:'Ramesh Sharma 6',
            status:'Confirmed'
        }
    }

];


export {NearByDoctors};